import { ErrorResult } from "../../store/model/errorResult";
import axiosInstance from "../axiosInstance";



const GetAllOfficeNominalLedger = async (data: any) => {
    try {
        const response = await axiosInstance.post("OfficeAccount/searchnominal", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetOfficeNominalLedgerById= async (id: any) => {
    try {
        const response = await axiosInstance.get(`OfficeAccount/nominaltransactions/${id}`);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const OfficeNominalLedgerService = {
    GetAllOfficeNominalLedger,
    GetOfficeNominalLedgerById
};

export default OfficeNominalLedgerService;
