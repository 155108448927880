import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";




const GetAllDisbursementType = async () => {
    try {
        const response = await axiosInstance.get("DisbursementType");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostDisbursementType = async (data: any) => {
    try {
        const response = await axiosInstance.post("DisbursementType", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutDisbursementType = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`DisbursementType/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteDisbursementType = async (id: number) => {
    try {
        await axiosInstance.delete(`DisbursementType/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const DisbursementTypeService = {
    GetAllDisbursementType,
    PostDisbursementType,
    PutDisbursementType,
    deleteDisbursementType
};


export default DisbursementTypeService;
