import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";



const GetAllOfficeSupplierReFundCreditNote = async (data: any) => {
    try {
        const response = await axiosInstance.post("Supplier/ReFundcreditnote/search", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostOfficeSupplierReFundCreditNote = async (data: any) => {
    try {
        const response = await axiosInstance.post("Supplier/ReFundcreditnote/add", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutOfficeSupplierReFundCreditNote = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`OfficeSupplierReFundCreditNote/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteOfficeSupplierReFundCreditNote = async (id: number) => {
    try {
        await axiosInstance.delete(`/OfficeSupplierReFundCreditNote/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const OfficeSupplierReFundCreditNoteService = {
    GetAllOfficeSupplierReFundCreditNote,
    PostOfficeSupplierReFundCreditNote,
    PutOfficeSupplierReFundCreditNote,
    deleteOfficeSupplierReFundCreditNote
};


export default OfficeSupplierReFundCreditNoteService;
