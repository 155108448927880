import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllIndemnityInsurance = async (data: any) => {
    try {
        const response = await axiosInstance.post("IndemnityInsurance", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostIndemnityInsurance = async (data: any) => {
    try {
        const response = await axiosInstance.post("IndemnityInsurance", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutIndemnityInsurance = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`IndemnityInsurance/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteIndemnityInsurance = async (id: number) => {
    try {
        await axiosInstance.delete(`/IndemnityInsurance/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const IndemnityInsuranceService = {
    GetAllIndemnityInsurance,
    PostIndemnityInsurance,
    PutIndemnityInsurance,
    deleteIndemnityInsurance
};


export default IndemnityInsuranceService;
