import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";




const GetAllClientBankAccount = async (data: any) => {
    try {
        const response = await axiosInstance.post("accounting/clientbankaccounttransaction", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


// const PostClientBankAccount = async (data: any) => {
//     try {
//         console.log(data);
//         const response = await axiosInstance.post("ClientBankAccount", data);
//         return response.data;
//     } catch (error: any) {
//         let data = error as ErrorResult;
//         throw new Error(data.messages.toString());
//     }
// }


// const PutClientBankAccount = async (id: number, data: any) => {
//     try {
//         const response = await axiosInstance.put(`ClientBankAccount/${id}`, data);
//         return response.data;
//     } catch (error: any) {
//         let data = error as ErrorResult;
//         throw new Error(data.messages.toString());
//     }
// }


// const deleteClientBankAccount = async (id: number) => {
//     try {
//         await axiosInstance.delete(`/ClientBankAccount/${id}`);
//     } catch (error: any) {
//         let data = error as ErrorResult;
//         throw new Error(data.messages.toString());
//     }
// }

const ClientBankAccountService = {
    GetAllClientBankAccount,
    // PostClientBankAccount,
    // PutClientBankAccount,
    // deleteClientBankAccount
};


export default ClientBankAccountService;
