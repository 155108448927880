interface AddressModel {
  addressLine1: string;
  addressLine2: string;
  city: string;
  county: string;
  postcode: string;
}

const splitAddress = (address: string): AddressModel => {
  if (!address || typeof address !== "string") {
    console.error("Invalid address input: Address must be a non-empty string");
    return {
      addressLine1: "",
      addressLine2: "",
      city: "",
      county: "",
      postcode: "",
    };
  }

  const parts = address
    .split(",")
    .map((part) => part.trim())
    .filter(Boolean);

  if (parts.length < 3) {
      console.error("Invalid address format: Address must contain at least two parts");

    return {
      addressLine1:parts[0] || "",
      addressLine2: "",
      city: "",
      county: "",
      postcode: parts[parts.length - 1] || "",
    };
  }

  let result: AddressModel;

  if (parts.length === 5) {
    result = {
      addressLine1: parts[0],
      addressLine2: parts[1],
      city: parts[2],
      county: parts[3],
      postcode: parts[4],
    };
  } else if (parts.length === 6) {
    result = {
      addressLine1: `${parts[0]}, ${parts[1]}`,
      addressLine2: parts[2],
      city: parts[3],
      county: parts[4],
      postcode: parts[5],
    };
  } else {
    // Handle other cases (e.g., fewer parts) by filling from the start and leaving the rest as 'N/A'
    result = {
      addressLine1: parts[0] || 'N/A',
      addressLine2: parts[1] || 'N/A',
      city: parts[2] || 'N/A',
      county: parts[3] || 'N/A',
      postcode: parts[parts.length - 1] || 'N/A',
    };
  }

  return result;
};

const LocationUtils = {
  splitAddress,
};

export default LocationUtils;
