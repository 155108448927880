import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetAllSurveyQuestionOrder = async (data: any) => {
    try {
        const response = await axiosInstance.post("SurveyQuestionOrder", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostSurveyQuestionOrder = async (data: any) => {
    try {
        const response = await axiosInstance.post("SurveyQuestionOrder", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutSurveyQuestionOrder = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`SurveyQuestionOrder/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteSurveyQuestionOrder = async (id: number) => {
    try {
        await axiosInstance.delete(`/SurveyQuestionOrder/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const SurveyQuestionOrderService = {
    GetAllSurveyQuestionOrder,
    PostSurveyQuestionOrder,
    PutSurveyQuestionOrder,
    deleteSurveyQuestionOrder
};


export default SurveyQuestionOrderService;
