import axiosInstance from '../../../axiosInstance';
import { ErrorResult } from '../../../../store/model/errorResult';

const GetContactgroup = async (id: number) => {
    try {
        const response = await axiosInstance.get(`ContactGroup/${id}`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const ContactGroupService = {
    GetContactgroup,
};

export default ContactGroupService;
