import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../../Filters/CheckBoxFilter";
import { SearchInput } from "../../Filters/SearchInput";
import { HeaderTitle } from "../../HeaderTitle";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { fetchAllAuthorizePayment } from "../../../store/slice/Accounting/ClientAccounts/ClientPosting/BankPayment/AuthorizePaymentSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../../loadingAnimation/MainAnimation";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineRefresh } from "react-icons/md";
import { Pagination } from "flowbite-react";
import { SubTitle } from "../../SubTitle";

function AuthorisePayment() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState("AuthorisePayment");
  const dispatch = useAppDispatch();
  const { authorizePayments, status, error } = useSelector(
    (state: RootState) => state.authorizePayment
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleButtonClick = (section: any) => {
    setSelectedSection(section);
  };

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    // if (!effectServiceCall.current) {
    //     // dispatch(fetchAllAuthorizePayment())
    //         .then(() => setInitialLoading(false))
    //         .catch(() => setInitialLoading(false));
    //     effectServiceCall.current = true;
    // }

    if (status === "failed" && !errorShownRef.current) {
      toast.error(error);
      errorShownRef.current = true;
    }
  }, [dispatch, status, error]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const toggleDrawer = () => {
    setOpenModal(!openModal);
  };

  // const filterAuthorisePaymentBySearchQuery = () => {
  //     return authorizePayments.filter((authorizePayment: any) =>
  //         authorizePayment.clientBank.toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  // };

  // const filteredByAuthorisePayment = filterAuthorisePaymentBySearchQuery();

  const indexOfLastAuthorisePayment = currentPage * itemsPerPage;
  const indexOfFirstAuthorisePayment =
    indexOfLastAuthorisePayment - itemsPerPage;
  const currentAuthorisePayment = authorizePayments.slice(
    indexOfFirstAuthorisePayment,
    indexOfLastAuthorisePayment
  );

  const totalPages = Math.ceil(authorizePayments.length / itemsPerPage);

  return (
    <>
      <div className="">
        <div className=" w-full flex flex-col gap-2">
          <div className="mb-5">
            <SubTitle title="Authorise Payment" />
          </div>
          <div className="flex flex-row md:flex-col justify-between mb-5 gap-10 w-full md:gap-3">
            <div>
              <SearchInput title="Keyword" />
            </div>
            <div>
              <div className="flex flex-col gap-x-4 gap-y-2 md:gap-1 w-full">
                <div className="flex border-b-2 pb-1.5">
                  <CheckBoxFilter title="Client Bank" />
                  <div className="w-px h-5 mx-2 bg-gray-300"></div>
                  {/* <CheckBoxFilter title="Areas of Practice" /> */}
                  <div className="w-px h-5 mx-2 bg-gray-300"></div>
                  <CheckBoxFilter title="Case Type" />
                </div>
                <div className="flex">
                  <CheckBoxFilter title="Priority" />
                  {/* <CheckBoxFilter title="Case Sub Type" /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="relative overflow-hidden sm:rounded-lg">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              {/* {initialLoading ? (<MainAnimation />) : ""} */}
              <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Priority
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Request Details
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Case Details
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Payee
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Description
                  </th>
                  <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Action
                  </th>
                </tr>
              </thead>{" "}
              <tbody>
                {currentAuthorisePayment.map((authorisePayment: any, index) => (
                  <tr
                    key={index}
                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{authorisePayment.priority}</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {authorisePayment.requestDetails}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {authorisePayment.caseDetails}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{authorisePayment.payee}</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {authorisePayment.description}
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{authorisePayment.amount}</td>
                    <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <div className="flex gap-3 w-full">
                        <FaEye className="fill-gray-500 w-6 h-6" />
                        <FaEdit className="fill-blue-500 w-6 h-6" />
                        <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                        <MdOutlineRefresh className="fill-blue-800 w-6 h-6" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {currentAuthorisePayment.length === 0 && (
              <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                <p className="text-red-800">No Bank Create Payment found.</p>
              </div>
            )}
          </div>
          {currentAuthorisePayment.length > 0 && (
            <div className="w-full flex flex-col pb-[100px]">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export { AuthorisePayment };
