import { useEffect, useRef, useState } from "react";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllReportBankReconcilliation } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/ReportBankReconcilliationSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "flowbite-react";
import { SelectBox } from "../SelectBox";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
function OfficeBankReconcilliation() {
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(false);
  const { reportBankReconcilliations, status, error } = useSelector(
    (state: RootState) => state.reportBankReconcilliation
  );
  const [totalPages, setTotalPages] = useState(0);
  const [
    currentReportBankReconcilliation,
    setCurrentReportBankReconcilliation,
  ] = useState<any[]>([]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [branchUserLists, setBranchUserLists] = useState<any[]>([]);
  const [officeAccLists, setOfficeAccLists] = useState<any>([]);
  const [staffId, setStaffId] = useState<any>(null);
  const [selectedMonth, setSelectedMonth] = useState<any>(null);
  const [selectedYear, setSelectedYear] = useState<any>(null);
  const [bankId, setBankId] = useState<any>(null);
  const { branchUsers } = useSelector((state: RootState) => state.userManage);
  const officeBankAccData = useSelector((state: RootState) => state.officeBank.officeBanks);
  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const years = [
    { value: "2024", label: "2024" },
    { value: "2023", label: "2023" },
    { value: "2022", label: "2022" },
    { value: "2021", label: "2021" },
    { value: "2020", label: "2020" },
    { value: "2019", label: "2019" },
  ];

  useEffect(() => {
    if (officeBankAccData.length > 0) {
      let data = officeBankAccData.map((officeAccount: any, index) => ({
        value: officeAccount.bankAccountId,
        label: officeAccount.accountName + officeAccount.bankAccountType,
      }));
      setOfficeAccLists(data);
    }
  }, [officeBankAccData]);

  useEffect(() => {
    if (branchUsers != null && branchUsers.length > 0) {
      let data = branchUsers.map((branchUser: any, index) => ({
        value: branchUser.userId,
        label: `${branchUser.firstName} ${branchUser.lastName} `,
      }));
      setBranchUserLists(data);
    }
  }, [branchUsers]);

  const handleSearchClick = async () => {
    setInitialLoading(true);
    const data = {
      staffId: staffId,
      month: selectedMonth,
      year: selectedYear,
      bankAccountId: bankId
    };
    try {
      await dispatch(fetchAllReportBankReconcilliation(data)).unwrap();
      toast.success("Successfully Search Bank Reconcilliation");
    } catch (error: any) {
      toast.error(error?.message || "An error occurred while fetching data");
    } finally {
      setInitialLoading(false);
    }
  };


  useEffect(() => {
    if (
      reportBankReconcilliations != null &&
      reportBankReconcilliations.length > 0
    ) {
      const indexOfLastReportBankReconcilliation = currentPage * itemsPerPage;
      const indexOfFirstReportBankReconcilliation =
        indexOfLastReportBankReconcilliation - itemsPerPage;
      const currentReportBankReconcilliation = reportBankReconcilliations.slice(
        indexOfFirstReportBankReconcilliation,
        indexOfLastReportBankReconcilliation
      );
      const totalPages = Math.ceil(
        reportBankReconcilliations.length / itemsPerPage
      );
      setTotalPages(totalPages);
      setCurrentReportBankReconcilliation(currentReportBankReconcilliation);
    }
  }, [reportBankReconcilliations]);

  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle title="Bank Reconcilliation" />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">

          <div className="grid grid-cols-4 gap-x-3 md:gap-1 w-full">
            <div className="">
              <SelectBox
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={officeAccLists}
                isSearchable={true}
                placeholder="Client Bank"
                shape="round"
                color="indigo_50"
                size="xs"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setBankId(selectedOption);
                }
              }}
              />
            </div>
            <div className="">
              <SelectBox
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={branchUserLists}
                isSearchable={true}
                placeholder="Staff"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setStaffId(selectedOption);
                  }
                }}
              />
            </div>
            <div className="">
              <SelectBox
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={years}
                isSearchable={true}
                placeholder="Years"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setSelectedYear(selectedOption);
                  }
                }}
              />
            </div>
            <div className="">
              <SelectBox
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                options={months}
                name="frameFiftySix"
                isSearchable={true}
                placeholder="Months"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setSelectedMonth(selectedOption);
                  }
                }}
              />
            </div>
            {/* <CheckBoxFilter title="Staff" />
              <CheckBoxFilter title="Year" />
              <CheckBoxFilter title="Month" /> */}
          </div>

          <div>
            <button
              className="z-[2] inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
              onClick={handleSearchClick}
              type="button"
            >
              Search
            </button>
          </div>
        </div>
        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto max-h-96">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Statement Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Bank
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Staff
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Report
                </th>
                <th scope="col" className="text-center px-3 py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentReportBankReconcilliation.map(
                (reportBankReconcilliation: any, index) => (
                  <tr
                    key={index}
                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {formatDateOnlyDisplay(reportBankReconcilliation.statementDate)}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {reportBankReconcilliation.bank}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {reportBankReconcilliation.staff}
                    </td>
                    <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {reportBankReconcilliation.report}
                    </td>
                    <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          </div>
          {currentReportBankReconcilliation.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">
                No Bank Reconcilliation Report Details found.
              </p>
            </div>
          )}
        </div>
        {currentReportBankReconcilliation.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { OfficeBankReconcilliation };
