import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { boolean, number, object, string } from "yup";
import { RootState } from "../../../store/store";
import { fetchUsersDataByBranch } from "../../../store/slice/Administrator/UserManagementSlice";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { SelectBox } from "../../SelectBox";
import { fetchAllRiskTypeStatus } from "../../../store/slice/CaseManagement/Case/CaseManagementSlice";
import { useParams } from "react-router-dom";

function AddCaseAml() {
    const { referenceNumber } = useParams();
    const [selectedRiskType, setSelectedRiskType] = useState('na');
    const usersDataByBranch = useSelector((state: RootState) => state.userManage.branchUsers);
    const riskTypeStatus = useSelector((state: RootState) => state.case.riskTypeStatus);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [branchUserOptionsLists, setBranchUserOptionsLists] = useState<any[]>([]);
    const [statusOptionsLists, setStatusOptionsLists] = useState<any[]>([]);


    const riskType = [
        { label: 'Low', value: 'Low' },
        { label: 'Medium', value: 'Medium' },
        { label: 'High', value: 'High' }
    ];

    const riskTypeOptionList = riskType.map((type: any) => ({
        label: type.label,
        value: type.value
    }));

    const effectServiceCall = useRef(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchUsersDataByBranch());
                await dispatch(fetchAllRiskTypeStatus());
            } catch (error) {
                console.error("Failed to fetch data:", error);
            } finally {
                setInitialLoading(false);
            }
        };

        if (!effectServiceCall.current) {
            fetchData();
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (usersDataByBranch != null) {
            const data = usersDataByBranch.map((branchUser: any) => ({
                value: branchUser.userId,
                label: branchUser.firstName + " " + branchUser.lastName + "(" + branchUser.designation + ")",
            }));
            setBranchUserOptionsLists(data);
        }

        if (riskTypeStatus != null) {
            const dataList = riskTypeStatus.map((status: any) => ({
                value: status.riskAssessmentStatusTypeId,
                label: status.riskAssessmentStatus,
            }));
            setStatusOptionsLists(dataList);
        }
    }, [usersDataByBranch]);


    const handleRiskTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRiskType(event.target.value);
        reset();
        setValue('assessmentType', event.target.value);
    };


    const validationSchema = object().shape({
        notes: string(),
        assessmentType: string(),
        riskType: string(),
        riskScore: string(),
        fkCaseId: string(),
        completedOn: string()
            .nullable()
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split('T')[0];
            }),
        completedBy: number(),
        currentStatus: number(),
    });

    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            assessmentType: 'na',
            fkCaseId: referenceNumber,
        }
    });

    const onSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));

        // setIsLoading(true);
        // try {
        //     await dispatch(addCaseRiskAssessment({ id: referenceNumber, data })).unwrap();
        //     Close();
        //     toast.success("Successfully Added Case Risk");
        // } catch (error: any) {
        //     toast.error(error.toString());
        // } finally {
        //     setIsLoading(false);
        // }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="col-start-1 col-span-2">
                    <div className=" flex flex-col border dark:text-white-bg dark:border dark:border-gray-400 dark:border-opacity-35 p-6 gap-5">
                        <input type="hidden" {...register('fkCaseId')} value={referenceNumber} />
                        <div className="flex flex-row items-center space-x-10">
                            <div>
                                <input
                                    type="radio"
                                    value='na'
                                    checked={selectedRiskType === 'na'}
                                    onChange={handleRiskTypeChange}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Not Applicable</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    value='onscreen'
                                    checked={selectedRiskType === 'onscreen'}
                                    onChange={handleRiskTypeChange}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">On Screen AML Assessment</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    value='upload'
                                    checked={selectedRiskType === 'upload'}
                                    onChange={handleRiskTypeChange}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Upload AML Assessment</label>
                            </div>
                        </div>
                        {selectedRiskType === 'na' && (
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Notes
                                </label>
                                <textarea className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 $`}
                                    {...register('notes')} />
                            </div>
                        )}

                        {selectedRiskType === 'onscreen' && (
                            <div className="">
                                <div className=" grid grid-cols-2 gap-5">
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Select AML Assessment
                                        </label>
                                        <button className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
                                            Select RA
                                        </button>
                                    </div>
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Completed On
                                        </label>
                                        <input type="date" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('completedOn')} max={new Date().toISOString().split('T')[0]} />
                                    </div>
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Completed By
                                        </label>
                                        <SelectBox
                                            className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                            indicator={
                                                <svg
                                                    width="15"
                                                    className="fill-current mr-2"
                                                    height="15"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512">
                                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                                </svg>
                                            }
                                            isMulti={false}
                                            name="branchuser"
                                            isSearchable={true}
                                            options={branchUserOptionsLists}
                                            placeholder="Select"
                                            shape="round"
                                            color="indigo_50"
                                            size="xs"
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    setValue('completedBy', selectedOption);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Risk Score
                                        </label>
                                        <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('riskScore')} />
                                    </div>
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Risk
                                        </label>
                                        <SelectBox
                                            className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                            indicator={
                                                <svg
                                                    width="15"
                                                    className="fill-current mr-2"
                                                    height="15"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512">
                                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                                </svg>
                                            }
                                            isMulti={false}
                                            name="branchuser"
                                            isSearchable={true}
                                            options={riskTypeOptionList}
                                            placeholder="Select"
                                            shape="round"
                                            color="indigo_50"
                                            size="xs"
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    setValue('riskType', selectedOption);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Status
                                        </label>
                                        <SelectBox
                                            className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                            indicator={
                                                <svg
                                                    width="15"
                                                    className="fill-current mr-2"
                                                    height="15"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512">
                                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                                </svg>
                                            }
                                            isMulti={false}
                                            name="branchuser"
                                            isSearchable={true}
                                            options={statusOptionsLists}
                                            placeholder="Select"
                                            shape="round"
                                            color="indigo_50"
                                            size="xs"
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    setValue('currentStatus', selectedOption);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Notes
                                </label>
                                <textarea {...register('notes')} className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 $`} />
                            </div>
                        )}

                        {selectedRiskType === 'upload' && (
                            <div className="">
                                <div className=" grid grid-cols-2 gap-5">
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Attachment
                                        </label>
                                        <button className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
                                            Select RA
                                        </button>
                                    </div>
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Completed On
                                        </label>
                                        <input type="date" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('completedOn')} max={new Date().toISOString().split('T')[0]} />
                                    </div>
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Completed By
                                        </label>
                                        <SelectBox
                                            className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                            indicator={
                                                <svg
                                                    width="15"
                                                    className="fill-current mr-2"
                                                    height="15"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512">
                                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                                </svg>
                                            }
                                            isMulti={false}
                                            name="branchuser"
                                            isSearchable={true}
                                            options={branchUserOptionsLists}
                                            placeholder="Select"
                                            shape="round"
                                            color="indigo_50"
                                            size="xs"
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    setValue('completedBy', selectedOption);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Risk Score
                                        </label>
                                        <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('riskScore')} />
                                    </div>
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Risk
                                        </label>
                                        <SelectBox
                                            className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                            indicator={
                                                <svg
                                                    width="15"
                                                    className="fill-current mr-2"
                                                    height="15"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512">
                                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                                </svg>
                                            }
                                            isMulti={false}
                                            name="branchuser"
                                            isSearchable={true}
                                            options={riskTypeOptionList}
                                            placeholder="Select"
                                            shape="round"
                                            color="indigo_50"
                                            size="xs"
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    setValue('riskType', selectedOption);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                            Status
                                        </label>
                                        <SelectBox
                                            className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                            indicator={
                                                <svg
                                                    width="15"
                                                    className="fill-current mr-2"
                                                    height="15"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512">
                                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                                </svg>
                                            }
                                            isMulti={false}
                                            name="branchuser"
                                            isSearchable={true}
                                            options={statusOptionsLists}
                                            placeholder="Select"
                                            shape="round"
                                            color="indigo_50"
                                            size="xs"
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    setValue('currentStatus', selectedOption);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Notes
                                </label>
                                <textarea {...register('notes')} className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 $`} />
                            </div>
                        )}

                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                    type='button'
                                    onClick={() => {
                                        reset();
                                    }}
                                    className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    {isLoading ? (
                                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                        </svg>
                                    ) : (
                                        'Save'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export { AddCaseAml };