import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import LexcelCqsColpCofaGdprService from "../../../../services/OfficeManagement/OfficeInformation/LexcelCqsColpCofaGdprService";
import { ChapterTypeEnum } from "../../../model/OfficeManagement/OfficeInformation/OfficeChapterEnum";

export interface GuideToGDPR {

 // guideToGDPRId: number;
  chapterType: ChapterTypeEnum;
  id: number;
  chapterName: string;
  attachmentName: string;
  attachmentFile: string; 
}
// Define the state type for Checklist
type GuideToGDPRState = {
    guideToGDPRs: PaginationResponse<GuideToGDPR> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: GuideToGDPRState = {
    guideToGDPRs: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllGuideToGDPR = createAsyncThunk(
    "fetchAllGuideToGDPR",
    async (chapterType: ChapterTypeEnum, thunkAPI) => {
        try {
            const response = await LexcelCqsColpCofaGdprService.GetAllLexcelCqsColpCofaGdpr(chapterType);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addGuideToGDPR = createAsyncThunk(
    "addGuideToGDPR",
    async (data: { chapterType: number; data: any }, thunkAPI) => {
        try {
            const response = await LexcelCqsColpCofaGdprService.PostLexcelCqsColpCofaGdpr(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateGuideToGDPR = createAsyncThunk(
    "updateGuideToGDPR",
    async ({ id, data }: { id: number, data: GuideToGDPR }, thunkAPI) => {
        try {
            const response = await LexcelCqsColpCofaGdprService.PutLexcelCqsColpCofaGdpr(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteGuideToGDPR = createAsyncThunk(
    "deleteGuideToGDPR",
    async (id: number, thunkAPI) => {
        try {
            await LexcelCqsColpCofaGdprService.deleteLexcelCqsColpCofaGdpr(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const GuideToGDPRSlice = createSlice({
    name: "guideToGDPR",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllGuideToGDPR.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllGuideToGDPR.fulfilled, (state, action: PayloadAction<PaginationResponse<GuideToGDPR>>) => {
                state.status = "idle";
                state.guideToGDPRs = action.payload;
            })
            .addCase(fetchAllGuideToGDPR.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addGuideToGDPR.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addGuideToGDPR.fulfilled, (state, action: PayloadAction<GuideToGDPR>) => {
                state.status = "idle";
               // state.guideToGDPRs.push(action.payload);
            })
            .addCase(addGuideToGDPR.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateGuideToGDPR.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateGuideToGDPR.fulfilled, (state, action: PayloadAction<GuideToGDPR>) => {
                state.status = "idle";
                // const index = state.guideToGDPRs.findIndex(t => t.guideToGDPRId === action.payload.guideToGDPRId);
                // if (index !== -1) {
                //     state.guideToGDPRs[index] = action.payload;
                // }
            })
            .addCase(updateGuideToGDPR.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteGuideToGDPR.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteGuideToGDPR.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.guideToGDPRs = state.guideToGDPRs.filter(t => t.guideToGDPRId !== action.payload);
            })
            .addCase(deleteGuideToGDPR.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default GuideToGDPRSlice.reducer;
