// import axios from 'axios';


import axiosInstance from "../axiosInstance";



const fetchAllTitle = async () => {
  try {
    const response = await axiosInstance.get("Title");
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Title: ${error}`);
  }
}
const TitleService = {
  fetchAllTitle,
};

export default TitleService;
