import axiosInstance from '../axiosInstance';
import { ErrorResult } from '../../store/model/errorResult';



const GetAllCheckList = async () => {
    try {
        const response = await axiosInstance.get("/Checklist");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch Checklist:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch Checklist");
    }
}

// Add a new Checklist
const PostCheckList = async (data: any) => {
    try {
        const response = await axiosInstance.post("/Checklist", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostCheckListItem = async (data: any) => {
    try {
        const response = await axiosInstance.post("Checklist/item", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetCheckListData = async () => {
    try {
        const response = await axiosInstance.post("searchchecklist", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetCheckListItemData = async (data: any) => {
    try {
        const response = await axiosInstance.post("searchchecklistItem", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Update a Checklist
const PutCheckList = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`/Checklist/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Delete a Checklist
const deleteCheckList = async (checklistId: number) => {
    try {
        await axiosInstance.delete(`/Checklist/${checklistId}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const ChecklistService = {
    GetAllCheckList,
    PostCheckList,
    PutCheckList,
    deleteCheckList,
    PostCheckListItem,
    GetCheckListData,
    GetCheckListItemData
};


export default ChecklistService;
