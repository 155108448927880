import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ContactGroupService from "../../../../../services/CaseManagement/Case/ContactGroup/ContactGroupService";


export interface ContactGoup {
    contactGroupId: number;
    groupName: string;
    groupSlug: string;
    description: string;
    fkAreasOfPracticeIds: string;
    isActive: boolean;
};

// Define the state type for Client
type ContactGoupState = {
    contactGoups: ContactGoup[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ContactGoupState = {
    contactGoups: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchContactgroup = createAsyncThunk(
    "fetchContactgroup",
    async ({ id }: { id: number }, thunkAPI) => {
        try {
            const response = await ContactGroupService.GetContactgroup(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const ContactGroupSlice = createSlice({
    name: "contactGoup",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchContactgroup.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchContactgroup.fulfilled, (state, action: PayloadAction<ContactGoup[]>) => {
                state.status = "idle";
                state.contactGoups = action.payload;
            })
            .addCase(fetchContactgroup.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
    }
});

export default ContactGroupSlice.reducer;
