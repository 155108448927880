import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetNextCreditNoteRefundNumber = async () => {
    try {
        const response = await axiosInstance.get("CACreditNoteRefundNextNumber");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetCARefundCreditNoteServiceSearch = async () => {
    try {
        const response = await axiosInstance.post("CreditNoteRefundSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostCreditNoteRefund = async (data: any) => {
    try {
        const response = await axiosInstance.post("CACreditNoteRefundSave", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const deleteCreditNoteRefund = async (id: number) => {
    try {
        const response = await axiosInstance.delete(`/CACreditNoteRefundDelete/${id}`);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


// const PutProfessionalFee = async (id: number, data: any) => {
//     try {
//         const response = await axiosInstance.put(`NewInvoice/${id}`, data);
//         return response.data;
//     } catch (error: any) {
//         let data = error as ErrorResult;
//         throw new Error(data.messages.toString());
//     }
// }


const CARefundCreditNoteService = {
    GetCARefundCreditNoteServiceSearch,
    GetNextCreditNoteRefundNumber,
    PostCreditNoteRefund,
    deleteCreditNoteRefund
    // PutProfessionalFee,
    // GetProfessionalFeeSearch
    //deleteNewInvoice
};


export default CARefundCreditNoteService;
