import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetAllReportBankReconcilliation = async (data: any) => {
    try {
        const response = await axiosInstance.post("officeaccount/officereport/reconcilliation", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const ReportBankReconcilliationService = {
    GetAllReportBankReconcilliation,
   
};


export default ReportBankReconcilliationService;
