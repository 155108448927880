import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ContactCategoryService from "../../../services/CaseManagement/ContactCategoryService";

export interface ContactCategory  {
    contactCategoryId: number;
    contactCategory: string;
    fkContactTypeId: number;
    notes: string;
    isActive: boolean;
};

// Define the state type for ContactCategory
type ContactCategoryState = {
    contactCategorys: ContactCategory[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ContactCategoryState = {
    contactCategorys: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllContactCategory = createAsyncThunk(
    "fetchAllContactCategory",
    async (_, thunkAPI) => {
        try {
            const response = await ContactCategoryService.GetAllContactCategory();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// export const addBranch = createAsyncThunk(
//     "addBranch",
//     async (newBranch: Omit<Branch, 'branchId'>, thunkAPI) => {
//         try {
//             const response = await BranchManagementService.addBranch(newBranch);
//             return response;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// export const updateBranch = createAsyncThunk(
//     "updateBranch",
//     async (updatedBranch: Branch, thunkAPI) => {
//         const { branchId, ...data } = updatedBranch;
//         try {
//             const response = await BranchManagementService.updateBranch(branchId, data);
//             return response;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// export const deleteBranch = createAsyncThunk(
//     "deleteBranch",
//     async (BranchId: number, thunkAPI) => {
//         try {
//             await BranchManagementService.deleteBranch(BranchId);
//             return BranchId;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// Slice definition
const ContactCategorySlice = createSlice({
    name: "contactCategory",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllContactCategory.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllContactCategory.fulfilled, (state, action: PayloadAction<ContactCategory[]>) => {
                state.status = "idle";
                state.contactCategorys = action.payload;
            })
            .addCase(fetchAllContactCategory.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            // .addCase(addBranch.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(addBranch.fulfilled, (state, action: PayloadAction<Branch>) => {
            //     state.status = "idle";
            //     state.branches.push(action.payload);
            // })
            // .addCase(addBranch.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // })
            // .addCase(updateBranch.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(updateBranch.fulfilled, (state, action: PayloadAction<Branch>) => {
            //     state.status = "idle";
            //     const index = state.branches.findIndex(t => t.branchId === action.payload.branchId);
            //     if (index !== -1) {
            //         state.branches[index] = action.payload;
            //     }
            // })
            // .addCase(updateBranch.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // })
            // .addCase(deleteBranch.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(deleteBranch.fulfilled, (state, action: PayloadAction<number>) => {
            //     state.status = "idle";
            //     state.branches = state.branches.filter(t => t.branchId !== action.payload);
            // })
            // .addCase(deleteBranch.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // });
    }
});

export default ContactCategorySlice.reducer;
