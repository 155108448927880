import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { deleteFinancialYear, fetchAllFinancialYear } from "../../store/slice/Accounting/OfficeAccounts/GeneralSettings/FinancialYearSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
import DeleteModal from "../Modal/DeleteModal";
import { AddFinancialYear } from "../Modal/AccountSetup/AddFinancialYear";


function FinancialYear() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [financialYearData, setFinancialYearData] = useState<any>([]);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { financialYears, status, error } = useSelector((state: RootState) => state.financialYear);
    const [totalPages, setTotalPages] = useState(0);
    const [currentFinancialYear, setCurrentFinancialYear] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);
    const [openDelModal, setOpenDelModal] = useState(false);
    const [financialYearId, setFinancialYearId] = useState<number>(0);
    const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);



    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current || isOnAddedOrUpdate) {
            let data = {
                keyword: ''
            }
            dispatch(fetchAllFinancialYear(data))
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
            setOnAddedOrUpdate(false);
        }

        if (status === 'failed' && !errorShownRef.current) {
            toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, isOnAddedOrUpdate, status, error]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if (financialYears != null && (financialYears.dataList != null && financialYears.dataList.length > 0)) {
            const totalPages = financialYears.totalRows;
            setTotalPages(totalPages)
            setCurrentFinancialYear(financialYears.dataList)
            
        }

    }, [financialYears])

    console.log(currentFinancialYear);


    const toggleDrawer = (value: string) => {
        if (value === "success") {
            setOnAddedOrUpdate(true);
          }
        setIsEdit(false);
        setOpenModal(!openModal);
    };
    const onDeleteCall = () => {
        try {
          dispatch(deleteFinancialYear(financialYearId)).unwrap();
          setOpenDelModal(false)
          toast.success("Successfully Deleted financialYear");
        } catch (error: any) {
          toast.error(error.toString());
        }
      };
    
    

    return (
        <>
            <div className="">
            {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={financialYearId}
            title="Bank Receipt"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
                <div className="mb-5">
                    <SubHeaderTitle title="Financial Year" buttonName="Financial Year" isAdd={true} onClickAdd={toggleDrawer} />
                    {
                        (openModal) &&
                        <AddFinancialYear openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} financialYear={financialYearData} />
                    }
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Year" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-hidden sm:rounded-lg my-5">
                {initialLoading ? <MainAnimation /> : ""}
                    <div className="overflow-x-auto max-h-96">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="sticky top-0 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Financial Year ID
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Financial Year
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Accounting Method
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Is Completed
                                </th>
                                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-lg 3xl:text-xs">
                            {currentFinancialYear.map((financialYear: any, index) => {
                                return(
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {financialYear.financialYearId}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {formatDateOnlyDisplay(financialYear.startDate)} - {formatDateOnlyDisplay(financialYear.endDate)}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {financialYear.accountingMethod}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {(financialYear.isCompleted === 0) ? "No" : "Yes"}
                                    </td>
                                    <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <div className="flex gap-3 w-full">
                                            <FaEdit className="fill-blue-500 w-6 h-6" 
                                             onClick={() => {
                                                setOpenModal(true);
                                                setIsEdit(true);
                                                setFinancialYearData(financialYear);
                                              }}
                                            />
                                            <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" 
                                             onClick={() => {
                                                setOpenDelModal(true);
                                                setFinancialYearData(financialYear.financialYearId);
                                              }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )} )}
                        </tbody>

                    </table>
                    </div>
                    {currentFinancialYear.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No Financial Year Details found.</p>
                        </div>
                    )}
                </div>
                {currentFinancialYear.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            totalRecords={currentFinancialYear.length}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { FinancialYear };