import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";



const GetAllCaseTypeIncomeLedgerDropdown = async () => {
    try {
        const response = await axiosInstance.get("CaseTypeIncomeLedger/dropdown");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllCaseTypeIncomeLedger = async () => {
    try {
        const response = await axiosInstance.get("CaseTypeIncomeLedger/list");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}



const PostCaseTypeIncomeLedger = async (data: any) => {
    try {
        console.log(data);
        const response = await axiosInstance.post("CaseTypeIncomeLedger", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const CaseTypeIncomeLedgerService = {
    GetAllCaseTypeIncomeLedgerDropdown,
    PostCaseTypeIncomeLedger,
    GetAllCaseTypeIncomeLedger
};


export default CaseTypeIncomeLedgerService;
