import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllImportantDatesAndTask = async () => {
    try {
        const response = await axiosInstance.get("ImportantDateAndTask");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostImportantDatesAndTask = async (data: any) => {
    try {
        const response = await axiosInstance.post("ImportantDateAndTask", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutImportantDatesAndTask = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`ImportantDateAndTask`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteImportantDatesAndTask = async (id: number) => {
    try {
        await axiosInstance.delete(`/SaveImportantDateAndTask/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const ImportantDatesAndTaskService = {
    GetAllImportantDatesAndTask,
    PostImportantDatesAndTask,
    PutImportantDatesAndTask,
    deleteImportantDatesAndTask
};


export default ImportantDatesAndTaskService;
