import { SubHeaderTitle } from "../SubHeaderTitle";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useEffect, useRef, useState } from "react";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { fetchAllIncomeReport } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/IncomeReportSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SelectBox } from "../SelectBox";
import { toast } from "react-toastify";

function IncomeReport() {
  const today = new Date();
  const oneYearBefore = new Date();
  oneYearBefore.setFullYear(today.getFullYear() - 1);
  const formatDate = (date: Date) => date.toISOString().split("T")[0];
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(false);
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(formatDate(oneYearBefore));
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(formatDate(today));
  const incomeReports = useSelector((state: RootState) => state.incomeReport.incomeReports);
  const [selectedType, setSelectedType] = useState<string>("daterange");
  const [selectedIncomeType, setSelectedTIncomeype] = useState<string>("case");
  const [financialYearId, setFinancialYearId] = useState<string | null>(null);
  const [ledgerId, setLedgerId] = useState<number | null>(null);
  const [finacialYearList, setFinancialYearList] = useState<any[]>([]);
  const [disbursementAccounts, setdisbursementAccounts] = useState<any[]>([]);
  const { financialYears } = useSelector((state: RootState) => state.financialYear);
  const { nominalLedgerTypeRes, nominalLedgerAcList } = useSelector(
    (state: RootState) => state.nominalLedger
  );
  const companyProfiles = useSelector((state: RootState) => state.companyProfile.companyProfiles);

  useEffect(() => {
    if (financialYears != null && (financialYears.dataList != null && financialYears.dataList.length > 0)) {
      let data = financialYears.dataList.map((item: any) => ({
        value: item.financialYearId,
        label: `${item.startDate.split("T")[0]} - ${item.endDate.split("T")[0]}`,
      }));
      setFinancialYearList(data);
    }
  }, [financialYears]);

  useEffect(() => {
    if (nominalLedgerTypeRes !== null && nominalLedgerTypeRes.disbursementAccounts != null && nominalLedgerTypeRes.disbursementAccounts.length > 0) {
      let data = nominalLedgerTypeRes.disbursementAccounts.map(
        (item: any) => ({
          value: item.accountId,
          label: item.accountName,
        })
      );
      setdisbursementAccounts(data);
    }
  }, [financialYears]);

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value);
  };
  const handleIncomeRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTIncomeype(event.target.value);
  };

  const handleSearchClick = async () => {
    setInitialLoading(true);
    let data: any = {
      searchType: selectedType,
      incomeType: selectedIncomeType,
    };

    if (selectedType === "daterange") {
      data = {
        ...data,
        startDate: plannedStartDate,
        endDate: plannedEndDate,
      };
    } else if (selectedType === "financialyear") {
      data = {
        ...data,
        financialYearId: financialYearId,
      };
    }

    if (selectedIncomeType === "ledger") {
      data = {
        ...data,
        ledgerId: ledgerId,
      };
    } else if (selectedIncomeType === "case") {
      data = {
        ...data,
      };
    }

    try {
      await dispatch(fetchAllIncomeReport(data)).unwrap();
      toast.success("Successfully Search Profit");
    } catch (error: any) {
      toast.error(error?.message || "An error occurred while fetching data");
    } finally {
      setInitialLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="flex items-center justify-between mb-5">
          <div>
          <SubHeaderTitle title="Income Report" />
          </div>
          <div className="flex gap-x-2 ietms-center justify-end">
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-sm"
          >
            <IoIosPrint className="w-5 h-5" />
            Print
          </button>
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-sm"
          >
            <BsFileEarmarkExcelFill className="w-5 h-5" />
            Export to Excel
          </button>
        </div>
        </div>
        <div className="">
          <div className="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-x-10 gap-y-2 mb-5">
            <div className="border rounded-md p-2 space-y-2">
              <div className="mb-2">
                <label className="text-sm font-medium">Period</label>
              </div>
              <div className="grid grid-cols-2 items-center gap-x-12 pb-2">
                <div className="flex items-center gap-3">
                  <input
                    id="date-range-radio"
                    type="radio"
                    value="daterange"
                    name="date-type"
                    checked={selectedType === "daterange"}
                    onChange={handleRadioChange}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <JustifyDateFilter
                    title="Date Range"
                    plannedStartDate={plannedStartDate}
                    plannedEndDate={plannedEndDate}
                    onPlannedStartDateChange={handleStartDateChange}
                    onPlannedEndDateChange={handleEndDateChange}
                  />
                </div>
                <div className="flex items-center gap-3">
                  <input
                    type="radio"
                    value="financialyear"
                    name="date-type"
                    checked={selectedType === "financialyear"}
                    onChange={handleRadioChange}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <div className="w-72">
                    <SelectBox
                      indicator={
                        <svg
                          width="15"
                          className="fill-current mr-2"
                          height="15"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512">
                          <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                        </svg>
                      }
                      isMulti={false}
                      name="frameFiftySix"
                      options={finacialYearList}
                      isSearchable={true}
                      placeholder="FinacialYear"
                      shape="round"
                      color="indigo_50"
                      size="xs"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setFinancialYearId(selectedOption);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="border rounded-md p-2 space-y-2">
              <div className="mb-2">
                <label className="text-sm font-medium">Income Types</label>
              </div>
              <div className="grid grid-cols-2 gap-x-12 pb-2">
                <div className="flex items-center gap-3">
                  <input
                    type="radio"
                    value="case"
                    name="date-type"
                    checked={selectedType === "case"}
                    onChange={handleIncomeRadioChange}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <CheckBoxFilter title="Case Type" />
                </div>
                <div className="flex items-center gap-3">
                  <input
                    type="radio"
                    value="ledger"
                    name="date-type"
                    checked={selectedType === "ledger"}
                    onChange={handleIncomeRadioChange}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <div className="w-72">
                    <SelectBox
                      indicator={
                        <svg
                          width="15"
                          className="fill-current mr-2"
                          height="15"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512">
                          <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                        </svg>
                      }
                      isMulti={false}
                      name="frameFiftySix"
                      options={disbursementAccounts}
                      isSearchable={true}
                      placeholder="Income Ledger"
                      shape="round"
                      color="indigo_50"
                      size="xs"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setLedgerId(selectedOption);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-start-2 flex items-center justify-end">
              <button
                className="z-[2] inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                onClick={handleSearchClick}
                type="button">
                Search
              </button>
            </div>
          </div>
        </div>
        {initialLoading ? <MainAnimation /> : ""}
        
        <div className="my-5 text-center">
          <h1 className="text-2xl font-bold">{companyProfiles?.companyName}</h1>
          <p className="text-lg font-semibold">Income Ledger - All</p>
          <p className="font-semibold">06 Sep 2024 - 06 Sep 2024</p>
        </div>
        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto max-h-96">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Income Type
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Balance
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Percentage
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {incomeReports?.incomes.length > 0 && incomeReports?.incomes.map(
                (incomeReport: any, index) => (
                  <tr
                    key={index}
                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {incomeReport.accountName}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {incomeReport.runningBalance}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">

                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          </div>
          {incomeReports?.incomes.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">
                No Office Income Report Details found.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export { IncomeReport };
