import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddFeeTypes } from "../Modal/AccountSetup/AddFeeTypes";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import {
  deleteFeeType,
  fetchAllFeeType,
} from "../../store/slice/Accounting/AccountSetup/FeeTypeSlice";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { FaEdit } from "react-icons/fa";
import { Pagination } from "../Pagination";
import { MdDelete } from "react-icons/md";
import { SubHeaderTitle } from "../SubHeaderTitle";
import DeleteModal from "../Modal/DeleteModal";
import { NewRadioButtonFilter } from "../Filters/RadioButtonFilter";


const isActiveStatus = [
  { id: 1, label: "Active" },
  { id: 2, label: "InActive" },
];


const FeeTypes = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const { feeTypes, status, error } = useSelector(
    (state: RootState) => state.feeType
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [feeTypeData, setFeeTypeData] = useState<any>([]);
  const [feeTypeId, setFeeTypeId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [openDelModal, setOpenDelModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || status === "Added") {
      dispatch(fetchAllFeeType())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }

    if (status === "failed" && !errorShownRef.current) {
      // toast.error(error);
      errorShownRef.current = true;
    }
  }, [dispatch, status, error]);

  

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };



 // Handle the radio button change
 const handleRadioButtonChange = (status: number | null) => {
  setSelectedStatus(status);
  console.log("Selected Status:", status);
}

  // Function to filter fee types by search query
  const filterFeeTypes = (data: any[]) => {
    return data.filter((feeType: any) => {
      console.log(feeType)
      // Handle potential undefined values using optional chaining
      const feeTypess = feeType?.feeType?.toLowerCase() || "";
      const vatCode = feeType?.vatType?.toLowerCase() || "";
      const isActiveStatus = feeType?.isActive ? 1 : 2;
     
      // Check if the fee type or VAT code matches the search query
      const matchesSearch =
        searchQuery.trim() === "" ||
        feeTypess.includes(searchQuery.toLowerCase()) ||
        vatCode.includes(searchQuery.toLowerCase());
  
      // Check if the fee type matches the selected filter (active/inactive)
      const matchesStatus =
        selectedStatus === null || selectedStatus === isActiveStatus;
  
      return matchesSearch && matchesStatus;
    });
  };
  
 

    // Apply search filter and pagination
    const filteredFeeTypes = filterFeeTypes(feeTypes);

    
    const indexOfLastFeeType = currentPage * itemsPerPage;
    const indexOfFirstFeeType = indexOfLastFeeType - itemsPerPage;
    const currentFeeType = filteredFeeTypes.slice(
      indexOfFirstFeeType,
      indexOfLastFeeType
    );
  


  const totalPages = Math.ceil(feeTypes.length / itemsPerPage);

  const toggleDrawer = () => {
    setIsEdit(false);
    setOpenModal(!openModal);
  };
  const onDeleteCall = () => {
    try {
      dispatch(deleteFeeType(feeTypeId)).unwrap();
      setOpenDelModal(false)
      toast.success("Successfully Deleted FeeTypes");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  
  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            onDeleteAccept={onDeleteCall}
            id={feeTypeId}
            title="demo.laitlegal.uk.says"
            message="Are you sure?"
          />
        )}
        <div className=" w-full flex flex-col gap-2">
          <div className="mb-5">
            <SubHeaderTitle
              title="Fee Types"
              buttonName="Fee Type"
              isAdd={true}
              onClickAdd={toggleDrawer}
            />
            {openModal && (
              <AddFeeTypes
                openDrawer={openModal}
                Close={toggleDrawer}
                isEdit={isEdit}
                feeType={feeTypeData}
              />
            )}
          </div>
          <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
            <SearchInput
              title="Search FeeType"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <div>
              <div className="flex gap-4 md:gap-1 w-full">
                {/* <NewCheckBoxFilter title="Is Active" data={isActiveStatus} showSearchInput={false} onFilterChange={handleFilterChange} /> */}
                <NewRadioButtonFilter
                  title="Is Active"
                  data={isActiveStatus}
                  showSearchInput={false}
                  onFilterChange={handleRadioButtonChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg my-5">
          <div className="overflow-x-auto max-h-96">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="sticky top-0 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-800 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Fee Type
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  VAT Code
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Is Active
                </th>
                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Edit
                </th>
                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentFeeType.map((feeType: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{feeType.feeType}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{feeType.vatType}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {feeType.isActive === true ? "Active" : "Inactive"}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 cursor-pointer">
                    <FaEdit
                      className="fill-blue-500 w-6 h-6"
                      onClick={() => {
                        setOpenModal(true);
                        setIsEdit(true);
                        setFeeTypeData(feeType);
                      }}
                    />
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 cursor-pointer">
                    <MdDelete
                      className="fill-red-600 w-6 h-6"
                      onClick={() => {
                        setOpenDelModal(true);
                        setFeeTypeId(feeType.feeTypeId);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          {currentFeeType.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No FeeType found.</p>
            </div>
          )}
        </div>
        {currentFeeType.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={currentFeeType.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export { FeeTypes };
