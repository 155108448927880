import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import FinalizeService from "../../../../../services/Accounting/ClientAccounts/Invoice/FinalizeService";



export interface Finalize {
    finalizeId: number;
    areasofPractice: string;
    caseType: string;
    caseSubType: string;
    status : string;
    dateBetween: string;
    keyword: string;


};
// Define the state type for Checklist
type FinalizeState = {
    finalizes: Finalize[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

//Initial state
const initialState: FinalizeState = {
    finalizes: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllFinalize = createAsyncThunk(
    "fetchAllFinalize",
    async (_, thunkAPI) => {
        try {
            const response = await FinalizeService.GetAllFinalize();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateFinalize = createAsyncThunk(
    "updateFinalize",
    async ({ id, data }: { id: number, data: Finalize }, thunkAPI) => {
        try {
            const response = await FinalizeService.PutFinalize(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// export const deleteFinalize = createAsyncThunk(
//     "deleteFinalize",
//     async (id: number, thunkAPI) => {
//         try {
//             await FinalizeService.deleteFinalize(id);
//             return id;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );


const FinalizeSlice = createSlice({
    name: "finalize",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllFinalize.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllFinalize.fulfilled, (state, action: PayloadAction<Finalize[]>) => {
                state.status = "idle";
                state.finalizes = action.payload;
            })
            .addCase(fetchAllFinalize.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateFinalize.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateFinalize.fulfilled, (state, action: PayloadAction<Finalize>) => {
                state.status = "idle";
                const index = state.finalizes.findIndex(t => t.finalizeId === action.payload.finalizeId);
                if (index !== -1) {
                    state.finalizes[index] = action.payload;
                }
            })
            .addCase(updateFinalize.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            // .addCase(deleteFinalize.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(deleteFinalize.fulfilled, (state, action: PayloadAction<number>) => {
            //     state.status = "idle";
            //     state.finalizes = state.finalizes.filter(t => t.authorisationId !== action.payload);
            // })
            // .addCase(deleteFinalize.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // });

        }

        });
 

export default FinalizeSlice.reducer;
