import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";






const GetAllOfficeVatRate = async () => {
    try {
        const response = await axiosInstance.get("OfficeAccount/vattypes");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const OfficeVatRateService = {
    GetAllOfficeVatRate,
   
};


export default OfficeVatRateService;
