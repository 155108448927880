import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AdministratorMenuData, MenuItem } from "./AdministratorMenuData";
import AuthService from "../../services/auth.service";

function RightDrawer({
  openDrawer,
  onClose,
  onLogout,
}: {
  openDrawer: boolean;
  onClose: any;
  onLogout: any;
}) {
  const location = useLocation();
  const [dark, setDark] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [fullName, setFullName] = useState<string>("");
  const [designation, setDesignation] = useState<string>("");

  useEffect(() => {
    setFullName(AuthService.getUserName());
    setDesignation(AuthService.getDesignation());
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const darkModeHandler = () => {
    const newDarkModeState = !dark;
    setDark(newDarkModeState);
    document.body.classList.toggle("dark", newDarkModeState);
  };

  // const dropdownRef = useRef<HTMLDivElement>(null);
  // useOutsideClick({
  //     ref: dropdownRef,
  //     handler: () => onClose(fas),
  // });

  return (
    <>
      <div
        className={`fixed top-0 right-0 z-40 h-auto bottom-0 border-none  p-3 overflow-y-auto transition-transform duration-300 ${
          openDrawer
            ? "translate-x-0 duration-300"
            : "duration-300 translate-x-full"
        } bg-white-bg w-1/4 md:w-full dark:bg-black drop-shadow-xl border overflow-y-auto duration-500`}
        aria-labelledby="drawer-right-label"
      >
        <div className="top-0 fixed flex md:flex-col flex-row md:h-auto md:p-2 w-full">
          <ul className="inline-flex items-start justify-start space-x-1 md:space-x-2 rtl:space-x-reverse sm:mb-0">
            <li>
              <button
                className="text-white font-medium rounded-lg text-sm p-2.5"
                type="button"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  className="fill-black dark:fill-white-bg"
                  width="25"
                  height="25"
                  fill="red"
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </button>
            </li>
          </ul>
        </div>
        <div className="fle flex-col gap-9">
          <div
            className="z-50 flex flex-col my-4 text-base list-none bg-white divide-y divide-gray-100 dark:bg-black dark:divide-gray-600 dark:text-white-bg"
            id="user-dropdown"
          >
            <div className="flex justify-between px-4 py-3">
              <div>
                <img
                  className="w-12 h-12 rounded-full"
                  src="/images/header/user_profile.png"
                  alt="user"
                />
                <span className="top-10 left-16 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
              </div>

              <div className="">
                <span className="block text-base font-semibold text-gray-900 dark:text-white-bg">
                  {fullName}
                </span>
                <span className="block text-sm  text-gray-500 truncate dark:text-white-bg">
                  {designation}
                </span>
              </div>

              <div className="w-6 h-6 py-3 cursor-pointer dark:fill-white-bg">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                </svg>
              </div>
            </div>

            <ul
              className="py-1 cursor-pointer"
              aria-labelledby="user-menu-button"
            >
              {/*
                            <li>
                                 <button className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5" onClick={() => darkModeHandler()}>
                                    {

                                        dark && <FontAwesomeIcon icon={faSun} />
                                    }
                                    {
                                        !dark && <FontAwesomeIcon icon={faMoon} />
                                    }
                                </button> 
                                
                            </li>*/}

              <Link to={"/myprofile"}>
              <li className="flex mx-4 my-2 hover:bg-gray-100 dark:hover:bg-gray-900 py-5 hover:rounded-lg hover:mx-4 hover:my-2">
                <div className="flex flex-row gap-2 item-start justify-start">
                  <svg
                    className="w-6 h-6 ml-7 dark:fill-white-bg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                  </svg>
                  <div className="block px-2 text-base font-semibold text-gray-700 dark:text-gray-200 dark:hover:text-white">
                    My Profile
                  </div>
                </div>
              </li></Link>
              <hr className="text-gray-600 px-5" />
              <li className="flex justify-between mx-4 my-2 hover:bg-gray-100 dark:hover:bg-gray-900 py-5 hover:rounded-lg hover:mx-4 hover:my-2">
                <div className="flex">
                  <svg
                    className="w-6 h-6  ml-7 dark:fill-white-bg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z" />
                  </svg>
                  <div className="block px-2 text-base font-semibold text-gray-700 dark:text-gray-200 dark:hover:text-white">
                    Dark Mode
                  </div>
                </div>

                <div className="py-2 mx-4">
                  <label className="cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      className="sr-only peer"
                      checked={dark}
                      onChange={() => darkModeHandler()}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white-bg after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all peer-checked:bg-green-700"></div>
                  </label>
                </div>
              </li>
              <hr className="text-gray-300 px-5" />
              <li
                className=" cursor-pointer flex flex-col mx-4 my-2 hover:bg-gray-100 dark:hover:bg-gray-900 py-5 hover:rounded-lg hover:mx-4 hover:my-2"
                onClick={() => {
                  toggleDropdown();
                }}
              >
                <div className="flex flex-row gap-2 item-start justify-start">
                  <svg
                    className="w-6 h-6 ml-7 dark:fill-white-bg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                  </svg>
                  <div className="block px-2 text-base font-semibold text-gray-700 dark:text-gray-200 dark:hover:text-white">
                    Administrator Settings
                  </div>
                </div>
                <ul
                  className={`px-9 mt-4 space-y-1 ${
                    isOpen
                      ? "opacity-100 transform duration-500 translate-y-0"
                      : "opacity-0 transform -translate-y-2 duration-500 hidden"
                  }`}
                >
                  {AdministratorMenuData.map(
                    (item: MenuItem, index: number) => (
                      <li
                        key={index}
                        className={`flex flex-row gap-2 rounded-lg hover:bg-hover_bg hover:text-white-bg ${
                          location.pathname === item.path
                            ? "bg-hover_bg  text-white-bg"
                            : ""
                        }`}
                      >
                        <div className="w-full" onClick={onClose}>
                          <Link to={item.path}>
                            <div
                              className={`flex flex-row gap-2 p-3 rounded-lg`}
                            >
                              {item.title}
                            </div>
                          </Link>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </li>
              <li className="flex mx-4 my-2 hover:bg-gray-100 dark:hover:bg-gray-900 py-5 hover:rounded-lg hover:mx-4 hover:my-2">
                <svg
                  className="w-6 h-6 my-2 ml-7 dark:fill-white-bg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                </svg>
                <a
                  href="#!"
                  className="block px-4 py-2 text-base font-semibold text-gray-700 dark:text-gray-200 dark:hover:text-white"
                >
                  Help
                </a>
              </li>
              <hr className="text-gray-300 px-5" />

              <li className="flex mx-4 my-2 hover:bg-gray-100 dark:hover:bg-gray-900 py-5 hover:rounded-lg hover:mx-4 hover:my-2">
                <svg
                  className="w-6 h-6 my-2 ml-7 dark:fill-white-bg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" />
                </svg>
                <a
                  onClick={onLogout}
                  href="#!"
                  className="block px-4 py-2 text-base font-semibold text-gray-700 dark:text-gray-200 dark:hover:text-white"
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export { RightDrawer };
