import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import QuotationRequestStatusTypeService from "../../../../services/RiskManagement/RiskAssessment/QuotationRequestStatusTypeService";



export interface QuotationRequestStatusType {
    quotationRequestStatusTypeId: number;
    quotationRequestStatus: string;
    isActive: boolean;
};

// Define the state type for casetype
type QuotationRequestStatusTypeState = {
    quotationRequestStatusType: QuotationRequestStatusType[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: QuotationRequestStatusTypeState = {
    quotationRequestStatusType: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllQuotationRequestStatusType = createAsyncThunk(
    "fetchAllQuotationRequestStatusType",
    async (_, thunkAPI) => {
        try {
            const response = await QuotationRequestStatusTypeService.GetAllQuotationRequestStatusType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const QuotationRequestStatusTypeSlice = createSlice({
    name: "quotationRequestStatusType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllQuotationRequestStatusType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllQuotationRequestStatusType.fulfilled, (state, action: PayloadAction<QuotationRequestStatusType[]>) => {
                state.status = "idle";
                state.quotationRequestStatusType = action.payload;
            })
            .addCase(fetchAllQuotationRequestStatusType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
    }
});

export default QuotationRequestStatusTypeSlice.reducer;
