import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../../model/Pagination/paginationClass";
import PreviewAndPublishSurveyService from "../../../../../services/HumanResources/Surveys/CreateSurvey/PreviewAndPublishSurveyService";

export interface PreviewAndPublishSurvey {

  previewAndPublishSurveyId: number;
}
// Define the state type for Checklist
type PreviewAndPublishSurveyState = {
    previewAndPublishSurveys: PaginationResponse<PreviewAndPublishSurvey> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: PreviewAndPublishSurveyState = {
    previewAndPublishSurveys: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllPreviewAndPublishSurvey = createAsyncThunk(
    "fetchAllPreviewAndPublishSurvey",
    async (data: any, thunkAPI) => {
        try {
            const response = await PreviewAndPublishSurveyService.GetAllPreviewAndPublishSurvey(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addPreviewAndPublishSurvey = createAsyncThunk(
    "addPreviewAndPublishSurvey",
    async (data: any, thunkAPI) => {
        try {
            const response = await PreviewAndPublishSurveyService.PostPreviewAndPublishSurvey(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updatePreviewAndPublishSurvey = createAsyncThunk(
    "updatePreviewAndPublishSurvey",
    async ({ id, data }: { id: number, data: PreviewAndPublishSurvey }, thunkAPI) => {
        try {
            const response = await PreviewAndPublishSurveyService.PutPreviewAndPublishSurvey(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deletePreviewAndPublishSurvey = createAsyncThunk(
    "deletePreviewAndPublishSurvey",
    async (id: number, thunkAPI) => {
        try {
            await PreviewAndPublishSurveyService.deletePreviewAndPublishSurvey(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const PreviewAndPublishSurveySlice = createSlice({
    name: "previewAndPublishSurvey",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllPreviewAndPublishSurvey.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllPreviewAndPublishSurvey.fulfilled, (state, action: PayloadAction<PaginationResponse<PreviewAndPublishSurvey>>) => {
                state.status = "idle";
                state.previewAndPublishSurveys = action.payload;
            })
            .addCase(fetchAllPreviewAndPublishSurvey.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addPreviewAndPublishSurvey.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addPreviewAndPublishSurvey.fulfilled, (state, action: PayloadAction<PreviewAndPublishSurvey>) => {
                state.status = "idle";
               // state.previewAndPublishSurveys.push(action.payload);
            })
            .addCase(addPreviewAndPublishSurvey.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updatePreviewAndPublishSurvey.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updatePreviewAndPublishSurvey.fulfilled, (state, action: PayloadAction<PreviewAndPublishSurvey>) => {
                state.status = "idle";
                // const index = state.previewAndPublishSurveys.findIndex(t => t.previewAndPublishSurveyId === action.payload.previewAndPublishSurveyId);
                // if (index !== -1) {
                //     state.previewAndPublishSurveys[index] = action.payload;
                // }
            })
            .addCase(updatePreviewAndPublishSurvey.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deletePreviewAndPublishSurvey.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deletePreviewAndPublishSurvey.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.previewAndPublishSurveys = state.previewAndPublishSurveys.filter(t => t.previewAndPublishSurveyId !== action.payload);
            })
            .addCase(deletePreviewAndPublishSurvey.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default PreviewAndPublishSurveySlice.reducer;
