import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import VatPeriodService from "../../../../../services/Accounting/OfficeAccount/GeneralSettings/VatPeriodService";
import { PaginationResponse } from "../../../../model/Pagination/paginationClass";



export interface VatPeriod {
    vatPeriodId : number;
    vatStartDate: Date;
    vatEndDate: Date;
    description : string;
    vatPeriod : string;
    accountingMethod : string;
  
};
// Define the state type for Checklist
type VatPeriodState = {
    vatPeriods: PaginationResponse<VatPeriod> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: VatPeriodState = {
    vatPeriods: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllVatPeriod = createAsyncThunk(
    "fetchAllVatPeriod",
    async (data: any, thunkAPI) => {
        try {
            const response = await VatPeriodService.GetAllVatPeriod(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addVatPeriod = createAsyncThunk(
    "addVatPeriod",
    async (data: any, thunkAPI) => {
        try {
            const response = await VatPeriodService.PostVatPeriod(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateVatPeriod = createAsyncThunk(
    "updateVatPeriod",
    async ({ id, data }: { id: number, data: VatPeriod }, thunkAPI) => {
        try {
            const response = await VatPeriodService.PutVatPeriod(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteVatPeriod = createAsyncThunk(
    "deleteVatPeriod",
    async (id: number, thunkAPI) => {
        try {
            await VatPeriodService.deleteVatPeriod(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const VatPeriodSlice = createSlice({
    name: "vatPeriod",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllVatPeriod.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllVatPeriod.fulfilled, (state, action: PayloadAction<PaginationResponse<VatPeriod>>) => {
                state.status = "idle";
                state.vatPeriods = action.payload;
            })
            .addCase(fetchAllVatPeriod.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addVatPeriod.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addVatPeriod.fulfilled, (state, action: PayloadAction<VatPeriod>) => {
                state.status = "idle";
                //state.vatPeriods.push(action.payload);
            })
            .addCase(addVatPeriod.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateVatPeriod.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateVatPeriod.fulfilled, (state, action: PayloadAction<VatPeriod>) => {
                state.status = "idle";
                // const index = state.vatPeriods.findIndex(t => t.vATPeriodNumber === action.payload.vATPeriodNumber);
                // if (index !== -1) {
                //     state.vatPeriods[index] = action.payload;
                // }
            })
            .addCase(updateVatPeriod.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteVatPeriod.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteVatPeriod.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                //state.vatPeriods = state.vatPeriods.filter(t => t.vATPeriodNumber !== action.payload);
            })
            .addCase(deleteVatPeriod.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default VatPeriodSlice.reducer;
