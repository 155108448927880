import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import OfficeJournalEntryService from "../../../../../services/Accounting/OfficeAccount/OfficePosting/OfficeJournalEntryService";
import OfficeFundTransferService from "../../../../../services/Accounting/OfficeAccount/OfficePosting/OfficeFundTransferService";



export interface OfficeFundTransfer {
    officeFundTransferId: number;
    accountToAccountTransactionId: number
    fromBankId: number;
    toBankId: number;
    amount: number;
    referenceNumber: number;
    transactionDescription: string;
    transactionDate: string;
    memo: string;


};

export interface OfficeFundTransferData {
    page: number;
    totalRows: number;
    listingData: []
}
// Define the state type for Checklist
type OfficeFundTransferState = {
    officeFundTransfers: OfficeFundTransfer[];
    officeFundTransferData: OfficeFundTransferData;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: OfficeFundTransferState = {
    officeFundTransfers: [],
    officeFundTransferData: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllOfficeFundTransfer = createAsyncThunk(
    "fetchAllOfficeFundTransfer",
    async (_, thunkAPI) => {
        try {
            const response = await OfficeFundTransferService.GetAllOfficeFundTransfer();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addOfficeFundTransfer = createAsyncThunk(
    "addOfficeFundTransfer",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeFundTransferService.PostOfficeFundTransfer(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateOfficeFundTransfer = createAsyncThunk(
    "updateOfficeFundTransfer",
    async ({ id, data }: { id: number, data: OfficeFundTransfer }, thunkAPI) => {
        try {
            const response = await OfficeFundTransferService.PutOfficeFundTransfer(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteOfficeFundTransfer = createAsyncThunk(
    "deleteOfficeFundTransfer",
    async (id: number, thunkAPI) => {
        try {
            await OfficeFundTransferService.deleteOfficeFundTransfer(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const OfficeFundTransferSlice = createSlice({
    name: "officeJournalEntry",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllOfficeFundTransfer.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllOfficeFundTransfer.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.officeFundTransferData = action.payload;
            })
            .addCase(fetchAllOfficeFundTransfer.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addOfficeFundTransfer.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addOfficeFundTransfer.fulfilled, (state, action: PayloadAction<OfficeFundTransfer>) => {
                state.status = "idle";
                state.officeFundTransfers.push(action.payload);
            })
            .addCase(addOfficeFundTransfer.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateOfficeFundTransfer.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateOfficeFundTransfer.fulfilled, (state, action: PayloadAction<OfficeFundTransfer>) => {
                state.status = "idle";
                const index = state.officeFundTransfers.findIndex(t => t.officeFundTransferId === action.payload.officeFundTransferId);
                if (index !== -1) {
                    state.officeFundTransfers[index] = action.payload;
                }
            })
            .addCase(updateOfficeFundTransfer.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteOfficeFundTransfer.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteOfficeFundTransfer.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.officeFundTransfers = state.officeFundTransfers.filter(t => t.officeFundTransferId !== action.payload);
            })
            .addCase(deleteOfficeFundTransfer.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default OfficeFundTransferSlice.reducer;
