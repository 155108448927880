import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllPostageMethod = async () => {
    try {
        const response = await axiosInstance.get("postregister/methods");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostageMethodService = {
    GetAllPostageMethod,
  
};


export default PostageMethodService;