// import axios from 'axios';


import axiosInstance from "../axiosInstance";



const fetchAllMenu = async () => {
  try {
    const response = await axiosInstance.get("Menu");
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch menu: ${error}`);
  }
}

const fetchAllOperations = async () => {
  try {
    const response = await axiosInstance.get("Menu/Operations");
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch menu: ${error}`);
  }
}
const MenuPermissionService = {
  fetchAllMenu,
  fetchAllOperations
};

export default MenuPermissionService;
