// import axios from 'axios';

import { ErrorResult } from "../../store/model/errorResult";
import axiosInstance from "../axiosInstance";

// // Use the absolute path from the public directory
// // const BASE_URL = 'http://localhost:3000/tasks.json';

// export type Task = {
//   id: number;
//   assignee: string;
//   task: string;
//   description: string;
//   plannedDate: string;  
//   dueDate: string;
//   assignedTo: string;
//   status: string;
// };

// class TaskService {
//   async fetchAllTasks(): Promise<Task[]> {
//     try {
//       const response = await axios.get<Task[]>(BASE_URL);
//       return response.data;
//     } catch (error) {
//       throw new Error(`Failed to fetch tasks: ${error}`);
//     }
//   }

//   // Add a new task
//   async addTask(newTask: Omit<Task, 'id'>): Promise<Task> {
//     try {
//       const tasks = await this.fetchAllTasks();
//       const newId = tasks.length ? Math.max(...tasks.map(t => t.id)) + 1 : 1;
//       const task = { ...newTask, id: newId };
//       tasks.push(task);
//       return task;
//     } catch (error) {
//       throw new Error(`Failed to add task: ${error}`);
//     }
//   }

//   // Update a task
//   async updateTask(updatedTask: Task): Promise<Task> {
//     try {
//       const tasks = await this.fetchAllTasks();
//       const taskIndex = tasks.findIndex(t => t.id === updatedTask.id);
//       if (taskIndex !== -1) {
//         tasks[taskIndex] = updatedTask;
//         return updatedTask;
//       }
//       throw new Error('Task not found');
//     } catch (error) {
//       throw new Error(`Failed to update task: ${error}`);
//     }
//   }

//   // Delete a task
//   async deleteTask(taskId: number): Promise<void> {
//     try {
//       const tasks = await this.fetchAllTasks();
//       const newTasks = tasks.filter(t => t.id !== taskId);
//       if (tasks.length === newTasks.length) {
//         throw new Error('Task not found');
//       }
//       // Simulate deletion success by returning nothing
//     } catch (error) {
//       throw new Error(`Failed to delete task: ${error}`);
//     }
//   }
// }

// export default new TaskService();

const GetAllTask = async (data: any) => {
  try {
      const response = await axiosInstance.post("task/search", data);
      return response.data;
  } catch (error: any) {
      let data = error as ErrorResult;
      throw new Error(data.messages.toString());
  }
}


const PostTask= async (data: any) => {
  try {
      const response = await axiosInstance.post("/task/add", data);
      return response.data;
  } catch (error: any) {
      let data = error as ErrorResult;
      throw new Error(data.messages.toString());
  }
}


const PutTask = async (id: number, data: any) => {
  try {
      const response = await axiosInstance.put(`Task/${id}`, data);
      return response.data;
  } catch (error: any) {
      let data = error as ErrorResult;
      throw new Error(data.messages.toString());
  }
}


const deleteTask = async (id: number) => {
  try {
      await axiosInstance.delete(`/Task/${id}`);
  } catch (error: any) {
      let data = error as ErrorResult;
      throw new Error(data.messages.toString());
  }
}

const TaskService = {
  GetAllTask,
  PostTask,
  PutTask,
  deleteTask,
};


export default TaskService;