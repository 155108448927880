import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";




const GetAllQuotationNotice = async () => {
    try {
        const response = await axiosInstance.get("QuotationNote");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostQuotationNotice = async (data: any) => {
    try {
        const response = await axiosInstance.post("QuotationNote", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutQuotationNotice = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`QuotationNote/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteQuotationNotice = async (id: number) => {
    try {
        await axiosInstance.delete(`/QuotationNote/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const QuotationNoticeService = {
    GetAllQuotationNotice,
    PostQuotationNotice,
    PutQuotationNotice,
    deleteQuotationNotice
};


export default QuotationNoticeService;
