import { ErrorResult } from "../../store/model/errorResult";
import axiosInstance from "../axiosInstance";

const GetAllTaskStatus = async () => {
    try {
        const response = await axiosInstance.get("/task/status");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const TaskStatusService = {
    GetAllTaskStatus,
    
};


export default TaskStatusService;