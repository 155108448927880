import React from 'react';

interface HeadingProps {
    title: string;
    select?: React.ReactNode;
    Data?: string;

}
const Heading: React.FC<HeadingProps> = ({ title, select, Data }) => {

    return (
        <>
            <div className='flex items-center justify-between dark:text-white-bg mb-5 bg-gray-200 dark:bg-gray-900 p-2'>
                <h3 className="text-lg xl:text-base font-bold text-left text-gray-900 dark:text-white-bg">{title}</h3>
                {/* {(select != null) ? (
                         {select && <div>{select}</div>}
                ) : (
                    <h3>{Data}</h3>
                )} */}
                {select && <div>{select}</div>}

            </div>
        </>
    )
}

export { Heading };