import React, { useRef, useState, useEffect } from "react";
import useOutsideClick from "../../Dropdown";
import { IoIosArrowDown } from "react-icons/io";
import { FaSearch } from "react-icons/fa";

interface RadioButtonFilterProps {
  title: string;
  data: { id: number; label: string }[];
  showSearchInput?: boolean;
  onFilterChange: (selectedItem: number | null) => void;
}

const NewRadioButtonFilter: React.FC<RadioButtonFilterProps> = ({
  title,
  data,
  showSearchInput = true,
  onFilterChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  const dropdownRef = useRef<HTMLDivElement>(null);

  // Toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close dropdown when clicking outside
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  // Handle radio button selection change
  const handleRadioChange = (id: number) => {
    setSelectedItem(id);
    onFilterChange(id);
    setIsOpen(false); // Close dropdown on selection
  };

  // Reset selected item
  const handleReset = () => {
    setSelectedItem(null);
    onFilterChange(null);
  };

  // Filter data based on search term
  const filteredData = data.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      className={`relative ${
        isOpen
          ? "text-hover_bg bg-gray-100 dark:bg-gray-900 rounded"
          : "bg-gray-100 dark:bg-gray-900 rounded"
      }`}
      ref={dropdownRef}
    >
      {/* Dropdown Header */}
      <div
        className="flex cursor-pointer items-center gap-1 p-1.5 dark:text-gray-300 fill-hover_bg transition border dark:border-gray-400 dark:border-opacity-35 rounded"
        onClick={toggleDropdown}
      >
        <span className="text-sm font-medium w-32 lg:w-44 xl:w-48">
          {title}
        </span>
        <span className={`transition ${isOpen ? "rotate-180" : ""}`}>
          <IoIosArrowDown className="h-4 w-4" />
        </span>
      </div>

      {/* Dropdown Content */}
      {isOpen && (
        <div className="z-50 absolute end-0 top-full mt-2 bg-gray-100 dark:bg-gray-900 w-72 rounded border border-gray-200 dark:border-gray-600">
          {/* Header with selected count and reset button */}
          <header className="flex items-center justify-between p-3">
            <span className="text-sm font-medium text-gray-700 dark:text-gray-400">
              {selectedItem !== null ? "1 Selected" : "0 Selected"}
            </span>
            <button
              type="button"
              onClick={handleReset}
              className="text-sm font-medium text-gray-700 dark:text-gray-400 underline underline-offset-4"
            >
              Reset
            </button>
          </header>

          {/* Conditionally render the Search Input */}
          {showSearchInput && (
            <div className="flex items-center justify-between p-3">
              <div className="relative w-full">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full px-10 py-2 border border-gray-300 rounded-md bg-white text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-400" />
              </div>
            </div>
          )}

          {/* Radio Button List */}
          <ul className="space-y-1 border-t dark:border-t-gray-400 border-t-gray-200 p-4 dark:bg-gray-900">
            {filteredData.length > 0 ? (
              filteredData.map((item) => (
                <li key={item.id}>
                  <label className="inline-flex items-center gap-2">
                    <input
                      type="radio"
                      checked={selectedItem === item.id}
                      onChange={() => handleRadioChange(item.id)}
                      className="size-5 rounded-full border-gray-300 dark:bg-gray-900"
                    />
                    <span className="text-sm font-medium text-gray-700 dark:text-gray-400">
                      {item.label}
                    </span>
                  </label>
                </li>
              ))
            ) : (
              <p className="text-sm text-gray-500 dark:text-gray-400">
                No results found.
              </p>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export { NewRadioButtonFilter };
