import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ProtectedFundService from "../../../../../services/Accounting/ClientAccounts/ClientPostings/ProtectedFundService";



export interface ProtectedFund {
    protectedFundId: number;
    fkCaseId: number;
    fkClientBankId: number;
    fkContactId: number;
    protectedAmount : number;
    comments: string;
    reason: string;  
    entryDate: string;
    protectedDateTime : string;
    paymentForFrom : string;
    caseReferenceAuto: string;
    protectedFundType : string;
};

export interface ProtectedFundGetData{
    protectedFundResult: [];
    recordPerPage: number;
    totalCount: number;
}
// Define the state type for Checklist
type ProtectedFundState = {
    protectedFundGetData: ProtectedFundGetData;
    protectedFunds: ProtectedFund[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    any: any;
};

// Initial state
const initialState: ProtectedFundState = {
    protectedFundGetData: null!,
    protectedFunds: [],
    status: "idle",
    error: null,
    any: null
};

// Async thunks for CRUD operations
export const fetchAllProtectedFund = createAsyncThunk(
    "fetchAllProtectedFund",
    async (_, thunkAPI) => {
        try {
            const response = await ProtectedFundService.GetAllProtectedFund();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addProtectedFund = createAsyncThunk(
    "addProtectedFund",
    async (data: any, thunkAPI) => {
        try {
            const response = await ProtectedFundService.PostProtectedFund(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addReverseProtectedFund = createAsyncThunk(
    "addReverseProtectedFund",
    async (data: any, thunkAPI) => {
        try {
            const response = await ProtectedFundService.PostReverseProtectedFund(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateProtectedFund = createAsyncThunk(
    "updateProtectedFund",
    async ({ id, data }: { id: number, data: ProtectedFund }, thunkAPI) => {
        try {
            const response = await ProtectedFundService.PutProtectedFund(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteProtectedFund = createAsyncThunk(
    "deleteProtectedFund",
    async (id: number, thunkAPI) => {
        try {
            await ProtectedFundService.deleteProtectedFund(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const ProtectedFundSlice = createSlice({
    name: "protectedFund",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllProtectedFund.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllProtectedFund.fulfilled, (state, action: PayloadAction<ProtectedFundGetData>) => {
                state.status = "idle";
                state.protectedFundGetData = action.payload;
            })
            .addCase(fetchAllProtectedFund.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addProtectedFund.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addProtectedFund.fulfilled, (state, action: PayloadAction<ProtectedFund>) => {
                state.status = "idle";
                state.protectedFunds.push(action.payload);
            })
            .addCase(addProtectedFund.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addReverseProtectedFund.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addReverseProtectedFund.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.any.push(action.payload);
            })
            .addCase(addReverseProtectedFund.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateProtectedFund.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateProtectedFund.fulfilled, (state, action: PayloadAction<ProtectedFund>) => {
                state.status = "idle";
                const index = state.protectedFunds.findIndex(t => t.protectedFundId === action.payload.protectedFundId);
                if (index !== -1) {
                    state.protectedFunds[index] = action.payload;
                }
            })
            .addCase(updateProtectedFund.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteProtectedFund.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteProtectedFund.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.protectedFunds = state.protectedFunds.filter(t => t.protectedFundId !== action.payload);
            })
            .addCase(deleteProtectedFund.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default ProtectedFundSlice.reducer;
