import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../../model/Pagination/paginationClass";
import CreateSurveyQuestionService from "../../../../../services/HumanResources/Surveys/CreateSurvey/CreateSurveyQuestionService";


export interface CreateSurveyQuestion {

  createSurveyQuestionId: number;
}
// Define the state type for Checklist
type CreateSurveyQuestionState = {
    createSurveyQuestions: PaginationResponse<CreateSurveyQuestion> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CreateSurveyQuestionState = {
    createSurveyQuestions: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllCreateSurveyQuestion = createAsyncThunk(
    "fetchAllCreateSurveyQuestion",
    async (data: any, thunkAPI) => {
        try {
            const response = await CreateSurveyQuestionService.GetAllCreateSurveyQuestion(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCreateSurveyQuestion = createAsyncThunk(
    "addCreateSurveyQuestion",
    async (data: any, thunkAPI) => {
        try {
            const response = await CreateSurveyQuestionService.PostCreateSurveyQuestion(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateCreateSurveyQuestion = createAsyncThunk(
    "updateCreateSurveyQuestion",
    async ({ id, data }: { id: number, data: CreateSurveyQuestion }, thunkAPI) => {
        try {
            const response = await CreateSurveyQuestionService.PutCreateSurveyQuestion(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCreateSurveyQuestion = createAsyncThunk(
    "deleteCreateSurveyQuestion",
    async (id: number, thunkAPI) => {
        try {
            await CreateSurveyQuestionService.deleteCreateSurveyQuestion(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CreateSurveyQuestionSlice = createSlice({
    name: "createSurveyQuestion",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCreateSurveyQuestion.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCreateSurveyQuestion.fulfilled, (state, action: PayloadAction<PaginationResponse<CreateSurveyQuestion>>) => {
                state.status = "idle";
                state.createSurveyQuestions = action.payload;
            })
            .addCase(fetchAllCreateSurveyQuestion.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCreateSurveyQuestion.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCreateSurveyQuestion.fulfilled, (state, action: PayloadAction<CreateSurveyQuestion>) => {
                state.status = "idle";
               // state.createSurveyQuestions.push(action.payload);
            })
            .addCase(addCreateSurveyQuestion.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateCreateSurveyQuestion.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCreateSurveyQuestion.fulfilled, (state, action: PayloadAction<CreateSurveyQuestion>) => {
                state.status = "idle";
                // const index = state.createSurveyQuestions.findIndex(t => t.createSurveyQuestionId === action.payload.createSurveyQuestionId);
                // if (index !== -1) {
                //     state.createSurveyQuestions[index] = action.payload;
                // }
            })
            .addCase(updateCreateSurveyQuestion.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCreateSurveyQuestion.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCreateSurveyQuestion.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.createSurveyQuestions = state.createSurveyQuestions.filter(t => t.createSurveyQuestionId !== action.payload);
            })
            .addCase(deleteCreateSurveyQuestion.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default CreateSurveyQuestionSlice.reducer;
