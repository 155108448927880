
import axiosInstance from '../axiosInstance';
import { ErrorResult } from '../../store/model/errorResult';



const GetAllDocumentFormat = async () => {
    try {
        const response = await axiosInstance.get("DocumentFormat");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch DocumentFormat:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch DocumentFormat");
    }
}

// Add a new DocumentFormat
const PostDocumentFormat = async (data: any) => {
    try {
        const response = await axiosInstance.post("DocumentFormat", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Update a DocumentFormat
const PutDocumentFormat = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`DocumentFormat/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Delete a DocumentFormat
const deleteDocument = async (documentFormatId: number) => {
    try {
        await axiosInstance.delete(`/DocumentFormat/${documentFormatId}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}
const DocumentFormatService = {
    GetAllDocumentFormat,
    PostDocumentFormat,
    PutDocumentFormat,
    deleteDocument,
};

export default DocumentFormatService;
