import { Modal } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";
import { FcCancel } from "react-icons/fc";
import { FaPlus } from "react-icons/fa6";
import { addImportDate, fetchImportantDateType } from "../../../../../store/slice/CaseManagement/Case/Correspondence/AddItem/CorrAddItemsSlice";
import { ImportDateData } from "../../../../../store/model/CaseManagement/CaseCorrAddItemInterface";
import { SelectBox } from "../../../../SelectBox";
import { FaSave } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function AddImportantDatesTasks({
  openDrawer,
  Close,
}: {
  openDrawer: boolean;
  Close: any;
}) {
  const dispatch = useAppDispatch();
  const { referenceNumber } = useParams();
  const caseDetailsData = useSelector((state: RootState) => state.case.caseDto);
  const importantDateType = useSelector((state: RootState) => state.corrAddItems.importantDateType);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [linesImportantDates, setLinesImportantDates] = useState<any[]>(
    []
  );
  const [importantDateTypeData, setImportantDateTypeData] = useState<any[]>([]);

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchImportantDateType(0));
    }
    return () => { effectServiceCall.current = true; };
  }, [dispatch]);

  useEffect(() => {
    if (importantDateType.length > 0) {
      const options = importantDateType.map((item: any) => ({
        label: item.importantDateType,
        value: item.importantDateId
      }));
      setImportantDateTypeData(options);
    }
  }, [importantDateType]);

  const addTimeEntryRow = () => {
    const newLine: ImportDateData = {
      dateTypeId: 0,
      caseId: Number(referenceNumber),
      eventStartDate: "",
      reminderDate: "",
    };
    setLinesImportantDates((prev) => [...prev, newLine]);
  };

  const removeRow = (index: number) => {
    setLinesImportantDates((prev) => prev.filter((_, i) => i !== index));
  };

  const handleInputChange = (index: number, field: keyof ImportDateData, value: any) => {
    setLinesImportantDates((prev) => {
      const updated = [...prev];
      updated[index][field] = value;
      return updated;
    });
  };

  const saveRowData = async (index: number) => {
    const rowData = linesImportantDates[index];
    console.log("Row Data Saved:", rowData);
    try {
      await dispatch(addImportDate(rowData)).unwrap();
      Close();
      toast.success("Successfully Added Importent Date");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
    }
  };


  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        Close();
      }}
      initialFocus={emailInputRef}
      size="2xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="dark:text-white-bg text-xl w-auto">
          Add Important Dates & Tasks
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <div className="space-y-3 w-full">
          <div className="bg-sky-50 dark:bg-gray-900 p-5 space-y-2">
            <div className="flex items-center">
              <label className="w-1/4 text-sm font-semibold dark:text-white-bg">
                Case Type
              </label>
              <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-3/4 text-sm dark:text-white-bg">
                {caseDetailsData?.area}
                {caseDetailsData?.caseType && ` > ${caseDetailsData.caseType}`}
                {caseDetailsData?.subCaseType && ` > ${caseDetailsData.subCaseType}`}
              </p>
            </div>
            <div className="flex items-center">
              <label className="w-1/4 text-sm font-semibold dark:text-white-bg">
                Case Ref
              </label>
              <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-3/4 text-sm dark:text-white-bg">
                {caseDetailsData?.caseReferenceAuto}
              </p>
            </div>
          </div>
          <button
            className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 mt-1"
            type="button"
            onClick={addTimeEntryRow}
          >
            <FaPlus className="w-4 h-4 fill-white-bg" />
            <span className="text-white-bg mx-2">Add Line</span>
          </button>
          <div className="relative overflow-x-auto sm:rounded-lg">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th scope="col" className="px-6 py-3">
                    Important Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date Time
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Reminder
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {linesImportantDates &&
                  linesImportantDates.map((row, index) => (
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="p-3">
                        <SelectBox
                          placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                          indicator={
                            <svg
                              width="15"
                              className="fill-current mr-2"
                              height="15"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                          }
                          isMulti={false}
                          name="frameFiftySix"
                          isSearchable={true}
                          placeholder="Select"
                          options={importantDateTypeData}
                          shape="round"
                          color="indigo_50"
                          size="xs"
                          onChange={(selectedOption) => {
                            if (selectedOption) {
                              handleInputChange(index, "dateTypeId", selectedOption);
                            }
                          }}
                        />
                      </td>
                      <td className="p-3">
                        <input
                          type="date"
                          value={row.eventStartDate}
                          onChange={(e) => handleInputChange(index, "eventStartDate", e.target.value)}
                          max={new Date().toISOString().split("T")[0]}
                          className={`border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full p-3 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        />
                      </td>
                      <td className="p-3">
                        <input
                          type="date"
                          value={row.reminderDate}
                          onChange={(e) => handleInputChange(index, "reminderDate", e.target.value)}
                          max={new Date().toISOString().split("T")[0]}
                          className={`border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full p-3 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        />
                      </td>
                      <td className="p-3 flex gap-2">
                        <button
                          type="button"
                          onClick={() => removeRow(index)}
                        >
                          <FcCancel className="w-6 h-6" />
                        </button>
                        <button
                          type="button"
                          onClick={() => saveRowData(index)}
                        >
                          <FaSave className="w-6 h-6" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export { AddImportantDatesTasks };
