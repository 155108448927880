import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddFixedFeeInvoicePayment } from "../Modal/AddOfficeAccounts/AddOfficePostings/AddFixedFeeInvoicePayment";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { deleteFixedFeeInvoicePayment, fetchAllFixedFeeInvoicePayment } from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/FixedFeeInvoicePaymentSlice";
import { toast } from "react-toastify";
import { Pagination } from "flowbite-react";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import DeleteModal from "../Modal/DeleteModal";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
function FixedFeeInvoicePayment() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [fixedFeeInvoicePaymentDatas, setFixedFeeInvoicePaymentDatas] =
    useState<any>([]);
  const [fixedFeeInvoicePaymentGetData, setFixedFeeInvoicePaymentGetData] =
    useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const fixedFeeInvoicePaymentData = useSelector(
    (state: RootState) =>
      state.fixedFeeInvoicePayment.fixedFeeInvoicePaymentData
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentFixedFeeInvoicePayment, setCurrentFixedFeeInvoicePayment] =
    useState<any[]>([]);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [fixedFeePaymentId, setFixedFeePaymentId] = useState<number>(0);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current ||isOnAddedOrUpdate ) {
      dispatch(fetchAllFixedFeeInvoicePayment())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
        setOnAddedOrUpdate(false);
      effectServiceCall.current = true;
    }
  }, [dispatch,isOnAddedOrUpdate]);

  useEffect(() => {
    if (fixedFeeInvoicePaymentData && fixedFeeInvoicePaymentData.listingData) {
      setFixedFeeInvoicePaymentGetData(fixedFeeInvoicePaymentData.listingData);
    }
  }, [fixedFeeInvoicePaymentData]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (
      fixedFeeInvoicePaymentGetData != null &&
      fixedFeeInvoicePaymentGetData.length > 0
    ) {
      const filteredByFixedFeeInvoicePayment =
        filterFixedFeeInvoicePaymentBySearchQuery();
      const indexOfLastFixedFeeInvoicePayment = currentPage * itemsPerPage;
      const indexOfFirstFixedFeeInvoicePayment =
        indexOfLastFixedFeeInvoicePayment - itemsPerPage;
      const currentFixedFeeInvoicePayment =
        filteredByFixedFeeInvoicePayment.slice(
          indexOfFirstFixedFeeInvoicePayment,
          indexOfLastFixedFeeInvoicePayment
        );
      const totalPages = Math.ceil(
        fixedFeeInvoicePaymentGetData.length / itemsPerPage
      );
      setTotalPages(totalPages);
      setCurrentFixedFeeInvoicePayment(currentFixedFeeInvoicePayment);
    }
  }, [fixedFeeInvoicePaymentGetData]);

  const filterFixedFeeInvoicePaymentBySearchQuery = () => {
    return fixedFeeInvoicePaymentGetData.filter((fixedFeeInvoicePayment: any) =>
      fixedFeeInvoicePayment?.accountName
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  };

  const onDeleteCall = () => {
    try {
      dispatch(deleteFixedFeeInvoicePayment(fixedFeePaymentId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted fixedFee Payment");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };
  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };
  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OAFixedFeeInvoicePayment"
        getData={fixedFeeInvoicePaymentDatas}
        title="Fixedfee Invoice Payment "
      />
    );
  }

    return (
        <>
            <div className="">
            {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={fixedFeePaymentId}
            title="FixedFee Payment"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
                <div className="mb-5">
                    <SubHeaderTitle title="Fixed Fee Invoice Payment" buttonName="Payment" isAdd={true} onClickAdd={toggleDrawer} />
                    <AddFixedFeeInvoicePayment openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} isView={isView} fixedFeeInvoicePayment={fixedFeeInvoicePaymentDatas} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Status" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-hidden sm:rounded-lg">
                    {initialLoading ? <MainAnimation /> : ""}
                    <div className="overflow-x-auto h-[calc(100vh-10px)]">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Invoicee (s)
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Invoice
                                </th>
                                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Total
                                </th>
                                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Amount Paid
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3 text-center w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-lg 3xl:text-xs">
                            {currentFixedFeeInvoicePayment.map((fixedFeeInvoicePayment: any, index) => (

                                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {formatDateOnlyDisplay(fixedFeeInvoicePayment.invoicePaymentDate)}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {fixedFeeInvoicePayment.invoiceeName}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <a href="" className="hover:text-blue-500"> {fixedFeeInvoicePayment.invoiceName}</a>
                                    </td>
                                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                         {formatCurrency(fixedFeeInvoicePayment.totalAmount)}
                                    </td>
                                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                         {formatCurrency(fixedFeeInvoicePayment.invoicePaymentAmount)}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {fixedFeeInvoicePayment.status}
                                    </td>
                                    <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <div className="flex gap-3 w-full">
                                            <FaEye className="fill-gray-500 w-6 h-6"
                                             onClick={() => {
                                              setOpenViewDrawer(true);
                                              setIsView(true);
                                              setIsEdit(false);
                                              setFixedFeeInvoicePaymentDatas(fixedFeeInvoicePayment);
                                            }} 
                                            />
                                            <FaEdit className="fill-blue-500 w-6 h-6"
                                             onClick={() => {
                                              setOpenModal(true);
                                              setIsEdit(true);
                                              setFixedFeeInvoicePaymentDatas(fixedFeeInvoicePayment);
                                            }}
                                             />
                                            <RiDeleteBin5Fill className="fill-red-600 w-6 h-6"
                                             onClick={() => {
                                              setOpenDelModal(true);
                                              setFixedFeePaymentId(fixedFeeInvoicePayment.fixedFeeInvoicePaymentId);
                                            }}
                                            />
                                        </div>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                    {currentFixedFeeInvoicePayment.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No Fixed FeeInvoice Payment Details found.</p>
                        </div>
                    )}
                </div>
                {currentFixedFeeInvoicePayment.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}

       
      </div>
    </>
  );
}

export { FixedFeeInvoicePayment };
