import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllNominalLedgerByTpes = async () => {
    try {
        const response = await axiosInstance.get("NominalLedger/bytypes");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAccountSetupNominalLedger = async () => {
    try {
        const response = await axiosInstance.get("NominalLedger/list");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const GetAllNominalLedger = async () => {
    try {
        const response = await axiosInstance.get("NominalLedger");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllNominalLedgerReceiable = async () => {
    try {
        const response = await axiosInstance.get("NominalLedger/OfficeReceiable");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}



const PostNominalLedger = async (data: any) => {
    try {
        const response = await axiosInstance.post("NominalLedger", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutNominalLedger = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`NominalLedger/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteNominalLedger = async (id: number) => {
    try {
        await axiosInstance.delete(`/NominalLedger/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const NominalLedgerService = {
    GetAccountSetupNominalLedger,
    GetAllNominalLedger,
    PostNominalLedger,
    PutNominalLedger,
    deleteNominalLedger,
    GetAllNominalLedgerByTpes,
    GetAllNominalLedgerReceiable
};


export default NominalLedgerService;
