import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetNextCreditNoteNumber = async () => {
    try {
        const response = await axiosInstance.get("CACreditNoteNextNumber");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetCreditNoteServiceSearch = async () => {
    try {
        const response = await axiosInstance.post("CreditNoteSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostCreditNote = async (data: any) => {
    try {
        const response = await axiosInstance.post("CACreditNoteSave", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


// const PutProfessionalFee = async (id: number, data: any) => {
//     try {
//         const response = await axiosInstance.put(`NewInvoice/${id}`, data);
//         return response.data;
//     } catch (error: any) {
//         let data = error as ErrorResult;
//         throw new Error(data.messages.toString());
//     }
// }

const deleteCreditNote = async (id: number) => {
    try {
        await axiosInstance.delete(`/CreditNote/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const CACreditNoteService = {
    GetCreditNoteServiceSearch,
    PostCreditNote,
    GetNextCreditNoteNumber,
    deleteCreditNote,
    // PutProfessionalFee,
    // GetProfessionalFeeSearch
    //deleteNewInvoice
};


export default CACreditNoteService;
