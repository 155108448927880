import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number, lazy } from "yup";
import { useEffect, useRef, useState } from "react";
import { SelectBox } from "../../../../SelectBox";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import {
  addBankNewPayment,
  addReverseBankNewPayment,
} from "../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/BankPayment/BankNewPaymentSlice";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { SearchCaseReference } from "../../../SearchCaseReference";
import { SearchContact } from "../../../SearchContact";
import { SearchSupplier } from "../../../SearchSupplier";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import { fetchCaseClient } from "../../../../../store/slice/Accounting";
import { SearchCaseClient } from "../../../SearchCaseClient";
import { fetchNextBankPaymentNumber } from "../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/BankPayment/CreatePaymentSlice";
import { NumericFormatWrapper } from "../../../../InputComponents/NumericFormatWrapper";
import { fetchClientCaseBalance } from "../../../../../store/slice/CaseManagement/Case/CaseManagementSlice";
import { formatDateISODisplay } from "../../../../../utils/date-time.utils";
import { fetchNextReverseNumber } from "../../../../../store/slice/Accounting/ClientAccounts/ClientAccountSlice";
import { ClientAccountConstant } from "../../../../../store/model/Accounting/ClientAccount/ClientAccountConst";
import { SearchCaseClientContact } from "../../../SearchCaseClientContact";

function AddNewPayment({
  openDrawer,
  Close,
  isEdit,
  isView,
  isReverse,
  banNewPayment,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  isView: boolean;
  isReverse: boolean;
  banNewPayment: any;
}) {
  const [title] = useState(!isEdit ? "Add New Payment" : "Edit New Payment");
  const emailInputRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);

  const dispatch = useAppDispatch();
  const { clientAccounts } = useSelector(
    (state: RootState) => state.clientAccount
  );
  const clientDetailsList = useSelector(
    (state: RootState) => state.case.caseClient
  );
  const receiptAutoNumber = useSelector(
    (state: RootState) => state.createPayment.receiptNumber
  );
  const { priorityClients } = useSelector(
    (state: RootState) => state.priorityClient
  );
  const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
  const [priorityClientLists, setPriorityClientLists] = useState<any[]>([]);
  const [clientResultData, setClientResultData] = useState<any[]>([]);
  const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
  const [openModal, setOpenModal] = useState(false);
  const [selectedCaseReference, setSelectedCaseReference] = useState<any>();
  const [selectedCaseClient, setSelectedCaseClient] = useState<any>();
  const [selectedContact, setSelectedContact] = useState<any>();
  const [selectedClient, setSelectedClient] = useState<any>();
  const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false);
  const [openSearchContactModal, setOpenSearchContactModal] = useState(false);
  const [openSearchSupplierModal, setOpenSearchSupplierModal] = useState(false);
  const [openSearchClientModal, setOpenSearchClientModal] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const { caseClient, caseClientBanalce } = useSelector(
    (state: RootState) => state.case
  );
  const [caseClientLists, setcaseClientLists] = useState<any[]>([]);
  const [openCaseClientContactModal, setOpenCaseClientContactModal] =
    useState(false);
  const [isReverseDisabled, setIsReverseDisabled] = useState(true);
  const reverseReceiptNumber = useSelector(
    (state: RootState) => state.clientAccount.reverseReceiptNumber
  );
  const [selectedCaseId, setSelectedCaseId] = useState<number>();
  const [invoiceTo, setInvoiceTo] = useState<string>("");
  const [invoiceAddressLine1, setInvoiceAddressLine1] = useState<string>("");
  const [invoiceCity, setInvoiceCity] = useState<string>("");
  const [invoiceCoutry, setInvoiceCoutry] = useState<string>("");
  const [invoicePostCode, setInvoicePostCode] = useState<string>("");
  const [selectedCaseClientContact, setSelectedCaseClientContact] =
    useState<any>();
  const { clientPaymentTypes } = useSelector(
    (state: RootState) => state.clientPaymentType
  );
  const [clientPaymentTypeLists, setclientPaymentTypeLists] = useState<any[]>(
    []
  );
  const [caseClientType, setCaseClientType] = useState<string>("");
  const payeeType = {
    contact: 0,
    client: 1,
    supplier: 2,
    other: (value: number) => (value >= 3 && value <= 9 ? value : null), // Dynamic range
  };
  useEffect(() => {
    if (!effectServiceCall.current && !isEdit) {
      setInitialLoading(true);
      const fetchPromises = [dispatch(fetchNextBankPaymentNumber())];
      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (receiptAutoNumber != null) {
      setValue("paymentNumber", receiptAutoNumber);
      setAutoReceiptNumber(receiptAutoNumber);
    }
  }, [receiptAutoNumber]);

  useEffect(() => {
    if (clientAccounts != null && clientAccounts.length > 0) {
      let data = clientAccounts.map((clientAccount: any, index) => ({
        value: clientAccount.bankAccountId,
        label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`,
      }));
      setClientAccountLists(data);
      let findObj = clientAccounts.find((x) => x.isDefaultAccount === true);
      if (findObj && findObj !== null) {
        setValue("fkClientBankId", findObj?.bankAccountId);
      }
    }
  }, [clientAccounts]);

  useEffect(() => {
    if (clientPaymentTypes.length > 0) {
      console.log(clientPaymentTypes);
      let data = clientPaymentTypes.map((PaymentType: any, index) => ({
        value: PaymentType.paymentTypeId,
        label: PaymentType.paymentType,
      }));
      setclientPaymentTypeLists(data);
      let findObj = clientPaymentTypes.find((x) => x.isActive === true);
      if (findObj && findObj !== null) {
        setValue("paymentMethod", findObj?.paymentType);
      }
    }
  }, [clientPaymentTypes]);

  // useEffect(() => {
  //     if (priorityClients.length > 0) {
  //         let data = priorityClients.map((priorityClient: any, index) => ({
  //             value: priorityClient.priorityTypeId,
  //             label: priorityClient.priority,
  //         }));
  //         setPriorityClientLists(data);

  //     }
  // }, [priorityClients]);

  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };

  const [isDisabled, setIsDisabled] = useState(true);

  const validationSchema = object().shape({
    paymentNumber: number().required("Payment Number is required"),
    fkClientBankId: number().required("Client Bank Account is required"),
    paymentCreateDate: string()
      .required("Transaction Date is required")
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split("T")[0];
      }),
    // entryDate: string()
    //     .required('Entry Date is required'),
    // dueDate: string(),
    paymentMethod: string(),
    availableBalance: string(),
    transactionDescription: string().required(
      "Transaction Description is required"
    ),
    payTo: string(),
    //  .required('Pay To is required'),
    payToId: number(),
    amount: number().required("Amount is required"),
    comments: string(),
    fkCaseId: number().required("Amount is required"),
    contact: string(),
    clientPaymentId: number(),
    // fkClientId: number().required("Client is required"),
    payeeType: number(),
    paymentReference: string(),
    ReverseDate: string().transform((value, originalValue) => {
      if (originalValue === "" || value === null) return null;
      return new Date(value).toISOString().split("T")[0];
    }),
    reverseNumber: lazy((value: any) => number()),
    reverseReference: lazy((value: any) =>
      isReverse
        ? string().required("Reverse Referenceis required")
        : string().notRequired()
    ),
    reason: lazy((value: any) => string()),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      paymentNumber: autoReceiptNumber,
      paymentCreateDate: new Date().toISOString().split("T")[0],
    },
  });

  useEffect(() => {
    setValue("paymentCreateDate", new Date().toISOString().split("T")[0]);
    setValue("ReverseDate", new Date().toISOString().split("T")[0]);
  }, []);

  const toggleCaseAccountModal = () => {
    setOpenCaseAccountModal(!openCaseAccountModal);
  };
  const toggleSearchClientModal = () => {
    setOpenSearchClientModal(!openSearchClientModal);
  };
  // const toggleSearchContactModal = () => {
  //     setOpenSearchContactModal(!openSearchContactModal);
  // }
  const toggleSearchSupplierModal = () => {
    setOpenSearchSupplierModal(!openSearchSupplierModal);
  };

  const onSubmit = async (data: any) => {
    // console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    if (!isReverse) {
      console.log(data);
      try {
        await dispatch(addBankNewPayment(data)).unwrap();
        Close("success");
        toast.success("Successfully Added Client New Payment Type");
      } catch (error: any) {
        toast.error(error.toString());
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log(data);
      try {
        await dispatch(addReverseBankNewPayment(data)).unwrap();
        Close("success");
        toast.success("Successfully Reverse the Client Payment Type");
      } catch (error: any) {
        toast.error(error.toString());
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  // useEffect(() => {
  //     if (isEdit && banNewPayment) {
  //         setExistingBankNewPayment(banNewPayment);
  //         reset(banNewPayment);
  //     }
  // }, [banNewPayment, isEdit, reset, setValue]);

  const toggleDrawer = () => {
    // setIsEdit(false);
    setOpenModal(!openModal);
  };

  const handleCaseSelection = (selectedCase: any) => {
    setSelectedCaseReference(selectedCase);
    setValue("fkCaseId", selectedCase.caseId);
    setSelectedCaseId(selectedCase.caseId);
    dispatch(fetchClientCaseBalance(selectedCase.caseId));
    setOpenCaseAccountModal(false);
  };

  const handleCaseClientSelection = (selectedCaseClient: any) => {
    if (selectedCaseClient.clientId > 0) {
      setSelectedCaseClient(selectedCaseClient);
      setValue("payToId", selectedCaseClient.clientId);
      const fullName = `${selectedCaseClient?.givenNames || ""} ${
        selectedCaseClient?.lastName || ""
      }`;
      setValue("payTo", fullName);
      setValue("payeeType", payeeType.client);
      setIsOther(false);
      setOpenCaseAccountModal(false);
    }
  };

  const handleCaseClientContactSelection = (caseContactClient: any) => {
    console.log(caseContactClient);
    setCaseClientType(caseContactClient.cType);
    setSelectedCaseClientContact(caseContactClient);
    if (caseContactClient != null) {
      if (caseContactClient.clientType === "Individual") {
        let fullName =
          caseContactClient.title +
          caseContactClient.givenNames +
          " " +
          caseContactClient.lastName;
        setValue("payTo", fullName);
        setValue("payToId", caseContactClient.individualClientId);
        setValue("payeeType", payeeType.contact);
        // setInvoiceAddressLine1(caseContactClient.currentAddLine1);
        // setInvoiceCity(caseContactClient.currentAddCity);
        // setInvoiceCoutry(caseContactClient.currentAddCounty);
        // setInvoicePostCode(caseContactClient.currentAddPostCode);
      } else if (caseContactClient.clientType === "Organisation") {
        setValue("payTo", caseContactClient.orgName);
        setValue("payeeType", payeeType.contact);
        // setInvoiceAddressLine1(caseContactClient.orgCurrentAddLine1);
        // setInvoiceCity(caseContactClient.orgCurrentAddCity);
        // setInvoiceCoutry(caseContactClient.orgCurrentAddCounty);
        // setInvoicePostCode(caseContactClient.orgCurrentAddPostCode);
      } else {
        setValue("payTo", caseContactClient.companyName);
        setValue("payeeType", payeeType.contact);
        // setInvoiceTo(caseContactClient.companyName);
        // setInvoiceAddressLine1(caseContactClient.compCurrentAddLine1);
        // setInvoiceCity(caseContactClient.currentAddCity);
        // setInvoiceCoutry(caseContactClient.currentAddCounty);
        // setInvoicePostCode(caseContactClient.currentAddPostCode);
      }
    }
    //console.log(caseContactClient);
    // console.log(caseContactClient)
    // setValue("caseId", caseContactClient.caseId)
    // setSelectedCaseClientId(caseContactClient.caseId)

    // setSelectedMatter(`${caseContactClient.caseName}`);
    //setSelectedResponsipleName(caseContactClient.personResponsibleName);
  };

  useEffect(() => {
    if (selectedCaseReference != null) {
      dispatch(fetchCaseClient(selectedCaseReference.caseId));
    }
  }, [selectedCaseReference, dispatch]);

  useEffect(() => {
    if (clientDetailsList != null && clientDetailsList.caseClients) {
      setClientResultData(clientDetailsList.caseClients);
    }
  }, [clientDetailsList]);

  useEffect(() => {
    if (caseClientBanalce !== null) {
      setValue("availableBalance", caseClientBanalce.closingBalance.toFixed(2));
      let data = caseClientBanalce.clientList.map((client: any) => ({
        value: client.clientId,
        label: `${client.clientName}`,
      }));
      setcaseClientLists(data);
      if (isEdit) {
        setValue("payTo", banNewPayment.clientId);
        setValue("availableBalance", caseClientBanalce.closingBalance.toFixed(2));
      }
    }
  }, [caseClientBanalce]);

  // const handleContactSelection = (selectedContact: any) => {
  //     setSelectedContact(selectedContact);
  //      setValue('contact', selectedContact.contactId);
  //     setOpenCaseAccountModal(false);

  // };

  const handleOtherCheckbox = () => {
    setIsOther(!isOther);
    if (isOther) {
      setValue("payTo", "");
   // setValue("payeeType", payeeType.other);
    }
  };
  const toggleCaseClientContactModal = () => {
    setOpenCaseClientContactModal(!openCaseClientContactModal);
  };

  // const today = new Date().toISOString().split("T")[0];
  // useEffect(() => {
  //   setValue("paymentCreateDate", today);
  // }, [today]);

  const handleClientSelection = (selectedClient: any) => {
    setSelectedClient(selectedClient);
    // setValue('client', selectedClient.contactId);
    // setOpenCaseAccountModal(false);
  };
  useEffect(() => {
    if (
      (banNewPayment != null && isEdit === true) ||
      (banNewPayment != null && isView === true && isEdit === false) ||
      (banNewPayment != null &&
        isView === false &&
        isEdit === false &&
        isReverse === true)
    ) {
      console.log(banNewPayment);
      setValue("paymentNumber", banNewPayment?.paymentNumber);
      setValue(
        "paymentCreateDate",
        formatDateISODisplay(banNewPayment?.paymentCreateDate)
      );
      setValue("fkCaseId", banNewPayment?.caseId);
      setValue("fkClientBankId", banNewPayment?.clientBankId);
      setValue("availableBalance", banNewPayment?.availableBalance);
      setValue("transactionDescription", banNewPayment?.transactionDescription);
      setValue("comments", banNewPayment?.comments);
      setValue("paymentMethod", banNewPayment?.paymentMethod);
      setValue("amount", banNewPayment?.amount);
      setValue("payTo", banNewPayment?.payTo);
      setValue("contact", banNewPayment?.contact);
      setValue("clientPaymentId", banNewPayment?.clientPaymentId);
      setValue("paymentReference", banNewPayment?.paymentReference);

      setSelectedCaseReference({
        caseReferenceAuto: banNewPayment.caseReferenceAuto,
      });
      setSelectedCaseClient({payTo: banNewPayment.payTo});
      setSelectedCaseClientContact({payTo: banNewPayment.PayTo});

      dispatch(fetchClientCaseBalance(banNewPayment?.fkCaseId));
      
    }
  }, [banNewPayment, isEdit, isView, reset, setValue]);

  useEffect(() => {
    if (isReverse) {
      dispatch(fetchNextReverseNumber(ClientAccountConstant.BankPayment));
    }
  }, [dispatch, isReverse]);

  const handleReverseEditClick = () => {
    setIsReverseDisabled(!isReverseDisabled);
  };

  return (
    <>
      {openDrawer === true ? (
        <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-12">
          <div className="flex flex-row md:flex-col justify-between my-3 gap-2 w-full md:gap-3">
            <div className="relative flex gap-1">
              <input
                type="text"
                placeholder={
                  selectedCaseReference?.caseReferenceAuto || "Select Case"
                }
                aria-label="Search"
                value={selectedCaseReference?.caseReferenceAuto || ""}
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.fkCaseId ? "is-invalid" : ""
                }`}
                {...register("fkCaseId")}
                disabled
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.fkCaseId?.message?.toString()}
              </div>
              <button
                onClick={toggleCaseAccountModal}
                className="z-[2] inline-block rounded-e border-2 border-primary w-36 px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                data-twe-ripple-init
                data-twe-ripple-color="white"
                type="button"
                disabled={isView}
              >
                Add Case
              </button>
              {openCaseAccountModal && (
                <SearchCaseReference
                  openDrawer={openCaseAccountModal}
                  Close={toggleCaseAccountModal}
                  onCaseSelect={handleCaseSelection}
                />
              )}
            </div>
            {isReverse === false ||
              (isView === false && (
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      Close();
                    }}
                    className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              ))}
          </div>
          <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
            <div>
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Payment Number <span className="text-red-500">*</span>
              </label>
              <div className="flex gap-3">
                <input
                  type="text"
                  value={autoReceiptNumber}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("paymentNumber")}
                  disabled={isDisabled}
                />
                <button
                  type="button"
                  onClick={handleEditClick}
                  className="mt-2 text-lg 3xl:text-sm font-medium text-blue-600 dark:text-blue-500"
                >
                  {isDisabled ? <FaLock /> : <FaLockOpen />}
                </button>
              </div>
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.paymentNumber?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Client Bank Account <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={clientAccountLists}
                isSearchable={true}
                value={getValues().fkClientBankId}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("fkClientBankId", selectedOption);
                  }
                }}
                isDisabled={isView || isReverse}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.fkClientBankId?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Transaction Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.paymentCreateDate ? "is-invalid" : ""
                }`}
                {...register("paymentCreateDate")}
                placeholder="Enter Transaction Date"
                disabled={isView || isReverse}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.paymentCreateDate?.message?.toString()}
              </div>
            </div>
            {/* <div className="relative">
                            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                Entry Date <span className="text-red-500">*</span>
                            </label>
                            <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.entryDate ? 'is-invalid' : ''}`} {...register('entryDate')}
                                placeholder="Enter Date"
                            />
                            <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">{errors?.entryDate?.message?.toString()}</div>
                        </div> */}
            {/* <div className="relative">
                            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                                Due Date
                            </label>
                            <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('dueDate')}
                                placeholder="Enter Date"
                            />
                        </div> */}

            <div className="">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Payment Method
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={clientPaymentTypeLists}
                isSearchable={true}
                value={getValues().paymentMethod}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("paymentMethod", selectedOption);
                  }
                }}
                isDisabled={isView || isReverse}
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Available Balance <span className="text-red-500">*</span>
              </label>
              <NumericFormatWrapper
                decimalScale={2}
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.availableBalance ? "is-invalid" : ""
                }`}
                {...register("availableBalance")}
                value={getValues().availableBalance}
                onValueChange={(values: any) => {
                  setValue("availableBalance", values.value);
                }}
                placeholder="Enter Available Balance"
                disabled
                defaultValue={0.0}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.availableBalance?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Pay To <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.payTo ? "is-invalid" : ""
                }`}
                {...register("payTo")}
                placeholder="Enter the Name"
                value={getValues().payTo}
                disabled={!isOther}
               // disabled={!isOther || isView || isReverse}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.payTo?.message?.toString()}
              </div>
              <div className="space-x-1">
                <button
                  type="button"
                  onClick={toggleSearchClientModal}
                  className="text-lg 3xl:text-xs py-1 px-3 font-medium text-white-bg bg-blue-500 rounded hover:bg-blue-600"
                  disabled={isView || isReverse}
                >
                  Client
                </button>
                {openSearchClientModal && (
                  <SearchCaseClient
                    openDrawer={openSearchClientModal}
                    onCaseClientSelect={handleCaseClientSelection}
                    Close={toggleSearchClientModal}
                    clientResult={clientResultData}
                  />
                )}
                <button
                  type="button"
                  onClick={toggleCaseClientContactModal}
                  className="text-lg 3xl:text-xs py-1 px-3 font-medium text-white-bg bg-blue-500 rounded hover:bg-blue-600"
                  disabled={isView || isReverse}
                >
                  Contact
                </button>
                {openCaseClientContactModal && selectedCaseId !== undefined && (
                  <SearchCaseClientContact
                    openDrawer={openCaseClientContactModal}
                    Close={toggleCaseClientContactModal}
                    onCaseSelectDetail={handleCaseClientContactSelection}
                    caseId={selectedCaseId}
                  />
                )}
                {/* {(openCaseAccountModal) &&
                                <SearchCaseReference 
                                openDrawer={openCaseAccountModal} 
                                Close={toggleCaseAccountModal} 
                                
                                />
                            } */}
                <button
                  type="button"
                  onClick={toggleSearchSupplierModal}
                  className="text-lg 3xl:text-xs py-1 px-3 font-medium text-white-bg bg-blue-500 rounded hover:bg-blue-600"
                  disabled={isView || isReverse}
                >
                  Supplier
                </button>
                {openSearchSupplierModal && (
                  <SearchSupplier
                    openDrawer={openSearchSupplierModal}
                    Close={toggleSearchSupplierModal}
                    onAccountSupplierSelect={undefined}
                  />
                )}
              </div>
              <div>
                <div className="flex items-center my-2">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    checked={isOther}
                    onChange={handleOtherCheckbox}
                    disabled={isView || isReverse}
                  />
                  <label className="ms-2 text-lg 3xl:text-xs font-medium text-gray-900 dark:text-gray-300">
                    Other
                  </label>
                </div>
              </div>
            </div>

            <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Transaction Description <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.transactionDescription ? "is-invalid" : ""
                }`}
                {...register("transactionDescription")}
                placeholder="Enter Transaction Description"
                disabled={isView || isReverse}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.transactionDescription?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Amount (£) <span className="text-red-500">*</span>
              </label>
              <NumericFormat
                decimalScale={2}
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.amount ? "is-invalid" : ""
                }`}
                value={getValues().amount}
                onValueChange={(values) => {
                  setValue("amount", Number(values.value));
                }}
                placeholder="Enter Amount (£)"
                disabled={isView || isReverse}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.amount?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Payment Reference <span className="text-red-500"></span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.paymentReference ? "is-invalid" : ""
                }`}
                {...register("paymentReference")}
                placeholder="Enter Payment Reference"
                disabled={isView || isReverse}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.paymentReference?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Comments
              </label>
              <textarea
                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Comments"
                {...register("comments")}
                disabled={isView || isReverse}
              ></textarea>
            </div>
          </div>

          {isReverse && (
            <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Reverse Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.ReverseDate ? "is-invalid" : ""
                  }`}
                  {...register("ReverseDate")}
                  placeholder="Enter Transaction Date"
                />
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.ReverseDate?.message?.toString()}
                </div>
              </div>

              <div>
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Reverse Receipt Number
                </label>
                <div className="flex gap-3">
                  <input
                    type="text"
                    value={reverseReceiptNumber}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    disabled={isReverseDisabled}
                    {...register("reverseNumber")}
                  />

                  <button
                    type="button"
                    onClick={handleReverseEditClick}
                    className="mt-2 text-lg 3xl:text-sm font-medium text-blue-600 dark:text-blue-500"
                  >
                    {isReverseDisabled ? <FaLock /> : <FaLockOpen />}
                  </button>
                </div>
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.reverseNumber?.message?.toString()}
                </div>
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Reverse Reference
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.reverseReference ? "is-invalid" : ""
                  }`}
                  placeholder="Enter Reverse Reference"
                  {...register("reverseReference")}
                />
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.reverseReference?.message?.toString()}
                </div>
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Reason
                </label>
                <textarea
                  className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Reason"
                  {...register("reason")}
                ></textarea>
              </div>
            </div>
          )}
          {!isView && (
            <div className="flex col-span-2 gap-2 justify-end my-5">
              <button
                type="button"
                onClick={() => {
                  Close();
                }}
                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className={`cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-lg 3xl:text-sm w-[120px]  ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white-bg mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          )}
        </form>
      ) : null}
    </>
  );
}

export { AddNewPayment };
