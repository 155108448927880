import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddRefundCreditNote } from "../Modal/AddClientAccounts/AddClientInvoice/AddRefundCreditNote";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { RootState } from "../../store/store";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { deleteCACreditNoteRefund, fetchCARefundCreditNoteSearch } from "../../store/slice/Accounting/ClientAccounts/Invoice/CARefundCreditNoteSlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import DeleteModal from "../Modal/DeleteModal";
import { toast } from "react-toastify";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";


function RefundCreditNote() {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const caRefundCreditNote = useSelector(
    (state: RootState) => state.carefundCreditNote.caRefundCreditNote
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [getDataList, setGetDataList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [currentFetchDataList, setCurrentFetchDataList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(null);
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [refundCreditNoteData, setRefundCreditNoteData] = useState<any>([]);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [refundCreditNoteId, setRefundCreditNoteId] = useState<number>(0);

 

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      dispatch(fetchCARefundCreditNoteSearch())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch,isOnAddedOrUpdate]);

  useEffect(() => {
    if (caRefundCreditNote && caRefundCreditNote.creditNoteRefundResult) {
      setGetDataList(caRefundCreditNote.creditNoteRefundResult);
    }
  }, [caRefundCreditNote]);

  // const filterGetDataSearchQuery = () => {
  //   return getDataList.filter(
  //     (ledger: any) =>
  //       ledger?.billingDesc.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       ledger?.disbursementType
  //         .toLowerCase()
  //         .includes(searchQuery.toLowerCase()) ||
  //       ledger?.lastName.toLowerCase().includes(searchQuery.toLowerCase())
  //   );
  // };
  // useEffect(() => {
  //   if (getDataList != null && getDataList.length > 0) {
  //     const filteredByDisbursement = filterGetDataSearchQuery();
  //     const indexOfLastData = currentPage * itemsPerPage;
  //     const indexOfFirstData = indexOfLastData - itemsPerPage;
  //     const currentGetData = filteredByDisbursement.slice(
  //       indexOfFirstData,
  //       indexOfLastData
  //     );
  //     const totalPages = Math.ceil(getDataList.length / itemsPerPage);
  //     setTotalPages(totalPages);
  //     setCurrentFetchDataList(currentGetData);
  //   }
  // }, [getDataList]);

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const onDeleteCall = () => {
    try {
      dispatch(deleteCACreditNoteRefund(refundCreditNoteId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted BankReceipt");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };
  

  if (openModal) {
    return (
      <AddRefundCreditNote
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        isView={isView}
        refundCreditNote={refundCreditNoteData}
      />
    );
  }
  return (
    <>
      <div className="">
      {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={refundCreditNoteId}
            title="Refund CreditNote"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        {initialLoading ? <MainAnimation /> : ""}
        <div className="mb-5">
          <SubHeaderTitle
            title="Refund Credit Note"
            buttonName="Refund"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <SearchInput title="Keyword" />
          <div>
            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full">
              <CheckBoxFilter title="Status" />
              <JustifyDateFilter
                title="Date Between"
                plannedStartDate={plannedStartDate}
                plannedEndDate={plannedEndDate}
                onPlannedStartDateChange={handleStartDateChange}
                onPlannedEndDateChange={handleEndDateChange}
              />
            </div>
          </div>
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Case Reference
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Refund Amount
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Credit Note
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Invoice
                </th>
                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {getDataList.map((refundCreditNote: any, index: number) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {
                      formatDateOnlyDisplay(refundCreditNote.refundDate)                   
                    }
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {refundCreditNote.caseReferenceAuto}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{formatCurrency(refundCreditNote.refundAmount)}</td>
                  
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    CN_{refundCreditNote.creditNoteNumber}
                   
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{refundCreditNote.invoiceName}</td>
                  
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenModal(true);
                          setIsView(true);
                          setIsEdit(false);
                          setRefundCreditNoteData(refundCreditNote);
                        }}
                      />
                      <FaEdit
                        className="fill-blue-500 w-6 h-6"
                        onClick={() => {
                          setOpenModal(true);
                          setIsEdit(true);
                          setRefundCreditNoteData(refundCreditNote);
                        }}
                      />
                      <RiDeleteBin5Fill
                        className="fill-red-600 w-6 h-6"
                        onClick={() => {
                          setOpenDelModal(true);
                          setRefundCreditNoteId(refundCreditNote.creditNoteRefundId);
                        }}
                      />
                      
                    </div>
                  </td>
                </tr>
              ))}
            </tbody> 
          </table>
        </div>
      </div>
    </>
  );
}

export { RefundCreditNote };
