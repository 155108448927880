import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetAllViewSurveyResult = async (data: any) => {
    try {
        const response = await axiosInstance.post("ViewSurveyResult", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostViewSurveyResult = async (data: any) => {
    try {
        const response = await axiosInstance.post("ViewSurveyResult", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutViewSurveyResult = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`ViewSurveyResult/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteViewSurveyResult = async (id: number) => {
    try {
        await axiosInstance.delete(`/ViewSurveyResult/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const ViewSurveyResultService = {
    GetAllViewSurveyResult,
    PostViewSurveyResult,
    PutViewSurveyResult,
    deleteViewSurveyResult
};


export default ViewSurveyResultService;
