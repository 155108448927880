import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { fetchAllBankReconReports } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/ClientReports/ClientReportsSlice";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
function ReportsBankReconcilliation() {
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);

    const toggleDrawer = () => {
        setOpenModal(!openModal);
    };


    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllBankReconReports())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);
    // 'useEffect(() => {
    //     if (bankRecpit && bankRecpit.bankRecriptResult) {
    //         setRecepitDatas(bankRecpit.bankRecriptResult);
    //     }
    // }, [bankRecpit]);'

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Bank Reconcilliation" />

                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="grid grid-cols-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full">
                            <CheckBoxFilter title="Client Bank" />
                            <CheckBoxFilter title="Staff" />
                            <CheckBoxFilter title="Year" />
                            <CheckBoxFilter title="Month" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-hidden sm:rounded-lg">
                    {initialLoading ? (<MainAnimation />) : ""}
                    <div className="overflow-x-auto max-h-96">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Statement Date
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Bank
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Staff
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Report
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Action
                                </th>
                            </tr>
                        </thead>
                    </table>
                    </div>
                </div>

            </div>
        </>
    )
}

export { ReportsBankReconcilliation };