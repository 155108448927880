import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllFinancialYearEnd = async (data: any) => {
    try {
        const response = await axiosInstance.post("officeaccount/officereport/yearend", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const FinancialYearEndService = {
    GetAllFinancialYearEnd,
   
};


export default FinancialYearEndService;
