import axiosInstance from '../axiosInstance';





const GetAllMergefield = async () => {
    try {
        const response = await axiosInstance.get("Mergefield");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch Mergefield:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch Mergefield");
    }
}

// Add a new Mergefield
// async addMergefield(newMergefield: Omit<Mergefield, 'id'>): Promise<Mergefield> {
//     try {
//         const mergefields = await this.fetchAllMergefield();
//         const newId = mergefields.length ? Math.max(...mergefields.map(t => t.id)) + 1 : 1;
//         const mergefield = { ...newMergefield, id: newId };
//         mergefields.push(mergefield);
//         return mergefield;
//     } catch (error) {
//         throw new Error(`Failed to add Mergefield: ${error}`);
//     }
// }

// Update a Mergefield
// async updateMergefield(updateMergefield: Mergefield): Promise<Mergefield> {
//     try {
//         const mergefields = await this.fetchAllMergefield();
//         const mergefieldIndex = mergefields.findIndex(t => t.id === updateMergefield.id);
//         if (mergefieldIndex !== -1) {
//             mergefields[mergefieldIndex] = updateMergefield;
//             return updateMergefield;
//         }
//         throw new Error('Mergefield not found');
//     } catch (error) {
//         throw new Error(`Failed to update Mergefield: ${error}`);
//     }
// }

// Delete a Mergefield
// async deleteMergefield(mergefieldId: number): Promise<void> {
//     try {
//         const mergefields = await this.fetchAllMergefield();
//         const newMergefields = mergefields.filter(t => t.id !== mergefieldId);
//         if (mergefields.length === newMergefields.length) {
//             throw new Error('Mergefield not found');
//         }
//         // Simulate deletion success by returning nothing
//     } catch (error) {
//         throw new Error(`Failed to delete Mergefield: ${error}`);
//     }
// }
const MergefieldService = {
    GetAllMergefield,
};

export default MergefieldService;
