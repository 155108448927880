import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ProfitAndLossStatementService from "../../../../../services/Accounting/OfficeAccount/OfficeReports/ProfitAndLossStatementService";


export interface ProfitAndLossStatement {
    incomes: [];
    expenses: [];
    dividends: [];
    costOfSales:[];
    taxes:[]
   
};
// Define the state type for Checklist
type ProfitAndLossStatementState = {
    profitAndLossStatements: ProfitAndLossStatement;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ProfitAndLossStatementState = {
    profitAndLossStatements: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllProfitAndLossStatement = createAsyncThunk(
    "fetchAllProfitAndLossStatement",
    async (data: any, thunkAPI) => {
        try {
            const response = await ProfitAndLossStatementService.GetAllProfitAndLossStatement(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const ProfitAndLossStatementSlice = createSlice({
    name: "profitAndLossStatement",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllProfitAndLossStatement.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllProfitAndLossStatement.fulfilled, (state, action: PayloadAction<ProfitAndLossStatement>) => {
                state.status = "idle";
                state.profitAndLossStatements = action.payload;
            })
            .addCase(fetchAllProfitAndLossStatement.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
           
    }
});

export default ProfitAndLossStatementSlice.reducer;
