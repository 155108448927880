import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetNextDisbursementNumber = async () => {
    try {
        const response = await axiosInstance.get("CADisbursementNextNumber");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetDisbursementSearch = async () => {
    try {
        const response = await axiosInstance.post("DisbursementSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostCADisbursement = async (data: any) => {
    try {
        const response = await axiosInstance.post("CADisbursementSave", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


// const PutProfessionalFee = async (id: number, data: any) => {
//     try {
//         const response = await axiosInstance.put(`NewInvoice/${id}`, data);
//         return response.data;
//     } catch (error: any) {
//         let data = error as ErrorResult;
//         throw new Error(data.messages.toString());
//     }
// }


const DisbursementService = {
    GetDisbursementSearch,
    PostCADisbursement,
    GetNextDisbursementNumber,
    // GetProfessionalFeeSearch
    //deleteNewInvoice
};


export default DisbursementService;
