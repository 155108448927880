import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";



const GetAllOfficeVATReturnReport = async () => {
    try {
        const response = await axiosInstance.post("OfficeVATReturnReport");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const OfficeVATReturnReportService = {
    GetAllOfficeVATReturnReport,
   
};


export default OfficeVATReturnReportService;
