import flattenDeep from 'lodash/flattenDeep';
import React, {Suspense} from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

const LoadingFallback = () => <div>Loading...</div>;

const generateFlattenRoutes = (routes) => {
  if (!routes) return [];
  return flattenDeep(routes.map(({ routes: subRoutes, ...rest }, index) => [rest,  generateFlattenRoutes(subRoutes)]));
}

export const renderRoutes = (mainRoutes) => {
  const Routes = ({ isAuthorized }) => {
    const layouts = mainRoutes.map(({ layout: Layout, routes, isPublic }, index) => {
      const subRoutes = generateFlattenRoutes(routes);
      // const ispublic = (Layout.name == "AnonymousLayout") ? true : false;
      return (
        <Route key={index} element={<Layout />}>
          <Route element={<ProtectedRoute isPublic={isPublic} isAuthorized={isAuthorized}  />}>
           {subRoutes.map(({ component: Component, path, name, componentroutes }) => {
           let subRouteItem = [];
           if(componentroutes!=null && componentroutes.length > 0){
             subRouteItem =  componentroutes.map(({ component: Component, path, name }) =>{
                 return <Route key={name} element={
                  <Suspense fallback={<LoadingFallback />}>
                  <Component />
                </Suspense>
                } path={path} />
             })
            //  console.log(subRouteItem)
           }

           if(Component && path){
             if(subRouteItem.length > 0){
                 return <Route key={name} element={
                  <Suspense fallback={<LoadingFallback />}>
                        <Component />
                      </Suspense>
                } path={path} > {subRouteItem} </Route>
             }else{
               return <Route key={name} element={
                <Suspense fallback={<LoadingFallback />}>
                <Component />
              </Suspense>
              } path={path} />
             }
           
           }
              // return (
              //   (Component && path) && (<Route key={name} element={<Component />} path={path} />)
              // )
            })}
          </Route>
        </Route>
      )
    });
    return <ReactRoutes>{layouts}</ReactRoutes>;
  }
  return Routes;
}