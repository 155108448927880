import { useEffect, useRef, useState } from "react";
import { AddInvoiceNotices } from "../Modal/AccountSetup/AddInvoiceNotices";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { deleteInvoiceNotice, fetchAllInvoiceNotice } from "../../store/slice/Accounting/AccountSetup/InvoiceNoticeSlice";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import { Pagination } from "../Pagination";
import { MdDelete } from "react-icons/md";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEdit } from "react-icons/fa";
import DeleteModal from "../Modal/DeleteModal";

function InvoiceNotices() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { invoiceNotices, status, error } = useSelector((state: RootState) => state.invoiceNotice);
    const [invoiceNoticeData, setInvoiceNoticeData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [invoiceNoticeId, setInvoiceNoticeId] = useState<number>(0);
    const [openDelModal, setOpenDelModal] = useState(false);


    // pagination
    const indexOfLastInvoiceNotice = currentPage * itemsPerPage;
    const indexOfFirstInvoiceNotice = indexOfLastInvoiceNotice - itemsPerPage;
    const currentInvoiceNotice = invoiceNotices.slice(indexOfFirstInvoiceNotice, indexOfLastInvoiceNotice);
    const totalPages = Math.ceil(invoiceNotices.length / itemsPerPage);

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllInvoiceNotice() || status === "Added")
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            // toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);



    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };
    const onDeleteCall = () => {
        try {
          dispatch(deleteInvoiceNotice(invoiceNoticeId)).unwrap();
          setOpenDelModal(false)
          toast.success("Successfully Deleted Invoice Notice");
        } catch (error: any) {
          toast.error(error.toString());
        }
      };


    return (
        <div className="flex flex-col">
                 {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            onDeleteAccept={onDeleteCall}
            id={invoiceNoticeId}
            title="demo.laitlegal.uk.says"
            message="Are you sure?"
          />
        )}
            <div className="">
                <SubHeaderTitle title="Invoice Notices" buttonName="Invoice Notice" isAdd={true} onClickAdd={toggleDrawer} />
                {(openModal) &&
                    <AddInvoiceNotices openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} invoiceNotice={invoiceNoticeData} />
                }
            </div>
            <div className="relative overflow-hidden sm:rounded-lg my-5">
                <div className="overflow-x-auto max-h-96">
                <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                    {initialLoading ? (<MainAnimation />) : ""}
                    <thead className="sticky top-0 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-800 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                Invoice Notice Title
                            </th>
                            <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                Is Default
                            </th>
                            <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                Edit
                            </th>
                            <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                Delete
                            </th>

                        </tr>
                    </thead>
                    <tbody className="text-lg 3xl:text-xs">
                        {currentInvoiceNotice.map((invoiceNotice: any, index) => (
                            <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    {invoiceNotice.title}
                                </td>
                                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    {invoiceNotice.isDefault === true ? "Yes" : "No"}
                                </td>
                                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 cursor-pointer">
                                <FaEdit
                                 className="fill-blue-500 w-6 h-6"
                                onClick={() => {
                                setOpenModal(true);
                                setIsEdit(true);
                                setInvoiceNoticeData(invoiceNotice);
                      }}
                    />
                                </td>
                                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 cursor-pointer">
                                    <MdDelete className="fill-red-600 w-6 h-6" 
                                     onClick={() => {
                                        setOpenDelModal(true);
                                        setInvoiceNoticeId(invoiceNotice.invoiceNoticeId);
                                      }}
                                    />
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                </div>
                {currentInvoiceNotice.length === 0 && (
                    <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                        <p className="text-red-800">No InvoiceNotice found.</p>
                    </div>
                )}
            </div>
            {currentInvoiceNotice.length > 0 && (
                <div className="w-full flex flex-col pb-[100px]">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalRecords={currentInvoiceNotice.length}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            )}
        </div>
    )
}

export { InvoiceNotices };

