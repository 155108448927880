import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllAccountSearchNominalAccount = async (data: any) => {
    try {
        const response = await axiosInstance.post("OfficeAccount/searchnominal", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const AccountSearchNominalAccountService = {
    GetAllAccountSearchNominalAccount,
    
};


export default AccountSearchNominalAccountService;
