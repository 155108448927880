import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import OutgoingPostService from "../../../../services/OfficeManagement/PostRegister/OutgoingPostService";

export interface OutgoingPost {
    listingData: ListingData[];
    totalCount: number;
}

export interface ListingData{
    outgoingPostId?: number | null;
    outgoingPostDate: Date;
    caseId: number;
    sentTo: string;
    sentToOther: string;
    outgoingPostDescription: string;
    postageMethodId: number;
    trackingNumber: string;
    outgoingPostNotes: string;
    attachmentFileUrl: string;
    attachmentFileName: string;
}
// Define the state type for Checklist
type OutgoingPostState = {
    outgoingPosts: PaginationResponse<OutgoingPost> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: OutgoingPostState = {
    outgoingPosts: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllOutgoingPost = createAsyncThunk(
    "fetchAllOutgoingPost",
    async (data: any, thunkAPI) => {
        try {
            const response = await OutgoingPostService.GetAllOutgoingPost(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addOutgoingPost = createAsyncThunk(
    "addOutgoingPost",
    async (data: any, thunkAPI) => {
        try {
            const response = await OutgoingPostService.PostOutgoingPost(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateOutgoingPost = createAsyncThunk(
    "updateOutgoingPost",
    async ({ id, data }: { id: number, data: OutgoingPost }, thunkAPI) => {
        try {
            const response = await OutgoingPostService.PutOutgoingPost(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteOutgoingPost = createAsyncThunk(
    "deleteOutgoingPost",
    async (id: number, thunkAPI) => {
        try {
            await OutgoingPostService.deleteOutgoingPost(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const OutgoingPostSlice = createSlice({
    name: "outgoingPost",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllOutgoingPost.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllOutgoingPost.fulfilled, (state, action: PayloadAction<PaginationResponse<OutgoingPost>>) => {
                state.status = "idle";
                state.outgoingPosts = action.payload;
            })
            .addCase(fetchAllOutgoingPost.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addOutgoingPost.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addOutgoingPost.fulfilled, (state, action: PayloadAction<OutgoingPost>) => {
                state.status = "idle";
               // state.outgoingPosts.push(action.payload);
            })
            .addCase(addOutgoingPost.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateOutgoingPost.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateOutgoingPost.fulfilled, (state, action: PayloadAction<OutgoingPost>) => {
                state.status = "idle";
                // const index = state.outgoingPosts.findIndex(t => t.outgoingPostId === action.payload.outgoingPostId);
                // if (index !== -1) {
                //     state.outgoingPosts[index] = action.payload;
                // }
            })
            .addCase(updateOutgoingPost.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteOutgoingPost.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteOutgoingPost.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.outgoingPosts = state.outgoingPosts.filter(t => t.outgoingPostId !== action.payload);
            })
            .addCase(deleteOutgoingPost.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default OutgoingPostSlice.reducer;
