import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { SelectBox } from '../../SelectBox';
import { useAppDispatch } from '../../../store/hooks/redux-hooks';
import { toast } from 'react-toastify';
import { addLeaveRequest } from '../../../store/slice/HumanResources/HumanResources/LeaveRequestSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { fetchAllLeaveManagementType } from '../../../store/slice/HumanResources/HumanResources/LeaveManagementTypesSlice';


function AddLeaveRequests({ openDrawer, Close, isEdit, leaveRequest }: { openDrawer: boolean, Close: any,isEdit: boolean, leaveRequest: any }) {
    const [title] = useState((!isEdit? 'Add Leave Requests' : 'Edit Leave Requests'))
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const [leaveRequestFileName, setLeaveRequestFileName] = useState('');
    const [documentLeaveRequestFile, setDocumentLeaveRequestFile] = useState<any>();
    const [leaveManagementTypeLists, setLeaveManagementTypeLists] = useState<any[]>([]);
    const { leaveManagementTypes } = useSelector((state: RootState) => state.leaveManagementType);
    const effectServiceCall = useRef(false);
    const [existingLeaveRequest, setExistingLeaveRequest] = useState(leaveRequest);

    useEffect(() => {
        if (!effectServiceCall.current) {
       
          dispatch(fetchAllLeaveManagementType());
        
          effectServiceCall.current = true;
        }
      }, [dispatch]);
    
    useEffect(() => {
        if (leaveManagementTypes.length > 0) {
          let data = leaveManagementTypes.map((leaveManagementType: any, index) => ({
            value: leaveManagementType.fkLeaveTypeId,
            label: leaveManagementType.leaveType,
          }));
          setLeaveManagementTypeLists(data);
          console.log(data);
        }
      }, [leaveManagementTypes]);

      
    const validationSchema = object().shape({
        fkLeaveTypeId: string()
            .required('Leave Type is required'),
        description: string(),
        leaveStartOn: string()
            .required('Leave Date/Time is required'),
        leaveEndOn: string()
            .required('Return Date/Time is required'),
        attachmentFile: string(),
        attachmentName: string(),

    });

    const { register, handleSubmit, reset, setValue,getValues, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });


    const onSubmit = async (data: any) => {
        if (documentLeaveRequestFile == null) {
            toast.error("Please select the file");
            return;
        }

        data.attachmentName = leaveRequestFileName;

        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        formData.append('file', documentLeaveRequestFile);

        console.log(JSON.stringify(data, null, 2));
        setIsLoading(true);
        try {
            await dispatch(addLeaveRequest(formData)).unwrap();
            Close();
            toast.success("Successfully Added Leave Request");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };
    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };
    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            setDocumentLeaveRequestFile(file);
            setLeaveRequestFileName(file.name);
        } else {
            setLeaveRequestFileName('');
        }
    };

    
  useEffect(() => {
    if (isEdit && leaveRequest != null) {
      let dateStart = leaveRequest.leaveStartOn.split('T')[0];
      let dateEnd = leaveRequest.leaveEndOn.split('T')[0];
        setValue('fkLeaveTypeId',leaveRequest?.staffName);
        setValue('description',leaveRequest?.description );
        setValue('leaveStartOn',dateStart );
        setValue('leaveEndOn', dateEnd );
        setValue('attachmentName',leaveRequest?.attachmentName);
    }
}, [leaveRequest, isEdit, setValue]);
    


    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); Close(); }}
            initialFocus={emailInputRef}
            size="xl"
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">

                <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto" >
                    <div className="space-y-3 w-full">
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Leave Type <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                isSearchable={true}
                                options={leaveManagementTypeLists}
                                value={getValues().fkLeaveTypeId}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('fkLeaveTypeId', selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.fkLeaveTypeId?.message?.toString()}</div>
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Leave Date/Time <span className="text-red-500">*</span>
                            </label>
                            <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.leaveStartOn ? 'is-invalid' : ''}`} {...register('leaveStartOn')}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.leaveStartOn?.message?.toString()}</div>
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Return Date/Time <span className="text-red-500">*</span>
                            </label>
                            <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.leaveEndOn ? 'is-invalid' : ''}`} {...register('leaveEndOn')}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.leaveEndOn?.message?.toString()}</div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Attachment
                            </label>
                            <input className={`block w-full text-sm text-gray-900 border-2 border-gray-300 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 `}
                             {...register('attachmentName')} 
                             type="file" multiple 
                             onChange={handleFileChange}/>
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Description
                            </label>
                            <textarea
                                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholder="Description" {...register('description')}
                            ></textarea>
                        </div>

                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                     type='button'
                                     onClick={() =>{
                                         reset();
                                        Close(); 
                                     }}
                                    className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                                >
                                 {isLoading ? (
                                    <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                    </svg>
                                ) : (
                                    'Save'
                                )}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    );
}

export { AddLeaveRequests };
