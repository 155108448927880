import axiosInstance from '../axiosInstance';
import { ErrorResult } from '../../store/model/errorResult';



const GetAllDocumentCategory = async () => {
    try {
        const response = await axiosInstance.get("DocumentCategory");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch DocumentCategory:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch DocumentCategory");
    }
}

// Add a new newDocumentCategorys
const PostDocumentCategory = async (data: any) => {
    try {
        const response = await axiosInstance.post("DocumentCategory", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Update a DocumentCategory
const PutDocumentCategory = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`DocumentCategory/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Delete a DocumentCategory
const deleteDocumentCategory = async (DocumentCategoryId: number) => {
    try {
        await axiosInstance.delete(`/DocumentCategory/${DocumentCategoryId}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}
const DocumentCategoryService = {
    GetAllDocumentCategory,
    PostDocumentCategory,
    PutDocumentCategory,
    deleteDocumentCategory,
};

export default DocumentCategoryService;
