import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AuthourisedClientService from "../../../../services/Accounting/ClientAccounts/AuthorisedClientService";
import PriorityClientService from "../../../../services/Accounting/ClientAccounts/PriorityClientService";


export interface PriorityClient {
    priorityTypeId : number;
    priority: string;
  
};
// Define the state type for Checklist
type PriorityClientState = {
    priorityClients : PriorityClient[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: PriorityClientState = {
    priorityClients: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllPriorityClient = createAsyncThunk(
    "fetchAllPriorityClient",
    async (_, thunkAPI) => {
        try {
            const response = await PriorityClientService.GetAllPriorityClient();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const PriorityClientSlice = createSlice({
    name: "priorityClient",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllPriorityClient.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllPriorityClient.fulfilled, (state, action: PayloadAction<any[]>) => {
            state.status = "idle";
            state.priorityClients = action.payload;
        })
        .addCase(fetchAllPriorityClient.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })

    }
});

export default PriorityClientSlice.reducer;