import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllOfficeNominalLedgerReport } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/OfficeNominalLedgerReportSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { SelectBox } from "../SelectBox";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
function NominalLedgerReport() {
  const today = new Date();
  const oneYearBefore = new Date();
  oneYearBefore.setFullYear(today.getFullYear() - 1);
  const formatDate = (date: Date) => date.toISOString().split("T")[0];
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(false);
  const { officeNominalLedgerReports, status, error } = useSelector(
    (state: RootState) => state.officeNominalLedgerReport
  );
  const [
    currentOfficeNominalLedgerReport,
    setCurrentOfficeNominalLedgerReport,
  ] = useState<any[]>([]);
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(
    formatDate(oneYearBefore)
  );
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(
    formatDate(today)
  );
  const [selectedType, setSelectedType] = useState<string>("dateType");
  const [financialYearId, setFinancialYearId] = useState<string | null>(null);
  const [finacialYearList, setFinancialYearList] = useState<any[]>([]);
  const [selectAccTypeId, setSelectAccTypeId] = useState<number | null>(null);
  const [selectAccSubTypeId, setSelectAccSubTypeId] = useState<string | null>(
    null
  );
  const { financialYears } = useSelector(
    (state: RootState) => state.financialYear
  );

  useEffect(() => {
    if (
      financialYears != null &&
      financialYears.dataList != null &&
      financialYears.dataList.length > 0
    ) {
      let data = financialYears.dataList.map((item: any) => ({
        value: item.financialYearId,
        label: `${item.startDate.split("T")[0]} - ${
          item.endDate.split("T")[0]
        }`,
      }));
      setFinancialYearList(data);
    }
  }, [financialYears]);

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value);
  };

  useEffect(() => {
    if (
      officeNominalLedgerReports != null &&
      officeNominalLedgerReports.length > 0
    ) {
      setCurrentOfficeNominalLedgerReport(officeNominalLedgerReports);
    }
  }, [officeNominalLedgerReports]);

  const handleSearchClick = async () => {
    setInitialLoading(true);
    const data =
      selectedType === "dateType"
        ? {
            startDate: plannedStartDate,
            endDate: plannedEndDate,
            searchType: selectedType,
            accountTypeId: selectAccTypeId,
            accountSubTypeId: selectAccSubTypeId,
          }
        : {
            financialYearId: financialYearId,
            searchType: selectedType,
            accountTypeId: selectAccTypeId,
            accountSubTypeId: selectAccSubTypeId,
          };

    try {
      await dispatch(fetchAllOfficeNominalLedgerReport(data)).unwrap();
      toast.success("Successfully Search NominalLedger");
    } catch (error: any) {
      toast.error(error?.message || "An error occurred while fetching data");
    } finally {
      setInitialLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="flex items-center justify-between mb-5">
          <div>
          <SubHeaderTitle title="Nominal Ledger Report" />
          </div>
          <div className="flex gap-x-2 ietms-center justify-end">
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-sm"
          >
            <IoIosPrint className="w-5 h-5" />
            Print
          </button>
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-sm"
          >
            <BsFileEarmarkExcelFill className="w-5 h-5" />
            Export to Excel
          </button>
        </div>
        </div>
        <div className="grid grid-cols-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full mb-5">
          <div className="">
            <SelectBox
              indicator={
                <svg
                  width="15"
                  className="fill-current mr-2"
                  height="15"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              }
              isMulti={false}
              name="frameFiftySix"
              // options={finacialYearList}
              isSearchable={true}
              placeholder="Account Type"
              shape="round"
              color="indigo_50"
              size="xs"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setSelectAccTypeId(selectedOption);
                }
              }}
            />
          </div>
          <div className="">
            <SelectBox
              indicator={
                <svg
                  width="15"
                  className="fill-current mr-2"
                  height="15"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              }
              isMulti={false}
              name="frameFiftySix"
              // options={finacialYearList}
              isSearchable={true}
              placeholder="Account Sub Type"
              shape="round"
              color="indigo_50"
              size="xs"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setSelectAccSubTypeId(selectedOption);
                }
              }}
            />
          </div>
          <div className="flex items-center gap-3">
            <input
              id="date-range-radio"
              type="radio"
              value="dateType"
              name="date-type"
              checked={selectedType === "dateType"}
              onChange={handleRadioChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <JustifyDateFilter
              title="Date Range"
              plannedStartDate={plannedStartDate}
              plannedEndDate={plannedEndDate}
              onPlannedStartDateChange={handleStartDateChange}
              onPlannedEndDateChange={handleEndDateChange}
            />
          </div>
          <div className="flex items-center w-full gap-3">
            <input
              id="financial-year-radio"
              type="radio"
              value="financialYear"
              name="date-type"
              checked={selectedType === "financialYear"}
              onChange={handleRadioChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <div className="w-72">
              <SelectBox
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={finacialYearList}
                isSearchable={true}
                placeholder="FinacialYear"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setFinancialYearId(selectedOption);
                  }
                }}
              />
            </div>
          </div>
          <div className="col-start-4 flex justify-end">
            <button
              className="z-[2] inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
              onClick={handleSearchClick}
              type="button"
            >
              Search
            </button>
          </div>
        </div>
        
        {initialLoading ? <MainAnimation /> : ""}
        {currentOfficeNominalLedgerReport.length > 0 &&
          currentOfficeNominalLedgerReport.map(
            (officeNominalLedgerReport: any, index) => (
              <div
                key={index}
                className="relative overflow-hidden sm:rounded-lg my-6"
              >
                <div>{officeNominalLedgerReport?.accountName}</div>
                <div className="overflow-x-auto max-h-96">
                  <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                    <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <th
                          scope="col"
                          className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                        >
                          Trans No
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                        >
                          Details
                        </th>
                        <th
                          scope="col"
                          className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                        >
                          Debit
                        </th>
                        <th
                          scope="col"
                          className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                        >
                          Credit
                        </th>
                        <th
                          scope="col"
                          className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                        >
                          Balance
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-lg 3xl:text-xs">
                      {officeNominalLedgerReport?.listingData &&
                        officeNominalLedgerReport?.listingData.map(
                          (listing: any, listingIndex: number) => (
                            <tr
                              key={listingIndex}
                              className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                            >
                              <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                {listing?.accountCode}
                              </td>
                              <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                {formatDateOnlyDisplay(listing?.transactionDate)}
                              </td>
                              <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                {listing?.details}
                              </td>
                              <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                {listing?.dr}
                              </td>
                              <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                {listing?.cr}
                              </td>
                              <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
                {currentOfficeNominalLedgerReport.length === 0 && (
                  <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                    <p className="text-red-800">
                      No Office Nominal Ledger Report Details found.
                    </p>
                  </div>
                )}
              </div>
            )
          )}
      </div>
    </>
  );
}

export { NominalLedgerReport };
