import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AuthorizePaymentService from "../../../../../../services/Accounting/ClientAccounts/ClientPostings/BankPayment/AuthorizePaymentService";
import CreatePaymentService from "../../../../../../services/Accounting/ClientAccounts/ClientPostings/BankPayment/CreatePaymentService";




export interface CreatePayment {
    createPaymentId: number;
    clientBank: string;
    areasofPractice: string;
    caseType: string;
    caseSubType: string;
    priority: string;
    dateBetween: string;
    keyword: string;
};


// Define the state type for Checklist
type CreatePaymentState = {
    createPayments: CreatePayment[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    receiptNumber: number | null;
};

//Initial state
const initialState: CreatePaymentState = {
    createPayments: [],
    status: "idle",
    error: null,
    receiptNumber: null
};

// Async thunks for CRUD operations
export const fetchAllCreatePayment = createAsyncThunk(
    "fetchAllCreatePayment",
    async (_, thunkAPI) => {
        try {
            const response = await CreatePaymentService.GetAllCreatePayment();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchNextBankPaymentNumber = createAsyncThunk(
    "fetchNextBankPaymentNumber",
    async (_, thunkAPI) => {
        try {
            const response = await CreatePaymentService.GetNextBankPaymentNumber();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateCreatePayment = createAsyncThunk(
    "updateCreatePayment",
    async ({ id, data }: { id: number, data: CreatePayment }, thunkAPI) => {
        try {
            const response = await CreatePaymentService.PutCreatePayment(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCreatePayment = createAsyncThunk(
    "deleteCreatePayment",
    async (id: number, thunkAPI) => {
        try {
            await CreatePaymentService.deleteCreatePayment(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


const CreatePaymentSlice = createSlice({
    name: "createPayment",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCreatePayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCreatePayment.fulfilled, (state, action: PayloadAction<CreatePayment[]>) => {
                state.status = "idle";
                state.createPayments = action.payload;
            })
            .addCase(fetchAllCreatePayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchNextBankPaymentNumber.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchNextBankPaymentNumber.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.receiptNumber = action.payload;
            })
            .addCase(fetchNextBankPaymentNumber.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(updateCreatePayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCreatePayment.fulfilled, (state, action: PayloadAction<CreatePayment>) => {
                state.status = "idle";
                const index = state.createPayments.findIndex(t => t.createPaymentId === action.payload.createPaymentId);
                if (index !== -1) {
                    state.createPayments[index] = action.payload;
                }
            })
            .addCase(updateCreatePayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCreatePayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCreatePayment.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.createPayments = state.createPayments.filter(t => t.createPaymentId !== action.payload);
            })
            .addCase(deleteCreatePayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });

    }

});


export default CreatePaymentSlice.reducer;
