
import axiosInstance from "../axiosInstance";



const GetAllIdentificationType = async () => {
    try {
        const response = await axiosInstance.get("IdentificationType");
        return response.data;
    } catch (error) {
        throw new Error(`Failed to fetch IdentificationType: ${error}`);
    }
}
const IdentificationTypeSrvice = {
    GetAllIdentificationType,
};

export default IdentificationTypeSrvice;