import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import MaritalStatusService from "../../../services/Administrator/MaritalStatusService";

export interface Marital  {
    maritalStatusId: number;
    maritalStatus: string;
    isActive: boolean
};

// Define the state type for branch
type MaritalState = {
    MaritalStatus: Marital[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: MaritalState = {
    MaritalStatus: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const GetAllMaritalStatus = createAsyncThunk(
    "GetAllMaritalStatus",
    async (_, thunkAPI) => {
        try {
            const response = await MaritalStatusService.fetchAllMaritalStatus();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// export const addBranch = createAsyncThunk(
//     "addBranch",
//     async (newBranch: Omit<Branch, 'branchId'>, thunkAPI) => {
//         try {
//             const response = await BranchManagementService.addBranch(newBranch);
//             return response;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// export const updateBranch = createAsyncThunk(
//     "updateBranch",
//     async (updatedBranch: Branch, thunkAPI) => {
//         const { branchId, ...data } = updatedBranch;
//         try {
//             const response = await BranchManagementService.updateBranch(branchId, data);
//             return response;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// export const deleteBranch = createAsyncThunk(
//     "deleteBranch",
//     async (BranchId: number, thunkAPI) => {
//         try {
//             await BranchManagementService.deleteBranch(BranchId);
//             return BranchId;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// Slice definition
const MaritalStatusSlice = createSlice({
    name: "maritalStatus",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAllMaritalStatus.pending, (state) => {
                state.status = "loading";
            })
            .addCase(GetAllMaritalStatus.fulfilled, (state, action: PayloadAction<Marital[]>) => {
                state.status = "idle";
                state.MaritalStatus = action.payload;
            })
            .addCase(GetAllMaritalStatus.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            // .addCase(addBranch.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(addBranch.fulfilled, (state, action: PayloadAction<Branch>) => {
            //     state.status = "idle";
            //     state.branches.push(action.payload);
            // })
            // .addCase(addBranch.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // })
            // .addCase(updateBranch.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(updateBranch.fulfilled, (state, action: PayloadAction<Branch>) => {
            //     state.status = "idle";
            //     const index = state.branches.findIndex(t => t.branchId === action.payload.branchId);
            //     if (index !== -1) {
            //         state.branches[index] = action.payload;
            //     }
            // })
            // .addCase(updateBranch.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // })
            // .addCase(deleteBranch.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(deleteBranch.fulfilled, (state, action: PayloadAction<number>) => {
            //     state.status = "idle";
            //     state.branches = state.branches.filter(t => t.branchId !== action.payload);
            // })
            // .addCase(deleteBranch.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // });
    }
});

export default MaritalStatusSlice.reducer;