import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";




const GetAllReconciliation = async (data: any) => {
    try {
        const response = await axiosInstance.post("BankReconciliationSearch", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostReconciliation = async (data: any) => {
    try {
        const response = await axiosInstance.post("Reconciliation", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutReconciliation = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`Reconciliation/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const ReconciliationService = {
    GetAllReconciliation,
    PostReconciliation,
    PutReconciliation,
    
};


export default ReconciliationService;
