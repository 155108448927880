import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AddressService from "../../../services/CaseManagement/AddressService";

export interface Location {
    countryId: number;
    countryName: string;
    countryCode: string;
    nationality: string;
    priority: number;
};

// Define the state type for Location
type LocationState = {
    locations: Location[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: LocationState = {
    locations: [],
    status: "idle",
    error: null
};


export const fetchLocationByPostcode = createAsyncThunk(
    "fetchLocationByPostcode",
    async (postcode: number | string, thunkAPI) => {
        try {
            const response = await AddressService.GetLocationByPostCode(postcode);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const AddressSlice = createSlice({
    name: "location",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLocationByPostcode.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchLocationByPostcode.fulfilled, (state, action: PayloadAction<Location[]>) => {
                state.status = "idle";
                state.locations = action.payload;
            })
            .addCase(fetchLocationByPostcode.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default AddressSlice.reducer;
