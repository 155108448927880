import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllLeaveManagementType = async () => {
    try {
        const response = await axiosInstance.get("LeaveManagement/Types");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const LeaveManagementTypeService = {
    GetAllLeaveManagementType,
  
};


export default LeaveManagementTypeService;