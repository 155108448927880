import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import FixedFeeInvoiceFormatService from "../../../../services/Accounting/AccountSetup/FixedFeeInvoiceFormatService";



export interface FixedFeeInvoiceFormat {
    fixedFeeInvoiceNumberFormatId: number;
    prefix: string;
    separator: string;
    dateFormat: string;
    nextInvoiceNumber: number;  
    isPrefixIncluded: boolean;
    isSeparator: boolean;
    isDateFormatIncluded: boolean;
};
// Define the state type for Checklist
type FixedFeeInvoiceFormatState = {
    fixedFeeInvoiceFormats: FixedFeeInvoiceFormat | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: FixedFeeInvoiceFormatState = {
    fixedFeeInvoiceFormats: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllFixedFeeInvoiceFormat = createAsyncThunk(
    "fetchAllFixedFeeInvoiceFormat",
    async (_, thunkAPI) => {
        try {
            const response = await FixedFeeInvoiceFormatService.GetAllFixedFeeInvoiceFormat();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addFixedFeeInvoiceFormat = createAsyncThunk(
    "addFixedFeeInvoiceFormat",
    async (data: any, thunkAPI) => {
        try {
            const response = await FixedFeeInvoiceFormatService.PostFixedFeeInvoiceFormat(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateFixedFeeInvoiceFormat = createAsyncThunk(
    "updateFixedFeeInvoiceFormat",
    async ({ id, data }: { id: number, data: FixedFeeInvoiceFormat }, thunkAPI) => {
        try {
            const response = await FixedFeeInvoiceFormatService.PutFixedFeeInvoiceFormat(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteFixedFeeInvoiceFormat = createAsyncThunk(
    "deleteFixedFeeInvoiceFormat",
    async (id: number, thunkAPI) => {
        try {
            await FixedFeeInvoiceFormatService.deleteFixedFeeInvoiceFormat(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const FixedFeeInvoiceFormatSlice = createSlice({
    name: "fixedFeeInvoiceFormat",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllFixedFeeInvoiceFormat.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllFixedFeeInvoiceFormat.fulfilled, (state, action: PayloadAction<FixedFeeInvoiceFormat>) => {
                state.status = "idle";
                state.fixedFeeInvoiceFormats = action.payload;
            })
            .addCase(fetchAllFixedFeeInvoiceFormat.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addFixedFeeInvoiceFormat.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addFixedFeeInvoiceFormat.fulfilled, (state, action: PayloadAction<FixedFeeInvoiceFormat>) => {
                state.status = "idle";
                // state.fixedFeeInvoiceFormats.push(action.payload);
            })
            .addCase(addFixedFeeInvoiceFormat.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateFixedFeeInvoiceFormat.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateFixedFeeInvoiceFormat.fulfilled, (state, action: PayloadAction<FixedFeeInvoiceFormat>) => {
                state.status = "idle";
                // const index = state.fixedFeeInvoiceFormats.findIndex(t => t.fixedFeeInvoiceNumberFormatId === action.payload.fixedFeeInvoiceNumberFormatId);
                // if (index !== -1) {
                //     state.fixedFeeInvoiceFormats[index] = action.payload;
                // }
            })
            .addCase(updateFixedFeeInvoiceFormat.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteFixedFeeInvoiceFormat.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteFixedFeeInvoiceFormat.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.fixedFeeInvoiceFormats = state.fixedFeeInvoiceFormats.filter(t => t.fixedFeeInvoiceNumberFormatId !== action.payload);
            })
            .addCase(deleteFixedFeeInvoiceFormat.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default FixedFeeInvoiceFormatSlice.reducer;
