import { ErrorResult } from "../../../../../store/model/errorResult";
import axiosInstance from "../../../../axiosInstance";




const GetAllAuthorizePayment = async () => {
    try {
        const response = await axiosInstance.get("AuthorizePayment");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutAuthorizePayment = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`AuthorizePayment/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteAuthorizePayment = async (id: number) => {
    try {
        await axiosInstance.delete(`/AuthorizePayment/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}
const AuthorizePaymentService = {
    GetAllAuthorizePayment,
    // PostFeeType,
     PutAuthorizePayment,
     deleteAuthorizePayment
};


export default AuthorizePaymentService;