import { LocalStorageService } from "./localstorage.service";
import axiosInstance from "./axiosInstance";
import { jwtDecode } from "jwt-decode";


const keyvalue = process.env.REACT_APP_LOCAL_KEY || 'lait-userinfo';

const register = (data:any) => {
  return axiosInstance.post("tenant", data);
};

const login = (data:any) => {
  return axiosInstance
    .post("auth/login",data)
    .then((response:any) => {
      if (response.data!=null) {
        LocalStorageService.setItem(keyvalue, response.data)
      //  localStorage.setItem("userInfo", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem(keyvalue);
};

const getCurrentUser = () => {
  return LocalStorageService.getItem(keyvalue);
};


const getUserName =() => {
  let data = LocalStorageService.getItem<any>(keyvalue)
  if (data == null) {
    return '';
  }
  let decoded: any = getTokenObjectData(data.token);
  if (decoded != null) {
    return decoded['fullName'];
  }
  return '';
}

const getBranchName =() => {
  let data = LocalStorageService.getItem<any>(keyvalue)
  if (data == null) {
    return '';
  }
  let decoded: any = getTokenObjectData(data.token);
  if (decoded != null) {
    return decoded['branchName'];
  }
  return '';
}


const getDesignation =() => {
  let data = LocalStorageService.getItem<any>(keyvalue)
  if (data == null) {
    return '';
  }
  let decoded: any = getTokenObjectData(data.token);
  if (decoded != null) {
    return decoded['designation'];
  }
  return '';
}



const getTokenObjectData= (token: string) =>{
  let decoded: any = jwtDecode(token);
  if (decoded != null) {
    return decoded;
  }
  return null as any;
}

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  getUserName,
  getBranchName,
  getDesignation
}

export default AuthService;


// import { LocalStorageService } from "./localstorage.service";


// const keyvalue = process.env.REACT_APP_LOCAL_KEY || 'lait-userinfo';


// const dummyUserData = {
//   id: "1",
//   name: "Lait",
//   email: "lait@gmail.com",
//   token: "dummy-jwt-token",
//   role: "user"
// };

// const register = (data: any) => {
// };

// const login = (data: any) => {
//   return new Promise<{ data: typeof dummyUserData }>((resolve, reject) => {
//     if (data.email !== dummyUserData.email) {
//       reject(new Error("Invalid email or password"));
//     } else if (data.password !== "password") {
//       reject(new Error("Invalid email or password"));
//     } else {
//       LocalStorageService.setItem(keyvalue, dummyUserData);
//       resolve({ data: dummyUserData });
//     }
//   });
// };

// const logout = () => {
//   localStorage.removeItem(keyvalue);
// };

// const getCurrentUser = () => {
//   return LocalStorageService.getItem(keyvalue);
// };

// const AuthService = {
//   register,
//   login,
//   logout,
//   getCurrentUser,
// }

// export default AuthService;
