import axiosInstance from '../axiosInstance';
import { ErrorResult } from '../../store/model/errorResult';


const GetDashboard = async () => {
    try {
        const response = await axiosInstance.get("dashboard");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch Account:', error);
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const DashboardService = {
    GetDashboard
};


export default DashboardService;
