import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";




const GetAllFixedFeeInvoiceFormat = async () => {
    try {
        const response = await axiosInstance.get("InvoiceNumberFormat");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostFixedFeeInvoiceFormat = async (data: any) => {
    try {
        console.log(data);
        const response = await axiosInstance.post("InvoiceNumberFormat", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutFixedFeeInvoiceFormat = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`InvoiceNumberFormat/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteFixedFeeInvoiceFormat = async (id: number) => {
    try {
        await axiosInstance.delete(`InvoiceNumberFormat/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const FixedFeeInvoiceFormatService = {
    GetAllFixedFeeInvoiceFormat,
    PostFixedFeeInvoiceFormat,
    PutFixedFeeInvoiceFormat,
    deleteFixedFeeInvoiceFormat,
};


export default FixedFeeInvoiceFormatService;
