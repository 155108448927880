import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import WorkflowSetupService from "../../../services/SystemMaintenance/WorkflowSetupService";
import { WorkflowDataById, WorkFlowDetail, WorkflowDoc, WorkflowDocument, WorkFlowResponse, WorkflowStep, WorkflowStepResponse } from "../../model/SystemMaintenance/WorkFlowInterFace";


// Define the state type for workflows
type WorkflowState = {
    workflowDataById: WorkflowDataById;
    workflowDocument: WorkflowDocument;
    workflowDoc: WorkflowDoc[];
    workflows: WorkFlowResponse;
    status: "idle" | "loading" | "failed";
    error: string | null;
    workFlowDetail: WorkFlowDetail[];
    workflowStepResponse: WorkflowStepResponse;
};

// Initial state
const initialState: WorkflowState = {
    workflowDocument: null!,
    workflowDataById: null!,
    workflowDoc: [],
    workflows: null!,
    status: "idle",
    error: null,
    workFlowDetail: null!,
    workflowStepResponse: null!,
};

export const fetchAllWorkflowById = createAsyncThunk(
    "fetchAllWorkflowById",
    async (id: number, thunkAPI) => {
        try {
            const response = await WorkflowSetupService.GetAllWorkflowById(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchWorkflowDataById = createAsyncThunk(
    "fetchWorkflowDataById",
    async (id: number, thunkAPI) => {
        try {
            const response = await WorkflowSetupService.GetWorkflowDataById(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Async thunks for CRUD operations
export const fetchAllWorkflow = createAsyncThunk(
    "fetchAllWorkflow",
    async (_, thunkAPI) => {
        try {
            const response = await WorkflowSetupService.GetAllWorkflow();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllWorkflowDocument = createAsyncThunk(
    "fetchAllWorkflowDocument",
    async (data: any, thunkAPI) => {
        try {
            const response = await WorkflowSetupService.GetWorkflowDocument(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllExistingWorkFlow = createAsyncThunk(
    "fetchAllExistingWorkFlow",
    async (_, thunkAPI) => {
        try {
            const response = await WorkflowSetupService.GetAllExistingWorkFlow();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export const addWorkflowSetup = createAsyncThunk(
    "addWorkflowSetup",
    async (data: WorkflowStep, thunkAPI) => {
        try {
            const response = await WorkflowSetupService.PostWorkflowSetup(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addWorkflowDoc = createAsyncThunk(
    "addWorkflowDoc",
    async (data: any, thunkAPI) => {
        try {
            const response = await WorkflowSetupService.PostWorkflowDoc(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateWorkflow = createAsyncThunk(
    "updateWorkflow",
    async ({ id, data }: { id: number, data: WorkflowStep }, thunkAPI) => {
        try {
            const response = await WorkflowSetupService.PutWorkflow(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteWorkflow = createAsyncThunk(
    "deleteWorkflow",
    async (WorkflowId: number, thunkAPI) => {
        try {
            await WorkflowSetupService.deleteWorkflow(WorkflowId);
            return WorkflowId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const WorkflowSetupSlice = createSlice({
    name: "workflow",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllWorkflow.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllWorkflow.fulfilled, (state, action: PayloadAction<WorkFlowResponse>) => {
                state.status = "idle";
                state.workflows = action.payload;
            })
            .addCase(fetchAllWorkflow.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchAllWorkflowDocument.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllWorkflowDocument.fulfilled, (state, action: PayloadAction<WorkflowDocument>) => {
                state.status = "idle";
                state.workflowDocument = action.payload;
            })
            .addCase(fetchAllWorkflowDocument.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchAllWorkflowById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllWorkflowById.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.status = "idle";
                state.workFlowDetail = action.payload;
            })
            .addCase(fetchAllWorkflowById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchWorkflowDataById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchWorkflowDataById.fulfilled, (state, action: PayloadAction<WorkflowDataById>) => {
                state.status = "idle";
                state.workflowDataById = action.payload;
            })
            .addCase(fetchWorkflowDataById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })


            .addCase(fetchAllExistingWorkFlow.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllExistingWorkFlow.fulfilled, (state, action: PayloadAction<WorkFlowResponse>) => {
                state.status = "idle";
                state.workflows = action.payload;
            })
            .addCase(fetchAllExistingWorkFlow.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addWorkflowSetup.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addWorkflowSetup.fulfilled, (state, action: PayloadAction<WorkflowStepResponse>) => {
                state.status = "idle";
                state.workflowStepResponse = action.payload;
            })
            .addCase(addWorkflowSetup.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addWorkflowDoc.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addWorkflowDoc.fulfilled, (state, action: PayloadAction<WorkflowDoc>) => {
                state.status = "idle";
                state.workflowDoc.push(action.payload);
            })
            .addCase(addWorkflowDoc.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(updateWorkflow.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateWorkflow.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                if (action.payload != null) {
                    state.workflows = action.payload || [];
                }
            })
            .addCase(updateWorkflow.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteWorkflow.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteWorkflow.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
            })
            .addCase(deleteWorkflow.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default WorkflowSetupSlice.reducer;
