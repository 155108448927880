import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllOfficeSupplierCreditNote = async (data: any) => {
    try {
        const response = await axiosInstance.post("Supplier/creditnote/search", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostOfficeSupplierCreditNote = async (data: any) => {
    try {
        const response = await axiosInstance.post("Supplier/creditnote/add", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutOfficeSupplierCreditNote = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`OfficeSupplierCreditNote/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteOfficeSupplierCreditNote = async (id: number) => {
    try {
        await axiosInstance.delete(`/OfficeSupplierCreditNote/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const OfficeSupplierCreditNoteService = {
    GetAllOfficeSupplierCreditNote,
    PostOfficeSupplierCreditNote,
    PutOfficeSupplierCreditNote,
    deleteOfficeSupplierCreditNote
};


export default OfficeSupplierCreditNoteService;
