import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";


const GetAllOfficeBankAccount= async (data: any) => {
    try {
        const response = await axiosInstance.post("OfficeAccount/banktransaction", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetNextReceiptNumber= async (type: string) => {
    try {
        const response = await axiosInstance.get("OfficeAccount/NextNumber/" + type);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const OfficeBankAccountService = {
    GetAllOfficeBankAccount,
    GetNextReceiptNumber,
   
};


export default OfficeBankAccountService;