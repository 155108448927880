import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { FaEye } from "react-icons/fa";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { AddBankDeposit } from "../Modal/AddOfficeAccounts/AddOfficePostings/AddBankDeposit";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  deleteOfficeBankDeposit,
  fetchAllOfficeBankDeposit,
} from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/BankDepositSlice";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import { toast } from "react-toastify";
import DeleteModal from "../Modal/DeleteModal";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";

function BankDeposit() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const officeBankDepositData = useSelector(
    (state: RootState) => state.officeBankDeposit.officeBankDepositData
  );
  const [officeBankDepositDatas, setOfficeBankDepositDatas] = useState<any>([]);
  const [officeBankDepositGetData, setOfficeBankDepositGetDatas] =
    useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentOfficeBankDeposit, setCurrentOfficeBankDeposit] = useState<
    any[]
  >([]);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [bankDepositId, setBankDepositId] = useState<number>(0);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      dispatch(fetchAllOfficeBankDeposit())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch, isOnAddedOrUpdate]);

  useEffect(() => {
    if (officeBankDepositData && officeBankDepositData.listingData) {
      setOfficeBankDepositGetDatas(officeBankDepositData.listingData);
    }
  }, [officeBankDepositData]);

  useEffect(() => {
    if (
      officeBankDepositGetData != null &&
      officeBankDepositGetData.length > 0
    ) {
      const filteredByOfficeBankDeposit =
        filterOfficeBankDepositBySearchQuery();
      const indexOfLastOfficeBankDeposit = currentPage * itemsPerPage;
      const indexOfFirstOfficeBankDeposit =
        indexOfLastOfficeBankDeposit - itemsPerPage;
      const currentOfficeBankDeposits = filteredByOfficeBankDeposit.slice(
        indexOfFirstOfficeBankDeposit,
        indexOfLastOfficeBankDeposit
      );
      const totalPages = Math.ceil(
        officeBankDepositGetData.length / itemsPerPage
      );
      setTotalPages(totalPages);
      setCurrentOfficeBankDeposit(currentOfficeBankDeposits);
    }
  }, [officeBankDepositGetData]);

  const filterOfficeBankDepositBySearchQuery = () => {
    return officeBankDepositGetData.filter((officeBankDeposit: any) =>
      officeBankDeposit?.accountName
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  };

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const onDeleteCall = () => {
    try {
      dispatch(deleteOfficeBankDeposit(bankDepositId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted BankDeposit");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OABankDeposit"
        getData={officeBankDepositDatas}
        title="BankDeposit"
      />
    );
  }
  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={bankDepositId}
            title="Bank Deposit"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="Bank Deposit"
            buttonName="Bank Deposit"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
          <AddBankDeposit
            openDrawer={openModal}
            Close={toggleDrawer}
            isEdit={isEdit}
            isView={isView}
            officeBankDeposit={officeBankDepositDatas}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <SearchInput title="Keyword" />
          <div>
            <div className="flex gap-4 md:gap-1 w-full">
              <CheckBoxFilter title="Office Bank" />
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto h-[calc(100vh-10px)]">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Bank
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Reference
                </th>
                <th
                  scope="col"
                  className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-center w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentOfficeBankDeposit.map((officeBankDeposit: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {
                     formatDateOnlyDisplay(officeBankDeposit?.postDate)
                       
                    }
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {officeBankDeposit?.accountName}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {officeBankDeposit?.paymentReference}
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                     {formatCurrency(officeBankDeposit?.totalAmount)}
                  </td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setIsView(true);
                          setOfficeBankDepositDatas(officeBankDeposit);
                        }}
                      />
                      <FaEdit
                        className="fill-blue-500 w-6 h-6"
                        onClick={() => {
                          setOpenModal(true);
                          setIsEdit(true);
                          setOfficeBankDepositDatas(officeBankDeposit);
                        }}
                      />
                      <RiDeleteBin5Fill
                        className="fill-red-600 w-6 h-6"
                        onClick={() => {
                          setOpenDelModal(true);
                          setBankDepositId(officeBankDeposit.bankDepositId);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          {currentOfficeBankDeposit.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Bank Deposit Details found.</p>
            </div>
          )}
        </div>
        {currentOfficeBankDeposit.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={currentOfficeBankDeposit.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { BankDeposit };
