import { useState, useRef, useEffect } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddDisbursementTypes } from "../Modal/AccountSetup/AddDisbursementTypes";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteDisbursementType, fetchAllDisbursementType } from "../../store/slice/Accounting/AccountSetup/DisbursementTypeSlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { FaEdit } from "react-icons/fa";
import { Pagination } from "../Pagination";
import { MdDelete } from "react-icons/md";
import { SubHeaderTitle } from "../SubHeaderTitle";
import DeleteModal from "../Modal/DeleteModal";
import { NewRadioButtonFilter } from "../Filters/RadioButtonFilter";
import { formatCurrency } from "../../utils/currency-format.utilis";


const isActiveStatus = [
    { id: 1, label: "Active" },
    { id: 2, label: "InActive" },
  ];

function DisbursementTypes() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { disbursementTypes, status, error } = useSelector((state: RootState) => state.disbursementType);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [disbursementTypeData, setDisbursementTypeData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [openDelModal, setOpenDelModal] = useState(false);
    const [disbursmentId, setDisbursmentId] = useState<number>(0);
    const [selectedStatus, setSelectedStatus] = useState<number | null>(null);


    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current || status === "Added" ) {
            dispatch(fetchAllDisbursementType())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            // toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); 
    };

     // Handle the radio button change
 const handleRadioButtonChange = (status: number | null) => {
    setSelectedStatus(status);
    console.log("Selected Status:", status);
  }
    

// Function to filter fee types by search query
const filterDisbursmentTypes = (data: any[]) => {
    return data.filter((disbursmentType: any) => {
        console.log(disbursmentType)
      // Handle potential undefined values using optional chaining
      const disbursmentTypess = disbursmentType?.disbursementType?.toLowerCase() || "";
      const taxCode = disbursmentType?.vatType?.toLowerCase() || "";
      const isActiveStatus = disbursmentType?.isActive ? 1 : 2;
     
      // Check if the fee type or VAT code matches the search query
      const matchesSearch =
        searchQuery.trim() === "" ||
        disbursmentTypess.includes(searchQuery.toLowerCase()) ||
        taxCode.includes(searchQuery.toLowerCase()) ;
  
      // Check if the fee type matches the selected filter (active/inactive)
      const matchesStatus =
        selectedStatus === null || selectedStatus === isActiveStatus;
  
      return matchesSearch && matchesStatus;
    });
  };

  const filteredDisbursementType = filterDisbursmentTypes(disbursementTypes);

    // pagination
    const indexOfLastDisbursementType = currentPage * itemsPerPage;
    const indexOfFirstDisbursementType = indexOfLastDisbursementType - itemsPerPage;
    const currentDisbursementType = filteredDisbursementType.slice(indexOfFirstDisbursementType, indexOfLastDisbursementType);

    const totalPages = Math.ceil(disbursementTypes.length / itemsPerPage);

    const onDeleteCall = () => {
        try {
          dispatch(deleteDisbursementType(disbursmentId)).unwrap();
          setOpenDelModal(false)
          toast.success("Successfully Deleted DisbursmentTypes");
        } catch (error: any) {
          toast.error(error.toString());
        }
      };





   

    return (
        <>
            <div className="">
            {openDelModal && (
            <DeleteModal
                    open={openDelModal}
                    onClose={() => setOpenDelModal(false)}
                    onDeleteAccept={onDeleteCall}
                    id={disbursmentId}
                    title="demo.laitlegal.uk.says"
                    message="Are you sure?"
                />
                )}
                <div className="mb-5">
                    <SubHeaderTitle title="Disbursement Types" buttonName="Disbursement Type" isAdd={true} onClickAdd={toggleDrawer} />
                    {(openModal) &&
                        <AddDisbursementTypes openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} disbursementType={disbursementTypeData} />
                    }

                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Search FeeType" value={searchQuery} onChange={handleSearchChange} />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                        <NewRadioButtonFilter
                            title="Is Active"
                            data={isActiveStatus}
                            showSearchInput={false}
                            onFilterChange={handleRadioButtonChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="relative overflow-hidden sm:rounded-lg my-5">
                    <div className="overflow-x-auto max-h-96">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        {initialLoading ? (<MainAnimation />) : ""}
                        <thead className="sticky top-0 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-800 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                {/* <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Code
                                </th> */}
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Disbursement Type
                                </th>
                                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Unit Price £
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Tax Code
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Is Active
                                </th>
                                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Edit
                                </th>
                                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Delete
                                </th>

                            </tr>
                        </thead>
                        <tbody className="text-lg 3xl:text-xs">
                            {currentDisbursementType.map((disbursementType: any, index) => (

                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    {/* <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {disbursementType.disbursementCode}
                                    </td> */}
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {disbursementType.disbursementType}
                                    </td>
                                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {formatCurrency(disbursementType.unitPrice)}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    {disbursementType.vatType}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {disbursementType.isActive === true ? "Active" : "InActive"}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 cursor-pointer">
                                        <FaEdit className="fill-blue-500 w-6 h-6"
                                            onClick={() => { setOpenModal(true); setIsEdit(true); setDisbursementTypeData(disbursementType) }} />
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 cursor-pointer">
                                        <MdDelete className="fill-red-600 w-6 h-6" 
                                         onClick={() => {
                                            setOpenDelModal(true);
                                            setDisbursmentId(disbursementType.disbursementTypeId);
                                          }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                    {currentDisbursementType.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No DisbursementType found.</p>
                        </div>
                    )}
                </div>
                {currentDisbursementType.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            totalRecords={filteredDisbursementType.length}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { DisbursementTypes };