import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddOfficeJournalEntry } from "../Modal/AddOfficeAccounts/AddOfficePostings/AddOfficeJournalEntry";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  deleteOfficeJournalEntry,
  fetchAllOfficeJournalEntry,
} from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/OfficeJournalEntrySlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import { toast } from "react-toastify";
import DeleteModal from "../Modal/DeleteModal";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";

function OfficeJournalEntry() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [officeJournalEntryData, setOfficeJournalEntryData] = useState<any>([]);
  const [officeJournalEntryGetData, setOfficeJournalEntryGetData] = useState<
    any[]
  >([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const officeJournalEntrieData = useSelector(
    (state: RootState) => state.officeJournalEntry.officeJournalEntrieData
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentOfficeJournalEntry, setCurrentOfficeJournalEntry] = useState<
    any[]
  >([]);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [officeJournalEntryId, setOfficeJournalEntryId] = useState<number>(0);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      dispatch(fetchAllOfficeJournalEntry())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch, isOnAddedOrUpdate]);

  useEffect(() => {
    if (officeJournalEntrieData && officeJournalEntrieData.listingData) {
      setOfficeJournalEntryGetData(officeJournalEntrieData.listingData);
    }
  }, [officeJournalEntrieData]);

  const filterOfficeJournalEntryBySearchQuery = () => {
    return officeJournalEntryGetData.filter((officeJournalEntry: any) =>
      officeJournalEntry?.reference
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  };
  useEffect(() => {
    if (
      officeJournalEntryGetData != null &&
      officeJournalEntryGetData.length > 0
    ) {
      console.log(officeJournalEntryGetData);
      const filteredByOfficeJournalEntry =
        filterOfficeJournalEntryBySearchQuery();
      const indexOfLastOfficeJournalEntry = currentPage * itemsPerPage;
      const indexOfFirstOfficeJournalEntry =
        indexOfLastOfficeJournalEntry - itemsPerPage;
      const currentOfficeJournalEntrys = filteredByOfficeJournalEntry.slice(
        indexOfFirstOfficeJournalEntry,
        indexOfLastOfficeJournalEntry
      );
      const totalPages = Math.ceil(
        officeJournalEntryGetData.length / itemsPerPage
      );
      setTotalPages(totalPages);
      setCurrentOfficeJournalEntry(currentOfficeJournalEntrys);
    }
  }, [officeJournalEntryGetData]);

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const onDeleteCall = () => {
    try {
      dispatch(deleteOfficeJournalEntry(officeJournalEntryId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted office Journal Entry");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
        Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OAJournalEntry"
        getData={officeJournalEntryData}
        title="office JournalEntry"
      />
    );
  }

  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={officeJournalEntryId}
            title="Bank Deposit"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}

        <div className="mb-5">
          <SubHeaderTitle
            title="Journal Entry"
            buttonName="Journal Entry"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
          {openModal && (
            <AddOfficeJournalEntry
              openDrawer={openModal}
              Close={toggleDrawer}
              isEdit={isEdit}
              isView={isView}
              officeJournalEntry={officeJournalEntryData}
            />
          )}
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <SearchInput title="Keyword" />
        </div>
        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto max-h-96">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              {initialLoading ? <MainAnimation /> : ""}
              <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  {/* <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Journal Entry Id
                </th> */}
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Date
                  </th>
                  {/* <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Staff
                </th> */}
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Journal Description
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Number of Accounts
                  </th>
                  <th
                    scope="col"
                    className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Total
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Staff
                  </th>
                  <th
                    scope="col"
                    className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-lg 3xl:text-xs">
                {currentOfficeJournalEntry.map(
                  (officeJournalEntry: any, index) => (
                    <tr
                      key={index}
                      className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      {/* <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"> JE{officeJournalEntry?.offJournalEntryId}</td> */}
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {formatDateOnlyDisplay(officeJournalEntry?.entryDate)}
                      </td>
                      {/* <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{officeJournalEntry?.staff}</td> */}
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {officeJournalEntry?.description}
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {officeJournalEntry?.totalEntries}
                      </td>
                      <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        {formatCurrency(officeJournalEntry?.total)}
                      </td>
                      <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        
                      </td>
                      <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <div className="flex gap-3 w-full">
                          <FaEye
                            className="fill-gray-500 w-6 h-6"
                            onClick={() => {
                              setOpenViewDrawer(true);
                              setIsView(true);
                              setIsEdit(false);
                              setOfficeJournalEntryData(officeJournalEntry);
                            }}
                          />
                          <FaEdit
                            className="fill-blue-500 w-6 h-6"
                            onClick={() => {
                              setOpenModal(true);
                              setIsEdit(true);
                              setIsView(false);
                              setOfficeJournalEntryData(officeJournalEntry);
                            }}
                          />
                          <RiDeleteBin5Fill
                            className="fill-red-600 w-6 h-6"
                            onClick={() => {
                              setOpenDelModal(true);
                              setOfficeJournalEntryId(
                                officeJournalEntry.journalEntryId
                              );
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          {currentOfficeJournalEntry.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No office Journal Entry found.</p>
            </div>
          )}
        </div>
        {currentOfficeJournalEntry.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={currentOfficeJournalEntry.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { OfficeJournalEntry };
