import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AuthourisedClientService from "../../../../services/Accounting/ClientAccounts/AuthorisedClientService";


export interface AuthourisedClient {
    userId : number;
    firstName: string;
    lastName: string;
};
// Define the state type for Checklist
type AuthourisedClientState = {
    authourisedClients : AuthourisedClient[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: AuthourisedClientState = {
    authourisedClients: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllAuthourisedClient = createAsyncThunk(
    "fetchAllAuthourisedClient",
    async (_, thunkAPI) => {
        try {
            const response = await AuthourisedClientService.GetAllAuthourisedClient();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const authourisedClientsSlice = createSlice({
    name: "authourisedClient",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllAuthourisedClient.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllAuthourisedClient.fulfilled, (state, action: PayloadAction<any[]>) => {
            state.status = "idle";
            state.authourisedClients = action.payload;
        })
        .addCase(fetchAllAuthourisedClient.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })

    }
});

export default authourisedClientsSlice.reducer;