import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import OfficeReconciliationService from "../../../../services/Accounting/OfficeAccount/OfficeRecociliationService";





export interface OfficeReconciliation {
    officeReconciliationId: number;
    officeReconcilliationDate: Date;
    officeStatementDate: Date;
    officeStatementBalance: string;
    officeADD: string;
    officeLESS: string;
    officeSystemBankAccount: string;
    officeDifference: string;

};

export interface OfficeReconciliationData {
    bankTransactions : []
}
// Define the state type for Checklist
type OfficeReconciliationState = {
    officeReconciliations: OfficeReconciliation[];
    officeReconciliationData: OfficeReconciliationData;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: OfficeReconciliationState = {
    officeReconciliations: [],
    officeReconciliationData: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllOfficeReconciliation = createAsyncThunk(
    "fetchAllOfficeReconciliation",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeReconciliationService.GetAllOfficeReconciliation(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addOfficeReconciliation = createAsyncThunk(
    "addOfficeReconciliation",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeReconciliationService.PostOfficeReconciliation(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateOfficeReconciliation = createAsyncThunk(
    "updateOfficeReconciliation",
    async ({ id, data }: { id: number, data: OfficeReconciliation }, thunkAPI) => {
        try {
            const response = await OfficeReconciliationService.PutOfficeReconciliation(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



// Slice definition
const OfficeReconciliationSlice = createSlice({
    name: "officeReconciliation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllOfficeReconciliation.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllOfficeReconciliation.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.officeReconciliationData = action.payload;
            })
            .addCase(fetchAllOfficeReconciliation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addOfficeReconciliation.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addOfficeReconciliation.fulfilled, (state, action: PayloadAction<OfficeReconciliation>) => {
                state.status = "idle";
                state.officeReconciliations.push(action.payload);
            })
            .addCase(addOfficeReconciliation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateOfficeReconciliation.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateOfficeReconciliation.fulfilled, (state, action: PayloadAction<OfficeReconciliation>) => {
                state.status = "idle";
                const index = state.officeReconciliations.findIndex(t => t.officeReconciliationId === action.payload.officeReconciliationId);
                if (index !== -1) {
                    state.officeReconciliations[index] = action.payload;
                }
            })
            .addCase(updateOfficeReconciliation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;

            });
    }
});

export default OfficeReconciliationSlice.reducer;
