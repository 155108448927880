import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetAllGeneralOffice = async () => {
    try {
        const response = await axiosInstance.get("generalsettings");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostGeneralOffice = async (data: any) => {
    try {
        const response = await axiosInstance.post("generalsettings", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllAccountingMethod = async () => {
    try {
        const response = await axiosInstance.get("OfficeAccount/accountmethod");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllOfficeBusinessType = async () => {
    try {
        const response = await axiosInstance.get("OfficeAccount/businesstype");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllVatPeriodType = async () => {
    try {
        const response = await axiosInstance.get("OfficeAccount/vatperiodtypes");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllAccountType = async () => {
    try {
        const response = await axiosInstance.get("OfficeAccount/accounttypes");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}
const GeneralOfficeService = {
  
    GetAllGeneralOffice,
    PostGeneralOffice,
    GetAllAccountingMethod,
    GetAllOfficeBusinessType,
    GetAllVatPeriodType,
    GetAllAccountType,
};

export default GeneralOfficeService;