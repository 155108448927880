import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks/redux-hooks';
import { SearchInput } from '../../Filters/SearchInput';
import { fetchAllClients } from '../../../store/slice/CaseManagement/ClientSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Pagination } from '../../Pagination';
import { MainAnimation } from '../../loadingAnimation/MainAnimation';
import { fetchCaseClient } from '../../../store/slice/Accounting';

function SearchCaseClient({ openDrawer, Close, onCaseClientSelect, clientResult, }: { openDrawer: boolean, Close: any, onCaseClientSelect: (client: any) => void, clientResult: any[] }) {
    const dispatch = useAppDispatch();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center');
    const [initialLoading, setInitialLoading] = useState(true);
    const [selectedClient, setSelectedClient] = useState<any>();
    const clientDetailsList = useSelector((state: RootState) => state.client.clientDetailsLists);
    const [clientDetailsLists, setClientDetailsLists] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;


    useEffect(() => {
        if (clientResult.length > 0) {
            setClientDetailsLists(clientResult);
        }
    }, [clientResult]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const filterClientBySearchQuery = () => {
        return clientDetailsLists.filter((client: any) =>
            client?.givenNames?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            client?.email?.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };
    const filteredByClient = filterClientBySearchQuery();

    const indexOfLastClient = currentPage * itemsPerPage;
    const indexOfFirstClient = indexOfLastClient - itemsPerPage;
    const currentClient = filteredByClient.slice(indexOfFirstClient, indexOfLastClient);

    const totalPages = Math.ceil(clientDetailsLists.length / itemsPerPage);

    const handleClientSelection = (clients: any) => {
        setSelectedClient(clients);
    };

    const handleSelectClientClick = () => {
        if ((selectedClient !== null)) {
            onCaseClientSelect(selectedClient);
            Close();
        }
    };

    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { Close(); }}
            initialFocus={emailInputRef}
        >
            <Modal.Header className="bg-white-bg p-5">
                <span className="text-blue_gray-900 text-xl w-auto">Search Clients For Account</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg">

                <div className="space-y-6 w-full">
                    <div className="relative overflow-x-auto sm:rounded-lg">
                        <div className='flex mb-5'>
                            <SearchInput title={'Keyword'} onChange={handleSearchChange} />
                        </div>

                        <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                            <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <th scope="col" className="px-6 py-3">
                                        Select
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Client Details
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentClient.length > 0 && (
                                    currentClient.map((client: any, index) => (
                                        <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                            <td className="px-6 py-3">
                                                <input type="radio" name="selectedClient" onChange={() => handleClientSelection(client)} />
                                            </td>
                                            <td className="px-6 py-3">
                                                {(() => {
                                                    if (client.clientType === "Individual") {
                                                        return (
                                                            <>
                                                                <p className="font-semibold pl-6 dark:text-white-bg">{client.givenNames} {client.lastName}</p>
                                                                <p className="font-semibold pl-6 dark:text-white-bg">{client.jobTitle || 'N/A'}</p>
                                                                <p className="font-semibold pl-6 dark:text-white-bg">
                                                                    {client.currentAddLine1 || 'N/A'}, {client.currentAddLine2 || 'N/A'} - {client.mobilePhone || 'N/A'} - {client.email || 'N/A'}
                                                                </p>
                                                            </>
                                                        );
                                                    } else if (client.clientType === "Organisation") {
                                                        return (
                                                            <>
                                                                <p className="font-semibold pl-6 dark:text-white-bg">{client.orgName}</p>
                                                                <p className="font-semibold pl-6 dark:text-white-bg">{client.orgNature || 'N/A'}</p>
                                                                <p className="font-semibold pl-6 dark:text-white-bg">
                                                                    {client.orgCurrentAddLine1 || 'N/A'}, {client.orgCurrentAddLine2 || 'N/A'} - {client.orgSwitchboardPhone || 'N/A'} - {client.orgEmail || 'N/A'}
                                                                </p>
                                                            </>
                                                        )
                                                    } else {
                                                        return (
                                                            <>
                                                                <p className="font-semibold pl-6 dark:text-white-bg">{client.companyName}</p>
                                                                <p className="font-semibold pl-6 dark:text-white-bg">{client.companyNature || 'N/A'}</p>
                                                                <p className="font-semibold pl-6 dark:text-white-bg">
                                                                    {client.compCurrentAddLine1 || 'N/A'}, {client.compCurrentAddLine2 || 'N/A'} - {client.compContactPhone1 || 'N/A'} - {client.compEmailAddress || 'N/A'}
                                                                </p>
                                                            </>
                                                        )
                                                    }
                                                })()}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <div className="w-full flex flex-col">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                totalRecords={currentClient.length}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    </div>
                    <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                        <div className="flex flex-row gap-2 items-end justify-end w-full">
                            <button
                                onClick={() => {
                                    Close();
                                }}
                                type='button'
                                className="cursor-pointer h-10 bg-white-A700 rounded-lg text-center border border-deep_purple-A400 text-deep_purple-A400 text-xs w-[140px] hover:text-white-A700 hover:bg-deep_purple-A400"
                            >
                                Cancel
                            </button>
                            <button
                                type='button'
                                onClick={handleSelectClientClick}
                                className="bg-green-700 text-white-bg cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center border border-deep_purple-A400 text-white-A700 text-sm w-[120px] hover:text-deep_purple-A400 hover:bg-white-A700"
                            >
                                Select Cient
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export { SearchCaseClient };
