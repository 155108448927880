import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineRefresh } from "react-icons/md";
import { AddLedgerToLedger } from "../Modal/AddClientAccounts/AddClientPostings/AddLedgerToLedger";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { deleteLedgerToLedger, fetchAllLedgerToLedger } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/LedgerToLedgerSlice";
import { toast } from "react-toastify";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import DeleteModal from "../Modal/DeleteModal";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { ReverseMainDrawer } from "../Drawer/ReverseMainDrawer";
import { ViewMainDrawer } from "../Drawer/ViewMainDrawer";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
function LedgerToLedger() {
  const [openModal, setOpenModal] = useState(false);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const ledgerToLedgers = useSelector(
    (state: RootState) => state.ledgerToLedger.ledgerToLedgerData
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [currentLedger, setCurrentLedger] = useState<any[]>([]);
  const [ledgerToLedgerData, setLedgerToLedgerData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [ledgerToLedgerId, setLedgerToLedgerId] = useState<number>(0);
  const [isView, setIsView] = useState(false);
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(null);
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(null);
  const [openReverseDrawer, setOpenReverseDrawer] = useState(false);
  const [isReverse, setIsReverse] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);


  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current|| isOnAddedOrUpdate) {
      dispatch(fetchAllLedgerToLedger())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch,isOnAddedOrUpdate]);

  useEffect(() => {
    if (ledgerToLedgers && ledgerToLedgers.ledgerToLedgerResult) {
      setLedgerToLedgerData(ledgerToLedgers.ledgerToLedgerResult);
    }
  }, [ledgerToLedgers]);

  const filterLedgerToLedgerBySearchQuery = () => {
    return ledgerToLedgerData.filter((ledgerToLedger: any) =>
      ledgerToLedger?.transactionDetails
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase())
    );
  };

  useEffect(() => {
    if (ledgerToLedgerData != null && ledgerToLedgerData.length > 0) {
      const filteredByLedgerToLedger = filterLedgerToLedgerBySearchQuery();
      const indexOfLastLedgerToLedger = currentPage * itemsPerPage;
      const indexOfFirstLedgerToLedger =
        indexOfLastLedgerToLedger - itemsPerPage;
      const currentLedgerToLedger = filteredByLedgerToLedger.slice(
        indexOfFirstLedgerToLedger,
        indexOfLastLedgerToLedger
      );
      const totalPages = Math.ceil(ledgerToLedgerData.length / itemsPerPage);
      setTotalPages(totalPages);
      setCurrentLedger(currentLedgerToLedger);
    }
  }, [ledgerToLedgerData]);

  const onDeleteCall = () => {
    try {
      dispatch(deleteLedgerToLedger(ledgerToLedgerId)).unwrap();
      setOpenDelModal(false)
      toast.success("Successfully Deleted Ledger To Ledger");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleDrawer = (value: string) => {
    if(value === "success"){
      setOnAddedOrUpdate(true)
    }
    setOpenModal(!openModal);
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };

  const toggleReverseDrawer = (value: string) => {
    setIsReverse(false);
    setOpenReverseDrawer(!openReverseDrawer);
  };
  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };



  if (openModal) {
    return (
      <AddLedgerToLedger
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        isView={isView}
        isReverse={false}
        ledgerToLedger={ledgerToLedgerData}
      />
    );
  }
  if (openReverseDrawer) {
    return (
      <ReverseMainDrawer
        Close={toggleReverseDrawer}
        openCaseDrawer={openReverseDrawer}
        drawerContent="ledgerToLedger"
        getData={ledgerToLedgerData}
      />
    );
  }

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="CPledgerToLedger"
        getData={ledgerToLedgerData}
        title="Ledger To Ledger"
      />
    );
  }

  return (
    <>
      <div className="-ml-0">
      {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            onDeleteAccept={onDeleteCall}
            id={ledgerToLedgerId}
            title="Ledger To Ledger"
            message="Are you sure?"
          />
        )}

        <div className="mb-5">
          <SubHeaderTitle
            title="Ledger to Ledger"
            buttonName="Ledger to Ledger"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col lg:flex-col xl:flex-col justify-between my-5 gap-4 w-full md:gap-3">
          <div className="md:flex lg:flex xl:flex">
            <SearchInput title="Keyword" />
          </div>
          <div>
            <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full">
              <CheckBoxFilter title="From Bank" />
              <CheckBoxFilter title="To Bank" />
              <JustifyDateFilter
                title="Date Between"
                plannedStartDate={plannedStartDate}
                plannedEndDate={plannedEndDate}
                onPlannedStartDateChange={handleStartDateChange}
                onPlannedEndDateChange={handleEndDateChange}
              />
            </div>
          </div>
        </div>
        <div className="relative w-full overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                {/* <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Transaction Ref
                </th> */}
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Transaction Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Transaction Details
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  From Ledger Card
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  To Ledger Card
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Amount
                </th>
                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentLedger.map((ledgerToLedger: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  {/* <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    L2L {ledgerToLedger.ledgerToLedgerNumber}
                  </td> */}
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {
                      formatDateOnlyDisplay(ledgerToLedger.transactionDate) 
                    }
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                   {ledgerToLedger.transactionDetails}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <p className=" font-bold">
                      {ledgerToLedger.individualNames}
                    </p>
                    <br />
                    <p className="">{ledgerToLedger.caseReferenceAuto}</p>
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <p className=" font-bold">
                      {ledgerToLedger.toIndividualNames}
                    </p>
                    <br />
                    <p className="">{ledgerToLedger.toCaseReferenceAuto}</p>
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{formatCurrency(ledgerToLedger.amount)}</td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye className="fill-gray-500 w-6 h-6" 
                       onClick={() => {
                        setOpenViewDrawer(true);
                        setIsView(true);
                        setIsEdit(false);
                        setLedgerToLedgerData(ledgerToLedger);
                      }}
                      />
                      <FaEdit className="fill-blue-500 w-6 h-6" 
                       onClick={() => {
                        setOpenModal(true);
                        setIsEdit(true);
                        setLedgerToLedgerData(ledgerToLedger);
                      }}
                      
                      />
                      <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" 
                       onClick={() => {
                        setOpenDelModal(true);
                        setLedgerToLedgerId(ledgerToLedger.ledgerToLedgerTransactionId);
                      }}
                      />
                      {/* <MdOutlineRefresh className="fill-blue-800 w-6 h-6"
                      onClick={() => {
                        setOpenReverseDrawer(true);
                        setLedgerToLedgerData(ledgerToLedger);
                      }}
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {currentLedger.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">Ledger To Ledger Details not found.</p>
            </div>
          )}
        </div>
        {currentLedger.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={ledgerToLedgerData.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { LedgerToLedger };
