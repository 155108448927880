export const formatCurrency = (value: number | null) => {
    if (value === null || value === undefined) {
      return "";
    }

  const formattedValue = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
  }).format(Math.abs(value));

    if (value < 0) {
      return `(${formattedValue})`;
    }


    return formattedValue;
  };