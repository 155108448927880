import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CorrAddItemServices from "../../../../../../services/CaseManagement/Case/Correspondence/AddItems/CorrAddItemServices";
import { CallOutData, CaseCorrespondenceItems, CaseCorrNote, CorrFormData, ImportantDateType, ImportDateData, UploadDocument } from "../../../../../model/CaseManagement/CaseCorrAddItemInterface";




// Define the state type for cases
type CorrAddItemState = {
    uploadDocument: UploadDocument[];
    callOutData: CallOutData[],
    caseCorrespondenceItems: CaseCorrespondenceItems[];
    caseCorrNote: CaseCorrNote[],
    corrFormData: CorrFormData[],
    importDateData: ImportDateData[];
    importantDateType: ImportantDateType[];
    caseDocumentTabsDisplay: boolean;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CorrAddItemState = {
    caseDocumentTabsDisplay: null!,
    caseCorrespondenceItems: null!,
    callOutData: [],
    uploadDocument: [],
    caseCorrNote: [],
    corrFormData: [],
    importDateData:[],
    importantDateType: [],
    status: "idle",
    error: null
};

export const fetchCaseDocumentTabsDisplay = createAsyncThunk(
    "fetchCaseDocumentTabsDisplay",
    async (id: number, thunkAPI) => {
        try {
            const response = await CorrAddItemServices.GetCaseDocumentTabsDisplay(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCaseCorrespondenceItems = createAsyncThunk(
    "fetchCaseCorrespondenceItems",
    async (id: number, thunkAPI) => {
        try {
            const response = await CorrAddItemServices.GetCaseCorrespondenceItems(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchImportantDateType = createAsyncThunk(
    "fetchImportantDateType",
    async (id: number, thunkAPI) => {
        try {
            const response = await CorrAddItemServices.GetImportantDateType(id || 0);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCorrUploadDocument = createAsyncThunk(
    "addCorrUploadDocument",
    async (data: any, thunkAPI) => {
        try {
            const response = await CorrAddItemServices.PostCorrUploadDocument(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCorrNote = createAsyncThunk(
    "addCorrNote",
    async (data: any, thunkAPI) => {
        try {
            const response = await CorrAddItemServices.PostCorrNote(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCorrCallout = createAsyncThunk(
    "addCorrCallout",
    async (data: any, thunkAPI) => {
        try {
            const response = await CorrAddItemServices.PostCorrCallOut(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCorrForm = createAsyncThunk(
    "addCorrForm",
    async (data: any, thunkAPI) => {
        try {
            const response = await CorrAddItemServices.PostFormsAndPrecedents(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addImportDate = createAsyncThunk(
    "addImportDate",
    async (data: any, thunkAPI) => {
        try {
            const response = await CorrAddItemServices.PostImportDate(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CorrAddItemsSlice = createSlice({
    name: "corrAddItems",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchCaseDocumentTabsDisplay.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseDocumentTabsDisplay.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.status = "idle";
                state.caseDocumentTabsDisplay = action.payload;
            })
            .addCase(fetchCaseDocumentTabsDisplay.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchCaseCorrespondenceItems.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseCorrespondenceItems.fulfilled, (state, action: PayloadAction<CaseCorrespondenceItems[]>) => {
                state.status = "idle";
                state.caseCorrespondenceItems = action.payload;
            })
            .addCase(fetchCaseCorrespondenceItems.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchImportantDateType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchImportantDateType.fulfilled, (state, action: PayloadAction<ImportantDateType[]>) => {
                state.status = "idle";
                state.importantDateType = action.payload;
            })
            .addCase(fetchImportantDateType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addCorrUploadDocument.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCorrUploadDocument.fulfilled, (state, action: PayloadAction<UploadDocument>) => {
                state.status = "idle";
                state.uploadDocument.push(action.payload);
                // state.uploadDocument = action.payload;
            })
            .addCase(addCorrUploadDocument.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCorrNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCorrNote.fulfilled, (state, action: PayloadAction<CaseCorrNote>) => {
                state.status = "idle";
                state.caseCorrNote.push(action.payload);
            })
            .addCase(addCorrNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addCorrCallout.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCorrCallout.fulfilled, (state, action: PayloadAction<CallOutData[]>) => {
                state.status = "idle";
                state.callOutData = action.payload;
            })
            .addCase(addCorrCallout.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addCorrForm.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCorrForm.fulfilled, (state, action: PayloadAction<CorrFormData[]>) => {
                state.status = "idle";
                state.corrFormData = action.payload;
            })
            .addCase(addCorrForm.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addImportDate.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addImportDate.fulfilled, (state, action: PayloadAction<ImportDateData[]>) => {
                state.status = "idle";
                state.importDateData = action.payload;
            })
            .addCase(addImportDate.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
    }
});

export default CorrAddItemsSlice.reducer;
