import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import OfficeSupplierService from "../../../../../services/Accounting/OfficeAccount/Suppliers/OfficeSupplierService";


export interface OfficeSupplier {


    supplierId: number;                          // Non-nullable int
    supplierName: string;                        // Non-nullable string
    servicesProducts?: string | null;            // Nullable string
    fkNominalAccountId?: number | null;          // Nullable int
    supplierVatNumber?: string | null;           // Nullable string
    supplierUtrn?: string | null;                // Nullable string
    supplierCompanyRegNumber?: string | null;    // Nullable string
    fkContactPersonTitleId?: number | null;  

    contactPersonGivenName?: string | null;      // Nullable string
    contactPersonLastName?: string | null;       // Nullable string
    contactPersonJobTitle?: string | null;       // Nullable string
    contactPersonEmail?: string | null;          // Nullable string
    contactPersonDirectLine?: string | null;     // Nullable string
    contactPersonExtension?: string | null;      // Nullable string
    contactPersonOfficePhone?: string | null;    // Nullable string
    contactPersonMobile?: string | null;         // Nullable string
    supplierWebsite?: string | null;             // Nullable string
    supplierEmail?: string | null;               // Nullable string
    supplierAddressLine1?: string | null;        // Nullable string
    supplierAddressLine2?: string | null;        // Nullable string
    supplierCity?: string | null;                // Nullable string
    supplierCountry?: string | null;             // Nullable string
    supplierPostcode?: string | null;            // Nullable string
    supplierPhone1?: string | null;              // Nullable string
    supplierPhone2?: string | null;              // Nullable string

    supplierFax?: string | null;                 // Nullable string
    supplierDxNumber?: string | null;            // Nullable string

    supplierExchange?: string | null;            // Nullable string
    supplierPoBoxNumber?: string | null;         // Nullable string
    supplierPoBoxCountry?: string | null;        // Nullable string
    supplierPoBoxTown?: string | null;           // Nullable string
    supplierPoBoxPostcode?: string | null;       // Nullable string
    supplierIsActive?: boolean | null; 
};



export interface OfficeSupplierData{
    listingData: [];
    totalRows: number
}

// Define the state type for Checklist
type OfficeSupplierState = {
    officeSuppliers: OfficeSupplier[];
    status: "idle" | "loading" | "failed";
    error: string | null;
     officeSupplierData: OfficeSupplierData;
};

// Initial state
const initialState: OfficeSupplierState = {
    officeSuppliers: [],
    officeSupplierData: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllOfficeSupplier = createAsyncThunk(
    "fetchAllOfficeSupplier",
    async (_, thunkAPI) => {
        try {
            const response = await OfficeSupplierService.GetAllOfficeSupplier();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addOfficeSupplier = createAsyncThunk(
    "addOfficeSupplier",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeSupplierService.PostOfficeSupplier(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateOfficeSupplier = createAsyncThunk(
    "updateOfficeSupplier",
    async ({ id, data }: { id: number, data: OfficeSupplier }, thunkAPI) => {
        try {
            const response = await OfficeSupplierService.PutOfficeSupplier(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteOfficeSupplier = createAsyncThunk(
    "deleteOfficeSupplier",
    async (id: number, thunkAPI) => {
        try {
            await OfficeSupplierService.deleteOfficeSupplier(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const OfficeSupplierSlice = createSlice({
    name: "officeSupplier",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllOfficeSupplier.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllOfficeSupplier.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.officeSupplierData = action.payload;
            })
            .addCase(fetchAllOfficeSupplier.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addOfficeSupplier.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addOfficeSupplier.fulfilled, (state, action: PayloadAction<OfficeSupplier>) => {
                state.status = "idle";
                state.officeSuppliers.push(action.payload);
            })
            .addCase(addOfficeSupplier.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateOfficeSupplier.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateOfficeSupplier.fulfilled, (state, action: PayloadAction<OfficeSupplier>) => {
                state.status = "idle";
                const index = state.officeSuppliers.findIndex(t => t.supplierId === action.payload.supplierId);
                if (index !== -1) {
                    state.officeSuppliers[index] = action.payload;
                }
            })
            .addCase(updateOfficeSupplier.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteOfficeSupplier.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteOfficeSupplier.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.officeSuppliers = state.officeSuppliers.filter(t => t.supplierId !== action.payload);
            })
            .addCase(deleteOfficeSupplier.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default OfficeSupplierSlice.reducer;
