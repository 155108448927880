import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import VATExIncomeAccountService from "../../../../services/Accounting/AccountSetup/VATExIncomeAccountService";



export interface VATExIncomeAccount {
    vatExemptIncomeAccountId: number;
    fkAccountId: number[];
};
// Define the state type for Checklist
type VATExIncomeAccountState = {
    vATExIncomeAccounts: VATExIncomeAccount[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: VATExIncomeAccountState = {
    vATExIncomeAccounts: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllVATExIncomeAccount = createAsyncThunk(
    "fetchAllVATExIncomeAccount",
    async (_, thunkAPI) => {
        try {
            const response = await VATExIncomeAccountService.GetAllVATExIncomeAccount();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addVATExIncomeAccount = createAsyncThunk(
    "addVATExIncomeAccount",
    async (data: any, thunkAPI) => {
        try {
            // data.fkAccountId = data.fkAccountId.toString();
            const response = await VATExIncomeAccountService.PostVATExIncomeAccount(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateVATExIncomeAccount = createAsyncThunk(
    "updateVATExIncomeAccount",
    async ({ id, data }: { id: number, data: VATExIncomeAccount }, thunkAPI) => {
        try {
            const response = await VATExIncomeAccountService.PutVATExIncomeAccount(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteVATExIncomeAccount = createAsyncThunk(
    "deleteVATExIncomeAccount",
    async (id: number, thunkAPI) => {
        try {
            await VATExIncomeAccountService.deleteVATExIncomeAccount(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const VATExIncomeAccountSlice = createSlice({
    name: "vATExIncomeAccount",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllVATExIncomeAccount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllVATExIncomeAccount.fulfilled, (state, action: PayloadAction<VATExIncomeAccount[]>) => {
                state.status = "idle";
                state.vATExIncomeAccounts = action.payload;
            })
            .addCase(fetchAllVATExIncomeAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addVATExIncomeAccount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addVATExIncomeAccount.fulfilled, (state, action: PayloadAction<VATExIncomeAccount>) => {
                state.status = "idle";
                state.vATExIncomeAccounts.push(action.payload);
            })
            .addCase(addVATExIncomeAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateVATExIncomeAccount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateVATExIncomeAccount.fulfilled, (state, action: PayloadAction<VATExIncomeAccount>) => {
                state.status = "idle";
                const index = state.vATExIncomeAccounts.findIndex(t => t.vatExemptIncomeAccountId === action.payload.vatExemptIncomeAccountId);
                if (index !== -1) {
                    state.vATExIncomeAccounts[index] = action.payload;
                }
            })
            .addCase(updateVATExIncomeAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteVATExIncomeAccount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteVATExIncomeAccount.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.vATExIncomeAccounts = state.vATExIncomeAccounts.filter(t => t.vatExemptIncomeAccountId !== action.payload);
            })
            .addCase(deleteVATExIncomeAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default VATExIncomeAccountSlice.reducer;
