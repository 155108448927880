
import { ErrorResult } from '../../../store/model/errorResult';
import axiosInstance from '../../axiosInstance';



const GetAllQuotationRequest = async (data: any) => {
    try {
        const response = await axiosInstance.post("RiskAssesment", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetQuotationRequestNumber = async () => {
    try {
      const response = await axiosInstance.get(`QuotationRequestNumber`);
      return response.data;
    } catch (error) {
      throw new Error(`Failed to fetch QuotationRequestNumber: ${error}`);
    }
  }


const PostQuotationRequest = async (data: any) => {
    try {
        const response = await axiosInstance.post("QuotationRequest", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutQuotationRequest = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`QuotationRequest/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteQuotationRequest = async (caseSubTypeId: number) => {
    try {
        await axiosInstance.delete(`QuotationRequest/${caseSubTypeId}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}
const QuotationRequestService = {
    GetAllQuotationRequest,
    GetQuotationRequestNumber,
    PostQuotationRequest,
    PutQuotationRequest,
    deleteQuotationRequest,
};

export default QuotationRequestService;
