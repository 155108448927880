import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetAllClientToOfficeInvoices = async () => {
    try {
        const response = await axiosInstance.get("ClientToOffice/invoices");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllClientToOffice = async () => {
    try {
        const response = await axiosInstance.post("ClientToOfficeSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostClientToOffice = async (data: any) => {
    console.log(data);
    try {
        const response = await axiosInstance.post("ClientToOffice", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostReverseClientToOffice = async (data: any) => {
    console.log(data);
    try {
        const response = await axiosInstance.post("ReverseClientToOffice", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutClientToOffice = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`ClientToOffice/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteClientToOffice = async (id: number) => {
    try {
        await axiosInstance.delete(`/ClientToOffice/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const ClientToOfficeService = {
    GetAllClientToOfficeInvoices,
    GetAllClientToOffice,
    PostReverseClientToOffice,
    PostClientToOffice,
    PutClientToOffice,
    deleteClientToOffice
};


export default ClientToOfficeService;
