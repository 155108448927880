import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AccountSearchNominalAccountService from "../../../../services/Accounting/OfficeAccount/AccountSearchNominalAccountsService";

export interface NominalAccountResponse{
    totalCount: number;
    accounts: AccountSearchNominalAccount[];
}

export interface AccountSearchNominalAccount {
   
        accountId: number;
        accountCode: number;
        accountName: string;
        fkAccountTypeId: number;
        isEditable: boolean;
        isActive: boolean;
        accountTypeId: number;
        accountType: string;
        range: string;
        fkFinancialStatementId: number;
        toIncrease: "DR" | "CR"; // assuming "DR" and "CR" are the only valid values
        accountOrder: number;
        totalCr: number | null;
        totalDr: number | null;
        runningBalance: number | null;
        financialStatement: string;
  
      
    

};
// Define the state type for Checklist
type AccountSearchNominalAccountState = {
    accountSearchNominalAccounts : NominalAccountResponse | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: AccountSearchNominalAccountState = {
    accountSearchNominalAccounts: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllAccountSearchNominalAccount = createAsyncThunk(
    "fetchAllAccountSearchNominalAccount",
    async (data: any, thunkAPI) => {
        try {
            const response = await AccountSearchNominalAccountService.GetAllAccountSearchNominalAccount(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const AccountSearchNominalAccountSlice = createSlice({
    name: "accountSearchNominalAccount",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllAccountSearchNominalAccount.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllAccountSearchNominalAccount.fulfilled, (state, action: PayloadAction<any>) => {
            state.status = "idle";
            state.accountSearchNominalAccounts = action.payload;
        })
        .addCase(fetchAllAccountSearchNominalAccount.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })

    }
});

export default AccountSearchNominalAccountSlice.reducer;