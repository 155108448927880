import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CorrespondenceService from "../../../../../services/CaseManagement/Case/Correspondence/CorrespondenceService";
import { CorrespondenceItemType } from "../../../../model/CaseManagement/CorrespondenceInterFace";



// Define the state type for cases
type CorrespondenceState = {
    correspondenceItemTypes: CorrespondenceItemType[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CorrespondenceState = {
    correspondenceItemTypes: [],
    status: "idle",
    error: null
};


export const fetchCorrespondenceItemType = createAsyncThunk(
    "fetchCorrespondenceItemType",
    async (_, thunkAPI) => {
        try {
            const response = await CorrespondenceService.GetCorrespondenceItemType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);





// Slice definition
const CorrespondenceSlice = createSlice({
    name: "correspondence",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCorrespondenceItemType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCorrespondenceItemType.fulfilled, (state, action: PayloadAction<CorrespondenceItemType[]>) => {
                state.status = "idle";
                state.correspondenceItemTypes = action.payload;
            })
            .addCase(fetchCorrespondenceItemType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

    }
});

export default CorrespondenceSlice.reducer;
