import axiosInstance from '../axiosInstance';



const fetchAllCompanyProfile = async () => {
    try {
        const response = await axiosInstance.get("Company");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch company:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch company");
    }
}
const CompanyProfileService = {
    fetchAllCompanyProfile,
};

export default CompanyProfileService;
