import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import CentralDiaryService from "../../../../services/OfficeManagement/CentralDiary/CentralDiaryService";

export interface CentralDiary {

  centralDiaryId: number;
}
// Define the state type for Checklist
type CentralDiaryState = {
    centralDiarys: PaginationResponse<CentralDiary> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CentralDiaryState = {
    centralDiarys: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllCentralDiary = createAsyncThunk(
    "fetchAllCentralDiary",
    async (data: any, thunkAPI) => {
        try {
            const response = await CentralDiaryService.GetAllCentralDiary(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCentralDiary = createAsyncThunk(
    "addCentralDiary",
    async (data: any, thunkAPI) => {
        try {
            const response = await CentralDiaryService.PostCentralDiary(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateCentralDiary = createAsyncThunk(
    "updateCentralDiary",
    async ({ id, data }: { id: number, data: CentralDiary }, thunkAPI) => {
        try {
            const response = await CentralDiaryService.PutCentralDiary(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCentralDiary = createAsyncThunk(
    "deleteCentralDiary",
    async (id: number, thunkAPI) => {
        try {
            await CentralDiaryService.deleteCentralDiary(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CentralDiarySlice = createSlice({
    name: "centralDiary",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCentralDiary.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCentralDiary.fulfilled, (state, action: PayloadAction<PaginationResponse<CentralDiary>>) => {
                state.status = "idle";
                state.centralDiarys = action.payload;
            })
            .addCase(fetchAllCentralDiary.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCentralDiary.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCentralDiary.fulfilled, (state, action: PayloadAction<CentralDiary>) => {
                state.status = "idle";
               // state.centralDiarys.push(action.payload);
            })
            .addCase(addCentralDiary.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateCentralDiary.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCentralDiary.fulfilled, (state, action: PayloadAction<CentralDiary>) => {
                state.status = "idle";
                // const index = state.centralDiarys.findIndex(t => t.centralDiaryId === action.payload.centralDiaryId);
                // if (index !== -1) {
                //     state.centralDiarys[index] = action.payload;
                // }
            })
            .addCase(updateCentralDiary.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCentralDiary.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCentralDiary.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.centralDiarys = state.centralDiarys.filter(t => t.centralDiaryId !== action.payload);
            })
            .addCase(deleteCentralDiary.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default CentralDiarySlice.reducer;
