
import axiosInstance from "../axiosInstance";



const GetAllCountry = async () => {
  try {
    const response = await axiosInstance.get("Country");
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Country: ${error}`);
  }
}
const CountryService = {
  GetAllCountry,
};

export default CountryService;
