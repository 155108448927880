import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import VATReturnReportService from "../../../../../services/Accounting/OfficeAccount/OfficeReports/VATReturnReportService";


export interface VATReturnReport {
    //creditNoteId: number;

};
// Define the state type for Checklist
type VATReturnReportState = {
    vATReturnReport: VATReturnReport[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: VATReturnReportState = {
    vATReturnReport: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllVATReturnReport = createAsyncThunk(
    "fetchAllVATReturnReport",
    async (data: any, thunkAPI) => {
        try {
            const response = await VATReturnReportService.GetAllVATReturnReport(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const VATReturnReportSlice = createSlice({
    name: "VATReturnReport",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllVATReturnReport.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllVATReturnReport.fulfilled, (state, action: PayloadAction<VATReturnReport[]>) => {
                state.status = "idle";
                state.vATReturnReport = action.payload;
            })
            .addCase(fetchAllVATReturnReport.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

    }
});

export default VATReturnReportSlice.reducer;
