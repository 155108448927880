import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllLawSociety = async (data: any) => {
    try {
        const response = await axiosInstance.post("LawSociety", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostLawSociety = async (data: any) => {
    try {
        const response = await axiosInstance.post("LawSociety", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutLawSociety = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`LawSociety/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteLawSociety = async (id: number) => {
    try {
        await axiosInstance.delete(`/LawSociety/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const LawSocietyService = {
    GetAllLawSociety,
    PostLawSociety,
    PutLawSociety,
    deleteLawSociety
};


export default LawSocietyService;
