import axiosInstance from '../../../axiosInstance';
import { ErrorResult } from '../../../../store/model/errorResult';

const GetContactSubgroup = async (id: number) => {
    try {
        const response = await axiosInstance.get(`ContactSubGroup/${id}`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const ContactSubGroupService = {
    GetContactSubgroup,
};

export default ContactSubGroupService;
