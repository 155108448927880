import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllVatPeriod = async (data: any) => {
    try {
        const response = await axiosInstance.post("generalsettings/searchvatperiod",data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostVatPeriod = async (data: any) => {
    try {
        const response = await axiosInstance.post("generalsettings/vatperiod", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutVatPeriod = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`generalsettings/vatperiod/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteVatPeriod = async (id: number) => {
    try {
        await axiosInstance.delete(`api/generalsettings/vatperiod/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const VatPeriodService = {
    GetAllVatPeriod,
    PostVatPeriod,
    PutVatPeriod,
    deleteVatPeriod
};


export default VatPeriodService;
