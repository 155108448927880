import axiosInstance from "../axiosInstance";
import { ErrorResult } from '../../store/model/errorResult';




const GetAllUser = async () => {
  try {
    const response = await axiosInstance.get("users");
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const GetUserById = async (id: number) => {
  try {
    const response = await axiosInstance.get(`users/${id}`);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const GetUsersDataByBranch = async () => {
  try {
    const response = await axiosInstance.get("BranchUsers");
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

// Add a new User
const addUserData = async (data: any) => {
  try {
    const response = await axiosInstance.post("user", data, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  });
  return response.data;
    // const response = await axiosInstance.post("user", data);
    // return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

// Update a User
const updateUserData = async (id: number, data: any) => {
  try {
    const response = await axiosInstance.put(`User/${id}`, data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

// Delete a User
const deleteUser = async (userId: number) => {
  try {
    await axiosInstance.delete(`/User/${userId}`);
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}
const UserManagementServices = {
  GetAllUser,
  GetUsersDataByBranch,
  addUserData,
  updateUserData,
  deleteUser,
  GetUserById
};

export default UserManagementServices;
