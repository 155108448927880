import { SelectBox } from "../SelectBox";


interface SelectAddressComponentProps {
    locationOptionsLists: any[] | undefined;
    onLocationChange: (location: any) => void;
    setValue: any;
}

function SelectAddressComponent({ locationOptionsLists, onLocationChange }: SelectAddressComponentProps) {
    return (
        <div className="">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Select Address
            </label>
            <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                    <svg
                        width="15"
                        className="fill-current mr-2"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512">
                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                }
                isMulti={false}
                options={locationOptionsLists}
                isSearchable={true}
                placeholder="Location"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                    onLocationChange(selectedOption);
                }}
            />
        </div>
    );
}

export { SelectAddressComponent };
