import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CaseReferenceConstantService from "../../../services/SystemMaintenance/CaseReferenceConstantService";


export interface CaseReferenceConstant  {
    code: string;
    description: string;
};
// Define the state type for CaseReferenceConstants
type CaseReferenceConstantsState = {
    CaseReferenceConstants: CaseReferenceConstant[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CaseReferenceConstantsState = {
    CaseReferenceConstants: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllCaseReferenceConstant = createAsyncThunk(
    "fetchAllCaseReferenceConstant",
    async (_, thunkAPI) => {
        try {
            const response = await CaseReferenceConstantService.GetAllCaseReferenceConstant();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



// Slice definition
const CaseReferenceConstantSlice = createSlice({
    name: "caseReferenceConstant",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCaseReferenceConstant.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCaseReferenceConstant.fulfilled, (state, action: PayloadAction<CaseReferenceConstant[]>) => {
                state.status = "idle";
                state.CaseReferenceConstants = action.payload;
            })
            .addCase(fetchAllCaseReferenceConstant.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            
    }
});

export default CaseReferenceConstantSlice.reducer;
