import { Modal } from "flowbite-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number } from "yup";
import { useEffect, useRef, useState } from "react";
import { SelectBox } from "../../../../SelectBox";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { toast } from "react-toastify";
import { addRefundCreditNote } from "../../../../../store/slice/Accounting/OfficeAccounts/OfficePostings/RefundCreditNoteSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { fetchNextReceiptNumber } from "../../../../../store/slice/Accounting/OfficeAccounts/OfficeBankAccountSlice";
import { OfficeAccountConstant } from "../../../../../store/model/Accounting/OfficeAccount/officeaccountconst";
import { getValue } from "@testing-library/user-event/dist/utils";

function AddOfficeRefundCreditNote({
  openDrawer,
  Close,
  isEdit,
  isView,
  refundCreditNote,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  isView: boolean;
  refundCreditNote: any;
}) {
  const [title] = useState(
    !isEdit ? "Add Refund Credit Note" : "Edit Refund Credit Note"
  );
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement] = useState("center");
  const [isLoading, setIsLoading] = useState(false);
  const [setExistingRefundCreditNote] = useState(refundCreditNote);
  const dispatch = useAppDispatch();

  const { receiptNumber } = useSelector(
    (state: RootState) => state.officeBankAccount
  );
  const [initialLoading, setInitialLoading] = useState(true);
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();

  const { clientPaymentTypes } = useSelector(
    (state: RootState) => state.clientPaymentType
  );
  const [clientPaymentTypeLists, setclientPaymentTypeLists] = useState<any[]>(
    []
  );

  const { officeBanks } = useSelector((state: RootState) => state.officeBank);
  const [officeBankLists, setOfficeBankLists] = useState<any[]>([]);

  const { invoiceNotices } = useSelector(
    (state: RootState) => state.invoiceNotice
  );
  const [invoiceNoticeLists, setInvoiceNoticeLists] = useState<any[]>([]);

  const { creditNotes } = useSelector((state: RootState) => state.creditNote);
  const [creditNoteLists, setCreditNoteLists] = useState<any[]>([]);
  const [selectedInvoiceNote, setSelectedInvoiceNote] = useState('');
  const [selectedInvoiceNotice, setSelectedInvoiceNotice] = useState('');
  const [selectedInvoiceNoteTitle, setSelectedInvoiceNoteTitle] = useState('');
  const [selectedInvoiceNoticeTitle, setSelectedInvoiceNoticeTitle] = useState('');


  useEffect(() => {
    if (creditNotes.length > 0) {
      let data = creditNotes.map((creditNote: any, index) => ({
        value: creditNote.creditNoteId,
        label: `${creditNote.creditNote} ( ${creditNote.invoice})`,
      }));
      setOfficeBankLists(data);
      let findObj = creditNotes.find((x) => x.creditNoteId === 1);
      if(findObj && findObj!==null) {
        setValue("creditNoteId", findObj?.creditNoteId)
      }
    }
  }, [creditNotes]);

  useEffect(() => {
    if (clientPaymentTypes.length > 0) {
      console.log(clientPaymentTypes);
      let data = clientPaymentTypes.map((PaymentType: any, index) => ({
        value: PaymentType.paymentTypeId,
        label: PaymentType.paymentType,
      }));
      setclientPaymentTypeLists(data);
      let findObj = clientPaymentTypes.find((x) => x.isActive === true);
      if(findObj && findObj!==null) {
        setValue("refundMethodId", findObj?.paymentTypeId)
      }

    }
  }, [clientPaymentTypes]);

  useEffect(() => {
    if (officeBanks.length > 0) {
      let data = officeBanks.map((officeBank: any, index) => ({
        value: officeBank.bankAccountId,
        label: `${officeBank.institution} - ${officeBank.bankAccountType}`,
      }));
      setOfficeBankLists(data);
      let findObj = officeBanks.find((x) => x.isDefaultAccount === true);
      if(findObj && findObj!==null) {
        setValue("refundAccountId", findObj?.bankAccountId)
      }
    }
  }, [officeBanks]);

  useEffect(() => {
    if (invoiceNotices.length > 0) {
      console.log(invoiceNotices);
      let data = invoiceNotices.map((invoiceNotice: any, index) => ({
        value: invoiceNotice.invoiceNoticeId,
        label: `${invoiceNotice.title}`,
      }));
      setInvoiceNoticeLists(data);
      let findObj = invoiceNotices.find((x) => x.isDefault === true);
      if(findObj && findObj!==null) {
        setValue("invoiceNoticeId", findObj?.invoiceNoticeId)
        setValue("invoiceNoteId", findObj?.invoiceNoticeId)
      }
    }
  }, [invoiceNotices]);

  // const creditNote = [
  //     { label: "NetWast office", value: 1 },

  //   ];

  //   const mappedCreditNote = creditNote.map((type: any) => ({
  //     label: type.label,
  //     value: type.value,
  //   }));

  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };

  const [isDisabled, setIsDisabled] = useState(true);

  const validationSchema = object().shape({
    creditNoteRefundNumber: number().required("Credit Note Refund is required"),
    creditNoteId: number().required("Credit Note is required"),
    creditRemaining: string(),
    transactionDescription: string(),
    refundReason: string(),
    refundDate: string()
      .required("Refund Date is required")
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split("T")[0];
      }),
    refundAmount: string().required("Refund Amount is required"),
    refundAccountId: number().required("Office Bank Account is required"),
    refundMethodId: number().required("Payment Type is required"),
    client: string(),
    refundNotes: string().required("Refund Notes is required"),
    creditNoteRefundNotice: string().required("Invoice Notice is required"),
    refundReference: string(),
    notes: string(),
    invoiceNoteId: number(),
    invoiceNoticeId: number(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      refundDate: new Date().toISOString().split("T")[0],
    },
  });
  useEffect(() => {
    setValue("refundDate", new Date().toISOString().split("T")[0]);
  }, []);

  const onSubmit = async (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    try {
      await dispatch(addRefundCreditNote(data)).unwrap();
      Close("success");
      toast.success("Successfully Added RefundCreditNote");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const handleInvoiceNoticeTitleChange = (selectedOption: any) => {
    setSelectedInvoiceNoticeTitle(selectedOption.title)
    setValue("invoiceNoticeId",selectedOption.invoiceNoticeId );
    if (selectedOption) {
      setSelectedInvoiceNotice(selectedOption.invoiceNotice); // Populate textarea with the selected note
      setValue("creditNoteRefundNotice",selectedOption.invoiceNotice);
      
    } 
  };

  const handleInvoiceNoteTitleChange = (selectedOption: any) => {
    setSelectedInvoiceNoteTitle(selectedOption.title)
    if (selectedOption) {
      setSelectedInvoiceNote(selectedOption.invoiceNotice); // Populate textarea with the selected note
      setValue("invoiceNoteId",selectedOption.invoiceNoticeId);
      setValue("refundNotes",selectedOption.invoiceNotice);
      
    } 
  };


  useEffect(() => {
    if (!effectServiceCall.current) {
      const fetchPromises = [
        dispatch(
          fetchNextReceiptNumber(OfficeAccountConstant.RefundCreditNote)
        ),
      ];

      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });

      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (receiptNumber != null) {
      setValue("creditNoteRefundNumber", receiptNumber);
      setAutoReceiptNumber(receiptNumber);
    }
  }, [receiptNumber]);

  

  useEffect(() => {
    if ((refundCreditNote != null && isEdit === true) || (refundCreditNote != null && isView === true && isEdit === false)) {
      setValue('creditNoteRefundNumber', refundCreditNote?.creditNoteRefundNumber)
      setValue('creditNoteId', (refundCreditNote?.creditNoteId))
      setValue('creditRemaining', refundCreditNote?.creditRemaining)
      setValue('transactionDescription', refundCreditNote?.transactionDescription)
      setValue('refundReason', refundCreditNote?.refundReason)
       setValue('refundDate', refundCreditNote?.refundDate)
      setValue('refundAmount', refundCreditNote?.refundAmount)
      setValue('refundAccountId', refundCreditNote?.refundAccountId)
      setValue('refundMethodId', refundCreditNote?.refundMethodId)
      setValue('client', refundCreditNote?.client)
      setValue('refundNotes', refundCreditNote?.refundNotes)
      setValue('creditNoteRefundNotice', refundCreditNote?.creditNoteRefundNotice)
      setValue('refundReference', refundCreditNote?.refundReference);
      setValue('notes', refundCreditNote?.notes);
      setValue('invoiceNoteId', refundCreditNote?.invoiceNoteId);
      setValue('invoiceNoticeId', refundCreditNote?.invoiceNoticeId);

      
    }
  }, [refundCreditNote, isEdit, isView, reset, setValue]);


  return (
    <>
      {isView === true ? (
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
          <div className="grid grid-cols-3 md:grid-cols-1 gap-x-4 gap-y-1 w-full">
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Credit Note Refund <span className="text-red-500">*</span>
              </label>
              <div className="flex gap-3">
                <input
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.creditNoteRefundNumber ? "is-invalid" : ""
                  }`}
                  {...register("creditNoteRefundNumber")}
                  disabled={isDisabled}
                />
                <button
                  type="button"
                  onClick={handleEditClick}
                  className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
                >
                  {isDisabled ? <FaLock /> : <FaLockOpen />}
                </button>
              </div>
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.creditNoteRefundNumber?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Credit Note <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={creditNoteLists}
                value={getValues().creditNoteId}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("creditNoteId", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.creditNoteId?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Credit Remaining
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("creditRemaining")}
                placeholder="Enter Credit Remaining"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Transaction Description
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("transactionDescription")}
                placeholder="Enter Transaction Description"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Refund Reason
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("refundReason")}
                placeholder="Enter Refund Reason"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Refund Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.refundDate ? "is-invalid" : ""
                }`}
                {...register("refundDate")}
                placeholder="Enter Transaction Date"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.refundDate?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Refund Amount <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.refundAmount ? "is-invalid" : ""
                }`}
                {...register("refundAmount")}
                placeholder="Enter Date"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.refundAmount?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Office Bank Account <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={officeBankLists}
                value={getValues().refundAccountId}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("refundAccountId", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.refundAccountId?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Payment Type <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={clientPaymentTypeLists}
                value={getValues().refundMethodId}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("refundMethodId", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.refundMethodId?.message?.toString()}
              </div>
            </div>

            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Refund Reference
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("refundReference")}
                placeholder="Enter Refund Reference"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Notes
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("refundNotes")}
                placeholder="Enter Notes"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Client
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("client")}
                placeholder="Enter Client"
              />
            </div>

            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Refund Notes Title
              </label>
              <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={invoiceNoticeLists}
                value={getValues().invoiceNoteId}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={handleInvoiceNoteTitleChange}
              />
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Invoice Notice Title
              </label>
              <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={invoiceNoticeLists}
                value={getValues().invoiceNoticeId}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={handleInvoiceNoticeTitleChange}
              />
            </div>
            <div className="col-start-1 relative col-span-1">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Refund Notes <span className="text-red-500">*</span>
              </label>
              <textarea
                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Refund Notes"
                {...register("refundNotes")}
                value={selectedInvoiceNote} // Bind value to the textarea
                  onChange={(e) => setSelectedInvoiceNote(e.target.value)}
              ></textarea>
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.refundNotes?.message?.toString()}
              </div>
            </div>
            <div className="relative col-span-1">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Invoice Notice <span className="text-red-500">*</span>
              </label>
              <textarea
                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Invoice Notice"
                {...register("creditNoteRefundNotice")}
                value={selectedInvoiceNotice} // Bind value to the textarea
                    onChange={(e) => setSelectedInvoiceNotice(e.target.value)}
              ></textarea>
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.creditNoteRefundNotice?.message?.toString()}
              </div>
            </div>
          </div>
          {/* <div className="flex col-span-2 gap-2 justify-end my-5">
            <button
              type="button"
              onClick={() => {
                Close();
              }}
              className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]  ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white-bg mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              ) : (
                "Save"
              )}
            </button>
          </div> */}
        </form>
      ) : (
        <Modal
          className={`backdrop-blur-sm items-center justify-center`}
          show={openDrawer}
          position={modalPlacement}
          onClose={() => {
            reset();
            Close();
          }}
          initialFocus={emailInputRef}
          size="3xl"
        >
          <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
            <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
              {title}
            </span>
          </Modal.Header>
          <Modal.Body className="bg-white-bg dark:bg-dark_bg">
            <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
              <div className="grid grid-cols-3 md:grid-cols-1 gap-x-4 gap-y-1 w-full">
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Credit Note Refund <span className="text-red-500">*</span>
                  </label>
                  <div className="flex gap-3">
                    <input
                      type="text"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        errors.creditNoteRefundNumber ? "is-invalid" : ""
                      }`}
                      {...register("creditNoteRefundNumber")}
                      disabled={isDisabled}
                    />
                    <button
                      type="button"
                      onClick={handleEditClick}
                      className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
                    >
                      {isDisabled ? <FaLock /> : <FaLockOpen />}
                    </button>
                  </div>
                  <div className="font-medium mt-2 text-xs text-red-600">
                    {errors?.creditNoteRefundNumber?.message?.toString()}
                  </div>
                </div>
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Credit Note <span className="text-red-500">*</span>
                  </label>
                  <SelectBox
                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                    indicator={
                      <svg
                        width="15"
                        className="fill-current mr-2"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                      </svg>
                    }
                    isMulti={false}
                    name="frameFiftySix"
                    isSearchable={true}
                    options={creditNoteLists}
                    value={getValues().creditNoteId}
                    placeholder="Select"
                    shape="round"
                    color="indigo_50"
                    size="xs"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setValue("creditNoteId", selectedOption);
                      }
                    }}
                  />
                  <div className="font-medium mt-2 text-xs text-red-600">
                    {errors?.creditNoteId?.message?.toString()}
                  </div>
                </div>
                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Credit Remaining
                  </label>
                  <input
                    type="text"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    {...register("creditRemaining")}
                    placeholder="Enter Credit Remaining"
                  />
                </div>
                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Transaction Description
                  </label>
                  <input
                    type="text"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    {...register("transactionDescription")}
                    placeholder="Enter Transaction Description"
                  />
                </div>
                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Refund Reason
                  </label>
                  <input
                    type="text"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    {...register("refundReason")}
                    placeholder="Enter Refund Reason"
                  />
                </div>
                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Refund Date <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                      errors.refundDate ? "is-invalid" : ""
                    }`}
                    {...register("refundDate")}
                    placeholder="Enter Transaction Date"
                  />
                  <div className="font-medium mt-2 text-xs text-red-600">
                    {errors?.refundDate?.message?.toString()}
                  </div>
                </div>
                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Refund Amount <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                      errors.refundAmount ? "is-invalid" : ""
                    }`}
                    {...register("refundAmount")}
                    placeholder="Enter Date"
                  />
                  <div className="font-medium mt-2 text-xs text-red-600">
                    {errors?.refundAmount?.message?.toString()}
                  </div>
                </div>
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Office Bank Account <span className="text-red-500">*</span>
                  </label>
                  <SelectBox
                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                    indicator={
                      <svg
                        width="15"
                        className="fill-current mr-2"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                      </svg>
                    }
                    isMulti={false}
                    name="frameFiftySix"
                    isSearchable={true}
                    options={officeBankLists}
                    value={getValues().refundAccountId}
                    placeholder="Select"
                    shape="round"
                    color="indigo_50"
                    size="xs"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setValue("refundAccountId", selectedOption);
                      }
                    }}
                  />
                  <div className="font-medium mt-2 text-xs text-red-600">
                    {errors?.refundAccountId?.message?.toString()}
                  </div>
                </div>
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Payment Type <span className="text-red-500">*</span>
                  </label>
                  <SelectBox
                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                    indicator={
                      <svg
                        width="15"
                        className="fill-current mr-2"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                      </svg>
                    }
                    isMulti={false}
                    name="frameFiftySix"
                    isSearchable={true}
                    options={clientPaymentTypeLists}
                    value={getValues().refundMethodId}
                    placeholder="Select"
                    shape="round"
                    color="indigo_50"
                    size="xs"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setValue("refundMethodId", selectedOption);
                      }
                    }}
                  />
                  <div className="font-medium mt-2 text-xs text-red-600">
                    {errors?.refundMethodId?.message?.toString()}
                  </div>
                </div>

                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Refund Reference
                  </label>
                  <input
                    type="text"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    {...register("refundReference")}
                    placeholder="Enter Refund Reference"
                  />
                </div>
                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Notes
                  </label>
                  <input
                    type="text"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    {...register("refundNotes")}
                    placeholder="Enter Notes"
                  />
                </div>
                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Client
                  </label>
                  <input
                    type="text"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    {...register("client")}
                    placeholder="Enter Client"
                  />
                </div>

                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Refund Notes Title
                  </label>
                  <SelectBox
                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                    indicator={
                      <svg
                        width="15"
                        className="fill-current mr-2"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                      </svg>
                    }
                    isMulti={false}
                    name="frameFiftySix"
                    isSearchable={true}
                    options={invoiceNoticeLists}
                    value={getValues().invoiceNoteId}
                    placeholder="Select"
                    shape="round"
                    color="indigo_50"
                    size="xs"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setValue("invoiceNoteId", selectedOption);
                      }
                    }}
                  />
                </div>
                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Invoice Notice Title
                  </label>
                  <SelectBox
                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                    indicator={
                      <svg
                        width="15"
                        className="fill-current mr-2"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                      </svg>
                    }
                    isMulti={false}
                    name="frameFiftySix"
                    isSearchable={true}
                    options={invoiceNoticeLists}
                    value={getValues().invoiceNoticeId}
                    placeholder="Select"
                    shape="round"
                    color="indigo_50"
                    size="xs"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setValue("invoiceNoticeId", selectedOption);
                      }
                    }}
                  />
                </div>
                <div className="col-start-1 relative col-span-1">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Refund Notes <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    placeholder="Refund Notes"
                    {...register("refundNotes")}
                  ></textarea>
                  <div className="font-medium mt-2 text-xs text-red-600">
                    {errors?.refundNotes?.message?.toString()}
                  </div>
                </div>
                <div className="relative col-span-1">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Invoice Notice <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    placeholder="Invoice Notice"
                    {...register("creditNoteRefundNotice")}
                  ></textarea>
                  <div className="font-medium mt-2 text-xs text-red-600">
                    {errors?.creditNoteRefundNotice?.message?.toString()}
                  </div>
                </div>
              </div>
              <div className="flex col-span-2 gap-2 justify-end my-5">
                <button
                  type="button"
                  onClick={() => {
                    Close();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]  ${
                    isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white-bg mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export { AddOfficeRefundCreditNote };
