import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import DashboardService from "../../../services/Dashboard/DashboardService";

export interface Dashboard  {
    totalClients: number;
    totalActiveCases: number;
    totalCases :number;
    totalContacts: number;
};

type DashboardState = {
    dashboard: Dashboard | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

const initialState: DashboardState = {
    dashboard: null,
    status: "idle",
    error: null
};

export const fetchDashboard = createAsyncThunk(
    "fetchDashboard",
    async (_, thunkAPI) => {
        try {
            const response = await DashboardService.GetDashboard();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


const DashboardSlice = createSlice({
    name: "DashboardSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboard.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchDashboard.fulfilled, (state, action: PayloadAction<Dashboard>) => {
                state.status = "idle";
                state.dashboard = action.payload;
            })
            .addCase(fetchDashboard.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
 
    }
});

export default DashboardSlice.reducer;
