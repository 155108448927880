import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import DisbursementTypeService from "../../../../services/Accounting/AccountSetup/DisbursementTypeService";



export interface DisbursementType {
    disbursementTypeId: number;
    disbursementCode: string;
    disbursementType: string;
    billingDescription: string;
    unitPrice: number;
    fkVatTypeId: number;
    isVatInclude: boolean;
    isActive: boolean;
};
// Define the state type for Checklist
type DisbursementTypeState = {
    disbursementTypes: DisbursementType[];
    status: "idle" | "loading" | "failed" | "Added";
    error: string | null;
};

// Initial state
const initialState: DisbursementTypeState = {
    disbursementTypes: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllDisbursementType = createAsyncThunk(
    "fetchAllDisbursementType",
    async (_, thunkAPI) => {
        try {
            const response = await DisbursementTypeService.GetAllDisbursementType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addDisbursementType = createAsyncThunk(
    "addDisbursementType",
    async (data: any, thunkAPI) => {
        try {
            const response = await DisbursementTypeService.PostDisbursementType(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateDisbursementType = createAsyncThunk(
    "updateDisbursementType",
    async ({ id, data }: { id: number, data: DisbursementType }, thunkAPI) => {
        try {
            const response = await DisbursementTypeService.PutDisbursementType(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteDisbursementType = createAsyncThunk(
    "deleteDisbursementType",
    async (id: number, thunkAPI) => {
        try {
            await DisbursementTypeService.deleteDisbursementType(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const DisbursementTypeSlice = createSlice({
    name: "disbursementType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllDisbursementType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllDisbursementType.fulfilled, (state, action: PayloadAction<DisbursementType[]>) => {
                state.status = "idle";
                state.disbursementTypes = action.payload;
            })
            .addCase(fetchAllDisbursementType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addDisbursementType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addDisbursementType.fulfilled, (state) => {
                state.status = "Added";
               // state.feeTypes.push(action.payload);
            })
            .addCase(addDisbursementType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateDisbursementType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateDisbursementType.fulfilled, (state, action: PayloadAction<DisbursementType>) => {
                state.status = "idle";
                const index = state.disbursementTypes.findIndex(t => t.disbursementTypeId === action.payload.disbursementTypeId);
                if (index !== -1) {
                    state.disbursementTypes[index] = action.payload;
                }
            })
            .addCase(updateDisbursementType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteDisbursementType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteDisbursementType.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.disbursementTypes = state.disbursementTypes.filter(t => t.disbursementTypeId !== action.payload);
            })
            .addCase(deleteDisbursementType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default DisbursementTypeSlice.reducer;
