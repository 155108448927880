import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import LeaveManagementService from "../../../../services/HumanResources/HumanResources/LeaveManagementService";

export interface LeaveManagement {

    listingData: ListingData[];
    totalCount: number;
}

export interface ListingData{
    approvedDeniedBy: number;
    approvedDeniedNotes: string | null;
    approvedDeniedOn: string | null; // or Date if handling Date objects
    attachmentFile: string;
    attachmentName: string;
    description: string;
    designation: string;
    fkLeaveTypeId: number;
    fkStaffId: number;
    leaveEndOn: string; // or Date if handling Date objects
    leaveRequestId: number;
    leaveStartOn: string; // or Date if handling Date objects
    leaveType: string | null;
    photo: string;
    requestOn: string; // or Date if handling Date objects
    requestStatus: string;
    staffLeaveId: number;
    staffName: string;
}
// Define the state type for Checklist
type LeaveManagementState = {
    leaveManagements: PaginationResponse<LeaveManagement> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: LeaveManagementState = {
    leaveManagements: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllLeaveManagement = createAsyncThunk(
    "fetchAllLeaveManagement",
    async (data: any, thunkAPI) => {
        try {
            const response = await LeaveManagementService.GetAllLeaveManagement(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addLeaveManagement = createAsyncThunk(
    "addLeaveManagement",
    async (data: any, thunkAPI) => {
        try {
            const response = await LeaveManagementService.PostLeaveManagement(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateLeaveManagement = createAsyncThunk(
    "updateLeaveManagement",
    async ({ id, data }: { id: number, data: LeaveManagement }, thunkAPI) => {
        try {
            const response = await LeaveManagementService.PutLeaveManagement(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteLeaveManagement = createAsyncThunk(
    "deleteLeaveManagement",
    async (id: number, thunkAPI) => {
        try {
            await LeaveManagementService.deleteLeaveManagement(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const LeaveManagementSlice = createSlice({
    name: "leaveManagement",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllLeaveManagement.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllLeaveManagement.fulfilled, (state, action: PayloadAction<PaginationResponse<LeaveManagement>>) => {
                state.status = "idle";
                state.leaveManagements = action.payload;
            })
            .addCase(fetchAllLeaveManagement.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addLeaveManagement.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addLeaveManagement.fulfilled, (state, action: PayloadAction<LeaveManagement>) => {
                state.status = "idle";
               // state.leaveManagements.push(action.payload);
            })
            .addCase(addLeaveManagement.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateLeaveManagement.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateLeaveManagement.fulfilled, (state, action: PayloadAction<LeaveManagement>) => {
                state.status = "idle";
                // const index = state.leaveManagements.findIndex(t => t.leaveManagementId === action.payload.leaveManagementId);
                // if (index !== -1) {
                //     state.leaveManagements[index] = action.payload;
                // }
            })
            .addCase(updateLeaveManagement.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteLeaveManagement.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteLeaveManagement.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.leaveManagements = state.leaveManagements.filter(t => t.leaveManagementId !== action.payload);
            })
            .addCase(deleteLeaveManagement.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default LeaveManagementSlice.reducer;
