import { ErrorResult } from '../../store/model/errorResult';
import axiosInstance from '../axiosInstance';



const GetAllDocumentSubCategory = async () => {
    try {
        const response = await axiosInstance.get("DocumentSubCategory");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Add a new newDocumentSubCategorys
const PostDocumentSubCategory = async (data: any) => {
    try {
        const response = await axiosInstance.post("DocumentSubCategory", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Update a DocumentSubCategory
const PutDocumentSubCategory = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`DocumentSubCategory/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Delete a DocumentSubCategory
const deleteDocumentSubCategory = async (DocumentSubCategoryId: number) => {
    try {
        await axiosInstance.delete(`/DocumentSubCategory/${DocumentSubCategoryId}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}
const DocumentSubCategoryService = {
    GetAllDocumentSubCategory,
    PostDocumentSubCategory,
    PutDocumentSubCategory,
    deleteDocumentSubCategory,
};

export default DocumentSubCategoryService;
