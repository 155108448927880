import { IoClose } from "react-icons/io5";
import { MainAnimation } from "../../loadingAnimation/MainAnimation";
import { useState } from "react";
import { AddBankReceipt } from "../../Modal/AddClientAccounts/AddClientPostings/AddBankReceipt";
import { AddNewPayment } from "../../Modal/AddClientAccounts/AddClientPostings/AddNewPayment";
import { AddClientToOffice } from "../../Modal/AddClientAccounts/AddClientPostings/AddClientToOffice";
import { AddOfficeToClient } from "../../Modal/AddClientAccounts/AddClientPostings/AddOfficeToClient";
import { AddBankToBank } from "../../Modal/AddClientAccounts/AddClientPostings/AddBankToBank";
import { AddLedgerToLedger } from "../../Modal/AddClientAccounts/AddClientPostings/AddLedgerToLedger";
import { AddProtectedFund } from "../../Modal/AddClientAccounts/AddClientPostings/AddProtectedFund";

function ReverseMainDrawer({ openCaseDrawer, Close, drawerContent, getData
}: { openCaseDrawer: boolean; Close: any; drawerContent: string; getData: any; }) {
    const [initialLoading, setInitialLoading] = useState(true);


    const renderContent = () => {
        switch (drawerContent) {
            case "bankReceipt":
                return <AddBankReceipt
                    openDrawer={openCaseDrawer}
                    Close={openCaseDrawer}
                    isEdit={false}
                    isView={false}
                    isReverse={true}
                    bankReceipt={getData}
                />;
                case "banNewPayment":
                    return   <AddNewPayment
                    openDrawer={openCaseDrawer}
                    Close={openCaseDrawer}
                    isEdit={false}
                    isView={false}
                    isReverse={true}
                    banNewPayment={getData}
                    />;
                case "clientToOffice":
                return  <AddClientToOffice
                openDrawer={openCaseDrawer}
                Close={openCaseDrawer}
                isView={false}
                isReverse={true}
                clientToOffice={getData}
                    />;
                case "officeToClient":
                return  <AddOfficeToClient
                openDrawer={openCaseDrawer}
                Close={openCaseDrawer}
                isView={false}
                isReverse={true}
                officeToClient={getData}
                    />;
                case "bankToBank":
                    return   <AddBankToBank
                    openDrawer={openCaseDrawer}
                    Close={openCaseDrawer}
                    isEdit={false}
                    isView={false}
                    isReverse={true}
                    bankToBank={getData}
                    />;
                case "ledgerToLedger":
                    return   <AddLedgerToLedger
                    openDrawer={openCaseDrawer}
                    Close={openCaseDrawer}
                    isEdit={false}
                    isView={false}
                    isReverse={true}
                    ledgerToLedger={getData}
                    />;
                case "protectedFund":
                    return   <AddProtectedFund
                    openDrawer={openCaseDrawer}
                    Close={openCaseDrawer}
                    isView={false}
                    isReverse={true}
                    protectedFund={getData}
                    />;
                    
            case "ledger":
                return <div>Ledger Content Goes Here</div>;
            case "client":
                return <div>Client Content Goes Here</div>;
            case "contact":
                return <div>Contact Content Goes Here</div>;
            default:
                return <div>Select a section to view its content</div>;
        }
    };

    return (
        <>
            <div
                className={`fixed top-0 right-0 z-40 h-auto bottom-0 border-none p-6 overflow-y-auto transition-transform duration-300 overflow-x-hidden ${openCaseDrawer
                    ? "translate-x-0 duration-300"
                    : "duration-300 translate-x-full"
                    } bg-white-bg w-[1100px] xl:w-[680px] lg:w-[350px] 2xl:[1600px] dark:bg-black drop-shadow-md border overflow-y-auto duration-500`}
                aria-labelledby="drawer-right-label"
            >

                <div className="top-0 fixed flex md:flex-col flex-row items-center justify-between md:h-auto md:p-2 w-full my-5">
                    <ul className="flex items-center justify-between gap-x-1 md:gap-x-2 sm:mb-0">
                        <li>
                            <button
                                className="text-white font-medium rounded-full shadow-md text-sm p-1.5"
                                type="button"
                                onClick={Close}
                            >
                                <IoClose className="size-6" />
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-col gap-9">
                    {renderContent()}
                </div>
            </div>
        </>
    );
}

export { ReverseMainDrawer };
