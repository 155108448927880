import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEye } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { AddSuppliersCreditNote } from "./SuppliersCreditNoteDetails/AddSuppliersCreditNote";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { fetchAllOfficeSupplierCreditNote } from "../../store/slice/Accounting/OfficeAccounts/Suppliers/OfficeSupplierCreditNoteSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "flowbite-react";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
function SuppliersCreditNote() {
  const [openModal, setOpenModal] = useState(false);
  const [officeSupplierCreditNoteData, setOfficeSupplierCreditNoteData] =
    useState<any>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const { officeSupplierCreditNotes, status, error } = useSelector(
    (state: RootState) => state.officeSupplierCreditNote
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentOfficeSupplierCreditNote, setCurrentOfficeSupplierCreditNote] =
    useState<any[]>([]);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(null);
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(null);
  const [isView, setIsView] = useState(false);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let data = {
        keyword: "",
      };
      dispatch(fetchAllOfficeSupplierCreditNote(data))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }

    if (status === "failed" && !errorShownRef.current) {
      toast.error(error);
      errorShownRef.current = true;
    }
  }, [dispatch,isOnAddedOrUpdate, status, error]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //     if(officeSupplierCreditNotes!=null && officeSupplierCreditNotes.length > 0){
  //         const filteredByOfficeSupplierCreditNote = filterOfficeSupplierCreditNoteBySearchQuery();
  //         const indexOfLastOfficeSupplierCreditNote = currentPage * itemsPerPage;
  //         const indexOfFirstOfficeSupplierCreditNote = indexOfLastOfficeSupplierCreditNote - itemsPerPage;
  //         const currentOfficeSupplierCreditNote = filteredByOfficeSupplierCreditNote.slice(indexOfFirstOfficeSupplierCreditNote, indexOfLastOfficeSupplierCreditNote);
  //         const totalPages = Math.ceil(officeSupplierCreditNotes.length / itemsPerPage);
  //         setTotalPages(totalPages)
  //         setCurrentOfficeSupplierCreditNote(currentOfficeSupplierCreditNote)
  //     }

  // },[officeSupplierCreditNotes])

  useEffect(() => {
    if (
      officeSupplierCreditNotes != null &&
      officeSupplierCreditNotes.listingData != null &&
      officeSupplierCreditNotes.listingData.length > 0
    ) {
      const totalPages = officeSupplierCreditNotes.totalRecords;
      setTotalPages(totalPages);
      setCurrentOfficeSupplierCreditNote(officeSupplierCreditNotes.listingData);
    }
  }, [officeSupplierCreditNotes]);

  // const filterOfficeSupplierCreditNoteBySearchQuery = () => {

  //     return officeSupplierCreditNotes.filter((officeSupplierCreditNote: any) =>
  //         officeSupplierCreditNote?.supplier?.toLowerCase().includes(searchQuery.toLowerCase()));
  // };

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsView(false);
    setOpenModal(!openModal);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };
  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OASupplierCreditNote"
        getData={officeSupplierCreditNoteData}
        title="Supplier Credit Note"
      />
    );
  }

  if (openModal) {
    return (
      <AddSuppliersCreditNote
        openModal={openModal}
        Close={toggleDrawer}
        isView={isView}
        officeSupplierCreditNote={officeSupplierCreditNoteData}
      />
    );
  }
  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle
            title="Credit Note"
            buttonName="Credit Note"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <SearchInput title="Keyword" />
          <div>
            <div className="flex gap-4 md:gap-1 w-full">
              <CheckBoxFilter title="Supplier" />
              <JustifyDateFilter
                title="Date Between"
                plannedStartDate={plannedStartDate}
                plannedEndDate={plannedEndDate}
                onPlannedStartDateChange={handleStartDateChange}
                onPlannedEndDateChange={handleEndDateChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden sm:rounded-lg my-5">
        <div className="overflow-x-auto max-h-96">
        <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
          {initialLoading ? <MainAnimation /> : ""}
          <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
            <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Credit Note Number
              </th>
              <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Supplier
              </th>
              <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Invoice Number
              </th>
            
              <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Description
              </th>
              <th
                scope="col"
                className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Net Amount
              </th>
              <th
                scope="col"
                className="text-center px-3 py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="text-lg 3xl:text-xs">
            {currentOfficeSupplierCreditNote.map(
              (officeSupplierCreditNote: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatDateOnlyDisplay(officeSupplierCreditNote.entryDate)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    CN{officeSupplierCreditNote.supplierCreditNoteId}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {officeSupplierCreditNote.supplierName}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {officeSupplierCreditNote.invoiceNumber}
                  </td>
                  
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {officeSupplierCreditNote.description}
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatCurrency(officeSupplierCreditNote.netAmount)}
                  </td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <FaEye className="fill-gray-500 w-6 h-6"
                    onClick={() => {
                      setOpenViewDrawer(true);
                      setIsView(true);
                      setOfficeSupplierCreditNoteData(officeSupplierCreditNote);
                    }} 
                    />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
        </div>
        {currentOfficeSupplierCreditNote.length === 0 && (
          <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
            <p className="text-red-800">
              No Supplier Credit Note Details found.
            </p>
          </div>
        )}
      </div>
      {currentOfficeSupplierCreditNote.length > 0 && (
        <div className="w-full flex flex-col pb-[100px]">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </>
  );
}

export { SuppliersCreditNote };
