import React from "react";

interface HeadingProps {
  title: string;
  icon: JSX.Element;
}
const AddItems: React.FC<HeadingProps> = ({ title, icon }) => {
  return (
    <>
      <button className="flex items-center font-semibold pl-6 py-3 border-t border-b dark:border-gray-400 dark:border-opacity-35 hover:bg-sky-100 dark:hover:bg-gray-700">
        {icon}
        <span className="mx-5 w-[227px] text-left dark:text-white-bg">
          {title}
        </span>
      </button>
    </>
  );
};

export { AddItems };
