import { Modal } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { useParams } from "react-router-dom";
import { fetchCaseCorrespondenceItems } from "../../../../../store/slice/CaseManagement/Case/Correspondence/AddItem/CorrAddItemsSlice";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";

function AddChecklist({
  openDrawer,
  Close,
}: {
  openDrawer: boolean;
  Close: any;
}) {
  const dispatch = useAppDispatch();
  const { referenceNumber } = useParams();
  const [openModal, setOpenModal] = useState(true);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const validationSchema = object().shape({});
  const caseDetailsData = useSelector((state: RootState) => state.case.caseDto);
  const caseCorrespondenceItems = useSelector((state: RootState) => state.corrAddItems.caseCorrespondenceItems);
  const [checkListData, setCheckListData] = useState<any[]>([]);

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current && referenceNumber) {
      dispatch(fetchCaseCorrespondenceItems(Number(referenceNumber)))
    }
    return () => { effectServiceCall.current = true; };
  }, [dispatch]);

  useEffect(() => {
    if (caseCorrespondenceItems !== null && caseCorrespondenceItems.length > 0) {
      setCheckListData(caseCorrespondenceItems);
    }
  }, [caseCorrespondenceItems])

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    // dispatch(AddChecklist(data));
    Close();
  };

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        Close();
      }}
      initialFocus={emailInputRef}
      size="2xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="dark:text-white-bg text-xl w-auto">
          Add Checklist
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
          <div className="space-y-3 w-full">
            <div className="bg-sky-50 dark:bg-gray-900 p-5 space-y-2">
              <div className="flex items-center">
                <label className="w-1/4 text-sm font-semibold dark:text-white-bg">
                  Case Type
                </label>
                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-3/4 text-sm dark:text-white-bg">
                  {caseDetailsData?.area}
                  {caseDetailsData?.caseType && ` > ${caseDetailsData.caseType}`}
                  {caseDetailsData?.subCaseType && ` > ${caseDetailsData.subCaseType}`}
                </p>
              </div>
              <div className="flex items-center">
                <label className="w-1/4 text-sm font-semibold dark:text-white-bg">
                  Case Ref
                </label>
                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-3/4 text-sm dark:text-white-bg">
                  {caseDetailsData?.caseReferenceAuto}
                </p>
              </div>
            </div>
            <div className="relative overflow-x-auto sm:rounded-lg">
              <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <th scope="col" className="px-6 py-3">
                      Item Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Received
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Checked
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Notes
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {checkListData && checkListData.map((item: any, index) => (
                    <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="px-6 py-3">
                        {item?.checklistItemName}
                      </td>
                      <td className="px-6 py-3">
                        <input
                          type="date"
                          max={new Date().toISOString().split("T")[0]}
                          className={`border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full p-3 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        />
                      </td>
                      <td className="px-6 py-3">
                        <input
                          type="date"
                          max={new Date().toISOString().split("T")[0]}
                          className={`border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full p-3 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        />
                      </td>
                      <td className="px-6 py-3">
                        <input
                          type="text"
                          placeholder="Enter Note"
                          className={`border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full p-3 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  onClick={() => {
                    reset();
                    setOpenModal(Close);
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddChecklist };
