import axiosInstance from '../axiosInstance';
import { ErrorResult } from '../../store/model/errorResult';


const GetAllCaseReferenceFormat = async () => {
    try {
        const response = await axiosInstance.get("CaseReferenceFormat");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch CaseReferenceFormats:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch CaseReferenceFormat");
    }
}

// Add a new CaseReferenceFormat
const PostCaseReferenceFormat = async (data: any) => {
    try {
        const response = await axiosInstance.post("CaseReferenceFormat", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Update a CaseReferenceFormat
const PutCaseReferenceFormat = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`CaseReferenceFormat/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Delete a CaseReferenceFormat
const deleteCaseReferenceFormat = async (caseReferenceFormatId: number) => {
    try {
        await axiosInstance.delete(`/CaseReferenceFormat/${caseReferenceFormatId}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const CaseReferenceFormatService = {
    GetAllCaseReferenceFormat,
    PostCaseReferenceFormat,
    PutCaseReferenceFormat,
    deleteCaseReferenceFormat,
};

export default CaseReferenceFormatService;
