import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import QuotationRequestService from "../../../../services/RiskManagement/RiskAssessment/QuotationRequestService";
import { RAQRGetData } from "../../../model/RiskAssessment/RiskAssessmentData/RAQRInterface";



export interface QuotationRequest {
    quotationRequestId: number;
    quotationRequestNumber: number;
    fkAreaOfPracticeId: number;
    fkCaseTypeId: number;
    fkCaseSubTypeId: number;
    quotationRequestDate: string;
    clientStatus: string;
    fkClientId: number;
    contactType: string;
    clientAddress: string;
    noOfClients: string;
    fkContactTitleId: number;
    contactFirstName: string;
    contactLastName: string;
    contactEmail: string;
    contactMobile: string;
    contactPhone: string;
    contactCompanyName: string;
    attachmentName: string;
    attachmentFile: string;
    contactNotes: string;
    fkCurrentStatus: string;

    postCode: string;
    propertyAddress: string;
    propertyValue: string;
    tenureType: string;
    purchaseType: string;
    fkConditionTypeIds?: number[];

};

// Define the state type for casetype
type QuotationState = {
    quotationRequest: QuotationRequest[];
    quotationRequestNumber: number;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: QuotationState = {
    quotationRequest: [],
    quotationRequestNumber: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllQuotationRequest = createAsyncThunk(
    "fetchAllQuotationRequest",
    async (data: RAQRGetData, thunkAPI) => {
        try {
            const response = await QuotationRequestService.GetAllQuotationRequest(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchQuotationRequestNumber = createAsyncThunk(
    "fetchQuotationRequestNumber",
    async (_, thunkAPI) => {
        try {
            const response = await QuotationRequestService.GetQuotationRequestNumber();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addQuotationRequest = createAsyncThunk(
    "addQuotationRequest",
    async (data: any, thunkAPI) => {
        try {
            const response = await QuotationRequestService.PostQuotationRequest(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateQuotationRequest = createAsyncThunk(
    "updateQuotationRequest",
    async ({ id, data }: { id: number, data: QuotationRequest }, thunkAPI) => {
        try {
            const response = await QuotationRequestService.PutQuotationRequest(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteQuotationRequest = createAsyncThunk(
    "deleteQuotationRequest",
    async (QuotationRequestId: number, thunkAPI) => {
        try {
            await QuotationRequestService.deleteQuotationRequest(QuotationRequestId);
            return QuotationRequestId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const QuotationRequestSlice = createSlice({
    name: "quotationRequest",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllQuotationRequest.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllQuotationRequest.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.status = "idle";
                state.quotationRequest = action.payload;
            })
            .addCase(fetchAllQuotationRequest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchQuotationRequestNumber.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchQuotationRequestNumber.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.quotationRequestNumber = action.payload;
            })
            .addCase(fetchQuotationRequestNumber.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addQuotationRequest.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addQuotationRequest.fulfilled, (state, action: PayloadAction<QuotationRequest>) => {
                state.status = "idle";
                state.quotationRequest.push(action.payload);
            })
            .addCase(addQuotationRequest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateQuotationRequest.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateQuotationRequest.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                if (action.payload != null) {
                    state.quotationRequest = action.payload || [];
                }
            })
            .addCase(updateQuotationRequest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteQuotationRequest.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteQuotationRequest.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
            })
            .addCase(deleteQuotationRequest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default QuotationRequestSlice.reducer;
