  export const generateApiKeyInBrowser = (length: number = 32): string => {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    
    // Convert the random values to a hex string
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
  };


  export const  generateRandomPassword = (): string => {
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const special = '!@#$%^&*()_+-=[]{}|;:,.<>?';
    
    const allChars = uppercase + lowercase + numbers + special;
    let newPassword = '';
    
    // Ensure at least one character from each category
    newPassword += uppercase[Math.floor(Math.random() * uppercase.length)];
    newPassword += lowercase[Math.floor(Math.random() * lowercase.length)];
    newPassword += numbers[Math.floor(Math.random() * numbers.length)];
    newPassword += special[Math.floor(Math.random() * special.length)];
    
    // Fill the remaining 4 characters
    for (let i = 0; i < 4; i++) {
      newPassword += allChars[Math.floor(Math.random() * allChars.length)];
    }
    
    newPassword = newPassword
      .split('')
      .sort(() => Math.random() - 0.5)
      .join('');

      return newPassword
  }
