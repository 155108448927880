import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CaseManagementService from "../../../../services/CaseManagement/Case/CaseManagementService";
import { CaseAML, CaseDto, Case, StaffsInvolved, CaseClient, CaseContactData, CaseDetailsList, CaseNumber, CasePermission, ClientGreeting, Permission, PermittedStafs, RiskAssessment, RiskTypeStatus } from "../../../model/CaseManagement/CaseInterface";


export interface FileReview {

}



// Define the state type for cases
type CaseState = {
    fileReview: FileReview[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CaseState = {
    fileReview: null!,
    status: "idle",
    error: null
};


export const fetchFileReviewById = createAsyncThunk(
    "fetchFileReviewById",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseManagementService.GetCaseClient(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);




// Slice definition
const FileReviewSlice = createSlice({
    name: "fileReview",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFileReviewById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchFileReviewById.fulfilled, (state, action: PayloadAction<FileReview[]>) => {
                state.status = "idle";
                state.fileReview = action.payload;
            })
            .addCase(fetchFileReviewById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

    }
});

export default FileReviewSlice.reducer;
