import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import OfficeSupplierCreditNoteService from "../../../../../services/Accounting/OfficeAccount/Suppliers/OfficeSupplierCreditNoteService";
import { PaginationResponse } from "../../../../model/Pagination/paginationClass";

export interface OfficeSupplierCreditNote{
 lisitingData: ListingData[];
 totalRecords: number;

}


export interface ListingData {
    supplierCreditNoteId: number;
    supplierCreditNumber: number;
    fkSupplierId: number;
    //supplier: string;
    invoice: string;
    netAmount: number;
    vat: number;
    grossAmount: number;
    description: string;
    accountBalance: string;
    transactionDescription: string;
    transactionDate: string;
    entryDate: string;
  fkSupplierBillEntryId: number;
  creditNoteDate: string; // ISO date string format
  creditNoteLineItems: CreditNoteLineItem[];
   
  
};

export interface CreditNoteLineItem{
    lineId: number;
    accountId: number;
    description: string;
    netAmount: number;
    fkVatTypeId: number;
    vat: number;
    grossAmount: number;
    lineOrder: number;
}
// Define the state type for Checklist
type OfficeSupplierCreditNoteState = {
    officeSupplierCreditNotes: PaginationResponse<OfficeSupplierCreditNote> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: OfficeSupplierCreditNoteState = {
    officeSupplierCreditNotes: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllOfficeSupplierCreditNote = createAsyncThunk(
    "fetchAllOfficeSupplierCreditNote",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeSupplierCreditNoteService.GetAllOfficeSupplierCreditNote(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addOfficeSupplierCreditNote = createAsyncThunk(
    "addOfficeSupplierCreditNote",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeSupplierCreditNoteService.PostOfficeSupplierCreditNote(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateOfficeSupplierCreditNote = createAsyncThunk(
    "updateOfficeSupplierCreditNote",
    async ({ id, data }: { id: number, data: OfficeSupplierCreditNote }, thunkAPI) => {
        try {
            const response = await OfficeSupplierCreditNoteService.PutOfficeSupplierCreditNote(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteOfficeSupplierCreditNote = createAsyncThunk(
    "deleteOfficeSupplierCreditNote",
    async (id: number, thunkAPI) => {
        try {
            await OfficeSupplierCreditNoteService.deleteOfficeSupplierCreditNote(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const OfficeSupplierCreditNoteSlice = createSlice({
    name: "officeSupplierCreditNote",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllOfficeSupplierCreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllOfficeSupplierCreditNote.fulfilled, (state, action: PayloadAction<PaginationResponse<OfficeSupplierCreditNote>>) => {
                state.status = "idle";
                state.officeSupplierCreditNotes = action.payload;
            })
            .addCase(fetchAllOfficeSupplierCreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addOfficeSupplierCreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addOfficeSupplierCreditNote.fulfilled, (state, action: PayloadAction<PaginationResponse<OfficeSupplierCreditNote>>) => {
                state.status = "idle";
                //state.officeSupplierCreditNotes.push(action.payload);
            })
            .addCase(addOfficeSupplierCreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateOfficeSupplierCreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateOfficeSupplierCreditNote.fulfilled, (state, action: PayloadAction<OfficeSupplierCreditNote>) => {
                state.status = "idle";
                // const index = state.officeSupplierCreditNotes.findIndex(t => t.officeSupplierCreditNoteId === action.payload.officeSupplierCreditNoteId);
                // if (index !== -1) {
                //     state.officeSupplierCreditNotes[index] = action.payload;
                // }
            })
            .addCase(updateOfficeSupplierCreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteOfficeSupplierCreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteOfficeSupplierCreditNote.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.officeSupplierCreditNotes = state.officeSupplierCreditNotes.filter(t => t.officeSupplierCreditNoteId !== action.payload);
            })
            .addCase(deleteOfficeSupplierCreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default OfficeSupplierCreditNoteSlice.reducer;
