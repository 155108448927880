import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";




const GetAllJournalEntry = async () => {
    try {
        const response = await axiosInstance.post("JournalEntrySearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetJournalEntryById = async (id: number) => {
    try {
        const response = await axiosInstance.get("JournalEntry/byid/" + id);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const JournalEntryNumber = async () => {
    try {
        const response = await axiosInstance.get("JournalEntryNumber");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostJournalEntry = async (data: any) => {
    try {
        const response = await axiosInstance.post("JournalEntry", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutJournalEntry = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`JournalEntry/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteJournalEntry = async (id: number) => {
    try {
        await axiosInstance.delete(`/journalentry/delete/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const JournalEntryService = {
    GetAllJournalEntry,
    PostJournalEntry,
    PutJournalEntry,
    deleteJournalEntry,
    JournalEntryNumber,
    GetJournalEntryById
};


export default JournalEntryService;
