import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import OfficeAccountReferenceNumberService from "../../../../services/Accounting/OfficeAccount/OfficeAccountReferenceNumberService";

export interface NominalAccountResponse{
    totalCount: number;
    accounts: OfficeAccountReferenceNumber[];
}

export interface OfficeAccountReferenceNumber {
   
        accountId: number;
        accountCode: number;
        accountName: string;
        fkAccountTypeId: number;
        isEditable: boolean;
        isActive: boolean;
        accountTypeId: number;
        accountType: string;
        range: string;
        fkFinancialStatementId: number;
        toIncrease: "DR" | "CR"; // assuming "DR" and "CR" are the only valid values
        accountOrder: number;
        totalCr: number | null;
        totalDr: number | null;
        runningBalance: number | null;
        financialStatement: string;
  
      
    

};
// Define the state type for Checklist
type OfficeAccountReferenceNumberState = {
    officeAccountReferenceNumbers : NominalAccountResponse | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: OfficeAccountReferenceNumberState = {
    officeAccountReferenceNumbers: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllOfficeAccountReferenceNumber = createAsyncThunk(
    "fetchAllOfficeAccountReferenceNumber",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeAccountReferenceNumberService.GetAllOfficeAccountReferenceNumber(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const OfficeAccountReferenceNumberSlice = createSlice({
    name: "officeAccountReferenceNumber",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllOfficeAccountReferenceNumber.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllOfficeAccountReferenceNumber.fulfilled, (state, action: PayloadAction<any>) => {
            state.status = "idle";
            state.officeAccountReferenceNumbers = action.payload;
        })
        .addCase(fetchAllOfficeAccountReferenceNumber.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })

    }
});

export default OfficeAccountReferenceNumberSlice.reducer;