import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import QuotationNoticeService from "../../../../services/Accounting/AccountSetup/QuotationNoticeService";



export interface QuotationNotice {
    quotationNoticeId: number;
    title: string;
    quotationNotice: string,

};
// Define the state type for Checklist
type QuotationNoticeState = {
    quotationNotices: QuotationNotice[];
    status: "idle" | "loading" | "failed" | "Added";
    error: string | null;
};

// Initial state
const initialState: QuotationNoticeState = {
    quotationNotices: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllQuotationNotice = createAsyncThunk(
    "fetchAllQuotationNotice",
    async (_, thunkAPI) => {
        try {
            const response = await QuotationNoticeService.GetAllQuotationNotice();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addQuotationNotice = createAsyncThunk(
    "addQuotationNotice",
    async (data: any, thunkAPI) => {
        try {
            const response = await QuotationNoticeService.PostQuotationNotice(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateQuotationNotice = createAsyncThunk(
    "updateQuotationNotice",
    async ({ id, data }: { id: number, data: QuotationNotice }, thunkAPI) => {
        try {
            const response = await QuotationNoticeService.PutQuotationNotice(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteQuotationNotice = createAsyncThunk(
    "deleteQuotationNotice",
    async (id: number, thunkAPI) => {
        try {
            await QuotationNoticeService.deleteQuotationNotice(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const QuotationNoticeSlice = createSlice({
    name: "quotationNotice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllQuotationNotice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllQuotationNotice.fulfilled, (state, action: PayloadAction<QuotationNotice[]>) => {
                state.status = "idle";
                state.quotationNotices = action.payload;
            })
            .addCase(fetchAllQuotationNotice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addQuotationNotice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addQuotationNotice.fulfilled, (state) => {
                state.status = "Added";
               // state.feeTypes.push(action.payload);
            })
            .addCase(addQuotationNotice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateQuotationNotice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateQuotationNotice.fulfilled, (state, action: PayloadAction<QuotationNotice>) => {
                state.status = "idle";
                const index = state.quotationNotices.findIndex(t => t.quotationNoticeId === action.payload.quotationNoticeId);
                if (index !== -1) {
                    state.quotationNotices[index] = action.payload;
                }
            })
            .addCase(updateQuotationNotice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteQuotationNotice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteQuotationNotice.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.quotationNotices = state.quotationNotices.filter(t => t.quotationNoticeId !== action.payload);
            })
            .addCase(deleteQuotationNotice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default QuotationNoticeSlice.reducer;
