import { ErrorResult } from '../../../store/model/errorResult';
import axiosInstance from '../../axiosInstance';



const GetAllPropertyConditionType = async () => {
    try {
        const response = await axiosInstance.get("PropertyConditionType");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PropertyConditionTypeService = {
    GetAllPropertyConditionType,

};

export default PropertyConditionTypeService;
