import { FaPlus } from "react-icons/fa6";
function Invoice() {
  return (
    <>
      <div className="flex flex-row md:flex-row items-start justify-between mb-5">
        <h1 className="text-xl font-bold text-left md:text-lg dark:text-white-bg">
          Invoice
        </h1>
        <button
          className="flex items-center bg-blue-500 text-white-bg px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
          type="button"
        >
          <FaPlus className="w-4 h-4 fill-white-bg" />
          <span className="text-white-bg mx-2">Add Invoice</span>
        </button>
      </div>
      <div className="relative overflow-x-auto sm:rounded-lg">
        <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
          <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <th scope="col" className="px-6 py-3">
                Assigned By
              </th>
              <th scope="col" className="px-6 py-3">
                Priority
              </th>
              <th scope="col" className="px-6 py-3">
                Task
              </th>
              <th scope="col" className="px-6 py-3">
                Planned Date
              </th>
              <th scope="col" className="px-6 py-3">
                Due Date
              </th>
              <th scope="col" className="px-6 py-3">
                Assigned To
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <td className="px-6 py-3"></td>
              <td className="px-6 py-3"></td>
              <td className="px-6 py-3"></td>
              <td className="px-6 py-3"></td>
              <td className="px-6 py-3"></td>
              <td className="px-6 py-3"></td>
              <td className="px-6 py-3"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Invoice;
