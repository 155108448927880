import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import TimeEntryTypeService from "../../../../services/Accounting/AccountSetup/TimeEntryTypeService";
import CaseTypeIncomeLedgerService from "../../../../services/Accounting/AccountSetup/CaseTypeIncomeLedgerService";



// Define the state type for Checklist
type CaseTypeIncomeLedgerState = {
    caseTypesDropdown: any[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    incomeLedgers: any[]
};

// Initial state
const initialState: CaseTypeIncomeLedgerState = {
    caseTypesDropdown: [],
    status: "idle",
    error: null,
    incomeLedgers: []
};

// Async thunks for CRUD operations
export const fetchAllCaseTypeIncomeLedger= createAsyncThunk(
    "fetchAllCaseTypeIncomeLedger",
    async (_, thunkAPI) => {
        try {
            const response = await CaseTypeIncomeLedgerService.GetAllCaseTypeIncomeLedger();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllCaseTypeIncomeLedgerDropdown = createAsyncThunk(
    "fetchAllCaseTypeIncomeLedgerDropdown",
    async (_, thunkAPI) => {
        try {
            const response = await CaseTypeIncomeLedgerService.GetAllCaseTypeIncomeLedgerDropdown();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCaseTypeIncomeLedger = createAsyncThunk(
    "addCaseTypeIncomeLedger",
    async (data: any[], thunkAPI) => {
        try {
            const response = await CaseTypeIncomeLedgerService.PostCaseTypeIncomeLedger(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



// Slice definition
const CaseTypeIncomeLedgerDataSlice = createSlice({
    name: "caseTypeIncomeLedger",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllCaseTypeIncomeLedgerDropdown.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllCaseTypeIncomeLedgerDropdown.fulfilled, (state, action: PayloadAction<any[]>) => {
            state.status = "idle";
            state.caseTypesDropdown = action.payload;
        })
        .addCase(fetchAllCaseTypeIncomeLedgerDropdown.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })
            .addCase(fetchAllCaseTypeIncomeLedger.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCaseTypeIncomeLedger.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.status = "idle";
                state.incomeLedgers = action.payload;
            })
            .addCase(fetchAllCaseTypeIncomeLedger.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCaseTypeIncomeLedger.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCaseTypeIncomeLedger.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                //state.timeEntryTypes.push(action.payload);
            })
            .addCase(addCaseTypeIncomeLedger.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

           
    }
});

export default CaseTypeIncomeLedgerDataSlice.reducer;
