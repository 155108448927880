import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllOfficeJournalEntry = async () => {
    try {
        const response = await axiosInstance.post("OAJournalTransactionsSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostOfficeJournalEntry = async (data: any) => {
    try {
        const response = await axiosInstance.post("OAJournalSaveOrUpdate", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutOfficeJournalEntry = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`OfficeJournalEntry/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteOfficeJournalEntry = async (id: number) => {
    try {
        await axiosInstance.delete(`/OfficeJournalEntry/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const OfficeJournalEntryService = {
    GetAllOfficeJournalEntry,
    PostOfficeJournalEntry,
    PutOfficeJournalEntry,
    deleteOfficeJournalEntry
};


export default OfficeJournalEntryService;
