import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GroupedOperations, Menu, Operation } from "../../../store/model/Menu/menuResult";
import { useEffect, useState } from "react";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";

function AddRoleStepTwo({ onSubmitStep, initialData, onPreviousStep, menu, isLoading, onCancel, firstHalfMenu, secondHalfMenu, operationsMenu }: any) {

    const [openMenuId, setOpenMenuId] = useState<number | null>(null);
    const [openOperationId, setOpenOperationId] = useState<number | null>(null);
    const [selectedMenus, setSelectedMenus] = useState<number[]>([]); 
    const [selectedOperations, setselectedOperations] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState(false); 
    const [selectAllOperation, setSelectAllOperation] = useState(false);
    const roleById = useSelector((state: RootState) => state.role.roleById);

    const validationSchema = object().shape({
        isActive: string()
    });

    const { handleSubmit, setValue, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialData
    });

    useEffect(() => {
        if (roleById !== null) {
            setValue("isActive", roleById?.isActive);
            if(roleById.fkMenuId!=null){
                let menus = roleById.fkMenuId.split(",");
                setSelectedMenus(menus.map(x=> {return Number(x)}))
            }
            if(roleById.fkOperationId!=null){
                let operations = roleById.fkOperationId.split(",");
                setselectedOperations(operations.map(x=> {return Number(x)}))
            }
        }
    }, [roleById])

    const handleFormSubmit = (data: any) => {
        data.fkMenuId = selectedMenus.join(', ');
        data.fkOperationId = selectedOperations.join(', ');
        data.isActive = true;
        onSubmitStep(data);
    };

    const toggleAccordion = (menuId: number) => {
        setOpenMenuId((prevMenuId) => (prevMenuId === menuId ? null : menuId));
    };

    const toggleOperationAccordion = (fkAreaId: number) => {
        setOpenOperationId((prevMenuId) => (prevMenuId === fkAreaId ? null : fkAreaId));
    };

    const toggleMOperationSelection = (obj: Operation) => {
        const operatonId = obj.operationsId;
        let updatedSelectedOperations = [...selectedOperations];

        if (selectedOperations.includes(operatonId)) {
            updatedSelectedOperations = updatedSelectedOperations.filter((id) => id !== operatonId);
        } else {
            updatedSelectedOperations.push(operatonId);
        }

        setselectedOperations(updatedSelectedOperations);
    }


    const toggleMenuSelection = (menu: Menu) => {
        const menuId = menu.menuId;
        let updatedSelectedMenus = [...selectedMenus];

        if (selectedMenus.includes(menuId)) {
            updatedSelectedMenus = updatedSelectedMenus.filter((id) => id !== menuId);
            if (menu.subMenu) {
                updatedSelectedMenus = updatedSelectedMenus.filter(
                    (id) => !menu.subMenu?.some((sub: any) => sub.menuId === id)
                );
            }
        } else {
            updatedSelectedMenus.push(menuId);
            if (menu.subMenu) {
                updatedSelectedMenus = [
                    ...updatedSelectedMenus,
                    ...menu.subMenu.map((sub: any) => sub.menuId),
                ];
            }
        }

        setSelectedMenus(updatedSelectedMenus);
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedMenus([]);
        } else {
            let allMenuIds: number[] = [];
            menu.forEach((m: any) => {
                allMenuIds.push(m.menuId);
                if (m.subMenu) {
                    allMenuIds = [...allMenuIds, ...m.subMenu.map((sub: any) => sub.menuId)];
                }
            });
            setSelectedMenus(allMenuIds);
        }
        setSelectAll(!selectAll);
    };

    const handleSelectAllOperation = () => {
        if (selectAllOperation) {
            setselectedOperations([]);
        } else {
            let allOperationIds: number[] = [];
            operationsMenu.forEach((m: any) => {
                if (m.operations) {
                    allOperationIds = [...allOperationIds, ...m.operations.map((sub: any) => sub.operationsId)];
                }
            });
            setselectedOperations(allOperationIds);
        }
        setSelectAllOperation(!selectAllOperation);
    };

    const handleSelectAllSubMenus = (menu: Menu) => {
        let updatedSelectedMenus = [...selectedMenus];

        if (menu.subMenu) {
            const allSubMenuIds = menu.subMenu.map((sub) => sub.menuId);
            const isAllSelected = allSubMenuIds.every((id) => selectedMenus.includes(id));

            if (isAllSelected) {
                updatedSelectedMenus = updatedSelectedMenus.filter((id) => !allSubMenuIds.includes(id));
            } else {
                updatedSelectedMenus = [...updatedSelectedMenus, ...allSubMenuIds];
            }
        }

        setSelectedMenus(updatedSelectedMenus);
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="grid grid-cols-12 md:grid-cols-1 gap-10">
                <div className="col-span-8">
                    <div >
                        <div className="flex items-center justify-between mb-5">
                            <h3 className="text-lg font-bold text-left text-gray-900 dark:text-white-bg">Menu Permissions</h3>
                            <div className="">
                                {/* <SearchInput title="Search Permission" /> */}
                                <div className="">
                                    <label className="mr-2 text-sm font-semibold dark:text-gray-400">Select All</label>
                                    <input
                                        type="checkbox"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dark:text-white-bg my-5 grid grid-cols-2 md:grid-cols-1 gap-2">
                        <div>
                            {firstHalfMenu.map((menus: Menu) => (
                                <>
                                    <div key={menus.menuId} className="mb-2">
                                        {
                                            (menus.subMenu && menus.subMenu.length > 0) ? (
                                                <button type="button" onClick={() => toggleAccordion(menus.menuId)} className="flex items-center justify-between w-full p-2 text-sm font-medium rtl:text-right border border-gray-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-sky-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-color-body-1" aria-expanded="true" aria-controls="accordion-color-body-1">
                                                    <span>{menus.menuName}</span>
                                                    <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                    </svg>
                                                </button>) :
                                                <button type="button" className="flex items-center justify-between w-full  p-2 text-sm font-medium rtl:text-right border border-gray-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-sky-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-color-body-1" aria-expanded="true" aria-controls="accordion-color-body-1">
                                                    <span>{menus.menuName}</span>
                                                    <input type="checkbox" onChange={() => toggleMenuSelection(menus)} checked={selectedMenus.includes(menus.menuId)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                </button>

                                        }


                                        {
                                            (menus.subMenu && menus.subMenu.length > 0) &&
                                            <>
                                                <div id="accordion-color-body-1" className={(openMenuId == menus.menuId) ? '' : 'hidden'} aria-labelledby="accordion-color-heading-1">
                                                    <a
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleSelectAllSubMenus(menus);
                                                        }}
                                                        className="text-xs text-blue-500 hover:underline"
                                                    >
                                                        {selectedMenus.some((id) => menu.subMenu?.some((sub: any) => sub.menuId === id))
                                                            ? 'Deselect All Submenus'
                                                            : 'Select All Submenus'}
                                                    </a>
                                                    <div className="p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                                                        {menus.subMenu.map((item: any, suindex: number) => {
                                                            return <>
                                                                <button key={suindex} type="button" className="flex items-center justify-between w-full  p-2 text-sm font-medium rtl:text-right border border-gray-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-sky-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-color-body-1" aria-expanded="true" aria-controls="accordion-color-body-1">
                                                                    <span>{item.menuName}</span>
                                                                    <input type="checkbox" checked={selectedMenus.includes(item.menuId)} onChange={() => toggleMenuSelection(item)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                </button>
                                                            </>
                                                        })}
                                                    </div>
                                                </div>
                                            </>

                                        }

                                    </div>
                                </>
                            ))}
                        </div>
                        <div>
                            {secondHalfMenu.map((menus: Menu) => (
                                <>
                                    <div key={menus.menuId} className="mb-2">
                                        {
                                            (menus.subMenu && menus.subMenu.length > 0) ? (
                                                <button type="button" onClick={() => toggleAccordion(menus.menuId)} className="flex items-center justify-between w-full p-2 text-sm font-medium rtl:text-right border border-gray-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-sky-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-color-body-1" aria-expanded="true" aria-controls="accordion-color-body-1">
                                                    <span>{menus.menuName}</span>
                                                    <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                    </svg>
                                                </button>) :
                                                <button type="button" className="flex items-center justify-between w-full  p-2 text-sm font-medium rtl:text-right border border-gray-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-sky-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-color-body-1" aria-expanded="true" aria-controls="accordion-color-body-1">
                                                    <span>{menus.menuName}</span>
                                                    <input type="checkbox" onChange={() => toggleMenuSelection(menus)} checked={selectedMenus.includes(menus.menuId)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                </button>

                                        }


                                        {
                                            (menus.subMenu && menus.subMenu.length > 0) &&

                                            <div id="accordion-color-body-1" className={(openMenuId == menus.menuId) ? '' : 'hidden'} aria-labelledby="accordion-color-heading-1">
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleSelectAllSubMenus(menus);
                                                    }}
                                                    className="text-xs text-blue-500 hover:underline"
                                                >
                                                    {selectedMenus.some((id) => menu.subMenu?.some((sub: any) => sub.menuId === id))
                                                        ? 'Deselect All Submenus'
                                                        : 'Select All Submenus'}
                                                </a>
                                                <div className="p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                                                    {menus.subMenu.map((item: any, suindex: number) => {
                                                        return <>
                                                            <button key={suindex} type="button" className="flex items-center justify-between w-full  p-2 text-sm font-medium rtl:text-right border border-gray-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-sky-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-color-body-1" aria-expanded="true" aria-controls="accordion-color-body-1">
                                                                <span>{item.menuName}</span>
                                                                <input type="checkbox" checked={selectedMenus.includes(item.menuId)} onChange={() => toggleMenuSelection(item)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                            </button>
                                                        </>
                                                    })}
                                                </div>
                                            </div>


                                        }

                                    </div>

                                </>
                            ))}
                        </div>

                    </div>
                </div>
                <div className="col-span-4">
                    <div>
                        <div className="flex items-center justify-between mb-5">
                            <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">Operational Permissions</h3>
                            <div className="">
                                {/* <SearchInput title="Search Permission" /> */}
                                <div className="">
                                    <label className="ml-2 text-sm font-semibold dark:text-gray-400">Select All </label>
                                    <input
                                        type="checkbox"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        checked={selectAllOperation}
                                        onChange={handleSelectAllOperation}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dark:text-white-bg my-5">
                        {operationsMenu.map((menus: GroupedOperations) => (
                            <>
                                <div key={menus.fkOperationalAreaId} className="mb-2">
                                    {
                                        <h2>
                                            <button type="button" onClick={() => toggleOperationAccordion(menus.fkOperationalAreaId)} className="flex items-center justify-between w-full p-2 text-sm font-medium rtl:text-right border border-gray-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-sky-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-color-body-1" aria-expanded="true" aria-controls="accordion-color-body-1">
                                                <span>{menus.operationalArea}</span>
                                                <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                                </svg>
                                            </button>
                                        </h2>
                                    }


                                    {
                                        (menus.operations && menus.operations.length > 0) &&

                                        <div id="accordion-color-body-1" className={(openOperationId == menus.fkOperationalAreaId) ? '' : 'hidden'} aria-labelledby="accordion-color-heading-1">

                                            <div className="p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                                                {menus.operations.map((item: any, suindex: number) => {
                                                    return <>
                                                        <button key={suindex} type="button" className="flex items-center justify-between w-full  p-2 text-sm font-medium rtl:text-right border border-gray-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-sky-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-color-body-1" aria-expanded="true" aria-controls="accordion-color-body-1">
                                                            <span>{item.operation}</span>
                                                            <input type="checkbox" checked={selectedOperations.includes(item.operationsId)} onChange={() => toggleMOperationSelection(item)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                        </button>
                                                    </>
                                                })}
                                            </div>
                                        </div>


                                    }

                                </div>
                            </>
                        ))}

                        {/* <h2>
                            <button type="button" className="flex items-center justify-between w-full p-2 text-sm font-medium rtl:text-right border border-gray-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-sky-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-color-body-1" aria-expanded="true" aria-controls="accordion-color-body-1">
                                <span>Administrator</span>
                                <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                </svg>
                            </button>
                        </h2>
                        <div id="accordion-color-body-1" className="hidden"  aria-labelledby="accordion-color-heading-1">
                            <div className="p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                                <p className="mb-2 dark:text-gray-400">User Role Management</p>
                            </div>
                        </div>
                        <h2>
                            <button type="button" className="flex items-center justify-between w-full p-2 text-sm font-medium rtl:text-right border border-gray-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-sky-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-color-body-1" aria-expanded="true" aria-controls="accordion-color-body-1">
                                <span>System Maintenance</span>
                                <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                </svg>
                            </button>
                        </h2> */}

                    </div>
                    {/* <div className="flex flex-row space-x-10">
                        <label className="text-sm font-medium text-gray-900 dark:text-white-bg">Is Active</label>
                        <div>
                            <input
                                id="bordered-radio-1"
                                type="radio"
                                value="true"
                                {...register('isActive')}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                        </div>
                        <div>
                            <input
                                id="bordered-radio-1"
                                type="radio"
                                value="false"
                                {...register('isActive')}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                        </div>

                    </div> */}
                </div>
            </div>

            <div className="">
                <div className="flex flex-row gap-2 items-center justify-between w-full">
                    <button
                        onClick={onPreviousStep}
                        type="button"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[140px]">
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                        Previous
                    </button>
                    <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                        <div className="flex flex-row gap-2 items-end justify-end w-full">

                            <button
                                type="button"
                                onClick={onCancel}
                                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                            >
                                Cancel
                            </button>
                            {/* <button
                                type="submit"
                                className="cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]"
                            >
                                Save
                            </button> */}
                            <button
                                type="submit"
                                disabled={isLoading}
                                className={`cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isLoading ? (
                                    <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                    </svg>
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export { AddRoleStepTwo };


