import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllRefundCreditNote = async () => {
    try {
        const response = await axiosInstance.post("OACpRefundCreditNoteSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostRefundCreditNote = async (data: any) => {
    try {
        const response = await axiosInstance.post("OACpRefundCreditNoteSaveOrUpdate", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutRefundCreditNote = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`RefundCreditNote/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteRefundCreditNote = async (id: number) => {
    try {
        await axiosInstance.delete(`/RefundCreditNote/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const RefundCreditNoteService = {
    GetAllRefundCreditNote,
    PostRefundCreditNote,
    PutRefundCreditNote,
    deleteRefundCreditNote
};


export default RefundCreditNoteService;
