import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../../Filters/CheckBoxFilter";
import { SearchInput } from "../../Filters/SearchInput";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineRefresh } from "react-icons/md";
import { AddNewPayment } from "../../Modal/AddClientAccounts/AddClientPostings/AddNewPayment";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import {
  deleteBankNewPayment,
  fetchAllBankNewPayment,
} from "../../../store/slice/Accounting/ClientAccounts/ClientPosting/BankPayment/BankNewPaymentSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../../loadingAnimation/MainAnimation";
import { RootState } from "../../../store/store";
import { SubTitle } from "../../SubTitle";
import { BankReceiptModel } from "../../../store/model/Accounting/ClientAccount/ClientBankReceipt";
import { Pagination } from "../../Pagination";
import DeleteModal from "../../Modal/DeleteModal";
import { JustifyDateFilter } from "../../Filters/JustifyDateFilter";
import { ReverseMainDrawer } from "../../Drawer/ReverseMainDrawer";
import { ViewMainDrawer } from "../../Drawer/ViewMainDrawer";
import { ViewClientOfficeDrawer } from "../../Drawer/clientPostingViewDrawer";
import { formatCurrency } from "../../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../../utils/date-time.utils";

const NewPayment = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState("NewPayment");
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const banNewPayments = useSelector(
    (state: RootState) => state.bankNewPayment.banNewPayments
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [bankNewPaymentData, setBankNewPaymentData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentBankNewPayment, setCurrentBankNewPayment] = useState<any[]>([]);
  const itemsPerPage = 10;
  const [openDelModal, setOpenDelModal] = useState(false);
  const [bankNewPaymentId, setBankNewPaymentId] = useState<number>(0);
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(null);
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(null);
  const [openReverseDrawer, setOpenReverseDrawer] = useState(false);
  const [isReverse, setIsReverse] = useState(false);
  const [isLeftButtonOpen, setIsLeftButtonOpen] = useState(true);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);


  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      dispatch(fetchAllBankNewPayment())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch,isOnAddedOrUpdate]);

  useEffect(() => {
    if (banNewPayments && banNewPayments.clientBankPaymentResult) {
      setBankNewPaymentData(banNewPayments.clientBankPaymentResult);
    }
  }, [banNewPayments]);

  const onDeleteCall = () => {
    try {
      dispatch(deleteBankNewPayment(bankNewPaymentId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted Bank New Payment");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const filterBankNewPaymentBySearchQuery = () => {
    return bankNewPaymentData.filter(
      (paymentData: any) =>
        paymentData?.caseName
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        paymentData?.payTo
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        paymentData?.priority
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        paymentData?.individualNames
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase())
    );
  };

  useEffect(() => {
    if (bankNewPaymentData && bankNewPaymentData.length > 0) {
      // console.log(bankNewPaymentData);
      const filteredByBankNewPayment = filterBankNewPaymentBySearchQuery();
      const indexOfLastBankNewPayment = currentPage * itemsPerPage;
      const indexOfFirstBankNewPayment =
        indexOfLastBankNewPayment - itemsPerPage;
      const currentBankNewPayments = filteredByBankNewPayment.slice(
        indexOfFirstBankNewPayment,
        indexOfLastBankNewPayment
      );
      const totalPages = Math.ceil(bankNewPaymentData.length / itemsPerPage); // Use filtered data length
      setTotalPages(totalPages);
      setCurrentBankNewPayment(currentBankNewPayments);
    } else {
      setCurrentBankNewPayment([]);
    }
  }, [bankNewPaymentData, searchQuery, currentPage]);

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };
  const handleButtonClick = (section: any) => {
    setSelectedSection(section);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };
  const toggleReverseDrawer = (value: string) => {
    setIsReverse(false);
    setOpenReverseDrawer(!openReverseDrawer);
  };

  if (openModal) {
    return (
      <AddNewPayment
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        banNewPayment={bankNewPaymentData}
        isView={isView}
        isReverse={false}
      />
    );
  }

  if (openReverseDrawer) {
    return (
      <ReverseMainDrawer
        Close={toggleReverseDrawer}
        openCaseDrawer={openReverseDrawer}
        drawerContent="banNewPayment"
        getData={bankNewPaymentData}
      />
    );
  }

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="CPNewBankPayment"
        getData={bankNewPaymentData}
        title="Bank Payment"
      />
    );
  }

  return (
    <>
      <div className=" min-w-full flex flex-col gap-2">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={bankNewPaymentId}
            title="Bank Payment"
            message="Are you sure?"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="">
          <SubTitle
            title=""
            buttonName="New Payment"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-col md:flex-col justify-between gap-7 w-full md:gap-3">
          <div className="flex w-72">
            <SearchInput title="Keyword" />
          </div>
          <div className="bg-gray-100 p-3 border border-gray-300 rounded mb-5">
          <div className="grid grid-cols-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full">
            <CheckBoxFilter title="Client Bank" />
            {/* <CheckBoxFilter title="Areas of Practice" /> */}
            <CheckBoxFilter title="Case Type" />
            {/* <CheckBoxFilter title="Case Sub Type" /> */}
            <CheckBoxFilter title="Status" />
            <JustifyDateFilter
              title="Date Between"
              plannedStartDate={plannedStartDate}
              plannedEndDate={plannedEndDate}
              onPlannedStartDateChange={handleStartDateChange}
              onPlannedEndDateChange={handleEndDateChange}
            />
          </div>
          </div>
          
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg min-h-screen overflow-auto">
          <table className="w-[100%] text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-800 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Case Details
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Transaction Details
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Payee
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Amount
                </th>
                {/* <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Status
                </th> */}
                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentBankNewPayment.map((banNewPayment: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {formatDateOnlyDisplay(banNewPayment.paymentCreateDate)}
                  </td>
              
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <p>{banNewPayment.individualNames || banNewPayment.organisationNames || banNewPayment.companyNames}</p>
                    <p>{banNewPayment.caseReferenceAuto}</p> <br />
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {banNewPayment.transactionDescription}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{banNewPayment.payTo}</td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{formatCurrency(banNewPayment.amount)}</td>
                  {/* <td className="px-6 py-3">{banNewPayment.status}</td> */}
                  <td className="cursor-pointer px-6 py-3">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setIsEdit(false);
                          setIsView(true);
                          setBankNewPaymentData(banNewPayment);
                        }}
                      />
                      <FaEdit
                        className="fill-blue-500 w-6 h-6"
                        onClick={() => {
                          setOpenModal(true);
                          setIsEdit(true);
                          setBankNewPaymentData(banNewPayment);
                        }}
                      />
                      <RiDeleteBin5Fill
                        className="fill-red-600 w-6 h-6"
                        onClick={() => {
                          setOpenDelModal(true);
                          setBankNewPaymentId(banNewPayment.clientPaymentId);
                        }}
                      />
                      {/* <MdOutlineRefresh className="fill-blue-800 w-6 h-6"
                      onClick={() => {
                        setOpenReverseDrawer(true);
                        setBankNewPaymentData(banNewPayment);
                      }}
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {currentBankNewPayment.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Bank New Payment found.</p>
            </div>
          )}
        </div>
        {currentBankNewPayment.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={currentBankNewPayment.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export { NewPayment };
