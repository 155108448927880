import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";




const GetAllTimeEntryUnitPrice = async () => {
    try {
        const response = await axiosInstance.get("TimeEntryPrice");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostTimeEntryUnitPrice = async (data: any) => {
    try {
        console.log(data);
        const response = await axiosInstance.post("TimeEntryPrice", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutTimeEntryUnitPrice = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`TimeEntryPrice/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteTimeEntryUnitPrice = async (id: number) => {
    try {
        await axiosInstance.delete(`TimeEntryPrice/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const TimeEntryUnitPriceService = {
    GetAllTimeEntryUnitPrice,
    PostTimeEntryUnitPrice,
    PutTimeEntryUnitPrice,
    deleteTimeEntryUnitPrice
};


export default TimeEntryUnitPriceService;
