import { IoClose } from "react-icons/io5";
import { MainAnimation } from "../../loadingAnimation/MainAnimation";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { fetchTrialBalanceById } from "../../../store/slice/Accounting/OfficeAccounts/OfficeReports/TrialBalanceSlice";
import { RootState } from "../../../store/store";
import { formatDateISODisplay, formatDateOnlyDisplay } from "../../../utils/date-time.utils";

function ViewMainDrawer({ openCaseDrawer, onClose, drawerContent, getData, title, selectedDate
}: { openCaseDrawer: boolean; onClose: () => void; drawerContent: string; title: string; getData: any; selectedDate: any }) {
    const [initialLoading, setInitialLoading] = useState(false);
    const dispatch = useAppDispatch();
    const [TrialBalandDetails, setTrialBalandDetails] = useState<any[]>([])
    const trialBalanceDetails = useSelector((state: RootState) => state.trialBalance.trialBalanceDetails);

    const effectServiceCall = useRef(false);
    console.log(drawerContent);
    useEffect(() => {
        if (!effectServiceCall.current) {
            let data = {
                accountId: getData?.accountId,
                date:
                    new Date(selectedDate)
                        .toISOString()
                        .split("T")[0]

            };
            dispatch(fetchTrialBalanceById(data))
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (trialBalanceDetails !== null && trialBalanceDetails.officeTrialBalanceDetails.length > 0) {
            console.log(trialBalanceDetails.officeTrialBalanceDetails);
            setTrialBalandDetails(trialBalanceDetails.officeTrialBalanceDetails);
        }
    }, [trialBalanceDetails])

    const renderContent = () => {
        switch (drawerContent) {
            case "trialBalance":
                return (
                    <div className="flex flex-col gap-9">
                        <div className="flex gap-x-2 ietms-center justify-end">

                        </div>
                        <div className="grid md:inline-block grid-cols-2 md:grid-cols-1 gap-y-3 gap-x-5 md:space-y-5 bg-sky-50 dark:bg-gray-900 dark:text-white-bg p-6 rounded-b-md">
                            <div className="flex items-center">
                                <label className="w-1/4 uppercase text-sm font-semibold dark:text-white-bg">
                                    Account Code
                                </label>
                                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                                    :
                                </span>
                                <p className="w-3/4 text-sm dark:text-white-bg">{getData?.accountCode}</p>
                            </div>
                            <div className="flex items-center">
                                <label className="w-1/4 uppercase text-sm font-semibold dark:text-white-bg">
                                    Account
                                </label>
                                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                                    :
                                </span>
                                <p className="w-3/4 text-sm dark:text-white-bg">
                                    {getData?.accountName}
                                </p>
                            </div>
                            <div className="flex items-center">
                                <label className="w-1/4 uppercase text-sm font-semibold dark:text-white-bg">
                                    Account Type
                                </label>
                                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                                    :
                                </span>
                                <p className="w-3/4 text-sm dark:text-white-bg">
                                    {getData?.accountType}
                                </p>
                            </div>
                            <div className="flex items-center">
                                <label className="w-1/4 uppercase text-sm font-semibold dark:text-white-bg">
                                    Running Balance
                                </label>
                                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                                    :
                                </span>
                                <p className="w-3/4 text-sm dark:text-white-bg">£ {getData?.runningBalance}.00</p>
                            </div>
                            <div className="flex items-center">
                                <label className="w-1/4 uppercase text-sm font-semibold dark:text-white-bg">
                                    DATE
                                </label>
                                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                                    :
                                </span>
                                <p className="w-3/4 text-sm dark:text-white-bg">
                                    {formatDateOnlyDisplay(selectedDate)}
                                </p>
                            </div>
                        </div>

                        <div className="relative overflow-hidden sm:rounded-lg my-5">
                            <div className="overflow-x-auto max-h-96">
                            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                                <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                            Trans No.
                                        </th>
                                        <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                            Date
                                        </th>
                                        <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                            Details
                                        </th>
                                        <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                            Debit
                                        </th>
                                        <th scope="col" className="text-left px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                            Credit
                                        </th>
                                        <th scope="col" className="text-left px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                            Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {TrialBalandDetails.map((auditTrail: any, index) => (
                                        <tr key={index}>
                                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{auditTrail?.fkTransactionId}</td>
                                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{formatDateOnlyDisplay(auditTrail?.transactionDate)}</td>
                                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{auditTrail?.details}</td>
                                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{auditTrail?.dr}</td>
                                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{auditTrail?.cr}</td>
                                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{auditTrail?.cr}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                );
            case "ledger":
                return <div>Ledger Content Goes Here</div>;
            case "client":
                return <div>Client Content Goes Here</div>;
            case "contact":
                return <div>Contact Content Goes Here</div>;
            default:
                return <div>Select a section to view its content</div>;
        }
    };

    return (
        <>
            <div
                className={`fixed top-0 right-0 z-40 h-auto bottom-0 border-none p-6 overflow-y-auto transition-transform duration-300 overflow-x-hidden ${openCaseDrawer
                    ? "translate-x-0 duration-300"
                    : "duration-300 translate-x-full"
                    } bg-white-bg w-[1100px] xl:w-[680px] lg:w-[350px] 2xl:[1600px] dark:bg-black drop-shadow-md border overflow-y-auto duration-500`}
                aria-labelledby="drawer-right-label"
            >
                {initialLoading ? <MainAnimation /> : ""}
                <div className="top-0 fixed flex md:flex-col flex-row items-center justify-between md:h-auto md:p-2 w-full my-5">
                    <ul className="flex items-center justify-between gap-x-1 md:gap-x-2 sm:mb-0">
                        <li className="flex w-full gap-5 items-center">
                            <button
                                className="text-white font-medium rounded-full shadow-md text-sm p-1.5"
                                type="button"
                                onClick={onClose}
                            >
                                <IoClose className="size-6" />
                            </button>
                            <div className="text-2xl font-bold text-left md:text-xl dark:text-white-bg">
                                {title}
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-col gap-9">
                    {renderContent()}
                </div>
            </div>
        </>
    );
}

export { ViewMainDrawer };
