import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import NewInvoiceService from "../../../../../services/Accounting/ClientAccounts/Invoice/NewInvoiceService";
import ProfessionalFeeService from "../../../../../services/Accounting/ClientAccounts/Invoice/ProfessionalFeeService";
import DisbursementTypeService from "../../../../../services/Accounting/AccountSetup/DisbursementTypeService";
import DisbursementService from "../../../../../services/Accounting/ClientAccounts/Invoice/DisbursementService";



export interface DisbursementData {
    invoiceDisbResult: [];
    totalCount: number;
    currentPage: number;
    recordPerPage: number;
}

export interface CADisbursement {

}
// Define the state type for Checklist
type NewInvoiceState = {
    disbursementData: DisbursementData;
    cADisbursement: CADisbursement[],
    status: "idle" | "loading" | "failed";
    error: string | null;
    receiptNumber : number | null;
};

// Initial state
const initialState: NewInvoiceState = {
    disbursementData: null!,
    cADisbursement: [],
    status: "idle",
    error: null,
    receiptNumber: null,
};

// Async thunks for CRUD operations

export const fetchNextDisbursementNumber = createAsyncThunk(
    "fetchNextDisbursementNumber",
    async (_, thunkAPI) => {
        try {
            const response = await DisbursementService.GetNextDisbursementNumber();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchDisbursementSearch = createAsyncThunk(
    "fetchDisbursementSearch",
    async (_, thunkAPI) => {
        try {
            const response = await DisbursementService.GetDisbursementSearch();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCADisbursement = createAsyncThunk(
    "addCADisbursement",
    async (data: any, thunkAPI) => {
        try {
            const response = await DisbursementService.PostCADisbursement(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const DisbursementSlice = createSlice({
    name: "disbursement",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDisbursementSearch.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchDisbursementSearch.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.disbursementData = action.payload;
            })
            .addCase(fetchDisbursementSearch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(fetchNextDisbursementNumber.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchNextDisbursementNumber.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.receiptNumber = action.payload;
            })
            .addCase(fetchNextDisbursementNumber.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCADisbursement.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCADisbursement.fulfilled, (state, action: PayloadAction<CADisbursement>) => {
                state.status = "idle";
                state.cADisbursement.push(action.payload);
            })
            .addCase(addCADisbursement.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
    }
});

export default DisbursementSlice.reducer;
