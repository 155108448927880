import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import BudgetService from "../../../../services/Accounting/OfficeAccount/BudgetService";





export interface Budget {
    budgetId: number;
    budgetName: string;
    financialYear: string;

    accountId: number;
    description: string;
    netAmount: number;
    vatId: number;
    vat: number;
    vatAdjustment: number;
    grossAmount: number;

    billEntryId: number;
    billEntryNumber: number;
    vatNominalId: number;
    vatAmount: number;
    supplierId: number;
    nominalIds: number[];
   // billLines: IOfficeSupplierBillEntryLine[];
    invoiceNumber: string;
    invoiceDate: Date;
    dueDate: Date;
    attachmentName: string;
    attachmentFile: Uint8Array;
 
};
// Define the state type for Checklist
type BudgetState = {
    budgets: Budget[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: BudgetState = {
    budgets: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllBudget = createAsyncThunk(
    "fetchAllBudget",
    async (_, thunkAPI) => {
        try {
            const response = await BudgetService.GetAllBudget();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addBudget = createAsyncThunk(
    "addBudget",
    async (data: any, thunkAPI) => {
        try {
            const response = await BudgetService.PostBudget(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateBudget = createAsyncThunk(
    "updateBudget",
    async ({ id, data }: { id: number, data: Budget }, thunkAPI) => {
        try {
            const response = await BudgetService.PutBudget(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteBudget = createAsyncThunk(
    "deleteBudget",
    async (id: number, thunkAPI) => {
        try {
            await BudgetService.deleteBudget(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const BudgetSlice = createSlice({
    name: "budget",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBudget.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllBudget.fulfilled, (state, action: PayloadAction<Budget[]>) => {
                state.status = "idle";
                state.budgets = action.payload;
            })
            .addCase(fetchAllBudget.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addBudget.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addBudget.fulfilled, (state, action: PayloadAction<Budget>) => {
                state.status = "idle";
                state.budgets.push(action.payload);
            })
            .addCase(addBudget.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateBudget.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateBudget.fulfilled, (state, action: PayloadAction<Budget>) => {
                state.status = "idle";
                const index = state.budgets.findIndex(t => t.budgetId === action.payload.budgetId);
                if (index !== -1) {
                    state.budgets[index] = action.payload;
                }
            })
            .addCase(updateBudget.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteBudget.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteBudget.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.budgets = state.budgets.filter(t => t.budgetId !== action.payload);
            })
            .addCase(deleteBudget.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default BudgetSlice.reducer;
