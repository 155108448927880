import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, boolean, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { addCaseSubType } from '../../../store/slice/SystemMaintenance/CaseSubTypeSlice';
import { useAppDispatch } from '../../../store/hooks/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function AddSubCaseType({ openDrawer, Close, isEdit, caseTypeId, caseSubType }: { openDrawer: boolean, Close: any, caseTypeId?: number, isEdit: boolean, caseSubType?: any }) {
    const [title] = useState((!isEdit ? 'Add New Sub-Case Type' : 'Edit Sub-Case Type'))
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement] = useState('center')
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [existingCaseType, setExistingCaseType] = useState(caseSubType);

    const validationSchema = object().shape({
        subCaseType: string()
            .required('SubCase is required'),
        subTypeCode: string()
            .required('SubType is required'),
        description: string(),
        isActive: boolean(),
        fkCaseTypeId: number()
    });

    const { register, handleSubmit, reset, setValue, formState: { errors }, watch } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            isActive: true,
        }
    });

    const onSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        dispatch(addCaseSubType(data))
            .unwrap()
            .then(() => {

                Close();
                navigate("/systemmanagement/systemmaintenance/casetype");
                toast.success("Successfully Added Case SubType");
            })
            .catch((error) => {
                toast.error(error)
            });
    };
    useEffect(() => {
        if (isEdit && caseSubType) {
            setExistingCaseType(caseSubType);
            reset(caseSubType);
            // setValue('isActive', caseType.isActive === 1 ? 'true' : 'false');
        }
    }, [caseSubType, isEdit, reset, setValue]);

    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); Close(); }}
            initialFocus={emailInputRef}
            size="xl"
        >
            <Modal.Header className="bg-white-bg p-5">
                <span className="text-blue_gray-900 text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg">

                <form onSubmit={handleSubmit(onSubmit)} className="mx-auto" >
                    <input type="hidden" value={caseTypeId} className={` ${errors.fkCaseTypeId ? 'is-invalid' : ''}`} {...register('fkCaseTypeId')}
                        placeholder="Enter Case type"
                    />
                    <div className="space-y-3 w-full">
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2">
                                Sub-Case Type
                            </label>
                            <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h ${errors.subCaseType ? 'is-invalid' : ''}`} {...register('subCaseType')}
                                placeholder="Enter Sub-case type"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.subCaseType?.message?.toString()}</div>
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2">
                                Sub Type Code
                            </label>
                            <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h ${errors.subTypeCode ? 'is-invalid' : ''}`} {...register('subTypeCode')}
                                placeholder="Enter Sub Case Type Code"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.subTypeCode?.message?.toString()}</div>
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2">
                                Description
                            </label>
                            <textarea
                                className={`bg-white-A700 border border-gray-200 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-lg placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full`}
                                placeholder="Describe" {...register('description')}
                            ></textarea>
                        </div>
                        <div className="relative max-w-full flex gap-6">
                            <label className="block mb-2">
                                Is Active
                            </label>
                            <div className='flex gap-5'>
                                <div>
                                    <input
                                        type="radio"
                                        value="true"
                                        checked={watch('isActive') === true}
                                        onChange={() => setValue('isActive', true)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                                </div>
                                <div>
                                    <input
                                        id="bordered-radio-1"
                                        type="radio"
                                        value="false"
                                        checked={watch('isActive') === false}
                                        onChange={() => setValue('isActive', false)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                    onClick={() => { reset(); Close(); }}
                                    type='button'
                                    className="cursor-pointer h-10 bg-white-A700 rounded-lg text-center border border-deep_purple-A400 text-deep_purple-A400 text-xs w-[140px] hover:text-white-A700 hover:bg-deep_purple-A400"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className="bg-green-700 text-white-bg cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center border border-deep_purple-A400 text-white-A700 text-sm w-[120px] hover:text-deep_purple-A400 hover:bg-white-A700"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export { AddSubCaseType };
