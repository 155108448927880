import { ErrorResult } from '../../../store/model/errorResult';
import axiosInstance from '../../axiosInstance';



const GetSearchRiskAssessment = async (data: any) => {
    try {
        const response = await axiosInstance.post("RiskAssesment/RARASearch", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetScreenQuestionAnswesByRAId = async (id: number) => {
    try {
        const response = await axiosInstance.get(`RiskAssesment/${id}`);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostRiskAssessmentAttachments = async (data: any) => {
    try {
        const response = await axiosInstance.post("RiskAssesment/attachment", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostRiskAssessmentResult = async (data: any) => {
    try {
        const response = await axiosInstance.post("RiskAssesment/result", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostRARANotes = async (data: any) => {
    try {
        const response = await axiosInstance.post("RiskAssesment/notes", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const RARiskAssessmentService = {
    GetSearchRiskAssessment,
    PostRiskAssessmentAttachments,
    PostRiskAssessmentResult,
    GetScreenQuestionAnswesByRAId,
};

export default RARiskAssessmentService;
