import React from 'react';
import './styles/App.css';
import { Routes } from './routes';


function App() {
  return (
    <Routes isAuthorized={true}/>
  );
}

export default App;
