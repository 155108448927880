import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, boolean } from 'yup';
import { addImportantDatesAndTask, updateImportantDatesAndTask } from '../../../../store/slice/SystemMaintenance/TaskAndAssesment/ImportantDateAndTaskSlice';
import { useAppDispatch } from '../../../../store/hooks/redux-hooks';
import { toast } from 'react-toastify';

function AddDatesAndTasks({ openDrawer, Close, isEdit, importantDatesAndTask }: { openDrawer: boolean, Close: any, isEdit: boolean, importantDatesAndTask: any }) {
  const [openModal, setOpenModal] = useState(true);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState('center');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  // const [existingImportantDatesAndTask, setExistingImportantDatesAndTask] = useState(importantDatesAndTask);

  const validationSchema = object().shape({
    importantDateType: string()
      .required('Important Date Type is required'),
    isActive: boolean()
  });

  const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      isActive: true
    }
  });

  const onSubmit = async (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    if (isEdit === true) {
      console.log("this edit")      
      try {
        await dispatch(updateImportantDatesAndTask({ id: importantDatesAndTask.importantDateId, data })).unwrap();
        Close();
        toast.success("Successfully Added Important Dates And Task Type");
      } catch (error: any) {
        toast.error(error.toString());
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log("add");
      try {
        await dispatch(addImportantDatesAndTask(data)).unwrap();
        Close();
        toast.success("Successfully Added Important Dates And Task Type");
      } catch (error: any) {
        toast.error(error.toString());
      } finally {
        setIsLoading(false);
      }
    }
  };
  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };


  useEffect(() => {
    if (isEdit && importantDatesAndTask) {
      // setExistingImportantDatesAndTask(importantDatesAndTask);
      reset(importantDatesAndTask);
      setValue('isActive', importantDatesAndTask?.isActive === 1 ? true : false);
    }
  }, [importantDatesAndTask, isEdit, reset, setValue]);

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => { reset(); setOpenModal(Close); }}
      initialFocus={emailInputRef}
      size="xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-black p-5">
        <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">Add Dates and Tasks</span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-black">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="space-y-3 w-full">
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Important Date Type <span className="text-red-500">*</span>
              </label>
              <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.importantDateType ? 'is-invalid' : ''}`} {...register('importantDateType')}
                placeholder="Enter Important Date Type"
              />
              <div className="font-medium mt-2 text-xs text-red-600" >{errors?.importantDateType?.message?.toString()}</div>
            </div>
            <div>
              <div className="flex items-center gap-x-10">
                <label className="text-sm font-medium text-gray-900 dark:text-white-bg">
                  Is Active
                </label>
                <div>
                  <input
                    type="radio"
                    value="true"
                    checked={watch('isActive') === true}
                    onChange={() => setValue('isActive', true)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label className="w-full py-3 ms-2 text-gray-900 dark:text-gray-300 text-sm font-medium">Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="true"
                    checked={watch('isActive') === false}
                    onChange={() => setValue('isActive', false)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label className="w-full py-3 ms-2 text-gray-900 dark:text-gray-300 text-sm font-medium">No</label>
                </div>
              </div>
            </div>

            <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  type='button'
                  onClick={() => {
                    reset();
                    Close();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  disabled={isLoading}
                  className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {isLoading ? (
                    <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                  ) : (
                    'Save'
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddDatesAndTasks };
