import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CaseCorrespondenceServices from "../../../../services/CaseManagement/Case/CaseCorrespondenceServices";


export interface CaseCorrespondenceDate {
    caseId: number;
    corrId: number;
    newDate: string;
}
export interface CorrespondenceDataByCase {

}
export interface CorrespondenceDataBy{
    caseImportWfId: number;
    dateImported: string;
    designation: string;
    firstName: string;
    fkBranchId: number;
    fkCaseId: number;
    fkWfId: number;
    lastName: string;
    userImported: number;
}

// Define the state type for cases
type CaseState = {
    any: any[];
    caseCorrespondenceDate: CaseCorrespondenceDate[];
    correspondenceDataByCase: CorrespondenceDataByCase[];
    correspondenceDataBy: CorrespondenceDataBy;
    hasCorrespondence: boolean;
    status: "idle" | "loading" | "failed";
    error: string | null;
    correspondenceImport: boolean;
};

// Initial state
const initialState: CaseState = {
    caseCorrespondenceDate: [],
    correspondenceDataByCase: [],
    correspondenceDataBy: null!,
    any: null!,
    hasCorrespondence: null!,
    correspondenceImport: null!,
    status: "idle",
    error: null
};


export const fetchCaseCorrespondenceById = createAsyncThunk(
    "fetchCaseCorrespondenceById",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.GetCaseCorrespondenceById(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCaseCorrespondenceBy = createAsyncThunk(
    "fetchCaseCorrespondenceBy",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.GetCaseCorrespondenceBy(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCaseworkflowimport = createAsyncThunk(
    "fetchCaseworkflowimport",
    async (data: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.GetCaseworkflowimport(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCaseCorrespondenceDate = createAsyncThunk(
    "addCaseCorrespondenceDate",
    async (data: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.PostCaseCorrespondenceDate(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export const fetchCaseWorkflowExists = createAsyncThunk(
    "fetchCaseWorkflowExists",
    async (id: any, thunkAPI) => {
        try {
            const response = await CaseCorrespondenceServices.GetCaseWorkflowExists(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);




// Slice definition
const CaseCorrespondenceSlice = createSlice({
    name: "caseCorrespondence",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCaseCorrespondenceById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseCorrespondenceById.fulfilled, (state, action: PayloadAction<CorrespondenceDataByCase[]>) => {
                state.status = "idle";
                state.correspondenceDataByCase = action.payload;
            })
            .addCase(fetchCaseCorrespondenceById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchCaseCorrespondenceBy.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseCorrespondenceBy.fulfilled, (state, action: PayloadAction<CorrespondenceDataBy>) => {
                state.status = "idle";
                state.correspondenceDataBy = action.payload;
            })
            .addCase(fetchCaseCorrespondenceBy.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchCaseworkflowimport.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseworkflowimport.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.status = "idle";
                state.correspondenceImport = action.payload;
            })
            .addCase(fetchCaseworkflowimport.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchCaseWorkflowExists.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseWorkflowExists.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.status = "idle";
                state.hasCorrespondence = action.payload;
            })
            .addCase(fetchCaseWorkflowExists.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addCaseCorrespondenceDate.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCaseCorrespondenceDate.fulfilled, (state, action: PayloadAction<CaseCorrespondenceDate>) => {
                state.status = "idle";
                state.caseCorrespondenceDate.push(action.payload);
            })
            .addCase(addCaseCorrespondenceDate.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

    }
});

export default CaseCorrespondenceSlice.reducer;
