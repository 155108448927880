import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { fetchAllVATReturnReport } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/VATReturnReportSlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { SelectBox } from "../SelectBox";
import { toast } from "react-toastify";
function VATReturn() {
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(false);
  const VATReturnReport = useSelector(
    (state: RootState) => state.VATReturnReport
  );
  const [vatId, setVatId] = useState<string | null>(null);


  const handleSearchClick = async () => {
    setInitialLoading(true);
    const data = {
      vatId: vatId,
    };
    try {
      await dispatch(fetchAllVATReturnReport(data)).unwrap();
      toast.success("Successfully Search Vat Return");
    } catch (error: any) {
      toast.error(error?.message || "An error occurred while fetching data");
    } finally {
      setInitialLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle title="VAT Return" />
        </div>
        {initialLoading ? <MainAnimation /> : ""}
        <div className="flex flex-row md:flex-col my-5 gap-2 w-full md:gap-3">
          <div>
            <div className="flex gap-4 md:gap-1">
              <div className="w-72">
                <SelectBox
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512">
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  // options={finacialYearList}
                  isSearchable={true}
                  placeholder="VAT Period"
                  shape="round"
                  color="indigo_50"
                  size="xs"
                // onChange={(selectedOption) => {
                //   if (selectedOption) {
                //     setFinancialYearId(selectedOption);
                //   }
                // }}
                />
              </div>
            </div>
          </div>
          <div>
            <button
              className="z-[2] inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
              onClick={handleSearchClick}
              type="button"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export { VATReturn };
