import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";




const GetAllNewInvoice = async () => {
    try {
        const response = await axiosInstance.post("ClientInvoiceAuthorisation", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostNewInvoice = async (data: any) => {
    try {
        const response = await axiosInstance.post("NewInvoice", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutNewInvoice = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`NewInvoice/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


// const deleteNewInvoice = async (id: number) => {
//     try {
//         await axiosInstance.delete(`/NewInvoice/${id}`);
//     } catch (error: any) {
//         let data = error as ErrorResult;
//         throw new Error(data.messages.toString());
//     }
// }

const NewInvoiceService = {
    GetAllNewInvoice,
    PostNewInvoice,
    PutNewInvoice,
    //deleteNewInvoice
};


export default NewInvoiceService;
