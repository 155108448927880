import { InputBox, SelectBox } from "../../../components";
import { useEffect, useRef, useState } from "react";
import { number, object, string } from "yup";
import { useForm } from "react-hook-form";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { addOfficeSupplierCreditNote } from "../../../store/slice/Accounting/OfficeAccounts/Suppliers/OfficeSupplierCreditNoteSlice";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import { SearchSupplier } from "../../Modal/SearchSupplier";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { fetchNextReceiptNumber } from "../../../store/slice/Accounting/OfficeAccounts/OfficeBankAccountSlice";
import { OfficeAccountConstant } from "../../../store/model/Accounting/OfficeAccount/officeaccountconst";
import { fetchAllSuppliersBillEntry, fetchAllSuppliersBillEntryId } from "../../../store/slice/Accounting/OfficeAccounts/Suppliers/SuppliersBillEntrySlice";
  
  const AddSuppliersCreditNote = ({
    openModal,
    Close,
    officeSupplierCreditNote,
    isView,
  }: {
    openModal: boolean;
    Close: any;
    isView: boolean;
    officeSupplierCreditNote: any;
  }) => {
   // const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement] = useState("center");
    const [isLoading, setIsLoading] = useState(false);
    const [setExistingOfficeSupplierCreditNote] = useState(officeSupplierCreditNote);
    const dispatch = useAppDispatch();

    const [fromSupplierBankAccount, setFromSupplierBankAccount] = useState<string>("");
    const [openFromSupplierBankAccountModal, setOpenFromSupplierBankAccountModal] = useState(false);

    const { officeVatRates } = useSelector((state: RootState) => state.officeVatRate);
    const [officeVatRateLists, setOfficeVatRateLists] = useState<any[]>([]);
    const { receiptNumber } = useSelector((state: RootState) => state.officeBankAccount);
    const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const { suppliersBillEntries, suppliersBillEntryById } = useSelector((state: RootState) => state.suppliersBillEntry);
    const [suppliersBillEntryLists, setSuppliersBillEntryLists] = useState<any[]>([]);
    const [billId, setBillId] = useState<any>();
    const suppliersBillEntry = useSelector((state: RootState) => state.suppliersBillEntry.suppliersBillEntryById);

    const toggleFromSupplierBankAccountModal = () => {
      setOpenFromSupplierBankAccountModal(!openFromSupplierBankAccountModal);
    };
    

    // const handleAccountSelect = (selectedAccountType: any) => {
    //   if (selectedRowIndex !== null) {
    //     const updatedRows = [...rows];
    //     updatedRows[selectedRowIndex].accountType = `${selectedAccountType.accountName}`; // Assuming accountType is returned from SearchAccount
    //     updatedRows[selectedRowIndex].bankAccountId = selectedAccountType.accountId; // Save the bankAccountId
    //     setRows(updatedRows);
    //   }
    //   setOpenFromBankAccountModal(false);
    // };
  
    const [rows, setRows] = useState([
      {
        lineId: 0,
        accountType: "",
        accountId: "",
        description: "",
        netAmount: 0,
        grossAmount: 0,
        vat: 0,
        fkVatTypeId:0,
      },
    ]);
    const row = () => {
      setRows([
        ...rows,
        { 
          lineId: 0,
          accountType: "",
          accountId: "",
          description: "",
          netAmount: 0,
          grossAmount: 0,
          vat: 0,
          fkVatTypeId:0
        },
      ]);
    };

  const totalNetAmount = rows.reduce((acc, row) => acc + (row.netAmount || 0), 0);
  const totalVatAmount = rows.reduce((acc, row) => acc + ((row.netAmount || 0) * (row.vat || 0) / 100), 0);
  const totalGrossAmount = rows.reduce((acc, row) => acc + ((row.netAmount || 0) + ((row.netAmount || 0) * (row.vat || 0) / 100)), 0);
  
  useEffect(() => {
    setValue("netAmount", totalNetAmount);
    setValue("vat", totalVatAmount);
    setValue("grossAmount", totalGrossAmount);
  }, [totalNetAmount, totalVatAmount, totalGrossAmount]);

  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };
 
  const handleVatRateChange = (index: number, selectedOption: any) => {
    const updatedRows = [...rows];
    if (selectedOption) {
      updatedRows[index] = {
        ...updatedRows[index],
        vat: selectedOption.percentage, // Assuming 'vatType' is the display value
        fkVatTypeId: selectedOption.vatTypeId, 
      };
    console.log( selectedOption.percentage);
      setRows(updatedRows);
    }
  };

  const handleInputChange = (index: number, field: string, value: any) => {
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: value,
    };
    setRows(updatedRows);
  };
    
    const handleFromSupplierAccountSelect = (selectedAccountType: any,) => {

      setFromSupplierBankAccount(selectedAccountType.supplierName); // Set the selected account type to display in input field
      if(selectedAccountType.supplierId > 0) {
        let data = {
          keyword: '',
          supplierId: selectedAccountType.supplierId
          
    }
    dispatch(fetchAllSuppliersBillEntry(data))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
        //setValue("invoice",(selectedAccountType.invoice_number)&& (selectedAccountType.net_amount));
      }
      setValue('fkSupplierId', selectedAccountType.supplierId);
  
      setOpenFromSupplierBankAccountModal(false);
    
    };

    useEffect(() => {
      if (
        suppliersBillEntries != null &&
        suppliersBillEntries.listingData != null &&
        suppliersBillEntries.listingData.length > 0
      ) {
        // Map over the `listingData` array, not `suppliersBillEntries` directly
        const data = suppliersBillEntries.listingData.map(
          (suppliersBillEntry: any, supplierId: number) => ({
            value: suppliersBillEntry.supplierBillEntryId,
            label: `${suppliersBillEntry.invoiceNumber} - £${suppliersBillEntry.netAmount}`,
          })
        );
    
        // Ensure `data` is typed correctly
        setSuppliersBillEntryLists(data as { value: number; label: string }[]);
      }
    }, [suppliersBillEntries]);

   
    useEffect(() => {
        if (billId !== null && billId > 0) {
             dispatch(fetchAllSuppliersBillEntryId(billId));
        } 
        setBillId(billId);

    }, [dispatch,billId]);

    useEffect(() => {
      if (suppliersBillEntry && billId !== null) {
        setRows((prevRows) =>
          prevRows.map((row, index) => ({
            ...row,
            accountType: suppliersBillEntry.accountNameNet || "",
            description: suppliersBillEntry.details || "",
          }))
        );
      }
    }, [suppliersBillEntry, billId]);



    useEffect(() => {
      if (officeVatRates.length > 0) {
        console.log(officeVatRates);
        let data = officeVatRates.map((officeVatRate: any) => ({
          value: officeVatRate,
          label: `${officeVatRate.vatType}`,
          vatPercentage: officeVatRate.percentage,
        
        }));
        setOfficeVatRateLists(data);
       
      }
    }, [officeVatRates])

    useEffect(() => {
      if (!effectServiceCall.current) {
          const fetchPromises = [dispatch(fetchNextReceiptNumber(OfficeAccountConstant.SupplierCreditNote))];
    
          Promise.all(fetchPromises)
              .then(() => {
                  setInitialLoading(false);
              })
              .catch((error) => {
                  if (!errorShownRef.current) {
                      toast.error("Error fetching data: " + error.message);
                      errorShownRef.current = true;
                  }
                  setInitialLoading(false);
              });
    
          effectServiceCall.current = true;
      }
    }, [dispatch]);
    
    useEffect(() => {
      if (receiptNumber != null) {
           setValue("supplierCreditNumber", receiptNumber)
          setAutoReceiptNumber(receiptNumber)
      }
    }, [receiptNumber]);


    const [isEdit, setIsEdit] = useState(false);
  
    const validationSchema = object().shape({

      supplierCreditNumber: number(),
      fkSupplierId: string().required("supplier is required"),
      fkSupplierBillEntryId: number().required("Invoice is required"),
      netAmount: number().required("Net Amount is required"),
      vat: number().required("VAT is required"),
      grossAmount: number().required("gross Amount is required"),
      accountBalance: string(),
      description: string().required(
        "Transaction Description is required"
      ),
      details: string(),
      creditNoteDate: string()
      .required("Transaction Date is required")
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split("T")[0];
      }),
      entryDate: string()
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split("T")[0];
      }),
    
    });


  
    const {
      register,
      handleSubmit,
      setValue,
      reset,
      getValues,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
      defaultValues:{
        creditNoteDate: new Date().toISOString().split("T")[0],
        entryDate: new Date().toISOString().split("T")[0]
      }
    });

    useEffect(()=>{
      if(suppliersBillEntryById!=null){
        setValue("grossAmount", suppliersBillEntryById.grossAmount)
        setValue("netAmount", suppliersBillEntryById.netAmount)
        setValue("vat", suppliersBillEntryById.vat)

        const mappedData = suppliersBillEntryById.billEntryLines.map((item: any) => ({
          fkVatTypeId: item.vatId || "",
          accountType: item.accountType || "",
          accountId: item.accountId || "",
          description: item.description || "",
          netAmount: item.netAmount || 0,
          grossAmount: item.grossAmount || 0,
          vat: item.vat || 0,
          lineId: item.lineId || 0
      }));
        setRows(mappedData)

      }
    },[suppliersBillEntryById])

    const onSubmit = async(data: any) => {
      const combinedData ={
        ...data,
        creditNoteLineItems: rows,
      }

      console.log(JSON.stringify(data, null, 2));
      setIsLoading(true);
      try {
        await dispatch(addOfficeSupplierCreditNote(combinedData)).unwrap();
        Close("success");
        toast.success("Successfully Added Supplier CreditNote");
      } catch (error: any) {
        toast.error(error.toString());
      } finally {
        setIsLoading(false);
      }
      };
  
      const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };
  
      // useEffect(() => {
      //     if (isEdit && officeSupplierCreditNote) {
      //       setExistingOfficeSupplierCreditNote(officeSupplierCreditNote);
      //       reset(officeSupplierCreditNote);
      //     }
      //   }, [officeSupplierCreditNote, isEdit, reset, setValue]);
  
   
        useEffect(() => {
          if ((officeSupplierCreditNote != null && isEdit === true) || (officeSupplierCreditNote != null && isView === true && isEdit === false)) {
            setValue('supplierCreditNumber', officeSupplierCreditNote?.supplierCreditNumber)
            setValue('fkSupplierId', (officeSupplierCreditNote?.fkSupplierId))
            setValue('netAmount', officeSupplierCreditNote?.netAmount)
            setValue('vat', officeSupplierCreditNote?.vat)
            setValue('grossAmount', officeSupplierCreditNote?.grossAmount)
            // setValue('receivedFromOther', bankReceipt?.fkclientId)
            setValue('accountBalance', officeSupplierCreditNote?.accountBalance)
            setValue('description', officeSupplierCreditNote?.description)
            setValue('details', officeSupplierCreditNote?.details)
            setValue('creditNoteDate', officeSupplierCreditNote?.creditNoteDate)
           
          }
        }, [officeSupplierCreditNote, isEdit, isView,  reset, setValue]);
      
    
  
    const [isDisabled, setIsDisabled] = useState(true);
    return (
      <>
        {openModal !== false && (
       <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto my-5">    
          <div className="">
            <div className="flex flex-row md:flex-col justify-between mb-5 gap-2 w-full md:gap-3">
              <div className="relative flex">
                <input
                  type="text"
                  placeholder="Supplier"
                  value={fromSupplierBankAccount}
                  aria-label="Search"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.fkSupplierId ? "is-invalid" : ""
                  }`}
                  {...register("fkSupplierId")}
  
                  //  disabled
                />
                <div className="font-medium mt-2 text-xs text-red-600">
                  {errors?.fkSupplierId?.message?.toString()}
                </div>
                <button
                  onClick={toggleFromSupplierBankAccountModal}
                  className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg -ml-1"
                  data-twe-ripple-init
                  data-twe-ripple-color="white"
                  type="button"
                >
                  Search
                </button>
                {openFromSupplierBankAccountModal && (
                <SearchSupplier
                openDrawer={openFromSupplierBankAccountModal}
                Close={toggleFromSupplierBankAccountModal}
                onAccountSupplierSelect={handleFromSupplierAccountSelect} 
                
              />
            )}
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    Close();
                  }}
                  className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Credit Note Number
                </label>
                <div className="flex gap-3">
                  <input
                    type="text"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    {...register("supplierCreditNumber")}
                    disabled={isDisabled}
                  />
                  <button
                    type="button"
                    onClick={handleEditClick}
                    className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
                  >
                    {isDisabled ? <FaLock /> : <FaLockOpen />}
                  </button>
                </div>
              </div>
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Invoice <span className="text-red-500">*</span>
                </label>
                <SelectBox
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  isSearchable={true}
                  options={suppliersBillEntryLists}
                  value={getValues().fkSupplierBillEntryId}
                  placeholder="Select"
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setValue("fkSupplierBillEntryId", selectedOption);
                      setBillId(selectedOption);
                    }
                  }}
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Net Amount <span className="text-red-500">*</span>
                </label>
                <NumericFormat decimalScale={2}
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("netAmount")}
                  placeholder="0.00"
                  value={(totalNetAmount || 0).toFixed(2)} 
                  disabled
                  // onValueChange={(values) => {
                  //   setValue('netAmount', values.value)
                  // }}
                />
              </div>
  
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                VAT <span className="text-red-500">*</span>
                </label>
                <NumericFormat decimalScale={2}
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("vat")}
                  placeholder="0.00"
                  value={(totalVatAmount || 0).toFixed(2)} 
                  disabled
                />
              </div>
  
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Gross Amount <span className="text-red-500">*</span>
                </label>
                <NumericFormat decimalScale={2}
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("grossAmount")}
                  placeholder="0.00"
                  value={(totalGrossAmount || 0).toFixed(2)} 
                  disabled
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Account Balance 
                </label>
                <NumericFormat decimalScale={2}
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("accountBalance")}
                  placeholder="Enter Account Balance"
                  onValueChange={(values) => {
                    setValue('accountBalance', values.value)
                  }}
                />
              </div>
  
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Transaction Description <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("description")}
                  placeholder="Enter Transaction Description"
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Transaction Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.creditNoteDate ? "is-invalid" : ""
                  }`}
                  {...register("creditNoteDate")}
                  placeholder="Enter Transaction Date"
                />
                <div className="font-medium mt-2 text-xs text-red-600">
                  {errors?.creditNoteDate?.message?.toString()}
                </div>
              </div>
              {/* <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Entry Date
                </label>
                <input
                  type="date"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.entryDate ? "is-invalid" : ""
                  }`}
                  {...register("entryDate")}
                  placeholder="Enter Entry Date"
                />
                <div className="font-medium mt-2 text-xs text-red-600">
                  {errors?.entryDate?.message?.toString()}
                </div>
              </div> */}
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Details
                </label>
                <textarea
                  className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Details"
                  {...register("details")}
                
                ></textarea>
              </div>
            </div>
            <div className="relative h-auto sm:rounded-lg mt-5">
              <table className="w-full text-xs text-black text-left rtl:text-right dark:text-gray-400">
                <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <th scope="col" className="px-6 py-3"></th>
                    <th scope="col" className="px-6 py-3">
                    Account
                    </th>
                    <th scope="col" className="px-6 py-3">
                    Description
                    </th>
                    <th scope="col" className="px-6 py-3">
                    Net Amount	
                    </th>
                    <th scope="col" className="px-6 py-3">
                    VAT Rate
                    </th>
                    <th scope="col" className="px-6 py-3">
                    VAT	
                    </th>
                    <th scope="col" className="px-6 py-3">
                    Gross Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                {rows.map((row, index) => (
                  <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <td className="px-6 py-3">
                      
                    </td>
                    <td className="px-6 py-3">
                    <InputBox
                dataType="text"
                placeholder="Account Type"
                value={row.accountType} // Display the selected account type
                onChange={(e) => handleInputChange(index, 'accountType', e.target.value)}
                disabled
              /> 
                    </td>
                    <td className="px-6 py-3">
                    <InputBox
                          dataType={"string"}
                          placeholder={"Enter Item name / Description"}
                          value={row.description}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                        />
                    </td>
                    <td className="px-6 py-3">
                    <NumericFormat
                          value={row.netAmount || 0}
                          className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                          placeholder="Amount"
                          decimalScale={2}
                          onValueChange={(values) => handleInputChange(index, 'netAmount', values.floatValue || 0)}
                        />

                    </td>
                    <td className="px-6 py-3">
                        <div className="">
                          <div className="mb-2"></div>
                          <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            isMulti={false}
                            name="type"
                            isSearchable={true}
                            options={officeVatRateLists}
                            value={
                              officeVatRateLists.find((option) => option.vatTypeId === row.fkVatTypeId) || null
                            }
                            placeholder="Select"
                            onChange={(selectedOption) => handleVatRateChange(index, selectedOption)   }
                          />
                        </div>
                      </td>
                    <td className="px-6 py-3">
                      <NumericFormat
                        value={(row.netAmount * row.vat / 100).toFixed(2) || 0}
                        className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="£"
                      />
                  </td>
                    <td className="px-6 py-3">

                    <NumericFormat
                      value={(row.netAmount + (row.netAmount * row.vat / 100)).toFixed(2)}
                    className={`border-2 border-gray-300  bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="£"
                  />
                    </td>
                  </tr>
                ))}

              <tr className="text-center">
                
                <td colSpan={3}>Totals</td> {/* Changed to colSpan={3} */}
                <td className="px-6 py-3">
                  <NumericFormat
                    value={totalNetAmount.toFixed(2)|| 0}
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="£"
                  />
                </td>
                <td colSpan={1}></td>
                <td className="px-6 py-3">
                  <NumericFormat
                    value={totalVatAmount.toFixed(2) || 0}
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="£"
                  />
                </td>
                <td className="px-6 py-3">
          <NumericFormat
            value={totalGrossAmount.toFixed(2) || 0}
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
            displayType="text"
            thousandSeparator={true}
            prefix="£"
          />
        </td>
        </tr>
                </tbody>
              </table>
            </div>
            {!isView && (
            <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
            <div className="flex flex-row gap-2 items-end justify-end w-full">
              <button
                type="button"
                onClick={() => {
                  Close();
                  reset();
              }}
                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                   >{isLoading ? (
                       <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                           <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                           <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                       </svg>
                   ) : (
                       'Save'
                   )}
              </button>
            </div>
          </div>
          )}
          </div>
        </form>
        )}
      </>
    );
  };
  
  export { AddSuppliersCreditNote };
  