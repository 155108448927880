import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineRefresh } from "react-icons/md";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  deleteFixedFeeInvoice,
  fetchAllFixedFeeInvoice,
} from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/FixedFeeInvoiceSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { AddFixedFeeInvoice } from "../Modal/AddOfficeAccounts/AddOfficePostings/AddFixedFeeInvoice";
import { Pagination } from "../Pagination";
import DeleteModal from "../Modal/DeleteModal";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";

function FixedFeeInvoice() {
  const [openModal, setOpenModal] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [fixedFeeInvoiceDatas, setFixedFeeInvoiceDatas] = useState<any>([]);
  const [fixedFeeInvoiceGetData, setFixedFeeInvoiceGetData] = useState<any[]>(
    []
  );
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const fixedFeeInvoiceData = useSelector(
    (state: RootState) => state.fixedFeeInvoice.fixedFeeInvoiceData
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentFixedFeeInvoice, setCurrentFixedFeeInvoice] = useState<any[]>(
    []
  );
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [fixedFeeInvoiceId, setFixedFeeInvoiceId] = useState<number>(0);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      dispatch(fetchAllFixedFeeInvoice())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch, isOnAddedOrUpdate]);

  useEffect(() => {
    if (fixedFeeInvoiceData && fixedFeeInvoiceData.listingData) {
      setFixedFeeInvoiceGetData(fixedFeeInvoiceData.listingData);
    }
  }, [fixedFeeInvoiceData]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (fixedFeeInvoiceGetData != null && fixedFeeInvoiceGetData.length > 0) {
      const filteredByFixedFeeInvoice = filterFixedFeeInvoiceBySearchQuery();
      const indexOfLastFixedFeeInvoice = currentPage * itemsPerPage;
      const indexOfFirstFixedFeeInvoice =
        indexOfLastFixedFeeInvoice - itemsPerPage;
      const currentFixedFeeInvoices = filteredByFixedFeeInvoice.slice(
        indexOfFirstFixedFeeInvoice,
        indexOfLastFixedFeeInvoice
      );
      const totalPages = Math.ceil(
        fixedFeeInvoiceGetData.length / itemsPerPage
      );
      setTotalPages(totalPages);
      setCurrentFixedFeeInvoice(currentFixedFeeInvoices);
    }
  }, [fixedFeeInvoiceGetData]);

  const filterFixedFeeInvoiceBySearchQuery = () => {
    return fixedFeeInvoiceGetData.filter((fixedFeeInvoice: any) =>
      fixedFeeInvoice?.accountName
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  };

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const onDeleteCall = () => {
    try {
      dispatch(deleteFixedFeeInvoice(fixedFeeInvoiceId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted fixed Invoice");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  if (openModal) {
    return (
      <AddFixedFeeInvoice
        openModal={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        isView={isView}
        fixedFeeInvoice={fixedFeeInvoiceData}
      />
    );
  }

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OAFixedFeeInvoice"
        getData={fixedFeeInvoiceData}
        title="fixed Fee Invoice"
      />
    );
  }
  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={fixedFeeInvoiceId}
            title="FixedFee Invoice"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="Fixed Fee Invoice"
            buttonName="Invoice"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <SearchInput title="Keyword" />
          <div>
            <div className="flex gap-4 md:gap-1 w-full">
              <CheckBoxFilter title="Status" />
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto h-[calc(100vh-10px)]">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-0 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Invoicee (s)
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Invoice
                </th>
                <th
                  scope="col"
                  className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Total
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-center w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentFixedFeeInvoice.map((fixedFeeInvoice: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {
                      formatDateOnlyDisplay(fixedFeeInvoice?.invoiceDate)

                    }
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {fixedFeeInvoice.invoiceeName}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <a href="" className="hover:text-blue-500">
                      {fixedFeeInvoice.fileName}{" "}
                    </a>
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatCurrency(fixedFeeInvoice.totalAmount)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {fixedFeeInvoice.status}
                  </td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setIsView(true);
                          setIsEdit(false);
                          setFixedFeeInvoiceDatas(fixedFeeInvoice);
                        }}
                      />
                      <FaEdit
                        className="fill-blue-500 w-6 h-6"
                        onClick={() => {
                          setOpenModal(true);
                          setIsEdit(true);
                          setFixedFeeInvoiceDatas(fixedFeeInvoice);
                        }}
                      />
                      <RiDeleteBin5Fill
                        className="fill-red-600 w-6 h-6"
                        onClick={() => {
                          setOpenDelModal(true);
                          setFixedFeeInvoiceId(
                            fixedFeeInvoice.fixedFeeInvoiceId
                          );
                        }}
                      />
                      {/* <MdOutlineRefresh className="fill-blue-800 w-6 h-6" /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          {currentFixedFeeInvoice.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">
                No Fixed Fee Invoice Details found.
              </p>
            </div>
          )}
        </div>
        {currentFixedFeeInvoice.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={currentFixedFeeInvoice.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { FixedFeeInvoice };
