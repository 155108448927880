import React, { useState } from "react";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { fetchLocationByPostcode } from "../../store/slice/CaseManagement/AddressSlice";


interface SearchComponentProps {
    setPostcode: React.Dispatch<React.SetStateAction<string>>;
    loading?: boolean;
}

function SearchComponent({ setPostcode, loading }: SearchComponentProps) {
    const dispatch = useAppDispatch();
    const [inputValue, setInputValue] = useState('');

    const handleSearch = () => {
        if (inputValue) {
            dispatch(fetchLocationByPostcode(inputValue));
        }
    };

    return (
        <div className="relative flex">
            <input
                type="text"
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e.target.value);
                    setPostcode(e.target.value);
                }}
                className="relative m-0 -me-0.5 block flex-auto rounded-s border border-solid border-neutral-200 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-surface outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:shadow-inset focus:outline-none motion-reduce:transition-none dark:border-white/10 dark:text-white dark:placeholder:text-neutral-200 dark:autofill:shadow-autofill dark:focus:border-primary"
                placeholder="Post Code"
            />
            <button
                disabled={loading}
                className={`z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 dark:hover:bg-blue-950 dark:focus:bg-blue-950 bg-hover_bg text-white-bg border-hover_bg ${loading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                onClick={handleSearch}
                type="button"
            >
                {loading ? (
                    <svg
                        className="animate-spin h-5 w-5 text-white-bg mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v8H4z"
                        ></path>
                    </svg>
                ) : (
                    "Search"
                )}

            </button>
        </div>
    );
}

export { SearchComponent };
