import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";

const GetAllCreateSurveyQuestion = async (data: any) => {
    try {
        const response = await axiosInstance.post("CreateSurveyQuestion", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostCreateSurveyQuestion = async (data: any) => {
    try {
        const response = await axiosInstance.post("CreateSurveyQuestion", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutCreateSurveyQuestion = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`CreateSurveyQuestion/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteCreateSurveyQuestion = async (id: number) => {
    try {
        await axiosInstance.delete(`/CreateSurveyQuestion/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const CreateSurveyQuestionService = {
    GetAllCreateSurveyQuestion,
    PostCreateSurveyQuestion,
    PutCreateSurveyQuestion,
    deleteCreateSurveyQuestion
};


export default CreateSurveyQuestionService;
