import { Modal } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number } from "yup";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { SelectBox } from "../../../../SelectBox";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import { addCorrCallout } from "../../../../../store/slice/CaseManagement/Case/Correspondence/AddItem/CorrAddItemsSlice";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";
import { fetchCaseCorrCallerCombo, fetchCaseCorrCallReceiverCombo } from "../../../../../store/slice/CaseManagement/Case/Correspondence/AddItem/CorrCallInSlice";

function AddCallOut({
  openDrawer,
  Close,
}: {
  openDrawer: boolean;
  Close: any;
}) {
  const dispatch = useAppDispatch();
  const { referenceNumber } = useParams();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const caseDetailsData = useSelector((state: RootState) => state.case.caseDto);
  const [isLoading, setIsLoading] = useState(false);
  const caseCorrCallerCombo = useSelector((state: RootState) => state.callIn.caseCorrCallerCombo);
  const caseCorrCallReceiverCombo = useSelector((state: RootState) => state.callIn.caseCorrCallReceiverCombo);
  const [callerOption, setCallerOption] = useState<any[]>([]);
  const [callReceiverOption, setCallReceiverOption] = useState<any[]>([]);

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    }
  };

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current && referenceNumber) {
      dispatch(fetchCaseCorrCallerCombo(Number(referenceNumber)));
      dispatch(fetchCaseCorrCallReceiverCombo(Number(referenceNumber)));
    }
    return () => { effectServiceCall.current = true; };
  }, [dispatch]);

  useEffect(() => {
    if (caseCorrCallerCombo.length > 0) {
      const options = caseCorrCallerCombo.map((item: any) => ({
        label: item.callerName,
        value: item.callerName
      }));
      setCallerOption(options);
    }
  }, [caseCorrCallerCombo]);

  useEffect(() => {
    if (caseCorrCallReceiverCombo.length > 0) {
      const options = caseCorrCallReceiverCombo.map((item: any) => ({
        label: item.receiverName,
        value: item.receiverName
      }));
      setCallReceiverOption(options);
    }
  }, [caseCorrCallReceiverCombo]);

  useEffect(() => {
    if (referenceNumber) {
      setValue("caseId", Number(referenceNumber))
    }
  }, [referenceNumber])

  const validationSchema = object().shape({
    caseId: number(),
    subject: string(),
    callerName: string()
      .required('Caller is required'),
    receiverName: string(),
    callOutDate: string(),
    callOutTime: string(),
    callOutDuration: string(),
    callOutNotes: string()
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    try {
      await dispatch(addCorrCallout(data)).unwrap();
      Close();
      toast.success("Successfully Added Call Out ");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const handleNoteChange = (value: string) => {
    setValue("callOutNotes", value, { shouldValidate: true });
  };


  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        Close();
      }}
      initialFocus={emailInputRef}
      size="2xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="dark:text-white-bg text-xl w-auto">
          Add Call-Out
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
          <div className="space-y-3 w-full">
            <div className="bg-sky-50 dark:bg-gray-900 p-5 space-y-2">
              <div className="flex items-center">
                <label className="w-1/4 text-sm font-semibold dark:text-white-bg">
                  Case Type
                </label>
                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-3/4 text-sm dark:text-white-bg">
                  {caseDetailsData?.area}
                  {caseDetailsData?.caseType && ` > ${caseDetailsData.caseType}`}
                  {caseDetailsData?.subCaseType && ` > ${caseDetailsData.subCaseType}`}
                </p>
              </div>
              <div className="flex items-center">
                <label className="w-1/4 text-sm font-semibold dark:text-white-bg">
                  Case Ref
                </label>
                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-3/4 text-sm dark:text-white-bg">
                  {caseDetailsData?.caseReferenceAuto}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-3">
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Subject
                </label>
                <input
                  type="text"
                  placeholder="Enter Note subject"
                  {...register("subject")}
                  className={`border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full p-3 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.subject ? "is-invalid" : ""
                    }`}
                />
                <div className="font-medium mt-2 text-xs text-red-600">{errors?.subject?.message?.toString()}</div>
              </div>
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Caller <span className="text-red-500">*</span>
                </label>
                <SelectBox
                  placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  isSearchable={true}
                  placeholder="Select"
                  options={callReceiverOption}
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setValue("callerName", selectedOption);
                    }
                  }}
                />
                <div className="font-medium mt-2 text-xs text-red-600">{errors?.callerName?.message?.toString()}</div>
              </div>
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Receiver
                </label>
                <SelectBox
                  className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  isSearchable={true}
                  placeholder="Select"
                  options={callerOption}
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setValue("receiverName", selectedOption);
                    }
                  }}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Date
                </label>
                <input
                  type="date"
                  placeholder="Enter Note subject"
                  {...register("callOutDate")}
                  className={`border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full p-3 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Time
                </label>
                <input
                  type="time"
                  placeholder="Enter Note subject"
                  {...register("callOutTime")}
                  className={`border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full p-3 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Duration
                </label>
                <input
                  type="text"
                  placeholder="Enter Note subject"
                  {...register("callOutDuration")}
                  className={`border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full p-3 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                />
              </div>
            </div>
            <div>
            <ReactQuill theme="snow" onChange={handleNoteChange}
            className="h-[10rem]"   
            formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video']} 
            modules={modules}/>
            </div>
          </div>
          <div className="flex md:flex-col mt-[90px] gap-2 flex-row justify-start w-full">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  onClick={() => {
                    reset();
                    Close();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white-bg mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddCallOut };
