import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../../model/Pagination/paginationClass";
import AnalyseSurveyResultService from "../../../../../services/HumanResources/Surveys/SurveyResult/AnalyseSurveyResultService";

export interface AnalyseSurveyResult {

  analyseSurveyResultId: number;
}
// Define the state type for Checklist
type AnalyseSurveyResultState = {
    analyseSurveyResults: PaginationResponse<AnalyseSurveyResult> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: AnalyseSurveyResultState = {
    analyseSurveyResults: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllAnalyseSurveyResult = createAsyncThunk(
    "fetchAllAnalyseSurveyResult",
    async (data: any, thunkAPI) => {
        try {
            const response = await AnalyseSurveyResultService.GetAllAnalyseSurveyResult(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addAnalyseSurveyResult = createAsyncThunk(
    "addAnalyseSurveyResult",
    async (data: any, thunkAPI) => {
        try {
            const response = await AnalyseSurveyResultService.PostAnalyseSurveyResult(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateAnalyseSurveyResult = createAsyncThunk(
    "updateAnalyseSurveyResult",
    async ({ id, data }: { id: number, data: AnalyseSurveyResult }, thunkAPI) => {
        try {
            const response = await AnalyseSurveyResultService.PutAnalyseSurveyResult(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteAnalyseSurveyResult = createAsyncThunk(
    "deleteAnalyseSurveyResult",
    async (id: number, thunkAPI) => {
        try {
            await AnalyseSurveyResultService.deleteAnalyseSurveyResult(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const AnalyseSurveyResultSlice = createSlice({
    name: "analyseSurveyResult",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllAnalyseSurveyResult.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllAnalyseSurveyResult.fulfilled, (state, action: PayloadAction<PaginationResponse<AnalyseSurveyResult>>) => {
                state.status = "idle";
                state.analyseSurveyResults = action.payload;
            })
            .addCase(fetchAllAnalyseSurveyResult.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addAnalyseSurveyResult.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addAnalyseSurveyResult.fulfilled, (state, action: PayloadAction<AnalyseSurveyResult>) => {
                state.status = "idle";
               // state.analyseSurveyResults.push(action.payload);
            })
            .addCase(addAnalyseSurveyResult.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateAnalyseSurveyResult.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateAnalyseSurveyResult.fulfilled, (state, action: PayloadAction<AnalyseSurveyResult>) => {
                state.status = "idle";
                // const index = state.analyseSurveyResults.findIndex(t => t.analyseSurveyResultId === action.payload.analyseSurveyResultId);
                // if (index !== -1) {
                //     state.analyseSurveyResults[index] = action.payload;
                // }
            })
            .addCase(updateAnalyseSurveyResult.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteAnalyseSurveyResult.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteAnalyseSurveyResult.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.analyseSurveyResults = state.analyseSurveyResults.filter(t => t.analyseSurveyResultId !== action.payload);
            })
            .addCase(deleteAnalyseSurveyResult.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default AnalyseSurveyResultSlice.reducer;
