import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";




const GetAllTimeSheet = async () => {
    try {
        const response = await axiosInstance.post("TimeSheetSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const PostTimeSheet = async (data: any) => {
    try {
        console.log(data);
        const response = await axiosInstance.post("TimeEntry/Sheet/AddOrUpdate", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutTimeSheet = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`TimeSheet/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteTimeSheet = async (id: number) => {
    try {
        await axiosInstance.delete(`/TimeSheet/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const TimeSheetService = {
    GetAllTimeSheet,
    PostTimeSheet,
    PutTimeSheet,
    deleteTimeSheet,
    
};


export default TimeSheetService;
