import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number, AnyObject, ObjectSchema, date, lazy } from "yup";
import { useEffect, useRef, useState } from "react";
import { SelectBox } from "../../../../SelectBox";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { addBankReceipt, addReverseBankReceipt, fetchNextBankReceiptNumber, } from "../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/BankReceiptSlice";
import { toast } from "react-toastify";

import { SearchCaseReference } from "../../../SearchCaseReference";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { MainAnimation } from "../../../../loadingAnimation/MainAnimation";
import { fetchClientCaseBalance } from "../../../../../store/slice/CaseManagement/Case/CaseManagementSlice";
import { NumericFormatWrapper } from "../../../../InputComponents/NumericFormatWrapper";
import { formatDateISODisplay } from "../../../../../utils/date-time.utils";
import { ClientAccountConstant } from "../../../../../store/model/Accounting/ClientAccount/ClientAccountConst";
import { fetchNextReverseNumber } from "../../../../../store/slice/Accounting/ClientAccounts/ClientAccountSlice";

function AddBankReceipt({
  openDrawer,
  Close,
  isEdit,
  isView,
  isReverse,
  bankReceipt,
}: {
  openDrawer: boolean;
  Close: any;
  isView: boolean;
  isEdit: boolean;
  isReverse: boolean;
  bankReceipt: any;
}) {
  const [title] = useState((!isEdit ? 'Add Bank Receipt' : 'Edit Bank Receipt'))
  const dispatch = useAppDispatch();
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
  const [initialLoading, setInitialLoading] = useState(false);
  const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCaseReference, setSelectedCaseReference] = useState<any>();
  const receiptAutoNumber = useSelector((state: RootState) => state.bankReceipt.receiptNumber);
  const reverseReceiptNumber = useSelector((state: RootState) => state.clientAccount.reverseReceiptNumber);
  const { clientPaymentTypes } = useSelector(
    (state: RootState) => state.clientPaymentType
  );
  const [clientPaymentTypeLists, setclientPaymentTypeLists] = useState<any[]>(
    []
  );
  const { authourisedClients } = useSelector(
    (state: RootState) => state.authorisedClient
  );
  const [authorisedClientLists, setAuthorisedClientLists] = useState<any[]>([]);
  const { clientAccounts } = useSelector(
    (state: RootState) => state.clientAccount
  );
  const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
  const { caseClient, caseClientBanalce } = useSelector((state: RootState) => state.case);
  const [caseClientLists, setcaseClientLists] = useState<any[]>([]);

  const [isDisabled, setIsDisabled] = useState(true);
  const [isReverseDisabled, setIsReverseDisabled] = useState(true);
const isvalid = false;
  const validationSchema = object().shape({
    fkClientBankId: number().required("Client Bank Account is required"),
    receiptNumber: number().required("Receipt Number is required"),
    receiptDate: string()
      .required('Receipt Date is required')
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split("T")[0];
      }),
    fkCaseId: string().required("Case Reference is required"),

    availableBalance: string(),
    receivedFromOther: string(),
    transactionDescription: string().required(
      "Transaction Description is required"
    ),
    amount: number()
      .typeError("Amount must be a number")
      .required("Amount is required"),
    comments: string(),
    paymentReference: string(),
    paymentTypeId: number().required("Payment Type is required"),
    fkClientId: number().required("Client is required"),
    clientReceiptId: number(),
    reverseNumber: lazy((value: any) =>number()
    ),
    reverseReference: lazy((value: any) =>
      isReverse
        ? string().required("Reverse Reference is required")
        : string().notRequired()
    ),
    reason: lazy((value: any) => string(),
    )
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      receiptNumber: 0,
      receivedFromOther: "",
      receiptDate: new Date().toISOString().split("T")[0],
    },
  });
  useEffect(() => {
    setValue("receiptDate", new Date().toISOString().split("T")[0]);
  }, []);

  useEffect(() => {
    if (!effectServiceCall.current && !isEdit) {
      setInitialLoading(true);
      const fetchPromises = [dispatch(fetchNextBankReceiptNumber())
      ];

      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });

      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (receiptAutoNumber != null) {
      setValue("receiptNumber", receiptAutoNumber);
      setAutoReceiptNumber(receiptAutoNumber);
    }
  }, [receiptAutoNumber]);

  useEffect(() => {
    if (clientPaymentTypes.length > 0) {
      
      let data = clientPaymentTypes.map((PaymentType: any, index) => ({
        value: PaymentType.paymentTypeId,
        label: PaymentType.paymentType,
      }));
      setclientPaymentTypeLists(data);
      let findObj = clientPaymentTypes.find((x) => x.isActive === true);
      if(findObj && findObj!==null) {
        
        setValue("paymentTypeId", findObj?.paymentTypeId)
      }
    }
  }, [clientPaymentTypes]);
 

  useEffect(() => {
    if (clientAccounts.length > 0) {
      let data = clientAccounts.map((clientAccount: any, index) => ({
        value: clientAccount.bankAccountId,
        label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`,
      }));
      setClientAccountLists(data);
      let findObj = clientAccounts.find((x) => x.isDefaultAccount === true);
      if(findObj && findObj!==null) {
        setValue("fkClientBankId", findObj?.bankAccountId)
      }
    }
  }, [clientAccounts]);


  useEffect(() => {
    if (caseClientBanalce !== null) {
      setValue("availableBalance", caseClientBanalce.closingBalance.toFixed(2))
      let data = caseClientBanalce.clientList.map((client: any) => ({
        value: client.clientId,
        label: `${client.clientName}`,

      }));
      setcaseClientLists(data);
      if (isEdit) {
        setValue("fkClientId", bankReceipt.fkClientId)
      }
    }
  }, [caseClientBanalce]);

  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };

  const handleReverseEditClick = () => {
    setIsReverseDisabled(!isReverseDisabled);
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    if(!isReverse){
    try {
       await dispatch(addBankReceipt(data)).unwrap();
      Close("success");
      toast.success("Successfully Added Bank Receipt");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
    }else{
          try {
            await dispatch(addReverseBankReceipt(data)).unwrap();
            Close("success");
            toast.success("Successfully Added Bank Receipt");
          } catch (error: any) {
            toast.error(error.toString());
          } finally {
            setIsLoading(false);
          }
    }
   
   
  };

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  const toggleCaseAccountModal = () => {
    setOpenCaseAccountModal(!openCaseAccountModal);
  };
  const handleCaseSelection = (selectedCase: any) => {
    setSelectedCaseReference(selectedCase);
    setValue("fkCaseId", selectedCase.caseId);
    //dispatch(fetchCaseClient(selectedCase.caseId));
    dispatch(fetchClientCaseBalance(selectedCase.caseId));
    setOpenCaseAccountModal(false);
  };


  useEffect(() => {
    if ((bankReceipt != null && isEdit === true) || (bankReceipt != null && isView === true && isEdit === false) || (bankReceipt != null && isView === false && isEdit === false && isReverse === true)) {
      setValue('receiptNumber', bankReceipt?.receiptNumber)
      setValue('receiptDate', formatDateISODisplay(bankReceipt?.receiptDate))
      setValue('fkCaseId', bankReceipt?.fkCaseId)
      setValue('fkClientBankId', bankReceipt?.fkClientBankId)
      setValue('availableBalance', bankReceipt?.availableBalance)
      // setValue('receivedFromOther', bankReceipt?.fkclientId)
      setValue('transactionDescription', bankReceipt?.transactionDescription)
      setValue('comments', bankReceipt?.comments)
      setValue('paymentReference', bankReceipt?.paymentReference)
      setValue('amount', bankReceipt?.amount)
      setValue('paymentTypeId', bankReceipt?.paymentTypeId)
      setValue('fkClientId', bankReceipt?.fkClientId)
      setValue('clientReceiptId', bankReceipt?.clientReceiptId);

      setSelectedCaseReference({ caseReferenceAuto: bankReceipt.caseReferenceAuto })

      dispatch(fetchClientCaseBalance(bankReceipt?.fkCaseId));
    }
  }, [bankReceipt, isEdit, isView, isReverse, reset, setValue]);

  useEffect(() => {
    if (isReverse) {
      dispatch(fetchNextReverseNumber(ClientAccountConstant.BankReceipt));
    }

  }, [dispatch, isReverse]);

  return (
    <>
      {openDrawer === true ? (
        <form onSubmit={handleSubmit(onSubmit, onError)} className={`${isReverse ? 'mx-[50px]' : 'mx-12'}`}>
          {initialLoading ? <MainAnimation /> : ""}
          <div className="flex flex-row md:flex-col justify-between mb-5 gap-2 w-full md:gap-3">
            <div >
              <div className="relative flex gap-1">
                <input
                  type="text"
                  placeholder={selectedCaseReference?.caseReferenceAuto || "Select Case"}
                  aria-label="Search"
                  value={selectedCaseReference?.caseReferenceAuto || ""}
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.fkCaseId ? "is-invalid" : ""
                    }`}
                  disabled
                />

                <button
                  onClick={toggleCaseAccountModal}
                  className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] w-36 pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                  data-twe-ripple-init
                  data-twe-ripple-color="white"
                  type="button"
                  disabled={isView || isReverse} 
                >
                  Add Case
                </button>
                {openCaseAccountModal && (
                  <SearchCaseReference
                    openDrawer={openCaseAccountModal}
                    Close={toggleCaseAccountModal}
                    onCaseSelect={handleCaseSelection}
                  />
                )}<span className="text-red-500">*</span>
              </div>
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.fkCaseId?.message?.toString()}
              </div>
            </div>
            {isReverse === false || isView === false &&(
              <div>
                <button
                  type="button"
                  onClick={() => {
                    Close();
                  }}
                  className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
          <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
            <div>
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Receipt Number
              </label>
              <div className="flex gap-3">
                <input
                  type="text"
                  value={getValues().receiptNumber}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  disabled={isDisabled || isReverse}
                />
                <button
                  type="button"
                  onClick={handleEditClick}
                  className="mt-2 text-lg 3xl:text-sm font-medium text-blue-600 dark:text-blue-500"
                >
                  {isDisabled ? <FaLock /> : <FaLockOpen />}
                </button>
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Client Bank Account <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={clientAccountLists}
                value={getValues().fkClientBankId}
                isSearchable={true}
                placeholder="Select"
                shape="round"
                color=""
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("fkClientBankId", selectedOption);
                  }
                }}
                isDisabled={isView || isReverse}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.fkClientBankId?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Transaction Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.receiptDate ? "is-invalid" : ""
                  }`}
                {...register("receiptDate")}
                placeholder="Enter Transaction Date"
                disabled={isView || isReverse}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.receiptDate?.message?.toString()}
              </div>
            </div>

            {/* <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Entry Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.entryDate ? "is-invalid" : ""
                }`}
                {...register("entryDate")}
                max={new Date().toISOString().split("T")[0]}
                placeholder="Enter Date"
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.entryDate?.message?.toString()}
              </div>
            </div> */}
            <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Available Balance <span className="text-red-500">*</span>
              </label>
              <NumericFormatWrapper
                decimalScale={2}
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.availableBalance ? "is-invalid" : ""
                  }`}
                {...register("availableBalance")}
                value={getValues().availableBalance}
                onValueChange={(values: any) => {
                  setValue("availableBalance", values.value);
                }}
                placeholder="Enter Available Balance"
                disabled
                defaultValue={0.00}

              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.availableBalance?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Received From <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={caseClientLists}
                isSearchable={true}
                value={getValues().fkClientId}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    //setValue("receivedFromOther", selectedOption.label);
                    setValue("fkClientId", selectedOption);
                  }
                }}

                isDisabled={isView || isReverse}

              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.fkClientId?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Transaction Description <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDescription ? "is-invalid" : ""
                  }`}
                {...register("transactionDescription")}
                placeholder="Enter Transaction Description"
                disabled={isView || isReverse}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.transactionDescription?.message?.toString()}
              </div>
            </div>

            <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Amount (£) <span className="text-red-500">*</span>
              </label>
              <NumericFormatWrapper
                decimalScale={2}
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.amount ? "is-invalid" : ""
                  }`}
                placeholder="Enter Amount (£)"
                value={getValues().amount}
                onValueChange={(values: any) => {
                  setValue('amount', Number(values.value))
                }}
                disabled={isView || isReverse}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.amount?.message?.toString()}
              </div>
            </div>
            {/* <div className="">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Authorised By <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={authorisedClientLists}
                isSearchable={true}
                value={getValues().authorisedBy}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("authorisedBy", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.authorisedBy?.message?.toString()}
              </div>
            </div> */}
            <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Payment Reference <span className="text-red-500"></span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.paymentReference ? "is-invalid" : ""
                  }`}
                {...register("paymentReference")}
                placeholder="Enter Payment Reference"
                disabled={isView || isReverse}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.paymentReference?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Payment Type <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={clientPaymentTypeLists}
                isSearchable={true}
                value={getValues().paymentTypeId}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("paymentTypeId", selectedOption);
                  }
                }}
                isDisabled={isView || isReverse}
              />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.paymentTypeId?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                Comments
              </label>
              <textarea
                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Comments"
                {...register("comments")}
                disabled={isView || isReverse}
              ></textarea>
            </div>
          </div>

          {isReverse && (
            <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
              <div>
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Reverse Receipt Number
                </label>
                <div className="flex gap-3">
                  <input
                    type="text"
                    value={reverseReceiptNumber}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    disabled={isReverseDisabled}
                    {...register('reverseNumber')}
                      
                  />
                  <button
                    type="button"
                    onClick={handleReverseEditClick}
                    className="mt-2 text-lg 3xl:text-sm font-medium text-blue-600 dark:text-blue-500"
                  >
                    {isReverseDisabled ? <FaLock /> : <FaLockOpen />}
                  </button>
                </div>
                
                     
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Reverse Reference
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.paymentReference ? "is-invalid" : ""
                    }`}
                  placeholder="Enter Reverse Reference"
                  {...register('reverseReference')}
                />
              </div>
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">{errors?.reverseReference?.message?.toString()}</div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Reason
                </label>
                <textarea
                  className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Reason"
                  {...register('reason')}
                ></textarea>
              </div>
            </div>
          )}


        {!isView && (
          <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
            <div className="flex flex-row gap-2 items-end justify-end w-full">
              <button
                type="button"
                onClick={() => {
                  Close();
                }}
                className="cursor-pointer h-10 rounded-md text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-md text-center border text-lg 3xl:text-sm w-[120px]  ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white-bg mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        )}
        </form>
      ) : null}
    </>
  );
}

export { AddBankReceipt };


