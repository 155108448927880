import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllAuditTrail } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/AuditTrailSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "flowbite-react";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { SelectBox } from "../SelectBox";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
function AuditTrail() {
  const today = new Date();
  const oneYearBefore = new Date();
  oneYearBefore.setFullYear(today.getFullYear() - 1);
  const formatDate = (date: Date) => date.toISOString().split("T")[0];
  const [auditTrailData, setAuditTrailData] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(false);
  const auditTrails = useSelector(
    (state: RootState) => state.auditTrail.auditTrails
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentAuditTrail, setCurrentAuditTrail] = useState<any[]>([]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(formatDate(oneYearBefore));
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(formatDate(today));
  const [branchUserLists, setBranchUserLists] = useState<any[]>([]);
  const [staffId, setStaffId] = useState<any>(null);
  const { branchUsers } = useSelector((state: RootState) => state.userManage);

  useEffect(() => {
    if (branchUsers.length > 0) {
      let data = branchUsers.map((branchUser: any, index) => ({
        value: branchUser.userId,
        label: `${branchUser.firstName} ${branchUser.lastName} `,
      }));
      setBranchUserLists(data);
    }
  }, [branchUsers]);


  useEffect(() => {
    if (auditTrails != null && auditTrails?.results.length > 0) {
      const indexOfLastAuditTrail = currentPage * itemsPerPage;
      const indexOfFirstAuditTrail = indexOfLastAuditTrail - itemsPerPage;
      const currentAuditTrail = auditTrails?.results.slice(
        indexOfFirstAuditTrail,
        indexOfLastAuditTrail
      );
      const totalPages = Math.ceil(auditTrails?.results.length / itemsPerPage);
      setTotalPages(totalPages);
      setCurrentAuditTrail(currentAuditTrail);
    }
  }, [auditTrails]);

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };

  const handleSearchClick = async () => {
    setInitialLoading(true);
    const data = {
      userId: staffId,
      startDate: plannedStartDate,
      endDate: plannedEndDate,
    };
    try {
      await dispatch(fetchAllAuditTrail(data)).unwrap();
      toast.success("Successfully Search Audit Trail");
    } catch (error: any) {
      toast.error(error?.message || "An error occurred while fetching data");
    } finally {
      setInitialLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle title="Audit Trail" />
        </div>
        <div className="flex flex-row md:flex-col lg:flex-col xl:flex-col justify-between my-5 gap-4 w-full md:gap-3">
          <div className="grid grid-cols-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full">
            <CheckBoxFilter title="Transaction" />
            <div className="">
              <SelectBox
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={branchUserLists}
                isSearchable={true}
                placeholder="User"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setStaffId(selectedOption);
                  }
                }}
              />
            </div>
            <JustifyDateFilter
              title="Date Range"
              plannedStartDate={plannedStartDate}
              plannedEndDate={plannedEndDate}
              onPlannedStartDateChange={handleStartDateChange}
              onPlannedEndDateChange={handleEndDateChange}
            />
          </div>
          <div>
            <button
              className="z-[2] inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
              onClick={handleSearchClick}
              type="button">
              Search
            </button>
          </div>
        </div>

        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto max-h-96">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Post Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Trn. Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Transaction
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Type
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Details
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  user
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentAuditTrail.map((auditTrail: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  {
                      formatDateOnlyDisplay(auditTrail?.postDateTime)
                        
                    }
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {
                      formatDateOnlyDisplay(auditTrail?.transactionDate)

                    }
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{auditTrail?.transactionType}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {auditTrail?.transactionSubType}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{auditTrail?.transactionDetails}</td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">£ {auditTrail?.transactionAmount}.00</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {auditTrail?.firstName} {auditTrail?.lastName}
                  </td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <button className="text-xs py-1 px-3 font-medium text-white-bg bg-green-500 rounded hover:bg-green-600">
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          {currentAuditTrail.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Audit Trail Details found.</p>
            </div>
          )}
        </div>
        {currentAuditTrail.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { AuditTrail };
