import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllPriorityClient = async () => {
    try {
        const response = await axiosInstance.get("Priority");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PriorityClientService = {
    GetAllPriorityClient,
    
};


export default PriorityClientService;
