import { IoClose } from "react-icons/io5";
import { MainAnimation } from "../../loadingAnimation/MainAnimation";
import { useState } from "react";
import { AddBankReceipt } from "../../Modal/AddClientAccounts/AddClientPostings/AddBankReceipt";
import { AddNewPayment } from "../../Modal/AddClientAccounts/AddClientPostings/AddNewPayment";
import { AddClientToOffice } from "../../Modal/AddClientAccounts/AddClientPostings/AddClientToOffice";
import { AddOfficeToClient } from "../../Modal/AddClientAccounts/AddClientPostings/AddOfficeToClient";
import { AddBankToBank } from "../../Modal/AddClientAccounts/AddClientPostings/AddBankToBank";
import { AddLedgerToLedger } from "../../Modal/AddClientAccounts/AddClientPostings/AddLedgerToLedger";
import { AddJournalEntry } from "../../Modal/AddClientAccounts/AddClientPostings/AddJournalEntry";
import { AddProtectedFund } from "../../Modal/AddClientAccounts/AddClientPostings/AddProtectedFund";
import { AddOfficeJournalEntry } from "../../Modal/AddOfficeAccounts/AddOfficePostings/AddOfficeJournalEntry";
import { AddBankDeposit } from "../../Modal/AddOfficeAccounts/AddOfficePostings/AddBankDeposit";
import { AddFundTransfer } from "../../Modal/AddOfficeAccounts/AddOfficePostings/AddFundTransfer";
import { AddClientPayment } from "../../Modal/AddOfficeAccounts/AddOfficePostings/AddClientPayment";
import { AddFixedFeeInvoice } from "../../Modal/AddOfficeAccounts/AddOfficePostings/AddFixedFeeInvoice";
import { AddFixedFeeInvoicePayment } from "../../Modal/AddOfficeAccounts/AddOfficePostings/AddFixedFeeInvoicePayment";
import { AddOfficeCreditNote } from "../../OfficePostings/OfficeCreditNoteDetails/AddOfficeCreditNote";
import { AddOfficeRefundCreditNote } from "../../Modal/AddOfficeAccounts/AddOfficePostings/AddOfficeRefundCreditNote";
import { AddSuppliersBillEntry } from "../../OfficeSuppliers/SuppliersBillEntryDetails/AddSuppliersBillEntry";
import { AddSuppliersCreditNote } from "../../OfficeSuppliers/SuppliersCreditNoteDetails/AddSuppliersCreditNote";
import { AddSupplierRefundCreditNote } from "../../OfficeSuppliers/SupplierRefundCreditNote/AddSupplierRefundCreditNote";
import { AddBillPayment } from "../../Modal/AddOfficeAccounts/AddSuppliers/AddBillPayment";

function ViewClientOfficeDrawer({ openCaseDrawer, Close, drawerContent,  getData, title,
}: { openCaseDrawer: boolean; Close: any; drawerContent: string;  getData: any; title: string }) {
    const [initialLoading, setInitialLoading] = useState(false);


    const renderContent = () => {
        switch (drawerContent) {
            case "CPBankReciept":
                return <AddBankReceipt
                openDrawer={openCaseDrawer}
                Close={openCaseDrawer}
                isEdit={false}
                isView={true}
                isReverse={false}
                bankReceipt={getData}
            />;
            case "CPNewBankPayment":
                return <AddNewPayment
                openDrawer={openCaseDrawer}
                Close={openCaseDrawer}
                isEdit={false}
                isView={true}
                isReverse={false}
                banNewPayment={getData}
                />;
                case "CPClientToOffice":
                    return  <AddClientToOffice
                    openDrawer={openCaseDrawer}
                    Close={openCaseDrawer}
                    isView={true}
                    isReverse={false}
                    clientToOffice={getData}
                        />;
                case "CPOfficeToClient":
                    return  <AddOfficeToClient
                    openDrawer={openCaseDrawer}
                    Close={openCaseDrawer}
                    isView={true}
                    isReverse={false}
                    officeToClient={getData}
                        />;
                case "CPBankToBank":
                    return   <AddBankToBank
                    openDrawer={openCaseDrawer}
                    Close={openCaseDrawer}
                    isEdit={false}
                    isView={true}
                    isReverse={false}
                    bankToBank={getData}
                    />;
                case "CPledgerToLedger":
                    return   <AddLedgerToLedger
                    openDrawer={openCaseDrawer}
                    Close={openCaseDrawer}
                    isEdit={false}
                    isView={true}
                    isReverse={false}
                    ledgerToLedger={getData}
                    />;
                case "CPJournalEntry":
                    return   <AddJournalEntry
                    openDrawer={openCaseDrawer}
                    Close={openCaseDrawer}
                    isEdit={false}
                    isView={true}
                    journalEntry={getData}
                  />;
                case "CPProtectedFund":
                return   <AddProtectedFund
                openDrawer={openCaseDrawer}
                Close={openCaseDrawer}
                isView={true}
                isReverse={false}
                protectedFund={getData}
                />;
                case "OAJournalEntry":
                return   <AddOfficeJournalEntry
                openDrawer={openCaseDrawer}
                Close={openCaseDrawer}
                isEdit={false}
                isView={true}
                officeJournalEntry={getData}
              />; case "OABankDeposit":
              return   <AddBankDeposit
              openDrawer={openCaseDrawer}
              Close={openCaseDrawer}
              isEdit={false}
              isView={true}
              officeBankDeposit={getData}
            />;
            case "OAFundTransfer":
                return   <AddFundTransfer
                openDrawer={openCaseDrawer}
                Close={openCaseDrawer}
                isEdit={false}
                isView={true}
                officeFundTransfer={getData}
              />;
              case "OAClientPayment":
                return   <AddClientPayment
                openDrawer={openCaseDrawer}
                Close={openCaseDrawer}
                isEdit={false}
                isView={true}
                clientPayment={getData}
              />;
              case "OAFixedFeeInvoice":
                return   <AddFixedFeeInvoice
                openModal={openCaseDrawer}
                Close={openCaseDrawer}
                isEdit={false}
                isView={true}
                fixedFeeInvoice={getData}
              />;
              case "OAFixedFeeInvoicePayment":
                return    <AddFixedFeeInvoicePayment 
                openDrawer={openCaseDrawer} 
                Close={openCaseDrawer} 
                isEdit={false} 
                isView={true}
                fixedFeeInvoicePayment={getData} 
                />;
              case "OACreditNote":
                return   <AddOfficeCreditNote
                openModal={openCaseDrawer}
                Close={openCaseDrawer}
                isEdit={false}
                isView={true}
                creditNote={getData}
              />;
              case "OARefundCreditNote":
                return   <AddOfficeRefundCreditNote 
                openDrawer={openCaseDrawer} 
                Close={openCaseDrawer} 
                isEdit ={false} 
                isView={true}
                refundCreditNote ={getData}
              />;
              case "OASupplierBillEntry":
                return  <AddSuppliersBillEntry
                openModal={openCaseDrawer}
                Close={openCaseDrawer}
                isEdit={false}
                isView={true}
                suppliersBillEntry={getData}
              />;
              case "OASupplierCreditNote":
                return  <AddSuppliersCreditNote
                openModal={openCaseDrawer}
                Close={openCaseDrawer}
                isView={true}
                officeSupplierCreditNote={getData}
              />;
              case "OASupplierRefundCreditNote":
                return   <AddSupplierRefundCreditNote
                openDrawer={openCaseDrawer}
                Close={openCaseDrawer}
                isEdit={false}
                isView={true}
                refundCreditNote={getData}
              />;
              case "OABillPayment":
                return   <AddBillPayment
                openDrawer={openCaseDrawer}
                Close={openCaseDrawer}
                isEdit={false}
                isView={true}
                billPayment={getData}
              />;

            case "ledger":
                return <div>Ledger Content Goes Here</div>;
            case "client":
                return <div>Client Content Goes Here</div>;
            case "contact":
                return <div>Contact Content Goes Here</div>;
            default:
                return <div>Select a section to view its content</div>;
        }
    };

    return (
        <>
            <div
                className={`flex flex-col fixed top-0 right-0 z-40 h-auto bottom-0 border-none p-6 overflow-y-auto transition-transform duration-300 overflow-x-hidden ${openCaseDrawer
                    ? "translate-x-0 duration-300"
                    : "duration-300 translate-x-full"
                    } bg-white-bg w-[1900px] 3xl:w-[1100px] xl:w-[680px] lg:w-[350px] dark:bg-black drop-shadow-md border overflow-y-auto duration-500`}
                aria-labelledby="drawer-right-label"
            >
                {initialLoading ? <MainAnimation /> : ""}
                <div className="top-0 fixed flex md:flex-col flex-row items-center justify-between md:h-auto md:p-2 w-full my-5">
                    <div>
                    <ul className="flex items-center justify-between gap-x-1 md:gap-x-2 sm:mb-0">
                        <li className="flex w-full gap-5 items-center">
                            <button
                                className="text-white font-medium rounded-full shadow-md text-sm p-1.5"
                                type="button"
                                onClick={Close}
                            >
                                <IoClose className="size-6" />
                            </button>
                            
                        </li>
                        
                    </ul>
                    </div>
                    
                </div>
                {/* <div className="text-2xl font-bold text-left md:text-xl dark:text-white-bg">
                                {title}
                </div> */}
                <div className="flex flex-col gap-9">
                    {renderContent()}
                </div>
            </div>
        </>
    );
}

export { ViewClientOfficeDrawer };
