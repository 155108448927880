import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CountryService from "../../../services/CaseManagement/CountryService";

export interface Country  {
    countryId: number;
    countryName: string;
    countryCode: string;
    nationality: string;
    priority: number;
};

// Define the state type for Country
type CountryState = {
    countries: Country[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CountryState = {
    countries: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllCountry = createAsyncThunk(
    "fetchAllCountry",
    async (_, thunkAPI) => {
        try {
            const response = await CountryService.GetAllCountry();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CountrySlice = createSlice({
    name: "country",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCountry.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCountry.fulfilled, (state, action: PayloadAction<Country[]>) => {
                state.status = "idle";
                state.countries = action.payload;
            })
            .addCase(fetchAllCountry.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
    }
});

export default CountrySlice.reducer;
