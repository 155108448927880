import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllSuppliersControlAccount = async () => {
    try {
        const response = await axiosInstance.post("Supplier/ControlSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const SuppliersControlAccountService = {
    GetAllSuppliersControlAccount,
   
};


export default SuppliersControlAccountService;
