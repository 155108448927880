import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { AddBillPayment } from "../Modal/AddOfficeAccounts/AddSuppliers/AddBillPayment";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { deleteBillPayment, fetchAllBillPayment } from "../../store/slice/Accounting/OfficeAccounts/Suppliers/BillPaymentSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "flowbite-react";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import DeleteModal from "../Modal/DeleteModal";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
function BillPayment() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [billPaymentData, setBillPaymentData] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const { billPayments, status, error } = useSelector(
    (state: RootState) => state.billPayment
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentBillPayment, setCurrentBillPayment] = useState<any[]>([]);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(null);
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(null);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [billPaymentId, setBillPaymentId] = useState<number>(0);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let data = {
        keyword: "",
      };
      dispatch(fetchAllBillPayment(data))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }

    if (status === "failed" && !errorShownRef.current) {
      toast.error(error);
      errorShownRef.current = true;
    }
  }, [dispatch,isOnAddedOrUpdate, status, error]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //     if(billPayments!=null && billPayments.length > 0){
  //         const filteredByBillPayment = filterBillPaymentBySearchQuery();
  //         const indexOfLastBillPayment = currentPage * itemsPerPage;
  //         const indexOfFirstBillPayment = indexOfLastBillPayment - itemsPerPage;
  //         const currentBillPayment = filteredByBillPayment.slice(indexOfFirstBillPayment, indexOfLastBillPayment);
  //         const totalPages = Math.ceil(billPayments.length / itemsPerPage);
  //         setTotalPages(totalPages)
  //         setCurrentBillPayment(currentBillPayment)
  //     }

  // },[billPayments])

  useEffect(() => {
    if (
      billPayments != null &&
      billPayments.listingData != null &&
      billPayments.listingData.length > 0
    ) {
      const totalPages = billPayments.totalRecords;
      setTotalPages(totalPages);
      setCurrentBillPayment(billPayments.listingData);
    }
  }, [billPayments]);

  // const filterBillPaymentBySearchQuery = () => {

  //     return billPayments.filter((billPayment: any) =>
  //         billPayment?.officeBank?.toLowerCase().includes(searchQuery.toLowerCase()));
  // };

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };
  const onDeleteCall = () => {
    try {
      dispatch(deleteBillPayment(billPaymentId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted Bill Payment");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };
  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OABillPayment"
        getData={billPaymentData}
        title="Bill Payment "
      />
    );
  }
  return (
    <>
      <div className="">
      {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={billPaymentId}
            title="Bill Payment"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="Bill Payment"
            buttonName="Bill Payment"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
          {openModal ? (
            <AddBillPayment
              openDrawer={openModal}
              Close={toggleDrawer}
              isEdit={isEdit}
              isView={isView}
              billPayment={billPaymentData}
            />
          ) : (
            ""
          )}
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <SearchInput title="Keyword" />
          <div>
            <div className="flex gap-4 md:gap-1 w-full">
              <CheckBoxFilter title="Supplier" />
              <JustifyDateFilter
                title="Date Between"
                plannedStartDate={plannedStartDate}
                plannedEndDate={plannedEndDate}
                onPlannedStartDateChange={handleStartDateChange}
                onPlannedEndDateChange={handleEndDateChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden sm:rounded-lg my-5">
        <div className="overflow-x-auto max-h-96">
        <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
          {initialLoading ? <MainAnimation /> : ""}
          <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Supplier
              </th>
              <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Invoice
              </th>
              <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Bank
              </th>
              <th
                scope="col"
                className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Amount
              </th>
              <th
                scope="col"
                className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="text-lg 3xl:text-xs">
            {currentBillPayment.map((billPayment: any, index) => (
              <tr
                key={index}
                className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  {formatDateOnlyDisplay(billPayment.transactionDate)}
                </td>
                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  {billPayment.supplierName}
                </td>
                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  {billPayment.invoiceNumber}
                </td>
                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  {billPayment.bankAccountCode}[{billPayment.accountName}]
                </td>
                <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  {formatCurrency(billPayment.paymentAmount)}
                </td>
                <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <div className="flex gap-3 w-full">
                    <FaEye className="fill-gray-500 w-6 h-6" 
                     onClick={() => {
                      setOpenViewDrawer(true);
                      setIsView(true);
                      setIsEdit(false);
                      setBillPaymentData(billPayment);
                    }} 
                    />
                    <FaEdit className="fill-blue-500 w-6 h-6"
                    onClick={() => {
                      setOpenModal(true);
                      setIsEdit(true);
                      setBillPaymentData(billPayment);
                    }}
                    />
                    <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" 
                    onClick={() => {
                      setOpenDelModal(true);
                      setBillPaymentId(billPayment.billPaymentId);
                    }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        {currentBillPayment.length === 0 && (
          <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
            <p className="text-red-800">No Bill Payment Details found.</p>
          </div>
        )}
      </div>
      {/* {currentBillPayment.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )} */}
    </>
  );
}

export { BillPayment };
