import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetAllPreviewAndPublishSurvey = async (data: any) => {
    try {
        const response = await axiosInstance.post("PreviewAndPublishSurvey", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostPreviewAndPublishSurvey = async (data: any) => {
    try {
        const response = await axiosInstance.post("PreviewAndPublishSurvey", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutPreviewAndPublishSurvey = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`PreviewAndPublishSurvey/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deletePreviewAndPublishSurvey = async (id: number) => {
    try {
        await axiosInstance.delete(`/PreviewAndPublishSurvey/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PreviewAndPublishSurveyService = {
    GetAllPreviewAndPublishSurvey,
    PostPreviewAndPublishSurvey,
    PutPreviewAndPublishSurvey,
    deletePreviewAndPublishSurvey
};


export default PreviewAndPublishSurveyService;
