import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import LedgerCardService from "../../../../services/Accounting/ClientAccounts/LedgerCardService";
import { CAFSAdditionDTO, FSData, LedgerCardByIdData, LedgerCardGetData } from "../../../model/Accounting/ClientAccount/LedgerCardAndFSInterface";

// Define the state type for Checklist
type LedgerCardState = {
    cAFSAdditionDTO: CAFSAdditionDTO[];
    ledgerCardByIdData: LedgerCardByIdData;
    ledgerCards: LedgerCardGetData;
    fSData: FSData;
    any: any;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: LedgerCardState = {
    cAFSAdditionDTO: [],
    ledgerCardByIdData: null!,
    ledgerCards: null!,
    fSData: null!,
    status: "idle",
    error: null,
    any: null!,
};

// Async thunks for CRUD operations
export const fetchAllLedgerCard = createAsyncThunk(
    "fetchAllLedgerCard",
    async (data: any, thunkAPI) => {
        try {
            const response = await LedgerCardService.GetAllLedgerCard(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCAFSData = createAsyncThunk(
    "addCAFSData",
    async (data: any, thunkAPI) => {
        try {
            const response = await LedgerCardService.PostCAFSData(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchLedgerCardById = createAsyncThunk(
    "fetchLedgerCardById",
    async (id: number, thunkAPI) => {
        try {
            const response = await LedgerCardService.GetLedgerCardById(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllCAFSData = createAsyncThunk(
    "fetchAllCAFSData",
    async (id: number, thunkAPI) => {
        try {
            const response = await LedgerCardService.GetAllCAFSData(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


const LedgerCardSlice = createSlice({
    name: "ledgerCard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllLedgerCard.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllLedgerCard.fulfilled, (state, action: PayloadAction<LedgerCardGetData>) => {
                state.status = "idle";
                state.ledgerCards = action.payload;
            })
            .addCase(fetchAllLedgerCard.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchLedgerCardById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchLedgerCardById.fulfilled, (state, action: PayloadAction<LedgerCardByIdData>) => {
                state.status = "idle";
                state.ledgerCardByIdData = action.payload;
            })
            .addCase(fetchLedgerCardById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchAllCAFSData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCAFSData.fulfilled, (state, action: PayloadAction<FSData>) => {
                state.status = "idle";
                state.fSData = action.payload;
            })
            .addCase(fetchAllCAFSData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addCAFSData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCAFSData.fulfilled, (state, action: PayloadAction<CAFSAdditionDTO>) => {
                state.status = "idle";
                state.cAFSAdditionDTO.push(action.payload);
            })
            .addCase(addCAFSData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

    }

});

export default LedgerCardSlice.reducer;