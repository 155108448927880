import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllStaffAttendenceType = async () => {
    try {
        const response = await axiosInstance.get("StaffAttendence/AttendanceType");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const StaffAttendenceTypeService = {
    GetAllStaffAttendenceType,
  
};


export default StaffAttendenceTypeService;