import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CorrCallInServices from "../../../../../../services/CaseManagement/Case/Correspondence/AddItems/CorrCallInServices";
import { CallInData, CaseCorrCallerCombo, CaseCorrCallReceiverCombo } from "../../../../../model/CaseManagement/CaseCorrAddItemInterface";




// Define the state type for cases
type CallInState = {
    callInData: CallInData[];
    caseCorrCallReceiverCombo: CaseCorrCallReceiverCombo[];
    caseCorrCallerCombo: CaseCorrCallerCombo[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CallInState = {
    callInData: [],
    caseCorrCallReceiverCombo: [],
    caseCorrCallerCombo: [],
    status: "idle",
    error: null
};

export const fetchCaseCorrCallerCombo = createAsyncThunk(
    "fetchCaseCorrCallerCombo",
    async (id: number, thunkAPI) => {
        try {
            const response = await CorrCallInServices.GetCaseCorrCallerCombo(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCaseCorrCallReceiverCombo = createAsyncThunk(
    "fetchCaseCorrCallReceiverCombo",
    async (id: number, thunkAPI) => {
        try {
            const response = await CorrCallInServices.GetCaseCorrCallReceiverCombo(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCorrCallIn = createAsyncThunk(
    "addCorrCallIn",
    async (data: any, thunkAPI) => {
        try {
            const response = await CorrCallInServices.PostCorrCallIn(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CorrCallInSlice = createSlice({
    name: "callIn",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchCaseCorrCallerCombo.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseCorrCallerCombo.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.caseCorrCallerCombo = action.payload;
            })
            .addCase(fetchCaseCorrCallerCombo.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchCaseCorrCallReceiverCombo.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseCorrCallReceiverCombo.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.caseCorrCallReceiverCombo = action.payload;
            })
            .addCase(fetchCaseCorrCallReceiverCombo.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addCorrCallIn.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCorrCallIn.fulfilled, (state, action: PayloadAction<CallInData[]>) => {
                state.status = "idle";
                state.callInData = action.payload;
            })
            .addCase(addCorrCallIn.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
    }
});

export default CorrCallInSlice.reducer;
