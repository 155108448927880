import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllAuditTrail = async (data: any) => {
    try {
        const response = await axiosInstance.post("officeaccount/officereport/audittrail", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const AuditTrailService = {
    GetAllAuditTrail,
   
};


export default AuditTrailService;
