import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import MergefieldService from "../../../services/SystemMaintenance/MergefieldService";

export type Mergefield = {
    mergefieldId: number;
    fkMergefieldAreaId: number;
    mergefield: string;
    description: string;
    IsActive: boolean;
};
// Define the state type for Mergefield
type MergefieldState = {
    mergefields: Mergefield[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: MergefieldState = {
    mergefields: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllMergefield = createAsyncThunk(
    "fetchAllMergefield",
    async (_, thunkAPI) => {
        try {
            const response = await MergefieldService.GetAllMergefield();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// export const addMergefield = createAsyncThunk(
//     "addMergefield",
//     async (newMergefield: Omit<Mergefield, 'id'>, thunkAPI) => {
//         try {
//             const response = await MergefieldService.addMergefield(newMergefield);
//             return response;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// export const updateMergefield = createAsyncThunk(
//     "updateMergefield",
//     async (updatedMergefield: Mergefield, thunkAPI) => {
//         try {
//             const response = await MergefieldService.updateMergefield(updatedMergefield);
//             return response;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// export const deleteMergefield = createAsyncThunk(
//     "deleteMergefield",
//     async (MergefieldId: number, thunkAPI) => {
//         try {
//             await MergefieldService.deleteMergefield(MergefieldId);
//             return MergefieldId;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// Slice definition
const MergefieldSlice = createSlice({
    name: "mergefield",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllMergefield.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllMergefield.fulfilled, (state, action: PayloadAction<Mergefield[]>) => {
                state.status = "idle";
                state.mergefields = action.payload;
            })
            .addCase(fetchAllMergefield.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            // .addCase(addMergefield.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(addMergefield.fulfilled, (state, action: PayloadAction<Mergefield>) => {
            //     state.status = "idle";
            //     state.mergefields.push(action.payload);
            // })
            // .addCase(addMergefield.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // })
            // .addCase(updateMergefield.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(updateMergefield.fulfilled, (state, action: PayloadAction<Mergefield>) => {
            //     state.status = "idle";
            //     const index = state.mergefields.findIndex(t => t.id === action.payload.id);
            //     if (index !== -1) {
            //         state.mergefields[index] = action.payload;
            //     }
            // })
            // .addCase(updateMergefield.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // })
            // .addCase(deleteMergefield.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(deleteMergefield.fulfilled, (state, action: PayloadAction<number>) => {
            //     state.status = "idle";
            //     state.mergefields = state.mergefields.filter(t => t.id !== action.payload);
            // })
            // .addCase(deleteMergefield.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // });
    }
});

export default MergefieldSlice.reducer;
