import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";

function HeaderTitle({ title, buttonName, isAdd = false, onClickAdd, link }: { title?: string, buttonName?: string, isAdd?: boolean, onClickAdd?: any, link?: any }) {


    return (
        <div className="flex flex-row md:flex-row items-start justify-between mt-10">
            <h1 className="text-3xl sm:text-base font-bold text-left md:text-2xl dark:text-white-bg">
                {title}
            </h1>
            {(isAdd) && (link ? (
                <Link to={`${link}`}>
                    <button className="flex items-center bg-blue-500 text-white-bg px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                        type="button"
                        onClick={onClickAdd}>
                        <FaPlus className="w-4 h-4 fill-white-bg"/>
                        <span className="text-white-bg mx-2">{buttonName}</span>
                    </button>
                </Link>
            ) : (
                <button className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                    type="button"
                    onClick={onClickAdd}>
                    <FaPlus className="w-4 h-4 fill-white-bg"/>
                    <span className="text-white-bg mx-2 text-lg 3xl:text-base">{buttonName}</span>
                </button>
            ))
            }
        </div>
    )
}

export { HeaderTitle };