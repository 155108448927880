import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import NewInvoiceService from "../../../../../services/Accounting/ClientAccounts/Invoice/NewInvoiceService";



export interface NewInvoice {
    invoiceId : number;
    caseReference: string;
    invoiceTo: string;
    paymentnumber : number;
    memo: string;
    addressLine1 : string;
    addressLine2 : string;
    city: string;
    county : string;
    postCode : string;
    transactionDate: Date;
    entryDate: Date;
    dueDate: Date;

};

export interface NewInvoicesData{
    clientCaseInvoicesResult: [];
    totalCount: number;
    currentPage: number;
    recordPerPage: number;
}

// Define the state type for Checklist
type NewInvoiceState = {
    newInvoices: NewInvoice[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    newInvoicesData: NewInvoicesData;
};

// Initial state
const initialState: NewInvoiceState = {
    newInvoices: [],
    status: "idle",
    error: null,
    newInvoicesData: null!,
};

// Async thunks for CRUD operations
export const fetchAllNewInvoice = createAsyncThunk(
    "fetchAllNewInvoice",
    async (_, thunkAPI) => {
        try {
            const response = await NewInvoiceService.GetAllNewInvoice();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addNewInvoice = createAsyncThunk(
    "addNewInvoice",
    async (data: any, thunkAPI) => {
        try {
            const response = await NewInvoiceService.PostNewInvoice(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateNewInvoice = createAsyncThunk(
    "updateNewInvoice",
    async ({ id, data }: { id: number, data: NewInvoice }, thunkAPI) => {
        try {
            const response = await NewInvoiceService.PutNewInvoice(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// export const deleteNewInvoice = createAsyncThunk(
//     "deleteNewInvoice",
//     async (id: number, thunkAPI) => {
//         try {
//             await NewInvoiceService.deleteNewInvoice(id);
//             return id;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// Slice definition
const FeeTypeSlice = createSlice({
    name: "newInvoice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllNewInvoice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllNewInvoice.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.newInvoicesData = action.payload;
            })
            .addCase(fetchAllNewInvoice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addNewInvoice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addNewInvoice.fulfilled, (state, action: PayloadAction<NewInvoice>) => {
                state.status = "idle";
                state.newInvoices.push(action.payload);
            })
            .addCase(addNewInvoice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateNewInvoice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateNewInvoice.fulfilled, (state, action: PayloadAction<NewInvoice>) => {
                state.status = "idle";
                const index = state.newInvoices.findIndex(t => t.invoiceId === action.payload.invoiceId);
                if (index !== -1) {
                    state.newInvoices[index] = action.payload;
                }
            })
            .addCase(updateNewInvoice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            // .addCase(deleteFeeType.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(deleteFeeType.fulfilled, (state, action: PayloadAction<number>) => {
            //     state.status = "idle";
            //     state.feeTypes = state.feeTypes.filter(t => t.feeTypeId !== action.payload);
            // })
            // .addCase(deleteFeeType.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // });
    }
});

export default FeeTypeSlice.reducer;
