import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, boolean, number } from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BreadCrumb,
  HeaderTitle,
  SearchCaseReference,
  SelectBox,
} from "../../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { fetchAllPriorityClient, fetchCaseClient } from "../../../store/slice/Accounting";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { SearchCaseReferenceDetails } from "../../../components/Modal/SearchCaseReferenceDetails";
import { toast } from "react-toastify";
import { addCentralDiary } from "../../../store/slice/OfficeManagement/CentralDiary/CentralDiarySlice";

interface Step {
  name: string;
  description: string;
  estimatedtime: string;
}
const AddCentralDiary = ({
  openModal,
  Close,
}: {
  openModal: boolean;
  Close: any;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false);
  const { priorityClients } = useSelector((state: RootState) => state.priorityClient);
  const [priorityClientLists, setPriorityClientLists] = useState<any[]>([]);
  const effectServiceCall = useRef(false);
  const dispatch = useAppDispatch();
  const [showInput, setShowInput] = useState(false);
  const [showCaseInput, setShowCaseInput] = useState(false);
  const [selectedCaseReference, setSelectedCaseReference] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
 

  const showToOthers = [
    { label: "None", value: 1 },
    { label: "All Staff", value: 2 },
    { label: "Custom", value: 3 },
   
    
  ];

  const mappedShowToOthers = showToOthers.map((type: any) => ({
    label: type.label,
    value: type.value,
  }));

  
  const validationSchema = object().shape({
    title: string().required("Title is required"),
    isRelateToCase: boolean(),
    ledgerCardId: string(),
    type: string(),
    priority: string(),
    start: string().required("Start is required"),
    end: string().required("End is required"),
    location: string(),
    description: string(),
    showToOthers: string(),
    isSetReminder: boolean(),
    setReminder: string(),
    isShowToOthers: boolean(),
    isAllDayEvent: boolean(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
   // data.stepId = 0;
   // data.fkWfId = 0;
    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    try {
      await dispatch(addCentralDiary(data)).unwrap();
      Close();
      toast.success("Successfully Added Central Diary");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  const toggleCaseAccountModal = () => {
    setOpenCaseAccountModal(!openCaseAccountModal);
  };

 

  const onCancelRedirect = () => {
    navigate("/officemanagement/centraldiary");
  };


  const handleCheckboxChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setShowInput(e.target.checked);
  };

  const handleCaseCheckboxChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setShowCaseInput(e.target.checked);
  };

  useEffect(() => {
    if (!effectServiceCall.current) {
        dispatch(fetchAllPriorityClient());
        effectServiceCall.current = true;
    }
}, [dispatch]);

  useEffect(() => {
    if (priorityClients.length > 0) {
        let data = priorityClients.map((priorityClient: any, index) => ({
            value: priorityClient.priorityTypeId,
            label: priorityClient.priority
            ,
        }));
        setPriorityClientLists(data);

    }
}, [priorityClients]);

const handleCaseSelection = (selectedCase: any) => {
  setSelectedCaseReference(selectedCase);
  setValue("ledgerCardId", selectedCase.caseId);
  dispatch(fetchCaseClient(selectedCase.caseId));
  setOpenCaseAccountModal(false);
};

  return (
    <>
      {/* {openModal != false && ( */}
      <form onSubmit={handleSubmit(onSubmit,onError)} className="w-full flex-col gap-5">
        <div className="">
          <BreadCrumb path={location} />
        </div>
        <div className="flex-col w-full gap-4 px-10 py-10">
          <div>
            <HeaderTitle title="Add Event" />
          </div>
          <div className="space-y-3 w-full mt-5">
            <div className="grid grid-cols-4 md:grid-cols-1 gap-x-3">
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Title <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.title ? "is-invalid" : ""
                  }`}
                  {...register("title")}
                  placeholder="Enter title"
                />
                <div className="font-medium mt-2 text-xs text-red-600">
                  {errors?.title?.message?.toString()}
                </div>
              </div>
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Type
                </label>
                <SelectBox
                  className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  isSearchable={true}
                  placeholder="Select"
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setValue("type", selectedOption);
                    }
                  }}
                />
              </div>
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Priority
                </label>
                <SelectBox
                  className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  isSearchable={true}
                  options={priorityClientLists}
                  placeholder="Select"
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setValue("priority", selectedOption);
                    }
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-1 gap-3">
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Start <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.start ? "is-invalid" : ""
                  }`}
                  {...register("start")}
                  placeholder="Enter start"
                />
                <div className="font-medium mt-2 text-xs text-red-600">
                  {errors?.start?.message?.toString()}
                </div>
              </div>
              <div>
                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    End <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                      errors.end ? "is-invalid" : ""
                    }`}
                    {...register("end")}
                    placeholder="Enter end"
                  />
                  <div className="font-medium mt-2 text-xs text-red-600">
                    {errors?.start?.message?.toString()}
                  </div>
                </div>
                <div className="flex items-center">
                  <input
                    id="bordered-checkbox-2"
                    type="checkbox"
                    value=""
                    className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
                    {...register("isAllDayEvent")}
                  />
                  <label className="w-full py-2 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    All Day Event
                  </label>
                </div>
              </div>
              <div className="">
                <div className="flex items-center">
                  <input
                    id="bordered-checkbox-1"
                    type="checkbox"
                    value=""
                    className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
                    {...register("isShowToOthers")}
                  />
                  <label className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Show to Others
                  </label>
                </div>
                <div className="">
                  <SelectBox
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                    indicator={
                      <svg
                        width="15"
                        className="fill-current mr-2"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                      </svg>
                    }
                    isMulti={false}
                    name="frameFiftySix"
                    isSearchable={true}
                    options={mappedShowToOthers}
                    placeholder="Select"
                    shape="round"
                    color="indigo_50"
                    size="xs"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setValue("showToOthers", selectedOption);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="">
                <div className="flex items-center">
                <input
                  id="bordered-checkbox-2"
                  type="checkbox"
                  value=""
                  className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
                  {...register("isSetReminder")}
                  onChange={handleCheckboxChange}
                />
                <label className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Set Reminder
                </label>
                </div>
                {showInput && (
                <div className="">
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.setReminder ? "is-invalid" : ""
                  }`}
                  {...register("setReminder")}
                  placeholder=""
                />
                </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 items-baseline gap-x-3">
            <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Location
                </label>
                <textarea
                  className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Enter Location"
                  {...register("location")}
                ></textarea>
              </div>
              <div>
                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Description
                  </label>
                  <textarea
                    className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    placeholder="Description"
                    {...register("description")}
                  ></textarea>
                </div>
                <div className="">
                <div className="flex items-center">
                  <input
                    id="bordered-checkbox-2"
                    type="checkbox"
                    value=""
                    className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
                    {...register("isRelateToCase")}
                    onChange={handleCaseCheckboxChange}
                  />
                  <label className="w-full py-2 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Relate to Case
                  </label>
                </div>
                {showCaseInput && (
                <div className="flex flex-row md:flex-col justify-between mb-5 w-full md:gap-3">
                  <div className="relative flex">
                    <input
                      type="text"
                      placeholder={selectedCaseReference?.caseReferenceAuto}
                      aria-label="Search"
                      value={selectedCaseReference?.caseReferenceAuto || ""}
                      className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}

                      //  disabled
                    />
                    <button
                      onClick={toggleCaseAccountModal}
                      className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg -ml-1"
                      data-twe-ripple-init
                      data-twe-ripple-color="white"
                      type="button"
                    >
                      Search
                    </button>
                    {openCaseAccountModal &&
                      <SearchCaseReferenceDetails
                        openDrawer={openCaseAccountModal}
                        Close={toggleCaseAccountModal}
                        onCaseSelectDetail={handleCaseSelection}
              />
            }
                  </div>
                </div>
                )}
                </div>
              </div>
            </div>
            <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  type="button"
                  onClick={() => {
                    reset();
                    onCancelRedirect();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {isLoading ? (
                    <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                  ) : (
                    'Save'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddCentralDiary;
