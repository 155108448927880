import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import PartnerShipAccountService from "../../../../services/Accounting/AccountSetup/PartnerShipAccountService";



export interface PartnerShipAccount {
    partnershipId: number;
    partnerName: string;
    partnershipPercentage: number;
    capitalAccountBfwdId: number;
    capitalIntroducedId: number;
    capitalInterestCreditedId: number;
    currentAccountBfwdId: number;
    currentCapitalIntroducedId: number;
    currentInterestCreditedId: number;
    drawingsId: number;
    profitForTheYearId: number;
    isActive: boolean;
};
// Define the state type for Checklist
type PartnerShipAccountState = {
    partnerShipAccounts: PartnerShipAccount[];
    status: "idle" | "loading" | "failed" | "Added";
    error: string | null;
};

// Initial state
const initialState: PartnerShipAccountState = {
    partnerShipAccounts: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllPartnerShipAccount = createAsyncThunk(
    "fetchAllPartnerShipAccount",
    async (_, thunkAPI) => {
        try {
            const response = await PartnerShipAccountService.GetAllPartnerShipAccount();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addPartnerShipAccount = createAsyncThunk(
    "addPartnerShipAccount",
    async (data: any, thunkAPI) => {
        try {
            const response = await PartnerShipAccountService.PostPartnerShipAccount(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updatePartnerShipAccount = createAsyncThunk(
    "updatePartnerShipAccount",
    async ({ id, data }: { id: number, data: PartnerShipAccount }, thunkAPI) => {
        try {
            const response = await PartnerShipAccountService.PutPartnerShipAccount(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deletePartnerShipAccount = createAsyncThunk(
    "deletePartnerShipAccount",
    async (id: number, thunkAPI) => {
        try {
            await PartnerShipAccountService.deletePartnerShipAccount(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const PartnerShipAccountSlice = createSlice({
    name: "partnerShipAccount",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllPartnerShipAccount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllPartnerShipAccount.fulfilled, (state, action: PayloadAction<PartnerShipAccount[]>) => {
                state.status = "idle";
                state.partnerShipAccounts = action.payload;
            })
            .addCase(fetchAllPartnerShipAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addPartnerShipAccount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addPartnerShipAccount.fulfilled, (state) => {
                state.status = "Added";
               // state.partnerShipAccounts.push(action.payload);
            })
            .addCase(addPartnerShipAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updatePartnerShipAccount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updatePartnerShipAccount.fulfilled, (state, action: PayloadAction<PartnerShipAccount>) => {
                state.status = "idle";
                const index = state.partnerShipAccounts.findIndex(t => t.partnershipId === action.payload.partnershipId);
                if (index !== -1) {
                    state.partnerShipAccounts[index] = action.payload;
                }
            })
            .addCase(updatePartnerShipAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deletePartnerShipAccount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deletePartnerShipAccount.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.partnerShipAccounts = state.partnerShipAccounts.filter(t => t.partnershipId !== action.payload);
            })
            .addCase(deletePartnerShipAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default PartnerShipAccountSlice.reducer;
