import { SubHeaderTitle } from "../SubHeaderTitle";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { fetchAllProfitAndLossStatement } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/ProfitAndLossStatementSlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { toast } from "react-toastify";
import { fetchAllFinancialYear } from "../../store/slice/Accounting/OfficeAccounts/GeneralSettings/FinancialYearSlice";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { SelectBox } from "../SelectBox";
import React from "react";

function ProfitAndLossStatement() {
  const today = new Date();
  const oneYearBefore = new Date();
  oneYearBefore.setFullYear(today.getFullYear() - 1);
  const formatDate = (date: Date) => date.toISOString().split("T")[0];
  const dispatch = useAppDispatch();
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(formatDate(oneYearBefore));
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(formatDate(today));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedType, setSelectedType] = useState<string>("dateType");
  const [financialYearId, setFinancialYearId] = useState<string | null>(null);
  const [finacialYearList, setFinancialYearList] = useState<any[]>([]);
  const { financialYears } = useSelector((state: RootState) => state.financialYear);
  const companyProfiles = useSelector((state: RootState) => state.companyProfile.companyProfiles);
  const profitAndLossStatements = useSelector((state: RootState) => state.profitAndLossStatement.profitAndLossStatements);

  useEffect(() => {

    if (financialYears != null && (financialYears.dataList != null && financialYears.dataList.length > 0)) {
      let data = financialYears.dataList.map((item: any) => ({
        value: item.financialYearId,
        label: `${item.startDate.split("T")[0]} - ${item.endDate.split("T")[0]}`,

      }));
      setFinancialYearList(data);
    }
  }, [financialYears]);

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value);
  };


  const handleSearchClick = async () => {
    setIsSubmitting(true);
    const data =
      selectedType === "dateType"
        ? {
          startDate: plannedStartDate,
          endDate: plannedEndDate,
          searchType: selectedType,
        }
        : {
          financialYearId: financialYearId,
          searchType: selectedType,
        };

    try {
      await dispatch(fetchAllProfitAndLossStatement(data)).unwrap();
      toast.success("Successfully Search Profit");
    } catch (error: any) {
      toast.error(error?.message || "An error occurred while fetching data");
    } finally {
      setIsSubmitting(false);
    }
  };



  return (
    <>
      <div className="">
        <div className="flex items-center justify-between mb-5">
          <div>
          <SubHeaderTitle title="Profit And Loss Statement" />
          </div>
          <div className="flex gap-x-2 ietms-center justify-end">
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-sm"
          >
            <IoIosPrint className="w-5 h-5" />
            Print
          </button>
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-sm"
          >
            <BsFileEarmarkExcelFill className="w-5 h-5" />
            Export to Excel
          </button>
        </div>
        </div>
        {isSubmitting ? <MainAnimation /> : ""}
        <div className="flex items-center justify-between">
          <form >
            <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-x-10 gap-y-2 mb-5">
              <div className="col-start-1 col-end-2 flex items-center gap-x-12 pb-2">
                <div className="flex items-center gap-3">
                  <input
                    id="date-range-radio"
                    type="radio"
                    value="dateType"
                    name="date-type"
                    checked={selectedType === "dateType"}
                    onChange={handleRadioChange}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <JustifyDateFilter
                    title="Date Range"
                    plannedStartDate={plannedStartDate}
                    plannedEndDate={plannedEndDate}
                    onPlannedStartDateChange={handleStartDateChange}
                    onPlannedEndDateChange={handleEndDateChange}
                  />
                </div>
                <div className="flex items-center w-full gap-3">
                  <input
                    id="financial-year-radio"
                    type="radio"
                    value="financialYear"
                    name="date-type"
                    checked={selectedType === "financialYear"}
                    onChange={handleRadioChange}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <div className="w-72">
                    <SelectBox
                      indicator={
                        <svg
                          width="15"
                          className="fill-current mr-2"
                          height="15"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512">
                          <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                        </svg>
                      }
                      isMulti={false}
                      name="frameFiftySix"
                      options={finacialYearList}
                      isSearchable={true}
                      placeholder="FinacialYear"
                      shape="round"
                      color="indigo_50"
                      size="xs"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setFinancialYearId(selectedOption);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-start-3 flex h-10">
                <button
                  className="z-[2] inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                  data-twe-ripple-init
                  data-twe-ripple-color="white"
                  type="button"
                  onClick={handleSearchClick}
                  id="button-addon3"
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
        
        <div className="my-5 text-center">
          <h1 className="text-2xl font-bold">{companyProfiles?.companyName}</h1>
          <p className="text-lg font-semibold">Profit and Loss Statement</p>
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg my-6">
          <div className="overflow-x-auto max-h-96">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                {profitAndLossStatements !== null && profitAndLossStatements?.incomes !== null && profitAndLossStatements?.incomes.length > 0 && (
                  <React.Fragment>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <th colSpan={3} scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        Income
                      </th>
                    </tr>
                    {profitAndLossStatements?.incomes.map(
                      (incomes: any, index) => (
                        <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                          <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                            {index + 1}
                          </td>
                          <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{incomes?.accountName}</td>
                          <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></td>
                        </tr>
                      ))}
                  </React.Fragment>
                )}
                {profitAndLossStatements !== null && profitAndLossStatements?.costOfSales !== null && profitAndLossStatements?.costOfSales.length > 0 && (
                  <React.Fragment>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <th colSpan={3} scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        Cost Of Sales
                      </th>
                    </tr>
                    {profitAndLossStatements?.costOfSales.map(
                      (costOfSales: any, index) => (
                        <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                          <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                            {index + 1}
                          </td>
                          <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{costOfSales?.accountName}</td>
                          <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></td>
                        </tr>
                      ))}
                  </React.Fragment>
                )}
                {profitAndLossStatements !== null && profitAndLossStatements?.expenses !== null && profitAndLossStatements?.expenses.length > 0 && (
                  <React.Fragment>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <th colSpan={3} scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        Expenses
                      </th>
                    </tr>
                    {profitAndLossStatements?.expenses.map(
                      (expenses: any, index) => (
                        <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                          <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                            {index + 1}
                          </td>
                          <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{expenses?.accountName}</td>
                          <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></td>
                        </tr>
                      ))}
                  </React.Fragment>
                )}
                {profitAndLossStatements !== null && profitAndLossStatements?.taxes !== null && profitAndLossStatements?.taxes.length > 0 && (
                  <React.Fragment>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <th colSpan={3} scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        Taxes
                      </th>
                    </tr>
                    {profitAndLossStatements?.taxes.map(
                      (taxes: any, index) => (
                        <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                          <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                            {index + 1}
                          </td>
                          <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{taxes?.accountName}</td>
                          <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></td>
                        </tr>
                      ))}
                  </React.Fragment>
                )}
              </thead>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export { ProfitAndLossStatement };
