import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import PostageMethodService from "../../../../services/HumanResources/HumanResources/PostageMethodService";

export interface PostageMethod {
fee: number;
isTracked: boolean;
postageMethod: string;
postageMethodId: number;
timescale: number;

}
// Define the state type for Checklist
type PostageMethodState = {
    postageMethods: PostageMethod[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: PostageMethodState = {
    postageMethods: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllPostageMethod = createAsyncThunk(
    "fetchAllPostageMethod",
    async (_, thunkAPI) => {
        try {
            const response = await PostageMethodService.GetAllPostageMethod();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
// Slice definition
const PostageMethodSlice = createSlice({
    name: "postageMethod",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchAllPostageMethod.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchAllPostageMethod.fulfilled, (state, action: PayloadAction<any[]>) => {
          state.status = "idle";
          state.postageMethods = action.payload;
        })
        .addCase(fetchAllPostageMethod.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.payload as string;
        })
    }
});

export default PostageMethodSlice.reducer;