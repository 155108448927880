import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import OfficeVatRateService from "../../../../services/Accounting/OfficeAccount/OfficeVatRateService";

export interface OfficeVatRate {
    isActive: boolean;
    percentage: number;
    vatType: string;
    vatTypeId: number;
  
};
// Define the state type for Checklist
type OfficeVatRateState = {
    officeVatRates: OfficeVatRate[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: OfficeVatRateState = {
    officeVatRates: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllOfficeVatRate = createAsyncThunk(
    "fetchAllOfficeVatRate",
    async (_, thunkAPI) => {
        try {
            const response = await OfficeVatRateService.GetAllOfficeVatRate();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const OfficeVatRateSlice = createSlice({
    name: "officeVatRate",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllOfficeVatRate.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllOfficeVatRate.fulfilled, (state, action: PayloadAction<OfficeVatRate[]>) => {
                state.status = "idle";
                state.officeVatRates = action.payload;
            })
            .addCase(fetchAllOfficeVatRate.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

        }
    });
    
    export default OfficeVatRateSlice.reducer;