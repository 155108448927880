import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import YearEndService from "../../../../services/Accounting/OfficeAccount/YearEndService";



export interface YearEnd {
    //creditNoteId: number;
   
};
// Define the state type for Checklist
type YearEndState = {
    yearEnds: YearEnd[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: YearEndState = {
    yearEnds: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllYearEnd = createAsyncThunk(
    "fetchAllYearEnd",
    async (_, thunkAPI) => {
        try {
            const response = await YearEndService.GetAllYearEnd();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const YearEndSlice = createSlice({
    name: "yearEnd",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllYearEnd.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllYearEnd.fulfilled, (state, action: PayloadAction<YearEnd[]>) => {
                state.status = "idle";
                state.yearEnds = action.payload;
            })
            .addCase(fetchAllYearEnd.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
           
    }
});

export default YearEndSlice.reducer;
