import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import IndemnityInsuranceService from "../../../../services/OfficeManagement/OfficeInformation/IndemnityInsuranceService";

export interface IndemnityInsurance {

  indemnityInsuranceId: number;
}
// Define the state type for Checklist
type IndemnityInsuranceState = {
    indemnityInsurances: PaginationResponse<IndemnityInsurance> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: IndemnityInsuranceState = {
    indemnityInsurances: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllIndemnityInsurance = createAsyncThunk(
    "fetchAllIndemnityInsurance",
    async (data: any, thunkAPI) => {
        try {
            const response = await IndemnityInsuranceService.GetAllIndemnityInsurance(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addIndemnityInsurance = createAsyncThunk(
    "addIndemnityInsurance",
    async (data: any, thunkAPI) => {
        try {
            const response = await IndemnityInsuranceService.PostIndemnityInsurance(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateIndemnityInsurance = createAsyncThunk(
    "updateIndemnityInsurance",
    async ({ id, data }: { id: number, data: IndemnityInsurance }, thunkAPI) => {
        try {
            const response = await IndemnityInsuranceService.PutIndemnityInsurance(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteIndemnityInsurance = createAsyncThunk(
    "deleteIndemnityInsurance",
    async (id: number, thunkAPI) => {
        try {
            await IndemnityInsuranceService.deleteIndemnityInsurance(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const IndemnityInsuranceSlice = createSlice({
    name: "indemnityInsurance",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllIndemnityInsurance.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllIndemnityInsurance.fulfilled, (state, action: PayloadAction<PaginationResponse<IndemnityInsurance>>) => {
                state.status = "idle";
                state.indemnityInsurances = action.payload;
            })
            .addCase(fetchAllIndemnityInsurance.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addIndemnityInsurance.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addIndemnityInsurance.fulfilled, (state, action: PayloadAction<IndemnityInsurance>) => {
                state.status = "idle";
               // state.indemnityInsurances.push(action.payload);
            })
            .addCase(addIndemnityInsurance.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateIndemnityInsurance.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateIndemnityInsurance.fulfilled, (state, action: PayloadAction<IndemnityInsurance>) => {
                state.status = "idle";
                // const index = state.indemnityInsurances.findIndex(t => t.indemnityInsuranceId === action.payload.indemnityInsuranceId);
                // if (index !== -1) {
                //     state.indemnityInsurances[index] = action.payload;
                // }
            })
            .addCase(updateIndemnityInsurance.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteIndemnityInsurance.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteIndemnityInsurance.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.indemnityInsurances = state.indemnityInsurances.filter(t => t.indemnityInsuranceId !== action.payload);
            })
            .addCase(deleteIndemnityInsurance.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default IndemnityInsuranceSlice.reducer;
