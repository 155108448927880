import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AccountService from "../../../../services/Accounting/Account.service";


export interface Account {
    bankAccountId: number;
    fkBranchId: number;
    fkNominalAccountId: number;
    bankAccountType: string;
    bankAccountCode: string;
    institution: string;
    accountName: string;
    accountNumber: string;
    accountSortCode: string;
    glAccountCode: string;
    lastChequeNumber: string;
    lastEtfNumber: string;
    isActive: boolean;
    isDefaultAccount: boolean;

};

export interface Journalaccounts{
    bankaccountList:[];
    caseList: [];
    totalAccountCount: number;
    totalCaseCount: number;
}
// Define the state type for Checklist
type AccountState = {
    accounts: Account[];
    status: "idle" | "loading" | "failed" | "Added";
    error: string | null;
    journalaccounts: Journalaccounts
};

// Initial state
const initialState: AccountState = {
    accounts: [],
    status: "idle",
    error: null,
    journalaccounts: null!,
};

export const searchJournalAccounts = createAsyncThunk(
    "searchJournalAccounts",
    async (data: any, thunkAPI) => {
        try {
            const response = await AccountService.SearchJournalAccounts(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Async thunks for CRUD operations
export const fetchAllAccount = createAsyncThunk(
    "fetchAllAccount",
    async (_, thunkAPI) => {
        try {
            const response = await AccountService.GetAllAccount();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addAccount = createAsyncThunk(
    "addAccount",
    async (data: any, thunkAPI) => {
        try {
            const response = await AccountService.PostAccount(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateAccount = createAsyncThunk(
    "updateAccount",
    async ({ id, data }: { id: number, data: Account }, thunkAPI) => {
        try {
            const response = await AccountService.PutAccount(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteAccount = createAsyncThunk(
    "deleteAccount",
    async (id: number, thunkAPI) => {
        try {
            await AccountService.deleteAccount(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const AccountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(searchJournalAccounts.pending, (state) => {
                state.status = "loading";
            })
            .addCase(searchJournalAccounts.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.journalaccounts = action.payload;
            })
            .addCase(searchJournalAccounts.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(fetchAllAccount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllAccount.fulfilled, (state, action: PayloadAction<Account[]>) => {
                state.status = "idle";
                state.accounts = action.payload;
            })
            .addCase(fetchAllAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addAccount.pending, (state) => {
                state.status = "loading";
            })
           
            .addCase(addAccount.fulfilled, (state) => {
                state.status = "Added";
               // state.feeTypes.push(action.payload);
            })
            .addCase(addAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateAccount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateAccount.fulfilled, (state, action: PayloadAction<Account>) => {
                state.status = "idle";
                const index = state.accounts.findIndex(t => t.bankAccountId === action.payload.bankAccountId);
                if (index !== -1) {
                    state.accounts[index] = action.payload;
                }
            })
            .addCase(updateAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteAccount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteAccount.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.accounts = state.accounts.filter(t => t.bankAccountId !== action.payload);
            })
            .addCase(deleteAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default AccountSlice.reducer;
