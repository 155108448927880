import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";

const GetAllSendDetails = async () => {
    try {
        const response = await axiosInstance.get("SendDetails");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutSendDetails = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`SendDetails/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteSendDetails= async (id: number) => {
    try {
        await axiosInstance.delete(`/SendDetails/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const SendDetailsService = {
    GetAllSendDetails,
    PutSendDetails,
    deleteSendDetails
};


export default SendDetailsService;