import axiosInstance from '../axiosInstance';
import { ErrorResult } from '../../store/model/errorResult';


const GetAllCaseType = async () => {
    try {
        const response = await axiosInstance.get("CaseType");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch CaseType:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch CaseType");
    }
}

// Add a new caseType
const PostCaseType = async (data: any) => {
    try {
        const response = await axiosInstance.post("CaseType", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Update a caseType
const PutCasetype = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`CaseType/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Delete a caseType
const deleteCaseType = async (casetypeId: number) => {
    try {
        await axiosInstance.delete(`/Casetype/${casetypeId}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}
const CaseTypeService = {
    GetAllCaseType,
    PostCaseType,
    PutCasetype,
    deleteCaseType,
};

export default CaseTypeService;
