import { Modal } from 'flowbite-react';
interface DeleteModalProps {
    open: boolean;
    onClose: any;
    title?: string;
    message?: string;
    id: number;
    onDeleteAccept:() =>void;
}

const DeleteModal = ({ 
    open, 
    onClose, 
    id, 
    title = "", 
    message = "Are you sure?",
    onDeleteAccept
}: DeleteModalProps) => {

    // const onDelete = () => {
    //     if(type === "feeType"){
            
    //     try {
    //         dispatch(deleteFeeType(id)).unwrap();
    //         onClose();
    //         toast.success("Successfully Deleted Fee Type");
    //     } catch (error: any) {
    //         toast.error(error.toString());
    //     } 
    //     console.log(id);
    //     } 
    //     else if( type === "bankAccount")  {
    //         try {
    //             dispatch(deleteAccount(id)).unwrap();
    //             onClose();
    //             toast.success("Successfully Deleted Account");
    //         } catch (error: any) {
    //             toast.error(error.toString());
    //         } 
    //         console.log(id);
    //     }   
    //     else if( type === "disbursment")  {
    //         try {
    //             dispatch(deleteDisbursementType(id)).unwrap();
    //             onClose();
    //             toast.success("Successfully Deleted Account");
    //         } catch (error: any) {
    //             toast.error(error.toString());
    //         } 
    //         console.log(id);
    //     } 
    //     else if( type === "invoiceNotice")  {
    //         try {
    //             dispatch(deleteInvoiceNotice(id)).unwrap();
    //             onClose();
    //             toast.success("Successfully Deleted Account");
    //         } catch (error: any) {
    //             toast.error(error.toString());
    //         } 
    //         console.log(id);
    //     } 
    //     else if( type === "quotationNotice")  {
    //         try {
    //             dispatch(deleteQuotationNotice(id)).unwrap();
    //             onClose();
    //             toast.success("Successfully Deleted Account");
    //         } catch (error: any) {
    //             toast.error(error.toString());
    //         } 
    //         console.log(id);
    //     } else if( type === "timeEntryType")  {
    //         try {
    //             dispatch(deleteTimeEntryType(id)).unwrap();
    //             onClose();
    //             toast.success("Successfully Deleted Account");
    //         } catch (error: any) {
    //             toast.error(error.toString());
    //         } 
    //         console.log(id);
    //     } else if( type === "timeEntryUnitPrice")  {
    //         try {
    //             dispatch(deleteTimeEntryUnitPrice(id)).unwrap();
    //             onClose();
    //             toast.success("Successfully Deleted Account");
    //         } catch (error: any) {
    //             toast.error(error.toString());
    //         } 
    //         console.log(id);
    //     } else if( type === "partnerShipAccount")  {
    //         try {
    //             dispatch(deletePartnerShipAccount(id)).unwrap();
    //             onClose();
    //             toast.success("Successfully Deleted Account");
    //         } catch (error: any) {
    //             toast.error(error.toString());
    //         } 
    //         console.log(id);
    //     }else if( type === "cpBankReceipt")  {

    //         console.log(id);
    //     }else if( type === "cpBankNewPayment")  {
    //         try {
    //             dispatch(deleteBankNewPayment(id)).unwrap();
    //             onClose();
    //             toast.success("Successfully Deleted BankNewPayment");
    //         } catch (error: any) {
    //             toast.error(error.toString());
    //         } 
    //         console.log(id);
    //     }
    //     };

    return (<Modal className={`backdrop-blur-sm items-center justify-center`}
        show={open}
        position={'center'}
        onClose={() => onclose}>
        <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
            <span className="dark:text-white-bg text-xl w-auto">{title}</span>
        </Modal.Header>
        <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <div className="text-center">
            
            <p className="mb-5 text-base text-black-700 dark:text-black-300">
                {message}
            </p>

            <div className="flex justify-end gap-4">
                <button
                    type="button"
                    className="px-6 py-2 text-sm font-semibold text-gray-800 bg-gray-200 rounded-md hover:bg-gray-300 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
                    onClick={onClose}>
                    No
                </button>

                <button type="button" className="px-6 py-2 text-sm font-semibold text-white bg-red-600 rounded-md hover:bg-red-700"
                   onClick={onDeleteAccept}>
                    Yes
                </button>
            </div>
        </div>
        </Modal.Body>
    </Modal>
       
    );
};

export default DeleteModal;
