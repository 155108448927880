import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";




const GetProfessionalFeeNumber = async () => {
    try {
        const response = await axiosInstance.get("ProfessionalFeeNumber");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetProfessionalFeeSearch = async () => {
    try {
        const response = await axiosInstance.post("ProfessionalFeeSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostProfessionalFee = async (data: any) => {
    try {
        const response = await axiosInstance.post("ProfessionalFee", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutProfessionalFee = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`NewInvoice/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


// const deleteNewInvoice = async (id: number) => {
//     try {
//         await axiosInstance.delete(`/NewInvoice/${id}`);
//     } catch (error: any) {
//         let data = error as ErrorResult;
//         throw new Error(data.messages.toString());
//     }
// }

const ProfessionalFeeService = {
    GetProfessionalFeeNumber,
    PostProfessionalFee,
    PutProfessionalFee,
    GetProfessionalFeeSearch
    //deleteNewInvoice
};


export default ProfessionalFeeService;
