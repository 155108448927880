import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllLedgerCard = async (data?:any) => {
    try {
        const response = await axiosInstance.post("ClientLedgerCardsSearch", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllCAFSData = async (id: number) => {
    try {
        const response = await axiosInstance.get(`ClientFinancialStatement/${id}`);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetLedgerCardById = async (id: number) => {
    try {
        const response = await axiosInstance.get(`ClientLedgerCards/${id}`);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostCAFSData = async (data: any) => {
    try {
        const response = await axiosInstance.post("ClientLedgerCards/financialaddition", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const LedgerCardService = {
    GetAllLedgerCard,
    GetAllCAFSData,
    GetLedgerCardById,
    PostCAFSData,
};


export default LedgerCardService;