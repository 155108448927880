import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddTimeEntryUnitPrices } from "../Modal/AccountSetup/AddTimeEntryUnitPrices";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { deleteTimeEntryUnitPrice, fetchAllTimeEntryUnitPrice } from "../../store/slice/Accounting/AccountSetup/TimeEntryUnitPriceSlice";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { Pagination } from "../Pagination";
import DeleteModal from "../Modal/DeleteModal";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { NewCheckBoxFilter } from "../Filters/CheckBoxFilter/newCheckBoxFilter";
import { formatCurrency } from "../../utils/currency-format.utilis";

const Roles = [
    { id: 1, label: "Practice Manager" },
    { id: 2, label: "Paralegal" },
    { id: 3, label: "Bookkeeper" },
    { id: 4, label: "Fee Earner" },
    { id: 5, label: "Office Manager" },
    { id: 6, label: "Accountant" },
  ];
  

function TimeEntryUnitPrices() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { timeEntryUnitPrices, status, error } = useSelector((state: RootState) => state.timeEntryUnitPrice);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [timeEntryUnitPriceData, setTimeEntryUnitPriceData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [openDelModal, setOpenDelModal] = useState(false);
    const [timeEntryUnitPriceId, setTimeEntryUnitPriceId] = useState<number>(0);
    const [selectedFilters, setSelectedFilters] = useState<number[]>([]);


    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current || status === "Added") {
            
            dispatch(fetchAllTimeEntryUnitPrice())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            // toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (filters: number[]) => {
        setSelectedFilters(filters);
      };

    const filterTimeEntryUnitPrices = (data: any[]) => {
        return data.filter((timeEntryUnitPrice: any) => {
            const accountName = timeEntryUnitPrice?.userRoleName?.toLowerCase() || "";
          const accountSortCode = timeEntryUnitPrice?.timeEntryType?.toLowerCase() || "";
          const roleType = timeEntryUnitPrice?.roleType || "";
      
          // Check if the account matches the search query
          const matchesSearch =
            searchQuery.trim() === "" ||
            accountName.includes(searchQuery.toLowerCase()) ||
            accountSortCode.includes(searchQuery.toLowerCase());
      
          // Check if the account matches the selected filter (account type)
          const matchesFilter =
            selectedFilters.length === 0 ||
            (selectedFilters.includes(1) && roleType === "Practice Manager") ||
            (selectedFilters.includes(2) && roleType === "Paralegal") || 
            (selectedFilters.includes(3) && roleType === "Bookkeeper") ||
            (selectedFilters.includes(4) && roleType === "Fee Earner") ||
            (selectedFilters.includes(5) && roleType === "Office Manager") ||
            (selectedFilters.includes(6) && roleType === "Accountant");
      
          // Return true if both conditions are met
          return matchesSearch && matchesFilter;
        });
      };
       

    const filteredByTimeEntryUnitPrice = filterTimeEntryUnitPrices(timeEntryUnitPrices);

    const indexOfLastTimeEntryUnitPrice = currentPage * itemsPerPage;
    const indexOfFirstTimeEntryUnitPrice = indexOfLastTimeEntryUnitPrice - itemsPerPage;
    const currentTimeEntryUnitPrice = filteredByTimeEntryUnitPrice.slice(indexOfFirstTimeEntryUnitPrice, indexOfLastTimeEntryUnitPrice);


    // Calculate the total number of pages
    const totalPages = Math.ceil(timeEntryUnitPrices.length / itemsPerPage);

    

    if (initialLoading) {
        return <MainAnimation />;
    }

    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };

    const onDeleteCall = () => {
        try {
          dispatch(deleteTimeEntryUnitPrice(timeEntryUnitPriceId)).unwrap();
          setOpenDelModal(false)
          toast.success("Successfully Deleted Time Entry unit Price");
        } catch (error: any) {
          toast.error(error.toString());
        }
      };


    return (
        <>
            <div className="">
            {openDelModal && (
            <DeleteModal
                    open={openDelModal}
                    onClose={() => setOpenDelModal(false)}
                    onDeleteAccept={onDeleteCall}
                    id={timeEntryUnitPriceId}
                    title="demo.laitlegal.uk.says"
                    message="Are you sure?"
                />
                )}
                <div className="mb-5">
                    <SubHeaderTitle title="Time Entry Unit Prices" buttonName="Time Entry Unit Price" isAdd={true} onClickAdd={toggleDrawer} />
                    {(openModal) &&
                        <AddTimeEntryUnitPrices openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} timeEntryUnitPrice={timeEntryUnitPriceData} />
                    }
                    <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                        <SearchInput title="Search TimeEntryUnitPrice" value={searchQuery} onChange={handleSearchChange} />
                        <div>
                            <div className="flex gap-4 md:gap-1 w-full">
                            <NewCheckBoxFilter title="Role" data={Roles} showSearchInput={false} onFilterChange={handleFilterChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="relative overflow-hidden sm:rounded-lg my-5">
                        <div className="overflow-x-auto max-h-96">
                        <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                            <thead className="sticky top-0 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-800 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        Role
                                    </th>
                                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        Time Entry Type
                                    </th>
                                    <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        Unit Price (£)
                                    </th>
                                    <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        Action
                                    </th>

                                </tr>
                            </thead>
                            <tbody className="text-lg 3xl:text-xs">
                                {currentTimeEntryUnitPrice.map((timeEntryUnitPrice: any) => (
                                    <tr key={timeEntryUnitPrice.roleTimeEntryPriceId} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                            {timeEntryUnitPrice.userRoleName}
                                        </td>
                                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                            {timeEntryUnitPrice.timeEntryType}
                                        </td>
                                        <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                            {formatCurrency(timeEntryUnitPrice.unitPrice)}
                                        </td>
                                        <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 cursor-pointer">
                                            <div className="flex gap-3 w-full">
                                            <FaEdit className="fill-blue-500 w-6 h-6"
                                            onClick={() => { setOpenModal(true); setIsEdit(true); setTimeEntryUnitPriceData(timeEntryUnitPrice) }} />
                                            <MdDelete className="fill-red-600 w-6 h-6" 
                                         onClick={() => {
                                            setOpenDelModal(true);
                                            setTimeEntryUnitPriceId(timeEntryUnitPrice.roleTimeEntryPriceId);
                                          }}
                                        />
                                            </div>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>

                        {currentTimeEntryUnitPrice.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                                <p className="text-red-800">No TimeEntryUnitPrice found.</p>
                            </div>
                        )}
                    </div>
                    {currentTimeEntryUnitPrice.length > 0 && (
                        <div className="w-full flex flex-col pb-[100px]">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                totalRecords={filteredByTimeEntryUnitPrice.length}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}

export { TimeEntryUnitPrices };