import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import PropertyConditionTypeService from "../../../../services/RiskManagement/RiskAssessment/PropertyConditionTypeService";



export interface PropertyConditionType {
    ConditionTypeId: number;
    ConditionType: string;
    IsActive: boolean;
};

// Define the state type for casetype
type PropertyConditionTypeState = {
    propertyConditionType: PropertyConditionType[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: PropertyConditionTypeState = {
    propertyConditionType: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllPropertyConditionType = createAsyncThunk(
    "fetchAllPropertyConditionType",
    async (_, thunkAPI) => {
        try {
            const response = await PropertyConditionTypeService.GetAllPropertyConditionType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const PropertyConditionTypeSlice = createSlice({
    name: "propertyConditionType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllPropertyConditionType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllPropertyConditionType.fulfilled, (state, action: PayloadAction<PropertyConditionType[]>) => {
                state.status = "idle";
                state.propertyConditionType = action.payload;
            })
            .addCase(fetchAllPropertyConditionType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
    }
});

export default PropertyConditionTypeSlice.reducer;
