import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { fetchAllC2OReports } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/ClientReports/ClientReportsSlice";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { Pagination } from "../Pagination";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";

function ReportsClientToOffice() {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [clientToOfficeReportsDataList, setClientToOfficeReportsDataList] =
    useState<any>([]);
  const clientToOfficeReports = useSelector(
    (state: RootState) => state.clientReports.clientToOfficeReports
  );
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [currentcurrentC2OList, setCurrentcurrentC2OList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(null);
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(null);

  const toggleDrawer = () => {
    setOpenModal(!openModal);
  };

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchAllC2OReports())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      clientToOfficeReports &&
      clientToOfficeReports.clientToOfficeReportResult
    ) {
      setClientToOfficeReportsDataList(
        clientToOfficeReports.clientToOfficeReportResult
      );
    }
  }, [clientToOfficeReports]);

  const filterC2OBySearchQuery = () => {
    return clientToOfficeReportsDataList.filter(
      (protectedFund: any) =>
        protectedFund?.accountName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        protectedFund?.caseName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        protectedFund?.toAccountName
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
  };
  useEffect(() => {
    if (
      clientToOfficeReportsDataList != null &&
      clientToOfficeReportsDataList.length > 0
    ) {
      const filteredByC2O = filterC2OBySearchQuery();
      const indexOfLastC2O = currentPage * itemsPerPage;
      const indexOfFirstC2O = indexOfLastC2O - itemsPerPage;
      const currentC2O = filteredByC2O.slice(indexOfFirstC2O, indexOfLastC2O);
      const totalPages = Math.ceil(
        clientToOfficeReportsDataList.length / itemsPerPage
      );
      setTotalPages(totalPages);
      setCurrentcurrentC2OList(currentC2O);
    }
  }, [clientToOfficeReportsDataList]);

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };

  return (
    <>
      <div className="">
        <div className="flex items-center justify-between mb-5">
          <div>
          <SubHeaderTitle title="Client to Office" />
          </div>
          <div className="flex gap-x-2 ietms-center justify-end">
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-lg 3xl:text-sm"
            >
              <IoIosPrint className="w-5 h-5" />
              Print
            </button>
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-lg 3xl:text-sm"
            >
              <BsFileEarmarkExcelFill className="w-5 h-5" />
              Export to Excel
            </button>
          </div>
        </div>
        <div className="flex flex-row md:flex-col lg:flex-col xl:flex-col justify-between my-5 gap-4 w-full md:gap-3">
        <div className="md:flex lg:flex xl:flex">
            <SearchInput title="Keyword" />
          </div>
        <div>
            <div className="grid grid-cols-3 md:grid-cols-2 gap-x-4 gap-y-2 md:gap-1 w-full">
              {/* <CheckBoxFilter title="Areas of Practice" /> */}
              <CheckBoxFilter title="Case Type" />
              {/* <CheckBoxFilter title="Case Sub Type" /> */}
              <CheckBoxFilter title="Client Bank" />
              <JustifyDateFilter
                title="Date Between"
                plannedStartDate={plannedStartDate}
                plannedEndDate={plannedEndDate}
                onPlannedStartDateChange={handleStartDateChange}
                onPlannedEndDateChange={handleEndDateChange}
              />
            </div>
          </div>
        </div>
        <div className="my-5">
          <div className="my-5 text-center bg-sky-50 dark:bg-gray-800 dark:text-white-bg p-6">
            <h1 className="text-2xl font-bold">Shan & Co Solicitors</h1>
            <p className="text-lg font-semibold">
              Client Bank to Office Bank Transaction Report
            </p>
            <p className="font-semibold">04 Sep 2024</p>
          </div>
          <div className="space-y-2">
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Area of Practice
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Case Type
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Case Sub Type
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Client Bank
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Date
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">All</p>
            </div>
            <div className="flex items-center">
              <label className="w-1/5 md:w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                Keyword
              </label>
              <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                :
              </span>
              <p className="w-4/5 md:w-1/2 text-lg 3xl:text-sm dark:text-white-bg">N/A</p>
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <div className="overflow-x-auto max-h-96">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Case Reference
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  From Bank (Client)
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  To Bank (Office)
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Details
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentcurrentC2OList.map((C2O: any, index) => (
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {new Date(C2O.entryDate).toISOString().split("T")[0]}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{C2O.caseReferenceAuto}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <p className="font-semibold">
                      {C2O.accountName} - {C2O.bankAccountType}
                    </p>
                    <p>(45896315)</p>
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <p className="font-semibold">
                      {C2O.toAccountName} - {C2O.toBankAccountType}
                    </p>
                    <p>(63253477)</p>
                  </td>
                  <td className=" px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    INV {C2O.invoiceNumber}
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{C2O.amountTransfer}</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          {currentcurrentC2OList.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Office To Client Reports.</p>
            </div>
          )}
        </div>
        {currentcurrentC2OList.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={currentcurrentC2OList.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { ReportsClientToOffice };
