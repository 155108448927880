import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetAllBalanceSheet = async (data: any) => {
    try {
        const response = await axiosInstance.post("officeaccount/officereport/balanceSheet", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const BalanceSheetService = {
    GetAllBalanceSheet,
   
};


export default BalanceSheetService;
