
export interface MenuItem {
    title: any;
    icon?: JSX.Element;
    path: any;
}

export const AdministratorMenuData: MenuItem[] = [
    {
        title: 'User Role ',
        path: '/administrator/roleManagement'
    },
    {
        title: 'User Management',
        path: '/administrator/usermanagement'
    },
    {
        title: 'Company Profile',
        path: '/administrator/companyprofile'
    },
    {
        title: 'OutLook API',
        path: '/administrator/outLookAPI'
    },
    {
        title: 'Land Registry Account',
        path: '/administrator/landregistryaccount'
    },
    {
        title: 'HMRC Stamp Duty',
        path: '/administrator/HMRCStampDuty'
    },
    {
        title: 'Outlook Email Setup',
        path: '/administrator/OutlookEmailSetup'
    },
    {
        title: 'HMRC API',
        path: '/administrator/HMRCAPI'
    },
    {
        title: 'Format Email Body',
        path: '/administrator/FormatEmailBody'
    },
]