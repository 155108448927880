export const formatDateTime = (dateTimeString: string) => {
 // Regex to match "YYYY-MM-DD", "YYYY-MM-DDTHH:MM:SS", or "YYYY-MM-DDTHH:MM:SSZ"
 const dateRegex = /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:Z)?)?$/;

 // Validate against the regex
 if (!dateRegex.test(dateTimeString)) {
   return { formattedDate: '', formattedTime: '' }; // Return empty strings if invalid
 }

 const date = new Date(dateTimeString);

 // Format Date as DD/MM/YYYY
 const month = String(date.getMonth() + 1).padStart(2, '0');
 const day = String(date.getDate()).padStart(2, '0');
 const year = date.getFullYear();
 const formattedDate = `${day}/${month}/${year}`;

 // Format Time as HH:MM AM/PM, or return an empty string if time is not present
 let formattedTime = '';
 if (dateTimeString.includes('T')) { // Only format time if the string includes time
   let hours = date.getHours();
   const minutes = String(date.getMinutes()).padStart(2, '0');
   const ampm = hours >= 12 ? 'PM' : 'AM';
   hours = hours % 12 || 12; // Convert 0 to 12 for AM/PM format
   formattedTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
 }

 return { formattedDate, formattedTime };
};


export const formatDateOnlyDisplay = (dateTimeString: string) => {
    let formattedDate = '';
    // Regex to match "YYYY-MM-DD", "YYYY-MM-DDTHH:MM:SS", or "YYYY-MM-DDTHH:MM:SSZ"
    const dateRegex = /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:Z)?)?$/;
   
    // Validate against the regex
    if (!dateRegex.test(dateTimeString)) {
      return formattedDate;
    }
   
    const date = new Date(dateTimeString);
   
    // Format Date as DD/MM/YYYY
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
     formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
   };

   export const formatDateISODisplay = (dateTimeString: string) => {
    let formattedDate = '';
    // Regex to match "YYYY-MM-DD", "YYYY-MM-DDTHH:MM:SS", or "YYYY-MM-DDTHH:MM:SSZ"
    const dateRegex = /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:Z)?)?$/;
   
    // Validate against the regex
    if (!dateRegex.test(dateTimeString)) {
      return formattedDate;
    }
   
    const date = new Date(dateTimeString);
   
    // Format Date as DD/MM/YYYY
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
     formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
   };