import React, { useRef } from 'react';

interface MainFilterProps {
    title?: string;
    startDate?: any | null;
    endDate?: any | null;
    onClearDates?: () => void;
}

const MainFilter: React.FC<MainFilterProps> = ({ title, startDate, endDate, onClearDates }) => {
    // const detailsRef = useRef<HTMLDetailsElement>(null);

    

    return (
        <div className="flex item-center gap-5 bg-gray-100 dark:bg-gray-900 px-6 py-2">
            <span className="py-3 dark:text-white-bg">Filters</span>
            <div className="w-px h-6 bg-gray-300 my-3"></div>
            <button className="flex items-center justify-center bg-blue-500 text-white px-4 py-2 md:px-2 md:py-1 md:w-fit rounded hover:bg-blue-600 transition duration-300">
                <span className="text-white-bg mx-2">View tasks</span>
                <svg className="w-4 h-4 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
            </button>
            {(startDate && endDate) &&(
                <button className="flex items-center justify-center bg-blue-500 text-white px-4 py-2 md:px-2 md:py-1 md:w-fit rounded hover:bg-blue-600 transition duration-300">
                    <span className="text-white-bg mx-2">
                        {`${title}: ${startDate} to ${endDate}`}
                    </span>
                    <svg onClick={onClearDates} className="w-4 h-4 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                </button>
            )}
        </div>
    )
}

export { MainFilter };