import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, boolean, number } from 'yup';
import { useAppDispatch } from '../../../../store/hooks/redux-hooks';
import { SelectBox } from '../../../SelectBox';
import { toast } from 'react-toastify';
import { addPartnerShipAccount } from '../../../../store/slice/Accounting/AccountSetup/PartnerShipAccountSlice';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { fetchAllNominalLedger } from '../../../../store/slice/Accounting/AccountSetup/NominalLedgerSlice';
import { getValue } from '@testing-library/user-event/dist/utils';

function AddPartnershipAccounts({ openDrawer, Close, isEdit, partnerShipAccount }: { openDrawer: boolean, Close: any, isEdit: boolean, partnerShipAccount: any }) {
    const [title] = useState((!isEdit ? 'Add New Partnership Account' : 'Edit PartnerShip Account'))
    const dispatch = useAppDispatch();
    const [openModal, setOpenModal] = useState(true);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center');
    const [isLoading, setIsLoading] = useState(false);
    const [existingFeeType, setExistingFeeType] = useState(partnerShipAccount);
    const { nominalLedgers } = useSelector((state: RootState) => state.nominalLedger);
    const [initialLoading, setInitialLoading] = useState(true);
    const [nominalLedgersOptionsLists, setNominalLedgersOptionsLists] = useState<any[]>([]);

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);

    useEffect(() => {
        if (!effectServiceCall.current) {
            const fetchPromises = [
                dispatch(fetchAllNominalLedger()),
            ];

            Promise.all(fetchPromises)
                .then(() => {
                    setInitialLoading(false);
                })
                .catch((error) => {
                    if (!errorShownRef.current) {
                        toast.error("Error fetching data: " + error.message);
                        errorShownRef.current = true;
                    }
                    setInitialLoading(false);
                });

            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (nominalLedgers != null && nominalLedgers.length > 0) {
            console.log(nominalLedgers)
            let data = nominalLedgers
                .filter((nominal: any) => (nominal.fkAccountTypeId === 7 && nominal.isActive == 1))
                .map((nominal: any) => ({
                    value: nominal.accountId,
                    label: nominal.accountName,
                    
                }));
            setNominalLedgersOptionsLists(data);
           
        }
    }, [nominalLedgers]);



    const validationSchema = object().shape({
        partnerName: string()
            .required('Partner Name is required'),
        partnershipPercentage: number()
            .required('Partnership Percentage is required'),
        capitalAccountBfwdId: number(),
        capitalIntroducedId: string(),
        capitalInterestCreditedAccount: number(),
        currentAccountBfwdId: number(),
        currentCapitalIntroducedId: number(),
        currentInterestCreditedId: number(),
        drawingsId: number(),
        profitForTheYearId: number(),
        isActive: boolean(),
        partnershipId: number(),
    });

    const { register, handleSubmit, reset, setValue,getValues, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            isActive: true,
            partnershipId: 0,
        }
    });

    const onSubmit = async (data: any) => {
        //console.log(JSON.stringify(data, null, 2));

        setIsLoading(true);
        try {
            if (isEdit && partnerShipAccount) {
                // Update existing fee type
                data.partnershipId = partnerShipAccount.partnershipId;
                await dispatch(addPartnerShipAccount(data )).unwrap();
                toast.success("Successfully Updated Partnership Account");
                console.log(partnerShipAccount.id)
            } else {
            await dispatch(addPartnerShipAccount(data)).unwrap();
            
            toast.success("Successfully Added Partnership Account");
                } 
                Close();
            }
        catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
      };

    // useEffect(() => {
    //     if (isEdit && partnerShipAccount) {
    //         setExistingFeeType(partnerShipAccount);
    //         reset(partnerShipAccount);
    //     }
    // }, [partnerShipAccount, isEdit, reset, setValue]);

    useEffect(() => {
        if(partnerShipAccount != null && isEdit === true){
            setValue('partnerName', partnerShipAccount?.partnerName)
            setValue('partnershipPercentage', partnerShipAccount?.partnershipPercentage)
            setValue('capitalAccountBfwdId', partnerShipAccount?.capitalAccountBfwdId)
            setValue('currentCapitalIntroducedId', partnerShipAccount?.currentCapitalIntroducedId)
            setValue('currentInterestCreditedId', partnerShipAccount?.currentInterestCreditedId)
            setValue('isActive', partnerShipAccount?.isActive)
            setValue('drawingsId', partnerShipAccount?.drawingsId)
            setValue('profitForTheYearId', partnerShipAccount?.profitForTheYearId)
            setValue('partnershipId', partnerShipAccount?.partnershipId)
        }
    }, [partnerShipAccount, isEdit, reset, setValue]);



    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); setOpenModal(Close); }}
            initialFocus={emailInputRef}
            size="3xl"
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="dark:text-white-bg text-xl w-auto">Add Partnership Accounts</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">
                <form onSubmit={handleSubmit(onSubmit,onError)} className="mx-auto" >
                    <div className="grid grid-cols-3 md:grid-cols-1 gap-3 w-full">
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Partner Name <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.partnerName ? 'is-invalid' : ''}`} {...register('partnerName')}
                                placeholder="Enter Partner Name"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.partnerName?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Partnership Percentage <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.partnershipPercentage ? 'is-invalid' : ''}`} {...register('partnershipPercentage')}
                                placeholder="Enter Partnership Percentage"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.partnershipPercentage?.message?.toString()}</div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Capital Account bfwd
                            </label>
                            <SelectBox
                                className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={nominalLedgersOptionsLists}
                                value={getValues().capitalAccountBfwdId}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('capitalAccountBfwdId', selectedOption);
                                    }
                                }}
                            />
                        </div>

                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Capital Introduced Account
                            </label>
                            <SelectBox
                                className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={nominalLedgersOptionsLists}
                                value={getValues().capitalIntroducedId}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('capitalIntroducedId', selectedOption);
                                    }
                                }}
                            />
                        </div>

                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Capital Interest Credited Account
                            </label>
                            <SelectBox
                                className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={nominalLedgersOptionsLists}
                                value={getValues().capitalInterestCreditedAccount}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('capitalInterestCreditedAccount', selectedOption);
                                    }
                                }}
                            />
                        </div>

                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Current Account bfwd
                            </label>
                            <SelectBox
                                className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={nominalLedgersOptionsLists}
                                value={getValues().currentAccountBfwdId}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('currentAccountBfwdId', selectedOption);
                                    }
                                }}
                            />
                        </div>

                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Current Capital Introduced
                            </label>
                            <SelectBox
                                className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={nominalLedgersOptionsLists}
                                value={getValues().currentCapitalIntroducedId}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('currentCapitalIntroducedId', selectedOption);
                                    }
                                }}
                            />
                        </div>

                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Current Interest Credited A/C
                            </label>
                            <SelectBox
                                className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={nominalLedgersOptionsLists}
                                value={getValues().currentInterestCreditedId}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('currentInterestCreditedId', selectedOption);
                                    }
                                }}
                            />
                        </div>

                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Drawings
                            </label>
                            <SelectBox
                                className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={nominalLedgersOptionsLists}
                                value={getValues().drawingsId}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('drawingsId', selectedOption);
                                    }
                                }}
                            />
                        </div>

                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Profit for the Year A/C
                            </label>
                            <SelectBox
                                className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={nominalLedgersOptionsLists}
                                value={getValues().profitForTheYearId}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('profitForTheYearId', selectedOption);
                                    }
                                }}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <label className="block text-sm font-medium text-gray-900 dark:text-white-bg">
                                Is Active
                            </label>
                            <div className='flex gap-5'>
                                <div>
                                    <input
                                        type="radio"
                                        value="true"
                                        checked={watch('isActive') === true}
                                        onChange={() => setValue('isActive', true)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        value="false"
                                        checked={watch('isActive') === false}
                                        onChange={() => setValue('isActive', false)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                    type='button'
                                    onClick={() => {
                                        reset();
                                        Close();
                                    }}
                                    className="cursor-pointer h-10 rounded-md text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    disabled={isLoading}
                                    className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-md text-center border text-sm w-[120px]"
                                >
                                    {isLoading ? (
                                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                        </svg>
                                    ) : (
                                        'Save'
                                    )}
                                </button>
                            </div>
                        </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export { AddPartnershipAccounts };
