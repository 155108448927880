import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AuthorisationService from "../../../../../services/Accounting/ClientAccounts/Invoice/AuthorisationService";



export interface Authorisation {
    authorisationId: number;
    areasofPractice: string;
    caseType: string;
    caseSubType: string;
    dateBetween: string;
    keyword: string;


};
// Define the state type for Checklist
type AuthorisationState = {
    authorisations: Authorisation[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

//Initial state
const initialState: AuthorisationState = {
    authorisations: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllAuthorisation = createAsyncThunk(
    "fetchAllAuthorisation",
    async (_, thunkAPI) => {
        try {
            const response = await AuthorisationService.GetAllAuthorisation();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateAuthorisation = createAsyncThunk(
    "updateAuthorisation",
    async ({ id, data }: { id: number, data: Authorisation }, thunkAPI) => {
        try {
            const response = await AuthorisationService.PutAuthorisation(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteAuthorisation = createAsyncThunk(
    "deleteAuthorisation",
    async (id: number, thunkAPI) => {
        try {
            await AuthorisationService.deleteAuthorisation(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


const AuthorisationSlice = createSlice({
    name: "authorisation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllAuthorisation.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllAuthorisation.fulfilled, (state, action: PayloadAction<Authorisation[]>) => {
                state.status = "idle";
                state.authorisations = action.payload;
            })
            .addCase(fetchAllAuthorisation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateAuthorisation.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateAuthorisation.fulfilled, (state, action: PayloadAction<Authorisation>) => {
                state.status = "idle";
                const index = state.authorisations.findIndex(t => t.authorisationId === action.payload.authorisationId);
                if (index !== -1) {
                    state.authorisations[index] = action.payload;
                }
            })
            .addCase(updateAuthorisation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteAuthorisation.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteAuthorisation.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.authorisations = state.authorisations.filter(t => t.authorisationId !== action.payload);
            })
            .addCase(deleteAuthorisation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });

        }

        });
 

export default AuthorisationSlice.reducer;
