import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import ImportantDatesAndTaskService from "../../../../services/SystemMaintenance/TaskAssesment/ImportantDatesAndTaskService";

export interface ImportantDatesAndTask {
    importantDatesAndTaskId: number;
    importantDateType: string;
    isActive: boolean;
}

export interface ImportantDatesAndTaskSave {
    importantDatesAndTaskId: number;
    importantDateType: string;
    isActive: boolean;
}
// Define the state type for Checklist
type ImportantDatesAndTaskState = {
    importantDatesAndTasks: ImportantDatesAndTask[];
    importantDatesAndTaskSave: ImportantDatesAndTaskSave[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ImportantDatesAndTaskState = {
    importantDatesAndTasks: [],
    status: "idle",
    error: null,
    importantDatesAndTaskSave: null!,
};

// Async thunks for CRUD operations
export const fetchAllImportantDatesAndTask = createAsyncThunk(
    "fetchAllImportantDatesAndTask",
    async (_, thunkAPI) => {
        try {
            const response = await ImportantDatesAndTaskService.GetAllImportantDatesAndTask();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addImportantDatesAndTask = createAsyncThunk(
    "addImportantDatesAndTask",
    async (data: any, thunkAPI) => {
        try {
            const response = await ImportantDatesAndTaskService.PostImportantDatesAndTask(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateImportantDatesAndTask = createAsyncThunk(
    "updateImportantDatesAndTask",
    async ({ id, data }: { id: any; data: any }, thunkAPI) => {
        try {
            const response = await ImportantDatesAndTaskService.PostImportantDatesAndTask(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteImportantDatesAndTask = createAsyncThunk(
    "deleteImportantDatesAndTask",
    async (id: number, thunkAPI) => {
        try {
            await ImportantDatesAndTaskService.deleteImportantDatesAndTask(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const ImportantDatesAndTaskSlice = createSlice({
    name: "importantDatesAndTask",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllImportantDatesAndTask.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllImportantDatesAndTask.fulfilled, (state, action: PayloadAction<ImportantDatesAndTask[]>) => {
                state.status = "idle";
                state.importantDatesAndTasks = action.payload;
            })
            .addCase(fetchAllImportantDatesAndTask.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addImportantDatesAndTask.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addImportantDatesAndTask.fulfilled, (state, action: PayloadAction<ImportantDatesAndTaskSave>) => {
                state.status = "idle";
                state.importantDatesAndTaskSave.push(action.payload);
            })
            .addCase(addImportantDatesAndTask.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateImportantDatesAndTask.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateImportantDatesAndTask.fulfilled, (state, action: PayloadAction<ImportantDatesAndTask>) => {
                state.status = "idle";
                // const index = state.importantDatesAndTasks.findIndex(t => t.importantDatesAndTaskId === action.payload.importantDatesAndTaskId);
                // if (index !== -1) {
                //     state.importantDatesAndTasks[index] = action.payload;
                // }
            })
            .addCase(updateImportantDatesAndTask.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteImportantDatesAndTask.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteImportantDatesAndTask.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                // state.importantDatesAndTasks = state.importantDatesAndTasks.filter(t => t.importantDatesAndTaskId !== action.payload);
            })
            .addCase(deleteImportantDatesAndTask.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default ImportantDatesAndTaskSlice.reducer;
