import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number, array } from "yup";
import { useEffect, useRef, useState } from "react";
import { addJournalEntry, fetchJournalEntryById, fetchJournalEntryNumber, JournalEntry } from "../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/JournalEntrySlice";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { FaPlus } from "react-icons/fa6";
// import { SearchContact } from '../../../SearchContact';
import { SearchAccountType } from "../../../SearchAccountType";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";
import { InputBox } from "../../../../InputBox";
import { NumericFormat } from "react-number-format";
import { FcCancel, FcPlus } from "react-icons/fc";
import { formatDateISODisplay } from "../../../../../utils/date-time.utils";
import { NumericFormatWrapper } from "../../../../InputComponents/NumericFormatWrapper";

interface JournalLine {
  account: string;
  description: string;
  debitCurrency: number;
  creditCurrency: number;
  journalEntryLineId?: number;
  fkJournalEntryId?: number;
  isDeleted?: boolean;
  lineNumber?: number;
  accountType?: string;
  fkAccountId?: number;
}

function AddJournalEntry({
  openDrawer,
  Close,
  isEdit,
  isView,
  journalEntry,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  isView: boolean;
  journalEntry: any;
}) {
  const [title] = useState(
    !isEdit ? "Add Journal Entry" : "Edit Journal Entry"
  );
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement] = useState("center");
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [openAccountTypeModal, setOpenAccountTypeModal] = useState(false);
  const [existingJournalEntry, setExistingJournalEntry] = useState(journalEntry);
  const [openModals, setOpenModals] = useState<boolean[]>([]);
  const {receiptNumber, journalEntryDataById} = useSelector((state: RootState) => state.journalEntry);
  const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
  const [initialLoading, setInitialLoading] = useState(true);
  const [journalLines, setJournalLines] = useState<JournalLine[]>([]);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);

  const validationSchema = object().shape({
    journalEntryNumber: number(),
    journalEntryDate: string()
    .transform((value, originalValue) => {
      if (originalValue === "" || value === null) return null;
      return new Date(value).toISOString().split("T")[0];
    }),
    journalEntryDescription: string(),
    reference:string(),
    total: number(),
    journalEntryLines: array().of(
      object().shape({
        account: string().required('Account is required'),
        description: string().required('Description is required'),
        debitCurrency: number().required('Debit Currency is required').typeError('Debit Currency must be a number'),
        creditCurrency: number().required('Credit Currency is required').typeError('Credit Currency must be a number'),
      })
    ).min(1, 'At least one journal line is required')
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      journalEntryNumber: autoReceiptNumber,
      journalEntryDate: new Date().toISOString().split("T")[0]
    }
  });

  useEffect(() => {
    setValue("journalEntryDate", new Date().toISOString().split("T")[0]);
  }, []);

  useEffect(() => {
    if (!effectServiceCall.current && !isEdit && !isView) {
      const fetchPromises = [
        dispatch(fetchJournalEntryNumber()),
      ];
      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (receiptNumber !== null) {
      setValue('journalEntryNumber', receiptNumber);
      setAutoReceiptNumber(receiptNumber);
    }
  })

  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };

  const [isDisabled, setIsDisabled] = useState(true);

  const toggleAccountTypeModal = (index: number) => {
    setOpenModals((prev) => {
      const newModals = [...prev];
      newModals[index] = !newModals[index];
      return newModals;
    });
  };


  const handleAddStepClick = () => {

    //  if (validateRows()) {

    setJournalLines([...journalLines, { account: '', description: '', creditCurrency: 0, debitCurrency: 0, fkAccountId:0, lineNumber:1, isDeleted: false, fkJournalEntryId: 1, journalEntryLineId:1 }]);
    //  }
  };

  const handleSaveClick = () => {
    var validateState = true;
    journalLines.forEach((journalLine) => {
      if (journalLine.account === "" || journalLine.debitCurrency === 0 || journalLine.creditCurrency === 0) {
        validateState = false;
      }
    })

  };

  const setFieldValue = (index: number, field: string, value: string) => {
    const updatedLines = journalLines.map((line, i) =>
      i === index ? { ...line, [field]: value } : line
    );
    setJournalLines(updatedLines);
  }


  

  const onSubmit = async (data: any) => {
    console.log(data)
    const journalEntry = {
      journalEntryNumber: data.journalEntryNumber,
      journalEntryDate: data.journalEntryDate,
      journalEntryDescription: data.journalEntryDescription,
      journalEntryLines: journalLines.map((line, index) => ({
        journalEntryLineId: 1,
        fkJournalEntryId: data.journalEntry, 
        isDeleted: false, 
        account: line.account,
        lineNumber: index + 1, 
        accountType: line.accountType || "string", 
        fkAccountId: line.fkAccountId || 0,  
        description: line.description || "string",
        debit: line.debitCurrency || 0,  
        credit: line.creditCurrency || 0,  
      })),
    };

    console.log(journalEntry);

    setIsLoading(true);
    try {
      await dispatch(addJournalEntry(journalEntry)).unwrap();
      Close("success");
      toast.success("Successfully Added journalEntry details");
    } catch (error: any) {
      setIsLoading(true);
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };
  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  const removeRow = (index: number) => {
    setJournalLines(journalLines.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if ((journalEntry != null &&isEdit === true ) || (journalEntry != null && isView === true )) {
      console.log(journalEntry)
      dispatch(  fetchJournalEntryById(journalEntry?.journalEntryId));
    
      setValue('journalEntryNumber', journalEntry?.journalEntryNumber)
      setValue('journalEntryDate', formatDateISODisplay(journalEntry?.journalEntryDate))
      setValue('journalEntryDescription', journalEntry?.journalEntryDescription)
    
      
    }
  }, [journalEntry, isEdit, isView, reset, setValue]);

  useEffect(()=>{
    if(journalEntryDataById!=null && journalEntryDataById.journalLines!=null){

      setJournalLines(journalEntryDataById.journalLines.map((item:any)=>{
        return { account: item.accountType, description: item.description, creditCurrency: item.credit, debitCurrency: item.debit, fkAccountId:item.fkAccountId, lineNumber:item.lineNumber, isDeleted: false, fkJournalEntryId: item.fkJournalEntryId, journalEntryLineId:item.journalEntryLineId }
      }));
    }
  },[journalEntryDataById])

  const handleAccountTypeSelection = (SelectedAccountType: any, index: number) => {
    setJournalLines((prev) =>
      prev.map((line, i) =>
        i === index
          ? {
            ...line,
            account: (SelectedAccountType.accountTypes === "bank") ? SelectedAccountType?.accountName + SelectedAccountType?.bankAccountType : SelectedAccountType.caseReferenceAuto + SelectedAccountType.caseName,
            fkAccountId: (SelectedAccountType.accountTypes === "bank") ? SelectedAccountType.bankAccountId : SelectedAccountType.caseId,
            accountType:SelectedAccountType.accountTypes,
            // journalEntryLineId:(SelectedAccountType.accountTypes == "bank") ? SelectedAccountType?.bankAccountType : SelectedAccountType.caseName, ,
            // fkJournalEntryId:(SelectedAccountType.accountTypes == "bank") ? SelectedAccountType?.bankAccountType : SelectedAccountType.caseName, ,
           
            isDisabled: false,
          }
          : line
      )
    );
    setOpenAccountTypeModal(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-12">
        
        <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-10 gap-y-1 w-full my-3">
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Journal Entry
            </label>
            <input
              type="text"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
              value={getValues().journalEntryNumber}

              disabled={isDisabled}
              placeholder="Journal Entry Id"
            />
          </div>
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Date
            </label>
            <input
              type="date"
            
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                errors.journalEntryDate ? "is-invalid" : ""
              } `}
              {...register("journalEntryDate")}
              placeholder="Enter Date"
              disabled ={isView}
              
            />
              <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                {errors?.journalEntryDate?.message?.toString()}
              </div>
          </div>
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Description
            </label>
            <input
              type="text"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
              {...register("journalEntryDescription")}
              placeholder="Enter Description"
              disabled ={isView}
            />
          </div>
        </div>
        <div className="mx-auto">

          <div className="">
            <button
              className="flex items-center bg-blue-500 text-white-bg px-3 py-1 rounded hover:bg-blue-600 transition duration-300"
              type="button"
              onClick={handleAddStepClick}
            >
              <FaPlus className="w-4 h-4 fill-white-bg" />
              <span className="text-white-bg mx-2">Add A Line</span>
            </button>
          </div>


          <table className="w-full text-lg 3xl:text-xs text-left rtl:text-right dark:text-gray-400 my-5">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">Account</th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">Description</th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">Debit</th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">Credit</th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
              </tr>
            </thead>
            <tbody >
            {journalLines.length > 0 &&
              journalLines.map((journalLine, index) => (
                
                  <tr key={index} className="border-2">
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <div className="flex items-center">
                  <button
                        type="button"
                        onClick={() => toggleAccountTypeModal(index)} // Pass index to identify which row
                       // className="flex items-center bg-blue-500 text-white-bg px-3 py-1 rounded hover:bg-blue-600 transition duration-300 mb-2"
                      >
                        <FcPlus />
                      </button>
                      {openModals[index] && ( // Conditionally render the modal for this row
                        <SearchAccountType
                          openDrawer={openModals[index]}
                          Close={() => toggleAccountTypeModal(index)} // Close the modal for this row
                          onSelectAccountType={(selected) => handleAccountTypeSelection(selected, index)} // Pass index to handle account type selection
                          title="Search Accounts"
                          type="Client"
                          
                        />
                      )}

                        <InputBox
                          dataType="text"
                        placeholder="account"
                        aria-label="Search"
                        value={journalLine.account}
                        onChange={(e) => setFieldValue(index, "account", e.target.value)}
                       // className="border border-gray-300 p-2 rounded mb-2"
                        disabled={journalLine.account ? false : true}
                      />
                  </div>
                  </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                       <InputBox
                          dataType="text"
                        value={journalLine.description}
                        onChange={(e) => setFieldValue(index, "description", e.target.value)}
                        placeholder="description"
                        // className="border border-gray-300 p-2 rounded mb-2"
                        disabled={!journalLine.account}
                      />
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <NumericFormatWrapper
                        value={journalLine.debitCurrency}
                        onChange={(e) => setFieldValue(index, "debitCurrency", e.target.value)}
                        decimalScale={2}
                        placeholder="0.00"
                        className="border border-gray-300 p-2 rounded mb-2"
                        disabled={!journalLine.account}
                      />
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <NumericFormatWrapper
                        value={journalLine.creditCurrency}
                        onChange={(e) => setFieldValue(index, "creditCurrency", e.target.value)}
                        placeholder="0.00"
                        decimalScale={2}
                        className="border border-gray-300 p-2 rounded mb-2"
                        disabled={!journalLine.account} // Disable if account is not set
                      />
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <button type="button" onClick={() => removeRow(index)}>
                        <FcCancel className="w-6 h-6" />
                      </button>
                    </td>
                  </tr>
                   ))}
                 <tr className="border-2 bg-sky-50 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <td></td>
                  <td className="px-6 py-3 text-lg 3xl:text-sm border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right font-semibold">Total</td>
                  {/* Total Credit */}
                  <td className="px-6 py-3 text-lg 3xl:text-sm border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right font-semibold">
                    £
                    {journalLines
                      .reduce((acc, journalLine) => acc + (parseFloat(String(journalLine.debitCurrency))  || 0), 0)
                      .toFixed(2)}
                  </td>
                  {/* Total debitAmount */}
                  <td className="px-6 py-3 text-lg 3xl:text-sm border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35 text-right font-semibold">
                    £
                    {journalLines
                      .reduce((acc, journalLine) => acc + (parseFloat(String(journalLine.creditCurrency)) || 0), 0)
                      .toFixed(2)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></td>
                </tr>
                
             
              </tbody>
          </table>

        </div>
        {!isView && (
        <div className="flex col-span-2 gap-2 justify-end">
          <button
            type="button"
            onClick={() => {
              Close();
            }}
            className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={handleSaveClick}
            className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-lg 3xl:text-sm w-[120px]"
            disabled ={isView}
          >
            {isLoading ? (
              <svg
                className="animate-spin h-5 w-5 text-white-bg mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4z"
                ></path>
              </svg>
            ) : (
              "Save"
            )}
          </button>
        </div>
        )}
      </form>


    </>
  );
}

export { AddJournalEntry };
