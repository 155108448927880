import { Modal } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number } from "yup";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { SelectBox } from "../../SelectBox";
import { addTask } from "../../../store/slice/CaseManagement/taskSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { fetchAllPriorityClient } from "../../../store/slice/Accounting";
import { RootState } from "../../../store/store";
import { fetchUsersDataByBranch } from "../../../store/slice/Administrator/UserManagementSlice";
import { fetchAllTaskStatus } from "../../../store/slice/CaseManagement/TaskStatusSlice";

function AddTask({
  openDrawer,
  Close,
  isEdit,
  task,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  task: any;
}) {
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(true);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [isLoading, setIsLoading] = useState(false);
  const [setExistingTask] = useState(task);
  const [priorityClientLists, setPriorityClientLists] = useState<any[]>([]);
  const { priorityClients } = useSelector(
    (state: RootState) => state.priorityClient
  );
  const { taskStatuses } = useSelector((state: RootState) => state.taskStatus);
  const [taskStatusLists, setTaskStatusLists] = useState<any[]>([]);

  const effectServiceCall = useRef(false);

  const [newTaskFileName, setNewTaskFileName] = useState("");
  const [documentNewTaskFile, setDocumentNewTaskFile] = useState<any>();

  const { branchUsers } = useSelector((state: RootState) => state.userManage);
  const [branchUserLists, setBranchUserLists] = useState<any[]>([]);

  // const status = [
  //   { label: "Working On it", value: 1 },
  //   { label: "Cancelled", value: 2 },
  //   { label: "Compeleted", value: 3 },
  //   { label: "overDue", value: 4 },
  //   { label: "Deleted", value: 5 },
  // ];

  // const mappedStatus = status.map((type: any) => ({
  //   label: type.label,
  //   value: type.value,
  // }));

  const validationSchema = object().shape({
    assignee: string().required("Assign To is required"),
    task: string().required("Task is required"),
    description: string(),
    priority: string(),
    status: string(),
    attachmentFile: string(),
    dueDate: string().transform((value, originalValue) => {
      if (originalValue === "" || value === null) return null;
      return new Date(value).toISOString().split("T")[0];
    }),
    plannedDate: string().transform((value, originalValue) => {
      if (originalValue === "" || value === null) return null;
      return new Date(value).toISOString().split("T")[0];
    }),

    taskId: number(),
    assignTo: number(),
    fkPriorityId: number(),
    attachmentName: string(),
    // attachmentFile: string(),
    currentStatus: number(),
    assignedBy: number(),
    //assignedOn: Date(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    if (documentNewTaskFile == null) {
      toast.error("Please select the file");
      return;
    }

    data.attachmentFile = newTaskFileName;

    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("file", documentNewTaskFile);
    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    try {
      await dispatch(addTask(data)).unwrap();
      Close();
      toast.success("Successfully Added Task");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setDocumentNewTaskFile(file);
      setNewTaskFileName(file.name);
    } else {
      setNewTaskFileName("");
    }
  };

  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchAllPriorityClient());
      dispatch(fetchAllTaskStatus());
      dispatch(fetchUsersDataByBranch());
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (priorityClients.length > 0) {
      let data = priorityClients.map((priorityClient: any, index) => ({
        value: priorityClient.priorityTypeId,
        label: priorityClient.priority,
      }));
      setPriorityClientLists(data);
    }
  }, [priorityClients]);

  useEffect(() => {
    if (taskStatuses.length > 0) {
      console.log(taskStatuses);
      let data = taskStatuses.map((taskStatus: any, index) => ({
        value: taskStatus.tasksStatusTypeId,
        label: taskStatus.status,
      }));

      setTaskStatusLists(data);
    }
  }, [taskStatuses]);

  useEffect(() => {
    if (branchUsers.length > 0) {
      console.log(branchUsers);
      let data = branchUsers.map((branchUser: any, index) => ({
        value: branchUser.userId,
        label: `${branchUser.firstName} ${branchUser.lastName} [${branchUser.designation}] `,
      }));
      setBranchUserLists(data);
    }
  }, [branchUsers]);

  useEffect(() => {
    if (isEdit && task) {
      setExistingTask(task);
      reset(task);
    }
  }, [task, isEdit, reset, setValue]);

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        Close();
      }}
      initialFocus={emailInputRef}
      size="2xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
          Add New Task
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-1 gap-3 w-full">
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Assign To <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName=""
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={branchUserLists}
                value={getValues().assignee}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("assignee", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.assignee?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Task <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.task ? "is-invalid" : ""
                }`}
                {...register("task")}
                placeholder="Enter Task"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.task?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Due Date
              </label>
              <input
                type="date"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("dueDate")}
              />
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Planned Date
              </label>
              <input
                type="date"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("plannedDate")}
              />
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Priority
              </label>
              <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={priorityClientLists}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("priority", selectedOption);
                  }
                }}
              />
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Status
              </label>
              <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={taskStatusLists}
                value={getValues().status}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("status", selectedOption);
                  }
                }}
              />
            </div>
            <div className="col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Attachment
              </label>
              <input
                className={`block w-full text-sm text-gray-900 border-2 border-gray-300 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 `}
                {...register("attachmentFile")}
                type="file"
                multiple
                onChange={handleFileChange}
              />
            </div>
            <div className="mb-5 col-span-2">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Description
              </label>
              <textarea
                className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                placeholder="Write your thoughts here..."
                {...register("description")}
              ></textarea>
            </div>
            <div></div>

            <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  onClick={() => {
                    reset();
                    setOpenModal(Close);
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white-bg mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddTask };
