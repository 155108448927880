import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import LawSocietyService from "../../../../services/OfficeManagement/OfficeInformation/LawSocietyService";

export interface LawSociety {

  lawSocietyId: number;
}
// Define the state type for Checklist
type LawSocietyState = {
    lawSocieties: PaginationResponse<LawSociety> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: LawSocietyState = {
    lawSocieties: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllLawSociety = createAsyncThunk(
    "fetchAllLawSociety",
    async (data: any, thunkAPI) => {
        try {
            const response = await LawSocietyService.GetAllLawSociety(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addLawSociety = createAsyncThunk(
    "addLawSociety",
    async (data: any, thunkAPI) => {
        try {
            const response = await LawSocietyService.PostLawSociety(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateLawSociety = createAsyncThunk(
    "updateLawSociety",
    async ({ id, data }: { id: number, data: LawSociety }, thunkAPI) => {
        try {
            const response = await LawSocietyService.PutLawSociety(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteLawSociety = createAsyncThunk(
    "deleteLawSociety",
    async (id: number, thunkAPI) => {
        try {
            await LawSocietyService.deleteLawSociety(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const LawSocietySlice = createSlice({
    name: "lawSociety",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllLawSociety.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllLawSociety.fulfilled, (state, action: PayloadAction<PaginationResponse<LawSociety>>) => {
                state.status = "idle";
                state.lawSocieties = action.payload;
            })
            .addCase(fetchAllLawSociety.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addLawSociety.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addLawSociety.fulfilled, (state, action: PayloadAction<LawSociety>) => {
                state.status = "idle";
               // state.lawSocietys.push(action.payload);
            })
            .addCase(addLawSociety.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateLawSociety.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateLawSociety.fulfilled, (state, action: PayloadAction<LawSociety>) => {
                state.status = "idle";
                // const index = state.lawSocietys.findIndex(t => t.lawSocietyId === action.payload.lawSocietyId);
                // if (index !== -1) {
                //     state.lawSocietys[index] = action.payload;
                // }
            })
            .addCase(updateLawSociety.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteLawSociety.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteLawSociety.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.lawSocietys = state.lawSocietys.filter(t => t.lawSocietyId !== action.payload);
            })
            .addCase(deleteLawSociety.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default LawSocietySlice.reducer;
