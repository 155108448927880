import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { SelectBox } from '../../../../SelectBox';
import { SearchCaseReference } from '../../../SearchCaseReference';
import { useAppDispatch } from '../../../../../store/hooks/redux-hooks';
import { addProtectedFund } from '../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/ProtectedFundSlice';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { getValue } from '@testing-library/user-event/dist/utils';
import { fetchCaseContact } from '../../../../../store/slice/CaseManagement/Case/CaseManagementSlice';
import { formatDateISODisplay } from '../../../../../utils/date-time.utils';
import { NumericFormatWrapper } from '../../../../InputComponents/NumericFormatWrapper';
import { fetchNextReverseNumber } from '../../../../../store/slice/Accounting/ClientAccounts/ClientAccountSlice';
import { ClientAccountConstant } from '../../../../../store/model/Accounting/ClientAccount/ClientAccountConst';
import { addReverseClientToOffice } from '../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/ClientToOfficeSlice';
import { SearchCaseClientContact } from '../../../SearchCaseClientContact';

function AddProtectedFund({ openDrawer, Close, isView,isReverse, protectedFund }: { openDrawer: boolean, Close: any, isView: boolean, isReverse: boolean, protectedFund: any }) {
    const [title] = useState((!isView ? 'Add Protected Fund' : 'Edit Protected Fund'))
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false)
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [existingProtectedFund, setExistingProtectedFund] = useState(protectedFund);
    const [selectedCaseReference, setSelectedCaseReference] = useState<string | null>(null);
    // const { caseContactData } = useSelector((state: RootState) => state.case.caseContactData);
    const [isOther, setIsOther] = useState(false);
    const { clientAccounts } = useSelector((state: RootState) => state.clientAccount);
    const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
    const [selectedPayFor, setSelectedPayFor] = useState('');
    const [isReverseDisabled, setIsReverseDisabled] = useState(true);
  const reverseReceiptNumber = useSelector((state: RootState) => state.clientAccount.reverseReceiptNumber);
  const [openCaseClientContactModal, setOpenCaseClientContactModal] = useState(false);
  const [selectedCaseClientContact, setSelectedCaseClientContact] = useState<any>();
  const [selectedCaseId, setSelectedCaseId] = useState<number>();

    useEffect(() => {
        if (clientAccounts.length > 0) {
            let data = clientAccounts.map((clientAccount: any, index) => ({
                value: clientAccount.bankAccountId,
                label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`
                ,
            }));
            setClientAccountLists(data);
            let findObj = clientAccounts.find((x) => x.isDefaultAccount === true);
            if(findObj && findObj!==null) {
            setValue("fkClientBankId", findObj?.bankAccountId)
        
      } 
        }
    }, [clientAccounts]);

    const handleEditClick = () => {
        setIsDisabled(!isDisabled);
    };

    const [isDisabled, setIsDisabled] = useState(true);


    const validationSchema = object().shape({
        fkCaseId: number()
            .required('Case Reference is required'),
        fkContactId: number(),
        protectedAmount: string()
            .required('Amount is required'),
        protectedDateTime: string()
            .required('Date is required')
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split("T")[0];
              }),
        
        availableBalance: string(),
        paymentForFrom: string(),
        reason: string(),
        comments: string(),
        fkClientBankId: number()
            .required('Client Bank is required'),
        caseReferenceAuto: string(),
        protectedFundType: string(),

        
        
    });
   

    const { register, handleSubmit, reset, setValue, getValues, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            protectedFundType: "Given",
            protectedDateTime:  new Date().toISOString().split("T")[0],
        }
    });
    useEffect(() => {
        setValue("protectedDateTime", new Date().toISOString().split("T")[0]);
        //setValue("relaseDate", new Date().toISOString().split("T")[0]);
      }, []);
    // const today = new Date().toISOString().split("T")[0];
    // useEffect(() => {
    //   setValue("protectedDateTime", today);
    // }, [today]);

    const onSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        setIsLoading(true);
        try {
            await dispatch(addProtectedFund(data)).unwrap();
            Close("success");
            toast.success("Successfully Added Protected Fund details");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    useEffect(() => {

        if ( (protectedFund != null  && isView === true) || ( protectedFund != null  && isView === false && isReverse ===true)) {

        setValue('fkContactId', protectedFund?.fkContactId)
        setValue('protectedDateTime', formatDateISODisplay(protectedFund?.protectedDateTime))
        setValue('fkCaseId', protectedFund?.caseId)
        setValue('caseReferenceAuto', protectedFund?.caseReferenceAuto)
        setValue('fkClientBankId', protectedFund?.clientBankId)
        setValue('availableBalance', protectedFund?.availableBalance)
        setValue('protectedAmount', protectedFund?.protectedAmount)
        setValue('paymentForFrom', protectedFund?.paymentForFrom)
        setValue('reason', protectedFund?.reason)
        setValue('comments', protectedFund?.comments)
        setValue('protectedFundType', protectedFund?.protectedFundType)

    //      setSelectedCaseReference({
    //         caseReferenceAuto: protectedFund.caseReferenceAuto,
    //   });
         dispatch(fetchCaseContact(protectedFund?.caseId));
        }
    }, [protectedFund, isView, reset, setValue]);


    const toggleCaseAccountModal = () => {
        setOpenCaseAccountModal(!openCaseAccountModal);
    }

    const toggleCaseClientContactModal = () => {
        setOpenCaseClientContactModal(!openCaseClientContactModal);
      };

    const handleCaseSelection = (selectedCase: any) => {
        setSelectedCaseId(selectedCase.caseId)
        dispatch(fetchCaseContact(selectedCase.caseId));
        setSelectedCaseReference(selectedCase?.caseReferenceAuto);
        setValue('fkCaseId', selectedCase.caseId);
        setValue('caseReferenceAuto', selectedCase.caseReferenceAuto);
        setOpenCaseAccountModal(false);
    };
    const handleReverseEditClick = () => {
        setIsReverseDisabled(!isReverseDisabled);
      };
      const [formData, setFormData] = useState<any>({
        relaseDate: string()
        .transform((value, originalValue) => {
            if (originalValue === "" || value === null) return null;
            return new Date(value).toISOString().split("T")[0];
          }),
        
      });

      const handleCaseClientContactSelection = (caseContactClient: any) => {
        setSelectedCaseClientContact(caseContactClient);
        console.log(caseContactClient)
        if (caseContactClient != null && caseContactClient.cType === 'client') {
          if (caseContactClient.clientType === "Individual") {
            let fullName =  caseContactClient.title + caseContactClient.givenNames + " " + caseContactClient.lastName;
            setValue("paymentForFrom", fullName);
            setValue("fkContactId", caseContactClient.individualClientId);
           // setValue("payeeType", payeeType.contact);
            
          } else if (caseContactClient.clientType === "Organisation") {
            //setValue("payTo", caseContactClient.orgName);
            setValue("paymentForFrom", caseContactClient.orgName);
            
          } else {
           // setValue("payTo", caseContactClient.companyName);
           setValue("paymentForFrom", caseContactClient.companyName);
          }
        }else{

        }
    }
    
      // Handle change in input fields
      const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleReverseClick = async () => {
        if (isReverse) {
          
            console.log(formData);
            try {
              setIsLoading(true);
             await dispatch(addReverseClientToOffice(formData)).unwrap();
              
              toast.success("Successfully saved reverse of Protected Fund details");
             
            } catch (error: any) {
              toast.error(error.toString());
            } finally {
              setIsLoading(false);
            }
          }
        }
      

    return (
        <>
        {openDrawer === true ? (
            <>
            <form onSubmit={handleSubmit(onSubmit,onError)} className="mx-auto" >
                <div className="flex flex-row md:flex-col justify-between my-3 gap-2 w-full md:gap-3">
                    <div>
                        <div className="relative flex gap-1">
                        <input
                            type="search"
                            placeholder="Case Reference"
                            aria-label="Search"
                            value={selectedCaseReference || ""}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.fkCaseId ? 'is-invalid' : ''}`}
                            disabled={isDisabled}
                        />

                        <button
                            onClick={toggleCaseAccountModal}
                            className="z-[2] inline-block rounded-e border-2 border-primary w-36 px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                            data-twe-ripple-init
                            data-twe-ripple-color="white"
                            type="button"
                            disabled ={isView}
                            >
                            Add Case
                        </button> 
                        <span className="text-red-500 mx-1">*</span>
                        {(openCaseAccountModal) &&
                            <SearchCaseReference
                                openDrawer={openCaseAccountModal}
                                Close={toggleCaseAccountModal}
                                onCaseSelect={handleCaseSelection} />
                        }
                        </div>
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">{errors?.fkCaseId?.message?.toString()}</div>
                    </div>
                    {(isReverse === false || isView === false) && (
                    <div>
                   
                        <button
                            type="button"
                            onClick={() => {
                                Close();
                            }}
                        
                            className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                  
                    </div>
                      )}

                </div >
                <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
                    <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Available Balance 
                        </label>
                        <NumericFormatWrapper decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('availableBalance')}
                            placeholder="Enter Available Balance"
                            value={getValues().availableBalance}
                            onValueChange={(values:any) => {
                              setValue("availableBalance", (values.value));
                            }}
                            defaultValue={0.00}
                            disabled={isDisabled}
                        />
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Date <span className="text-red-500">*</span>
                        </label>
                        <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.protectedDateTime ? 'is-invalid' : ''}`} 
                        {...register('protectedDateTime')}
                            placeholder="Enter Date"
                            disabled ={isView || isReverse}  
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">{errors?.protectedDateTime?.message?.toString()}</div>
                    </div>

                    {/* <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Entry Date <span className="text-red-500">*</span>
                        </label>
                        <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.entryDate ? 'is-invalid' : ''}`} {...register('entryDate')}
                            placeholder="Enter Date"
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">{errors?.entryDate?.message?.toString()}</div>

                        <div className='flex items-center gap-x-4'>
                            <div className="flex items-center">
                                <input
                                type="radio"
                                value="Given"
                                checked={watch('protectedFundType') === "Given"}
                                onChange={() => setValue('protectedFundType', "Given")}
                                name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label className="ms-2 text-lg 3xl:text-xs text-gray-900 dark:text-gray-300">Given</label>
                            </div>
                            <div className="flex items-center">
                                <input 
                                type="radio"
                                value="Taken"
                                checked={watch('protectedFundType') === "Taken"}
                                onChange={() => setValue('protectedFundType', "Taken")}
                                name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label className="ms-2 text-lg 3xl:text-xs text-gray-900 dark:text-gray-300">Taken</label>
                            </div>
                        </div>

                    </div> */}
                    <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Payment For / From <span className="text-red-500">*</span>
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.paymentForFrom ? 'is-invalid' : ''}`} 
                        {...register('paymentForFrom')}
                            placeholder="Enter Transaction Description"
                            disabled={!isOther}
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">{errors?.paymentForFrom?.message?.toString()}</div>
                        <div className='flex gap-x-2'>
                            <button
                                type="button"
                                onClick={toggleCaseClientContactModal}
                                className="text-lg 3xl:text-xs py-1 px-3 font-medium text-white-bg bg-blue-500 rounded hover:bg-blue-600"
                                 //disabled ={isView || isReverse}
                                 >
                                Case Contacts
                            </button>
                            {openCaseClientContactModal && selectedCaseId !== undefined && (
                                <SearchCaseClientContact
                                    openDrawer={openCaseClientContactModal}
                                    Close={toggleCaseClientContactModal}
                                    onCaseSelectDetail={handleCaseClientContactSelection}
                                    caseId={selectedCaseId}
                                />
                )}
                            <div className="flex items-center my-2">
                                <input id="default-checkbox" type="checkbox" value="" onChange={
                                    (e)=>{
                                        setIsOther(!isOther);
                                        if (!isOther) setValue('paymentForFrom', '');
                                    }
                                } 
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                disabled ={isView || isReverse}
                                />
                                
                                <label className="ms-2 text-lg 3xl:text-xs font-medium text-gray-900 dark:text-gray-300">Other</label>
                            </div>

                        </div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Client Bank <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            options={clientAccountLists}
                            isSearchable={true}
                            value={getValues().fkClientBankId}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setValue('fkClientBankId', selectedOption);
                                }
                            }}
                            isDisabled ={isView || isReverse}
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600" >{errors?.fkClientBankId?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Amount (£) <span className="text-red-500">*</span>
                        </label>
                        <NumericFormatWrapper decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.protectedAmount ? 'is-invalid' : ''}`}
                        value={getValues().protectedAmount}
                        onValueChange={(values: any) => {
                        setValue('protectedAmount', (values.value))
                        }}
                            placeholder="Enter Amount (£)"
                            disabled ={isView || isReverse}
                        />
                        <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">{errors?.protectedAmount?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Reason
                        </label>
                        <textarea
                            className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            placeholder="Reason" {...register('reason')}
                            disabled ={isView || isReverse}
                        ></textarea>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Comments
                        </label>
                        <textarea
                            className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            placeholder="Comments" {...register('comments')}
                            disabled ={isView || isReverse}
                        ></textarea>
                    </div>




                </div>
                {(!isView || !isReverse) && (
                <div className="flex col-span-2 gap-2 justify-end my-5">
                    <button
                        type="button"
                        onClick={() => {
                            Close();
                        }}
                        disabled ={isView || isReverse}
                        className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-lg 3xl:text-sm w-[120px]"
                        disabled ={isView || isReverse}
                    >  {isLoading ? (
                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                        </svg>
                    ) : (
                        'Save'
                    )}
                    </button>
                </div>
                )}
            </form>

            {isReverse && (
            <div className="grid grid-cols-3 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
              <div className="relative">
                        <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                            Release Date <span className="text-red-500">*</span>
                        </label>
                        <input 
                        type="date" 
                        name="relaseDate"
                        value={formData.relaseDate}
                        onChange={handleChange}
                        className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.protectedDateTime ? 'is-invalid' : ''}`} 
                        
                            placeholder="Enter Date"
                              
                        />

                    </div>
            </div>
          )}

        <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
            <div className="flex flex-row gap-2 items-end justify-end w-full">
            {isReverse && (
              <button
                type="submit"
                disabled={isLoading}
                onClick={handleReverseClick}
                className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-md text-center border text-lg 3xl:text-sm w-[120px]  ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white-bg mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  "Release Amount "
                )}
              </button>
            )}
            </div>
          </div>
        </>
    ):null}

    </>
    );
}

export { AddProtectedFund };
