// import axios from 'axios';


import axiosInstance from "../axiosInstance";



const GetAllContactCategory = async () => {
  try {
    const response = await axiosInstance.get("ContactCategory");
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch ContactCategory: ${error}`);
  }
}
const ContactCategoryService = {
  GetAllContactCategory,
};

export default ContactCategoryService;
