import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import BankReceiptService from "../../../../../services/Accounting/ClientAccounts/ClientPostings/BankReceiptService";
import { BankReceiptModel, SearchBankRecipt, SearchBankReciptResult } from "../../../../model/Accounting/ClientAccount/ClientBankReceipt";



// Define the state type for Checklist
type BankReceiptState = {
    searchBankRecipt: SearchBankRecipt;
    searchBankReciptResult: SearchBankReciptResult;
    bankReceipts: BankReceiptModel[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    receiptNumber : number | null;
    any: any;
};

// Initial state
const initialState: BankReceiptState = {
    searchBankReciptResult: null!,
    searchBankRecipt: null!,
    bankReceipts: [],
    status: "idle",
    error: null,
    receiptNumber: null,
    any: null,
};

// Async thunks for CRUD operations
export const fetchNextBankReceiptNumber = createAsyncThunk(
    "fetchNextBankReceiptNumber",
    async (_, thunkAPI) => {
        try {
            const response = await BankReceiptService.GetNextBankReceiptNumber();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export const fetchAllBankReceipt = createAsyncThunk(
    "fetchAllBankReceipt",
    async (_, thunkAPI) => {
        try {
            const response = await BankReceiptService.GetAllBankReceipt();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addBankReceipt = createAsyncThunk(
    "addBankReceipt",
    async (data: any, thunkAPI) => {
        try {
            const response = await BankReceiptService.PostBankReceipt(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addReverseBankReceipt = createAsyncThunk(
    "addReverseBankNewPayment",
    async (data: any, thunkAPI) => {
        try {
            const response = await BankReceiptService.PostReserveBankReceipt(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateBankReceipt = createAsyncThunk(
    "updateBankReceipt",
    async ({ id, data }: { id: number, data: BankReceiptModel }, thunkAPI) => {
        try {
            const response = await BankReceiptService.PutBankReceipt(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteBankReceipt = createAsyncThunk(
    "deleteBankReceipt",
    async (id: number, thunkAPI) => {
        try {
            await BankReceiptService.deleteBankReceipt(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const BankReceiptSlice = createSlice({
    name: "bankReceipt",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNextBankReceiptNumber.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchNextBankReceiptNumber.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.receiptNumber = action.payload;
            })
            .addCase(fetchNextBankReceiptNumber.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(fetchAllBankReceipt.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllBankReceipt.fulfilled, (state, action: PayloadAction<SearchBankReciptResult>) => {
                state.status = "idle";
                state.searchBankReciptResult = action.payload;
            })
            .addCase(fetchAllBankReceipt.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addBankReceipt.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addBankReceipt.fulfilled, (state, action: PayloadAction<BankReceiptModel>) => {
                state.status = "idle";
                state.bankReceipts.push(action.payload);
            })
            .addCase(addBankReceipt.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            // reverse
            .addCase(addReverseBankReceipt.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addReverseBankReceipt.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.any.push(action.payload);
            })
            .addCase(addReverseBankReceipt.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateBankReceipt.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateBankReceipt.fulfilled, (state, action: PayloadAction<BankReceiptModel>) => {
                state.status = "idle";
                const index = state.bankReceipts.findIndex(t => t.clientReceiptId === action.payload.clientReceiptId);
                if (index !== -1) {
                    state.bankReceipts[index] = action.payload;
                }
            })
            .addCase(updateBankReceipt.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteBankReceipt.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteBankReceipt.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.bankReceipts = state.bankReceipts.filter(t => t.clientReceiptId !== action.payload);
            })
            .addCase(deleteBankReceipt.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default BankReceiptSlice.reducer;
