import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";




const GetAllPartnerShipAccount = async () => {
    try {
        const response = await axiosInstance.get("AccountPartnership");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostPartnerShipAccount = async (data: any) => {
    try {
        console.log(data);
        const response = await axiosInstance.post("AccountPartnership", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutPartnerShipAccount = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`AccountPartnership/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deletePartnerShipAccount = async (id: number) => {
    try {
        await axiosInstance.delete(`AccountPartnership/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PartnerShipAccountService = {
    GetAllPartnerShipAccount,
    PostPartnerShipAccount,
    PutPartnerShipAccount,
    deletePartnerShipAccount
};


export default PartnerShipAccountService;
