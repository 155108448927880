import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddCreditNote } from "../Modal/AddClientAccounts/AddClientInvoice/AddCreditNote";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { FaEdit, FaEye } from "react-icons/fa";
import { deleteCACreditNote, fetchCreditNoteSearch } from "../../store/slice/Accounting/ClientAccounts/Invoice/CACreditNoteSlice";
import { Pagination } from "../Pagination";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { RiDeleteBin5Fill } from "react-icons/ri";
import DeleteModal from "../Modal/DeleteModal";
import { toast } from "react-toastify";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";

function CreditNote() {
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const cacreditNoteData = useSelector((state: RootState) => state.clientCreditNote.cacreditNoteData);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [getDataList, setGetDataList] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [currentFetchDataList, setCurrentFetchDataList] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [creditNoteDatas, setCreditCardDatas] = useState<any[]>([]);
    const [openDelModal, setOpenDelModal] = useState(false);
    const [cACreditNoteId, setCACreditNoteId] = useState<number>(0);
    const [plannedStartDate, setPlannedStartDate] = useState<string | null>(null);
    const [plannedEndDate, setPlannedEndDate] = useState<string | null>(null);

  const toggleDrawer = () => {
    setOpenModal(!openModal);
  };

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchCreditNoteSearch())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (cacreditNoteData && cacreditNoteData.creditNoteResult) {
      setGetDataList(cacreditNoteData.creditNoteResult);
    }
  }, [cacreditNoteData]);

  // const filterGetDataSearchQuery = () => {
  //     return getDataList.filter(
  //         (ledger: any) =>
  //             ledger?.billingDesc.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //             ledger?.disbursementType.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //             ledger?.lastName.toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  // };
  useEffect(() => {
    if (getDataList != null && getDataList.length > 0) {
      const filteredByDisbursement = getDataList;
      const indexOfLastData = currentPage * itemsPerPage;
      const indexOfFirstData = indexOfLastData - itemsPerPage;
      const currentGetData = filteredByDisbursement.slice(
        indexOfFirstData,
        indexOfLastData
      );
      const totalPages = Math.ceil(getDataList.length / itemsPerPage);
      setTotalPages(totalPages);
      setCurrentFetchDataList(currentGetData);
    }
  }, [getDataList]);

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };

  
  const onDeleteCall = () => {
    try {
      dispatch(deleteCACreditNote(cACreditNoteId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted client Account credit Note");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  if (openModal) {
    return (
      <AddCreditNote
        openDrawer={openModal}
        Close={toggleDrawer}
        modalType="Add" isView={isView} isEdit={isEdit} cACreditNote={creditNoteDatas}      />
    );
  }
    return (
        <>
            <div className=""> 
            {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={cACreditNoteId}
            title="Bank Receipt"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
                <div className="mb-5">
                {initialLoading ? <MainAnimation /> : ""}
                    <SubHeaderTitle title="Credit Note" buttonName="Credit Note" isAdd={true} onClickAdd={toggleDrawer} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Status" />
                            <JustifyDateFilter
                              title="Date Between"
                              plannedStartDate={plannedStartDate}
                              plannedEndDate={plannedEndDate}
                              onPlannedStartDateChange={handleStartDateChange}
                              onPlannedEndDateChange={handleEndDateChange}
              />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Case Reference
                                </th>
                                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Credit Amount
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Credit Note
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Invoice
                                </th>
                                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Status
                                </th>
                                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-lg 3xl:text-xs">
                            {currentFetchDataList.map((disb: any, index) => (
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {formatDateOnlyDisplay(disb.creditNoteDate)}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        {disb.caseReferenceAuto}
                                    </td>
                                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                     {formatCurrency(disb.creditAmount)}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        CN_{disb.creditNoteId}
                                    </td>
                                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <a href="">{disb.creditNoteName}</a>
                                    </td>
                                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                         {disb.total}
                                    </td>
                                    <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <FaEye className="fill-gray-500 w-6 h-6" 
                                         onClick={() => {
                                            setOpenModal(true);
                                            setIsView(true)
                                            setIsEdit(false);
                                            setCreditCardDatas(disb);
                                          }}
                                        />
                                         <FaEdit className="fill-blue-500 w-6 h-6" 
                                        onClick={() => {
                                            setOpenModal(true);
                                            setIsEdit(true);
                                            setCreditCardDatas(disb);
                                        }}
                                        />
                                        <RiDeleteBin5Fill className="fill-red-600 w-6 h-6"
                                        onClick={() => {
                                            setOpenDelModal(true);
                                            setCACreditNoteId(disb.creditNoteId);
                                        }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {currentFetchDataList.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No CreditNote found.</p>
                        </div>
                    )}
                </div>
                {currentFetchDataList.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages} 
                            totalRecords={currentFetchDataList.length}
                            
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
          {/* </div>
        </div> */}
        {/* <div className="relative overflow-x-auto sm:rounded-lg">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Case Reference
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Credit Amount
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Credit Note
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Invoice
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentFetchDataList.map((disb: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {new Date(disb.creditNoteDate).toISOString().split("T")[0]}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{disb.caseReferenceAuto}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">£ {disb.creditAmount}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">CN_{disb.creditNoteId}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <a href="">{disb.creditNoteName}</a>
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{disb.total}</td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <FaEye className="fill-gray-500 w-6 h-6" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {currentFetchDataList.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">Credit Note Details not found.</p>
            </div>
          )}
        </div>
        {currentFetchDataList.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={currentFetchDataList.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div> */}
    </>
  );
}

export { CreditNote };
