import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetInvoiceNextNumber = async () => {
  try {
    const response = await axiosInstance.get("InvoicesNextNumber");
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const GetAllClientQuickInvoices = async (data: any = {}) => {
  try {
    const response = await axiosInstance.post("ClientQuickInvoicesSearch", data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const GetQuickInvoiceByCaseId = async (id: any) => {
  try {
    const response = await axiosInstance.get(`clientaccount/invoicebyCase/${id}`);
    return response.data;
  } catch (error) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const GetQuickInvoiceDataByCaseId = async (id: any) => {
  try {
    const response = await axiosInstance.get(`clientaccount/QuickInvoices/${id}`);
    return response.data;
  } catch (error) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const GetDisbursementAccountCombo = async () => {
  try {
    const response = await axiosInstance.get(`clientaccount/QuickInvoices/DisbursementAccountCombo`);
    return response.data;
  } catch (error) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const GetVatAccountCombo = async () => {
  try {
    const response = await axiosInstance.get(`clientaccount/QuickInvoices/vataccountCombo`);
    return response.data;
  } catch (error) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const GetQuickInvoiceById = async (id: any) => {
  try {
    const response = await axiosInstance.get(`clientaccount/invoice/${id}`);
    return response.data;
  } catch (error) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const PostClientQuickInvoices = async (data: any) => {
  try {
    const response = await axiosInstance.post("ClientQuickInvoices", data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const deleteClientQuickInvoices = async (id: number) => {
  try {
      await axiosInstance.delete(`/ClientQuickInvoices/${id}`);
  } catch (error: any) {
      let data = error as ErrorResult;
      throw new Error(data.messages.toString());
  }
}

const ClientQuickInvoicesService = {
  GetInvoiceNextNumber,
  GetAllClientQuickInvoices,
  GetQuickInvoiceDataByCaseId,
  PostClientQuickInvoices,
  GetQuickInvoiceByCaseId,
  GetQuickInvoiceById,
  GetDisbursementAccountCombo,
  GetVatAccountCombo,
  deleteClientQuickInvoices,
};


export default ClientQuickInvoicesService;