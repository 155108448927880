import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import BalanceSheetService from "../../../../../services/Accounting/OfficeAccount/OfficeReports/BalanceSheetService";



export interface BalanceSheet {
    //creditNoteId: number;
   
};
// Define the state type for Checklist
type BalanceSheetState = {
    balanceSheets: BalanceSheet[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: BalanceSheetState = {
    balanceSheets: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllBalanceSheet = createAsyncThunk(
    "fetchAllBalanceSheet",
    async (data: any, thunkAPI) => {
        try {
            const response = await BalanceSheetService.GetAllBalanceSheet(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const BalanceSheetSlice = createSlice({
    name: "balanceSheet",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBalanceSheet.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllBalanceSheet.fulfilled, (state, action: PayloadAction<BalanceSheet[]>) => {
                state.status = "idle";
                state.balanceSheets = action.payload;
            })
            .addCase(fetchAllBalanceSheet.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
           
    }
});

export default BalanceSheetSlice.reducer;
