import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CARefundCreditNoteService from "../../../../../services/Accounting/ClientAccounts/Invoice/CARefundCreditNoteService";


export interface CACreditNoteRefund {
    creditNoteRefundId: number;

}
export interface CARefundCreditNote {
    creditNoteRefundResult: [];
    totalCount: number;
    currentPage: number;
    recordPerPage: number;
}
// Define the state type for Checklist
type NewCreditNote = {
    caRefundCreditNote: CARefundCreditNote;
    status: "idle" | "loading" | "failed";
    error: string | null;
    receiptNumber: number | null;
    cACreditNoteRefund: CACreditNoteRefund[];
};

// Initial state
const initialState: NewCreditNote = {
    caRefundCreditNote: null!,
    status: "idle",
    error: null,
    receiptNumber: null,
    cACreditNoteRefund: []
};

// Async thunks for CRUD operations
export const fetchNextCreditNoteRefundNumber = createAsyncThunk(
    "fetchNextCreditNoteRefundNumber",
    async (_, thunkAPI) => {
        try {
            const response = await CARefundCreditNoteService.GetNextCreditNoteRefundNumber();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCARefundCreditNoteSearch = createAsyncThunk(
    "fetchCreditNoteSearch",
    async (_, thunkAPI) => {
        try {
            const response = await CARefundCreditNoteService.GetCARefundCreditNoteServiceSearch();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCACreditNoteRefund = createAsyncThunk(
    "addCACreditNoteRefund",
    async (data: any, thunkAPI) => {
        try {
            const response = await CARefundCreditNoteService.PostCreditNoteRefund(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCACreditNoteRefund = createAsyncThunk(
    "deleteCACreditNoteRefund",
    async (id: number, thunkAPI) => {
        try {
            const response = await CARefundCreditNoteService.deleteCreditNoteRefund(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CARefundCreditNoteSlice = createSlice({
    name: "carefundCreditNote",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCARefundCreditNoteSearch.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCARefundCreditNoteSearch.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.caRefundCreditNote = action.payload;
            })
            .addCase(fetchCARefundCreditNoteSearch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(fetchNextCreditNoteRefundNumber.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchNextCreditNoteRefundNumber.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.receiptNumber = action.payload;
            })
            .addCase(fetchNextCreditNoteRefundNumber.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCACreditNoteRefund.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCACreditNoteRefund.fulfilled, (state, action: PayloadAction<CACreditNoteRefund[]>) => {
                state.status = "idle";
                //state.cACreditNoteRefund.push(action.payload);
            })
            .addCase(addCACreditNoteRefund.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCACreditNoteRefund.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCACreditNoteRefund.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.cACreditNoteRefund = state.cACreditNoteRefund.filter(t => t.creditNoteRefundId !== action.payload);
            })
            .addCase(deleteCACreditNoteRefund.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default CARefundCreditNoteSlice.reducer;
