import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, number } from 'yup';
import { useAppDispatch } from '../../../../store/hooks/redux-hooks';
import { addQuotationNotice } from '../../../../store/slice/Accounting/AccountSetup/QuotationNoticeSlice';
import { toast } from 'react-toastify';

function AddQuotationNotices({ openDrawer, Close, isEdit, quotationNotice }: { openDrawer: boolean, Close: any, isEdit: boolean, quotationNotice: any }) {
    const [title] = useState((!isEdit ? 'Add New Quotation Notice' : 'Edit Quotation Notice'))
    const dispatch = useAppDispatch();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const [isLoading, setIsLoading] = useState(false);
    const [existingQuotationNotice, setExistingQuotationNotice] = useState(quotationNotice);

    const validationSchema = object().shape({
        title: string()
            .required('Title is required'),
        quotationNotice: string(),
        quotationNoticeId: number(),
    });

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            quotationNoticeId: 0,
       }
    });

    const onSubmit = async (data: any) => {
        // console.log(JSON.stringify(data, null, 2));
        setIsLoading(true);
        try {
            if (isEdit && quotationNotice) {
                // Update existing fee type
                data.feeTypeId = quotationNotice.feeTypeId;
                await dispatch(addQuotationNotice(data )).unwrap();
                toast.success("Successfully Updated Quotation Notices");
                console.log(quotationNotice.id)
            } else {
            await dispatch(addQuotationNotice(data)).unwrap();
            
            toast.success("Successfully Added Quotation Notices");
                } 
                Close();
            }
        catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
      };

    useEffect(() => {
        if(quotationNotice != null && isEdit === true){
            setValue('title', quotationNotice?.title)
            setValue('quotationNotice', quotationNotice?.quotationNotice)
            setValue('quotationNoticeId', quotationNotice?.quotationNoticeId)
        }
    }, [quotationNotice, isEdit, reset, setValue]);

    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); Close(); }}
            initialFocus={emailInputRef}
            size="xl"
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="dark:text-white-bg text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">
                <form onSubmit={handleSubmit(onSubmit,onError)} className="mx-auto" >
                    <div className="space-y-3 w-full">

                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Title <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 
                            ${errors.title ? 'is-invalid' : ''}`}
                                {...register('title')}
                                placeholder="Enter Partner Name"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.title?.message?.toString()}</div>
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Invoice Notice
                            </label>
                            <textarea
                                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholder="Enter Invoice Notice" {...register('quotationNotice')}
                            ></textarea>
                        </div>


                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                    type='button'
                                    onClick={() => {
                                        reset();
                                        Close();
                                    }}
                                    className="cursor-pointer h-10 rounded-md text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    disabled={isLoading}
                                    className={`bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-md text-center border text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    {isLoading ? (
                                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                        </svg>
                                    ) : (
                                        'Save'
                                    )}

                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export { AddQuotationNotices };
