import { ErrorResult } from "../../../../../store/model/errorResult";
import axiosInstance from "../../../../axiosInstance";


const GetCaseDocumentTabsDisplay = async (id: number) => {
    try {
        const response = await axiosInstance.get(`case/${id}/DocumentTabsDisplay`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetImportantDateType = async (id?: number) => {
    try {
        const response = await axiosInstance.get(`CaseCorrespondence/importantdatetype/${id}`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetCaseCorrespondenceItems = async (id: number) => {
    try {
        const response = await axiosInstance.get(`CaseCorrespondence/checklist/${id}`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostCorrUploadDocument = async (data: any) => {
    try {
        const response = await axiosInstance.post("CaseCorrespondence/document", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostCorrNote = async (data: any) => {
    try {
        const response = await axiosInstance.post("CaseCorrespondence/note", data);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostCorrCallOut = async (data: any) => {
    try {
        const response = await axiosInstance.post("CaseCorrespondence/callout", data);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostFormsAndPrecedents = async (data: any) => {
    try {
        const response = await axiosInstance.post("CaseCorrespondence/forms", data);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostImportDate = async (data: any) => {
    try {
        const response = await axiosInstance.post("CaseCorrespondence/importdate", data);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}



const CorrAddItemServices = {
    PostCorrUploadDocument,
    GetCaseDocumentTabsDisplay,
    GetCaseCorrespondenceItems,
    PostCorrNote,
    PostCorrCallOut,
    PostFormsAndPrecedents,
    PostImportDate,
    GetImportantDateType
};

export default CorrAddItemServices;
