import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CaseReferenceFormatService from "../../../services/SystemMaintenance/CaseReferenceFormatService";

export interface CaseReferenceFormat {
    caseReferenceFormatId: number;
    caseReferenceFormat: string[];
    caseReferenceDescription: string[];
    isDefault: boolean;
};

// Define the state type for casetype
type CaseReferenceFormatState = {
    caseReferenceFormats: CaseReferenceFormat[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CaseReferenceFormatState = {
    caseReferenceFormats: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllCaseReferenceFormat = createAsyncThunk(
    "fetchAllCaseReferenceFormat",
    async (_, thunkAPI) => {
        try {
            const response = await CaseReferenceFormatService.GetAllCaseReferenceFormat();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCaseReferenceFormat = createAsyncThunk(
    "addCaseReferenceFormat",
    async (data: any, thunkAPI) => {
        try {
            data.caseReferenceFormat = data.caseReferenceFormat.toString();
            data.caseReferenceDescription = data.caseReferenceDescription.toString();
            const response = await CaseReferenceFormatService.PostCaseReferenceFormat(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateCaseReferenceFormat = createAsyncThunk(
    "updateCaseReferenceFormat",
    async ({ id, data }: { id: number, data: CaseReferenceFormat }, thunkAPI) => {
        try {
            const response = await CaseReferenceFormatService.PutCaseReferenceFormat(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCaseReferenceFormat = createAsyncThunk(
    "deleteCaseReferenceFormat",
    async (CaseReferenceFormatId: number, thunkAPI) => {
        try {
            await CaseReferenceFormatService.deleteCaseReferenceFormat(CaseReferenceFormatId);
            return CaseReferenceFormatId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CaseReferenceFormatSlice = createSlice({
    name: "caseReferenceFormat",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCaseReferenceFormat.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCaseReferenceFormat.fulfilled, (state, action: PayloadAction<CaseReferenceFormat[]>) => {
                state.status = "idle";
                state.caseReferenceFormats = action.payload;
            })
            .addCase(fetchAllCaseReferenceFormat.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCaseReferenceFormat.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCaseReferenceFormat.fulfilled, (state, action: PayloadAction<CaseReferenceFormat>) => {
                state.status = "idle";
                state.caseReferenceFormats.push(action.payload);
            })
            .addCase(addCaseReferenceFormat.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateCaseReferenceFormat.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCaseReferenceFormat.fulfilled, (state, action: PayloadAction<CaseReferenceFormat>) => {
                state.status = "idle";
                const index = state.caseReferenceFormats.findIndex(t => t.caseReferenceFormatId === action.payload.caseReferenceFormatId);
                if (index !== -1) {
                    state.caseReferenceFormats[index] = action.payload;
                }
            })
            .addCase(updateCaseReferenceFormat.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCaseReferenceFormat.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCaseReferenceFormat.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.caseReferenceFormats = state.caseReferenceFormats.filter(t => t.caseReferenceFormatId !== action.payload);
            })
            .addCase(deleteCaseReferenceFormat.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default CaseReferenceFormatSlice.reducer;
