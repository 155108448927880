import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";




const GetAllVATExIncomeAccount = async () => {
    try {
        const response = await axiosInstance.get("VatIncomeAccount");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostVATExIncomeAccount = async (data: any) => {
    try {
        const response = await axiosInstance.post("VatIncomeAccount", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutVATExIncomeAccount = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`VatIncomeAccount/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteVATExIncomeAccount = async (id: number) => {
    try {
        await axiosInstance.delete(`VatIncomeAccount/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const VATExIncomeAccountService = {
    GetAllVATExIncomeAccount,
    PostVATExIncomeAccount,
    PutVATExIncomeAccount,
    deleteVATExIncomeAccount
};


export default VATExIncomeAccountService;
