import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllOfficeAccountReferenceNumber = async (data: any) => {
    try {
        const response = await axiosInstance.post("OfficeAccountReferenceNumber", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const OfficeAccountReferenceNumberService = {
    GetAllOfficeAccountReferenceNumber,
    
};


export default OfficeAccountReferenceNumberService;
