import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";



const GetCorrespondenceItemType = async () => {
    try {
        const response = await axiosInstance.get(`CorrespondenceItemType`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}



const CorrespondenceService = {
    GetCorrespondenceItemType
};

export default CorrespondenceService;
