import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetAllBankReconReports = async () => {
    try {
        const response = await axiosInstance.post("BankReconReports", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllProtectedFundReports = async () => {
    try {
        const response = await axiosInstance.post("ProtectedFundReports", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllOfficeToClientReports = async () => {
    try {
        const response = await axiosInstance.post("OfficeToClientReports", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllClientToOfficeReports = async () => {
    try {
        const response = await axiosInstance.post("ClientToOfficeReports", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllCaseBalanceReport = async () => {
    try {
        const response = await axiosInstance.post("CaseBalanceReport", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const ClientReportsService = {
    GetAllBankReconReports,
    GetAllProtectedFundReports,
    GetAllOfficeToClientReports,
    GetAllCaseBalanceReport,
    GetAllClientToOfficeReports

};

export default ClientReportsService;