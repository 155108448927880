// import axios from 'axios';


import axiosInstance from "../axiosInstance";



const fetchAllMaritalStatus = async () => {
  try {
    const response = await axiosInstance.get("MaritalStatus");
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch MaritalStatus: ${error}`);
  }
}
const MaritalStatusService = {
  fetchAllMaritalStatus,
};

export default MaritalStatusService;
