import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ClientBankAccountService from "../../../../services/Accounting/ClientAccounts/ClientBankAccountService";



export interface ClientBankAccount {
    clientBank: string;
    clientBankCloseBalance: number;
    clientBankOpenBalance: number;
    transactions: [];
    orderBy: string;
    currentPage: number;
    totalRows: number;
};
// Define the state type for Checklist
type ClientBankAccountState = {
    clientBankAccounts: ClientBankAccount;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ClientBankAccountState = {
    clientBankAccounts: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllClientBankAccount = createAsyncThunk(
    "fetchAllClientBankAccount",
    async (data: any, thunkAPI) => {
        try {
            const response = await ClientBankAccountService.GetAllClientBankAccount(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// export const addClientBankAccount = createAsyncThunk(
//     "addClientBankAccount",
//     async (data: any, thunkAPI) => {
//         try {
//             const response = await ClientBankAccountService.PostClientBankAccount(data);
//             return response;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// export const updateClientBankAccount = createAsyncThunk(
//     "updateClientBankAccount",
//     async ({ id, data }: { id: number, data: ClientBankAccount }, thunkAPI) => {
//         try {
//             const response = await ClientBankAccountService.PutClientBankAccount(id, data);
//             return response;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// export const deleteClientBankAccount = createAsyncThunk(
//     "deleteClientBankAccount",
//     async (id: number, thunkAPI) => {
//         try {
//             await ClientBankAccountService.deleteClientBankAccount(id);
//             return id;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// Slice definition
const ClientBankAccountSlice = createSlice({
    name: "clientBankAccount",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllClientBankAccount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllClientBankAccount.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.clientBankAccounts = action.payload;
            })
            .addCase(fetchAllClientBankAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            // .addCase(addClientBankAccount.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(addClientBankAccount.fulfilled, (state, action: PayloadAction<ClientBankAccount>) => {
            //     state.status = "idle";
            //     state.clientBankAccounts.push(action.payload);
            // })
            // .addCase(addClientBankAccount.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // })
            // .addCase(updateClientBankAccount.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(updateClientBankAccount.fulfilled, (state, action: PayloadAction<ClientBankAccount>) => {
            //     state.status = "idle";
            //     const index = state.clientBankAccounts.findIndex(t => t.clientBankAccountId === action.payload.clientBankAccountId);
            //     if (index !== -1) {
            //         state.clientBankAccounts[index] = action.payload;
            //     }
            // })
            // .addCase(updateClientBankAccount.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // })
            // .addCase(deleteClientBankAccount.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(deleteClientBankAccount.fulfilled, (state, action: PayloadAction<number>) => {
            //     state.status = "idle";
            //     state.clientBankAccounts = state.clientBankAccounts.filter(t => t.clientBankAccountId !== action.payload);
            // })
            // .addCase(deleteClientBankAccount.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // });
    }
});

export default ClientBankAccountSlice.reducer;
