import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import DocumentmanagementService from "../../../services/SystemMaintenance/DocumentmanagementService";

export interface Document {
    documentId: number;
    documentName: string;
    description: string;
    fkDocumentTypeId: number;
    fkDocumentSourceId: number;
    documentSendTo: string;
    isEditable: boolean;
    fkDocumentFormatId: number;
    fileAttachedName: string;
    fileAttached: string;
    isLaitLibraryDocument: boolean;
    fkLaitLibraryDocumentId: number;
    dateOfUpload: string;
    fkUserId: number;
    fkAreasOfPracticeIds: string;
    fkCaseTypeIds: string;
    fkCaseSubTypeIds: string;
    lastUpdatedInfo: string;
    isActive: boolean;
};
// Define the state type for document
type DocumentState = {
    documents: Document[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: DocumentState = {
    documents: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllDocument = createAsyncThunk(
    "fetchAllDocument",
    async (_, thunkAPI) => {
        try {
            const response = await DocumentmanagementService.GetAllDocument();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addDocument = createAsyncThunk(
    "addDocument",
    async (data: any, thunkAPI) => {
        try {
            const response = await DocumentmanagementService.PostDocument(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateDocument = createAsyncThunk(
    "updateDocument",
    async ({ id, data }: { id: number, data: Document }, thunkAPI) => {
        try {
            const response = await DocumentmanagementService.PutDocument(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteDocument = createAsyncThunk(
    "deleteDocument",
    async (documentId: number, thunkAPI) => {
        try {
            await DocumentmanagementService.deleteDocument(documentId);
            return documentId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const DocumentmanagementSlice = createSlice({
    name: "document",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllDocument.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllDocument.fulfilled, (state, action: PayloadAction<Document[]>) => {
                state.status = "idle";
                state.documents = action.payload;
            })
            .addCase(fetchAllDocument.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addDocument.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addDocument.fulfilled, (state, action: PayloadAction<Document>) => {
                state.status = "idle";
                state.documents.push(action.payload);
            })
            .addCase(addDocument.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateDocument.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateDocument.fulfilled, (state, action: PayloadAction<Document>) => {
                state.status = "idle";
                const index = state.documents.findIndex(t => t.documentId === action.payload.documentId);
                if (index !== -1) {
                    state.documents[index] = action.payload;
                }
            })
            .addCase(updateDocument.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteDocument.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteDocument.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.documents = state.documents.filter(t => t.documentId !== action.payload);
            })
            .addCase(deleteDocument.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default DocumentmanagementSlice.reducer;
