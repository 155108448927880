
import GeneralOfficeService from "../../../../../services/Accounting/OfficeAccount/GeneralSettings/GeneralService";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GeneralOfficeResponse{
general: GeneralOffice[];
businessType: BusinessTypes[];
accountingMethod: AccountingMethods[];
vatperiodtype: Vatperiodtypes[];
accountType: AccountTypes[];
};

export interface GeneralOffice {
    companyNumber : string;
    businessType: string;
    utrNumber: string;
    financialYear : Date;
    accountingMethod: string;
    isVATRegistered : boolean;
    vATNumber : string;
    vATPeriod : string;
    vATAccountingMethod: string;
    
};

export interface BusinessTypes{
 businessTypeId: number;
 businessType: string;
};

export interface AccountingMethods{
    accountingMethodId: number;
    accountingMethod: string;
};

export interface Vatperiodtypes{
    vatPeriodTypeId: number;
    vatPeriod: string;
};

export interface AccountTypes{
    accountTypeId: number;
    accountType: string;
    range: string;
    fkFinancialStatementId: number;
    toIncrease: string;
    accountOrder: number;
}

export interface GeneralOfficeData{
    companyNumber: string;
    accOfficeSettingsId: number;
    fkAccountingMethodId: number;
    fkBusinessTypeId: number;
    fkFinancialYearId: number;
    fkVatAccountingMethodId: number;
    fkVatPeriodId: number;
    isVatRegistered: boolean;
    utrn: string;
    vatNumber: string;

}

// Define the state type for Checklist
type GeneralOfficeState = {
    generalOffices: GeneralOffice[];
    businessTypes: BusinessTypes[];
    accountingMethods: AccountingMethods[];
    vatPeriodTypes: Vatperiodtypes[];
    accountTypes: AccountTypes[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    generalOfficeData: GeneralOfficeData;
};

// Initial state
const initialState: GeneralOfficeState = {
    generalOffices: [],
    businessTypes: [],
    accountingMethods: [],
    vatPeriodTypes:[],
    accountTypes:[],
    status: "idle",
    error: null,
    generalOfficeData: null!,
};

// Thunk for fetching accounting methods
export const fetchAllAccountingMethods = createAsyncThunk(
    "fetchAllAccountingMethods",
    async (_, thunkAPI) => {
        try {
            const response = await GeneralOfficeService.GetAllAccountingMethod();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Thunk for fetching business types
export const fetchAllBusinessTypes = createAsyncThunk(
    "fetchAllBusinessTypes",
    async (_, thunkAPI) => {
        try {
            const response = await GeneralOfficeService.GetAllOfficeBusinessType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllVatPeriodType = createAsyncThunk(
    "fetchAllVatPeriodType",
    async (_, thunkAPI) => {
        try {
            const response = await GeneralOfficeService.GetAllVatPeriodType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllAccountType = createAsyncThunk(
    "fetchAllAccountType",
    async (_, thunkAPI) => {
        try {
            const response = await GeneralOfficeService.GetAllAccountType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
// Async thunks for CRUD operations
export const fetchAllGeneralOffice = createAsyncThunk(
    "fetchAllGeneralOffice",
    async (_, thunkAPI) => {
        try {
            const response = await GeneralOfficeService.GetAllGeneralOffice();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Async thunks for CRUD operations


export const addGeneralOffice = createAsyncThunk(
    "addGeneralOffice",
    async (data: any, thunkAPI) => {
        try {
            const response = await GeneralOfficeService.PostGeneralOffice(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const GeneralOfficeSlice = createSlice({
    name: "generalOffice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllGeneralOffice.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllGeneralOffice.fulfilled, (state, action: PayloadAction<any>) => {
            state.status = "idle";
            state.generalOfficeData = action.payload;
        })
        .addCase(fetchAllGeneralOffice.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })

        .addCase(addGeneralOffice.pending, (state) => {
            state.status = "loading";
        })
        .addCase(addGeneralOffice.fulfilled, (state, action: PayloadAction<GeneralOffice>) => {
            state.status = "idle";
            state.generalOffices.push(action.payload);
        })
        .addCase(addGeneralOffice.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })


  // Business Types      
        
        .addCase(fetchAllBusinessTypes.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllBusinessTypes.fulfilled, (state, action: PayloadAction<any[]>) => {
            state.status = "idle";
            state.businessTypes = action.payload;
        })
        .addCase(fetchAllBusinessTypes.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })
// Accounting method

        .addCase(fetchAllAccountingMethods.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllAccountingMethods.fulfilled, (state, action: PayloadAction<any[]>) => {
            state.status = "idle";
            state.accountingMethods = action.payload;
        })
        .addCase(fetchAllAccountingMethods.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })
//Vat period Type

        .addCase(fetchAllVatPeriodType.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllVatPeriodType.fulfilled, (state, action: PayloadAction<any[]>) => {
            state.status = "idle";
            state.vatPeriodTypes = action.payload;
        })
        .addCase(fetchAllVatPeriodType.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })

// fetch account type

        .addCase(fetchAllAccountType.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllAccountType.fulfilled, (state, action: PayloadAction<any[]>) => {
            state.status = "idle";
            state.accountTypes = action.payload;
        })
        .addCase(fetchAllAccountType.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })
    }

});

export default GeneralOfficeSlice.reducer;