import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddOfficeCreditNote } from "./OfficeCreditNoteDetails/AddOfficeCreditNote";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { deleteCreditNote, fetchAllCreditNote } from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/CreditNoteSlice";
import { toast } from "react-toastify";
import { Pagination } from "flowbite-react";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import DeleteModal from "../Modal/DeleteModal";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";

function OfficeCreditNote() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [creditNoteData, setCreditNoteData] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const { creditNotes, status, error } = useSelector(
    (state: RootState) => state.creditNote
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentCreditNote, setCurrentCreditNote] = useState<any[]>([]);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [creditNoteId, setCreditNoteId] = useState<number>(0);


  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      dispatch(fetchAllCreditNote())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }

    if (status === "failed" && !errorShownRef.current) {
      toast.error(error);
      errorShownRef.current = true;
    }
  }, [dispatch,isOnAddedOrUpdate, status, error]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (creditNotes != null && creditNotes.length > 0) {
      const filteredByCreditNote = filterCreditNoteBySearchQuery();
      const indexOfLastCreditNote = currentPage * itemsPerPage;
      const indexOfFirstCreditNote = indexOfLastCreditNote - itemsPerPage;
      const currentCreditNote = filteredByCreditNote.slice(
        indexOfFirstCreditNote,
        indexOfLastCreditNote
      );
      const totalPages = Math.ceil(creditNotes.length / itemsPerPage);
      setTotalPages(totalPages);
      setCurrentCreditNote(currentCreditNote);
    }
  }, [creditNotes]);

  const filterCreditNoteBySearchQuery = () => {
    return creditNotes.filter((creditNote: any) =>
      creditNote?.accountMethod
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  };

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const onDeleteCall = () => {
    try {
      dispatch(deleteCreditNote(creditNoteId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted Office credit Note");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OACreditNote"
        getData={creditNoteData}
        title="Office CreditNote "
      />
    );
  }

  if (openModal) {
    return (
      <AddOfficeCreditNote
        openModal={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        isView={isView}
        creditNote={creditNoteData}
      />
    );
  }
  return (
    <>
      <div className="">

      {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={creditNoteId}
            title="Office CreditNote"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="Credit Note"
            buttonName="Credit Note"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <SearchInput title="Keyword" />
          <div>
            <div className="flex gap-4 md:gap-1 w-full">
              <CheckBoxFilter title="Status" />
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden sm:rounded-lg my-5">
            <div className="overflow-x-auto max-h-96">
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Invoicee (s)
                </th>
                <th
                  scope="col"
                  className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Credit Amount
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Credit Note
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Invoice
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-center w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentCreditNote.map((creditNote: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatDateOnlyDisplay(creditNote.date)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {creditNote.invoicee}
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatCurrency(creditNote.creditAmount)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {creditNote.creditNote}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <a href="" className="hover:text-blue-500">
                      {" "}
                      {creditNote.invoice}
                    </a>
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {creditNote.status}
                  </td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye className="fill-gray-500 w-6 h-6" 
                       onClick={() => {
                        setOpenViewDrawer(true);
                        setIsView(true);
                        setIsEdit(false);
                        setCreditNoteData(creditNote);
                      }} 
                      />
                      <FaEdit className="fill-blue-500 w-6 h-6"
                       onClick={() => {
                        setOpenModal(true);
                        setIsEdit(true);
                        setCreditNoteData(creditNote);
                      }}
                      />
                      <RiDeleteBin5Fill className="fill-red-600 w-6 h-6"
                      onClick={() => {
                        setOpenDelModal(true);
                        setCreditNoteId(creditNote.creditNoteId);
                      }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
            </div>
          
          {currentCreditNote.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Credit Note Details found.</p>
            </div>
          )}
        </div>
        {currentCreditNote.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { OfficeCreditNote };
