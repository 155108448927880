import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import JournalEntryService from "../../../../../services/Accounting/ClientAccounts/ClientPostings/JournalEntryService";



export interface JournalEntry {
    journalEntryId ?: number;
    journalEntry: string;
    date: string;
    description: string;
    journalLine: journalLine[];
};

export interface journalLine{
    account: string;
    description: string;
    debitCurrency: number;
    creditCurrency: number;
}

export interface JournalEntryData{
    journalEntryResult: [];
    currentPage: number;
    recordPerPage: number;
    totalCount: number;
}
// Define the state type for Checklist
type JournalEntryState = {
    journalEntryData: JournalEntryData
    journalEntries: JournalEntry[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    receiptNumber: number | null;
    journalEntryDataById: any | null;

};

// Initial state
const initialState: JournalEntryState = {
    journalEntryData: null!,
    journalEntries: [],
    status: "idle",
    error: null,
    receiptNumber: null!,
    journalEntryDataById: null
};

// Async thunks for CRUD operations
export const fetchAllJournalEntry = createAsyncThunk(
    "fetchAllJournalEntry",
    async (_, thunkAPI) => {
        try {
            const response = await JournalEntryService.GetAllJournalEntry();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
export const fetchJournalEntryById = createAsyncThunk(
    "fetchJournalEntryById",
    async (id: number, thunkAPI) => {
        try {
            const response = await JournalEntryService.GetJournalEntryById(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchJournalEntryNumber = createAsyncThunk(
    "fetchJournalEntryNumber",
    async (_, thunkAPI) => {
        try {
            const response = await JournalEntryService.JournalEntryNumber();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addJournalEntry = createAsyncThunk(
    "addJournalEntry",
    async (data: any, thunkAPI) => {
        try {
            const response = await JournalEntryService.PostJournalEntry(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateJournalEntry = createAsyncThunk(
    "updateJournalEntry",
    async ({ id, data }: { id: number, data: JournalEntry }, thunkAPI) => {
        try {
            const response = await JournalEntryService.PutJournalEntry(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteJournalEntry = createAsyncThunk(
    "deleteJournalEntry",
    async (id: number, thunkAPI) => {
        try {
            await JournalEntryService.deleteJournalEntry(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const JournalEntrySlice = createSlice({
    name: "journalEntry",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllJournalEntry.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllJournalEntry.fulfilled, (state, action: PayloadAction<JournalEntryData>) => {
                state.status = "idle";
                state.journalEntryData = action.payload;
            })
            .addCase(fetchAllJournalEntry.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchJournalEntryById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchJournalEntryById.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.journalEntryDataById = action.payload;
            })
            .addCase(fetchJournalEntryById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchJournalEntryNumber.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchJournalEntryNumber.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.receiptNumber = action.payload;
            })
            .addCase(fetchJournalEntryNumber.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addJournalEntry.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addJournalEntry.fulfilled, (state, action: PayloadAction<JournalEntry>) => {
                state.status = "idle";
                state.journalEntries.push(action.payload);
            })
            .addCase(addJournalEntry.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateJournalEntry.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateJournalEntry.fulfilled, (state, action: PayloadAction<JournalEntry>) => {
                state.status = "idle";
                const index = state.journalEntries.findIndex(t => t.journalEntryId === action.payload.journalEntryId);
                if (index !== -1) {
                    state.journalEntries[index] = action.payload;
                }
            })
            .addCase(updateJournalEntry.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteJournalEntry.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteJournalEntry.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.journalEntries = state.journalEntries.filter(t => t.journalEntryId !== action.payload);
            })
            .addCase(deleteJournalEntry.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default JournalEntrySlice.reducer;
