import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import StaffAddNoteService from "../../../../../../services/CaseManagement/Case/Correspondence/AddItems/StaffAddNoteService";
import { AddStaffNote, StaffNoteData } from "../../../../../model/CaseManagement/StaffNoteInterFace";




// Define the state type for cases
type StaffNoteState = {
    addStaffNote: AddStaffNote[];
    staffNoteData: StaffNoteData;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: StaffNoteState = {
    addStaffNote: [],
    staffNoteData: null!,
    status: "idle",
    error: null
};

export const fetchCaseStaffNote = createAsyncThunk(
    "fetchCaseStaffNote",
    async (data: any, thunkAPI) => {
        try {
            const response = await StaffAddNoteService.GetCaseStaffNote(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export const addStaffNote = createAsyncThunk(
    "addStaffNote",
    async (data: any, thunkAPI) => {
        try {
            const response = await StaffAddNoteService.PostStaffNote(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const StaffAddNoteSlice = createSlice({
    name: "staffNote",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchCaseStaffNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCaseStaffNote.fulfilled, (state, action: PayloadAction<StaffNoteData>) => {
                state.status = "idle";
                state.staffNoteData = action.payload;
            })
            .addCase(fetchCaseStaffNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addStaffNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addStaffNote.fulfilled, (state, action: PayloadAction<AddStaffNote[]>) => {
                state.status = "idle";
                state.addStaffNote = action.payload;
            })
            .addCase(addStaffNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
    }
});

export default StaffAddNoteSlice.reducer;
