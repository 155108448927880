import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import NewInvoiceService from "../../../../../services/Accounting/ClientAccounts/Invoice/NewInvoiceService";
import ProfessionalFeeService from "../../../../../services/Accounting/ClientAccounts/Invoice/ProfessionalFeeService";



export interface NewInvoice {

    feeNumber : number;
    fkCaseId : number;
    feeDate: string;
    entryDate : string;
    fkFeeTypeId : number;
    feeType : string;
    fkStaffId : number;
    unitPrice  : number;
    Quantity : number;
    amout: number;
    fkTaxId : number
    tax :number;
    total : number;
    memo : number;
    isBillable : boolean;
    billingDesc : number
};

export interface ProfessionalFeeData{
    professionalFeeResult: [];
    totalCount: number;
    currentPage: number;
    recordPerPage: number;
}
// Define the state type for Checklist
type NewInvoiceState = {
    professionalFeeData: ProfessionalFeeData;
    receiptNumber: number | null;
    newInvoices: NewInvoice[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: NewInvoiceState = {
    professionalFeeData: null!,
    receiptNumber: null,
    newInvoices: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchProfessionalFeeNumber = createAsyncThunk(
    "fetchProfessionalFeeNumber",
    async (_, thunkAPI) => {
        try {
            const response = await ProfessionalFeeService.GetProfessionalFeeNumber();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchProfessionalFeeSearch = createAsyncThunk(
    "fetchProfessionalFeeSearch",
    async (_, thunkAPI) => {
        try {
            const response = await ProfessionalFeeService.GetProfessionalFeeSearch();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addProfessionalFee = createAsyncThunk(
    "addProfessionalFee",
    async (data: any, thunkAPI) => {
        try {
            const response = await ProfessionalFeeService.PostProfessionalFee(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateNewInvoice = createAsyncThunk(
    "updateNewInvoice",
    async ({ id, data }: { id: number, data: NewInvoice }, thunkAPI) => {
        try {
            const response = await NewInvoiceService.PutNewInvoice(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// export const deleteNewInvoice = createAsyncThunk(
//     "deleteNewInvoice",
//     async (id: number, thunkAPI) => {
//         try {
//             await NewInvoiceService.deleteNewInvoice(id);
//             return id;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// Slice definition
const ProfessionalFeeSlice = createSlice({
    name: "professionalFee",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProfessionalFeeNumber.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchProfessionalFeeNumber.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.receiptNumber = action.payload;
            })
            .addCase(fetchProfessionalFeeNumber.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchProfessionalFeeSearch.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchProfessionalFeeSearch.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.professionalFeeData = action.payload;
            })
            .addCase(fetchProfessionalFeeSearch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addProfessionalFee.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addProfessionalFee.fulfilled, (state, action: PayloadAction<NewInvoice>) => {
                state.status = "idle";
                state.newInvoices.push(action.payload);
            })
            .addCase(addProfessionalFee.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateNewInvoice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateNewInvoice.fulfilled, (state, action: PayloadAction<NewInvoice>) => {
                state.status = "idle";
                const index = state.newInvoices.findIndex(t => t.feeNumber === action.payload.feeNumber);
                if (index !== -1) {
                    state.newInvoices[index] = action.payload;
                }
            })
            .addCase(updateNewInvoice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
        // .addCase(deleteFeeType.pending, (state) => {
        //     state.status = "loading";
        // })
        // .addCase(deleteFeeType.fulfilled, (state, action: PayloadAction<number>) => {
        //     state.status = "idle";
        //     state.feeTypes = state.feeTypes.filter(t => t.feeTypeId !== action.payload);
        // })
        // .addCase(deleteFeeType.rejected, (state, action) => {
        //     state.status = "failed";
        //     state.error = action.payload as string;
        // });
    }
});

export default ProfessionalFeeSlice.reducer;
