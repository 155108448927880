import { useState } from "react";
import { NewPayment } from "./BankPaymentDetails/NewPayment";
import { AuthorisePayment } from "./BankPaymentDetails/AuthorisePayment";
import { CreatePayment } from "./BankPaymentDetails/CreatePayment";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaMoneyCheckAlt, FaCheckSquare, FaPlusCircle } from "react-icons/fa";

const BankPayment = () =>{


    const [selectedSection, setSelectedSection] = useState('NewPayment');


    const handleButtonClick = (section: any) => {
        setSelectedSection(section);
    };


    return (
        <>
            <div className="w-full flex flex-col gap-3">
                <div className="">
                    <SubHeaderTitle title="Bank Payment" />
                </div>
                {/* <div className="">
                    <div className="grid grid-cols-4 md:grid-cols-2 lg:grid-cols-3 items-center gap-2 dark:border-gray-400 dark:border-opacity-35 text-sm">
                        <button className={`flex items-center font-semibold px-3 py-3 rounded-md border dark:border-gray-400 dark:border-opacity-35 ${selectedSection === 'NewPayment' ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-300 hover:bg-gray-400 text-white-bg'}`} onClick={() => handleButtonClick('NewPayment')}>
                        <FaMoneyCheckAlt className={`size-5 ${selectedSection === 'NewPayment' ? 'fill-white-bg' : 'fill-black'}`}/>
                            <span className={`mx-3 w-[138px] text-left ${selectedSection === 'NewPayment' ? 'text-white-bg' : 'text-black'}`}>New Payment</span>
                        </button>
                        <button className={`flex items-center font-semibold px-3 py-3 rounded-md border dark:border-gray-400 dark:border-opacity-35 ${selectedSection === 'AuthorisePayment' ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-300 hover:bg-gray-400 text-white-bg'}`} onClick={() => handleButtonClick('AuthorisePayment')}>
                        <FaCheckSquare className={`size-5 ${selectedSection === 'AuthorisePayment' ? 'fill-white-bg' : 'fill-black'}`}/>
                            <span className={`mx-3 w-[138px] text-left ${selectedSection === 'AuthorisePayment' ? 'text-white-bg' : 'text-black'}`}>Authorise Payment</span>
                        </button>
                        <button className={`flex items-center font-semibold px-3 py-3 rounded-md border dark:border-gray-400 dark:border-opacity-35 ${selectedSection === 'CreatePayment' ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-300 hover:bg-gray-400 text-white-bg'}`} onClick={() => handleButtonClick('CreatePayment')}>
                        <FaPlusCircle className={`size-5 ${selectedSection === 'CreatePayment' ? 'fill-white-bg' : 'fill-black'}`} />
                            <span className={`mx-3 w-[138px] text-left ${selectedSection === 'CreatePayment' ? 'text-white-bg' : 'text-black'}`}>Create Payment</span>
                        </button>
                    </div>
                </div> */}
                <div className="mb-5 w-full">
                    {selectedSection === 'NewPayment' && (
                        <NewPayment />
                    )}
                    {selectedSection === 'AuthorisePayment' && (
                        <AuthorisePayment />
                    )}
                    {selectedSection === 'CreatePayment' && (
                        <CreatePayment />
                    )}
                </div>
            </div>
        </>
    )
}

export { BankPayment };