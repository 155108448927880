import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";

const GetAllAuthorisation = async () => {
    try {
        const response = await axiosInstance.get("Authorisation");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutAuthorisation = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`Authorisation/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteAuthorisation = async (id: number) => {
    try {
        await axiosInstance.delete(`/Authorisation/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const AuthorisationService = {
    GetAllAuthorisation,
    PutAuthorisation,
    deleteAuthorisation
};


export default AuthorisationService;