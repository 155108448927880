import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, boolean, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { SelectBox } from '../../SelectBox';
import { useAppDispatch } from '../../../store/hooks/redux-hooks';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';

import { MainAnimation } from '../../loadingAnimation/MainAnimation';
import { addAccount } from '../../../store/slice/Accounting/AccountSetup/AccountSlice';
import { fetchAllNominalLedgerReceiable } from '../../../store/slice/Accounting/AccountSetup/NominalLedgerSlice';

function AddAccounts({ openDrawer, Close, account, isEdit, }: { openDrawer: boolean, Close: any, account?: any, isEdit: boolean }) {
    const [title] = useState((!isEdit ? 'Add New Bank Account' : 'Edit New Bank Account'));
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center');
    const [] = useState(account);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedAccountType, setSelectedAccountType] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { nominalLedgerReceiables } = useSelector((state: RootState) => state.nominalLedger);
    const [initialLoading, setInitialLoading] = useState(true);
    const [nominalLedgersOptionsLists, setNominalLedgersOptionsLists] = useState<any[]>([]);
    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);

    useEffect(() => {
        if (!effectServiceCall.current) {
            const fetchPromises = [
                dispatch(fetchAllNominalLedgerReceiable()),
            ];

            Promise.all(fetchPromises)
                .then(() => {
                    setInitialLoading(false);
                })
                .catch((error) => {
                    if (!errorShownRef.current) {
                        toast.error("Error fetching data: " + error.message);
                        errorShownRef.current = true;
                    }
                    setInitialLoading(false);
                });

            effectServiceCall.current = true;
        }
    }, [dispatch]);

    const accountType = [
        { label: 'Client', value: 'Client' },
        { label: 'Office', value: 'Office' }
    ];

    const mappedAccountType = accountType.map((type: any) => ({
        label: type.label,
        value: type.value
    }));

    useEffect(() => {
        if (nominalLedgerReceiables != null && nominalLedgerReceiables.length > 0) {
            let data = nominalLedgerReceiables
                .map((nominal: any) => ({
                    value: nominal.accountId,
                    label: `${nominal.accountName} (${nominal.accountCode})`
                }));
            setNominalLedgersOptionsLists(data);
        }
    }, [nominalLedgerReceiables]);

    const validationSchema = object().shape({
        bankAccountType: string().required('Bank account Type is required'),
        fkNominalAccountId: number().when('bankAccountType', {
            is: "Office",
            then: (schema) => schema.required('Nominal AccountId is required.'),
        }),
       // bankAccountCode: string().required('Bank account Code is required'),
        institution: string().required('Institution is required'),
        accountName: string().required("Account Name is required"),
        accountNumber: string().required('Account Number is required'),
        accountSortCode: string().required("Account Sort Code is required"),
        glAccountCode: string(),
        // lastChequeNumber: string(),
        // lastEtfNumber: string(),
        isDefaultAccount: boolean(),
        isActive: boolean(),
        bankAccountId: number(),
    });

    const { register, handleSubmit, reset, setValue,getValues, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            isActive: true,
            isDefaultAccount: true,
            bankAccountId: 0

        }
    });

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        // console.log(JSON.stringify(data, null, 2));
        try {
            if (isEdit && account) {
                // Update existing fee type
                data.bankAccountId = account.bankAccountId;
                await dispatch(addAccount(data )).unwrap();
                toast.success("Successfully Updated Fee Type");
                console.log(account.bankAccountId)
            } else {
            await dispatch(addAccount(data)).unwrap();
           
            navigate("/financeandaccounting/accountings/accountssetup");
            toast.success("Successfully Added AccountSetup");
        } 
        Close();
    }catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
      };


    useEffect(() => {
        if(account != null && isEdit === true){
            console.log(account)
            setValue('bankAccountType', account?.bankAccountType)
            setValue('institution', account?.institution)
            setValue('accountName', account?.accountName)
            setValue('accountNumber', account?.accountNumber)
            setValue('accountSortCode', account?.accountSortCode)
            setValue('glAccountCode', account?.glAccountCode)
            setValue('isDefaultAccount', account?.isDefaultAccount)
            setValue('isActive', account?.isActive)
           
        }
    }, [account, isEdit, reset, setValue]);

    
    
    if (initialLoading) {
        return <MainAnimation />;
    }

    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); Close(); }}
            initialFocus={emailInputRef}
            size="2xl"
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">
                <form onSubmit={handleSubmit(onSubmit,onError)} className="mx-auto" >
                    <div className="grid grid-cols-2 gap-x-4 gap-y-1 w-full">
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Bank Account Type <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={mappedAccountType}
                                value={getValues().bankAccountType}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('bankAccountType', selectedOption);
                                        setSelectedAccountType(selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.bankAccountType?.message?.toString()}</div>
                        </div>
                        {selectedAccountType === 'Office' && (
                            <div className="relative">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Nominal Ledger <span className="text-red-500">*</span>
                                </label>
                                <SelectBox
                                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    isMulti={false}
                                    name="fkNominalAccountId"
                                    options={nominalLedgersOptionsLists}
                                    isSearchable={true}
                                    placeholder="Select Nominal Ledger"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            setValue('fkNominalAccountId', selectedOption);
                                        }
                                    }}
                                />
                                <div className="font-medium mt-2 text-xs text-red-600">{errors?.fkNominalAccountId?.message?.toString()}</div>
                            </div>
                        )}
                        {/* <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Bank Account Code <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 dark:bg-dark_bg text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400 dark:text-white-bg`}
                                placeholder="Account Code"
                                {...register('bankAccountCode')} />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.bankAccountCode?.message?.toString()}</div>
                        </div> */}
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Bank Name <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 dark:bg-dark_bg text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400 dark:text-white-bg`}
                                placeholder="Institution"
                                {...register('institution')} />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.institution?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Account Name <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.accountName ? 'is-invalid' : ''}`} {...register('accountName')}
                                placeholder="Enter Account Name"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.accountName?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Account Number <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.accountNumber ? 'is-invalid' : ''}`} {...register('accountNumber')}
                                placeholder="Enter Account Number"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.accountNumber?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Account Sort Code <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`first-line:marker:border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.accountSortCode ? 'is-invalid' : ''}`} {...register('accountSortCode')}
                                placeholder="Enter Account Sort Code"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.accountSortCode?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                GL Account Code
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('glAccountCode')}
                                placeholder="Enter GL Account Code"
                            />
                        </div>

                        {/* <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Last Cheque Number
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 dark:bg-dark_bg text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400 dark:text-white-bg`}
                                placeholder="Last Cheque Number"
                                {...register('lastChequeNumber')} />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.lastChequeNumber?.message?.toString()}</div>
                        </div> */}
                        {/* <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Last ETF Number
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 dark:bg-dark_bg text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400 dark:text-white-bg`}
                                placeholder="Last ETF Number"
                                {...register('lastEtfNumber')} />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.lastEtfNumber?.message?.toString()}</div>
                        </div> */}
                        
                    </div>
                    
                    <div className='grid grid-cols-2 gap-x-5'>
                            <div className="flex items-center">
                                <input id="bordered-checkbox-1" type="checkbox" value="" className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`} {...register('isDefaultAccount')} />
                                <label className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Is Default Account</label>
                            </div>
                            {isEdit && (
                            <div className="flex items-center gap-3">
                                <label className="block ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    Is Active
                                </label>
                                <div className='flex gap-5'>
                                    <div>
                                        <input
                                            type="radio"
                                            value="true"
                                            checked={watch('isActive') === true}
                                            onChange={() => setValue('isActive', true)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                                    </div>
                                    <div>
                                        <input
                                            id="bordered-radio-1"
                                            type="radio"
                                            value="false"
                                            checked={watch('isActive') === false}
                                            onChange={() => setValue('isActive', false)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>
                    
                    <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                        <div className="flex flex-row gap-2 items-end justify-end w-full">
                            <button
                                type='button'
                                onClick={() => {
                                    reset();
                                    Close();
                                }}
                                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isLoading ? (
                                    <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                    </svg>
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body >
        </Modal >
    );
}

export { AddAccounts };
