import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { SearchComponent } from "../../../AutoSelectAddress/SearchInput";
import { SelectAddressComponent } from "../../../AutoSelectAddress/SelectAddress";
import { useAppDispatch } from "../../../../store/hooks/redux-hooks";
import { useEffect, useRef, useState } from "react";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { fetchLocationByPostcode } from "../../../../store/slice/CaseManagement/AddressSlice";
import LocationUtils from "../../../../utils/location.utils";


function AddComContactTwo({ onSubmitStep, initialData, onPreviousStep, }: any) {
    const dispatch = useAppDispatch();
    const [postcode, setPostcode] = useState('');
    const [locationOptionsLists, setLocationOptionsLists] = useState<any[]>();
    const { locations } = useSelector((state: RootState) => state.location);
    const contactDataById = useSelector((state: RootState) => state.contact.contactDataById);

    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current && postcode) {
            dispatch(fetchLocationByPostcode(postcode));
        }
        return () => { effectServiceCall.current = true; };
    }, [dispatch, postcode]);


    useEffect(() => {
        if (locations != null && locations.length > 0) {
            let data = locations.map((location: any) => ({
                value: location.address,
                label: location.address,
            }));
            setLocationOptionsLists(data);
        }
    }, [locations]);

    const onLocationChange = (location: any) => {
        if (location) {
            const obj = LocationUtils.splitAddress(location);
            if (obj != null) {
                setValue('companyAddressLine1', obj.addressLine1)
                setValue('companyAddressLine2', obj.addressLine2)
                setValue('companyCity', obj.city)
                setValue('companyCounty', obj.county)
                setValue('companyPostcode', obj.postcode)
            }
            console.log(obj)
        }
    }

    const validationSchema = object().shape({
        companyAddressLine1: string()
            .required('Select Address is required'),
        companyAddressLine2: string(),
        companyCity: string(),
        companyCounty: string(),
        companyPostcode: string(),
        companyPoboxNumber: string(),
        companyPoboxCounty: string(),
        companyPoboxTown: string(),
        companyPoboxPostcode: string(),
    })

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialData || {}
    });

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        //close();
        onSubmitStep(data);
    };

    useEffect(() => {
        if (contactDataById !== null) {
            setValue("companyAddressLine1", contactDataById?.companyAddressLine1);
            setValue("companyAddressLine2", contactDataById?.companyAddressLine2);
            setValue("companyCity", contactDataById?.companyCity);
            setValue("companyCounty", contactDataById?.companyCounty);
            setValue("companyPostcode", contactDataById?.companyPostcode);
            setValue("companyPoboxNumber", contactDataById?.companyPoboxNumber);
            setValue("companyPoboxCounty", contactDataById?.companyPoboxCounty);
            setValue("companyPoboxTown", contactDataById?.companyPoboxTown);
            setValue("companyPoboxPostcode", contactDataById?.companyPoboxPostcode);
        }
    }, [contactDataById])

    return (
        <div>
            <form onSubmit={handleSubmit(handleFormSubmit)}>

                <div>
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">Current Address</h3>
                        <SearchComponent setPostcode={setPostcode} />
                    </div>
                    <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-2 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                        <div className="">
                            <SelectAddressComponent
                                locationOptionsLists={locationOptionsLists ?? []}
                                onLocationChange={onLocationChange}
                                setValue={setValue}
                            />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Address Line 1 <span className="text-red-500">*</span>
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.companyAddressLine1 ? 'is-invalid' : ''}`} {...register('companyAddressLine1')} />
                            <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.companyAddressLine1?.message?.toString()} </div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Address Line 2
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('companyAddressLine2')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                City
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyCity')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                County
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyCounty')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Postcode
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 }`} {...register('companyPostcode')} />
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">P.O Box Address</h3>
                    </div>
                    <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 ">
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Number
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyPoboxNumber')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Town
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyPoboxTown')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                County
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('companyPoboxCounty')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Post Code
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('companyPoboxPostcode')} />
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="flex flex-row gap-2 items-start justify-between w-full">
                        <button
                            onClick={onPreviousStep}
                            type="button"
                            className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[140px]">
                            <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                            Previous
                        </button>

                        <button
                            type="submit"
                            className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">
                            Next
                            <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export { AddComContactTwo };
