import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import TaskStatusService from "../../../services/CaseManagement/TaskStatusService";
import { PaginationResponse } from "../../model/Pagination/paginationClass";

export interface TaskStatus {

  taskStatusId: number;
  isActive: boolean; 
status: string;
taskColor: string;
tasksStatusTypeId: number;
}
// Define the state type for Checklist
type TaskStatusState = {
    taskStatuses: TaskStatus[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: TaskStatusState = {
    taskStatuses: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllTaskStatus = createAsyncThunk(
    "fetchAllTaskStatus",
    async (_, thunkAPI) => {
        try {
            const response = await TaskStatusService.GetAllTaskStatus();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const TaskStatusSlice = createSlice({
    name: "taskStatus",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTaskStatus.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllTaskStatus.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.status = "idle";
                state.taskStatuses = action.payload;
            })
            .addCase(fetchAllTaskStatus.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
        }
    });
    
    export default TaskStatusSlice.reducer;