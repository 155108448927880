import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import DocumentCategoryService from "../../../services/SystemMaintenance/DocumentCategoryService";

export interface DocumentCategory {
    documentCategoryId: number;
    documentCategory: string;
    isActive: boolean;
    createdBy: string;
    createdOn: string;
    deletedBy: number;
    isDeleted: boolean;
    deletedOn: string;
};

// Define the state type for DocumentCategory
type DocumentCategoryState = {
    documentCategorys: DocumentCategory[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: DocumentCategoryState = {
    documentCategorys: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllDocumentCategory = createAsyncThunk(
    "fetchAllDocumentCategory",
    async (_, thunkAPI) => {
        try {
            const response = await DocumentCategoryService.GetAllDocumentCategory();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addDocumentCategory = createAsyncThunk(
    "addDocumentCategory",
    async (data: any, thunkAPI) => {
        try {
            const response = await DocumentCategoryService.PostDocumentCategory(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateDocumentCategory = createAsyncThunk(
    "updateDocumentCategory",
    async ({ id, data }: { id: number, data: DocumentCategory }, thunkAPI) => {
        try {
            const response = await DocumentCategoryService.PutDocumentCategory(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteDocumentCategory = createAsyncThunk(
    "deleteDocumentCategory",
    async (DocumentCategoryId: number, thunkAPI) => {
        try {
            await DocumentCategoryService.deleteDocumentCategory(DocumentCategoryId);
            return DocumentCategoryId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const DocumentCategorySlice = createSlice({
    name: "documentCategory",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllDocumentCategory.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllDocumentCategory.fulfilled, (state, action: PayloadAction<DocumentCategory[]>) => {
                state.status = "idle";
                state.documentCategorys = action.payload;
            })
            .addCase(fetchAllDocumentCategory.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addDocumentCategory.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addDocumentCategory.fulfilled, (state, action: PayloadAction<DocumentCategory>) => {
                state.status = "idle";
                state.documentCategorys.push(action.payload);
            })
            .addCase(addDocumentCategory.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateDocumentCategory.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateDocumentCategory.fulfilled, (state, action: PayloadAction<DocumentCategory>) => {
                state.status = "idle";
                const index = state.documentCategorys.findIndex(t => t.documentCategoryId === action.payload.documentCategoryId);
                if (index !== -1) {
                    state.documentCategorys[index] = action.payload;
                }
            })
            .addCase(updateDocumentCategory.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteDocumentCategory.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteDocumentCategory.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.documentCategorys = state.documentCategorys.filter(t => t.documentCategoryId !== action.payload);
            })
            .addCase(deleteDocumentCategory.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default DocumentCategorySlice.reducer;
