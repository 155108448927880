import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import RARiskAssessmentService from "../../../../services/RiskManagement/RiskAssessment/RARiskAssessmentService";
import { Attachment, RiskAssessmentGetData, RiskAssessmentResult, ScreenQuestionAnswes } from "../../../model/RiskAssessment/RiskAssessmentData/RiskAssessmentSearch";
import { QuotationRequest } from "./QuotationRequestSlice";


// Define the state type for casetype
type PropertyConditionTypeState = {
    screenQuestionAnswes: ScreenQuestionAnswes;
    attachment: Attachment[];
    riskAssessmentResult: RiskAssessmentResult[];
    riskAssessmentGetData: RiskAssessmentGetData[];
    quotationRequest: QuotationRequest[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    any: any[];
};

// Initial state
const initialState: PropertyConditionTypeState = {
    screenQuestionAnswes: null!,
    attachment: [],
    quotationRequest: [],
    riskAssessmentResult: [],
    riskAssessmentGetData: [],
    status: "idle",
    error: null,
    any: null!
};

// Async thunks for CRUD operations
export const fetchAllRiskAssessmentGetData = createAsyncThunk(
    "fetchAllRiskAssessmentGetData",
    async (data: RiskAssessmentGetData, thunkAPI) => {
        try {
            const response = await RARiskAssessmentService.GetSearchRiskAssessment(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchScreenQuestionAnswesByRAId = createAsyncThunk(
    "fetchScreenQuestionAnswesByRAId",
    async (id: number, thunkAPI) => {
        try {
            const response = await RARiskAssessmentService.GetScreenQuestionAnswesByRAId(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addRiskAssessmentAttachments = createAsyncThunk(
    "addRiskAssessmentAttachments",
    async (data: any, thunkAPI) => {
        try {
            const response = await RARiskAssessmentService.PostRiskAssessmentAttachments(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addRiskAssessmentResult = createAsyncThunk(
    "addRiskAssessmentResult",
    async (data: any, thunkAPI) => {
        try {
            const response = await RARiskAssessmentService.PostRiskAssessmentResult(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const RiskAssessmentSearchSlice = createSlice({
    name: "RiskAssessmentData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllRiskAssessmentGetData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllRiskAssessmentGetData.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.status = "idle";
                state.quotationRequest = action.payload;
            })
            .addCase(fetchAllRiskAssessmentGetData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchScreenQuestionAnswesByRAId.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchScreenQuestionAnswesByRAId.fulfilled, (state, action: PayloadAction<ScreenQuestionAnswes>) => {
                state.status = "idle";
                state.screenQuestionAnswes = action.payload;
            })
            .addCase(fetchScreenQuestionAnswesByRAId.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addRiskAssessmentAttachments.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addRiskAssessmentAttachments.fulfilled, (state, action: PayloadAction<Attachment>) => {
                state.status = "idle";
                state.attachment.push(action.payload);
            })
            .addCase(addRiskAssessmentAttachments.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addRiskAssessmentResult.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addRiskAssessmentResult.fulfilled, (state, action: PayloadAction<RiskAssessmentResult>) => {
                state.status = "idle";
                state.riskAssessmentResult.push(action.payload);
            })
            .addCase(addRiskAssessmentResult.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
    }
});

export default RiskAssessmentSearchSlice.reducer;
