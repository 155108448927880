import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import IdentificationTypeSrvice from "../../../services/CaseManagement/IdentificationTypeSrvice";

export interface IdentificationType  {
    identificationTypeId: number;
    identificationType: string;
    notes: string;
    isActive: boolean;
};

// Define the state type for identificationType
type IdentificationTypeState = {
    identificationTypes: IdentificationType[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: IdentificationTypeState = {
    identificationTypes: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllIdentificationType = createAsyncThunk(
    "fetchAllIdentificationType",
    async (_, thunkAPI) => {
        try {
            const response = await IdentificationTypeSrvice.GetAllIdentificationType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const IdentificationTypeSlice = createSlice({
    name: "identificationType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllIdentificationType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllIdentificationType.fulfilled, (state, action: PayloadAction<IdentificationType[]>) => {
                state.status = "idle";
                state.identificationTypes = action.payload;
            })
            .addCase(fetchAllIdentificationType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
    }
});

export default IdentificationTypeSlice.reducer;
