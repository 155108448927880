import { SubHeaderTitle } from "../SubHeaderTitle";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useState } from "react";
import { fetchAllBalanceSheet } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/BalanceSheetSlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { toast } from "react-toastify";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
function BalanceSheet() {
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(false);
  const [data, setData] = useState({ date: new Date().toISOString().split("T")[0] });
  const companyProfiles = useSelector((state: RootState) => state.companyProfile.companyProfiles);


  const handleDateChange = (e: any) => {
    setData({ date: e.target.value });
  };

  const handleSearchClick = async() => {
    setInitialLoading(true);
    try {
      await dispatch(fetchAllBalanceSheet(data)).unwrap();
      toast.success("Successfully Search Balance Sheet");
    } catch (error: any) {
      toast.error(error?.message || "An error occurred while fetching data");
    } finally {
      setInitialLoading(false);
    }
  };


  return (
    <>
      <div className="">
        <div className="flex items-center justify-between mb-5">
          <div>
          <SubHeaderTitle title="Balance Sheet" />
          </div>
          <div className="flex gap-x-2 ietms-center justify-end">
          {initialLoading ? <MainAnimation /> : ""}
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-sm"
          >
            <IoIosPrint className="w-5 h-5" />
            Print
          </button>
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-sm"
          >
            <BsFileEarmarkExcelFill className="w-5 h-5" />
            Export to Excel
          </button>
        </div>
        </div>
        <div className="flex flex-row md:flex-col lg:flex-col xl:flex-col justify-between my-5 gap-4 w-full md:gap-3">
          <div className="flex gap-4">
            <input
              type="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
              max={new Date().toISOString().split("T")[0]}
              defaultValue={data.date}
              onChange={handleDateChange}
            />
            <button
              className="z-[2] inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
              type="button"
              onClick={handleSearchClick}>
              Search
            </button>
          </div>
        </div>
        
        <div className="my-5 text-center">
          <h1 className="text-2xl font-bold">{companyProfiles?.companyName}</h1>
          <p className="text-lg font-semibold">Balance Sheet</p>
          <p className="font-semibold">06 Sep 2024</p>
        </div>
        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto max-h-96">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Income
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">2</td>
                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">Miscellaneous</td>
                <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">£ 17,540.00</td>
              </tr>
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">3</td>
                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">Disbursement</td>
                <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">£ 2,845.00</td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </>
  );
}

export { BalanceSheet };
