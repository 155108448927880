import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useEffect, useRef, useState } from "react";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { fetchAllBudgetVarianceReport } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/BudgetVarianceReportSlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
function BudgetVarianceReport() {

  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const budgetVarianceReport = useSelector((state: RootState) => state.budgetVarianceReport);

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      let data = {
        date: ""
      }
      dispatch(fetchAllBudgetVarianceReport(data))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle title="Budget Variance Report" />
        </div>
        {initialLoading ? <MainAnimation /> : ""}
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <div>
            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full">
              <CheckBoxFilter title="Financial Year" />
              <CheckBoxFilter title="Budget Name" />
            </div>
          </div>
          <div>
            <button
              className="z-[2] inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
              data-twe-ripple-init
              data-twe-ripple-color="white"
              type="button"
              id="button-addon3"
            >
              Search
            </button>
          </div>
        </div>
        <div className="flex gap-x-2 ietms-center justify-end">
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-sm"
          >
            <IoIosPrint className="w-5 h-5" />
            Print
          </button>
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-sm"
          >
            <BsFileEarmarkExcelFill className="w-5 h-5" />
            Export to Excel
          </button>
        </div>
        <div className="my-5 text-center">
          <h1 className="text-2xl font-bold">Shan & Co Solicitors</h1>
          <p className="text-lg font-semibold">Budget Variance Report</p>
          <p className="font-semibold">01 January 2020 - 31 December 2020</p>
        </div>
      </div>
    </>
  );
}

export { BudgetVarianceReport };