import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { Heading } from "../../AccountSetupHeading/Heading";


function AddExpertStepThree({ onSubmitStep, initialData, titleData, maritalStatusData, userRoleData, onCancel }: any) {

    // console.log(titleData)
    // console.log(maritalStatusData)
    // console.log(userRoleData)

    const validationSchema = object().shape({
        chamberName: string(),
        details: string(),
        feedetails: string(),
        notes: string(),

    })

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialData || {}
    });

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        onSubmitStep(data);
    };

    return (

        <form onSubmit={handleSubmit(handleFormSubmit)}>

            <Heading title={"Area of Practice"} />
            <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border px-6 py-3 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Chamber Name
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('chamberName')}
                        placeholder="Enter Chamber Name"
                    />
                </div>
                <div className="relative max-w-full col-span-2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Details
                    </label>
                    <textarea
                        className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        placeholder="Enter Details" {...register('details')}
                    ></textarea>
                </div>
            </div>

            <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border px-6 py-3 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                <div className="relative max-w-full col-span-2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Fee Details
                    </label>
                    <textarea
                        className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        placeholder="Enter Fee Details" {...register('feedetails')}
                    ></textarea>
                </div>
                <div className="relative max-w-full col-span-2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Fee Notes
                    </label>
                    <textarea
                        className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        placeholder="Enter Notes" {...register('notes')}
                    ></textarea>
                </div>



            </div>


            <div className="">
                <div className="flex flex-row gap-2 items-end justify-between w-full">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">  Cancel
                        <svg className="w-6 h-6 rotate-180 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>

                    </button>
                    <button
                        type="submit"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">
                        Next
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                    </button>
                </div>
            </div>
        </form>
    );
}

export { AddExpertStepThree };
