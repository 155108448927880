import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import TimeEntryUnitPriceService from "../../../../services/Accounting/AccountSetup/TimeEntryUnitPriceServices";

// tbl_fixed_fee_invoice_number_format

export interface TimeEntryUnitPrice {
    roleTimeEntryPriceId: number;
    fkRoleId: number;
    fkTimeEntryTypeId: number;
    unitPrice: number;    
};
// Define the state type for Checklist
type TimeEntryUnitPriceState = {
    timeEntryUnitPrices: TimeEntryUnitPrice[];
    status: "idle" | "loading" | "failed" | "Added";
    error: string | null;
};

// Initial state
const initialState: TimeEntryUnitPriceState = {
    timeEntryUnitPrices: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllTimeEntryUnitPrice = createAsyncThunk(
    "fetchAllTimeEntryUnitPrice",
    async (_, thunkAPI) => {
        try {
            const response = await TimeEntryUnitPriceService.GetAllTimeEntryUnitPrice();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addTimeEntryUnitPrice = createAsyncThunk(
    "addTimeEntryUnitPrice",
    async (data: any, thunkAPI) => {
        try {
            const response = await TimeEntryUnitPriceService.PostTimeEntryUnitPrice(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateTimeEntryUnitPrice = createAsyncThunk(
    "updateTimeEntryUnitPrice",
    async ({ id, data }: { id: number, data: TimeEntryUnitPrice }, thunkAPI) => {
        try {
            const response = await TimeEntryUnitPriceService.PutTimeEntryUnitPrice(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteTimeEntryUnitPrice = createAsyncThunk(
    "deleteTimeEntryUnitPrice",
    async (id: number, thunkAPI) => {
        try {
            await TimeEntryUnitPriceService.deleteTimeEntryUnitPrice(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const TimeEntryUnitPriceSlice = createSlice({
    name: "timeEntryUnitPrice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTimeEntryUnitPrice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllTimeEntryUnitPrice.fulfilled, (state, action: PayloadAction<TimeEntryUnitPrice[]>) => {
                state.status = "idle";
                state.timeEntryUnitPrices = action.payload;
            })
            .addCase(fetchAllTimeEntryUnitPrice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addTimeEntryUnitPrice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addTimeEntryUnitPrice.fulfilled, (state) => {
                state.status = "Added";
               // state.feeTypes.push(action.payload);
            })
            .addCase(addTimeEntryUnitPrice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateTimeEntryUnitPrice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateTimeEntryUnitPrice.fulfilled, (state, action: PayloadAction<TimeEntryUnitPrice>) => {
                state.status = "idle";
                const index = state.timeEntryUnitPrices.findIndex(t => t.roleTimeEntryPriceId === action.payload.roleTimeEntryPriceId);
                if (index !== -1) {
                    state.timeEntryUnitPrices[index] = action.payload;
                }
            })
            .addCase(updateTimeEntryUnitPrice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteTimeEntryUnitPrice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteTimeEntryUnitPrice.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.timeEntryUnitPrices = state.timeEntryUnitPrices.filter(t => t.roleTimeEntryPriceId !== action.payload);
            })
            .addCase(deleteTimeEntryUnitPrice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default TimeEntryUnitPriceSlice.reducer;
