import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import FinancialYearService from "../../../../../services/Accounting/OfficeAccount/GeneralSettings/FinancialYearService";
import { PaginationResponse } from "../../../../model/Pagination/paginationClass";
export interface FinancialYear {
  accountingMethod: string;
  accountingMethodId: number;
  branchId: number;
  createdBy: number;
  createdOn: string; // You can also use Date type if you'll be converting strings to Date objects
  description: string | null;
  endDate: string; // Can be Date type as well, depending on your usage
  financialYearId: number;
  isAutorun: boolean | null;
  isCompleted: boolean;
  profitAndLossAmount: number | null;
  startDate: string; // Can be Date type as well
  transactionId: number;
};
// Define the state type for Checklist
type FinancialYearState = {
    financialYears: PaginationResponse<FinancialYear> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: FinancialYearState = {
    financialYears: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllFinancialYear = createAsyncThunk(
    "fetchAllFinancialYear",
    async (data: any, thunkAPI) => {
        try {
            const response = await FinancialYearService.GetAllFinancialYear(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addFinancialYear = createAsyncThunk(
    "addFinancialYear",
    async (data: any, thunkAPI) => {
        try {
            const response = await FinancialYearService.PostFinancialYear(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateFinancialYear = createAsyncThunk(
    "updateFinancialYear",
    async ({ id, data }: { id: number, data: FinancialYear }, thunkAPI) => {
        try {
            const response = await FinancialYearService.PutFinancialYear(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteFinancialYear = createAsyncThunk(
    "deleteFinancialYear",
    async (id: number, thunkAPI) => {
        try {
            await FinancialYearService.deleteFinancialYear(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const FinancialYearSlice = createSlice({
    name: "financialYear",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllFinancialYear.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllFinancialYear.fulfilled, (state, action: PayloadAction<PaginationResponse<FinancialYear>>) => {
                state.status = "idle";
                state.financialYears = action.payload;
            })
            .addCase(fetchAllFinancialYear.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addFinancialYear.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addFinancialYear.fulfilled, (state, action: PayloadAction<FinancialYear>) => {
                state.status = "idle";
               // state.financialYears.push(action.payload);
            })
            .addCase(addFinancialYear.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateFinancialYear.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateFinancialYear.fulfilled, (state, action: PayloadAction<FinancialYear>) => {
                state.status = "idle";
                // const index = state.financialYears.findIndex(t => t.financialYearId === action.payload.financialYearId);
                // if (index !== -1) {
                //     state.financialYears[index] = action.payload;
                // }
            })
            .addCase(updateFinancialYear.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteFinancialYear.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteFinancialYear.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.financialYears = state.financialYears.filter(t => t.financialYearId !== action.payload);
            })
            .addCase(deleteFinancialYear.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default FinancialYearSlice.reducer;
