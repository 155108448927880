import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks/redux-hooks';
import { SearchInput } from '../../Filters/SearchInput';
import { fetchAllAccount } from '../../../store/slice/Accounting/AccountSetup/AccountSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { MainAnimation } from '../../loadingAnimation/MainAnimation';
import { fetchAllAccountSearchNominalAccount } from '../../../store/slice/Accounting/OfficeAccounts/AccountSearchNominalAccountsSlice';

function SearchAccount({ openDrawer, Close, onAccountSelect, type, fkAccountTypeIds }: { openDrawer: boolean, Close: any, onAccountSelect: (account: any) => void, type?: string, fkAccountTypeIds? : string }) {
    const dispatch = useAppDispatch();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [openModal, setOpenModal] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [modalPlacement, setModalPlacement] = useState('center');
    const {accountSearchNominalAccounts }= useSelector((state: RootState) => state.accountSearchNominalAccount);
    const [accountLists, setAccountLists] = useState<any[]>([]);
    const [filteredAccounts, setFilteredAccounts] = useState<any[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<any | null>(null); // Store full account details
    const [searchQuery, setSearchQuery] = useState<string>("");
    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            let data = {
                keyword: '',
                BankAccountType: (type) ? type :'office',
                fkAccountTypeIds: (fkAccountTypeIds)? (fkAccountTypeIds): '1',
            }
            dispatch(fetchAllAccountSearchNominalAccount(data))
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (accountSearchNominalAccounts && accountSearchNominalAccounts.accounts.length > 0) {
            setAccountLists(accountSearchNominalAccounts.accounts);
        }
    }, [accountSearchNominalAccounts]);

    useEffect(() => {
        if (accountLists != null && accountLists.length > 0) {
            setFilteredAccounts(accountLists);
        }
      }, [accountLists]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (!query) {
            setFilteredAccounts(accountLists);
        } else {
          const filteredData = filterOfficeBankDepositBySearchQuery(query);
          setFilteredAccounts(filteredData);
        }
      };
    
      const filterOfficeBankDepositBySearchQuery = (query: string | null | undefined) => {
        if (!query || typeof query !== 'string' || !query.trim()) {
          return accountLists;
        }
      
        const normalizedQuery = query.toLowerCase().trim();
      
        return accountLists.filter((account: any) => {
          const accountName = account?.accountName?.toLowerCase();
          const accountType = account?.accountType;
      
          return (
            accountName?.includes(normalizedQuery) || accountType?.includes(normalizedQuery)
          );
        });
      };

    const handleAccountSelection = (accountSearchNominalAccount: any) => {
        setSelectedAccount(accountSearchNominalAccount);
    };

    const handleSelectAccountClick = () => {
        console.log(selectedAccount);
        if (selectedAccount) {
            onAccountSelect(selectedAccount);
        } else {
            console.log("No account selected");
        }
        resetModal();
    };

    const resetModal = () => {
        setSelectedAccount(null);
        setOpenModal(false);
    };

    

    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openModal}
            position={modalPlacement}
            onClose={resetModal}
            initialFocus={emailInputRef}
        >
            <Modal.Header className="bg-white-bg p-5">
                <span className="text-blue_gray-900 text-xl w-auto">Search Account</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg">
                <div className="space-y-6 w-full">
                    <div className="relative overflow-x-auto overflow-hidden my-5">
                        <div className='flex mb-5'>
                            <SearchInput title={'Keyword'} onChange={handleSearchChange} />
                        </div>
                        {initialLoading ? (<MainAnimation />) : (
                            <div className="overflow-x-auto max-h-96">
                                <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                                <thead className="sticky top-0 text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
                                        <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">Account Type</th>
                                        <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">Account Code</th>
                                        <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">Account Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredAccounts.map((accountSearchNominalAccount) => (
                                        <tr
                                            key={accountSearchNominalAccount.accountId}
                                            className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                                        >
                                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                                <input
                                                    type="radio"
                                                    name="account"
                                                    value={accountSearchNominalAccount.accountId}
                                                    onChange={() => handleAccountSelection(accountSearchNominalAccount)}
                                                    checked={selectedAccount?.accountId === accountSearchNominalAccount.accountId}
                                                />
                                            </td>
                                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{accountSearchNominalAccount.accountType}</td>
                                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{accountSearchNominalAccount.accountCode}</td>
                                            <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{accountSearchNominalAccount.accountName}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                            
                        )}
                    </div>
                    <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                        <div className="flex flex-row gap-2 items-end justify-end w-full">
                            <button
                                onClick={resetModal}
                                type='button'
                                className="cursor-pointer h-10 bg-white-A700 rounded-lg text-center border border-deep_purple-A400 text-deep_purple-A400 text-xs w-[140px] hover:text-white-A700 hover:bg-deep_purple-A400"
                            >
                                Cancel
                            </button>
                            <button
                                  type="button"
                                  onClick={handleSelectAccountClick}
                                className="bg-green-700 text-white-bg cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center border border-deep_purple-A400 text-white-A700 text-sm w-[120px] hover:text-deep_purple-A400 hover:bg-white-A700"
                            >
                                Select Account
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export { SearchAccount };
