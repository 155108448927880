import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CreditNoteService from "../../../../../services/Accounting/OfficeAccount/OfficePosting/CreditNoteService";


export interface CreditNote {
    creditNoteId: number;
    invoiceId: number;
    creditNoteNumber: number;
    totalAmount: number;
    remainingCredit: number;
    balanceDue: number;
    invoiceAmount: number;
    vat: number;
    creditNoteDate: Date;
    description: string;
    reason: string;
    notes: string;
    details: string;
    lineItems: CreditNoteLineItem[];
};

export interface CreditNoteLineItem {
    item: string;
    amount: number;
    vatRateId: number;
    vat: number;
    grossAmount: number;
}
// Define the state type for Checklist
type CreditNoteState = {
    creditNotes: CreditNote[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CreditNoteState = {
    creditNotes: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllCreditNote = createAsyncThunk(
    "fetchAllCreditNote",
    async (_, thunkAPI) => {
        try {
            const response = await CreditNoteService.GetAllCreditNote();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCreditNote = createAsyncThunk(
    "addCreditNote",
    async (data: any, thunkAPI) => {
        try {
            const response = await CreditNoteService.PostCreditNote(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateCreditNote = createAsyncThunk(
    "updateCreditNote",
    async ({ id, data }: { id: number, data: CreditNote }, thunkAPI) => {
        try {
            const response = await CreditNoteService.PutCreditNote(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCreditNote = createAsyncThunk(
    "deleteCreditNote",
    async (id: number, thunkAPI) => {
        try {
            await CreditNoteService.deleteCreditNote(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CreditNoteSlice = createSlice({
    name: "creditNote",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCreditNote.fulfilled, (state, action: PayloadAction<CreditNote[]>) => {
                state.status = "idle";
                state.creditNotes = action.payload;
            })
            .addCase(fetchAllCreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCreditNote.fulfilled, (state, action: PayloadAction<CreditNote>) => {
                state.status = "idle";
                state.creditNotes.push(action.payload);
            })
            .addCase(addCreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateCreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCreditNote.fulfilled, (state, action: PayloadAction<CreditNote>) => {
                state.status = "idle";
                const index = state.creditNotes.findIndex(t => t.creditNoteId === action.payload.creditNoteId);
                if (index !== -1) {
                    state.creditNotes[index] = action.payload;
                }
            })
            .addCase(updateCreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCreditNote.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCreditNote.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.creditNotes = state.creditNotes.filter(t => t.creditNoteId !== action.payload);
            })
            .addCase(deleteCreditNote.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default CreditNoteSlice.reducer;
