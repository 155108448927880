import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ContactGroupService from "../../../../../services/CaseManagement/Case/ContactGroup/ContactGroupService";
import ContactSubGroupService from "../../../../../services/CaseManagement/Case/ContactGroup/ContactSubGroup";


export interface ContactSubGroup {
    contactGroupId: number;
    groupName: string;
    groupSlug: string;
    description: string;
    fkAreasOfPracticeIds: string;
    isActive: boolean;
};

// Define the state type for Client
type ContactGoupState = {
    contactSubGroups: ContactSubGroup[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ContactGoupState = {
    contactSubGroups: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchContactSubGroup = createAsyncThunk(
    "fetchContactSubGroup",
    async ({ id }: { id: number }, thunkAPI) => {
        try {
            const response = await ContactSubGroupService.GetContactSubgroup(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const ContactSubGroupSlice = createSlice({
    name: "contactSubGroup",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchContactSubGroup.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchContactSubGroup.fulfilled, (state, action: PayloadAction<ContactSubGroup[]>) => {
                state.status = "idle";
                state.contactSubGroups = action.payload;
            })
            .addCase(fetchContactSubGroup.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
    }
});

export default ContactSubGroupSlice.reducer;
