// Company

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { useEffect } from "react";


function AddComClientOne({ onSubmitStep, initialData, onCancel }: any) {
    const clientByIdData = useSelector((state: RootState) => state.client.clientByIdData);


    const validationSchema = object().shape({
        companyName: string()
            .required("Company Name Required"),
        companyNature: string(),
        companyEmail: string(),
        companyFax: string(),
        companyWeb: string(),
        companyVat: string(),
        companyUtrn: string(),
        companyRegNo: string(),
        companyContactPhone1: string(),
        companyContactPhone2: string(),
        companyContactFax: string(),
    })

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...initialData,
        }
    });

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        onSubmitStep(data);
    };

    useEffect(() => {
        if (clientByIdData !== null) {
            setValue("companyName", clientByIdData?.companyName);
            setValue("companyNature", clientByIdData?.companyNature);
            setValue("companyEmail", clientByIdData?.companyEmail);
            setValue("companyFax", clientByIdData?.companyFax);
            setValue("companyWeb", clientByIdData?.companyWeb);
            setValue("companyVat", clientByIdData?.companyVat);
            setValue("companyUtrn", clientByIdData?.companyUtrn);
            setValue("companyRegNo", clientByIdData?.companyRegNo);
            setValue("companyContactPhone1", clientByIdData?.companyContactPhone1);
            setValue("companyContactPhone2", clientByIdData?.companyContactPhone2);
            setValue("companyContactFax", clientByIdData?.companyContactFax);
        }
    }, [clientByIdData])

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <h3 className="text-lg mt-2 font-bold text-left text-gray-900 dark:text-white-bg mb-5">Company Details</h3>
            <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-2 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Name <span className="text-red-500">*</span>
                    </label>
                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.companyName ? 'is-invalid' : ''}`} {...register('companyName')} />
                    <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.companyName?.message?.toString()}</div>
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Co. Reg Number
                    </label>
                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyNature')} />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Email Address
                    </label>
                    <input type="email" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                        {...register('companyEmail')} />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Website
                    </label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyWeb')} />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        VAT Reg Number
                    </label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyVat')} />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">UTRN</label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyUtrn')} />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">Other</label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyRegNo')} />
                </div>
            </div>
            <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">Contact Numbers</h3>
            <div className="grid grid-cols-3 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">Mobile No1</label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyContactPhone1')} />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Mobile No2
                    </label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyContactPhone2')} />
                </div>
                {/* <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Fax
                    </label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyContactFax')} />
                </div> */}
            </div>
            <div className="">
                <div className="flex flex-row gap-2 items-end justify-between w-full">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">  Cancel
                        <svg className="w-6 h-6 rotate-180 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>

                    </button>
                    <button
                        type="submit"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">
                        Next
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                    </button>
                </div>
            </div>
        </form>
    );
}

export { AddComClientOne };
