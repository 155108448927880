import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import NominalLedgerService from "../../../../services/Accounting/AccountSetup/NominalLedgerService";
import { NominalLedgerAc } from "../../../model/Accounting/AccountSetup/NominalLedgerAc";


export interface NominalLedger {
    accountId: number;
    accountCode: number;
    accountName: string;
    fkAccountTypeId: number;
    isEditable: boolean;
    isActive: boolean;
};

// Define the state type for Checklist
type NominalLedgerState = {
    nominalLedgerAcList: NominalLedgerAc | null,
    nominalLedgers: NominalLedger [];
    nominalLedgerReceiables: NominalLedger [];
    status: "idle" | "loading" | "failed";
    error: string | null;
    nominalLedgerTypeRes:any | null;
};

// Initial state
const initialState: NominalLedgerState = {
    nominalLedgerReceiables:[],
    nominalLedgers: [],
    nominalLedgerAcList: null,
    status: "idle",
    error: null,
    nominalLedgerTypeRes: null
};

// Async thunks for CRUD operations

export const fetchAllNominalLedgerByTypes = createAsyncThunk(
    "fetchAllNominalLedgerByTypes",
    async (_, thunkAPI) => {
        try {
            const response = await NominalLedgerService.GetAllNominalLedgerByTpes();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAccountSetupNominalLedger = createAsyncThunk(
    "fetchAccountSetupNominalLedger",
    async (_, thunkAPI) => {
        try {
            const response = await NominalLedgerService.GetAccountSetupNominalLedger();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllNominalLedger = createAsyncThunk(
    "fetchAllNominalLedger",
    async (_, thunkAPI) => {
        try {
            const response = await NominalLedgerService.GetAllNominalLedger();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllNominalLedgerReceiable = createAsyncThunk(
    "fetchAllNominalLedgerReceiable",
    async (_, thunkAPI) => {
        try {
            const response = await NominalLedgerService.GetAllNominalLedgerReceiable();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



export const addNominalLedger = createAsyncThunk(
    "addNominalLedger",
    async (data: any, thunkAPI) => {
        try {
            const response = await NominalLedgerService.PostNominalLedger(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const NominalLedgerSlice = createSlice({
    name: "nominalLedger",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAccountSetupNominalLedger.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAccountSetupNominalLedger.fulfilled, (state, action: PayloadAction<NominalLedgerAc>) => {
            state.status = "idle";
            state.nominalLedgerAcList = action.payload;
        })
        .addCase(fetchAccountSetupNominalLedger.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })

        .addCase(fetchAllNominalLedgerReceiable.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllNominalLedgerReceiable.fulfilled, (state, action: PayloadAction<NominalLedger[]>) => {
            state.status = "idle";
            state.nominalLedgerReceiables = action.payload;
        })
        .addCase(fetchAllNominalLedgerReceiable.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })

            .addCase(fetchAllNominalLedger.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllNominalLedger.fulfilled, (state, action: PayloadAction<NominalLedger[]>) => {
                state.status = "idle";
                state.nominalLedgers = action.payload;
            })
            .addCase(fetchAllNominalLedger.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(fetchAllNominalLedgerByTypes.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllNominalLedgerByTypes.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.nominalLedgerTypeRes = action.payload;
            })
            .addCase(fetchAllNominalLedgerByTypes.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addNominalLedger.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addNominalLedger.fulfilled, (state, action: PayloadAction<NominalLedger>) => {
                state.status = "idle";
                //state.nominalLedgers.push(action.payload);
            })
            .addCase(addNominalLedger.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

    }
});

export default NominalLedgerSlice.reducer;
