import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllTaskRiskAssesment = async () => {
    try {
        const response = await axiosInstance.post("SearchRiskAssessmentQuestionnaire", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostTaskRiskAssesment = async (data: any) => {
    try {
        const response = await axiosInstance.post("SaveRiskAssessmentQuestionnaire", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostRiskAssesmentQuestion = async (data: any) => {
    try {
        const response = await axiosInstance.post("SaveRiskAssessmentQuestion", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetRiskAssesmentQuestion = async (data: any) => {
    try {
        const response = await axiosInstance.post("Riskassement/questionbyid/search", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutTaskRiskAssesment = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`TaskRiskAssesment/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteTaskRiskAssesment = async (id: number) => {
    try {
        await axiosInstance.delete(`/TaskRiskAssesment/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const TaskRiskAssesmentService = {
    GetAllTaskRiskAssesment,
    PostTaskRiskAssesment,
    PutTaskRiskAssesment,
    deleteTaskRiskAssesment,
    PostRiskAssesmentQuestion,
    GetRiskAssesmentQuestion
};


export default TaskRiskAssesmentService;
