import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import BankToBankService from "../../../../../services/Accounting/ClientAccounts/ClientPostings/BankToBankService";
import { BankToBank, BankToBankGetData } from "../../../../model/Accounting/ClientAccount/BankToBankInterFace";



// Define the state type for Checklist
type BankToBankState = {
    bankToBankGetData: BankToBankGetData;
    bankToBanks: BankToBank[];
    receiptNumber: number | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
    any: any;
};

// Initial state
const initialState: BankToBankState = {
    bankToBankGetData: null!,
    receiptNumber: null,
    bankToBanks: [],
    status: "idle",
    error: null,
    any: null,
};

// Async thunks for CRUD operations
export const fetchAllBankToBank = createAsyncThunk(
    "fetchAllBankToBank",
    async (_, thunkAPI) => {
        try {
            const response = await BankToBankService.GetAllBankToBank();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchNextBankToBankNumber = createAsyncThunk(
    "fetchNextBankToBankNumber",
    async (_, thunkAPI) => {
        try {
            const response = await BankToBankService.GetBankToBankNumber();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addBankToBank = createAsyncThunk(
    "addBankToBank",
    async (data: BankToBank, thunkAPI) => {
        try {
            const response = await BankToBankService.PostBankToBank(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addReverseBankToBank = createAsyncThunk(
    "addReverseBankToBank",
    async (data: any, thunkAPI) => {
        try {
            const response = await BankToBankService.PostReverseBankToBank(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateBankToBank = createAsyncThunk(
    "updateBankToBank",
    async ({ id, data }: { id: number, data: BankToBank }, thunkAPI) => {
        try {
            const response = await BankToBankService.PutBankToBank(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteBankToBank = createAsyncThunk(
    "deleteBankToBank",
    async (id: number, thunkAPI) => {
        try {
            await BankToBankService.deleteBankToBank(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const BankToBankSlice = createSlice({
    name: "bankToBank",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBankToBank.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllBankToBank.fulfilled, (state, action: PayloadAction<BankToBankGetData>) => {
                state.status = "idle";
                state.bankToBankGetData = action.payload;
            })
            .addCase(fetchAllBankToBank.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchNextBankToBankNumber.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchNextBankToBankNumber.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.receiptNumber = action.payload;
            })
            .addCase(fetchNextBankToBankNumber.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addBankToBank.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addBankToBank.fulfilled, (state, action: PayloadAction<BankToBank>) => {
                state.status = "idle";
                state.bankToBanks.push(action.payload);
            })
            .addCase(addBankToBank.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addReverseBankToBank.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addReverseBankToBank.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.any.push(action.payload);
            })
            .addCase(addReverseBankToBank.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateBankToBank.pending, (state) => {
                state.status = "loading";
            })
            // .addCase(updateBankToBank.fulfilled, (state, action: PayloadAction<BankToBank>) => {
            //     state.status = "idle";
            //     const index = state.bankToBanks.findIndex(t => t.bankToBankId === action.payload.bankToBankId);
            //     if (index !== -1) {
            //         state.bankToBanks[index] = action.payload;
            //     }
            // })
            .addCase(updateBankToBank.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteBankToBank.pending, (state) => {
                state.status = "loading";
            })
            // .addCase(deleteBankToBank.fulfilled, (state, action: PayloadAction<number>) => {
            //     state.status = "idle";
            //     state.bankToBanks = state.bankToBanks.filter(t => t.bankToBankId !== action.payload);
            // })
            .addCase(deleteBankToBank.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default BankToBankSlice.reducer;
