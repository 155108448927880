import { Link } from "react-router-dom";
import { MenuItem, SidebarData } from "../Sidebar/sidebardata";


function BreadCrumb({ path }: { path: any }) {

    const pathSegments: string[] = path.pathname.split('/').filter((segment: string) => segment);

    const findBreadcrumbName = (currentPath: string, menuItems: MenuItem[]): string | undefined => {
        for (const menuItem of menuItems) {
            if (menuItem.path === currentPath) return menuItem.title;
            if (menuItem.subItems) {
                for (const subItem of menuItem.subItems) {
                    if (subItem.path === currentPath) return subItem.title;
                    if (subItem.subSecondMenuItem) {
                        for (const subSecondItem of subItem.subSecondMenuItem) {
                            if (subSecondItem.path === currentPath) return subSecondItem.title;
                        }
                    }
                }
            }
        }
        return undefined;
    };
    

    const breadcrumbItems = pathSegments.map((_, index) => {
        const breadcrumbPath = `/${pathSegments.slice(0, index + 1).join('/')}`;
        const lastSegment = breadcrumbPath.split('/').pop();
        const segmentName = 
            findBreadcrumbName(breadcrumbPath, SidebarData) || 
            (lastSegment ? lastSegment.charAt(0).toUpperCase() + lastSegment.slice(1) : "Unknown");

        return {
            name: segmentName,
            path: breadcrumbPath,
        };
    });

    const isValidPath = (path: string, menuData: any[]): boolean => {
        for (const item of menuData) {
            if (item.path === path) return true; 
            if (item.subSecondMenuItem && isValidPath(path, item.subSecondMenuItem)) {
                return true;
            }
        }
        return false; 
    };
    return (
        <div className="fixed bg-white-bg w-[88%] 3xl:w-full md:w-[90%] py-2 md:py-2 z-40 dark:bg-dark_bg">
            <nav className="flex py-0.5" aria-label="Breadcrumb">
                <ol className="inline-flex items-center rtl:space-x-reverse">
                    {breadcrumbItems.map((item, index) => {
                        const validPath = item.path && isValidPath(item.path, SidebarData);
                        return (
                        <li key={index} className={` flex `}>
                            <div className={`flex items-center text-sm ${breadcrumbItems.length > 3 ? 'md:text-[10px]': ''}`}>
                                {index < breadcrumbItems.length - 1 ? (
                                    <>
                                    {
                                    (validPath)? 
                                        <Link to={item.path} className=" cursor-pointer  font-medium text-gray-700 hover:text-blue-600  dark:text-gray-400 dark:hover:text-white-bg">
                                            {item.name}
                                        </Link>
                                        :   <span className="cursor-pointer ms-1 font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                                        {item.name}
                                    </span>}
                                    
                                        <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                    </>
                                ) : (
                                    <span className="cursor-pointer ms-1 font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                                        {item.name}
                                    </span>
                                )}
                            </div>
                        </li>
                    )}
                    )}
                </ol>
            </nav>
        </div>
    )
}

export { BreadCrumb };