import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";




const GetAllFeeType = async () => {
    try {
        const response = await axiosInstance.get("FeeType");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostFeeType = async (data: any) => {
    try {
        const response = await axiosInstance.post("FeeType", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutFeeType = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`FeeType/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteFeeType = async (id: number) => {
    try {
        await axiosInstance.delete(`/FeeType/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const FeeTypeService = {
    GetAllFeeType,
    PostFeeType,
    PutFeeType,
    deleteFeeType
};


export default FeeTypeService;
