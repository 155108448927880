import axiosInstance from "../axiosInstance";

const GetLocationByPostCode = async (postcode: number | string) => {
    try {
        const response = await axiosInstance.get(`Location/${postcode}`);
        return response.data;
    } catch (error) {
        throw new Error(`Failed to fetch Country with ID ${postcode}: ${error}`);
    }
}

const AddressService = {

    GetLocationByPostCode,
};

export default AddressService;
