import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import OfficeBankAccountService from "../../../../services/Accounting/OfficeAccount/OfficeBankAccountService";

export interface OfficeBankAccount {
    clientBank: string;
    officeBankCloseBalance: number;
    officeBankOpenBalance: number;
    transactions: [];
    orderBy: string;
    currentPage: number;
    totalRows: number;  
};
// Define the state type for Checklist
type OfficeBankAccountState = {
    officeBankAccounts: OfficeBankAccount;
    status: "idle" | "loading" | "failed";
    error: string | null;
    receiptNumber : number | null;
    
};

// Initial state
const initialState: OfficeBankAccountState = {
    officeBankAccounts: null!,
    status: "idle",
    error: null,
    receiptNumber : null,
   
};


export const fetchNextReceiptNumber = createAsyncThunk(
    "fetchNextReceiptNumber",
    async (type: string, thunkAPI) => {
        try {
            const response = await OfficeBankAccountService.GetNextReceiptNumber(type);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);





// Async thunks for CRUD operations
export const fetchAllOfficeBankAccount = createAsyncThunk(
    "fetchAllOfficeBankAccount",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeBankAccountService.GetAllOfficeBankAccount(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const OfficeBankAccountSlice = createSlice({
    name: "nominalLedger",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchNextReceiptNumber.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchNextReceiptNumber.fulfilled, (state, action: PayloadAction<number>) => {
            state.status = "idle";
            state.receiptNumber = action.payload;
        })
        .addCase(fetchNextReceiptNumber.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })
       
        .addCase(fetchAllOfficeBankAccount.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllOfficeBankAccount.fulfilled, (state, action: PayloadAction<any>) => {
            state.status = "idle";
            state.officeBankAccounts = action.payload;
        })
        .addCase(fetchAllOfficeBankAccount.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })

        }
    });
    
    export default OfficeBankAccountSlice.reducer;