import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import FixedFeeInvoiceService from "../../../../../services/Accounting/OfficeAccount/OfficePosting/FixedFeeInvoiceService";





export interface FixedFeeInvoice {
    fixedFeeInvoiceId: number;
    invoiceNumber: number;
    totalAmount: number;
    vat: number;
    invoiceAmount: number;
    paymentTerms: number;
    invoiceeName: string;
    invoiceAddress: string;
    incomeAccountId: number;
    lineItems: OffAccFixedInvoiceLineItem[];
    notes: string;
    invoiceDate: Date;
    invoiceNotes: string;

};

export interface OffAccFixedInvoiceLineItem {
    invoiceLineId: number;
    invoiceItem: string;
    amount: number;
    vatRateId: number;
    vat: number;
    grossAmount: number;
    staffId: number;
    type: string;
  }

export interface FixedFeeInvoiceData {
    listingData: []
    page: number,
    totalRows: number
}
// Define the state type for Checklist
type FixedFeeInvoiceState = {
    fixedFeeInvoices: FixedFeeInvoice[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    fixedFeeInvoiceData: FixedFeeInvoiceData;
};

// Initial state
const initialState: FixedFeeInvoiceState = {
    fixedFeeInvoices: [],
    status: "idle",
    error: null,
    fixedFeeInvoiceData: null!,
};

// Async thunks for CRUD operations
export const fetchAllFixedFeeInvoice = createAsyncThunk(
    "fetchAllFixedFeeInvoice",
    async (_, thunkAPI) => {
        try {
            const response = await FixedFeeInvoiceService.GetAllFixedFeeInvoice();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addFixedFeeInvoice = createAsyncThunk(
    "addFixedFeeInvoice",
    async (data: any, thunkAPI) => {
        try {
            const response = await FixedFeeInvoiceService.PostFixedFeeInvoice(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateFixedFeeInvoice = createAsyncThunk(
    "updateFixedFeeInvoice",
    async ({ id, data }: { id: number, data: FixedFeeInvoice }, thunkAPI) => {
        try {
            const response = await FixedFeeInvoiceService.PutFixedFeeInvoice(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteFixedFeeInvoice = createAsyncThunk(
    "deleteFixedFeeInvoice",
    async (id: number, thunkAPI) => {
        try {
            await FixedFeeInvoiceService.deleteFixedFeeInvoice(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const FixedFeeInvoiceSlice = createSlice({
    name: "fixedFeeInvoice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllFixedFeeInvoice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllFixedFeeInvoice.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.fixedFeeInvoiceData = action.payload;
            })
            .addCase(fetchAllFixedFeeInvoice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addFixedFeeInvoice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addFixedFeeInvoice.fulfilled, (state, action: PayloadAction<FixedFeeInvoice>) => {
                state.status = "idle";
                state.fixedFeeInvoices.push(action.payload);
            })
            .addCase(addFixedFeeInvoice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateFixedFeeInvoice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateFixedFeeInvoice.fulfilled, (state, action: PayloadAction<FixedFeeInvoice>) => {
                state.status = "idle";
                const index = state.fixedFeeInvoices.findIndex(t => t.fixedFeeInvoiceId === action.payload.fixedFeeInvoiceId);
                if (index !== -1) {
                    state.fixedFeeInvoices[index] = action.payload;
                }
            })
            .addCase(updateFixedFeeInvoice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteFixedFeeInvoice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteFixedFeeInvoice.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.fixedFeeInvoices = state.fixedFeeInvoices.filter(t => t.fixedFeeInvoiceId !== action.payload);
            })
            .addCase(deleteFixedFeeInvoice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default FixedFeeInvoiceSlice.reducer;
