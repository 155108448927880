import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SurveyQuestionOrderService from "../../../../../services/HumanResources/Surveys/CreateSurvey/SurveyQuestionOrderService";
import { PaginationResponse } from "../../../../model/Pagination/paginationClass";

export interface SurveyQuestionOrder {
  surveyQuestionOrderId: number;
}
// Define the state type for Checklist
type SurveyQuestionOrderState = {
    surveyQuestionOrders: PaginationResponse<SurveyQuestionOrder> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: SurveyQuestionOrderState = {
    surveyQuestionOrders: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllSurveyQuestionOrder = createAsyncThunk(
    "fetchAllSurveyQuestionOrder",
    async (data: any, thunkAPI) => {
        try {
            const response = await SurveyQuestionOrderService.GetAllSurveyQuestionOrder(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addSurveyQuestionOrder = createAsyncThunk(
    "addSurveyQuestionOrder",
    async (data: any, thunkAPI) => {
        try {
            const response = await SurveyQuestionOrderService.PostSurveyQuestionOrder(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateSurveyQuestionOrder = createAsyncThunk(
    "updateSurveyQuestionOrder",
    async ({ id, data }: { id: number, data: SurveyQuestionOrder }, thunkAPI) => {
        try {
            const response = await SurveyQuestionOrderService.PutSurveyQuestionOrder(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteSurveyQuestionOrder = createAsyncThunk(
    "deleteSurveyQuestionOrder",
    async (id: number, thunkAPI) => {
        try {
            await SurveyQuestionOrderService.deleteSurveyQuestionOrder(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const SurveyQuestionOrderSlice = createSlice({
    name: "surveyQuestionOrder",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllSurveyQuestionOrder.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllSurveyQuestionOrder.fulfilled, (state, action: PayloadAction<PaginationResponse<SurveyQuestionOrder>>) => {
                state.status = "idle";
                state.surveyQuestionOrders = action.payload;
            })
            .addCase(fetchAllSurveyQuestionOrder.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addSurveyQuestionOrder.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addSurveyQuestionOrder.fulfilled, (state, action: PayloadAction<SurveyQuestionOrder>) => {
                state.status = "idle";
               // state.surveyQuestionOrders.push(action.payload);
            })
            .addCase(addSurveyQuestionOrder.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateSurveyQuestionOrder.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateSurveyQuestionOrder.fulfilled, (state, action: PayloadAction<SurveyQuestionOrder>) => {
                state.status = "idle";
                // const index = state.surveyQuestionOrders.findIndex(t => t.surveyQuestionOrderId === action.payload.surveyQuestionOrderId);
                // if (index !== -1) {
                //     state.surveyQuestionOrders[index] = action.payload;
                // }
            })
            .addCase(updateSurveyQuestionOrder.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteSurveyQuestionOrder.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteSurveyQuestionOrder.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.surveyQuestionOrders = state.surveyQuestionOrders.filter(t => t.surveyQuestionOrderId !== action.payload);
            })
            .addCase(deleteSurveyQuestionOrder.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default SurveyQuestionOrderSlice.reducer;
