import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";

const GetAllFinalize = async () => {
    try {
        const response = await axiosInstance.get("Finalize");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutFinalize = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`Finalize/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


// const deleteFinalize= async (id: number) => {
//     try {
//         await axiosInstance.delete(`/Finalize/${id}`);
//     } catch (error: any) {
//         let data = error as ErrorResult;
//         throw new Error(data.messages.toString());
//     }
// }

const FinalizeService = {
    GetAllFinalize,
    PutFinalize,
    // deleteAuthorisation
};


export default FinalizeService;