import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";



const GetAllTimeEntryType = async () => {
    try {
        const response = await axiosInstance.get("TimeEntryType");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostTimeEntryType = async (data: any) => {
    try {
        console.log(data);
        const response = await axiosInstance.post("TimeEntryType", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutTimeEntryType = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`TimeEntryType/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteTimeEntryType = async (id: number) => {
    try {
        await axiosInstance.delete(`TimeEntryType/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const TimeEntryTypeService = {
    GetAllTimeEntryType,
    PostTimeEntryType,
    PutTimeEntryType,
    deleteTimeEntryType
};


export default TimeEntryTypeService;
