import axios from 'axios';


import axiosInstance from "../axiosInstance";
import { ErrorResult } from '../../store/model/errorResult';



const fetchAllBranch = async () => {
    try {
        const response = await axiosInstance.get("Branch");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch branches:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch Branch");
    }
}

// Add a new branch
const addBranchData = async (data: any) => {
    try {
        const response = await axiosInstance.post("Branch", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Update a branch
const updateBranchData = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`Branch/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Delete a branch
const deleteBranch = async (branchId: number) => {
    try {
        await axiosInstance.delete(`/Branch/${branchId}`);
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.response?.data || error.message);
            throw new Error(`Failed to delete branch: ${error.response?.data?.message || error.message}`);
        } else {
            console.error('Unexpected error:', error);
            throw new Error('An unexpected error occurred');
        }
    }
}
const BranchManagementService = {
    fetchAllBranch,
    addBranchData,
    updateBranchData,
    deleteBranch,
};

export default BranchManagementService;
