import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { AddSuppliersBillEntry } from "./SuppliersBillEntryDetails/AddSuppliersBillEntry";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllSuppliersBillEntry } from "../../store/slice/Accounting/OfficeAccounts/Suppliers/SuppliersBillEntrySlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "flowbite-react";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
function SuppliersBillEntry() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const { suppliersBillEntries, status, error } = useSelector(
    (state: RootState) => state.suppliersBillEntry
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [suppliersBillEntryData, setSuppliersBillEntryData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentSuppliersBillEntry, setCurrentSuppliersBillEntry] = useState<
    any[]
  >([]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(null);
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(null);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
 

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      let data = {
        keyword: "",
      };
      dispatch(fetchAllSuppliersBillEntry(data))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }

    if (status === "failed" && !errorShownRef.current) {
      // toast.error(error);
      errorShownRef.current = true;
    }
  }, [dispatch,isOnAddedOrUpdate, status, error]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //     if(suppliersBillEntries!=null && suppliersBillEntries.length > 0){
  //         const filteredBySuppliersBillEntry = filterSuppliersBillEntryBySearchQuery();
  //         const indexOfLastSuppliersBillEntry = currentPage * itemsPerPage;
  //         const indexOfFirstSuppliersBillEntry = indexOfLastSuppliersBillEntry - itemsPerPage;
  //         const currentSuppliersBillEntry = filteredBySuppliersBillEntry.slice(indexOfFirstSuppliersBillEntry, indexOfLastSuppliersBillEntry);
  //         const totalPages = Math.ceil(suppliersBillEntries.length / itemsPerPage);
  //         setTotalPages(totalPages)
  //         setCurrentSuppliersBillEntry(currentSuppliersBillEntry)
  //     }

  // },[suppliersBillEntries])

  useEffect(() => {
    if (
      suppliersBillEntries != null &&
      suppliersBillEntries.listingData != null &&
      suppliersBillEntries.listingData.length > 0
    ) {
      const totalPages = suppliersBillEntries.totalRecords;
      setTotalPages(totalPages);
      setCurrentSuppliersBillEntry(suppliersBillEntries.listingData);
    }
  }, [suppliersBillEntries]);

  // const filterSuppliersBillEntryBySearchQuery = () => {

  //     return suppliersBillEntries.filter((suppliersBillEntry: any) =>
  //         suppliersBillEntry?.supplier?.toLowerCase().includes(searchQuery.toLowerCase()));
  // };

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setOpenModal(!openModal);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };
  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };
  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="OASupplierBillEntry"
        getData={suppliersBillEntryData}
        title="Supplier BillEntry "
      />
    );
  }

  if (openModal) {
    return (
      <AddSuppliersBillEntry
        openModal={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        isView={isView}
        suppliersBillEntry={suppliersBillEntryData}
      />
    );
  }

  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle
            title="Suppliers Bill Entry"
            buttonName="Bill Entry"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col lg:flex-col xl:flex-col justify-between my-5 gap-4 w-full md:gap-3">
        <div className="md:flex lg:flex xl:flex">
            <SearchInput title="Keyword" />
          </div>
          <div>
            <div className="grid grid-cols-2 gap-2 md:gap-1 w-full">
              <CheckBoxFilter title="Supplier" />
              <JustifyDateFilter
                title="Date Between"
                plannedStartDate={plannedStartDate}
                plannedEndDate={plannedEndDate}
                onPlannedStartDateChange={handleStartDateChange}
                onPlannedEndDateChange={handleEndDateChange}
              />
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto max-h-96">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Bill Entry Number
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Supplier
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Invoice Number
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Due Date
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Gross Amount
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  VAT
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Net Amount
                </th>
                <th scope="col" className="text-center px-3 py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentSuppliersBillEntry.map(
                (suppliersBillEntry: any, index) => (
                  <tr
                    key={index}
                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Bill{suppliersBillEntry.billEntryNumber}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {suppliersBillEntry.supplierName}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {suppliersBillEntry.temporaryInvoiceNumber}
                    </td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {formatDateOnlyDisplay(suppliersBillEntry.dueDate)}
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {formatCurrency(suppliersBillEntry.grossAmount)}
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {formatCurrency(suppliersBillEntry.vat)}
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {formatCurrency(suppliersBillEntry.netAmount)}
                    </td>
                    <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <div className="flex gap-3 w-full">
                        <FaEye className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setIsView(true);
                          setIsEdit(false);
                          setSuppliersBillEntryData(suppliersBillEntry);
                        }} 
                        />
                        <FaEdit className="fill-blue-500 w-6 h-6"
                        onClick={() => {
                          setOpenModal(true);
                          setIsEdit(true);
                          setSuppliersBillEntryData(suppliersBillEntry);
                        }}
                        />
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          </div>
          {currentSuppliersBillEntry.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">
                No supplier Bill Entry Details found.
              </p>
            </div>
          )}
        </div>
        {currentSuppliersBillEntry.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
      </div>
    </>
  );
}

export { SuppliersBillEntry };
