import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddBankToBank } from "../Modal/AddClientAccounts/AddClientPostings/AddBankToBank";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  deleteBankToBank,
  fetchAllBankToBank,
} from "../../store/slice/Accounting/ClientAccounts/ClientPosting/BankToBankSlice";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineRefresh } from "react-icons/md";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import DeleteModal from "../Modal/DeleteModal";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { ReverseMainDrawer } from "../Drawer/ReverseMainDrawer";
import { ViewMainDrawer } from "../Drawer/ViewMainDrawer";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";

function BankToBank() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const bankToBankGetData = useSelector(
    (state: RootState) => state.bankToBank.bankToBankGetData
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [bankToBankData, setBankToBankData] = useState<any>([]);
  const [currentBankToBank, setCurrentBankToBank] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [bankToBankId, setBankToBankId] = useState<number>(0);
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(null);
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(null);
  const [openReverseDrawer, setOpenReverseDrawer] = useState(false);
  const [isReverse, setIsReverse] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      dispatch(fetchAllBankToBank())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch,isOnAddedOrUpdate]);

  useEffect(() => {
    if (bankToBankGetData && bankToBankGetData.bankToBankResult) {
      setBankToBankData(bankToBankGetData.bankToBankResult);
    }
  }, [bankToBankGetData]);

  const filterBankToBankBySearchQuery = () => {
    return bankToBankData;
  };
  const onDeleteCall = () => {
    try {
      dispatch(deleteBankToBank(bankToBankId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted Bank to Bank Details");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  // useEffect(() => {
  //     if (bankToBankData != null && bankToBankData.length > 0) {
  //         const filteredByBankToBank = filterBankToBankBySearchQuery();
  //         const indexOfLastBankToBank = currentPage * itemsPerPage;
  //         const indexOfFirstBankToBank = indexOfLastBankToBank - itemsPerPage;
  //         const currentBankToBanks = filteredByBankToBank.slice(indexOfFirstBankToBank, indexOfLastBankToBank);
  //         const totalPages = Math.ceil(bankToBankData.length / itemsPerPage);
  //         setTotalPages(totalPages)
  //         setCurrentBankToBank(currentBankToBanks)
  //     }
  // }, [bankToBankData])

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setOpenModal(!openModal);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };

  const toggleReverseDrawer = (value: string) => {
    setIsReverse(false);
    setOpenReverseDrawer(!openReverseDrawer);
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  if (openModal) {
    return (
      <AddBankToBank
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        isView={isView}
        isReverse={false}
        bankToBank={bankToBankData}
      />
    );
  }

  if (openReverseDrawer) {
    return (
      <ReverseMainDrawer
        Close={toggleReverseDrawer}
        openCaseDrawer={openReverseDrawer}
        drawerContent="bankToBank"
        getData={bankToBankData}
      />
    );
  }

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="CPBankToBank"
        getData={bankToBankData}
        title="Bank To Bank"
      />
    );
  }
  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            onDeleteAccept={onDeleteCall}
            id={bankToBankId}
            title="Bank To Bank"
            message="Are you sure?"
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="Bank To Bank"
            buttonName="Bank to Bank"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col lg:flex-col xl:flex-col justify-between my-5 gap-4 w-full md:gap-3">
          <div className="md:flex lg:flex xl:flex">
            <SearchInput title="Keyword" />
          </div>
          <div>
            <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full">
              <CheckBoxFilter title="From Bank" />
              <CheckBoxFilter title="To Bank" />
              <JustifyDateFilter
                title="Date Between"
                plannedStartDate={plannedStartDate}
                plannedEndDate={plannedEndDate}
                onPlannedStartDateChange={handleStartDateChange}
                onPlannedEndDateChange={handleEndDateChange}
              />
            </div>
          </div>
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Transaction Ref
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Transaction Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Client and Case Details
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Transaction Details
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  From Bank
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  To Bank
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Amount
                </th>
                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="text-lg 3xl:text-xs">
              {bankToBankData.map((bankToBank: any, index: number) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    b2b{bankToBank.bankToBankTransactionId}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {
                     formatDateOnlyDisplay(bankToBank.transactionDate)   
                    }
                  </td>
                  <td className="flex flex-col px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <p>{bankToBank.individualNames}</p>
                   <p> {bankToBank.caseName}</p>
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {bankToBank.transactionDetails}
                  </td> 
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{bankToBank.fromBank}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{bankToBank.toBank}</td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"> {formatCurrency(bankToBank.amount)}</td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setIsView(true);
                          setIsEdit(false);
                          setBankToBankData(bankToBank);
                        }}
                      />
                      <FaEdit
                        className="fill-blue-500 w-6 h-6"
                        onClick={() => {
                          setOpenModal(true);
                          setIsEdit(true);
                          setBankToBankData(bankToBank);
                        }}
                      />
                      <RiDeleteBin5Fill
                        className="fill-red-600 w-6 h-6"
                        onClick={() => {
                          setOpenDelModal(true);
                          setBankToBankId(bankToBank.bankToBankTransactionId);
                        }}
                      />
                      {/* <MdOutlineRefresh className="fill-blue-800 w-6 h-6"
                       onClick={() => {
                        setOpenReverseDrawer(true);
                        setBankToBankData(bankToBank);
                      }}
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {bankToBankData.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Bank to Bank Payment found.</p>
            </div>
          )}
        </div>
        {bankToBankData.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={bankToBankData.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { BankToBank };
