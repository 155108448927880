import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AuthorisationService from "../../../../../services/Accounting/ClientAccounts/Invoice/AuthorisationService";
import SendDetailsService from "../../../../../services/Accounting/ClientAccounts/Invoice/SendDetailsService";



export interface SendDetails {
    sendDetailId: number;
    areasofPractice: string;
    caseType: string;
    caseSubType: string;
    dateBetween: string;
    keyword: string;


};
// Define the state type for Checklist
type SendDetailsState = {
    sendDetails: SendDetails[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

//Initial state
const initialState: SendDetailsState = {
    sendDetails: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllSendDetails = createAsyncThunk(
    "fetchAllsendDetails",
    async (_, thunkAPI) => {
        try {
            const response = await SendDetailsService.GetAllSendDetails();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateSendDetails= createAsyncThunk(
    "updateSendDetails",
    async ({ id, data }: { id: number, data:SendDetails }, thunkAPI) => {
        try {
            const response = await SendDetailsService.PutSendDetails(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteSendDetails = createAsyncThunk(
    "deleteSendDetails",
    async (id: number, thunkAPI) => {
        try {
            await SendDetailsService.deleteSendDetails(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


const SendDetailsSlice = createSlice({
    name: "sendDetail",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllSendDetails.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllSendDetails.fulfilled, (state, action: PayloadAction<SendDetails[]>) => {
                state.status = "idle";
                state.sendDetails = action.payload;
            })
            .addCase(fetchAllSendDetails.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateSendDetails.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateSendDetails.fulfilled, (state, action: PayloadAction<SendDetails>) => {
                state.status = "idle";
                const index = state.sendDetails.findIndex(t => t.sendDetailId === action.payload.sendDetailId);
                if (index !== -1) {
                    state.sendDetails[index] = action.payload;
                }
            })
            .addCase(updateSendDetails.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteSendDetails.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteSendDetails.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.sendDetails = state.sendDetails.filter(t => t.sendDetailId !== action.payload);
            })
            .addCase(deleteSendDetails.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });

        }

        });
 

export default SendDetailsSlice.reducer;
