import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import TaskRiskAssesmentService from "../../../../services/SystemMaintenance/TaskAssesment/TaskRiskAssesmentService";
import { RAQuestion, RiskAssesmentQuestion, TaskRiskAssesment, TaskRiskAssesmentNameData } from "../../../model/SystemMaintenance/TaskAssesment/TaskRiskAssesmentInterface";

// Define the state type for Checklist
type TaskRiskAssesmentState = {
    rAQuestion: RAQuestion;
    taskRiskAssesmentNameData: TaskRiskAssesmentNameData;
    taskRiskAssesment: TaskRiskAssesment[];
    riskAssesmentQuestion: RiskAssesmentQuestion[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    any: any;
};

// Initial state
const initialState: TaskRiskAssesmentState = {
    rAQuestion: null!,
    taskRiskAssesment: [],
    riskAssesmentQuestion: [],
    taskRiskAssesmentNameData: null!,
    status: "idle",
    error: null,
    any: []
};

// Async thunks for CRUD operations
export const fetchAllTaskRiskAssesment = createAsyncThunk(
    "fetchAllTaskRiskAssesment",
    async (_, thunkAPI) => {
        try {
            const response = await TaskRiskAssesmentService.GetAllTaskRiskAssesment();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchRiskAssesmentQuestion = createAsyncThunk(
    "fetchRiskAssesmentQuestion",
    async (data: any, thunkAPI) => {
        try {
            const response = await TaskRiskAssesmentService.GetRiskAssesmentQuestion(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addTaskRiskAssesment = createAsyncThunk(
    "addTaskRiskAssesment",
    async (data: any, thunkAPI) => {
        try {
            const response = await TaskRiskAssesmentService.PostTaskRiskAssesment(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addRiskAssesmentQuestion = createAsyncThunk(
    "addRiskAssesmentQuestion",
    async (data: any, thunkAPI) => {
        try {
            const response = await TaskRiskAssesmentService.PostRiskAssesmentQuestion(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateTaskRiskAssesment = createAsyncThunk(
    "updateTaskRiskAssesment",
    async ({ id, data }: { id: any; data: any }, thunkAPI) => {
        try {
            const response = await TaskRiskAssesmentService.PutTaskRiskAssesment(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteTaskRiskAssesment = createAsyncThunk(
    "deleteTaskRiskAssesment",
    async (id: number, thunkAPI) => {
        try {
            await TaskRiskAssesmentService.deleteTaskRiskAssesment(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const TaskRiskAssesmentSlice = createSlice({
    name: "taskRiskAssesment",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTaskRiskAssesment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllTaskRiskAssesment.fulfilled, (state, action: PayloadAction<TaskRiskAssesmentNameData>) => {
                state.status = "idle";
                state.taskRiskAssesmentNameData = action.payload;
            })
            .addCase(fetchAllTaskRiskAssesment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchRiskAssesmentQuestion.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchRiskAssesmentQuestion.fulfilled, (state, action: PayloadAction<RAQuestion>) => {
                state.status = "idle";
                state.rAQuestion = action.payload;
            })
            .addCase(fetchRiskAssesmentQuestion.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addTaskRiskAssesment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addTaskRiskAssesment.fulfilled, (state, action: PayloadAction<TaskRiskAssesment>) => {
                state.status = "idle";
                state.taskRiskAssesment.push(action.payload);
            })
            .addCase(addTaskRiskAssesment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addRiskAssesmentQuestion.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addRiskAssesmentQuestion.fulfilled, (state, action: PayloadAction<RiskAssesmentQuestion>) => {
                state.status = "idle";
                state.riskAssesmentQuestion.push(action.payload);
            })
            .addCase(addRiskAssesmentQuestion.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateTaskRiskAssesment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateTaskRiskAssesment.fulfilled, (state, action: PayloadAction<TaskRiskAssesment>) => {
                state.status = "idle";
                const index = state.taskRiskAssesment.findIndex(t => t.riskAssessmentNameId === action.payload.riskAssessmentNameId);
                if (index !== -1) {
                    state.taskRiskAssesment[index] = action.payload;
                }
            })
            .addCase(updateTaskRiskAssesment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteTaskRiskAssesment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteTaskRiskAssesment.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                // state.taskRiskAssesments = state.taskRiskAssesments.filter(t => t.taskRiskAssesmentId !== action.payload);
            })
            .addCase(deleteTaskRiskAssesment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default TaskRiskAssesmentSlice.reducer;
