import { Modal } from "flowbite-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number } from "yup";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { RootState } from "../../../store/store";
import { addBudget } from "../../../store/slice/Accounting/OfficeAccounts/BudgetSlice";
import { SelectBox } from "../../SelectBox";
import { InputBox } from "../../InputBox";
import { FaArrowRight } from "react-icons/fa";
import { NumericFormat } from "react-number-format";
import { fetchAllFinancialYear } from "../../../store/slice/Accounting/OfficeAccounts/GeneralSettings/FinancialYearSlice";
import { getValue } from "@testing-library/user-event/dist/utils";

interface RowData {
  account: string;
  values: number[];
  total: number;
}

const AddBudget = ({
  openModal,
  Close,
  isEdit,
  budget,
}: {
  openModal: boolean;
  Close: any;
  isEdit: boolean;
  budget: any;
}) => {
  const [title] = useState(!isEdit ? "Add Budget" : "Edit Budget");
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [isLoading, setIsLoading] = useState(false);
  const [setExistingBudget] = useState(budget);
  const dispatch = useAppDispatch();
  const { financialYears } = useSelector(
    (state: RootState) => state.financialYear
  );
  const [finacialYearList, setFinancialYearList] = useState<any[]>([]);
  const [initialLoading, setInitialLoading] = useState(true);

  const incomeNames = [
    "Advocacy",
    "Com - Purchase",
    "Com - Remortgage",
    "Com - Sale",
    "Com - Assignment Lease Purchase",
    "Com - Lending Service",
    "Com - Licence to Assign",
    "Com - New Lease Purchase",
    "Com - Off Licence",
    "Com - Assignment Lease Sale",
    "Com - New Lease Sale",
    "Criminal",
    "Disbursement",
    "Employment",
    "Family",
    "Immigration",
    "Interest Income",
    "Litigation",
    "Miscellaneous",
    "Personal Injury",
    "Re - Purchase",
    "RE - Remortgage",
    "Re - Sale",
    "Re-Registration",
    "Re-Transfer",
    "Wills and Probate" /* ... add up to 26 items here ... */,
  ];

  const expenseNames = [
    "Accountancy",
    "Advertising & Marketing",
    "Amortation of Goodwil",
    "Audit",
    "Bad Debts",
    "Bank Charges",
    "Bank Interest",
    "Bookkeeping",
    "Books, News Paper & Journals",
    "Building Insurance",
    "Cleaning",
    "Consultancy Fees",
    "Corporation Tax",
    "Credit Card Charges",
    "Deffered Tax",
    "Depreciation - Motor Vehicle",
    "Depreciation - Computers",
    "Depreciation - Fixtures & Fittings",
    "epreciation - Plant & Machiney",
    "Directors Remuneration",
    "Disburshment Expenses",
    "Dividend",
    "Donation",
    "DX Postage",
    "Electricity",
    "Employers NIC",
    "Entertainment - Client",
    "Entertainment - Staff",
    "Equipment Hire",
    "Franking Machine",
    "Gas",
    "General Insurance",
    "Indemnity Insurance",
    "Internet",
    "IT Software and Consumables",
    "Land Registry",
    "Legal Expenses",
    "Mobile",
    "Motor Expenses",
    "Office Equipment Expenses",
    "Operating Lease Payments",
    "Pension",
    "Photocopy",
    "Postage",
    "Practising certificate and other regulatory costs",
    "Printing",
    "Professional Fees",
    "Profit/loss on sale of Intangible FA",
    "Profit/loss on sale of Tangible FA",
    "Rates",
    "Rental",
    "Repairs & Maintenance",
    "Service Charges",
    "Shredding Costs",
    "Staff Salaries",
    "Staff Training and continuing professional development",
    "Staff Welfare",
    "Stationery",
    "Storage Expenses",
    "Subscriptions",
    "Subsistence",
    "Sundry Expenses",
    "Telephone",
    "Travelling",
    "Use of Home as Office",
    "Water" /* Add up to 65 items here */,
  ];
  const [incomeData, setIncomeData] = useState<RowData[]>(
    incomeNames.map((name) => ({
      account: name,
      values: Array(8).fill(0),
      total: 0,
    }))
  );

  const [expenseData, setExpenseData] = useState<RowData[]>(
    expenseNames.map((name) => ({
      account: name,
      values: Array(8).fill(0),
      total: 0,
    }))
  );

  const calculateColumnTotals = (data: RowData[]) =>
    data[0]?.values.map((_, colIndex) =>
      data.reduce((sum, row) => sum + row.values[colIndex], 0)
    ) || Array(8).fill(0);

  const incomeColumnTotals = calculateColumnTotals(incomeData);
  const expenseColumnTotals = calculateColumnTotals(expenseData);
  // Update individual cell values and recalculate totals
  const handleValueChange = (
    data: RowData[],
    setData: React.Dispatch<React.SetStateAction<RowData[]>>,
    rowIndex: number,
    colIndex: number,
    value: number
  ) => {
    const updatedData = data.map((row, i) => {
      if (i === rowIndex) {
        const updatedValues = [...row.values];
        updatedValues[colIndex] = value;
        const newTotal = updatedValues.reduce((acc, curr) => acc + curr, 0);
        return { ...row, values: updatedValues, total: newTotal };
      }
      return row;
    });
    setData(updatedData);
  };
  const validationSchema = object().shape({
    budgetName: string().required("Budget Name is required"),
    financialYear: number().required("Financial Year is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    const combinedData = {
      ...data,
      incomeData, // assuming incomeData is defined as state
      expenseData, // assuming expenseData is defined as state
    };

    console.log(
      "combinedData to be sent:",
      JSON.stringify(combinedData, null, 2)
    );

    setIsLoading(true);
    try {
      await dispatch(addBudget(combinedData)).unwrap();
      Close("success");
      toast.success("Successfully Added Budget");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      let data = {
        keyword: "",
      };
      dispatch(fetchAllFinancialYear(data))
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;

    }
  }, [dispatch]);

  useEffect(() => {
    if (
      financialYears != null &&
      financialYears.dataList != null &&
      financialYears.dataList.length > 0
    ) {
      let data = financialYears.dataList.map((item: any) => ({
        value: item.financialYearId,
        label: `${item.startDate.split("T")[0]} - ${
          item.endDate.split("T")[0]
        }`,
      }));
      setFinancialYearList(data);
      let findObj = financialYears.dataList.find((x) => x.financialYearId === 1);
      if(findObj && findObj!==null) {
        setValue("financialYear", findObj?.financialYearId)
      }
    }
  }, [financialYears]);

  useEffect(() => {
    if (isEdit && budget) {
      setExistingBudget(budget);
      reset(budget);
    }
  }, [budget, isEdit, reset, setValue]);

  const [isDisabled, setIsDisabled] = useState(true);

  return (
    <>
      {openModal !== false && (
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className="mx-auto my-5"
        >
          <div className="">
            <div className="flex flex-row md:flex-col justify-between mb-5 gap-2 w-full md:gap-3">
              <div className=""></div>
              <div className="">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      Close();
                    }}
                    className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="grid grid-cols-4 lg:grid-cols-2 mb-5 gap-2 w-full md:gap-3">
              <div className="col-start-1">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Budget Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.budgetName ? "is-invalid" : ""
                  }`}
                  {...register("budgetName")}
                  placeholder="Enter Budget Name"
                />
                <div className="font-medium mt-2 text-xs text-red-600">
                  {errors?.budgetName?.message?.toString()}
                </div>
              </div>
              <div className="col-start-4 lg:col-start-2">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Financial Year <span className="text-red-500">*</span>
                </label>
                <SelectBox
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  isSearchable={true}
                  options={finacialYearList}
                  value={getValues().financialYear}
                  placeholder="Select"
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setValue("financialYear", selectedOption);
                    }
                  }}
                />
                <div className="font-medium mt-2 text-xs text-red-600">
                  {errors?.financialYear?.message?.toString()}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="relative overflow-x-auto sm:rounded-lg my-5">
              <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <th scope="col" className="p-3">
                      Account
                    </th>
                    <th scope="col" className="p-3">
                      Feb-24
                    </th>
                    <th scope="col" className="p-3"></th>
                    <th scope="col" className="p-3">
                      Mar-24
                    </th>
                    <th scope="col" className="p-3">
                      Apr-24
                    </th>
                    <th scope="col" className="p-3">
                      May-24
                    </th>
                    <th scope="col" className="p-3">
                      Jun-24
                    </th>
                    <th scope="col" className="p-3">
                      Jul-24
                    </th>
                    <th scope="col" className="p-3">
                      Aug-24
                    </th>
                    <th scope="col" className="p-3">
                      Sep-24
                    </th>
                    <th scope="col" className="p-3">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="colspan-9 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Income
                  </tr>
                  {incomeData.map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      <td className="w-32 p-3">{row.account}</td>
                      {row.values.map((value, colIndex) => (
                        <>
                          <td key={colIndex} className="w-32 p-3">
                            <NumericFormat
                              //displayType="text"
                              // thousandSeparator={true}
                              decimalScale={2}
                              value={value}
                              onChange={(e) =>
                                handleValueChange(
                                  incomeData,
                                  setIncomeData,
                                  rowIndex,
                                  colIndex,
                                  Number(e.target.value)
                                )
                              }
                              className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                            />
                          </td>
                          {/* Add arrow icon after the first input field */}
                          {colIndex === 0 && (
                            <td className="">
                              <FaArrowRight className="text-center" />
                            </td>
                          )}
                        </>
                      ))}
                      <td className="w-32 p-3">{row.total}</td>
                    </tr>
                  ))}
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <td className="w-32 p-3">Column Totals</td>
                    {incomeColumnTotals.map((colTotal, colIndex) => (
                      <>
                        <td key={colIndex} className="w-32 p-3">
                          {colTotal}
                        </td>
                        {colIndex === 0 && (
                          <td className="">
                            <FaArrowRight className="text-center" />
                          </td>
                        )}
                      </>
                    ))}
                    <td className="w-32 p-3">
                      {incomeColumnTotals.reduce((acc, curr) => acc + curr, 0)}
                    </td>
                  </tr>
                  <tr className="colspan-9 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    Expenses
                  </tr>
                  {/* Expense Data Rows */}
                  {expenseData.map((row, rowIndex) => (
                    <tr
                      key={`expense-${rowIndex}`}
                      className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      <td className="w-32 p-3">{row.account}</td>
                      {row.values.map((value, colIndex) => (
                        <>
                          <td key={colIndex} className="p-3">
                            <NumericFormat
                              decimalScale={2}
                              value={value}
                              onChange={(e) =>
                                handleValueChange(
                                  expenseData,
                                  setExpenseData,
                                  rowIndex,
                                  colIndex,
                                  Number(e.target.value)
                                )
                              }
                              className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                            />
                          </td>
                          {colIndex === 0 && (
                            <td className="p-3">
                              <FaArrowRight className="text-center" />
                            </td>
                          )}
                        </>
                      ))}
                      <td className="p-3">{row.total}</td>
                    </tr>
                  ))}
                  {/* Expense Column Totals */}
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <td className="w-32 p-3">Expense Column Totals</td>
                    {expenseColumnTotals.map((colTotal, colIndex) => (
                      <>
                        <td key={colIndex} className="w-32 p-3">
                          {colTotal}
                        </td>
                        {colIndex === 0 && (
                          <td className="">
                            <FaArrowRight className="text-center" />
                          </td>
                        )}
                      </>
                    ))}
                    <td className="p-3">
                      {expenseColumnTotals.reduce((acc, curr) => acc + curr, 0)}
                    </td>
                  </tr>

                  {/* Grand Total */}
                  {/* <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
            <td  className="p-3">Grand Total</td>
            {expenseColumnTotals.map((_, colIndex) => (
              <td key={colIndex} className="w-32 p-3">
                {expenseColumnTotals[colIndex] + expenseColumnTotals[colIndex]}
              </td>
            ))}
            <td  className="p-3">
              {expenseColumnTotals.reduce((acc, curr) => acc + curr, 0) +
               expenseColumnTotals.reduce((acc, curr) => acc + curr, 0)}
            </td>
          </tr> */}
                </tbody>
              </table>
            </div>
          </div>

          {/* <div className="mt-4">
              <div className="relative overflow-x-auto sm:rounded-lg my-5">
                <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                  <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <th scope="col" className="w-32 p-3">
                      Account
                      </th>
                      <th scope="col" className="p-3">
                      Jan-20
                      </th>
                      <th scope="col" className="p-3">
                      
                      </th>
                      <th scope="col" className="p-3">
                      Feb-20
                      </th>
                      <th scope="col" className="p-3">
                      Mar-20
                      </th>
                      <th scope="col" className="p-3">
                      Apr-20
                      </th>
                      <th scope="col" className="p-3">
                      May-20
                      </th>
                      <th scope="col" className="p-3">
                      Jun-20
                      </th>
                      <th scope="col" className="p-3">
                      Jul-20
                      </th>
                      <th scope="col" className="p-3">
                      Aug-20
                      </th>
                      <th scope="col" className="p-3">
                      Sep-20
                      </th>
                      <th scope="col" className="p-3">
                      Oct-20
                      </th>
                      <th scope="col" className="p-3">
                      Nov-20
                      </th>
                      <th scope="col" className="p-3">
                      Dec-20
                      </th>
                      <th scope="col" className="p-3">
                      Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td colSpan={14} className="w-32 p-3">Income</td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Com - Purchase
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Com - Remortgage
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Com - Sale
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                        Com- Assignment Lease Purchase
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Com- Lending Service
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Com- Licence to Assign
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Com- New Lease Purchase
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Com- Off Licence
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Com-Assignment Lease Sale
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Com-New Lease Sale
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Criminal
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>

                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35 bg-sky-50">
                      <td className="p-3"></td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="p-3">
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td colSpan={14} className="w-32 p-3"> Expenses</td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                        Accountancy
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Advertising & Marketing
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Amortation of Goodwill
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Audit
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Bad Debts
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Bank Charges
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Bank Interest
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Bookkeeping
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Books, News Paper & Journals
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Building Insurance
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Building Insurance
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>
                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <td className="w-32 p-3">
                      Consultancy Fees
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className='p-3'>
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="p-3">
                        <InputBox dataType="text" placeholder="" />
                      </td>
                      <td className="text-right p-3">
                      0.00
                      </td>
                    </tr>



                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35 bg-sky-50">
                      <td className="p-3"></td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="p-3">
                      <FaArrowRight className='text-center'/>
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                      <td className="text-right p-3">
                      0.00	
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              
                </div> */}

          <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
            <div className="flex flex-row gap-2 items-end justify-end w-full">
              <button
                type="button"
                onClick={() => {
                  Close();
                }}
                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]  ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white-bg mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

{
  /* </Modal.Body>
        </Modal>
    );
} */
}

export { AddBudget };
