import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";




const GetAllChartOfAccount = async (data : any) => {
    try {
        const response = await axiosInstance.get("ChartOfAccount", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostChartOfAccount = async (data: any) => {
    try {
        const response = await axiosInstance.post("OfficeAccount/chartOfAccount", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutChartOfAccount = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`ChartOfAccount/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteChartOfAccount = async (id: number) => {
    try {
        await axiosInstance.delete(`/ChartOfAccount/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const ChartOfAccountService = {
    GetAllChartOfAccount,
    PostChartOfAccount,
    PutChartOfAccount,
    deleteChartOfAccount
};


export default ChartOfAccountService;
