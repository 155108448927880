import { object, string, boolean } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FiRefreshCcw } from "react-icons/fi";
import { generateApiKeyInBrowser } from "../../../utils/generate-key.utilis";
import { HiOutlineUpload } from "react-icons/hi";
import { useEffect, useRef, useState } from "react";
import { BsPersonCircle } from "react-icons/bs";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";

function AddUserStepTwo({ onSubmitStep, initialData, onPreviousStep }: any) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const userByIdData = useSelector((state: RootState) => state.userManage.userByIdData);
  const validationSchema = object().shape({
    signature: string(),
    signatureText: string(),
    accessToken: string(),
    isAccessTokenActive: boolean(),
   notes: string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...initialData, isAccessTokenActive: true },
  });

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };
  const handleFormSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    onSubmitStep(data);
  };

  const generateToken = () => {
    let value = generateApiKeyInBrowser();
    setValue("accessToken", value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedImageFile(file);
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (userByIdData !== null) {
      setValue("signature", ( userByIdData?.signature)? userByIdData?.signature: '');
      setValue("signatureText", userByIdData?.signatureText);
      setValue("accessToken", userByIdData?.accessToken);
      setValue("isAccessTokenActive", userByIdData?.isAccessTokenActive);
      setValue("notes", (userByIdData?.notes)? userByIdData?.notes: '');
    }
  }, [userByIdData])

  return (
    <form onSubmit={handleSubmit(handleFormSubmit, onError)}>
      <div className="grid grid-cols-2 gap-5">
        <div>
          <h3 className="text-lg font-bold text-left text-gray-900 dark:text-white-bg mb-5">
            Electronic Signature
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
            <div className="grid grid-cols-2 gap-x-3">
              <div
                data-hs-file-upload='{
                  "url": "/upload",
                  "acceptedFiles": "image/*",
                  "maxFiles": 1,
                  "singleton": true
                }'
              >
                <template data-hs-file-upload-preview="">
                  <div className="size-20">
                    <img
                      className="w-full object-contain rounded-full"
                      data-dz-thumbnail=""
                    />
                  </div>
                </template>

                <div className="block items-center justify-center space-y-5 sm:space-x-5">
                  <div className="group" data-hs-file-upload-previews="">
                    <span className="group-has-[div]:hidden flex shrink-0 justify-center items-center w-full h-24 border-2 border-dotted border-gray-300 text-gray-400 cursor-pointer hover:bg-gray-50 dark:border-neutral-700 dark:text-neutral-600 dark:hover:bg-neutral-700/50">
                      <BsPersonCircle className="size-7" />
                    </span>
                  </div>

                  <div className="flex flex-col justify-center items-start gap-2 w-full sm:w-auto">
                    <button
                      type="button"
                      onClick={() => fileInputRef.current?.click()}
                      className="flex items-center text-xs bg-blue-500 text-white-bg p-2 rounded hover:bg-blue-600 transition duration-300"
                      data-hs-file-upload-trigger=""
                    >
                      <HiOutlineUpload className="size-4" />
                      Upload photo
                    </button>

                    <input
                      id="dropzone-file"
                      ref={fileInputRef}
                      type="file"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-grow">
                <textarea
                  className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Signature"
                  {...register("signatureText")}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">
            API Access Token
          </h3>
          <div className="grid grid-cols-2 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
            <div>
              <div className="flex flex-row space-x-5">
                <label className="text-sm font-medium text-gray-900 dark:text-white-bg">
                  Is Active
                </label>
                <div>
                  <input
                    type="radio"
                    value="true"
                    checked={watch("isAccessTokenActive") === true}
                    onChange={() => setValue("isAccessTokenActive", true)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="false"
                    checked={watch("isAccessTokenActive") === false}
                    onChange={() => setValue("isAccessTokenActive", false)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-5 space-y-1">
              <div className="flex items-center gap-3">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Token
                </label>
                <button
                  type="button"
                  onClick={generateToken}
                  className="flex items-center text-xs bg-blue-500 text-white-bg mt-2 px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                >
                  <FiRefreshCcw className="mr-2 h-4 w-4" /> Generate Token
                </button>
              </div>
              <textarea
                className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                placeholder="Write your thoughts here..."
                {...register("accessToken")}
              ></textarea>
              <div className="mt-2 text-xs text-gray-500">
                {" "}
                Leave token blank if dont want to change{" "}
              </div>
              <div>

              </div>
            </div>

          </div>
        </div>
        {/* <div>
          <h3 className="text-lg font-bold text-left text-gray-900 dark:text-white-bg mb-5">
            Notes
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
            <div>
              <textarea
                className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Write your thoughts here..."
                {...register("notes")}
              ></textarea>
            </div>
          </div>
        </div> */}
      </div>



      <div className="">
        <div className="flex flex-row gap-2 items-start justify-between w-full">
          <button
            onClick={onPreviousStep}
            type="button"
            className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[140px]"
          >
            <svg
              className="w-6 h-6 fill-white-bg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
            Previous
          </button>
          <button
            type="submit"
            className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]"
          >
            Next
            <svg
              className="w-6 h-6 fill-white-bg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" />
            </svg>
          </button>
        </div>
      </div>
    </form>
  );
}

export { AddUserStepTwo };
