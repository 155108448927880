import axiosInstance from '../axiosInstance';
import { ErrorResult } from '../../store/model/errorResult';


const GetAllCaseSubType = async () => {
    try {
        const response = await axiosInstance.get("CaseSubType");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch CaseSubType:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch CaseSubType");
    }
}

// Add a new CaseSubType
const PostCaseSubType = async (data: any) => {
    try {
        const response = await axiosInstance.post("CaseSubType", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Update a CaseSubType
const PutCaseSubType = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`CaseSubType/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Delete a CaseSubType
const deleteCaseSubType = async (caseSubTypeId: number) => {
    try {
        await axiosInstance.delete(`CaseSubType/${caseSubTypeId}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}
const CaseSubTypeService = {
    GetAllCaseSubType,
    PostCaseSubType,
    PutCaseSubType,
    deleteCaseSubType,
};

export default CaseSubTypeService;
