import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import OfficeNominalLedgerReportService from "../../../../../services/Accounting/OfficeAccount/OfficeReports/OfficeNominalLedgerReportService";


export interface OfficeNominalLedgerReport {
    
};
// Define the state type for Checklist
type OfficeNominalLedgerReportState = {
    officeNominalLedgerReports: OfficeNominalLedgerReport[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: OfficeNominalLedgerReportState = {
    officeNominalLedgerReports: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllOfficeNominalLedgerReport = createAsyncThunk(
    "fetchAllOfficeNominalLedgerReport",
    async (data: any, thunkAPI) => {
        try {
            const response = await OfficeNominalLedgerReportService.GetAllOfficeNominalLedgerReport(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const OfficeNominalLedgerReportSlice = createSlice({
    name: "officeNominalLedgerReport",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllOfficeNominalLedgerReport.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllOfficeNominalLedgerReport.fulfilled, (state, action: PayloadAction<OfficeNominalLedgerReport[]>) => {
                state.status = "idle";
                state.officeNominalLedgerReports = action.payload;
            })
            .addCase(fetchAllOfficeNominalLedgerReport.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
           
    }
});

export default OfficeNominalLedgerReportSlice.reducer;
