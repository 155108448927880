import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import OfficeBankService from "../../../../services/Accounting/ClientAccounts/OfficeBankServices";


export interface OfficeBank {
    bankAccountId : number;
    firstName: string;
    bankAccountType: string;
    institution: string;
    isDefaultAccount: boolean;
};
// Define the state type for Checklist
type OfficeBankState = {
    officeBanks : OfficeBank[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: OfficeBankState = {
    officeBanks: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllOfficeBank = createAsyncThunk(
    "fetchAllOfficeBank",
    async (_, thunkAPI) => {
        try {
            const response = await OfficeBankService.GetAllOfficeBank();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const OfficeBankSlice = createSlice({
    name: "officeBank",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllOfficeBank.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllOfficeBank.fulfilled, (state, action: PayloadAction<any[]>) => {
            state.status = "idle";
            state.officeBanks = action.payload;
        })
        .addCase(fetchAllOfficeBank.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })

    }
});

export default OfficeBankSlice.reducer;