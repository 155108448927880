import { ErrorResult } from '../../../store/model/errorResult';
import axiosInstance from '../../axiosInstance';



const GetAllQuotationRequestStatusType = async () => {
    try {
        const response = await axiosInstance.get("QuotationRequestStatusType");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const QuotationRequestStatusTypeService = {
    GetAllQuotationRequestStatusType,

};

export default QuotationRequestStatusTypeService;
