
import axiosInstance from '../axiosInstance';




const GetAllDocumentSource = async () => {
    try {
        const response = await axiosInstance.get("DocumentSource");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch DocumentSource:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch DocumentSource");
    }
}

// Add a new DocumentFormat
// async PostDocumentFormat(data: any) {
//     try {
//         const response = await axiosInstance.post("DocumentFormat", data);
//         return response.data;
//     } catch (error) {
//         if (axios.isAxiosError(error)) {
//             // Axios-specific error handling
//             console.log('Axios error:', error.response?.data || error.message);
//             throw new Error(`Failed to add DocumentFormat: ${error.response?.data?.message || error.message}`);
//         } else if (error instanceof Error) {
//             // Handle standard JavaScript errors
//             console.error('Standard JavaScript error:', error.message);
//             throw new Error('An unexpected error occurred: ' + error.message);
//         } else {
//             // Handle unexpected non-standard errors
//             console.error('Unexpected error:', error);
//             throw new Error('An unexpected error occurred');
//         }
//     }
// }

// Update a DocumentFormat
// async PutDocumentFormat(id: number, data: any) {
//     try {
//         const response = await axiosInstance.put(`DocumentFormat/${id}`, data);
//         return response.data;
//     } catch (error) {
//         if (axios.isAxiosError(error)) {
//             console.error('Axios error:', error.response?.data || error.message);
//             throw new Error(`Failed to update DocumentFormat: ${error.response?.data?.message || error.message}`);
//         } else {
//             console.error('Unexpected error:', error);
//             throw new Error('An unexpected error occurred');
//         }
//     }
// }

// Delete a DocumentFormat
// async deleteDocument(documentFormatId: number): Promise<void> {
//     try {
//         await axiosInstance.delete(`/DocumentFormat/${documentFormatId}`);
//     } catch (error) {
//         if (axios.isAxiosError(error)) {
//             console.error('Axios error:', error.response?.data || error.message);
//             throw new Error(`Failed to delete DocumentFormat: ${error.response?.data?.message || error.message}`);
//         } else {
//             console.error('Unexpected error:', error);
//             throw new Error('An unexpected error occurred');
//         }
//     }
// }
const DocumentSourceService = {
    GetAllDocumentSource,
};

export default DocumentSourceService;
