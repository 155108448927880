import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AgedCreditorsReportService from "../../../../../services/Accounting/OfficeAccount/OfficeReports/AgedCreditorsReportService";



export interface AgedCreditorSearchData {
    asDate:string
    period: string
    supplier: string   
};

export interface AgedCreditorsData {
    period: string
    agedCreditors: []  
};

export interface AgedCreditorsReport {
    agedCreditorSearchData:AgedCreditorSearchData
    agedCreditorsData: AgedCreditorsData[]
};
// Define the state type for Checklist
type AgedCreditorsReportState = {
    agedCreditorsReports: AgedCreditorsReport;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: AgedCreditorsReportState = {
    agedCreditorsReports: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllAgedCreditorsReport = createAsyncThunk(
    "fetchAllAgedCreditorsReport",
    async (data: any, thunkAPI) => {
        try {
            const response = await AgedCreditorsReportService.GetAllAgedCreditorsReport(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const AgedCreditorsReportSlice = createSlice({
    name: "agedCreditorsReport",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllAgedCreditorsReport.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllAgedCreditorsReport.fulfilled, (state, action: PayloadAction<AgedCreditorsReport>) => {
                state.status = "idle";
                state.agedCreditorsReports = action.payload;
            })
            .addCase(fetchAllAgedCreditorsReport.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
           
    }
});

export default AgedCreditorsReportSlice.reducer;
