import { Modal } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { SearchInput } from "../../Filters/SearchInput";
import { fetchAllCase } from "../../../store/slice/CaseManagement/Case/CaseManagementSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { MainAnimation } from "../../loadingAnimation/MainAnimation";
import { Pagination } from "../../Pagination";

function SearchCaseReferenceDetails({ openDrawer, Close, onCaseSelectDetail }: { openDrawer: boolean, Close: any, onCaseSelectDetail: (cases: any) => void }) {


  const dispatch = useAppDispatch();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [initialLoading, setInitialLoading] = useState(true);
  const caseDetails = useSelector((state: RootState) => state.case.caseDetailsLists);
  const [caseDetailsLists, setCaseDetailsLists] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [selectedCase, setSelectedCase] = useState<any[]>([]);

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchAllCase())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (caseDetails != null && caseDetails.caseDetailsList.length > 0) {
      setCaseDetailsLists(caseDetails.caseDetailsList);
    }
  }, [caseDetails])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filterCasesBySearchQuery = () => {
    return caseDetailsLists.filter(
      (item: any) =>
        item.caseName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.caseType.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const filteredByCase = filterCasesBySearchQuery();

  // pagination
  const indexOfLastCase = currentPage * itemsPerPage;
  const indexOfFirstCase = indexOfLastCase - itemsPerPage;
  const currentCase = filteredByCase.slice(indexOfFirstCase, indexOfLastCase);

  const totalPages = Math.ceil(caseDetailsLists.length / itemsPerPage);

  const handleCaseSelection = (client: any) => {
    setSelectedCase(client);
  };

  const handleSelectCaseClick = () => {
    if (selectedCase) {
        onCaseSelectDetail(selectedCase);
      Close();
    }
  };

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        Close();
      }}
      initialFocus={emailInputRef}
    >
      <Modal.Header className="bg-white-bg p-5">
        <span className="text-blue_gray-900 text-xl w-auto">
          Case Refrence
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg">
        <div className="space-y-6 w-full">
          <div className="relative overflow-x-auto sm:rounded-lg">
            <div className="flex mb-5">
              <SearchInput title={"Case Number / Keyword"} onChange={handleSearchChange} />
            </div>
            {initialLoading ? (<MainAnimation />) : ""}
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
              <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3">
                    Case Reference
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Case Details
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentCase && currentCase.map((caseList: any, index: number) =>
                  <tr
                    key={index}
                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    <td className="px-6 py-3">
                      <input
                        type="radio" name="selectedCase" onChange={() => handleCaseSelection(caseList)}
                      />
                    </td>
                    <td className="px-6 py-3">
                      {caseList.caseReferenceAuto}
                    </td>
                    <td className="px-6 py-3">
                      <p>{caseList.caseType}</p>
                      <p>{caseList.caseClients}</p>
                      <p>{caseList.caseDetailsText}</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="w-full flex flex-col">
            {/* <Pagination
              currentPage={currentPage}
              // totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            /> */}
          </div>
          <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
            <div className="flex flex-row gap-2 items-end justify-end w-full">
              <button
                onClick={() => {
                  Close();
                }}
                type="button"
                className="cursor-pointer h-10 bg-white-A700 rounded-lg text-center border border-deep_purple-A400 text-deep_purple-A400 text-xs w-[140px] hover:text-white-A700 hover:bg-deep_purple-A400"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSelectCaseClick}
                className="bg-green-700 text-white-bg cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center border border-deep_purple-A400 text-white-A700 text-sm w-[120px] hover:text-deep_purple-A400 hover:bg-white-A700"
              >
                Select Case
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export { SearchCaseReferenceDetails };
