import { Modal } from "flowbite-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, boolean } from "yup";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { useNavigate } from "react-router-dom";
import { addDocumentCategory } from "../../../store/slice/SystemMaintenance/DocumentCategorySlice";
import { toast } from "react-toastify";

function AddDocCategory({
  openDrawer,
  Close,
  isEdit,
  docCategory,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  docCategory?: any;
}) {
  const [title] = useState(
    !isEdit ? "Add New Document Category" : "Edit Document Category"
  );
  const [openModal, setOpenModal] = useState(true);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [existingDocCategory, setExistingDocCategory] = useState(docCategory);

  const validationSchema = object().shape({
    documentCategory: string().required("document Category is required"),
    isActive: boolean(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      isActive: true,
    },
  });

  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    dispatch(addDocumentCategory(data))
      .unwrap()
      .then(() => {
        Close();
        navigate("/systemmanagement/systemmaintenance/documentcategorys");
        toast.success("Successfully Added Document Category");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    if (isEdit && docCategory) {
      setExistingDocCategory(docCategory);
      reset(docCategory);
      // setValue('isActive', caseType.isActive === 1 ? 'true' : 'false');
    }
  }, [docCategory, isEdit, reset, setValue]);

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        setOpenModal(Close);
      }}
      initialFocus={emailInputRef}
      size="xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="dark:text-white-bg text-xl w-auto">
          {title}
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
          <div className="space-y-3 w-full">
            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Category
              </label>
              <input
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} placeholder="Enter Catergory"
                {...register("documentCategory")}
              />
            </div>
            <div className="relative max-w-full flex gap-6">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Is Active
              </label>
              <div className="flex gap-5">
                <div>
                  <input
                    type="radio"
                    value="true"
                    checked={watch("isActive") === true}
                    onChange={() => setValue("isActive", true)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    id="bordered-radio-1"
                    type="radio"
                    value="false"
                    checked={watch("isActive") === false}
                    onChange={() => setValue("isActive", false)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  type="button"
                  onClick={() => {
                    reset();
                    Close();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddDocCategory };
