import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number, lazy } from "yup";
import { useEffect, useRef, useState } from "react";
import { SelectBox } from "../../../../SelectBox";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import {
  addClientToOffice,
  addReverseClientToOffice,
  fetchAllClientToOfficeInvoices,
} from "../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/ClientToOfficeSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { FaEdit, FaLock, FaLockOpen, FaSave } from "react-icons/fa";
import { formatDateISODisplay } from "../../../../../utils/date-time.utils";
import { NumericFormatWrapper } from "../../../../InputComponents/NumericFormatWrapper";
import { fetchNextReverseNumber } from "../../../../../store/slice/Accounting/ClientAccounts/ClientAccountSlice";
import { ClientAccountConstant } from "../../../../../store/model/Accounting/ClientAccount/ClientAccountConst";

function AddClientToOffice({
  openDrawer,
  Close,
  isView,
  isReverse,
  clientToOffice,
}: {
  openDrawer: boolean;
  Close: any;
  isView: boolean;
  isReverse: boolean;
  clientToOffice: any;
}) {
  const [title] = useState(!isView ? "Add Bill Payment" : "Edit Bill Payment");
  const dispatch = useAppDispatch();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [initialLoading, setInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [existingClientToOffice, setExistingClientToOffice] =
    useState(clientToOffice);

  const invoiceDataC2O = useSelector(
    (state: RootState) => state.clientToOffice.invoicesC2O
  );
  const [invoicesc2o, setInvoicesc2o] = useState<any[]>([]);
  const { clientPaymentTypes } = useSelector(
    (state: RootState) => state.clientPaymentType
  );
  const [clientPaymentTypeLists, setclientPaymentTypeLists] = useState<any[]>(
    []
  );
  const { authourisedClients } = useSelector(
    (state: RootState) => state.authorisedClient
  );
  const [authorisedClientLists, setAuthorisedClientLists] = useState<any[]>([]);
  const { clientAccounts } = useSelector(
    (state: RootState) => state.clientAccount
  );
  const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
  const { officeBanks } = useSelector((state: RootState) => state.officeBank);
  const [officeBankLists, setOfficeBankLists] = useState<any[]>([]);
  const [amounts, setAmounts] = useState<number[]>([]);
  const [editRowIndex, setEditRowIndex] = useState<number | null>(null);
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  const [isReverseDisabled, setIsReverseDisabled] = useState(true);
  const reverseReceiptNumber = useSelector(
    (state: RootState) => state.clientAccount.reverseReceiptNumber
  );

  const [isDisabled, setIsDisabled] = useState(true);

  const validationSchema = object().shape({
    clientBankId: number().required("Client Bank Account is required"),
    transactionDate: string()
      .required("Transaction Date is required")
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split("T")[0];
      }),

    officeBankId: number().required("Office Bank is required"),
    comments: string(),

    referenceNumber: string(),
    transferMethod: string().required("Transfer Method is required"),

    //   reverseNumber: lazy((value: any) =>
    //     isReverse
    //   ? number().required("Reverse Reference is required")
    //   : number().notRequired()
    // ),
    // reverseReference: lazy((value: any) =>
    //  string()
    // ),
    // reason: lazy((value: any) => string(),
    // )
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      transactionDate: new Date().toISOString().split("T")[0],
    },
  });

  const [formData, setFormData] = useState<any>({
    reverseReference: "",
    reverseNumber: "",
    reason: "",
  });

  // Handle change in input fields
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleReverseClick = async () => {
    if (isReverse) {
      // Check if reverseNumber is empty or only contains whitespace
      if (!formData.reverseNumber || !formData.reverseNumber.value.trim()) {
        toast.error("Reverse Receipt Number is required.");
        // console.log(formData.reverseNumber)
      } else {
        // console.log(formData);
        try {
          setIsLoading(true);
          await dispatch(addReverseClientToOffice(formData)).unwrap();

          toast.success(
            "Successfully saved reverse of Client To Office details"
          );
        } catch (error: any) {
          toast.error(error.toString());
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    setValue("transactionDate", new Date().toISOString().split("T")[0]);
  }, []);

  useEffect(() => {
    if (!effectServiceCall.current && !isView) {
      const fetchPromises = [dispatch(fetchAllClientToOfficeInvoices())];
      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (clientPaymentTypes.length > 0) {
      let data = clientPaymentTypes.map((PaymentType: any, index) => ({
        value: PaymentType.paymentType,
        label: PaymentType.paymentType,
      }));
      setclientPaymentTypeLists(data);
      let findObj = clientPaymentTypes.find((x) => x.isActive === true);
      if (findObj && findObj !== null) {
        setValue("transferMethod", findObj?.paymentType);
      }
    }
  }, [clientPaymentTypes]);

  useEffect(() => {
    if (authourisedClients.length > 0) {
      let data = authourisedClients.map((authorisedClient: any, index) => ({
        value: authorisedClient.fkClientId,
        label: `${authorisedClient.firstName} -${authorisedClient.lastName}`,
      }));
      setAuthorisedClientLists(data);
    }
  }, [authourisedClients]);

  useEffect(() => {
    if (clientAccounts.length > 0) {
      let data = clientAccounts.map((clientAccount: any, index) => ({
        value: clientAccount.bankAccountId,
        label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`,
      }));
      setClientAccountLists(data);
      let findObj = clientAccounts.find((x) => x.isDefaultAccount === true);
      if (findObj && findObj !== null) {
        setValue("clientBankId", findObj?.bankAccountId);
      }
    }
  }, [clientAccounts]);

  useEffect(() => {
    if (officeBanks.length > 0) {
      let data = officeBanks.map((officeBank: any, index) => ({
        value: officeBank.bankAccountId,
        label: `${officeBank.institution} - ${officeBank.bankAccountType}`,
      }));
      setOfficeBankLists(data);
      let findObject = officeBanks.find((x) => x.isDefaultAccount === true);
      if (findObject && findObject !== null) {
        setValue("officeBankId", findObject?.bankAccountId);
      }
    }
  }, [officeBanks]);

  // const transferMethod = [
  //   { label: "Cheque", value: "Cheque" },
  //   { label: "Electronic Transfer", value: "Electronic Transfer" },
  // ];

  // const mappedTransferMethod = transferMethod.map((type: any) => ({
  //   label: type.label,
  //   value: type.value,
  // }));

  // const authorisedBy = [
  //   { label: "Cheque", value: 1 },
  //   { label: "Electronic Transfer", value: 2 },
  // ];

  // const mappedauthorisedBy = authorisedBy.map((type: any) => ({
  //   label: type.label,
  //   value: type.value,
  // }));
  // const handleEditClick = () => {
  //   setIsDisabled(!isDisabled);
  // };

  // Handle edit row click
  const handleEditClick = (index: number) => {
    setEditRowIndex(index);
  };

  const handleReverseEditClick = () => {
    setIsReverseDisabled(!isReverseDisabled);
  };

  // const handleReverseClick =async(data: any) =>{

  //   try {
  //     setIsLoading(true);
  //    await dispatch(addReverseClientToOffice(data)).unwrap();
  //     Close("success");
  //     toast.success("Successfully saved reverse of client and office details");

  //   } catch (error: any) {
  //     toast.error(error.toString());
  //   } finally {
  //     setIsLoading(false);
  //   }

  // }

  // Handle save click
  const handleSaveClick = async () => {
    try {
      setIsLoading(true);

      // Get form data
      const formData = getValues();

      // Prepare payload
      const payload = {
        ...formData,
        tableData: invoicesc2o.map((row, index) => ({
          ...row,
          amount: amounts[index] || 0,
          balanceDue: calculateBalanceDue(index, row.balance || 0),
        })),
      };
      // console.log(invoicesc2o)
      await dispatch(addClientToOffice(payload)).unwrap();
      Close("success");
      toast.success("Successfully saved client and office details");

      // Reset edit mode
      setEditRowIndex(null);
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  // Handle amount input change
  const handleAmountChange = (index: number, value: string) => {
    const updatedAmounts = [...amounts];
    updatedAmounts[index] = parseFloat(value) || 0;
    setAmounts(updatedAmounts);
  };
  // Submit form data
  // const onSubmit = async (data: any) => {
  //   console.log("Form Data:", JSON.stringify(data, null, 2));
  //   setIsLoading(true);

  //   try {
  //     const payload = {
  //       ...data,
  //       tableData: invoicesc2o.map((row, index) => ({
  //         ...row,
  //         amount: amounts[index] || 0,
  //        // balanceDue: (row.balance || 0) - (amounts[index] || 0),
  //       })),
  //     };

  //     await dispatch(addClientToOffice(payload)).unwrap();
  //     toast.success("Successfully added client and office details");
  //   } catch (error: any) {
  //     toast.error(error.toString());
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  // useEffect(() => {
  //   if (isEdit && clientToOffice) {
  //     setExistingClientToOffice(clientToOffice);
  //     reset(clientToOffice);
  //   }
  // }, [clientToOffice, isEdit, reset, setValue]);

  useEffect(() => {
    if (invoiceDataC2O != null && invoiceDataC2O.length > 0) {
      setInvoicesc2o(invoiceDataC2O);
    }
  }, [invoiceDataC2O]);

  const calculateBalanceDue = (index: number, balance: number) => {
    const enteredAmount = amounts[index] || 0;
    return balance - enteredAmount >= 0 ? balance - enteredAmount : 0;
  };

  useEffect(() => {
    if((clientToOffice != null && isView === true) || (clientToOffice != null && isView === false  && isReverse === true)){
        setValue('clientBankId', clientToOffice?.fkClientBankId)
        setValue('transactionDate', formatDateISODisplay(clientToOffice?.transactionDate))
        setValue('officeBankId', clientToOffice?.fkOfficeBankId)
        setValue('clientBankId', clientToOffice?.fkClientBankId)
       // setValue('receivedFromOther', bankReceipt?.fkclientId)
        setValue('referenceNumber', clientToOffice?.transferMethodReferenceNumber)
        setValue('comments', clientToOffice?.comments)
        setValue('transferMethod', clientToOffice?.transferMethod)
        
    }
  }, [clientToOffice, isView, isReverse, reset, setValue]);

  useEffect(() => {
    if (isReverse) {
      dispatch(fetchNextReverseNumber(ClientAccountConstant.ClienttoOffice));
    }
  }, [dispatch, isReverse]);

  return (
    <>
      {/* <form onSubmit={handleSubmit(onSubmit,onError)} className="mx-auto"> */}
      {isReverse === false ||
        (isView === false && (
          <div className="flex items-end justify-end">
            <button
              type="button"
              onClick={() => {
                Close();
              }}
              className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        ))}
      <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full px-12">
        <div className="">
          <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
            Client Bank Account <span className="text-red-500">*</span>
          </label>
          <SelectBox
            className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
            indicator={
              <svg
                width="15"
                className="fill-current mr-2"
                height="15"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            }
            isMulti={false}
            name="frameFiftySix"
            isSearchable={true}
            options={clientAccountLists}
            placeholder="Select"
            value={getValues().clientBankId}
            shape="round"
            color="indigo_50"
            size="xs"
            onChange={(selectedOption) => {
              if (selectedOption) {
                setValue("clientBankId", selectedOption);
              }
            }}
            isDisabled={isView || isReverse}
          />
          <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
            {errors?.clientBankId?.message?.toString()}
          </div>
        </div>
        <div className="">
          <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
            Office Bank <span className="text-red-500">*</span>
          </label>
          <SelectBox
            className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
            indicator={
              <svg
                width="15"
                className="fill-current mr-2"
                height="15"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            }
            isMulti={false}
            name="frameFiftySix"
            isSearchable={true}
            options={officeBankLists}
            value={getValues().officeBankId}
            placeholder="Select"
            shape="round"
            color="indigo_50"
            size="xs"
            onChange={(selectedOption) => {
              if (selectedOption) {
                setValue("officeBankId", selectedOption);
              }
            }}
            isDisabled={isView || isReverse}
          />
          <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
            {errors?.officeBankId?.message?.toString()}
          </div>
        </div>
        <div className="relative">
          <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
            Transaction Date <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              errors.transactionDate ? "is-invalid" : ""
            }`}
            {...register("transactionDate")}
            placeholder="Enter Transaction Date"
            disabled={isView || isReverse}
          />
          <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
            {errors?.transactionDate?.message?.toString()}
          </div>
        </div>
        <div className="">
          <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
            Transfer Method <span className="text-red-500">*</span>
          </label>
          <SelectBox
            className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
            indicator={
              <svg
                width="15"
                className="fill-current mr-2"
                height="15"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            }
            isMulti={false}
            name="frameFiftySix"
            isSearchable={true}
            options={clientPaymentTypeLists}
            value={getValues().transferMethod}
            placeholder="Select"
            shape="round"
            color="indigo_50"
            size="xs"
            onChange={(selectedOption) => {
              if (selectedOption) {
                setValue("transferMethod", selectedOption);
              }
            }}
            isDisabled={isView || isReverse}
          />
          <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
            {errors?.transferMethod?.message?.toString()}
          </div>
        </div>
        <div className="relative">
          <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
            Reference Number <span className="text-red-500"></span>
          </label>
          <input
            type="text"
            className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              errors.referenceNumber ? "is-invalid" : ""
            }`}
            {...register("referenceNumber")}
            placeholder="Enter Reference Number"
            disabled={isView || isReverse}
          />
          <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
            {errors?.referenceNumber?.message?.toString()}
          </div>
        </div>
        <div className="relative">
          <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
            Comments
          </label>
          <textarea
            className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
            placeholder="Comments"
            {...register("comments")}
            disabled={isView || isReverse}
          ></textarea>
        </div>
      </div>

      <div className="relative overflow-x-auto sm:rounded-lg mx-12">
        <table className="w-full text-lg 3xl:text-xs text-left rtl:text-right dark:text-gray-400">
          <thead className="text-lg 3xl:text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Reference
              </th>
              <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Case Reference
              </th>
              <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Available Balance
              </th>
              <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Details
              </th>
              <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Balance
              </th>
              <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Amount
              </th>
              <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Balance Due
              </th>
              <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {invoicesc2o &&
              invoicesc2o.map((invdaata: any, index) => (
                <tr
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  key={index}
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    C2O{invdaata?.clientCaseResult?.invoiceId}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {invdaata?.clientCaseResult?.caseReferenceAuto}
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{invdaata?.availableBalance}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    INV_{invdaata?.clientCaseResult?.invoiceId}
                  </td>

                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{invdaata?.balance}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <NumericFormatWrapper
                      value={amounts[index] || ""}
                      onValueChange={(values: any) =>
                        handleAmountChange(index, values.value)
                      }
                      decimalScale={2}
                      placeholder="0.00"
                      className="border p-2 rounded w-full"
                      disabled={isView || isReverse}
                    />
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {calculateBalanceDue(index, invdaata?.balance).toFixed(2)}
                  </td>
                  {!isView && (
                    <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {editRowIndex === index ? (
                        <FaSave
                          className="fill-blue-500 w-6 h-6"
                          onClick={handleSaveClick}
                        />
                      ) : (
                        <FaEdit
                          className="fill-blue-500 w-6 h-6"
                          onClick={() => handleEditClick(index)}
                        />
                      )}
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {isReverse && (
        <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
          <div>
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Reverse Reference
            </label>
            <div className="flex gap-3">
              R_C2O
              <input
                type="text"
                value={reverseReceiptNumber}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                disabled={isReverseDisabled}
              />
              <button
                type="button"
                onClick={handleReverseEditClick}
                className="mt-2 text-lg 3xl:text-sm font-medium text-blue-600 dark:text-blue-500"
              >
                {isReverseDisabled ? <FaLock /> : <FaLockOpen />}
              </button>
            </div>
          </div>
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Reverse Receipt Number
            </label>
            <input
              type="text"
              name="reverseNumber"
              value={formData.reverseNumber}
              onChange={handleChange}
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 
                    }`}
              placeholder="Enter Reverse Reference"
            />
          </div>
          <div className="relative">
            <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
              Reason
            </label>
            <textarea
              className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              placeholder="Reason"
            ></textarea>
          </div>
        </div>
      )}

      <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
        <div className="flex flex-row gap-2 items-end justify-end w-full">
          {isReverse && (
            <button
              type="button"
              onClick={handleReverseClick}
              disabled={isLoading}
              className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-md text-center border text-lg 3xl:text-sm w-[120px]  ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white-bg mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              ) : (
                "Reverse"
              )}
            </button>
          )}
        </div>
      </div>

      {/* </form> */}
    </>
  );
}

export { AddClientToOffice };
