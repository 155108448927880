import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CaseTypeService from "../../../services/SystemMaintenance/CaseTypeService";


export interface CaseType  {
    caseTypeId: number;
    fkBranchId: number;
    fkAreasOfPracticeId: number;
    caseType: string;
    caseTypeCode: string;
    description: string;
    isActive: boolean;
};
// Define the state type for casetype
type CasetypeState = {
    casetypes: CaseType[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CasetypeState = {
    casetypes: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllCaseType = createAsyncThunk(
    "fetchAllCaseType",
    async (_, thunkAPI) => {
        try {
            const response = await CaseTypeService.GetAllCaseType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCaseType = createAsyncThunk(
    "addCaseType",
    async (data: any, thunkAPI) => {
        try {
            const response = await CaseTypeService.PostCaseType(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateCasetype = createAsyncThunk(
    "updateCasetype",
    async ({ id, data }: { id: number, data: CaseType }, thunkAPI) => {
        try {
            const response = await CaseTypeService.PutCasetype(id,data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCaseType = createAsyncThunk(
    "deleteCaseType",
    async (CaseTypeId: number, thunkAPI) => {
        try {
            await CaseTypeService.deleteCaseType(CaseTypeId);
            return CaseTypeId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CaseTypeSlice = createSlice({
    name: "caseType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCaseType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCaseType.fulfilled, (state, action: PayloadAction<CaseType[]>) => {
                state.status = "idle";
                state.casetypes = action.payload;
            })
            .addCase(fetchAllCaseType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCaseType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCaseType.fulfilled, (state, action: PayloadAction<CaseType>) => {
                state.status = "idle";
                state.casetypes.push(action.payload);
            })
            .addCase(addCaseType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateCasetype.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCasetype.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                if (action.payload != null) {
                    state.casetypes = action.payload || [];
                }
            })
            .addCase(updateCasetype.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCaseType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCaseType.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
            })
            .addCase(deleteCaseType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default CaseTypeSlice.reducer;
