import { ErrorResult } from "../../../../../store/model/errorResult";
import axiosInstance from "../../../../axiosInstance";



const GetNextBankPaymentNumber = async () => {
    try {
        const response = await axiosInstance.get("BankPaymentNumber");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const GetAllCreatePayment = async () => {
    try {
        const response = await axiosInstance.get("CreatePayment");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutCreatePayment = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`CreatePayment/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteCreatePayment = async (id: number) => {
    try {
        await axiosInstance.delete(`/CreatePayment/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}
const CreatePaymentService = {
    GetAllCreatePayment,
    GetNextBankPaymentNumber,
    PutCreatePayment,
    deleteCreatePayment
};


export default CreatePaymentService;