import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ReconciliationService from "../../../../services/Accounting/ClientAccounts/ReconciliationService";



export interface Reconciliation {
    ReconciliationId : number;
    ReconcilliationDate: Date;
    StatementDate: Date;
    StatementBalance : string;
    ADD: string;
    LESS : string;
    SystemBankAccount: string;
    difference : string;
};

export interface ReconciliationData{
    bankTransactions: [];
    closingBalance: number;
    lastReconciledDate: string;
}
// Define the state type for Checklist
type ReconciliationState = {
    reconciliations: Reconciliation[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    reconciliationData: ReconciliationData;
};

// Initial state
const initialState: ReconciliationState = {
    reconciliations: [],
    status: "idle",
    error: null,
    reconciliationData: null!,
};

// Async thunks for CRUD operations
export const fetchAllReconciliation = createAsyncThunk(
    "fetchAllReconciliation",
    async (data: any, thunkAPI) => {
        try {
            const response = await ReconciliationService.GetAllReconciliation(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addReconciliation = createAsyncThunk(
    "addReconciliation",
    async (data: any, thunkAPI) => {
        try {
            const response = await ReconciliationService.PostReconciliation(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateReconciliation = createAsyncThunk(
    "updateReconciliation",
    async ({ id, data }: { id: number, data: Reconciliation }, thunkAPI) => {
        try {
            const response = await ReconciliationService.PutReconciliation(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);



// Slice definition
const ReconciliationSlice = createSlice({
    name: "reconciliation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllReconciliation.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllReconciliation.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.reconciliationData = action.payload;
            })
            .addCase(fetchAllReconciliation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addReconciliation.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addReconciliation.fulfilled, (state, action: PayloadAction<Reconciliation>) => {
                state.status = "idle";
                state.reconciliations.push(action.payload);
            })
            .addCase(addReconciliation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateReconciliation.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateReconciliation.fulfilled, (state, action: PayloadAction<Reconciliation>) => {
                state.status = "idle";
                const index = state.reconciliations.findIndex(t => t.ReconciliationId === action.payload.ReconciliationId);
                if (index !== -1) {
                    state.reconciliations[index] = action.payload;
                }
            })
            .addCase(updateReconciliation.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
            
    }
});

export default ReconciliationSlice.reducer;
