import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllLeaveRequest = async (data: any) => {
    try {
        const response = await axiosInstance.post("LeaveManagement/search", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostLeaveRequest = async (data: any) => {
    try {
        const response = await axiosInstance.post("LeaveManagement/request", data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                } 
            }
        );
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutLeaveRequest = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`LeaveManagement/request/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteLeaveRequest = async (id: number) => {
    try {
        await axiosInstance.delete(`/LeaveRequest/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const LeaveRequestService = {
    GetAllLeaveRequest,
    PostLeaveRequest,
    PutLeaveRequest,
    deleteLeaveRequest
};


export default LeaveRequestService;
