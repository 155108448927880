import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import SuppliersControlAccountService from "../../../../../services/Accounting/OfficeAccount/Suppliers/SuppliersControlAccountService";



export interface SuppliersControlAccount {
    listingData: [];
    totalRows: number

};
// Define the state type for Checklist
type SuppliersControlAccountState = {
    suppliersControlAccounts: SuppliersControlAccount;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: SuppliersControlAccountState = {
    suppliersControlAccounts: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllSuppliersControlAccount = createAsyncThunk(
    "fetchAllSuppliersControlAccount",
    async (_, thunkAPI) => {
        try {
            const response = await SuppliersControlAccountService.GetAllSuppliersControlAccount();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const SuppliersControlAccountSlice = createSlice({
    name: "suppliersControlAccount",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllSuppliersControlAccount.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllSuppliersControlAccount.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.suppliersControlAccounts = action.payload;
            })
            .addCase(fetchAllSuppliersControlAccount.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

    }
});

export default SuppliersControlAccountSlice.reducer;
