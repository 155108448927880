import axiosInstance from '../../axiosInstance';
import { ErrorResult } from '../../../store/model/errorResult';

const GetAllCase = async () => {
  try {
    const response = await axiosInstance.get("Case");
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Case: ${error}`);
  }
}

const GetByCaseId = async (id: any) => {
  try {
    const response = await axiosInstance.get(`Case/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Case: ${error}`);
  }
}

const GetCaseNumber = async () => {
  try {
    const response = await axiosInstance.get(`CaseNumber`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch CaseNumber: ${error}`);
  }
}

const GetRiskTypeStatus = async () => {
  try {
    const response = await axiosInstance.get(`RiskAssessmentStatusType`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch GetRiskTypeStatus: ${error}`);
  }
}

const GetPermittedStaffs = async (id: number) => {
  try {
    const response = await axiosInstance.get(`PermittedStaff/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get Permitted Staffs: ${error}`);
  }
}

const GetPostRegisters = async (data: any) => {
  try {
    const response = await axiosInstance.post(`case/casepostregister/search`, data);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get PostRegister: ${error}`);
  }
}

const GetCaseMoneyLaundarings = async (id: number) => {
  try {
    const response = await axiosInstance.get(`case/${id}/CaseMoneyLaundarings`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get CaseMoneyLaundarings: ${error}`);
  }
}

const GetStaffInvolved = async (id: number) => {
  try {
    const response = await axiosInstance.get(`StaffInvolved/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get StaffInvolved: ${error}`);
  }
}

const GetCasePermission = async (id: number) => {
  try {
    const response = await axiosInstance.get(`CasePermission/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get StaffInvolved: ${error}`);
  }
}

const GetCaseTitleDocuments = async (id: number) => {
  try {
    const response = await axiosInstance.get(`case/${id}/CaseTitleDocuments`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get CaseTitleDocuments: ${error}`);
  }
}
const GetCaseSearchDocuments = async (id: number) => {
  try {
    const response = await axiosInstance.get(`case/${id}/CaseSearchDocuments`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get CaseSearchDocuments: ${error}`);
  }
}

const GetCaseLedger = async (id: number) => {
  try {
    const response = await axiosInstance.get(`case/${id}/caseledger`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get CaseSearchDocuments: ${error}`);
  }
}

const GetCaseAccountSummary = async (id: number) => {
  try {
    const response = await axiosInstance.get(`case/${id}/accountsummary`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get CaseSearchDocuments: ${error}`);
  }
}

const GetCaseWorkflowItems= async (id: number) => {
  try {
    const response = await axiosInstance.get(`case/${id}/workflowWithitems`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get CaseSearchDocuments: ${error}`);
  }
}

// Add a new role
const PostCaseData = async (data: any) => {
  try {
    const response = await axiosInstance.post("Case", data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const PostCasePermission = async (id: number, data: any) => {
  try {
    const response = await axiosInstance.post(`Case/${id}/CasePermission`, data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const GetCaseStatus = async (id: number) => {
  try {
    const response = await axiosInstance.get(`Case/${id}/CaseStatus`);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const PostCaseStatus = async (id: number, data: any) => {
  try {
    const response = await axiosInstance.post(`Case/${id}/CaseStatus`, data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const PostCaseClients = async (id: number, data: any) => {
  console.log(data);
  try {
    const response = await axiosInstance.post(`case/${id}/caseclient`, data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const PostCaseContacts = async (id: number, data: any) => {
  console.log(data);
  try {
    const response = await axiosInstance.post(`case/${id}/casecontact`, data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const PostCaseRiskAssessment = async (id: number, data: any) => {
  console.log(data);
  try {
    const response = await axiosInstance.post(`case/${id}/RiskAssessment`, data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const PostCaseAML = async (id: number, data: any) => {
  console.log(data);
  try {
    const response = await axiosInstance.post(`case/${id}/AML`, data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const GetCaseClient = async (id: number) => {
  try {
    const response = await axiosInstance.get(`case/${id}/caseclient`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get caseclient: ${error}`);
  }
}

const GetCaseContact = async (id: number) => {
  try {
    const response = await axiosInstance.get(`case/${id}/caseContact`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get caseContact: ${error}`);
  }
}

const GetCaseGivenToClient = async (data: any) => {
  try {
    const response = await axiosInstance.post(`case/giventoclient`, data);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get caseContact: ${error}`);
  }
}

const GetCaseBalanceWithClient = async (id: number) => {
  try {
    const response = await axiosInstance.get(`case/${id}/caseclientbalance`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Get caseContact: ${error}`);
  }
}

// Update a role
const PutCaseData = async (id: number, data: any) => {
  try {
    const response = await axiosInstance.put(`Case/${id}`, data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

// Delete a role
const DeleteCaseData = async (caseId: number) => {
  try {
    await axiosInstance.delete(`/Case/${caseId}`);
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

const GetCaseContactAndClientByCaseId = async (data: any) => {
  try {
    const response = await axiosInstance.post(`case/caseclientcontact`, data);
    return response.data;
  } catch (error) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}
const CaseManagementService = {
  GetAllCase,
  GetByCaseId,
  GetCaseNumber,
  GetRiskTypeStatus,
  GetPermittedStaffs,
  GetPostRegisters,
  GetCaseTitleDocuments,
  GetCaseSearchDocuments,
  GetCaseMoneyLaundarings,
  GetStaffInvolved,
  GetCasePermission,
  PostCaseData,
  PostCasePermission,
  PostCaseStatus,
  GetCaseStatus,
  PostCaseRiskAssessment,
  PostCaseAML,
  GetCaseContactAndClientByCaseId,
  PostCaseClients,
  PostCaseContacts,
  PutCaseData,
  DeleteCaseData,
  GetCaseClient,
  GetCaseContact,
  GetCaseLedger,
  GetCaseAccountSummary,
  GetCaseWorkflowItems,
  GetCaseBalanceWithClient,
  GetCaseGivenToClient
};

export default CaseManagementService;
