import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import StaffAttendenceTypeService from "../../../../services/HumanResources/HumanResources/StaffAttendenceTypeService";

export interface StaffAttendenceType {
  attendanceStatusTypeId: number;
  attendanceStatusType: string;
  isDefault: boolean | null;
  isHoliday: boolean | null;
  isTimeInOut: boolean;
  color: string;
  isActive: boolean;

}
// Define the state type for Checklist
type StaffAttendenceTypeState = {
    staffAttendenceTypes: StaffAttendenceType[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: StaffAttendenceTypeState = {
    staffAttendenceTypes: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllStaffAttendenceType = createAsyncThunk(
    "fetchAllStaffAttendenceType",
    async (_, thunkAPI) => {
        try {
            const response = await StaffAttendenceTypeService.GetAllStaffAttendenceType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
// Slice definition
const StaffAttendenceTypeSlice = createSlice({
    name: "staffAttendenceType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchAllStaffAttendenceType.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchAllStaffAttendenceType.fulfilled, (state, action: PayloadAction<any[]>) => {
          state.status = "idle";
          state.staffAttendenceTypes = action.payload;
        })
        .addCase(fetchAllStaffAttendenceType.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.payload as string;
        })
    }
});

export default StaffAttendenceTypeSlice.reducer;