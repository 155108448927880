import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AuthorisationService from "../../../../../services/Accounting/ClientAccounts/Invoice/AuthorisationService";
import AdjustService from "../../../../../services/Accounting/ClientAccounts/Invoice/AdjustService";



export interface Adjust {
    adjustId: number;
    areasofPractice: string;
    caseType: string;
    caseSubType: string;
    dateBetween: string;
    keyword: string;


};
// Define the state type for Adjust
type AdjustState = {
    adjustions: Adjust[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

//Initial state
const initialState: AdjustState = {
    adjustions: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllAdjust = createAsyncThunk(
    "fetchAllAdjust",
    async (_, thunkAPI) => {
        try {
            const response = await AdjustService.GetAllAdjust();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateAdjust = createAsyncThunk(
    "updateAdjust",
    async ({ id, data }: { id: number, data: Adjust }, thunkAPI) => {
        try {
            const response = await AdjustService.PutAdjust(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// export const deleteAdjust = createAsyncThunk(
//     "deleteAdjust",
//     async (id: number, thunkAPI) => {
//         try {
//             await AuthorisationService.deleteAdjust(id);
//             return id;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );


const AdjustSlice = createSlice({
    name: "adjust",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllAdjust.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllAdjust.fulfilled, (state, action: PayloadAction<Adjust[]>) => {
                state.status = "idle";
                state.adjustions = action.payload;
            })
            .addCase(fetchAllAdjust.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateAdjust.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateAdjust.fulfilled, (state, action: PayloadAction<Adjust>) => {
                state.status = "idle";
                const index = state.adjustions.findIndex(t => t.adjustId === action.payload.adjustId);
                if (index !== -1) {
                    state.adjustions[index] = action.payload;
                }
            })
            .addCase(updateAdjust.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            // .addCase(deleteAuthorisation.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(deleteAuthorisation.fulfilled, (state, action: PayloadAction<number>) => {
            //     state.status = "idle";
            //     state.authorisations = state.authorisations.filter(t => t.authorisationId !== action.payload);
            // })
            // .addCase(deleteAuthorisation.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // });

        }

        });
 

export default AdjustSlice.reducer;
