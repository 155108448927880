// import axios from 'axios';


import axiosInstance from "../axiosInstance";



const GetAllContactType = async () => {
  try {
    const response = await axiosInstance.get("ContactType");
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch ContactType: ${error}`);
  }
}
const ContactTypeService = {
  GetAllContactType,
};

export default ContactTypeService;
