import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import IncomeReportService from "../../../../../services/Accounting/OfficeAccount/OfficeReports/IncomeReportService";


export interface IncomeReport {
    incomes:[]
   
};
// Define the state type for Checklist
type IncomeReportState = {
    incomeReports: IncomeReport;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: IncomeReportState = {
    incomeReports: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllIncomeReport = createAsyncThunk(
    "fetchAllIncomeReport",
    async (data: any, thunkAPI) => {
        try {
            const response = await IncomeReportService.GetAllIncomeReport(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const IncomeReportSlice = createSlice({
    name: "incomeReport",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllIncomeReport.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllIncomeReport.fulfilled, (state, action: PayloadAction<IncomeReport>) => {
                state.status = "idle";
                state.incomeReports = action.payload;
            })
            .addCase(fetchAllIncomeReport.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
           
    }
});

export default IncomeReportSlice.reducer;
