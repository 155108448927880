// import axios from 'axios';


import axiosInstance from "../axiosInstance";


const fetchAllAreasOfPractice = async () => {
    try {
        const response = await axiosInstance.get("AreasOfPractic");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch AreasOfPractices:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch AreasOfPractices");
    }
}

const GetAreasType = async (id: number) => {
    try {
        const response = await axiosInstance.get(`AreasType/${id}`);
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch AreasType:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch AreasType");
    }
}

// Add a new branch
// async addBranch(data: Omit<Branch, 'branchId'>): Promise<Branch> {
//     try {
//         const response = await axiosInstance.post<Branch>("Branch", data);
//         return response.data;
//       } catch (error) {
//         if (axios.isAxiosError(error)) {
//           // Handle Axios error
//           console.error('Axios error:', error.response?.data || error.message);
//           throw new Error(`Failed to add branch: ${error.response?.data?.message || error.message}`);
//         } else {
//           // Handle unexpected error
//           console.error('Unexpected error:', error);
//           throw new Error('An unexpected error occurred');
//         }
//       }
// }

// Update a branch
// async updateBranch(branchId: number, data: Partial<Branch>): Promise<Branch> {
//     try {
//         const response = await axiosInstance.put<Branch>(`/Branch/${branchId}`, data);
//         return response.data;
//     } catch (error) {
//         if (axios.isAxiosError(error)) {
//             console.error('Axios error:', error.response?.data || error.message);
//             throw new Error(`Failed to update branch: ${error.response?.data?.message || error.message}`);
//         } else {
//             console.error('Unexpected error:', error);
//             throw new Error('An unexpected error occurred');
//         }
//     }
// }

// Delete a branch
// async deleteBranch(branchId: number): Promise<void> {
//     try {
//         await axiosInstance.delete(`/Branch/${branchId}`);
//     } catch (error) {
//         if (axios.isAxiosError(error)) {
//             console.error('Axios error:', error.response?.data || error.message);
//             throw new Error(`Failed to delete branch: ${error.response?.data?.message || error.message}`);
//         } else {
//             console.error('Unexpected error:', error);
//             throw new Error('An unexpected error occurred');
//         }
//     }
// }
const AreasOfPracticeService = {
    fetchAllAreasOfPractice,
    GetAreasType
};

export default AreasOfPracticeService;
