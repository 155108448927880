import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ViewSurveyResultService from "../../../../../services/HumanResources/Surveys/SurveyResult/ViewSurveyResultService";
import { PaginationResponse } from "../../../../model/Pagination/paginationClass";

export interface ViewSurveyResult {

  viewSurveyResultId: number;
}
// Define the state type for Checklist
type ViewSurveyResultState = {
    viewSurveyResults: PaginationResponse<ViewSurveyResult> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ViewSurveyResultState = {
    viewSurveyResults: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllViewSurveyResult = createAsyncThunk(
    "fetchAllViewSurveyResult",
    async (data: any, thunkAPI) => {
        try {
            const response = await ViewSurveyResultService.GetAllViewSurveyResult(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addViewSurveyResult = createAsyncThunk(
    "addViewSurveyResult",
    async (data: any, thunkAPI) => {
        try {
            const response = await ViewSurveyResultService.PostViewSurveyResult(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateViewSurveyResult = createAsyncThunk(
    "updateViewSurveyResult",
    async ({ id, data }: { id: number, data: ViewSurveyResult }, thunkAPI) => {
        try {
            const response = await ViewSurveyResultService.PutViewSurveyResult(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteViewSurveyResult = createAsyncThunk(
    "deleteViewSurveyResult",
    async (id: number, thunkAPI) => {
        try {
            await ViewSurveyResultService.deleteViewSurveyResult(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const ViewSurveyResultSlice = createSlice({
    name: "viewSurveyResult",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllViewSurveyResult.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllViewSurveyResult.fulfilled, (state, action: PayloadAction<PaginationResponse<ViewSurveyResult>>) => {
                state.status = "idle";
                state.viewSurveyResults = action.payload;
            })
            .addCase(fetchAllViewSurveyResult.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addViewSurveyResult.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addViewSurveyResult.fulfilled, (state, action: PayloadAction<ViewSurveyResult>) => {
                state.status = "idle";
               // state.viewSurveyResults.push(action.payload);
            })
            .addCase(addViewSurveyResult.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateViewSurveyResult.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateViewSurveyResult.fulfilled, (state, action: PayloadAction<ViewSurveyResult>) => {
                state.status = "idle";
                // const index = state.viewSurveyResults.findIndex(t => t.viewSurveyResultId === action.payload.viewSurveyResultId);
                // if (index !== -1) {
                //     state.viewSurveyResults[index] = action.payload;
                // }
            })
            .addCase(updateViewSurveyResult.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteViewSurveyResult.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteViewSurveyResult.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.viewSurveyResults = state.viewSurveyResults.filter(t => t.viewSurveyResultId !== action.payload);
            })
            .addCase(deleteViewSurveyResult.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default ViewSurveyResultSlice.reducer;
