import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";




const GetAllBankToBank = async () => {
    try {
        const response = await axiosInstance.post("BankToBankSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetBankToBankNumber = async () => {
    try {
        const response = await axiosInstance.get("BankToBankNumber");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostBankToBank = async (data: any) => {
    console.log(data);
    try {
        const response = await axiosInstance.post("BankToBank ", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostReverseBankToBank = async (data: any) => {
    console.log(data);
    try {
        const response = await axiosInstance.post("ReverseBankToBank ", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutBankToBank = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`BankToBank /${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteBankToBank = async (id: number) => {
    try {
        await axiosInstance.delete(`/BankToBank /${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const BankToBankService = {
    GetAllBankToBank,
    PostBankToBank,
    PostReverseBankToBank,
    PutBankToBank,
    deleteBankToBank,
    GetBankToBankNumber
};


export default BankToBankService;
