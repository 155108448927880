import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import TimeSheetService from "../../../../../services/Accounting/ClientAccounts/Invoice/TimeSheetService";
import { TimeSheetModel } from "../../../../model/Accounting/ClientAccount/TimeSheetModel";



// Define the state type for Checklist
type TimeSheetState = {
  
    timeSheets: TimeSheetModel[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    receiptNumber : number | null;
};

// Initial state
const initialState: TimeSheetState = {
    timeSheets: [],
    status: "idle",
    error: null,
    receiptNumber: null
};




export const fetchAllTimeSheet = createAsyncThunk(
    "fetchAllTimeSheet",
    async (_, thunkAPI) => {
        try {
            const response = await TimeSheetService.GetAllTimeSheet();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addTimeSheet = createAsyncThunk(
    "addTimeSheet",
    async (data: any, thunkAPI) => {
        try {
            const response = await TimeSheetService.PostTimeSheet(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateTimeSheet = createAsyncThunk(
    "updateTimeSheet",
    async ({ id, data }: { id: number, data: TimeSheetModel }, thunkAPI) => {
        try {
            const response = await TimeSheetService.PutTimeSheet(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteTimeSheet = createAsyncThunk(
    "deleteTimeSheet",
    async (id: number, thunkAPI) => {
        try {
            await TimeSheetService.deleteTimeSheet(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const TimeSheetSlice = createSlice({
    name: "timeSheet",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            
            .addCase(fetchAllTimeSheet.pending, (state) => {
                state.status = "loading";
            })
            // .addCase(fetchAllTimeSheet.fulfilled, (state, action: PayloadAction<SearchBankReciptResult>) => {
            //     state.status = "idle";
            //     // state.searchBankReciptResult = action.payload;
            // })
            .addCase(fetchAllTimeSheet.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addTimeSheet.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addTimeSheet.fulfilled, (state, action: PayloadAction<TimeSheetModel>) => {
                state.status = "idle";
                state.timeSheets.push(action.payload);
            })
            .addCase(addTimeSheet.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateTimeSheet.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateTimeSheet.fulfilled, (state, action: PayloadAction<TimeSheetModel>) => {
                state.status = "idle";
                const index = state.timeSheets.findIndex(t => t.timesheetId === action.payload.timesheetId);
                if (index !== -1) {
                    state.timeSheets[index] = action.payload;
                }
            })
            .addCase(updateTimeSheet.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteTimeSheet.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteTimeSheet.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.timeSheets = state.timeSheets.filter(t => t.timesheetId !== action.payload);
            })
            .addCase(deleteTimeSheet.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default TimeSheetSlice.reducer;
