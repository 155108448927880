import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import TrainingRecordService from "../../../../services/HumanResources/HumanResources/TrainingRecordService";

export interface TrainingRecord {

    listingData: ListingData[];
    totalCount: number;
}
export interface ListingData{
  trainingRecordId: number;
  staffId: number;
  certificateName: string;
  certificateFile: string;
  courseTitle: string;
  courseProvider: string;
  description: string;
  totalCost: number;
  paidBy: string;
  durationType: number;
  duration: number;
  startDate: string; // Alternatively, you could use Date if you're working with Date objects in TypeScript
  endDate: string;   // Alternatively, Date type can be used for Date objects
  reference: string;
  notes: string;
}
// Define the state type for Checklist
type TrainingRecordState = {
    trainingRecords: PaginationResponse<TrainingRecord> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: TrainingRecordState = {
    trainingRecords: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllTrainingRecord = createAsyncThunk(
    "fetchAllTrainingRecord",
    async (data: any, thunkAPI) => {
        try {
            const response = await TrainingRecordService.GetAllTrainingRecord(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addTrainingRecord = createAsyncThunk(
    "addTrainingRecord",
    async (data: any, thunkAPI) => {
        try {
            const response = await TrainingRecordService.PostTrainingRecord(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateTrainingRecord = createAsyncThunk(
    "updateTrainingRecord",
    async ({ id, data }: { id: number, data: TrainingRecord }, thunkAPI) => {
        try {
            const response = await TrainingRecordService.PutTrainingRecord(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteTrainingRecord = createAsyncThunk(
    "deleteTrainingRecord",
    async (id: number, thunkAPI) => {
        try {
            await TrainingRecordService.deleteTrainingRecord(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const TrainingRecordSlice = createSlice({
    name: "trainingRecord",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTrainingRecord.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllTrainingRecord.fulfilled, (state, action: PayloadAction<PaginationResponse<TrainingRecord>>) => {
                state.status = "idle";
                state.trainingRecords = action.payload;
            })
            .addCase(fetchAllTrainingRecord.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addTrainingRecord.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addTrainingRecord.fulfilled, (state, action: PayloadAction<TrainingRecord>) => {
                state.status = "idle";
               // state.trainingRecords.push(action.payload);
            })
            .addCase(addTrainingRecord.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateTrainingRecord.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateTrainingRecord.fulfilled, (state, action: PayloadAction<TrainingRecord>) => {
                state.status = "idle";
                // const index = state.trainingRecords.findIndex(t => t.trainingRecordId === action.payload.trainingRecordId);
                // if (index !== -1) {
                //     state.trainingRecords[index] = action.payload;
                // }
            })
            .addCase(updateTrainingRecord.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteTrainingRecord.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteTrainingRecord.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.trainingRecords = state.trainingRecords.filter(t => t.trainingRecordId !== action.payload);
            })
            .addCase(deleteTrainingRecord.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default TrainingRecordSlice.reducer;
