import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { boolean, object, string } from "yup";
import { SearchComponent } from "../../../AutoSelectAddress/SearchInput";
import { SelectAddressComponent } from "../../../AutoSelectAddress/SelectAddress";
import { useAppDispatch } from "../../../../store/hooks/redux-hooks";
import { useEffect, useRef, useState } from "react";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { fetchLocationByPostcode } from "../../../../store/slice/CaseManagement/AddressSlice";
import LocationUtils from "../../../../utils/location.utils";


function AddComClientTwo({ onSubmitStep, initialData, onPreviousStep, }: any) {
    const clientByIdData = useSelector((state: RootState) => state.client.clientByIdData);

    const dispatch = useAppDispatch();
    const [postcode, setPostcode] = useState('');
    const [locationOptionsLists, setLocationOptionsLists] = useState<any[]>([]);
    const { locations } = useSelector((state: RootState) => state.location);
    const [isLoading, setIsLoading] = useState(false);
    const effectServiceCall = useRef(false);

    useEffect(() => {
        if (!effectServiceCall.current && postcode) {
            setIsLoading(true);
            dispatch(fetchLocationByPostcode(postcode))
                .unwrap()
                .catch((error) => {
                    console.error("Error fetching location:", error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        return () => { effectServiceCall.current = true; };
    }, [dispatch, postcode]);

    useEffect(() => {
        if (locations != null && locations.length > 0) {
            let data = locations.map((location: any) => ({
                value: location.address,
                label: location.address,
            }));
            setLocationOptionsLists(data);
        }
    }, [locations]);

    const onLocationChange = (location: any) => {
        if (location) {
            const obj = LocationUtils.splitAddress(location);
            if (obj != null) {
                setValue('companyAddressLine1', obj.addressLine1)
                setValue('companyAddressLine2', obj.addressLine2)
                setValue('companyCity', obj.city)
                setValue('companyCounty', obj.county)
                setValue('companyPostcode', obj.postcode)
            }
        }
    }

    const validationSchema = object().shape({
        companyAddressLine1: string()
            .required('Select Address is required'),
        companyAddressLine2: string(),
        companyCity: string(),
        companyCounty: string(),
        companyPostcode: string(),
        companyPoboxNumber: string(),
        companyPoboxCounty: string(),
        companyPoboxTown: string(),
        companyPoboxPostcode: string(),
        compCurrentAddDxNumber: string(),
        compCurrentAddDxExchange: string(),
        isCompFutureAddress: boolean(),
        compFutureAddLine1: string().when('isCompFutureAddress', {
            is: true,
            then: (schema) => schema.required('address is required.'),
        }),
        compFutureAddLine2: string(),
        compFutureAddCity: string(),
        compFutureAddCounty: string(),
        compFutureAddPostCode: string().when('isCompFutureAddress', {
            is: true,
            then: (schema) => schema.required('address is required.'),
        }),
        compFutureAddDxNumber: string(),
        compFutureAddDxExchange: string(),
        compFutureAddPoboxNumber: string(),
        compFutureAddPoboxCounty: string(),
        compFutureAddPoboxTown: string(),
        compFutureAddPoboxPostCode: string(),
        compFutureAddEffectFrom: string().when('isCompFutureAddress', {
            is: true,
            then: (schema) => schema.required('address is required.'),
        }),
        compFutureAddIsPreferred: boolean(),
    })

    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: initialData || {}
    });

    const isCompFutureAddress = watch('isCompFutureAddress', initialData?.isCompFutureAddress || false);
    const [isComFutureChecked, setIsComFutureChecked] = useState(isCompFutureAddress || false);
    const isPreferredAddress = watch('compFutureAddIsPreferred ', initialData?.compFutureAddIsPreferred || false);
    const [isPreferredAdd, setIsPreferredAdd] = useState(isPreferredAddress || false);

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        //close();
        onSubmitStep(data);
    };

    const handleIndFutureAddress = (checked: boolean) => {
        setIsComFutureChecked(checked);
        setValue('isCompFutureAddress', checked, { shouldValidate: true });
    };

    const handlePreferredAddress = (checked: boolean) => {
        setIsPreferredAdd(checked);
        setValue('compFutureAddIsPreferred', checked, { shouldValidate: true });
    };

    useEffect(() => {
        if (clientByIdData !== null) {
            setValue("companyAddressLine1", clientByIdData?.companyAddressLine1);
            setValue("companyAddressLine2", clientByIdData?.companyAddressLine2);
            setValue("companyCity", clientByIdData?.companyCity);
            setValue("companyCounty", clientByIdData?.companyCounty);
            setValue("companyPostcode", clientByIdData?.companyPostcode);
            setValue("companyPoboxNumber", clientByIdData?.companyPoboxNumber);
            setValue("companyPoboxCounty", clientByIdData?.companyPoboxCounty);
            setValue("companyPoboxTown", clientByIdData?.companyPoboxTown);
            setValue("companyPoboxPostcode", clientByIdData?.companyPoboxPostcode);
            setValue("compCurrentAddDxNumber", clientByIdData?.compCurrentAddDxNumber);
            setValue("compCurrentAddDxExchange", clientByIdData?.compCurrentAddDxExchange);
            setValue("isCompFutureAddress", clientByIdData?.isCompFutureAddress);

            setValue("compFutureAddLine1", clientByIdData?.compFutureAddLine1);
            setValue("compFutureAddLine2", clientByIdData?.compFutureAddLine2);
            setValue("compFutureAddCity", clientByIdData?.compFutureAddCity);
            setValue("compFutureAddCounty", clientByIdData?.compFutureAddCounty);
            setValue("compFutureAddPostCode", clientByIdData?.compFutureAddPostCode);
            setValue("compFutureAddDxNumber", clientByIdData?.compFutureAddDxNumber);
            setValue("compFutureAddDxExchange", clientByIdData?.compFutureAddDxExchange);
            setValue("compFutureAddPoboxNumber", clientByIdData?.compFutureAddPoboxNumber);
            setValue("compFutureAddPoboxCounty", clientByIdData?.compFutureAddPoboxCounty);
            setValue("compFutureAddPoboxTown", clientByIdData?.compFutureAddPoboxTown);
            setValue("compFutureAddPoboxPostCode", clientByIdData?.compFutureAddPoboxPostCode);
            setValue("compFutureAddEffectFrom", clientByIdData?.compFutureAddEffectFrom);
            setValue("compFutureAddIsPreferred", clientByIdData?.compFutureAddIsPreferred);
        }
    }, [clientByIdData])

    return (
        <div>
            <form onSubmit={handleSubmit(handleFormSubmit)}>

                <div>
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">Current Address</h3>
                        <SearchComponent setPostcode={setPostcode} loading={isLoading} />
                    </div>
                    <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-2 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                        {locationOptionsLists !== null && locationOptionsLists.length > 0 && (
                            <div className="">
                                <SelectAddressComponent
                                    locationOptionsLists={locationOptionsLists ?? []}
                                    onLocationChange={onLocationChange}
                                    setValue={setValue}
                                />
                            </div>
                        )}
                        {/* <div className="">
                            <SelectAddressComponent
                                locationOptionsLists={locationOptionsLists ?? []}
                                onLocationChange={onLocationChange}
                                setValue={setValue}
                            />
                        </div> */}
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Address Line 1 <span className="text-red-500">*</span>
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.companyAddressLine1 ? 'is-invalid' : ''}`} {...register('companyAddressLine1')} />
                            <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.companyAddressLine1?.message?.toString()} </div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Address Line 2
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('companyAddressLine2')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                City
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyCity')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                County
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyCounty')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Postcode
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 }`} {...register('companyPostcode')} />
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">P.O DX Address</h3>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 ">
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Number
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compCurrentAddDxNumber')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Exchange
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compCurrentAddDxExchange')} />
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">P.O Box Address</h3>
                    </div>
                    <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 ">
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Number
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyPoboxNumber')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Town
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyPoboxTown')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                County
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('companyPoboxCounty')} />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Post Code
                            </label>
                            <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('companyPoboxPostcode')} />
                        </div>
                    </div>
                </div>

                <div className="flex gap-5">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Future Address
                    </label>
                    <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 border-2 border-black rounded-sm"
                        checked={isComFutureChecked}
                        onChange={(e) => handleIndFutureAddress(e.target.checked)}
                    />
                </div>
                {isComFutureChecked && (
                    <>
                        <div className="grid grid-cols-4 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Address Line 1 <span className="text-red-500">*</span>
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.compFutureAddLine1 ? 'is-invalid' : ''}`} {...register('compFutureAddLine1')} />
                                <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.compFutureAddLine1?.message?.toString()}</div>
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Address Line 2
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compFutureAddLine2')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    City
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compFutureAddCity')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    County
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compFutureAddCounty')} />
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Postcode <span className="text-red-500">*</span>
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.compFutureAddPostCode ? 'is-invalid' : ''}`} {...register('compFutureAddPostCode')} />
                                <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.compFutureAddPostCode?.message?.toString()}</div>
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Effect From <span className="text-red-500">*</span>
                                </label>
                                <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.compFutureAddEffectFrom ? 'is-invalid' : ''} `} {...register('compFutureAddEffectFrom')} />
                                <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.compFutureAddEffectFrom?.message?.toString()}</div>
                            </div>
                            <div className=" flex gap-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Set as preferred contact address
                                </label>
                                <input
                                    type="checkbox"
                                    className="h-4 w-4 text-blue-600 border-2 border-black rounded-sm"
                                    checked={isPreferredAdd}
                                    onChange={(e) => handlePreferredAddress(e.target.checked)}
                                />
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="flex items-center justify-between mb-5">
                                <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">P.O DX Address</h3>
                            </div>
                            <div className="grid grid-cols-2 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 ">
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Number
                                    </label>
                                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compFutureAddDxNumber')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Exchange
                                    </label>
                                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compFutureAddDxExchange')} />
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="flex items-center justify-between mb-5">
                                <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">P.O Box Address</h3>
                            </div>
                            <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 ">
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Number
                                    </label>
                                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compFutureAddPoboxNumber')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Town
                                    </label>
                                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('compFutureAddPoboxTown')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        County
                                    </label>
                                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('compFutureAddPoboxCounty')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Post Code
                                    </label>
                                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('compFutureAddPoboxPostCode')} />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="">
                    <div className="flex flex-row gap-2 items-start justify-between w-full">
                        <button
                            onClick={onPreviousStep}
                            type="button"
                            className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[140px]">
                            <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                            Previous
                        </button>

                        <button
                            type="submit"
                            className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">
                            Next
                            <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export { AddComClientTwo };
