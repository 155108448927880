import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllLeaveManagement = async (data: any) => {
    try {
        const response = await axiosInstance.post("LeaveManagement/search", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostLeaveManagement = async (data: any) => {
    try {
        const response = await axiosInstance.post("LeaveManagement", data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }  
            }
        );
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutLeaveManagement = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`LeaveManagement/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteLeaveManagement = async (id: number) => {
    try {
        await axiosInstance.delete(`/LeaveManagement/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const LeaveManagementService = {
    GetAllLeaveManagement,
    PostLeaveManagement,
    PutLeaveManagement,
    deleteLeaveManagement
};


export default LeaveManagementService;
