import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllAgedDebtorsReport = async (data: any) => {
    try {
        const response = await axiosInstance.post("officeaccount/officereport/ageddebtor", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const AgedDebtorsReportService = {
    GetAllAgedDebtorsReport,
   
};


export default AgedDebtorsReportService;
