import axiosInstance from '../axiosInstance';
import { ErrorResult } from '../../store/model/errorResult';


class CaseReferenceConstantService {
    async GetAllCaseReferenceConstant() {
        try {
            const response = await axiosInstance.get("CaseReferenceConstant");
            return response.data;
        } catch (error: any) {
            console.error('Failed to fetch CaseReferenceConstant:', error);
            throw new Error(error.response?.data?.message || error.message || "Failed to fetch CaseReferenceConstant");
        }
    }

    async PostCaseReferenceConstant(data: any) {
        try {
            const response = await axiosInstance.post("CaseReferenceConstant", data);
            return response.data;
        } catch (error: any) {
            let data = error as ErrorResult;
            throw new Error(data.messages.toString());
        }
    }

    async PutCaseReferenceConstant(id: number, data: any) {
        try {
            const response = await axiosInstance.put(`CaseReferenceConstant/${id}`, data);
            return response.data;
        } catch (error: any) {
            let data = error as ErrorResult;
            throw new Error(data.messages.toString());
        }
    }

    async deleteCaseReferenceConstant(casetypeId: number) {
        try {
            await axiosInstance.delete(`/CaseReferenceConstant/${casetypeId}`);
        } catch (error: any) {
            let data = error as ErrorResult;
            throw new Error(data.messages.toString());
        }
    }
}

export default new CaseReferenceConstantService();
