import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { useAppDispatch } from '../../../store/hooks/redux-hooks';
import { SearchInput } from '../../Filters/SearchInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { fetchAllOfficeAccountReferenceNumber } from '../../../store/slice/Accounting/OfficeAccounts/OfficeAccountReferenceNumberSlice';
import { MainAnimation } from '../../loadingAnimation/MainAnimation';
import { fetchAllOfficeSupplier } from '../../../store/slice/Accounting/OfficeAccounts/Suppliers/OfficeSupplierSlice';

function SearchSupplier({ openDrawer, Close,onAccountSupplierSelect }: { openDrawer: boolean, Close: any, onAccountSupplierSelect: any }) {
    const dispatch = useAppDispatch();
    const [openModal, setOpenModal] = useState(true);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const [selectedAccount, setSelectedAccount] = useState<any | null>(null); // Store full account details
    const {officeAccountReferenceNumbers }= useSelector((state: RootState) => state.officeAccountReferenceNumber);
    const [accountLists, setAccountLists] = useState<any[]>([]);
    const [initialLoading, setInitialLoading] = useState(true);
    const [officeSupplierGetData, setOfficeSupplierGetData] = useState<any[]>([]);
    const officeSupplierData = useSelector((state: RootState) => state.officeSupplier.officeSupplierData);
   
    const validationSchema = object().shape({
    


    });

    const effectServiceCall = useRef(false); 
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchAllOfficeSupplier())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (officeSupplierData && officeSupplierData.listingData) {
        setOfficeSupplierGetData(officeSupplierData.listingData);
    }
}, [officeSupplierData]);
  
    useEffect(() => {
        if (!effectServiceCall.current) {
            let data = {
                keyword: '',
               // BankAccountType: (type) ? type :'office',
                //fkAccountTypeIds: (fkAccountTypeIds)? (fkAccountTypeIds): '1',
            }
            dispatch(fetchAllOfficeAccountReferenceNumber(data))
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (officeAccountReferenceNumbers != null && officeAccountReferenceNumbers.accounts.length > 0) {
            console.log(officeAccountReferenceNumbers);
            setAccountLists(officeAccountReferenceNumbers.accounts);
            //setFilteredAccounts(accountSearchNominalAccounts); // Initial full list
        }
    }, [officeAccountReferenceNumbers]);

  

    const handleAccountSelection = (accountSearchSupplierAccount: any) => {
        setSelectedAccount(accountSearchSupplierAccount); // Store the entire account object
    };

    const handleSelectSupplierAccountClick = () => {
        console.log(selectedAccount);
        if (selectedAccount) {
            // Pass selected account details to parent via callback
            onAccountSupplierSelect(selectedAccount);
        } else {
            console.log("No account selected");
        }
        Close();
    };

    


    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() =>{
                Close();
            }}
            initialFocus={emailInputRef}
        >
            <Modal.Header className="bg-white-bg p-5">
                <span className="text-blue_gray-900 text-xl w-auto">Search Suppliers</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg">
                {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                    <div className="space-y-6 w-full">
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <div className='flex mb-5'>
                                <SearchInput title={'Keyword'} />
                            </div>
                            {initialLoading ? (<MainAnimation />) : (
                            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                                <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <th scope="col" className="px-6 py-3">

                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Supplier
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Service
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {officeSupplierGetData.map((accountSearchSupplierAccount) => (
                                    <tr 
                                    key={accountSearchSupplierAccount.supplierId}
                                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        
                                        <td className="px-6 py-3">
                                        <input
                                                    type="radio"
                                                    name="account"
                                                    value={accountSearchSupplierAccount.supplierId}
                                                    onChange={() => handleAccountSelection(accountSearchSupplierAccount)}
                                                    checked={selectedAccount?.supplierId === accountSearchSupplierAccount.supplierId}
                                        />
                                        </td>
                                        <td className="px-6 py-3">
                                            {accountSearchSupplierAccount.supplierName}
                                        </td>
                                        <td className="px-6 py-3">
                                        {accountSearchSupplierAccount.servicesProducts}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            )}
                        </div>
                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                  onClick={() =>{
                                    Close();
                                  }}
                                    type='button'
                                    className="cursor-pointer h-10 bg-white-A700 rounded-lg text-center border border-deep_purple-A400 text-deep_purple-A400 text-xs w-[140px] hover:text-white-A700 hover:bg-deep_purple-A400"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={handleSelectSupplierAccountClick}
                                    className="bg-green-700 text-white-bg cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center border border-deep_purple-A400 text-white-A700 text-sm w-[120px] hover:text-deep_purple-A400 hover:bg-white-A700"
                                >
                                    Select Suppliers
                                </button>
                            </div>
                        </div>
                    </div>
                {/* </form> */}
            </Modal.Body>
        </Modal>
    );
}

export { SearchSupplier };
