import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, boolean, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { addCaseType } from '../../../store/slice/SystemMaintenance/CaseTypeSlice';
import { useAppDispatch } from '../../../store/hooks/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function AddCasetype({ openDrawer, Close, isEdit, areaId, caseType }: { openDrawer: boolean, Close: any, isEdit: boolean, areaId?: number, caseType?: any }) {
    const [title] = useState((!isEdit ? 'Add New Case Type' : 'Edit Case Type'));
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement] = useState('center');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [existingCaseType, setExistingCaseType] = useState(caseType);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validationSchema = object().shape({
        caseType: string()
            .required('CaseType is required'),
        caseTypeCode: string()
            .required('Case Type Code is required'),
        description: string(),
        isActive: boolean(),
        fkBranchId: number(),
        fkAreasOfPracticeId: number()
    });

    const { register, handleSubmit, reset, setValue, formState: { errors }, watch } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            caseType: '',
            caseTypeCode: '',
            description: '',
            isActive: true,
            fkBranchId: areaId || 0,
            fkAreasOfPracticeId: areaId || 0,
        }
    });

    const onSubmit = async (data: any) => {
        setIsSubmitting(true);
        try {
            await dispatch(addCaseType(data)).unwrap();
            Close();
            navigate("/systemmanagement/systemmaintenance/casetype");
            toast.success("Successfully Added Casetype");
        } catch (error: any) {
            toast.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (isEdit && caseType) {
            setExistingCaseType(caseType);
            reset({
                ...caseType,
                description: caseType.description || '',
            });
        }
    }, [caseType, isEdit, reset, setValue]);

    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); Close(); }}
            initialFocus={emailInputRef}
            size="xl"
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">

                <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
                    <div className="space-y-3 w-full">
                        <input type="hidden" value={areaId} className={`${errors.caseType ? 'is-invalid' : ''}`} {...register('fkAreasOfPracticeId')}
                            placeholder="Enter Case type"
                        />
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Case Type
                            </label>
                            <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h ${errors.caseType ? 'is-invalid' : ''}`} {...register('caseType')}
                                placeholder="Enter Case type"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.caseType?.message?.toString()}</div>
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Case Type Code
                            </label>
                            <input type="text" className={`border border-gray-200 border-solid text-xs rounded-lg block w-full p-3 placeholder:text-blue_gray-500 h ${errors.caseTypeCode ? 'is-invalid' : ''}`} {...register('caseTypeCode')}
                                placeholder="Enter Case Type Code"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.caseTypeCode?.message?.toString()}</div>
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Description
                            </label>
                            <textarea
                                className={`bg-white-A700 border border-gray-200 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-lg placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full`}
                                placeholder="Describe" {...register('description')}
                            ></textarea>
                        </div>
                        <div className="relative max-w-full flex gap-6">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Is Active
                            </label>
                            <div className='flex gap-5'>
                                <div>
                                    <input
                                        type="radio"
                                        value="true"
                                        checked={watch('isActive') === true}
                                        onChange={() => setValue('isActive', true)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                                </div>
                                <div>
                                    <input
                                        id="bordered-radio-1"
                                        type="radio"
                                        value="false"
                                        checked={watch('isActive') === false}
                                        onChange={() => setValue('isActive', false)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                                </div>
                            </div>
                        </div>
                        
                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button

                                    className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                    onClick={() => { reset(); Close(); }}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    disabled={isSubmitting}
                                    className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                                >
                                    {isSubmitting ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    );
}

export { AddCasetype };
