import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../../store/hooks/redux-hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { fetchAllFeeType } from '../../../../store/slice/Accounting/AccountSetup/FeeTypeSlice';
import { fetchAllDisbursementType } from '../../../../store/slice/Accounting/AccountSetup/DisbursementTypeSlice';


function AddOfficeFeeTypesDisbursement({ openDrawer, Close, onFeeOrDisbursmentSelect }: { openDrawer: boolean, Close: any, onFeeOrDisbursmentSelect: (value: any) => void }) {
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')

    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const [selectedObj, setSelectedObj] = useState<any>();
    const [selectedFeeOrDisbursement, setSelectedFeeOrDisbursement] = useState('');
    const [activeTab, setActiveTab] = useState<'feeType' | 'disbursement'>('feeType');

    const feeTypes = useSelector((state: RootState) => state.feeType);
    const [feeTypeLists, setFeeTypeLists] = useState<any[]>([]);

    const disbursementTypes = useSelector((state: RootState) => state.disbursementType);
    const [disbursementTypeLists, setDisbursementTypeLists] = useState<any[]>([]);


    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllFeeType())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllDisbursementType())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (feeTypes != null) {

            setFeeTypeLists(feeTypes.feeTypes); // Update local state with feeTypes from Redux
        }
    }, [feeTypes]);
    // console.log(feeTypes);
    // console.log('Fetched feeTypes:', feeTypes);

    useEffect(() => {
        if (disbursementTypes != null) {
            setDisbursementTypeLists(disbursementTypes.disbursementTypes); // Update local state with disbursementTypes from Redux
        }
    }, [disbursementTypes]);
    //console.log('Fetched disbursementTypes:', disbursementTypes);
    // console.log(disbursementTypes);





    const handleRadioChange = (value: string, obj: any) => {

        setSelectedFeeOrDisbursement(value);
        let valObj = { selectedType: activeTab, data: obj }
        setSelectedObj(valObj);
    };
    const handleTabChange = (tab: 'feeType' | 'disbursement') => {
        setActiveTab(tab);
    };

    const handleSelectFeeOrDisbursmentClick = () => {
        if (selectedObj) {
            onFeeOrDisbursmentSelect(selectedObj);
            Close();
        }
    };



    return (
        <Modal
            className="backdrop-blur-sm items-center justify-center"
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { Close(); }}
            initialFocus={emailInputRef}
            size="md"
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">Select an Item Description</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">
                <div className="flex justify-around">
                    <button
                        className={`px-4 py-2 ${activeTab === 'feeType' ? 'border-b-2 border-blue-500' : ''}`}
                        onClick={() => handleTabChange('feeType')}
                    >
                        <h3 className="font-bold">Fee Type</h3>
                    </button>
                    <button
                        className={`px-4 py-2 ${activeTab === 'disbursement' ? 'border-b-2 border-blue-500' : ''}`}
                        onClick={() => handleTabChange('disbursement')}
                    >
                        <h3 className="font-bold">Disbursement Type</h3>
                    </button>
                </div>

                <div className="mt-4">
                    {activeTab === 'feeType' ? (
                        <div className="space-y-2">

                            {feeTypeLists.length > 0 ? (
                                feeTypeLists.map((fee) => (
                                    <div key={fee.feeTypeId} className="flex items-center">
                                        <input
                                            type="radio"
                                            // id={`fee-${fee.feeTypeId}`}
                                            name="feeType"
                                            value={fee.feeType}
                                            checked={selectedFeeOrDisbursement === fee.feeType}
                                            onChange={() => handleRadioChange(fee.feeType, fee)}
                                            className="w-4 h-4 text-blue-600"
                                        />
                                        <label htmlFor={`fee-${fee.feeTypeId}`} className="ml-2 text-sm">{fee.feeType}</label>
                                    </div>
                                ))
                            ) : (
                                <p>No Fee Types available</p>
                            )}
                        </div>
                    ) : (
                        <div className="space-y-2">

                            {disbursementTypeLists.length > 0 ? (
                                disbursementTypeLists.map((disbursement: any) => (
                                    <div key={disbursement.disbursementTypeId} className="flex items-center">
                                        <input
                                            type="radio"
                                            id={`disbursement-${disbursement.disbursementTypeId}`}
                                            name="disbursementType"
                                            value={disbursement.disbursementType}
                                            checked={selectedFeeOrDisbursement === disbursement.disbursementType}
                                            onChange={() => handleRadioChange(disbursement.disbursementType, disbursement)}
                                            className="w-4 h-4 text-blue-600"
                                        />
                                        <label htmlFor={`disbursement-${disbursement.disbursementTypeId}`} className="ml-2 text-sm">{disbursement.disbursementType}</label>
                                    </div>
                                ))
                            ) : (
                                <p>No Disbursement Types available</p>
                            )}
                        </div>
                    )}
                </div>

                <div className="flex col-span-2 gap-2 justify-end my-5">
                    <button
                        type='button'
                        className="bg-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                        onClick={handleSelectFeeOrDisbursmentClick}

                    >
                        Select
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );

}

export { AddOfficeFeeTypesDisbursement };
