import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllCreditNote = async () => {
    try {
        const response = await axiosInstance.post("OACPCreditNoteSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostCreditNote = async (data: any) => {
    try {
        const response = await axiosInstance.post("OACPCreditNoteSaveOrUpdate", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutCreditNote = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`CreditNote/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteCreditNote = async (id: number) => {
    try {
        await axiosInstance.delete(`/CreditNote/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const CreditNoteService = {
    GetAllCreditNote,
    PostCreditNote,
    PutCreditNote,
    deleteCreditNote
};


export default CreditNoteService;
