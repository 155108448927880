import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";




const GetAllInvoiceNotice = async () => {
    try {
        const response = await axiosInstance.get("InvoiceNote");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostInvoiceNotice = async (data: any) => {
    try {
        const response = await axiosInstance.post("InvoiceNote", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutInvoiceNotice = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`InvoiceNote/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteInvoiceNotice = async (id: number) => {
    try {
        await axiosInstance.delete(`/InvoiceNote/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const InvoiceNoticeService = {
    GetAllInvoiceNotice,
    PostInvoiceNotice,
    PutInvoiceNotice,
    deleteInvoiceNotice
};


export default InvoiceNoticeService;
