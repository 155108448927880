import { useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useAppDispatch } from "../../../../store/hooks/redux-hooks";
import {
  MainAnimation,
  SelectBox,
} from "../../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addRiskAssesmentQuestion, fetchRiskAssesmentQuestion } from "../../../../store/slice/SystemMaintenance/TaskAndAssesment/TaskRiskAssesmentSlice";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { RiskAssesmentQuestion } from "../../../../store/model/SystemMaintenance/TaskAssesment/TaskRiskAssesmentInterface";
import { fetchWorkflowDataById } from "../../../../store/slice/SystemMaintenance/WorkflowSetupSlice";


type Option = {
  name: string;
  score: string;
};

type Question = {
  riskAssessmentQuestion: string;
  riskAssessmentQuestionType: string;
  options: Option[];
  isScored: boolean;
};

const AddSystemRiskAssessment = () => {
  const { referenceNumber } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [formData, setFormData] = useState({
    riskAssessmentQuestion: "",
    riskAssessmentQuestionType: '',
  });
  const [isOptionVisible, setIsOptionVisible] = useState<boolean>(false);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [isAddQuestionVisible, setIsAddQuestionVisible] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isScored, setIsScored] = useState(false);
  const rAQuestion = useSelector((state: RootState) => state.taskRiskAssesment.rAQuestion);
  const [rAQuestionDataList, setRAQuestionDataList] = useState<any[]>([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const callreferenceNumber = useRef<Set<number>>(new Set());

  useEffect(() => {
    if (referenceNumber !== null && !callreferenceNumber.current.has(Number(referenceNumber))) {
      callreferenceNumber.current.add(Number(referenceNumber));

      const data = {
        riskAssessmentNameId: Number(referenceNumber),
      };
      dispatch(fetchRiskAssesmentQuestion(data))      
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
    }
  }, [dispatch, referenceNumber]);

  useEffect(() => {
    if (rAQuestion !== null && rAQuestion.listingData.length > 0) {
      setRAQuestionDataList(rAQuestion.listingData)
      console.log(rAQuestion.listingData)
    }
  }, [rAQuestion])


  const handleAddOptionClick = () => {
    setIsOptionVisible(true);
    setOptions([...options, { name: "", score: "" }]);
  };

  const handleDeleteOptionClick = (index: number) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
  };

  // Handle change for option name and score inputs
  const handleOptionChange = (index: number, field: string, value: string) => {
    const updatedOptions = [...options];
    updatedOptions[index] = { ...updatedOptions[index], [field]: value };
    setOptions(updatedOptions);
  };
  const handleAddStepClick = () => {
    setIsFormVisible(true);

  };

  const handleSelectChange = (selectedOption: any) => {
    console.log(selectedOption);
    const valuesWithOptions = ["radio", "select", "checkbox"];
    setIsAddQuestionVisible(valuesWithOptions.includes(selectedOption));
    setFormData({ ...formData, riskAssessmentQuestionType: selectedOption });

  };


  const handleCancelClick = () => {
    setIsFormVisible(false);
  };

  const riskAssessmentQuestionType = [
    { label: "Sigle Text", value: "text" },
    { label: "Multiple Text", value: "textarea" },
    { label: "Number", value: "number" },
    { label: "options Button", value: "radio" },
    { label: "Combo Box", value: "select" },
    { label: "Multiple Selection", value: "checkbox" },
    { label: "Browse Document", value: "document" },
    { label: "Browse Image", value: "image" },
    { label: "Date", value: "date" },
  ];

  const mappedRiskAssessmentQuestionType = riskAssessmentQuestionType.map(
    (type: any) => ({
      label: type.label,
      value: type.value,
    })
  );
  const handleSave = async () => {
    const questionData: RiskAssesmentQuestion = {
      riskAssessmentQuestionId: 0,
      fkRiskAssessmentNameId: Number(referenceNumber),
      riskAssessmentQuestion: formData.riskAssessmentQuestion,
      riskAssessmentQuestionType: formData.riskAssessmentQuestionType,
      riskAssessmentAnswerList: options.map(option => ({
        answer: option.name,
        scorePercentage: option.score,
        riskAssessmentQuestionAnswerId: 0,
      })),
      isScore: isScored,
    };

    setIsLoading(true);
    try {
      await dispatch(addRiskAssesmentQuestion(questionData)).unwrap();
      toast.success("Successfully Added Assessment Question");
    } catch (error) {
      toast.error("Failed to add question");
    } finally {
      setIsLoading(false);
    }
  };

  // const resetForm = () => {
  //   setFormData({
  //     riskAssessmentQuestion: '',
  //     riskAssessmentQuestionType: '',
  //     options: [],
  //     isScored: false,
  //   });
  // };


  const handleEditClick = (question: Question, index: number) => {
    setFormData(question);
    setEditingIndex(index);
  };

  const handleDeleteClick = (index: number) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };


  const onCancelRedirect = () => {
    navigate("/systemmanagement/systemmaintenance/Systemriskassessment");
  };

  return (
    <>

      <div className="mt-6 flex-col w-full gap-4 p-10 md:p-2 lg:p-2">
        <div className="">
          <button
            className="flex items-center bg-blue-500 text-white-bg px-3 py-1 rounded hover:bg-blue-600 transition duration-300"
            type="button"
            onClick={handleAddStepClick}
          >
            <FaPlus className="w-4 h-4 fill-white-bg" />
            <span className="text-white-bg mx-2">Add Question</span>
          </button>
        </div>

        {isFormVisible && (
          <div className="mt-4">
            <div className="grid grid-cols-4 md:grid-cols-1 gap-x-5">
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  value={formData.riskAssessmentQuestion}
                  onChange={(e) => setFormData({ ...formData, riskAssessmentQuestion: e.target.value })}
                />

              </div>
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Type <span className="text-red-500">*</span>
                </label>
                <SelectBox
                  className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  isSearchable={true}
                  options={mappedRiskAssessmentQuestionType}
                  placeholder="Select"
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  value={formData.riskAssessmentQuestionType}
                  //onChange={(e) => setFormData({ ...formData, riskAssessmentQuestionType: e.target.value })}
                  onChange={handleSelectChange}
                />
              </div>
            </div>

            {isAddQuestionVisible && (
              <div className="flex flex-col gap-2 mt-2">
                <div className="">
                  <button
                    className="flex items-center bg-blue-500 text-white-bg px-3 py-1 rounded hover:bg-blue-600 transition duration-300"
                    type="button"
                    onClick={handleAddOptionClick}
                  >
                    <FaPlus className="w-4 h-4 fill-white-bg" />
                    <span className="text-white-bg mx-2">Add Option</span>
                  </button>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    type="checkbox"
                    onChange={() => setIsScored(!isScored)}
                    checked={isScored}
                  />
                  <label>
                    Score this question
                  </label>
                </div>
              </div>
            )}

            {isAddQuestionVisible &&
              isOptionVisible &&
              options.map((option, index) => (
                <div className="mt-4">
                  <div className="grid grid-cols-4 gap-x-5 md:gap-x-2">
                    <div className="">
                      <input
                        type="text"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                        onChange={(e) =>
                          handleOptionChange(index, "name", e.target.value)
                        }
                        placeholder="option Name"
                      />
                    </div>
                    <p className="text-center dark:text-white-bg">
                      Score (%)
                    </p>
                    <div className="">
                      <input
                        type="text"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                        value={option.score}
                        onChange={(e) =>
                          handleOptionChange(index, "score", e.target.value)
                        }
                        placeholder="Option Score"
                      />
                    </div>
                    <div className="flex items-start justify-center">
                      <button type="button" onClick={() => handleDeleteOptionClick(index)}>
                        <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}

            <div className="flex flex-row gap-2 items-end justify-end w-full">
              <button
                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                type="button"
                onClick={() => {
                  setOptions([]);
                  onCancelRedirect();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSave}
                disabled={isLoading}
                className={`cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white-bg mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        )}

        <div className="relative overflow-x-auto sm:rounded-lg mt-4">
          {initialLoading ? (<MainAnimation />) : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3">
                  Question Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {rAQuestionDataList.map((question, index) => (
                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <td className="px-6 py-3">{question.riskAssessmentQuestion}</td>
                  <td className="px-6 py-3">{question.riskAssessmentQuestinoType}</td>
                  <td className="cursor-pointer px-6 py-3">
                    <div className="flex gap-3 w-full">
                      {/* <FaArrowsAlt className="fill-gray-500 w-6 h-6" /> */}
                      <FaEdit className="fill-blue-500 w-6 h-6" onClick={() => handleEditClick(question, index)} />
                      <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" onClick={() => handleDeleteClick(index)} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export { AddSystemRiskAssessment };
