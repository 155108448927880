import React from 'react';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    totalRecords: number;
    onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages,  totalRecords, onPageChange }) => {


    return (
        <div className="flex flex-row justify-between md:justify-start w-full h-auto my-3 md:flex-col md:my-0 md:h-36 md:gap-2">
            <div className="flex flex-row gap-3 items-start">
                <span className="font-medium dark:text-white-bg">Matching Records: { totalRecords}</span>
                <span className="dark:text-white-bg">Page: {currentPage}/{totalPages}</span>
            </div>

            <div className="flex md:flex-row items-start justify-start space-x-1 dark:text-gray-800">
                <button
                    title="previous"
                    type="button"
                    disabled={currentPage === 1}
                    onClick={() => onPageChange(currentPage - 1)}
                    className="inline-flex items-center justify-center w-8 h-8 text-sm font-semibold border hover:border-blue-500 rounded shadow dark:text-gray-400 dark:bg-gray-900 hover:bg-blue-500 hover:dark:bg-blue-500 hover:dark:text-white-bg"
                >
                    <svg viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="w-4">
                        <polyline points="15 18 9 12 15 6"></polyline>
                    </svg>
                </button>

                {totalPages > 0 && [...Array(totalPages)].map((_, index) => (
                    <button
                        key={index + 1}
                        type="button"
                        title={`Page ${index + 1}`}
                        onClick={() => onPageChange(index + 1)}
                        className={`inline-flex items-center justify-center w-8 h-8 text-sm font-semibold border hover:border-blue-500 rounded shadow dark:text-gray-400 dark:bg-gray-900 hover:bg-blue-500 hover:dark:bg-blue-500 hover:dark:text-white-bg ${currentPage === index + 1 ? 'bg-blue-500 text-white' : ''
                            }`}
                    >
                        {index + 1}
                    </button>
                ))}

                <button
                    title="next"
                    type="button"
                    disabled={currentPage === totalPages}
                    onClick={() => onPageChange(currentPage + 1)}
                    className="inline-flex items-center justify-center w-8 h-8 text-sm font-semibold border hover:border-blue-500 rounded shadow dark:text-gray-400 dark:bg-gray-900 hover:bg-blue-500 hover:dark:bg-blue-500 hover:dark:text-white-bg"
                >
                    <svg viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="w-4">
                        <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                </button>
            </div>
        </div>
    )
}

export { Pagination };