import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";






const GetAllBudget = async () => {
    try {
        const response = await axiosInstance.post("OABudgetSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostBudget = async (data: any) => {
    try {
        const response = await axiosInstance.post("/Budget", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutBudget = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`Budget/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteBudget = async (id: number) => {
    try {
        await axiosInstance.delete(`/Budget/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const BudgetService = {
    GetAllBudget,
    PostBudget,
    PutBudget,
    deleteBudget
};


export default BudgetService;
