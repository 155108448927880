import { useEffect, useRef, useState } from "react";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllFinancialYearEnd } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/FinancialYearEndSlice";
import { toast } from "react-toastify";
import { Pagination } from "flowbite-react";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { SelectBox } from "../SelectBox";
function FinancialYearEnd() {
  const [finacialYearList, setFinancialYearList] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(false);
  const { financialYearEnds, status, error } = useSelector(
    (state: RootState) => state.financialYearEnd
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentFinancialYearEnd, setCurrentFinancialYearEnd] = useState<any[]>(
    []
  );
  const [financialYearId, setFinancialYearId] = useState<string | null>(null);
  const { financialYears } = useSelector((state: RootState) => state.financialYear);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (financialYears != null && (financialYears.dataList != null && financialYears.dataList.length > 0)) {
      let data = financialYears.dataList.map((item: any) => ({
        value: item.financialYearId,
        label: `${item.startDate.split("T")[0]} - ${item.endDate.split("T")[0]}`,

      }));
      setFinancialYearList(data);
    }
  }, [financialYears]);

  useEffect(() => {
    if (financialYearEnds != null && financialYearEnds.length > 0) {
      const indexOfLastFinancialYearEnd = currentPage * itemsPerPage;
      const indexOfFirstFinancialYearEnd =
        indexOfLastFinancialYearEnd - itemsPerPage;
      const currentFinancialYearEnd = financialYearEnds.slice(
        indexOfFirstFinancialYearEnd,
        indexOfLastFinancialYearEnd
      );
      const totalPages = Math.ceil(financialYearEnds.length / itemsPerPage);
      setTotalPages(totalPages);
      setCurrentFinancialYearEnd(currentFinancialYearEnd);
    }
  }, [financialYearEnds]);

  const handleSearchClick = async () => {
    setInitialLoading(true);
    const data = {
      financialYearId: financialYearId,
    };
    try {
      await dispatch(fetchAllFinancialYearEnd(data)).unwrap();
      toast.success("Successfully Search Financial Year");
    } catch (error: any) {
      toast.error(error?.message || "An error occurred while fetching data");
    } finally {
      setInitialLoading(false);
    }
  };


  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle title="Financial Year End" />
        </div>
        <div className="flex flex-row md:flex-col my-5 gap-2 w-full md:gap-3">
          <div className="flex gap-4 md:gap-1">
            <div className="w-72">
              <SelectBox
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={finacialYearList}
                isSearchable={true}
                placeholder="FinacialYear"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setFinancialYearId(selectedOption);
                  }
                }}
              />
            </div>
          </div>
          <div>
            <button
              className="z-[2] inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
              onClick={handleSearchClick}
              type="button"
            >
              Search
            </button>
          </div>
        </div>

        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto max-h-96">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Financial Year
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Trial Balance
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Profit & Loss Statement
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Balance Sheet
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentFinancialYearEnd.map((financialYearEnd: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {financialYearEnd.finalcialYear}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{financialYearEnd.trialBalance}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {financialYearEnd.profitLossStatement}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{financialYearEnd.balanceSheet}</td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <button className="text-xs py-1 px-3 font-medium text-white-bg bg-green-500 rounded hover:bg-green-600">
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          {currentFinancialYearEnd.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Final Year End Details found.</p>
            </div>
          )}
        </div>
        {currentFinancialYearEnd.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { FinancialYearEnd };
