import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllTrialBalance = async (data: any) => {
    try {
        const response = await axiosInstance.post("officeaccount/officereport/trialbalance", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetTrialBalanceById = async (data: any) => {
    try {
        const response = await axiosInstance.post("officeaccount/officereport/trialbalance/details", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const TrialBalanceService = {
    GetAllTrialBalance,
    GetTrialBalanceById
};


export default TrialBalanceService;
