import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Task } from "../../model/CaseManagement/TaskInterface";
import TaskService from "../../../services/CaseManagement/tasksService";
import { PaginationResponse } from "../../model/Pagination/paginationClass";

// Define the state type for tasks
type TasksState = {
  tasks: Task;
  status: "idle" | "loading" | "failed";
  error: string | null;
};

// Initial state
const initialState: TasksState = {
  tasks: null!,
  status: "idle",
  error: null
};

// Async thunks for CRUD operations
export const fetchAllTask = createAsyncThunk(
  "fetchAllTask",
  async (data: any, thunkAPI) => {
    try {
      const response = await TaskService.GetAllTask(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addTask = createAsyncThunk(
  "addTask",
  async (data: any, thunkAPI) => {
    try {
      const response = await TaskService.PostTask(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateTask = createAsyncThunk(
  "updateTask",
  async ({ id, data }: { id: number, data: Task }, thunkAPI) => {
    try {
      const response = await TaskService.PutTask(id, data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "deleteTask",
  async (id: number, thunkAPI) => {
    try {
      await TaskService.deleteTask(id);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Slice definition
const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTask.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllTask.fulfilled, (state, action: PayloadAction<Task>) => {
        state.status = "idle";
        state.tasks = action.payload;
      })
      .addCase(fetchAllTask.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(addTask.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addTask.fulfilled, (state, action: PayloadAction<Task>) => {
        state.status = "idle";
        //state.tasks.push(action.payload);
      })
      .addCase(addTask.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(updateTask.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTask.fulfilled, (state, action: PayloadAction<Task>) => {
        state.status = "idle";
       // const index = state.tasks.findIndex(t => t.id === action.payload.id);
       // if (index !== -1) {
       //   state.tasks[index] = action.payload;
       // }
      })
      .addCase(updateTask.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(deleteTask.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTask.fulfilled, (state, action: PayloadAction<number>) => {
        state.status = "idle";
      //  state.tasks = state.tasks.filter(t => t.id !== action.payload);
      })
      .addCase(deleteTask.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  }
});

export default taskSlice.reducer;
