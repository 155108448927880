import { Modal } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number, boolean, date } from "yup";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { SelectBox } from "../../../../SelectBox";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { InputBox } from "../../../../InputBox";
import { useParams } from "react-router-dom";
import { addCorrUploadDocument } from "../../../../../store/slice/CaseManagement/Case/Correspondence/AddItem/CorrAddItemsSlice";

function AddUploadDocument({
  openDrawer,
  Close,
  documentTabsDisplay,
}: {
  openDrawer: boolean;
  Close: any;
  documentTabsDisplay: boolean;
}) {
  const dispatch = useAppDispatch();
  const { referenceNumber } = useParams();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const caseDetailsData = useSelector((state: RootState) => state.case.caseDto);
  const [isLoading, setIsLoading] = useState(false);
  const [docFileName, setDocFileName] = useState('');
  const [documentFile, setDocumentFile] = useState<any>();


  useEffect(() => {
    if (referenceNumber) {
      setValue("caseId", Number(referenceNumber))
    }
  }, [referenceNumber])

  const MethodData = [
    { label: "Email", value: "email" },
    { label: "Post", value: "post" },
    { label: "Other", value: "other" },
    { label: "Inperson", value: "inperson" },
  ];

  const MethodDataOption = MethodData.map((type: any) => ({
    label: type.label,
    value: type.value,
  }));

  const validationSchema = object().shape({
    documentTitle: string().required('Please Enter Document title'),
    description: string(),
    method: string(),
    dateSentReceived: string(),
    documentFiles: string(),
    documentReference: string(),
    isAntiMoneyLaunderingMeasure: boolean(),
    isTitleDocument: boolean(),
    isSearchDocument: boolean(),
    caseId: number(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      documentReference: "sent",
      isAntiMoneyLaunderingMeasure: false,
      isTitleDocument: false,
      isSearchDocument: false
    },
  });

  const onSubmit = async (data: any) => {
    if (documentFile == null) {
      toast.error("Please select the file");
      return;
    }

    data.documentFiles = docFileName;

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('file', documentFile);

    console.log(JSON.stringify(data, null, 2));

    setIsLoading(true);
    try {
      await dispatch(addCorrUploadDocument(formData)).unwrap();
      Close();
      toast.success("Successfully Added Document Upload ");
      Close();
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };
  const onError = (errors: any) => {
    console.log(errors);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setDocumentFile(file);
      setDocFileName(file.name);
    } else {
      setDocFileName('');
    }
  };

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        Close();
      }}
      initialFocus={emailInputRef}
      size="2xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
          Add New Document Title
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto">
          <div className="space-y-3 w-full">
            <div className="bg-sky-50 dark:bg-gray-900 p-5 space-y-2">
              <div className="flex items-center">
                <label className="w-1/4 text-sm font-semibold dark:text-white-bg">
                  Case Type
                </label>
                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-3/4 text-sm dark:text-white-bg">
                  {caseDetailsData?.area}
                  {caseDetailsData?.caseType && ` > ${caseDetailsData.caseType}`}
                  {caseDetailsData?.subCaseType && ` > ${caseDetailsData.subCaseType}`}
                </p>
              </div>
              <div className="flex items-center">
                <label className="w-1/4 text-sm font-semibold dark:text-white-bg">
                  Case Ref
                </label>
                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-3/4 text-sm dark:text-white-bg">
                  {caseDetailsData?.caseReferenceAuto}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-x-3">
              {/* <InputBox
                title="Document Title"
                dataType="text"
                placeholder="Enter Document Title"
                {...register('documentTitle')}
              /> */}
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Document Title
                </label>
                <input
                  type="text"
                  placeholder="Enter Document Title"
                  {...register('documentTitle')}
                  className={`border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full p-3 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.documentTitle ? "is-invalid" : ""
                    }`} // Apply red border if there's an error
                />
                <div className="font-medium mt-2 text-xs text-red-600">
                  {errors?.documentTitle?.message?.toString()}
                </div>
              </div>
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  documentFiles
                </label>
                <input
                  className={`block w-full text-sm text-gray-900 border-2 border-gray-300 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${errors.documentFiles ? "is-invalid" : ""
                    } `}
                  {...register("documentFiles")}
                  type="file"
                  multiple
                  onChange={handleFileChange}
                />
                <div className="font-medium mt-2 text-xs text-red-600">
                  {errors?.documentFiles?.message?.toString()}
                </div>
              </div>
            </div>
            {/* need to check this content ---------------------------> */}
            <div className="flex flex-col gap-2  my-5">
              <div className="flex content-center">
                <input
                  type="checkbox"
                  onChange={(e) => setValue("isAntiMoneyLaunderingMeasure", e.target.checked ? true : false)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Is an Anti Money Laundering Measure?{" "}
                </label>
              </div>
              {(documentTabsDisplay === true) && (
                <>
                  <div className="flex content-center">
                    <input
                      onChange={(e) => setValue("isTitleDocument", e.target.checked ? true : false)}
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Is Title Document?{" "}
                    </label>
                  </div>
                  <div className="flex content-center">
                    <input
                      onChange={(e) => setValue("isSearchDocument", e.target.checked ? true : false)}
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Is Search Document?{" "}
                    </label>
                  </div>
                </>
              )}
            </div>

            {/*  */}
            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Description
              </label>
              <textarea
                className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                placeholder="Write your thoughts here..."
                {...register("description")}
              ></textarea>
            </div>

            <div className="flex items-center justify-end">
              <div className="flex items-center  me-4">
                <input
                  type="radio"
                  value="sent"
                  checked={watch("documentReference") === "sent"}
                  onChange={() => setValue("documentReference", "sent")}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Sent
                </label>
              </div>
              <div className="flex items-center me-4">
                <input
                  type="radio"
                  value="received"
                  checked={watch("documentReference") === "received"}
                  onChange={() => setValue("documentReference", "received")}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Received
                </label>
              </div>
              <div className="flex items-center me-4">
                <input
                  type="radio"
                  value="other"
                  checked={watch("documentReference") === "other"}
                  onChange={() => setValue("documentReference", "other")}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Other
                </label>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-x-3">
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Method
                </label>
                <SelectBox
                  className="border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  isSearchable={true}
                  placeholder="Select"
                  options={MethodDataOption}
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setValue("method", selectedOption);
                    }
                  }}
                />
              </div>
              {/* <InputBox
                title="Date"
                dataType="Date"
                placeholder="Enter Document Title"
                required={false}
                {...register("dateSentReceived")}
              /> */}
              <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Date
                </label>
                <input
                  type="date"
                  placeholder="Enter Document Date"
                  {...register('dateSentReceived')}
                  className={`border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full p-3 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} // Apply red border if there's an error
                />
              </div>
            </div>
            <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  type="button"
                  onClick={() => {
                    reset();
                    Close();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white-bg mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddUploadDocument };
