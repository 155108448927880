import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineRefresh } from "react-icons/md";
import { AddBankReceipt } from "../Modal/AddClientAccounts/AddClientPostings/AddBankReceipt";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  deleteBankReceipt,
  fetchAllBankReceipt,
} from "../../store/slice/Accounting/ClientAccounts/ClientPosting/BankReceiptSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import DeleteModal from "../Modal/DeleteModal";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { ReverseMainDrawer } from "../Drawer/ReverseMainDrawer";
import { ViewMainDrawer } from "../Drawer/ViewMainDrawer";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";

function BankReceipt() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isReverse, setIsReverse] = useState(false);
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(null);
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(null);
  const [bankReceiptData, setBankReceiptData] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const bankRecpits = useSelector(
    (state: RootState) => state.bankReceipt.searchBankReciptResult
  );
 
  const bankRecpit = useSelector(
    (state: RootState) => state.bankReceipt.searchBankReciptResult
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentBankReceipts, setCurrentBankReceipts] = useState<any[]>([]);
  const [recepitDatas, setRecepitDatas] = useState<any[]>([]);
  const itemsPerPage = 10;
 
  const [openReverseDrawer, setOpenReverseDrawer] = useState(false);
  const handleDueStartDateChange = (e: any) => setDueStartDate(e.target.value);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [bankReceiptId, setBankReceiptId] = useState<number>(0);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  
  
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      dispatch(fetchAllBankReceipt())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch, isOnAddedOrUpdate]);

  useEffect(() => {
    if (bankRecpits && bankRecpits.bankRecriptResult) {
      setRecepitDatas(bankRecpits.bankRecriptResult);
    }
  }, [bankRecpits]);

  const filterBankReceiptBySearchQuery = () => {
    const filtered = recepitDatas.filter(
      (bankReceipt: any) =>
        bankReceipt?.firstName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        bankReceipt?.lastName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        bankReceipt?.paymentType
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        bankReceipt?.caseName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    // console.log("Filtered data:", filtered); // Check what's being filtered
    return filtered;
  };

  useEffect(() => {
    if (recepitDatas && recepitDatas.length > 0) {
      // Make sure data is available
      const filteredByBankReceipt = filterBankReceiptBySearchQuery();
      const indexOfLastBankReceipt = currentPage * itemsPerPage;
      const indexOfFirstBankReceipt = indexOfLastBankReceipt - itemsPerPage;
      const currentBankReceipt = filteredByBankReceipt.slice(
        indexOfFirstBankReceipt,
        indexOfLastBankReceipt
      );
      const totalPages = Math.ceil(filteredByBankReceipt.length / itemsPerPage);
      setTotalPages(totalPages);
      setCurrentBankReceipts(currentBankReceipt);
    } else {
      setCurrentBankReceipts([]);
    }
  }, [recepitDatas, searchQuery, currentPage]);

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const toggleReverseDrawer = () => {
    setIsReverse(false);
    setOpenReverseDrawer(!openReverseDrawer);
  };

  

  const onDeleteCall = () => {
    try {
      dispatch(deleteBankReceipt(bankReceiptId)).unwrap();
      setOpenDelModal(false);
      toast.success("Successfully Deleted BankReceipt");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  if (openModal) {
    return (
      <AddBankReceipt
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        isView={isView}
        isReverse={false}
        bankReceipt={bankReceiptData}
      />
    );
  }

  if (openReverseDrawer) {
    return (
      <ReverseMainDrawer
        Close={toggleReverseDrawer}
        openCaseDrawer={openReverseDrawer}
        drawerContent="bankReceipt"
        getData={bankReceiptData}
      />
    );
  }

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="CPBankReciept"
        getData={bankReceiptData}
        title="BankReceipt"
      />
    );
  }

  return (
    <>
      <div className="">
        {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={bankReceiptId}
            title="Bank Receipt"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="Bank Receipt"
            buttonName="Receipt"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-col md:flex-col justify-between gap-7 w-full md:gap-3">
          <div className="flex w-72">
            <SearchInput title="Keyword" />
          </div>
          <div className="bg-gray-100 p-3 border border-gray-300 rounded mb-5">
            <div
              className="grid grid-cols-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-20 3xl:gap-2 md:gap-1 w-full"
            >
              <CheckBoxFilter title="Client Bank" />
              {/* <CheckBoxFilter title="Areas of Practice" /> */}
              <CheckBoxFilter title="Case Type" />
              {/* <CheckBoxFilter title="Case Sub Type" /> */}
              <CheckBoxFilter title="Staff" />
              <CheckBoxFilter title="Status" />
              <CheckBoxFilter title="Payment Type" />
              <JustifyDateFilter
                title="Date Between"
                plannedStartDate={plannedStartDate}
                plannedEndDate={plannedEndDate}
                onPlannedStartDateChange={handleStartDateChange}
                onPlannedEndDateChange={handleEndDateChange}
              />
            </div>
          </div>
        </div>

        <div className="relative overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Client and Case Details
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                 Transaction Details
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Payment Method
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Amount
                </th>
                {/* <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Receipt
                </th> */}
                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentBankReceipts.map((bankReceipt: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                     {formatDateOnlyDisplay(bankReceipt.receiptDate)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <p>{bankReceipt.individualNames || bankReceipt.organisationNames || bankReceipt.companyNames}</p>
                    <p>{bankReceipt.caseReferenceAuto}</p> <br />
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{bankReceipt.transactionDescription}</td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {bankReceipt.paymentType}
                    <br />
                    {formatDateOnlyDisplay(bankReceipt.entryDate)}
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{formatCurrency(bankReceipt.amount)}</td>
                  {/* <td className="px-6 py-3">
                    <a className="hover:text-blue-500" href="">
                      Rec{bankReceipt.receiptNumber}
                    </a>
                  </td> */}

                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setIsView(true);
                          setIsEdit(false);
                          setBankReceiptData(bankReceipt);
                        }}
                      />
                      <FaEdit
                        className="fill-blue-500 w-6 h-6"
                        onClick={() => {
                          setOpenModal(true);
                          setIsEdit(true);
                          setBankReceiptData(bankReceipt);
                        }}
                      />
                      <RiDeleteBin5Fill
                        className="fill-red-600 w-6 h-6"
                        onClick={() => {
                          setOpenDelModal(true);
                          setBankReceiptId(bankReceipt.clientReceiptId);
                        }}
                      />
                      {/* <MdOutlineRefresh className="fill-blue-800 w-6 h-6"
                        onClick={() => {
                          setOpenReverseDrawer(true);
                          setBankReceiptData(bankReceipt);
                        }}
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {currentBankReceipts.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Bank New Receipts found.</p>
            </div>
          )}
        </div>
        {currentBankReceipts.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={bankReceiptData.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { BankReceipt };
function setDueStartDate(value: any) {
  throw new Error("Function not implemented.");
}
