import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllClientPaymentType = async () => {
    try {
        const response = await axiosInstance.get("PaymentType");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const ClientPaymentTypeService = {
    GetAllClientPaymentType,
    
};


export default ClientPaymentTypeService;
