// Company

import { yupResolver } from "@hookform/resolvers/yup";
import { error } from "console";
import { useForm } from "react-hook-form";
import { number, object, string } from "yup";
import { SelectBox } from "../../../SelectBox";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { useEffect } from "react";


function AddComContactOne({ onSubmitStep, initialData, contactType, contactCategory, onCancel }: any) {
    const contactDataById = useSelector((state: RootState) => state.contact.contactDataById);

    const contactCategoryOption = contactCategory ? contactCategory.map((contactCategories: any) => ({
        label: contactCategories.contactCategory,
        value: contactCategories.contactCategoryId
    })) : [];

    const validationSchema = object().shape({
        fkContactTypeId: number(),
        fkCompanyContactsCategoryId: string(),
        companyName: string()
            .required("Company Name Required"),
        companyNature: string(),
        companyEmail: string(),
        companyFax: string(),
        companyWeb: string(),
        companyVat: string(),
        companyUtrn: string(),
        companyRegNo: string(),
        companyContactPhone1: string(),
        companyContactPhone2: string(),
        companyContactFax: string(),
    })

    const { register, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...initialData,
        }
    });

    const handleFormSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        onSubmitStep(data);
    };

    useEffect(() => {
        if (contactDataById !== null) {
            setValue("fkContactTypeId", contactDataById?.fkContactTypeId);
            setValue("fkCompanyContactsCategoryId", contactDataById?.fkCompanyContactsCategoryId);
            setValue("companyName", contactDataById?.companyName);
            setValue("companyNature", contactDataById?.companyNature);
            setValue("companyEmail", contactDataById?.companyEmail);
            setValue("companyFax", contactDataById?.companyFax);
            setValue("companyWeb", contactDataById?.companyWeb);
            setValue("companyVat", contactDataById?.companyVat);
            setValue("companyUtrn", contactDataById?.companyUtrn);
            setValue("companyRegNo", contactDataById?.companyRegNo);
            setValue("companyContactPhone1", contactDataById?.companyContactPhone1);
            setValue("companyContactPhone2", contactDataById?.companyContactPhone2);
            setValue("companyContactFax", contactDataById?.companyContactFax);
        }
    }, [contactDataById])

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="grid grid-cols-4 gap-x-10">
                <div className="col-start col-span-2">
                    <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mt-1 mb-5">Contact Category</h3>
                    <div className=" border dark:text-white-bg dark:border dark:border-gray-400 dark:border-opacity-35 p-6">
                        <div className="grid grid-cols-3">
                            <label className="col-span-1 text-sm font-medium text-gray-900 dark:text-white-bg">Select Category <span className="text-red-500">*</span></label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={contactCategoryOption}
                                isSearchable={true}
                                value={getValues().fkCompanyContactsCategoryId}
                                placeholder="Contact Category"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('fkCompanyContactsCategoryId', selectedOption);
                                    }
                                }}
                            />
                            <div className="col-start-2 font-medium mt-2 text-xs text-left text-red-600" >{errors?.fkCompanyContactsCategoryId?.message?.toString()}</div>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-lg mt-2 font-bold text-left text-gray-900 dark:text-white-bg mb-5">Company Details</h3>
            <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-2 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Name <span className="text-red-500">*</span>
                    </label>
                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.companyName ? 'is-invalid' : ''}`} {...register('companyName')} />
                    <div className="font-medium mt-2 text-xs text-left text-red-600" >{errors?.companyName?.message?.toString()}</div>
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Co. Reg Number
                    </label>
                    <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyNature')} />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Email Address
                    </label>
                    <input type="email" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                        {...register('companyEmail')} />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Website
                    </label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyWeb')} />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        VAT Reg Number
                    </label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyVat')} />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">UTRN</label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyUtrn')} />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">Other</label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyRegNo')} />
                </div>
            </div>
            <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">Contact Numbers</h3>
            <div className="grid grid-cols-3 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">Mobile No1</label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyContactPhone1')} />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Mobile No2
                    </label>
                    <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('companyContactPhone2')} />
                </div>
            </div>
            <div className="">
                <div className="flex flex-row gap-2 items-end justify-between w-full">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">  Cancel
                        <svg className="w-6 h-6 rotate-180 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>

                    </button>
                    <button
                        type="submit"
                        className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px]">
                        Next
                        <svg className="w-6 h-6 fill-white-bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                    </button>
                </div>
            </div>
        </form>
    );
}

export { AddComContactOne };
