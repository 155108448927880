import axiosInstance from '../axiosInstance';
import { ErrorResult } from '../../store/model/errorResult';




const GetAllDocument = async () => {
    try {
        const response = await axiosInstance.get("Document");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch Document:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch Document");
    }
}


const PostDocument = async (data: any) => {
    try {
        const response = await axiosInstance.post("Document", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Update a Document
const PutDocument = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`Document/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

// Delete a Document
const deleteDocument = async (documentId: number) => {
    try {
        await axiosInstance.delete(`/Document/${documentId}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}
const DocumentmanagementService = {
    GetAllDocument,
    PostDocument,
    PutDocument,
    deleteDocument,
};

export default DocumentmanagementService;
