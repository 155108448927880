import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";





const GetAllOfficeFundTransfer = async () => {
    try {
        const response = await axiosInstance.post("OAFundTransferSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostOfficeFundTransfer = async (data: any) => {
    try {
        const response = await axiosInstance.post("OAFundTransferSaveOrUpdate", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutOfficeFundTransfer = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`OfficeFundTransfer/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteOfficeFundTransfer = async (id: number) => {
    try {
        await axiosInstance.delete(`/OfficeFundTransfer/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const OfficeFundTransferService = {
    GetAllOfficeFundTransfer,
    PostOfficeFundTransfer,
    PutOfficeFundTransfer,
    deleteOfficeFundTransfer
};


export default OfficeFundTransferService;
