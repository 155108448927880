import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddJournalEntry } from "../Modal/AddClientAccounts/AddClientPostings/AddJournalEntry";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import { deleteJournalEntry, fetchAllJournalEntry } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/JournalEntrySlice";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import { JournalEntryDetails } from "./JournalEntryDetails";
import DeleteModal from "../Modal/DeleteModal";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { ViewMainDrawer } from "../Drawer/ViewMainDrawer";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";
function JournalEntry() {
  const [openModal, setOpenModal] = useState(false);
  const [openJournalModal, setOpenJournalModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const journalEntries = useSelector(
    (state: RootState) => state.journalEntry.journalEntryData
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [journalEntryData, setJournalEntryData] = useState<any>([]);
  const [currentJournals, setCurrentJournals] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const [journalEntryId, setJournalEntryId] = useState<number>(0);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(null);
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(null);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      dispatch(fetchAllJournalEntry())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch,isOnAddedOrUpdate]);

  useEffect(() => {
    if (journalEntries && journalEntries.journalEntryResult) {
      setJournalEntryData(journalEntries.journalEntryResult);
    }
  }, [journalEntries]);

  const filterJournalEntryBySearchQuery = () => {
    return journalEntryData.filter((journalEntry: any) =>
      journalEntry?.firstName
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase())
    );
  };

  useEffect(() => {
    if (journalEntryData != null && journalEntryData.length > 0) {
      const filteredByJournalEntry = filterJournalEntryBySearchQuery();
      const indexOfLastJournalEntry = currentPage * itemsPerPage;
      const indexOfFirstJournalEntry = indexOfLastJournalEntry - itemsPerPage;
      const currentJournalEntry = filteredByJournalEntry.slice(
        indexOfFirstJournalEntry,
        indexOfLastJournalEntry
      );
      const totalPages = Math.ceil(journalEntryData.length / itemsPerPage);
      setTotalPages(totalPages);
      setCurrentJournals(currentJournalEntry);
    }
  }, [journalEntryData]);

  const toggleDrawer = (value:string) => {
    if(value === "success"){
      setOnAddedOrUpdate(true)
    }
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  const onDeleteCall = () => {
    try {
      dispatch(deleteJournalEntry(journalEntryId)).unwrap();
      setOpenDelModal(false)
      toast.success("Successfully Deleted Journal Entry");
    } catch (error: any) {
      toast.error(error.toString());
    }
  };

  const toggleJournalDrawer = () => {
    setOpenJournalModal(!openJournalModal);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };
  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  if (openModal) {
    return (
      <AddJournalEntry
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        isView={isView}
        journalEntry={journalEntryData}
      />
    );
  }

  if (openJournalModal) {
    return (
      <JournalEntryDetails
        openModal={openJournalModal}
        Close={toggleJournalDrawer}
        isEdit={false}
      />
    );
  }

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
      Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="CPJournalEntry"
        getData={journalEntryData}
        title="JournalEntry"
      />
    );
  }
  return (
    <>
      <div className="">
      {openDelModal && (
          <DeleteModal
            open={openDelModal}
            onClose={() => setOpenDelModal(false)}
            id={journalEntryId}
            title="JournalEntry"
            message="Do you want to Delete"
            onDeleteAccept={onDeleteCall}
          />
        )}
        <div className="mb-5">
          <SubHeaderTitle
            title="Journal Entry"
            buttonName="Journal Entry"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <SearchInput title="Keyword" />
          <div>
            <div className="flex gap-4 md:gap-1 w-full">
              <JustifyDateFilter
                title="Date Between"
                plannedStartDate={plannedStartDate}
                plannedEndDate={plannedEndDate}
                onPlannedStartDateChange={handleStartDateChange}
                onPlannedEndDateChange={handleEndDateChange}
              />
            </div>
          </div>
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                {/* <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Journal Entry Id
                </th> */}
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Transaction Date
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Staff
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  JournalEntry Description
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Number of Accounts
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Total
                </th>
                <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentJournals.map((journalEntry: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  {/* <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">JE{journalEntry.journalEntryId}</td> */}
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {
                      formatDateOnlyDisplay(journalEntry.journalEntryDate)
                    }
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {journalEntry.firstName} {journalEntry.lastName}
                  </td>
                  <td className="px-6 py-3 w-[250px] border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {journalEntry.journalEntryDescription}
                  </td>
                  <td className="px-6 py-3 w-[250px] border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {journalEntry.totalEntries}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{formatCurrency(journalEntry.total)}</td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setIsView(true);
                          setIsEdit(false);
                          setJournalEntryData(journalEntry);
                        }}
                      />
                      <FaEdit className="fill-blue-500 w-6 h-6" 
                       onClick={() => {
                        setOpenModal(true);
                        setIsEdit(true);
                        setJournalEntryData(journalEntry);
                      }}
                      />
                      <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" 
                      onClick={() => {
                        setOpenDelModal(true);
                        setJournalEntryId(journalEntry.journalEntryId);
                      }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {currentJournals.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">Journal Entry Details not found.</p>
            </div>
          )}
        </div>
        {currentJournals.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={journalEntryData.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { JournalEntry };
