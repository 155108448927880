import {
  SelectBox,
} from "../../../components";
import { FcPlus } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import { useEffect, useRef, useState } from "react";
import { array, number, object, string } from "yup";
import { useForm } from "react-hook-form";
import { FaLock, FaPlus } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { addCreditNote } from "../../../store/slice/Accounting/OfficeAccounts/OfficePostings/CreditNoteSlice";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { fetchNextReceiptNumber } from "../../../store/slice/Accounting/OfficeAccounts/OfficeBankAccountSlice";
import { OfficeAccountConstant } from "../../../store/model/Accounting/OfficeAccount/officeaccountconst";
import { AddOfficeFeeTypesDisbursement } from "../../Modal/AddOfficeAccounts/AddOfficeFeeTypes&Disbursement";
import { SearchInvoices } from "../../Modal/SearchInvoice";

const AddOfficeCreditNote = ({
  openModal,
  Close,
  isEdit,
  isView,
  creditNote,
}: {
  openModal: boolean;
  Close: any;
  isEdit: boolean;
  isView: boolean;
  creditNote: any;
}) => {
  const [title] = useState(!isEdit ? "Add  Credit Note" : "Edit  Credit Note");
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement] = useState("center");
  const [isLoading, setIsLoading] = useState(false);
  const [setExistingCreditNote] = useState(creditNote);
  const dispatch = useAppDispatch();
  const { receiptNumber } = useSelector(
    (state: RootState) => state.officeBankAccount
  );
  const [initialLoading, setInitialLoading] = useState(true);
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();

  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false);

  const { invoiceNotices } = useSelector(
    (state: RootState) => state.invoiceNotice
  );
  const [invoiceNoticeLists, setInvoiceNoticeLists] = useState<any[]>([]);

  const { officeVatRates } = useSelector((state: RootState) => state.officeVatRate);
  const [officeVatRateLists, setOfficeVatRateLists] = useState<any[]>([]);

  const [lineitems, setCreditNoteRows] = useState([
    { vatRateId: null, item: "", description: "", amount: 0, vatRate: 0, vat: 0,grossAmount:0, },
  ]); // State to manage multiple rows
  const [totalVat, setTotalVat] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [remainingCredit, setRemainingCredit] = useState(0);
  const [lessCreditInvoice, setLessCreditInvoice] = useState(0);
  
  const [isDisabled, setIsDisabled] = useState(true);
  const[selectedInvoice, setSelectedInvoice] = useState('')
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [openFixedFeeInvoicesModal, setOpenFixedFeeInvoicesModal] = useState(false);
  const [selectedInvoiceNote, setSelectedInvoiceNote] = useState('');
  const [selectedInvoiceNotice, setSelectedInvoiceNotice] = useState('');
  const [selectedInvoiceNoteTitle, setSelectedInvoiceNoteTitle] = useState('');
  const [selectedInvoiceNoticeTitle, setSelectedInvoiceNoticeTitle] = useState('');


  useEffect(() => {
    const newTotalVat = lineitems.reduce(
      (acc, row) => acc + (row.amount * row.vatRate) / 100,
      0
    );
    const newTotalAmount = lineitems.reduce(
      (acc, row) => acc + row.amount + (row.amount * row.vatRate) / 100,
      0
    );
    const newSubTotal = lineitems.reduce(
      (acc, row) => acc + row.amount,
      0
    );
    const newLessCreditInvoice = lineitems.reduce(
      (acc, row) => acc - 0,
      0
    );

    const newRemainingCredit = lineitems.reduce((acc, row) => acc - 0, 0);

    setSubTotal(newSubTotal);
    setTotalVat(newTotalVat);
    setTotalAmount(newTotalAmount);
    setLessCreditInvoice(newLessCreditInvoice);
    setRemainingCredit(newRemainingCredit);
  }, [lineitems]);

  const handleRowChange = (index: number, field: string, value: any) => {
    const updatedRows = [...lineitems];
    updatedRows[index] = { ...updatedRows[index], [field]: value };
    setCreditNoteRows(updatedRows);
  };

  const addRow = () => {
    setCreditNoteRows([
      ...lineitems,
      { vatRateId: null,item: "", description: "", amount: 0, vatRate: 0, vat: 0, grossAmount:0, },
    ]); // Add a new row
  };

  const removeRow = (index: number) => {
    const updatedRows = lineitems.filter((_, i) => i !== index); // Remove the row at the given index
    setCreditNoteRows(updatedRows);
  };

  const onSubmit = async (data: any) => {
    const invoiceData = {
      ...data,
      lineItems: lineitems.map((line:any, index: number) =>({
        item: line.description,
        amount:line.amount,
        vatRateId:line.vatRateId,
        vat:line.vat,
        grossAmount: line.grossAmount,
      })), // Include table data in the submission
      totalVat,
      totalAmount,
    };

    //console.log(JSON.stringify(invoiceData, null, 2));
    setIsLoading(true);
    try {
      await dispatch(addCreditNote(invoiceData)).unwrap(); // Dispatch with the combined data
      Close("success");
      toast.success("Successfully Added CreditNote");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const toggleDescriptionModal = () => {
    setOpenDescriptionModal(!openDescriptionModal);
  };

  const handleSelect = (value: any) => {
   // console.log(value);
    let description = "";
    let selectedId = null;
    if (value != null && value.selectedType === "feeType") {
      description = value.data.feeType;
      selectedId = value.data.feeTypeId;
    } else {
      description = value.data.disbursementType;
      selectedId = value.data.disbursementTypeId;
    }

    const updatedRows = [...lineitems];
    updatedRows[selectedRow].description = description;
    updatedRows[selectedRow].vatRateId = selectedId;
    //updatedRows[selectedRow].type = value.selectedType;
    setCreditNoteRows(updatedRows);
    setSelectedValue(value);
    setOpenDescriptionModal(false);
  };

  const validationSchema = object().shape({
    creditNoteNumber: number(),
    invoiceId: number().required("invoice Id is required"),
   // invoiceDetail: string(),
    client: string(),
    invoiceAmount: number(),
    vat: number(),
    totalAmount: number(),
    remainingCredit: number(),
    totalPaid: string(),
    balanceDue: string(),
    details: string(),
    creditNoteDate: string()
      .required("Transaction Date is required")
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split("T")[0];
      }),
    reason: string(),
    invoiceNotesTitle: string(),
    notes: string(),
    invoiceNoticeTitle: string(),
    invoiceNotices: string(),
    amount: string(),
    itemDescription: string(),
    vatRate: string(),
    total: number(),
    invoiceNoteId: number(),
    invoiceNoticeId: number(),
    lineItems:array().of(
      object().shape({
      item: string(),
      amount: number(),
      vatRateId: number(),
      vat: number(),
      grossAmount: number()
    })
  ).min(1, 'At least one journal line is required')

    
    //fkCaseId: string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
    creditNoteDate: new Date().toISOString().split("T")[0],
    }
  });

  useEffect(() => {
    setValue("creditNoteDate", new Date().toISOString().split("T")[0]);
  }, []);

  const onError = (errors: any) => {
    //console.log("Form submission errors", errors);
  };

  useEffect(() => {
    if (!effectServiceCall.current) {
      const fetchPromises = [
        dispatch(
          fetchNextReceiptNumber(OfficeAccountConstant.OfficeCreditNote)
        ),
      ];

      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });

      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (receiptNumber != null) {
      setValue("creditNoteNumber", receiptNumber);
      setAutoReceiptNumber(receiptNumber);
    }
  }, [receiptNumber]);

  useEffect(() => {
    if (invoiceNotices.length > 0) {
    
      let data = invoiceNotices.map((invoiceNotice: any, index) => ({
        value: invoiceNotice,
        label: `${invoiceNotice.title}`,
      }));
      setInvoiceNoticeLists(data);
      let findObj = invoiceNotices.find((x) => x.isDefault === true);
      if(findObj && findObj!==null) {
        setValue("invoiceNoticeId", findObj?.invoiceNoticeId)
        setValue("invoiceNoteId", findObj?.invoiceNoticeId)
      }
    }
  }, [invoiceNotices]);

  const handleInvoiceNoticeTitleChange = (selectedOption: any) => {
    setSelectedInvoiceNoticeTitle(selectedOption.title)
    setValue("invoiceNoticeId",selectedOption.invoiceNoticeId );
    if (selectedOption) {
      setSelectedInvoiceNotice(selectedOption.invoiceNotice); // Populate textarea with the selected note
      setValue("invoiceNotices",selectedOption.invoiceNotice);
      
    } 
  };

  const handleInvoiceNoteTitleChange = (selectedOption: any) => {
    setSelectedInvoiceNoteTitle(selectedOption.title)
    if (selectedOption) {
      setSelectedInvoiceNote(selectedOption.invoiceNotice); // Populate textarea with the selected note
      setValue("invoiceNoteId",selectedOption.invoiceNoticeId);
      setValue("notes",selectedOption.invoiceNotice);
      
    } 
  };


  useEffect(() => {
    if (officeVatRates.length > 0) {
     // console.log(officeVatRates);
      let data = officeVatRates.map((officeVatRate: any, index) => ({
        value: officeVatRate,
        label: `${officeVatRate.vatType}`,
        vatPercentage: officeVatRate.percentage,

      }));
      setOfficeVatRateLists(data);

    }
  }, [officeVatRates]);

  const handleVatRateChange = (index: number, selectedOption: any) => {
    const updatedRows = [...lineitems];

    // Update the vatRate (percentage) and vatTypeId for the corresponding row
    updatedRows[index] = {
      ...updatedRows[index],
      vatRate: selectedOption.percentage, // Use 'percentage' for VAT calculation
      vatRateId: selectedOption.vatTypeId, // Use 'vatTypeId' for selected type
    };

    // Update the state with the new rows
    setCreditNoteRows(updatedRows);
  };


  useEffect(() => {
    if (isEdit && creditNote) {
      setExistingCreditNote(creditNote);
      reset(creditNote);
    }
  }, [creditNote, isEdit, reset, setValue]);

  useEffect(() => {
    if ((creditNote != null && isEdit === true) || (creditNote != null && isView === true && isEdit === false)) {
      setValue('creditNoteNumber', creditNote?.creditNoteNumber)
      setValue('invoiceId', (creditNote?.invoiceId))
      setValue('client', creditNote?.client)
      setValue('invoiceAmount', creditNote?.invoiceAmount)
      setValue('vat', creditNote?.vat)
      setValue('totalAmount', creditNote?.totalAmount)
      setValue('remainingCredit', creditNote?.remainingCredit)
      setValue('totalPaid', creditNote?.totalPaid)
      setValue('balanceDue', creditNote?.balanceDue)
      setValue('details', creditNote?.details)
      setValue('creditNoteDate', (creditNote?.creditNoteDate))
      setValue('reason', creditNote?.reason)
      setValue('invoiceNoteId', creditNote?.invoiceNoteId);
      setValue('invoiceNoticeId', creditNote?.invoiceNoticeId);

    }
  }, [creditNote, isEdit, isView,  reset, setValue]);


  const toggleCaseAccountModal = () => {
    setOpenCaseAccountModal(!openCaseAccountModal);
  };

  const toggleFixedFeeInvoicesModal = () => {
    setOpenFixedFeeInvoicesModal(!openFixedFeeInvoicesModal);
  };
  const handleInvoiceSelect = (selectedInvoice: any,) => {
    if(selectedInvoice != null){
      const invoice= `invoice ${selectedInvoice.invoiceNumber} £(${(selectedInvoice.totalCreditDue)})`
      setSelectedInvoice(invoice)
      setValue('invoiceId',selectedInvoice.fixedFeeInvoiceId);
      setValue('client', selectedInvoice.invoiceeName);
      setValue('vat', selectedInvoice.vat);
      setValue('totalAmount', selectedInvoice.totalAmount);
      setValue('balanceDue', selectedInvoice.totalDue);
      setValue('totalPaid', selectedInvoice.totalPayments);
      setValue('invoiceAmount', selectedInvoice.invoiceAmount);
      
      console.log(selectedInvoice.invoiceId)
    }
    // setFromBankAccount(selectedAccountType.financialStatement); // Set the selected account type to display in input field

    // setValue('fromBankId', selectedAccountType.accountId);

    // if (selectedAccountType) {
    //   setSelectedInvoiceBalance(selectedAccountType.runningBalance);
    //   setValue("fromBankAvailableBalance", selectedAccountType.runningBalance);
    // }
    // console.log(selectedAccountType.accountId);
    // //  console.log(selectedAccountType.runningBalance);
    // setOpenFixedFeeInvoicesModal(false);

  };


  return (
    <>
      {openModal !== false && (
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className="mx-auto my-5"
        >
          <div className="">
            <div className="flex flex-row md:flex-col justify-between mb-5 gap-2 w-full md:gap-3">
              <div className="relative flex">
                <input
                  type="text"
                  placeholder={selectedInvoice}
                  aria-label="Search"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.invoiceId ? "is-invalid" : ""
                    }`}
 
                  disabled
                />
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.invoiceId?.message?.toString()}
                </div>
                <button
                  onClick={toggleFixedFeeInvoicesModal}
                  className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-lg 3xl:text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg -ml-1"
                  data-twe-ripple-init
                  data-twe-ripple-color="white"
                  type="button"
                >
                  Search
                </button>
                {openFixedFeeInvoicesModal && (
              <SearchInvoices
                openDrawer={openFixedFeeInvoicesModal}
                Close={toggleFixedFeeInvoicesModal}
                onAccountSelect={handleInvoiceSelect}
                // type="Office" fkAccountTypeIds="1"// Pass the callback function to child
              />
            )}
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    Close();
                  }}
                  className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
              <div>
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Credit Note Number
                </label>
                <div className="flex gap-3">
                  <input
                    type="text"
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    {...register("creditNoteNumber")}
                    disabled={isDisabled}
                  />
                </div>
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Client
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("client")}
                  disabled={isDisabled}
                  placeholder="Enter Client"
                />
              </div>

              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Net Amount
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("invoiceAmount")}
                  disabled={isDisabled}
                  placeholder="Enter Net Amount"
                />
              </div>

              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  VAT
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("vat")}
                  disabled={isDisabled}
                  placeholder="Enter VAT"
                />
              </div>

              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Gross Amount
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("totalAmount")}
                  disabled={isDisabled}
                  placeholder="Enter Gross Amount"
                />
              </div>

              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Total Paid
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("totalPaid")}
                  disabled={isDisabled}
                  placeholder="Enter Total Paid"
                />
              </div>

              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Balance Due
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("balanceDue")}
                  disabled={isDisabled}
                  placeholder="Enter Balance Due"
                />
              </div>

              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Transaction Description
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("details")}
                  placeholder="Enter Transaction Description"
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Transaction Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.creditNoteDate ? "is-invalid" : ""
                    }`}
                  {...register("creditNoteDate")}
                  // max={new Date().toISOString().split("T")[0]}
                  placeholder="Enter Transaction Date"
                />
                <div className="font-medium mt-2 text-lg 3xl:text-xs text-red-600">
                  {errors?.creditNoteDate?.message?.toString()}
                </div>
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Reason
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("reason")}
                  placeholder="Enter reason"
                />
              </div>
              <div className="">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Invoice Notes Title
                </label>
                <SelectBox
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  isSearchable={true}
                  options={invoiceNoticeLists}
                  value={getValues().invoiceNoteId}
                  placeholder="Select"
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  onChange={handleInvoiceNoteTitleChange}
                />
              </div>
              
              <div className="">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Invoice Notice Title
                </label>
                <SelectBox
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-lg 3xl:text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                  indicator={
                    <svg
                      width="15"
                      className="fill-current mr-2"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                  }
                  isMulti={false}
                  name="frameFiftySix"
                  isSearchable={true}
                  options={invoiceNoticeLists}
                  value={getValues().invoiceNoticeId}
                  placeholder="Select"
                  shape="round"
                  color="indigo_50"
                  size="xs"
                  onChange={handleInvoiceNoticeTitleChange}
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Invoice Notice
                </label>
                <textarea
                  className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Invoice Notice"
                  {...register("invoiceNotices")}
                  value={selectedInvoiceNotice} // Bind value to the textarea
                    onChange={(e) => setSelectedInvoiceNotice(e.target.value)}
                ></textarea>
              </div>
              <div className="relative">
                <label className="block mb-2 text-lg 3xl:text-sm font-medium text-gray-900 dark:text-white-bg">
                  Invoice Notes
                </label>
                <textarea
                  className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-lg 3xl:text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Invoice Notes"
                  {...register("notes")}
                  value={selectedInvoiceNote} // Bind value to the textarea
                  onChange={(e) => setSelectedInvoiceNote(e.target.value)}
                ></textarea>
              </div>

            </div>
            <div className="relative h-auto sm:rounded-lg mt-5">
              <table className="w-full text-lg 3xl:text-xs text-black text-left rtl:text-right dark:text-gray-400">
                <thead className="text-lg 3xl:text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Item Description
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Amount
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      VAT Rate
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Vat
                    </th>
                    <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      Total
                    </th>
                    <th scope="col" className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {lineitems.map((row, index) => (
                    <tr
                      key={index}
                      className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <button type="button" onClick={toggleDescriptionModal}>
                          <FcPlus />
                        </button>
                        {openDescriptionModal && (
                          <AddOfficeFeeTypesDisbursement
                            openDrawer={openDescriptionModal}
                            Close={toggleDescriptionModal}
                            onFeeOrDisbursmentSelect={handleSelect}
                          />
                        )}
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <input
                          type="text"
                          placeholder="Enter title"
                          className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                          value={row.description}
                          onChange={(e) =>
                            handleRowChange(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <NumericFormat
                          value={row.amount}
                          className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                          placeholder="Amount"
                          decimalScale={2}
                          onValueChange={(values) =>
                            handleRowChange(
                              index,
                              "amount",
                              values.floatValue || 0
                            )
                          }
                        />
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <SelectBox
                          placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                          indicator={
                            <svg
                              width="15"
                              className="fill-current mr-2"
                              height="15"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5 0 45.3l160 160z" />
                            </svg>
                          }
                          isMulti={false}
                          name={`vatRate-${index}`}
                          isSearchable={true}
                          options={officeVatRateLists}
                          value={
                            officeVatRateLists.find((option) => option.vatTypeId === row.vatRateId) || null
                          }
                          placeholder="Select VAT Type"
                          shape="round"
                          color="indigo_50"
                          size="xs"
                          onChange={(selectedOption) => handleVatRateChange(index, selectedOption)}
                        />
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <NumericFormat
                          value={((row.amount * row.vatRate) / 100).toFixed(2)}
                          {...register("vat")}
                          className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="£"
                        />
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <NumericFormat
                          value={(
                            row.amount +
                            (row.amount * row.vatRate) / 100
                          ).toFixed(2)}
                          //{...register("grossAmount")}
                          className={`border-2 border-gray-300 border-solid bg-gray-100 text-lg 3xl:text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="£"
                        />
                      </td>
                      <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <button type="button" onClick={() => removeRow(index)}>
                          <FcCancel />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="my-5">
              <button
                className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                type="button"
                onClick={addRow}
              >
                <FaPlus className="w-4 h-4 fill-white-bg" />
                <span className="text-white-bg mx-2">Add Line</span>
              </button>
            </div>

            <div className="space-y-2 my-5 border-2 p-6">
              <div className="flex items-center">
                <label className="w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  Sub Total{" "}
                </label>
                <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-1/2 text-lg 3xl:text-sm dark:text-white-bg text-right">
                  £{subTotal.toFixed(2)}
                </p>
              </div>
              <div className="flex items-center">
                <label className="w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  VAT
                </label>
                <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-1/2 text-lg 3xl:text-sm dark:text-white-bg text-right">
                  £{totalVat.toFixed(2)}
                </p>
              </div>
              <div className="flex items-center">
                <label className="w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  Total Amount
                </label>
                <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-1/2 text-lg 3xl:text-sm dark:text-white-bg text-right">
                  £{totalAmount.toFixed(2)}
                </p>
              </div>
              <div className="flex items-center">
                <label className="w-1/2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  Less Credit to Invoice
                </label>
                <span className="mx-2 text-lg 3xl:text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-1/2 text-lg 3xl:text-sm dark:text-white-bg text-right">
                  £{lessCreditInvoice.toFixed(2)}
                </p>
              </div>
              <div className="flex items-center border-t-2 pt-2">
                <label className="w-1/2 text-lg 3xl:text-sm font-bold dark:text-white-bg">
                  Remaining Credit{" "}
                </label>
                <span className="mx-2 text-lg 3xl:text-sm font-bold dark:text-white-bg">
                  :
                </span>
                <p className="w-1/2 text-lg 3xl:text-sm font-bold dark:text-white-bg text-right">
                  £{remainingCredit.toFixed(2)}
                </p>
              </div>
            </div>

            {!isView &&(

           
            <div className="flex col-span-2 gap-2 justify-end px-10">
              <button
                type="button"
                onClick={() => {
                  Close();
                }}
                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-lg 3xl:text-sm w-[120px]"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-lg 3xl:text-sm w-[120px]  ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white-bg mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  "Save"
                )}
              </button>
            </div>
             )}
          </div>
        </form>
      )}
    </>
  );
};

export { AddOfficeCreditNote };
