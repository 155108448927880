import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AuthorizePaymentService from "../../../../../../services/Accounting/ClientAccounts/ClientPostings/BankPayment/AuthorizePaymentService";




export interface AuthorizePayment {
    authorizePaymentId: number;
    clientBank: string;
    areasofPractice: string;
    caseType: string;
    caseSubType: string;
    priority: string;
    dateBetween: string;
    keyword: string;


};
// Define the state type for Checklist
type AuthorizePaymentState = {
    authorizePayments: AuthorizePayment[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

//Initial state
const initialState: AuthorizePaymentState = {
    authorizePayments: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllAuthorizePayment = createAsyncThunk(
    "fetchAllAuthorizePayment",
    async (_, thunkAPI) => {
        try {
            const response = await AuthorizePaymentService.GetAllAuthorizePayment();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateAuthorizePayment = createAsyncThunk(
    "updateAuthorizePayment",
    async ({ id, data }: { id: number, data: AuthorizePayment }, thunkAPI) => {
        try {
            const response = await AuthorizePaymentService.PutAuthorizePayment(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteAuthorizePayment = createAsyncThunk(
    "deleteAuthorizePayment",
    async (id: number, thunkAPI) => {
        try {
            await AuthorizePaymentService.deleteAuthorizePayment(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


const AuthorizePaymentSlice = createSlice({
    name: "authorizePayment",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllAuthorizePayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllAuthorizePayment.fulfilled, (state, action: PayloadAction<AuthorizePayment[]>) => {
                state.status = "idle";
                state.authorizePayments = action.payload;
            })
            .addCase(fetchAllAuthorizePayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateAuthorizePayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateAuthorizePayment.fulfilled, (state, action: PayloadAction<AuthorizePayment>) => {
                state.status = "idle";
                const index = state.authorizePayments.findIndex(t => t.authorizePaymentId === action.payload.authorizePaymentId);
                if (index !== -1) {
                    state.authorizePayments[index] = action.payload;
                }
            })
            .addCase(updateAuthorizePayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteAuthorizePayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteAuthorizePayment.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.authorizePayments = state.authorizePayments.filter(t => t.authorizePaymentId !== action.payload);
            })
            .addCase(deleteAuthorizePayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });

        }

        });
 

export default AuthorizePaymentSlice.reducer;
