import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllOfficeBank = async () => {
    try {
        const response = await axiosInstance.get("AccountSetUp/officeaccounts");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const OfficeBankService = {
    GetAllOfficeBank,
    
};


export default OfficeBankService;
