import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import MenuPermissionService from "../../../services/Administrator/MenuPermissionService";
import { Menu } from "../../model/Menu/menuResult";

// Define the state type for branch
type MenuState = {
    menus: Menu[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    permisions: any[];
};

// Initial state
const initialState: MenuState = {
    menus: [],
    status: "idle",
    error: null,
    permisions:[]
};

// Async thunks for CRUD operations
export const GetAllMenu = createAsyncThunk(
    "GetAllMenu",
    async (_, thunkAPI) => {
        try {
            const response = await MenuPermissionService.fetchAllMenu();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const GetAllOperations = createAsyncThunk(
    "GetAllOperations",
    async (_, thunkAPI) => {
        try {
            const response = await MenuPermissionService.fetchAllOperations();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
// export const addBranch = createAsyncThunk(
//     "addBranch",
//     async (newBranch: Omit<Branch, 'branchId'>, thunkAPI) => {
//         try {
//             const response = await BranchManagementService.addBranch(newBranch);
//             return response;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// export const updateBranch = createAsyncThunk(
//     "updateBranch",
//     async (updatedBranch: Branch, thunkAPI) => {
//         const { branchId, ...data } = updatedBranch;
//         try {
//             const response = await BranchManagementService.updateBranch(branchId, data);
//             return response;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// export const deleteBranch = createAsyncThunk(
//     "deleteBranch",
//     async (BranchId: number, thunkAPI) => {
//         try {
//             await BranchManagementService.deleteBranch(BranchId);
//             return BranchId;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// Slice definition
const MenuPermissionSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAllMenu.pending, (state) => {
                state.status = "loading";
            })
            .addCase(GetAllMenu.fulfilled, (state, action: PayloadAction<Menu[]>) => {
                state.status = "idle";
                state.menus = action.payload;
            })
            .addCase(GetAllMenu.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(GetAllOperations.pending, (state) => {
                state.status = "loading";
            })
            .addCase(GetAllOperations.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.status = "idle";
                state.permisions = action.payload;
            })
            .addCase(GetAllOperations.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            // .addCase(addBranch.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(addBranch.fulfilled, (state, action: PayloadAction<Branch>) => {
            //     state.status = "idle";
            //     state.branches.push(action.payload);
            // })
            // .addCase(addBranch.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // })
            // .addCase(updateBranch.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(updateBranch.fulfilled, (state, action: PayloadAction<Branch>) => {
            //     state.status = "idle";
            //     const index = state.branches.findIndex(t => t.branchId === action.payload.branchId);
            //     if (index !== -1) {
            //         state.branches[index] = action.payload;
            //     }
            // })
            // .addCase(updateBranch.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // })
            // .addCase(deleteBranch.pending, (state) => {
            //     state.status = "loading";
            // })
            // .addCase(deleteBranch.fulfilled, (state, action: PayloadAction<number>) => {
            //     state.status = "idle";
            //     state.branches = state.branches.filter(t => t.branchId !== action.payload);
            // })
            // .addCase(deleteBranch.rejected, (state, action) => {
            //     state.status = "failed";
            //     state.error = action.payload as string;
            // });
    }
});

export default MenuPermissionSlice.reducer;
