import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import BranchManagementService from "../../../services/Administrator/BranchManagementService";

export interface Branch {
    branchId:number;
    branchName: string;
    noOfEmployees: number;
    noOfLicense: number;
    branchAddress: string;
    branchPostCode: string;
    dxaddressNumber: string;
    dxaddressExchange: string;
    poboxaddressNumber: string;
    poboxaddressTown: string;
    poboxaddressPostCode: string;
    branchEmail: string;
    branchPhone: string;
    branchFax: string;
    contactPersonFullName: string;
    contactPersonDesignation: string;
    contactPersonEmail: string;
    contactPersonMobile: string;
    fkAreasOfPractice: number[];
    otherdetailsVatNumber: string;
    otherdetailsCrn: string;
    otherdetailsLandRegNo: string;
    otherdetailsDirectDebit: boolean;
    otherdetailsOiscRegNo: string;
    otherdetailsFeeAccNo: string;
    isIpRestricted: boolean;
    allowedIps: string;
    isActive: boolean;
};

// Define the state type for branch
type BranchState = {
    branches: Branch[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: BranchState = {
    branches: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllBranch = createAsyncThunk(
    "fetchAllBranch",
    async (_, thunkAPI) => {
        try {
            const response = await BranchManagementService.fetchAllBranch();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addBranch = createAsyncThunk(
    "addBranch",
    async (data: any, thunkAPI) => {
        try {
            data.fkAreasOfPractice = data.fkAreasOfPractice.toString();
            const response = await BranchManagementService.addBranchData(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateBranch = createAsyncThunk(
    "updateBranch",
    async ({ id, data }: { id: number, data: Branch }, thunkAPI) => {
        try {
            const response = await BranchManagementService.updateBranchData(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteBranch = createAsyncThunk(
    "deleteBranch",
    async (BranchId: number, thunkAPI) => {
        try {
            await BranchManagementService.deleteBranch(BranchId);
            return BranchId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const BranchManagementSlice = createSlice({
    name: "branch",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBranch.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllBranch.fulfilled, (state, action: PayloadAction<Branch[]>) => {
                state.status = "idle";
                state.branches = action.payload;
            })
            .addCase(fetchAllBranch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addBranch.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(addBranch.fulfilled, (state, action: PayloadAction<Branch>) => {
                state.status = "idle";
                state.branches.push(action.payload);
            })
            .addCase(addBranch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateBranch.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateBranch.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                if (action.payload != null) {
                    state.branches = action.payload || [];
                }
            })
            .addCase(updateBranch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteBranch.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteBranch.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
            })
            .addCase(deleteBranch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default BranchManagementSlice.reducer;
