import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import TrialBalanceService from "../../../../../services/Accounting/OfficeAccount/OfficeReports/TrialBalanceService";





export interface TrialBalance {
    officeTrialBalances:[]
    selectedDate: string;   
};

export interface TrialBalanceDetails {
    officeTrialBalanceDetails:[]
    nominalLedgerData: [];     
};

// Define the state type for Checklist
type TrialBalanceState = {
    trialBalances: TrialBalance;
    trialBalanceDetails: TrialBalanceDetails;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: TrialBalanceState = {
    trialBalances: null!,
    trialBalanceDetails: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllTrialBalance = createAsyncThunk(
    "fetchAllTrialBalance",
    async (data: any, thunkAPI) => {
        try {
            const response = await TrialBalanceService.GetAllTrialBalance(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchTrialBalanceById = createAsyncThunk(
    "fetchTrialBalanceById",
    async (data: any, thunkAPI) => {
        try {
            const response = await TrialBalanceService.GetTrialBalanceById(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const TrialBalanceSlice = createSlice({
    name: "trialBalance",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTrialBalance.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllTrialBalance.fulfilled, (state, action: PayloadAction<TrialBalance>) => {
                state.status = "idle";
                state.trialBalances = action.payload;
            })
            .addCase(fetchAllTrialBalance.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchTrialBalanceById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchTrialBalanceById.fulfilled, (state, action: PayloadAction<TrialBalanceDetails>) => {
                state.status = "idle";
                state.trialBalanceDetails = action.payload;
            })
            .addCase(fetchTrialBalanceById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
           
    }
});

export default TrialBalanceSlice.reducer;
