import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks/redux-hooks';
import { toast } from 'react-toastify';
import { fetchAllWorkflowDocument } from '../../../store/slice/SystemMaintenance/WorkflowSetupSlice';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';

function DocFileModal({ openDrawer, Close, }: { openDrawer: boolean, Close: any }) {
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement] = useState('center')
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    const workflowDocument = useSelector((state: RootState) => state.workflow.workflowDocument);

    // useEffect(() => {
    //     if (!effectServiceCall.current) {
    //         const fetchPromises = [dispatch(fetchAllWorkflowDocument())];
    //         Promise.all(fetchPromises)
    //             .then(() => {
    //                 setInitialLoading(false);
    //             })
    //             .catch((error) => {
    //                 if (!errorShownRef.current) {
    //                     toast.error("Error fetching data: " + error.message);
    //                     errorShownRef.current = true;
    //                 }
    //                 setInitialLoading(false);
    //             });
    //         effectServiceCall.current = true;
    //     }
    // }, [dispatch]);

    useEffect(() => {
        if (workflowDocument !== null) {
            console.log(workflowDocument);
        }
    },[workflowDocument])


    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { Close(); }}
            initialFocus={emailInputRef}
            size="2xl"
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">Search</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">
                <form action=""></form>
            </Modal.Body>
        </Modal>
    );
}

export { DocFileModal };
