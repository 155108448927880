import { ErrorResult } from "../../../../../store/model/errorResult";
import axiosInstance from "../../../../axiosInstance";


const GetCaseStaffNote = async (data: any) => {
    try {
        const response = await axiosInstance.post(`Case/staffnote/search`, data);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostStaffNote = async (data: any) => {
    try {
        const response = await axiosInstance.post("Case/staffnote", data);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}



const StaffAddNoteService = {
    GetCaseStaffNote,
    PostStaffNote
};

export default StaffAddNoteService;
