import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllStaffAttendance = async (data: any) => {
    try {
        const response = await axiosInstance.post("StaffAttendence/search", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostStaffAttendance = async (data: any) => {
    try {
        const response = await axiosInstance.post("StaffAttendence", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutStaffAttendance = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`StaffAttendance/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteStaffAttendance = async (id: number) => {
    try {
        await axiosInstance.delete(`/StaffAttendance/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const StaffAttendanceService = {
    GetAllStaffAttendance,
    PostStaffAttendance,
    PutStaffAttendance,
    deleteStaffAttendance
};


export default StaffAttendanceService;
