import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, number } from 'yup';
import { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';

import { getValue } from '@testing-library/user-event/dist/utils';
import { useAppDispatch } from '../../../../store/hooks/redux-hooks';
import { RootState } from '../../../../store/store';
import { addFinancialYear } from '../../../../store/slice/Accounting/OfficeAccounts/GeneralSettings/FinancialYearSlice';
import { formatDateISODisplay } from '../../../../utils/date-time.utils';
import { SelectBox } from '../../../SelectBox';


function AddFinancialYear({ openDrawer, Close, isEdit, financialYear }: { openDrawer: boolean, Close: any,  isEdit: boolean, financialYear: any }) {
    const [title] = useState((!isEdit ? 'Add Financial Year' : 'Edit Financial Year'))
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const [isLoading, setIsLoading] = useState(false);
    const [ setExistingFinancialYear] = useState(financialYear);
    const { accountingMethods } = useSelector((state: RootState) => state.generalOffice);
    const dispatch = useAppDispatch();
    const [accountingMethodList, setAccountingMethodList] = useState<any[]>([]);

    const validationSchema = object().shape({
        accountingMethodId: number()
            .required('Account Method is required'),
        startDate: string()
            .required('Start Date is required')
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split("T")[0];
              }),
        endDate: string()
            .required('End Date is required')
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split("T")[0];
              }),


            
    });

    const { register, handleSubmit, reset, setValue,getValues, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        setIsLoading(true);
        try {
            if (isEdit && financialYear){
            await dispatch(addFinancialYear(data)).unwrap();
            toast.success("Successfully updated Financial Year");
            }else{
                await dispatch(addFinancialYear(data)).unwrap();
            toast.success("Successfully added Financial Year");
            }
            Close("success");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };
    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    // useEffect(() => {
    //     if (isEdit && financialYear) {
    //         setExistingFinancialYear(financialYear);
    //         reset(financialYear);
    //     }
    // }, [financialYear, isEdit, reset, setValue]);

    useEffect(() => {
        if((financialYear != null && isEdit === true)){
            setValue('accountingMethodId', financialYear?.accountingMethodId)
            setValue('startDate', formatDateISODisplay(financialYear?.startDate))
            setValue('endDate', formatDateISODisplay(financialYear?.endDate))
           
            
        }
    }, [financialYear, isEdit, reset, setValue]);

    useEffect(()=>{
        if(accountingMethods!=null && accountingMethods.length>0){
            let data = accountingMethods.map((item: any) => ({
                value: item.accountingMethodId,
                label: item.accountingMethod,
            }));
            setAccountingMethodList(data);
        }
    }, [accountingMethods])

    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); Close(); }}
            initialFocus={emailInputRef}
            size="xl"
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">

            <form onSubmit={handleSubmit(onSubmit,onError)} className="mx-auto" >
                    <div className="grid grid-cols-1 gap-x-4 gap-y-1 w-full">
                    <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Account Method <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                isSearchable={true}
                                options={accountingMethodList}
                                value={getValues().accountingMethodId}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('accountingMethodId', selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.accountingMethodId?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Start Date <span className="text-red-500">*</span>
                            </label>
                            <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.startDate ? 'is-invalid' : ''}`} {...register('startDate')}
                        
                               placeholder="Enter Start startDate"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.startDate?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            End Date <span className="text-red-500">*</span>
                            </label>
                            <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.endDate ? 'is-invalid' : ''}`} {...register('endDate')}
                              
                                placeholder="Enter End Date"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.endDate?.message?.toString()}</div>
                        </div>
                    </div>
                    <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                    type ='button'
                                    onClick={() => {
                                        reset();
                                        Close();
                                    }}
                                    className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    disabled={isLoading}
                                    className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >{isLoading ? (
                                    <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                    </svg>
                                ) : (
                                    'Save'
                                )}
                                </button>
                            </div>
                        </div>
                </form>

            </Modal.Body>
        </Modal>
    );
}

export { AddFinancialYear };
