import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";

const GetAllFinancialYear = async (data: any) => {
    try {
        const response = await axiosInstance.post("generalsettings/searchfinancialyear", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostFinancialYear = async (data: any) => {
    try {
        const response = await axiosInstance.post("generalsettings/financialyear", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutFinancialYear = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`FinancialYear/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteFinancialYear = async (id: number) => {
    try {
        await axiosInstance.delete(`/FinancialYear/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const FinancialYearService = {
    GetAllFinancialYear,
    PostFinancialYear,
    PutFinancialYear,
    deleteFinancialYear
};


export default FinancialYearService;
