import { ErrorResult } from "../../../../../store/model/errorResult";
import axiosInstance from "../../../../axiosInstance";


const GetCaseCorrCallerCombo = async (id: number) => {
    try {
        const response = await axiosInstance.get(`CaseCorrespondence/callercombo/${id}`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetCaseCorrCallReceiverCombo = async (id: number) => {
    try {
        const response = await axiosInstance.get(`CaseCorrespondence/callreceiver/${id}`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostCorrCallIn = async (data: any) => {
    try {
        const response = await axiosInstance.post("CaseCorrespondence/callin", data);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}



const CorrCallInServices = {
    GetCaseCorrCallerCombo,
    PostCorrCallIn,
    GetCaseCorrCallReceiverCombo
};

export default CorrCallInServices;
