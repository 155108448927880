import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AreasOfPracticeService from "../../../services/Administrator/AreasOfPracticeService";


export interface AreasOfPractice {
    areasOfPracticeId: number;
    area: string;
    apCode: string;
    description: string;
    isActive: boolean
};
// Define the state type for branch
type AreasOfPracticeState = {
    areasType: string;
    areasOfPractice: AreasOfPractice[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: AreasOfPracticeState = {
    areasOfPractice: [],
    areasType: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const GetAllAreasOfPractice = createAsyncThunk(
    "GetAllAreasOfPractice",
    async (_, thunkAPI) => {
        try {
            const response = await AreasOfPracticeService.fetchAllAreasOfPractice();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAreasType = createAsyncThunk(
    "fetchAreasType",
    async (id: number, thunkAPI) => {
        try {
            const response = await AreasOfPracticeService.GetAreasType(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// export const addBranch = createAsyncThunk(
//     "addBranch",
//     async (newBranch: Omit<Branch, 'branchId'>, thunkAPI) => {
//         try {
//             const response = await BranchManagementService.addBranch(newBranch);
//             return response;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// export const updateBranch = createAsyncThunk(
//     "updateBranch",
//     async (updatedBranch: Branch, thunkAPI) => {
//         const { branchId, ...data } = updatedBranch;
//         try {
//             const response = await BranchManagementService.updateBranch(branchId, data);
//             return response;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// export const deleteBranch = createAsyncThunk(
//     "deleteBranch",
//     async (BranchId: number, thunkAPI) => {
//         try {
//             await BranchManagementService.deleteBranch(BranchId);
//             return BranchId;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

// Slice definition
const AreasOfPracticeSlice = createSlice({
    name: "areasOfPractice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetAllAreasOfPractice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(GetAllAreasOfPractice.fulfilled, (state, action: PayloadAction<AreasOfPractice[]>) => {
                state.status = "idle";
                state.areasOfPractice = action.payload;
            })
            .addCase(GetAllAreasOfPractice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchAreasType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAreasType.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.areasType = action.payload;
            })
            .addCase(fetchAreasType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
        // .addCase(addBranch.pending, (state) => {
        //     state.status = "loading";
        // })
        // .addCase(addBranch.fulfilled, (state, action: PayloadAction<Branch>) => {
        //     state.status = "idle";
        //     state.branches.push(action.payload);
        // })
        // .addCase(addBranch.rejected, (state, action) => {
        //     state.status = "failed";
        //     state.error = action.payload as string;
        // })
        // .addCase(updateBranch.pending, (state) => {
        //     state.status = "loading";
        // })
        // .addCase(updateBranch.fulfilled, (state, action: PayloadAction<Branch>) => {
        //     state.status = "idle";
        //     const index = state.branches.findIndex(t => t.branchId === action.payload.branchId);
        //     if (index !== -1) {
        //         state.branches[index] = action.payload;
        //     }
        // })
        // .addCase(updateBranch.rejected, (state, action) => {
        //     state.status = "failed";
        //     state.error = action.payload as string;
        // })
        // .addCase(deleteBranch.pending, (state) => {
        //     state.status = "loading";
        // })
        // .addCase(deleteBranch.fulfilled, (state, action: PayloadAction<number>) => {
        //     state.status = "idle";
        //     state.branches = state.branches.filter(t => t.branchId !== action.payload);
        // })
        // .addCase(deleteBranch.rejected, (state, action) => {
        //     state.status = "failed";
        //     state.error = action.payload as string;
        // });
    }
});

export default AreasOfPracticeSlice.reducer;
