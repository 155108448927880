import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";

const GetAllAdjust = async () => {
    try {
        const response = await axiosInstance.get("Adjust");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutAdjust = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`Adjust/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


// const deleteAdjust = async (id: number) => {
//     try {
//         await axiosInstance.delete(`/Adjust/${id}`);
//     } catch (error: any) {
//         let data = error as ErrorResult;
//         throw new Error(data.messages.toString());
//     }
// }

const AdjustService = {
    GetAllAdjust,
    PutAdjust,
    // deleteAuthorisation
};


export default AdjustService;