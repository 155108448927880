import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CaseSubTypeService from "../../../services/SystemMaintenance/CaseSubTypeService";

export interface CaseSubType {
    caseSubTypeId: number;
    fkCaseTypeId: number;
    subCaseType: string;
    subTypeCode: string;
    description: string;
    isActive: boolean;
};
// Define the state type for CaseSubType
type CaseSubTypeState = {
    CaseSubTypes: CaseSubType[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: CaseSubTypeState = {
    CaseSubTypes: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllCaseSubType = createAsyncThunk(
    "fetchAllCaseSubType",
    async (_, thunkAPI) => {
        try {
            const response = await CaseSubTypeService.GetAllCaseSubType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCaseSubType = createAsyncThunk(
    "addCaseSubType",
    async (data: any, thunkAPI) => {
        try {
            const response = await CaseSubTypeService.PostCaseSubType(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateCaseSubType = createAsyncThunk(
    "updateCaseSubType",
    async ({ id, data }: { id: number, data: CaseSubType }, thunkAPI) => {
        try {
            const response = await CaseSubTypeService.PutCaseSubType(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCaseSubType = createAsyncThunk(
    "deleteCaseSubType",
    async (CaseSubTypeId: number, thunkAPI) => {
        try {
            await CaseSubTypeService.deleteCaseSubType(CaseSubTypeId);
            return CaseSubTypeId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const CaseSubTypeSlice = createSlice({
    name: "caseSubType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCaseSubType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCaseSubType.fulfilled, (state, action: PayloadAction<CaseSubType[]>) => {
                state.status = "idle";
                state.CaseSubTypes = action.payload;
            })
            .addCase(fetchAllCaseSubType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCaseSubType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCaseSubType.fulfilled, (state, action: PayloadAction<CaseSubType>) => {
                state.status = "idle";
                state.CaseSubTypes.push(action.payload);
            })
            .addCase(addCaseSubType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateCaseSubType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCaseSubType.fulfilled, (state, action: PayloadAction<CaseSubType>) => {
                state.status = "idle";
                const index = state.CaseSubTypes.findIndex(t => t.caseSubTypeId === action.payload.caseSubTypeId);
                if (index !== -1) {
                    state.CaseSubTypes[index] = action.payload;
                }
            })
            .addCase(updateCaseSubType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCaseSubType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCaseSubType.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.CaseSubTypes = state.CaseSubTypes.filter(t => t.caseSubTypeId !== action.payload);
            })
            .addCase(deleteCaseSubType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default CaseSubTypeSlice.reducer;
