import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { FaEye } from "react-icons/fa";
import { MdOutlineRefresh } from "react-icons/md";
import { AddClientToOffice } from "../Modal/AddClientAccounts/AddClientPostings/AddClientToOffice";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { fetchAllClientToOffice } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/ClientToOfficeSlice";
import { toast } from "react-toastify";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { ClientToOfficeDtata } from "../../store/model/Accounting/ClientAccount/ClientToOfficeInterface";
import { Pagination } from "../Pagination";
import { JustifyDateFilter } from "../Filters/JustifyDateFilter";
import { ReverseMainDrawer } from "../Drawer/ReverseMainDrawer";
import { ViewMainDrawer } from "../Drawer/ViewMainDrawer";
import { ViewClientOfficeDrawer } from "../Drawer/clientPostingViewDrawer";
import { formatCurrency } from "../../utils/currency-format.utilis";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";

function ClientToOffice() {
  const [openModal, setOpenModal] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOnAddedOrUpdate, setOnAddedOrUpdate] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const clientToOffices = useSelector(
    (state: RootState) => state.clientToOffice.clientToOfficeDtata
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [clientToOfficeData, setClientToOfficeData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentclientToOffic, setCurrentclientToOffic] = useState<any[]>([]);
  const [plannedStartDate, setPlannedStartDate] = useState<string | null>(null);
  const [plannedEndDate, setPlannedEndDate] = useState<string | null>(null);
  const [openReverseDrawer, setOpenReverseDrawer] = useState(false);
  const [isReverse, setIsReverse] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current || isOnAddedOrUpdate) {
      dispatch(fetchAllClientToOffice())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
      setOnAddedOrUpdate(false);
    }
  }, [dispatch, isOnAddedOrUpdate]);

  useEffect(() => {
    if (clientToOffices && clientToOffices.clientToOfficeResult) {
      setClientToOfficeData(clientToOffices.clientToOfficeResult);
      setTotalPages(clientToOffices.totalCount);
    }
  }, [clientToOffices]);


  useEffect(() => {
    if (clientToOfficeData != null && clientToOfficeData.length > 0) {
      setCurrentclientToOffic(clientToOfficeData);
    }
  }, [clientToOfficeData]);

  const toggleDrawer = (value: string) => {
    if (value === "success") {
      setOnAddedOrUpdate(true);
    }
    setOpenModal(!openModal);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlannedStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlannedEndDate(event.target.value);
  };

  const toggleReverseDrawer = (value: string) => {
    setIsReverse(false);
    setOpenReverseDrawer(!openReverseDrawer);
  };

  const toggleViewDrawer = (value: string) => {
    setIsView(false);
    setOpenViewDrawer(!openViewDrawer);
  };

  if (openModal) {
    return (
      <AddClientToOffice
        openDrawer={openModal}
        Close={toggleDrawer}
        isView={isView}
        isReverse={false}
        clientToOffice={clientToOfficeData}
      />
    );
  }

  if (openReverseDrawer) {
    return (
      <ReverseMainDrawer
        Close={toggleReverseDrawer}
        openCaseDrawer={openReverseDrawer}
        drawerContent="clientToOffice"
        getData={clientToOfficeData}
      />
    );
  }

  if (openViewDrawer) {
    return (
      <ViewClientOfficeDrawer
        Close={toggleViewDrawer}
        openCaseDrawer={openViewDrawer}
        drawerContent="CPClientToOffice"
        getData={clientToOfficeData}
        title="Bill Payment"
      />
    );
  }

  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle
            title="Bill Payment"
            buttonName="Bill Payment"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <SearchInput title="Keyword" />
        </div>
        <div className="bg-gray-100 p-3 border border-gray-300 rounded mb-5 ">
        <div className="grid grid-cols-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full">
          <div className="flex gap-4 md:gap-1 w-full">
            {/* <CheckBoxFilter title="Areas of Practice" /> */}
            <CheckBoxFilter title="Case Type" />
            {/* <CheckBoxFilter title="Case Sub Type" /> */}
            <CheckBoxFilter title="Client Bank" />
            <JustifyDateFilter
              title="Date Between"
              plannedStartDate={plannedStartDate}
              plannedEndDate={plannedEndDate}
              onPlannedStartDateChange={handleStartDateChange}
              onPlannedEndDateChange={handleEndDateChange}
            />
          </div>
        </div>
        </div>
        
        <div className="relative overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Case Details
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Transaction Details
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Payment Types
                </th>
                <th
                  scope="col"
                  className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="text-center py-3 w-12 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentclientToOffic.map((clientToOffice: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    
                    {formatDateOnlyDisplay(clientToOffice.transactionDate)}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <p>{clientToOffice.individualNames || clientToOffice.organisationNames || clientToOffice.companyNames}</p>
                  <p>{clientToOffice.caseReferenceAuto}</p> <br />
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {clientToOffice.transactionDetails}
                  </td>
                  <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {clientToOffice.transferMethod}
                  </td>
                  <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    {formatCurrency(clientToOffice.amountTransfer)}
                  </td>
                  <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setIsView(true);
                          setClientToOfficeData(clientToOffice);
                        }}
                      />
                      {/* <MdOutlineRefresh className="fill-blue-800 w-6 h-6" 
                       onClick={() => {
                        setOpenReverseDrawer(true);
                        setClientToOfficeData(clientToOffice);
                      }}
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {currentclientToOffic.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">
                {" "}
                client to office details Not found.
              </p>
            </div>
          )}
        </div>
        {currentclientToOffic.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRecords={currentclientToOffic.length}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { ClientToOffice };
