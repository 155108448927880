import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllCentralDiary = async (data: any) => {
    try {
        const response = await axiosInstance.post("CentralDiary", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostCentralDiary = async (data: any) => {
    try {
        const response = await axiosInstance.post("CentralDiary", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutCentralDiary = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`CentralDiary/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteCentralDiary = async (id: number) => {
    try {
        await axiosInstance.delete(`/CentralDiary/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const CentralDiaryService = {
    GetAllCentralDiary,
    PostCentralDiary,
    PutCentralDiary,
    deleteCentralDiary
};


export default CentralDiaryService;
