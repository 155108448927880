import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import LexcelCqsColpCofaGdprService from "../../../../services/OfficeManagement/OfficeInformation/LexcelCqsColpCofaGdprService";
import { ChapterTypeEnum } from "../../../model/OfficeManagement/OfficeInformation/OfficeChapterEnum";


export interface LEXCELOfficeManual {
   // lEXCELOfficeManualId: number;
    chapterType: ChapterTypeEnum;// Store the enum value as a number
    id: number;
    chapterName: string;
    attachmentName: string;
    attachmentFile: string; 
}

// Define the state type
type LEXCELOfficeManualState = {
    lEXCELOfficeManuals: PaginationResponse<LEXCELOfficeManual> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: LEXCELOfficeManualState = {
    lEXCELOfficeManuals: null,
    status: "idle",
    error: null
};

// Fetch LEXCEL Office Manuals based on the chapter type
export const fetchAllLEXCELOfficeManual = createAsyncThunk(
    "fetchAllLEXCELOfficeManual",
    async (chapterType: ChapterTypeEnum, thunkAPI) => {
        try {
            // Pass the chapter type as a number
            const response = await LexcelCqsColpCofaGdprService.GetAllLexcelCqsColpCofaGdpr({ chapterType });
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Add a new LEXCELOfficeManual
export const addLEXCELOfficeManual = createAsyncThunk(
    "addLEXCELOfficeManual",
    async (data: { chapterType: ChapterTypeEnum; id: number }, thunkAPI) => {
        try {
            const response = await LexcelCqsColpCofaGdprService.PostLexcelCqsColpCofaGdpr(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const LEXCELOfficeManualSlice = createSlice({
    name: "lEXCELOfficeManual",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllLEXCELOfficeManual.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllLEXCELOfficeManual.fulfilled, (state, action: PayloadAction<PaginationResponse<LEXCELOfficeManual>>) => {
                state.status = "idle";
                state.lEXCELOfficeManuals = action.payload;
            })
            .addCase(fetchAllLEXCELOfficeManual.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addLEXCELOfficeManual.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addLEXCELOfficeManual.fulfilled, (state) => {
                state.status = "idle";
            })
            .addCase(addLEXCELOfficeManual.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    },
});

export default LEXCELOfficeManualSlice.reducer;
