import { useEffect, useRef, useState } from "react";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllTrialBalance } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/TrialBalanceSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { ViewMainDrawer } from "../Drawer/ViewMainDrawer";
import { Datepicker } from "flowbite-react";
import {DatePickerWrapper} from "../InputComponents/DatePickerWrapper";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";


function TrialBalance() {
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(false);
  const trialBalances = useSelector(
    (state: RootState) => state.trialBalance.trialBalances
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentTrialBalance, setCurrentTrialBalance] = useState<any[]>([]);
  const itemsPerPage = 10;

  const [data, setData] = useState({ date: new Date()});
  const [openCaseDrawer, setOpenCaseDrawer] = useState(false);
  const [trialBalanceData, setTrialBalanceData] = useState<any>();
  const companyProfiles = useSelector((state: RootState) => state.companyProfile.companyProfiles);

  useEffect(() => {
    if (trialBalances != null && trialBalances.officeTrialBalances.length > 0) {
      const totalPages = Math.ceil(trialBalances.officeTrialBalances.length / itemsPerPage);
      setTotalPages(totalPages);
      setCurrentTrialBalance(trialBalances.officeTrialBalances);
    }
  }, [trialBalances]);


  const handleDateChange = (e: any) => {
    setData({ date: e });
  };

  const handleSearchClick = async () => {
    setInitialLoading(true);
    try {
      await dispatch(fetchAllTrialBalance(data)).unwrap();
      toast.success("Successfully Search Trial Balance");
    } catch (error: any) {
      toast.error(error?.message || "An error occurred while fetching data");
    } finally {
      setInitialLoading(false);
    }
  };

  let netTotalDr = 0;
  let netTotalCr = 0;

  const calculateTotals = (balance: number, type: 'DR' | 'CR') => {
    let totalDr = 0;
    let totalCr = 0;

    if (type === 'DR') {
      if (balance >= 0) {
        totalDr = Math.abs(balance);
        totalCr = 0;
      } else {
        totalDr = 0;
        totalCr = Math.abs(balance);
      }
    } else if (type === 'CR') {
      if (balance >= 0) {
        totalCr = Math.abs(balance);
        totalDr = 0;
      } else {
        totalCr = 0;
        totalDr = Math.abs(balance);
      }
    }

    return { totalDr, totalCr };
  };

  const toggleCaseDrawer = () => {
    setOpenCaseDrawer(!openCaseDrawer);
  };




  return (
    <>
      <div className="">
        <div className="flex items-center justify-between mb-5">
          <div>
          <SubHeaderTitle title="Trial Balance" />
          </div>
          <div className="flex gap-x-2 ietms-center justify-end">
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-sm"
          >
            <IoIosPrint className="w-5 h-5" />
            Print
          </button>
          <button
            type="submit"
            className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-sm"
          >
            <BsFileEarmarkExcelFill className="w-5 h-5" />
            Export to Excel
          </button>
        </div>
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <div className="flex gap-4">
            {/* <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Date
            </label> */}
            {/* <input
              type="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
              max={new Date().toISOString().split("T")[0]}
              defaultValue={data.date}
              onChange={handleDateChange}
            /> */}
            <DatePickerWrapper value={data.date} onChange={handleDateChange} />
            <button
              className="z-[2] inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
              type="button"
              onClick={handleSearchClick}>
              Search
            </button>
          </div>
        </div>
        
        <div className="my-5 text-center">
          <h1 className="text-2xl font-bold">{companyProfiles?.companyName}</h1>
          <p className="text-lg font-semibold">
            Trial Balance as at {formatDateOnlyDisplay(trialBalances?.selectedDate)}
          </p>
        </div>
        <div className="relative overflow-hidden sm:rounded-lg my-5">
          <div className="overflow-x-auto max-h-96">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="sticky -top-1 text-lg 3xl:text-sm bg-gray-100 dark:bg-gray-900 dark:text-gray-400 border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Account Code
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Account Name
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Debit
                </th>
                <th scope="col" className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  Credit
                </th>
                <th scope="col" className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></th>
              </tr>
            </thead>
            <tbody className="text-lg 3xl:text-xs">
              {currentTrialBalance.map((trialBalance, index) => {
                const { totalDr, totalCr } = calculateTotals(trialBalance.runningBalance, trialBalance.toIncrease);

                // Skip rows where runningBalance is 0, or totalDr and totalCr are both null/0
                if (trialBalance.runningBalance === 0 || (!totalDr && !totalCr)) return null;

                // Accumulate totals only for valid rows
                netTotalDr += totalDr;
                netTotalCr += totalCr;

                return (
                  <tr
                    key={index}
                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{trialBalance.accountCode}</td>
                    <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">{trialBalance.accountName}</td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {totalDr != null && totalDr !== 0
                        ? totalDr < 0
                          ? `£(${Math.abs(totalDr).toFixed(2)})`
                          : `£${Math.abs(totalDr).toFixed(2)}`
                        : ""}
                    </td>
                    <td className="text-right px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      {totalCr != null && totalCr !== 0
                        ? totalCr < 0
                          ? `£(${Math.abs(totalCr).toFixed(2)})`
                          : `£${Math.abs(totalCr).toFixed(2)}`
                        : ""}
                    </td>
                    <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                      <button className="text-xs py-1 px-3 font-medium text-white-bg bg-green-500 rounded hover:bg-green-600"
                        onClick={() => {
                          setOpenCaseDrawer(!openCaseDrawer);
                          setTrialBalanceData(trialBalance)
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
              {/* <tr
                className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
              >
                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">1</td>
                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">Immigration</td>
                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35"></td>
                <td className="px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">£ 400.00</td>
                <td className="cursor-pointer px-6 py-3 border-r-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <button type="button"
                    onClick={toggleTrialModal}
                    className="text-xs py-1 px-3 font-medium text-white-bg bg-green-500 rounded hover:bg-green-600">
                    View
                  </button>
                  {(openTrialModal) &&
                    <TrialBalanceTransactions openDrawer={openTrialModal} Close={toggleTrialModal} modalType={"Add"} />
                  }
                </td>
              </tr> */}
            </tbody>
          </table>
          </div>
          {currentTrialBalance.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Trial Balance Details found.</p>
            </div>
          )}
        </div>
        {
          (openCaseDrawer) && <ViewMainDrawer
            openCaseDrawer={openCaseDrawer}
            onClose={toggleCaseDrawer}
            getData={trialBalanceData}
            selectedDate= {trialBalances?.selectedDate}
            title="Trial Balance Transactions"
            drawerContent="trialBalance"
          />
        }
      </div>
    </>
  );
}

export { TrialBalance };
